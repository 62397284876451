import { PayrollPayoutAccountData } from '@app/@types/payroll.types';
import { Tooltip } from '@atob-developers/shared/src/components/Tooltip';
import { faCheckCircle, faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement } from 'react';

const PayoutAccountBadge = ({
  payoutAccount,
}: {
  payoutAccount: PayrollPayoutAccountData | undefined;
}): ReactElement => {
  if (!payoutAccount) {
    return (
      <span className="ml-2">
        <Tooltip
          icon={faExclamationTriangle}
          iconClassName="text-red"
          title="The driver needs to set up one card to be paid"
        />
      </span>
    );
  }

  if (!payoutAccount.support_instant) {
    return (
      <span className="ml-2">
        <Tooltip
          icon={faExclamationTriangle}
          iconClassName="text-orange"
          title="Your transaction may take up to 2-3 days to complete"
        />
      </span>
    );
  }

  return <FontAwesomeIcon className="text-green ml-2" icon={faCheckCircle} />;
};

export default PayoutAccountBadge;
