import Modal, { ModalBodyContent, ModalHeader } from '@atob-developers/shared/src/components/Modal';

interface AmountApplicationModalProps {
  isOpen: boolean;
  closeModal: () => void;
}

const AmountApplicationModal = ({ isOpen, closeModal }: AmountApplicationModalProps) => {
  return (
    <Modal open={isOpen} toggle={closeModal}>
      <ModalHeader
        title="Flexible financing built specifically for your fleet"
        onClose={closeModal}
      />
      <ModalBodyContent>
        <div className="flex flex-col items-center gap-2 bg-white pb-8">
          <p className="text-base text-gray-900">
            Get access to funds when you need them – supercharging your business’s growth and
            ensuring your longevity. Fill out the 5 min application below to receive your financing
            offer.
          </p>
          <div className="text-default-primary flex flex-col gap-2 text-base">
            <p>📈 12-24 month lines up to $100k</p>
            <p>🔐 Funds available in AtoB wallet within 2 days of approval</p>
            <p>💵 Best in-market rates for financing</p>
            <p>✔️ Only pay for funds your fleet uses</p>
          </div>
          <a
            className="bg-primary mt-4 w-full rounded px-4 py-2 text-center text-white"
            href={'https://secure.fundation.com/AtoB/Home'}
            target="_blank"
            rel="noreferrer"
          >
            Apply Now
          </a>
        </div>
      </ModalBodyContent>
    </Modal>
  );
};

export default AmountApplicationModal;
