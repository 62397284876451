import { ELDProvider } from '@app/@types/eld-connect.types';
import { PurchaseUploadingState } from '@app/hooks/query/useConnectTelematics';
import classNames from 'classnames';
import { PurchaseUploadingDetailPane } from '../PurchaseUploading/PurchaseUploadingDetailPane';
import TelematicsCard from './TelematicsCard';
import TelematicsMap from './TelematicsMaps/TelematicsMap';

interface ProviderDetailViewProps {
  connectedProviders: ELDProvider[];
  onClick: (provider: ELDProvider) => void;
  purchaseUploadingStatus: PurchaseUploadingState;
}

const ProviderDetailView = ({
  connectedProviders,
  onClick,
  purchaseUploadingStatus,
}: ProviderDetailViewProps) => {
  const showDetailPane = purchaseUploadingStatus && purchaseUploadingStatus !== 'unavailable';

  const detailPane = showDetailPane
    ? () => <PurchaseUploadingDetailPane purchaseUploadingStatus={purchaseUploadingStatus} />
    : undefined;

  return (
    <div className="w-full">
      <div
        className={classNames('grid', 'gap-6', {
          'grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3': !showDetailPane,
          'grid-cols-1': showDetailPane,
        })}
      >
        {connectedProviders &&
          connectedProviders.map((provider) => {
            return (
              <TelematicsCard
                key={provider.id}
                provider={provider}
                onClick={onClick}
                DetailPane={detailPane}
              />
            );
          })}
      </div>
      <div className="bg-soft-primary mt-8 rounded-md p-1">
        <TelematicsMap />
      </div>
    </div>
  );
};

export default ProviderDetailView;
