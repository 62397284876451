import { TruckType } from '@app/@types/telematics.types';
import { ErrorNotification } from '@app/components/layout';
import TelematicsMapContext from '@app/contexts/telematicsMapContext';
import useWindowWidth from '@app/hooks/useWindowWidth';
import Modal from '@atob-developers/shared/src/components/Modal';
import { ReactElement, useContext, useState } from 'react';
import './MapContainer.scss';
import TruckSelectorContainer from './TelematicsMapsComponents/TruckSelectorContainer';
import TruckSelectorContainerMobile from './TelematicsMapsComponents/TruckSelectorContainerMobile';
import TrucksMap from './TrucksMap';

const MapContainer = (): ReactElement => {
  const { trucks, activeTruck, setActiveTruck } = useContext(TelematicsMapContext);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [error, setError] = useState(null);

  const { isMobile } = useWindowWidth();

  const selectTruck = async (truck: TruckType | null) => {
    if (truck === null) {
      setActiveTruck(null);
    } else {
      setActiveTruck(truck);
    }
  };

  return (
    <div className="relative">
      {isMobile ? (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <TruckSelectorContainerMobile selectTruck={selectTruck} setError={setError} />
      ) : (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <TruckSelectorContainer selectTruck={selectTruck} setError={setError} />
      )}
      <div className="relative z-0 h-[30em] w-full md:h-[50em]">
        <TrucksMap
          trucks={trucks}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          activeTruck={activeTruck}
          selectTruck={selectTruck}
        />
      </div>
      <Modal open={showModal} toggle={() => setShowModal(false)}>
        <div className="mapContainerModalContent">
          <div className="modalLoader" />
          {error !== null ? (
            <ErrorNotification error="There was an error loading your vehicle data. Please reload the page and try again." />
          ) : (
            <>
              <h2 className="text-default-primary">Please wait a moment</h2>
              <p className="text-default-primary">
                We are populating data based on your selection; this can take up to 2 minutes.
              </p>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default MapContainer;
