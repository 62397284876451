import { CountryName, countries } from '@atob-developers/shared/src/utils/countries';
import { faCheck, faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  TextField,
  TextFieldProps,
  InputAdornment,
} from '@mui/material';
import { useState, useRef, useCallback, useMemo } from 'react';
import ReactInputMask from 'react-input-mask';

export default function PhoneInput({
  value,
  onChange,
  onBlur,
  disabled,
  ...props
}: TextFieldProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [country, setCountry] = useState<CountryName>('USA');
  const ref = useRef<HTMLDivElement>(null);

  const open = Boolean(anchorEl);

  const handleOpenMenu = useCallback(() => {
    setAnchorEl(ref.current);
  }, []);

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const startAdornment = useMemo(() => {
    const { icon } = countries[country];

    return (
      <InputAdornment
        className="h-fit cursor-pointer gap-2"
        onClick={handleOpenMenu}
        position="start"
      >
        {icon}
        <FontAwesomeIcon icon={faChevronDown} className="text-default-primary" />
      </InputAdornment>
    );
  }, [country, handleOpenMenu]);

  const [mask, placeholder, dialingCode] = useMemo(() => {
    const { mask, placeholder, dialingCode } = countries[country];

    return [mask, placeholder, dialingCode] as const;
  }, [country]);

  const formattedValue = useMemo(() => {
    if (typeof value !== 'string') {
      return;
    }

    if (!value.includes(dialingCode)) {
      return `${dialingCode} ${value}`;
    }

    return value;
  }, [value, dialingCode]);

  return (
    <>
      <ReactInputMask
        mask={mask}
        value={formattedValue}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
      >
        <TextField
          {...props}
          placeholder={props.placeholder || placeholder}
          slotProps={{
            input: {
              ...props.slotProps?.input,
              startAdornment,
            },
            htmlInput: { ...props.slotProps?.htmlInput },
          }}
        />
      </ReactInputMask>
      <div id="country-menu-position" ref={ref} />
      <Menu
        sx={{ '& .MuiPaper-root .MuiList-root': { width: anchorEl?.clientWidth } }}
        onClose={handleCloseMenu}
        open={open}
        anchorEl={anchorEl}
        id="country-menu-position"
      >
        {Object.keys(countries).map((value, index) => (
          <MenuItem
            key={index}
            onClick={() => {
              setCountry(value as CountryName);
              handleCloseMenu();
            }}
          >
            <ListItemText>{value}</ListItemText>
            {value === country && (
              <ListItemIcon>
                <FontAwesomeIcon icon={faCheck} />
              </ListItemIcon>
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
