import { usePlaidUpdateHandler } from '@app/hooks/usePlaidHandlers';
import PlaidLinkConnect from './PlaidLinkConnect';

interface PlaidLinkUpdateProps {
  updateBankAccountId: string;
  setError: (e: Error) => void;
  buttonProps?: {
    disabled?: boolean;
    text?: string;
    className?: string;
    primary?: boolean;
    dark?: boolean;
    fullWidth?: boolean;
    size?: 'small' | 'medium';
  };
  text: string;
}

const PlaidLinkUpdate = ({ updateBankAccountId, ...rest }: PlaidLinkUpdateProps) => {
  const { onSuccess, onEvent } = usePlaidUpdateHandler(updateBankAccountId);

  return <PlaidLinkConnect onSuccess={onSuccess} onEvent={onEvent} {...rest} />;
};

export default PlaidLinkUpdate;
