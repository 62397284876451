import { EndpointResponse } from '@app/@types/api.types';
import { DebitCardPaymentMethodCombined } from '@app/@types/debitCard.types';
import { InstantPayoutRecipient } from '@app/@types/instant_payout_method.types';
import { PaymentMethodCombined } from '@app/@types/payments.types';
import { PAYMENT_METHOD_QUERY_KEY } from '@app/hooks/query/usePaymentMethodsQuery';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import { useMemo } from 'react';
import useSWR from 'swr';
import { NormalizedDestination, PaymentMethodType } from '../utils';
import { useWalletTransfer } from './useWalletTransfer';

const mapAccountType = (type: string) => {
  switch (type) {
    case 'InstantPayoutMethod::Zelle':
      return 'zelle';
    case 'InstantPayoutMethod::Paypal':
      return 'paypal';
    case 'InstantPayoutMethod::Venmo':
      return 'venmo';
  }
};

const useTransferDestinations = () => {
  const { selectedRecipient } = useWalletTransfer();

  const { data: instantPayoutRecipient, isLoading: loadingInstantPayoutRecipient } = useSWR<
    EndpointResponse<InstantPayoutRecipient>
  >(
    {
      url: `/instant_payout/recipients/${selectedRecipient?.id}?include=treasury_recipients,instant_payout_methods`,
    },
    apiGetFetcher,
  );

  const { data: ownDestinations, isLoading: loadingOwnDestinations } = useSWR<
    EndpointResponse<PaymentMethodCombined[]>
  >(PAYMENT_METHOD_QUERY_KEY, apiGetFetcher);

  const processedBankAccountDestinations = useMemo(
    () =>
      (instantPayoutRecipient?.data?.treasury_recipients || []).map((destination) => {
        return {
          id: destination.id,
          recipientType: selectedRecipient?.customer_owned ? 'own' : 'external',
          name: destination.billing_details.name,
          type: 'bank_account',
          accountType: destination.account_type,
          instant: destination.instant_ach_eligible,
          lastTransferredAt: destination.last_transferred_at,
          alternateName: destination.display_name,
          lastFour: destination.payment_details.bank_account.mask,
          supportedNetworks: destination.payment_details.bank_account
            .supported_networks as PaymentMethodType[],
          brand: null,
          recipientName: destination.billing_details.name,
          needsAddress:
            destination.billing_details.address?.address1 == null ||
            destination.billing_details.address?.address1 === '',
        };
      }) as NormalizedDestination[],
    [instantPayoutRecipient?.data?.treasury_recipients, selectedRecipient?.customer_owned],
  );

  const processedInstantPayoutMethodDestinations = useMemo(
    () =>
      (instantPayoutRecipient?.data?.instant_payout_methods || []).map((destination) => {
        return {
          id: destination.id,
          recipientType: selectedRecipient?.customer_owned ? 'own' : 'external',
          name: destination.username,
          type: `${mapAccountType(destination.type)}_account`,
          instant: true,
          lastTransferredAt: destination.last_transferred_at,
          alternateName: destination.nickname,
          lastFour: '',
          accountType: mapAccountType(destination.type),
          supportedNetworks: [mapAccountType(destination.type)] as PaymentMethodType[],
          brand: null,
          recipientName:
            destination.username + (destination.nickname ? ` (${destination.nickname})` : ''),
          needsAddress: false,
        };
      }) as NormalizedDestination[],
    [instantPayoutRecipient?.data?.instant_payout_methods, selectedRecipient?.customer_owned],
  );

  const processedDebitCardDestinations = useMemo(
    () =>
      (ownDestinations?.data || [])
        .filter((destination) => destination.payment_method_detail.type === 'debit_card')
        .map((destination) => destination as DebitCardPaymentMethodCombined)
        .map((destination) => {
          return {
            id: destination.id,
            recipientType: 'own',
            name: destination.payment_method_detail.description,
            instant: true,
            alternateName: destination.payment_method_detail.expires,
            lastTransferredAt: destination.last_transferred_at,
            type: 'debit_card',
            lastFour: destination.payment_method_detail.last_four,
            brand: destination.payment_method_detail.brand,
            supportedNetworks: ['debit'],
            accountType: 'debit',
            recipientName: null,
          };
        }) as NormalizedDestination[],
    [ownDestinations?.data],
  );

  const normalizedExternalDestinations: NormalizedDestination[] = useMemo(() => {
    return [...processedBankAccountDestinations, ...processedInstantPayoutMethodDestinations];
  }, [processedBankAccountDestinations, processedInstantPayoutMethodDestinations]);

  const normalizedOwnDestinations: NormalizedDestination[] = useMemo(() => {
    return [
      ...processedDebitCardDestinations,
      ...processedBankAccountDestinations,
      ...processedInstantPayoutMethodDestinations,
    ];
  }, [
    processedDebitCardDestinations,
    processedBankAccountDestinations,
    processedInstantPayoutMethodDestinations,
  ]);

  return {
    normalizedExternalDestinations,
    normalizedOwnDestinations,
    loadingInstantPayoutRecipient,
    loadingOwnDestinations,
  };
};

export default useTransferDestinations;
