import { PaginatedEndpointResponse } from '@app/@types/api.types';
import { VehicleData } from '@app/@types/vehicle.types';
import { AssignEntityInfiniteList } from '@app/components/AssignEntity/AssignEntityInfiniteList';
import { DEFAULT_PAGE_SIZE } from '@app/hooks/paging/types';
import { MenuItem, Checkbox, ListItemText } from '@mui/material';
import { ReactElement, useCallback } from 'react';
import { SWRInfiniteKeyLoader } from 'swr/infinite';

export default function AssignVehicle({
  vehicleId,
  onChange,
}: {
  vehicleId: number | null;
  onChange: (id: string | null) => void;
}): ReactElement {
  const keyFunc: (
    searchString: string,
  ) => SWRInfiniteKeyLoader<PaginatedEndpointResponse<VehicleData>> = useCallback(
    (searchString) => (index, previousPage) => {
      if (
        previousPage &&
        previousPage.meta?.pagination.current_page == previousPage.meta?.pagination.total_pages
      ) {
        return null;
      }
      return {
        url: '/vehicles',
        params: {
          page: index + 1,
          per: DEFAULT_PAGE_SIZE,
          like: searchString !== '' ? searchString : undefined,
          all: false,
          sort: ['name:asc'],
          archived_at: 'none',
        },
      };
    },
    [],
  );

  return (
    <AssignEntityInfiniteList
      entityId={vehicleId ? vehicleId.toString() : null}
      onChange={onChange}
      name="Vehicle"
      keyFunc={keyFunc}
      labelField="name"
      singleEntityUrl="/vehicles/"
      renderListItem={(item: VehicleData, isSelected) => (
        <MenuItem
          autoFocus={false}
          key={item.id}
          value={item.id.toString()}
          selected={isSelected}
          className="gap-1 px-2"
        >
          <Checkbox checked={isSelected} />
          <ListItemText>
            <>{item.name ?? ''}</>
          </ListItemText>
        </MenuItem>
      )}
    />
  );
}
