import { InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { ReactElement } from 'react';
import { DropdownFormElement, InputFormElement, WithId } from '../@types/form.types';
import { DataItemType } from './DataItem';
import Input from './Input';

interface FormElementProps<T> {
  element: InputFormElement | DropdownFormElement<T>;
  value: T | string | number | boolean;
  handleOnChange: (val: string | T | null) => void;
  handleBlur?: () => void;
  required?: boolean;
  error?: string;
  warning?: string;
  disabled?: boolean;
  readOnly?: boolean;
  noPaste?: boolean;
}

const FormElement = <T,>({
  element,
  value,
  handleOnChange,
  handleBlur,
  required = false,
  error,
  warning,
  disabled,
  readOnly = false,
  noPaste = false,
}: FormElementProps<T>): ReactElement => {
  switch (element.type) {
    case DataItemType.DROPDOWN:
      return (
        <>
          {element.label && (
            <InputLabel className="text-default-primary block text-sm font-medium">
              {element.label}
            </InputLabel>
          )}
          <Select
            className="w-full"
            value={(value as WithId<T>).id}
            error={!!error}
            onChange={(e) => {
              const item = (element as DropdownFormElement<T>).options.find(
                (opt) => opt.id === e.target.value,
              );
              item && handleOnChange(item);
            }}
            displayEmpty
            renderValue={(value) => {
              const item = (element as DropdownFormElement<T>).options.find(
                (opt) => opt.id === value,
              );
              if (!item) {
                return (
                  <span className="text-default-secondary text-base">
                    {(element as DropdownFormElement<T>).blankState || 'Options'}
                  </span>
                );
              }
              return (element as DropdownFormElement<T>).children(item);
            }}
            onBlur={handleBlur}
          >
            {(element as DropdownFormElement<T>).options.map((option) => (
              <MenuItem
                classes={{ root: 'flex flex-col items-start' }}
                key={option.id}
                value={option.id}
              >
                {(element as DropdownFormElement<T>).children(option)}
              </MenuItem>
            ))}
          </Select>
        </>
      );
    case DataItemType.TEXT_FIELD:
      return (
        <TextField
          label={element.label}
          id={element.key}
          value={(value as string) || ''}
          placeholder={(element as InputFormElement)?.placeholder || ''}
          onChange={(event) => handleOnChange(event.target.value)}
          onBlur={handleBlur}
          helperText={error}
          error={!!error}
          disabled={disabled}
          className="w-full"
        />
      );
    default:
      return (
        <Input
          label={element.label}
          inputId={element.key}
          value={(value as string) || ''}
          inputType={(element as InputFormElement)?.inputType || 'text'}
          placeholder={(element as InputFormElement)?.placeholder || ''}
          required={required}
          onChange={(event) => handleOnChange(event.target.value)}
          error={error}
          warning={warning}
          disabled={disabled}
          readOnly={readOnly}
          onPaste={
            noPaste
              ? (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }
              : undefined
          }
        />
      );
  }
};

export default FormElement;
