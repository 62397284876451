import { SettingsPageEntityType } from '@app/constants/settings';
import { SETTINGS_ROW_OPTIONS } from '@app/constants/settingsRow';
import { IAddressProps } from '@app/pages/Settings/SettingsPageRow';
import { ReactElement } from 'react';
import { SettingsCurrentValue } from './SettingsCurrentValue';

export const SettingsRowCurrentValue = ({
  type,
  value,
}: {
  type: SettingsPageEntityType;
  value: string | IAddressProps | boolean;
}): ReactElement => {
  return (
    <div className="hidden flex-col gap-2 xl:flex xl:basis-5/12">
      <p className="text-default-primary flex items-center text-sm font-normal">
        {SETTINGS_ROW_OPTIONS[type].title}
      </p>
      <SettingsCurrentValue type={type} value={value} />
    </div>
  );
};
