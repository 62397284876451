import { faGasPump, faTruck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import { Coords } from 'google-map-react';

const MerchantMarker = (_: Coords) => {
  return (
    <Tooltip title="Merchant Location">
      <div className="bg-strong-primary text-contrast-primary -ml-6 -mt-6 flex h-9 w-9 items-center justify-center rounded-full">
        <FontAwesomeIcon icon={faGasPump} data-testid="merchant-marker" className="h-4 w-4" />
      </div>
    </Tooltip>
  );
};

const VehicleMarker = (_: Coords) => {
  return (
    <Tooltip title="Vehicle Location">
      <div className="bg-strong-primary text-contrast-primary -ml-6 -mt-6 flex h-9 w-9 items-center justify-center rounded-full">
        <FontAwesomeIcon icon={faTruck} data-testid="vehicle-marker" className="h-5 w-5" />
      </div>
    </Tooltip>
  );
};

const MerchantAndVehicleMarker = (_: Coords) => {
  return (
    <Tooltip title="The vehicle was located at the merchant.">
      <div className="text-contrast-primary -ml-6 -mt-6 flex h-12 min-w-fit flex-row items-center justify-center">
        <div className="relative overflow-hidden">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="36"
            viewBox="0 0 28 36"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M27.8492 33.0688C23.6517 29.4025 21 24.0109 21 18C21 11.9891 23.6517 6.59748 27.8492 2.93124C25.0192 1.07778 21.6355 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36C21.6355 36 25.0192 34.9222 27.8492 33.0688Z"
              className="fill-strong-primary"
            />
          </svg>
          <FontAwesomeIcon
            icon={faGasPump}
            className="text-contrast-primary absolute left-1/2 top-1/2 h-4 w-4 -translate-x-1/2 -translate-y-1/2"
          />
        </div>
        <div className="relative -translate-x-1 overflow-hidden">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="37"
            height="36"
            viewBox="0 0 37 36"
            fill="none"
          >
            <circle cx="18.0498" cy="18" r="18" className="fill-strong-primary" />
          </svg>
          <FontAwesomeIcon
            icon={faTruck}
            className="text-contrast-primary absolute left-1/2 top-1/2 h-5 w-5 -translate-x-1/2 -translate-y-1/2"
          />
        </div>
      </div>
    </Tooltip>
  );
};

export { MerchantAndVehicleMarker, MerchantMarker, VehicleMarker };
