import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import './RoundedCard.css';

/*
 * This will render as a normal div for screens under the `breakpoint` (if defined), and as a card for devices over the `breakpoint`.
 */
export default function RoundedCard({
  children,
  className,
  breakpoint,
}: PropsWithChildren<{ className?: string; breakpoint?: 'sm' | 'md' | 'lg' | 'xl' }>) {
  let responsiveClasses;

  switch (breakpoint) {
    case 'sm':
      responsiveClasses = 'sm:rounded-lg sm:rounded-card';
      break;
    case 'md':
      responsiveClasses = 'md:rounded-lg md:rounded-card';
      break;
    case 'lg':
      responsiveClasses = 'lg:rounded-lg lg:rounded-card';
      break;
    default:
      responsiveClasses = 'rounded-lg rounded-card';
  }

  return (
    <div
      className={classNames(
        responsiveClasses,
        'bg-soft-primary border-soft overflow-hidden border',
        className,
      )}
    >
      {children}
    </div>
  );
}
