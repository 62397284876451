import { EndpointResponse } from '@app/@types/api.types';
import {
  FetcherKey,
  apiDeleteFetcher,
  apiGetFetcher,
  apiPostFetcher,
} from '@app/utils/data/fetchers';
import { guardAxiosError } from '@app/utils/error/guards';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';
import useSWR from 'swr';
import useSWRMutation from 'swr/mutation';

type UpdateFuelTypeOtherTransactionParam = {
  gallons: number;
  fuel_type: string;
};

type RowData = {
  id: number;
  fuel_type: string;
  vehicle_count: number;
  total_miles: number;
  total_fuel_purchased: number;
  jurisdictions_count: number;
  avg_mpg?: number;
};

type Files = {
  id: number;
  created_at: string;
  file_name: string;
  no_of_records: string;
};
type UploadedFilesInterface = {
  data: Files[];
};

type TransactionData = {
  vehicle_name: string;
  state: string;
  auth_timestamp: string;
  id: number;
  fuel_data: {
    type: string;
  };
  gallons: number;
};

export function useUpdateFuelTypeOtherTransaction(id: string | null) {
  return useSWRMutation<
    EndpointResponse<undefined>,
    AxiosError,
    FetcherKey,
    UpdateFuelTypeOtherTransactionParam
  >(
    {
      url: `/ifta/fuel_summaries/${id}/update_fuel_type_other_transaction`,
    },
    apiPostFetcher,
    { populateCache: false },
  );
}
export function useSyncIftaSummary() {
  const { addToast } = useToasts();

  return useSWRMutation<EndpointResponse<undefined>, AxiosError, FetcherKey>(
    {
      url: `/ifta/fuel_summaries/sync_ifta_summary`,
    },
    apiGetFetcher,
    {
      populateCache: false,
      onError: (e) => {
        if (guardAxiosError(e)) {
          addToast({
            type: 'error',
            title: 'Failed to sync telematics IFTA summary. Please try again',
          });
          Sentry.captureException(e);
        }
      },
    },
  );
}

export function useGetUploadedFiles(with_file_mode: string) {
  return useSWR<EndpointResponse<UploadedFilesInterface>, AxiosError, FetcherKey>(
    {
      url: `/ifta/file_uploads?with_file_mode=${with_file_mode}/`,
    },
    apiGetFetcher,
  );
}

export function useSyncIftaFuelSummary() {
  return useSWRMutation<EndpointResponse<undefined>, AxiosError, FetcherKey>(
    {
      url: `/ifta/fuel_summaries/sync_ifta_fuel_summary`,
    },
    apiGetFetcher,
    { populateCache: false },
  );
}

export function useGetFuelTypeOtherTransactions() {
  return useSWR<EndpointResponse<TransactionData[]>>(
    `/ifta/fuel_summaries/get_fuel_type_other_transactions`,
    apiGetFetcher,
  );
}
export function useGetIftaReport() {
  return useSWR<EndpointResponse<RowData[]>>(`/ifta/fuel_summaries/ifta_report`, apiGetFetcher);
}

export function useDeleteUploadedFile(id?: string) {
  const { addToast } = useToasts();
  return useSWRMutation<EndpointResponse<Files[]>, AxiosError, FetcherKey>(
    `/ifta/file_uploads/${id}`,
    apiDeleteFetcher,
    {
      onError: () => {
        addToast({
          type: 'error',
          title: `Failed to delete file. Please try again`,
        });
      },
    },
  );
}

export function useUploadIftaReport() {
  return useSWRMutation<EndpointResponse<undefined>, AxiosError, FetcherKey, FormData>(
    {
      url: '/ifta/fuel_summaries/save_file_upload_fuel_ifta_report',
    },
    apiPostFetcher,
    { populateCache: false },
  );
}
export function useUploadTelematicsData() {
  return useSWRMutation<EndpointResponse<undefined>, AxiosError, FetcherKey, FormData>(
    {
      url: '/telematics/vehicles/telematics_data',
    },
    apiPostFetcher,
    { populateCache: false },
  );
}
