import { IftaFilingStage } from '@app/constants/iftaFiling';
import { memo, ReactElement, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ReportUpload from './ReportUpload';
import ReviewMapping from './ReviewMapping';
import UploadIftaReport from './UploadIftaReport';

type ParsedDataInterface = {
  s_no: number;
  id: number;
  month: string;
  gallons: number;
  state: string;
  fuel_type: string | null;
  vehicle_name: string | null;
  year: number | null;
};
function IftaReportFromOtherFuelCard(): ReactElement {
  const [parsedData, setParsedData] = useState<ParsedDataInterface[]>([]);
  const [isCSVUploaded, setIsCSVUploaded] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const isUpload = searchParams.get('isUpload');

  const handleFileUpload = (file: File | null, results: Array<Array<string>>) => {
    if (!file) return;
    setFile(file);
    if (results.length < 2) return;

    const data = results
      ?.slice(1)
      .map((values, index) => {
        return {
          s_no: index + 1,
          id: index + 1,
          month: values[1],
          gallons: parseFloat(values[2]),
          state: values[3],
          fuel_type: values[4],
          vehicle_name: values[5],
          year: parseInt(values[6]),
        };
      })
      .filter(Boolean);

    setParsedData(data);
    setIsCSVUploaded(true);
    navigate(`/ifta-filing?stage=${IftaFilingStage.NON_ATOB_REPORTS}`);
  };

  return (
    <>
      {isUpload ? (
        <UploadIftaReport handleFileUpload={handleFileUpload} />
      ) : isCSVUploaded ? (
        <ReviewMapping data={parsedData || []} file={file} setIsCSVUploaded={setIsCSVUploaded} />
      ) : (
        <ReportUpload />
      )}
    </>
  );
}

export default memo(IftaReportFromOtherFuelCard);
