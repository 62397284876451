import { faCopy, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Breakpoint,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import classNames from 'classnames';
import { PropsWithChildren, ReactElement, ReactNode } from 'react';

export function ModalHeader({
  title,
  secondaryTitle,
  onClose,
  className,
  headerImage,
  children,
  showHeaderImageWithoutPadding = false,
}: {
  title: string;
  secondaryTitle?: string | ReactElement;
  onClose?: () => void;
  className?: string;
  headerImage?: ReactElement;
  children?: React.ReactNode;
  showHeaderImageWithoutPadding?: boolean;
}): ReactElement {
  return (
    <DialogTitle
      className={classNames('w-full', headerImage && showHeaderImageWithoutPadding ? 'p-0' : '')}
    >
      {headerImage}
      <div
        className={classNames(
          'flex items-center gap-4',
          headerImage ? (showHeaderImageWithoutPadding ? 'px-8 pt-6' : 'pt-6') : '',
          className,
        )}
      >
        <div className="flex flex-col gap-1.5">
          <div className="min-h-8 text-default-primary text-2xl font-semibold tracking-tight">
            {title}
          </div>
          {secondaryTitle && (
            <p className="text-default-secondary text-base font-normal">{secondaryTitle}</p>
          )}
        </div>
        {onClose && <ModalCloseButton onClick={onClose} />}
      </div>
      {children}
    </DialogTitle>
  );
}

export function ModalCloseButton({ onClick }: { onClick: () => void }) {
  return (
    <IconButton size="small" color="secondary" className="absolute right-4 top-4" onClick={onClick}>
      <FontAwesomeIcon icon={faXmark} className="text-lg" />
    </IconButton>
  );
}

export const ModalBodyContent = ({
  children,
  overflowVisible = false,
  fullScreen = false,
}: {
  children: React.ReactNode;
  overflowVisible?: boolean;
  fullScreen?: boolean;
}): ReactElement => {
  return (
    <DialogContent
      classes={{
        root: classNames({
          'overflow-y-visible': overflowVisible,
          'overflow-y-scroll': !overflowVisible,
          'sm:h-auto': fullScreen,
          'h-full': fullScreen,
          'max-h-max': fullScreen,
          'max-h-[80vh]': !fullScreen,
        }),
      }}
    >
      {children}
    </DialogContent>
  );
};

export const ModalFooter = ({ children, className }: PropsWithChildren<{ className?: string }>) => {
  return <DialogActions className={className}>{children}</DialogActions>;
};

export const ModalInfo = ({
  title,
  className,
  children,
}: PropsWithChildren<{ title?: string; className?: string }>) => {
  return (
    <div
      className={classNames(
        'border-soft bg-soft-primary flex flex-col items-start gap-3 rounded-lg border px-5 py-4',
        className,
      )}
    >
      {title && <h4 className="text-default-primary text-base font-semibold">{title}</h4>}
      {children}
    </div>
  );
};

export const ModalCoreItem = ({
  leftContent,
  rightContent,
  info,
  copy,
}: {
  leftContent: ReactNode;
  rightContent: ReactNode;
  info?: ReactNode;
  copy?: boolean;
}) => {
  return (
    <div className="flex w-full items-center gap-2">
      <div className="flex flex-1 items-center gap-1">
        {typeof leftContent === 'string' ? (
          <p className="text-default-secondary">{leftContent}</p>
        ) : (
          leftContent
        )}
        {info}
      </div>
      {typeof rightContent === 'string' ? (
        <p className="text-default-primary font-semibold">{rightContent}</p>
      ) : (
        rightContent
      )}
      {copy && <FontAwesomeIcon icon={faCopy} className="h-4 w-4" />}
    </div>
  );
};

type ModalProps = {
  'open': boolean;
  'toggle'?: () => void;
  'data-testid'?: string;
  'fullWidth'?: boolean;
  'fullScreen'?: boolean;
  'maxWidth'?: Breakpoint;
  'disableEnforceFocus'?: boolean;
  'disableAutoFocus'?: boolean;
  'disableRestoreFocus'?: boolean;
};

export default function Modal({
  open,
  toggle,
  children,
  'data-testid': dataTestId,
  fullWidth = true,
  fullScreen,
  maxWidth,
  disableEnforceFocus = false,
  disableAutoFocus = false,
  disableRestoreFocus = false,
}: PropsWithChildren<ModalProps>) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Dialog
      open={open}
      onClose={toggle}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      sx={fullWidth ? {} : { '& .MuiPaper-root': { width: 'fit-content' } }}
      fullScreen={fullScreen && isMobile}
      data-testid={dataTestId}
      disableAutoFocus={disableAutoFocus}
      disableEnforceFocus={disableEnforceFocus}
      disableRestoreFocus={disableRestoreFocus}
    >
      {children}
    </Dialog>
  );
}
