import { PaginatedEndpointResponse } from '@app/@types/api.types';
import FuelCardUpsellBanner from '@app/components/Banner/FuelCardUpsellBanner';
import MobileNavHeader from '@app/components/Navigation/MobileNavHeader';
import Tour from '@app/components/Tour/Tour';
import { PageContentWrapper } from '@app/components/wrappers/PageContentWrapper';
import { DEFAULT_TRANSACTIONS_PAGE_SIZE } from '@app/hooks/paging/types';
import {
  CustomerOnboardingEventName,
  useCreateCustomerOnboardingEvent,
} from '@app/hooks/query/useCustomerOnboardingEvents';
import useCustomer from '@app/hooks/useCustomer';
import useProduct from '@app/hooks/useProduct';
import { useUrlState } from '@app/hooks/useUrlState';
import useWalletOnlyCustomerOnboarding from '@app/hooks/wallet/useWalletOnlyCustomerOnboarding';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import { shouldOnboard } from '@app/utils/wallet/customerTreasuryOnboarding';
import { ReactElement } from 'react';
import useSWR from 'swr';
import MarketingBannerComponent from '../../components/Banner/MarketingBanners';
import Onboarding from './Onboarding';
import WalletOverview from './WalletOverview';
import WalletPageHeader from './WalletPageHeader';
import WalletTransactions from './WalletTransactions';

export type TransactionStatus = 'success' | 'failed' | 'pending' | 'canceled' | 'returned';

export type WalletTransaction = {
  amount: string;
  created_timestamp: number;
  status: TransactionStatus;
  receipt_url: string;
  description: string;
  direction: 'inbound' | 'outbound';
  source: string;
  destination: string;
  key: string;
  id: string;
  fee?: string;
  method?: string;
};

export type WalletCredit = {
  amount: string;
  created_timestamp: number;
  status: TransactionStatus;
  key: string;
  id: string;
};

function WalletHomeContent() {
  const customer = useCustomer();
  const [walletLite] = useProduct('wallet_lite');

  const [queryParams, setQueryParams] = useUrlState<{
    page: number;
    pending: boolean;
    success: boolean;
    failed: boolean;
  }>({
    page: 1,
    pending: false,
    success: false,
    failed: false,
  });

  const {
    data,
    isLoading,
    error: transactionsError,
    mutate,
  } = useSWR<PaginatedEndpointResponse<WalletTransaction>>(
    {
      url: '/treasury/transactions',
      params: {
        per: DEFAULT_TRANSACTIONS_PAGE_SIZE,
        all: false,
        ...queryParams,
      },
    },
    apiGetFetcher,
  );

  useWalletOnlyCustomerOnboarding(isLoading);

  const fetchTransactions = () => {
    setQueryParams({ pending: false, success: false, failed: false, page: 1 });
    void mutate();
  };

  if (!walletLite && shouldOnboard(customer.treasury)) {
    return (
      <>
        <MobileNavHeader title="Wallet" />
        <PageContentWrapper>
          <MarketingBannerComponent />
          <Onboarding customerTreasuryDetails={customer.treasury} />
        </PageContentWrapper>
      </>
    );
  }

  const tab = queryParams.pending
    ? 'pending'
    : queryParams.failed
    ? 'failed'
    : queryParams.success
    ? 'success'
    : 'all';

  return (
    <PageContentWrapper header={<WalletPageHeader />}>
      {customer.fuel_card_upsell_enabled && <FuelCardUpsellBanner />}
      <WalletOverview
        financialAccount={customer.treasury.financial_account}
        loading={false}
        onAddFunds={fetchTransactions}
        onWithdrawFunds={fetchTransactions}
        customerCompanyName={customer.company_name}
        customerCompanyAddress={customer.company_address}
      />
      <WalletTransactions
        transactionsPage={data?.data ?? []}
        transactionsError={transactionsError}
        metadata={data?.meta}
        tab={tab}
        isLoading={isLoading}
        setTab={(t) =>
          setQueryParams((params) => ({
            ...params,
            page: 1,
            pending: t === 'pending',
            success: t === 'success',
            failed: t === 'failed',
          }))
        }
        goToPage={(p) => setQueryParams((params) => ({ ...params, page: p }))}
      />
    </PageContentWrapper>
  );
}

export default function WalletHome(): ReactElement {
  const { trigger: createOnboardingEvent } = useCreateCustomerOnboardingEvent();

  return (
    <Tour
      steps={[]}
      onClickMask={({ currentStep, steps }) => {
        if (currentStep + 1 === steps?.length) {
          createOnboardingEvent({
            customer_onboarding_event: {
              name: CustomerOnboardingEventName.CUSTOMER_WALLET_COMPLETED,
            },
          });
        } else {
          createOnboardingEvent({
            customer_onboarding_event: { name: CustomerOnboardingEventName.CUSTOMER_SKIP_WALLET },
          });
        }
      }}
    >
      <WalletHomeContent />
    </Tour>
  );
}
