import { IconDefinition, IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { PropsWithChildren, ReactElement } from 'react';
import { MobileFriendlyTooltip } from './MobileFriendlyTooltip';

export type TooltipAlignment =
  | 'bottom-end'
  | 'bottom-start'
  | 'bottom'
  | 'left-end'
  | 'left-start'
  | 'left'
  | 'right-end'
  | 'right-start'
  | 'right'
  | 'top-end'
  | 'top-start'
  | 'top';

type TooltipProps = {
  title?: string;
  description?: string;
  open?: boolean;
  align?: TooltipAlignment;
  icon?: IconDefinition;
  iconClassName?: string;
  arrow?: boolean;
};

export function Tooltip({
  title,
  description,
  open,
  arrow,
  align = 'bottom-end',
  icon = faCircleInfo,
  iconClassName = 'inline-block h-4', // FA has a known issue with SSR where css is loaded after the icons get rendered, resulting in huge icons in between pages
}: TooltipProps) {
  return (
    <MobileFriendlyTooltip
      open={open}
      placement={align}
      title={
        <div className="flex flex-col gap-1">
          <p className="text-contrast-primary text-sm font-medium">{title}</p>
          {description && (
            <p className="text-contrast-secondary text-sm font-normal">{description}</p>
          )}
        </div>
      }
      arrow={arrow}
    >
      <FontAwesomeIcon
        icon={icon as IconProp}
        className={classNames('text-default-primary', iconClassName)}
      />
    </MobileFriendlyTooltip>
  );
}

export function CustomTooltip({
  children,
  open,
  arrow,
  align = 'bottom-end',
  icon = faCircleInfo,
  iconClassName = '',
  target,
}: PropsWithChildren<TooltipProps & { target?: ReactElement }>) {
  return (
    <MobileFriendlyTooltip open={open} placement={align} title={children} arrow={arrow}>
      {target ?? (
        <FontAwesomeIcon
          icon={icon}
          className={classNames('text-default-primary', iconClassName)}
        />
      )}
    </MobileFriendlyTooltip>
  );
}
