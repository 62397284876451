import { SecurityDeposit } from '@app/components/Modals/SecurityDepositModals/SecurityDepositOfferModal';
import FooterActionButtons from '@app/components/Onboarding/FooterActionButtons';
import Alert from '@atob-developers/shared/src/components/Alert';
import { useOnboardingGuide } from './useOnboardingGuide';

export default function SecurityDepositPending({
  securityDeposit,
}: {
  securityDeposit: SecurityDeposit;
}) {
  const { goToPrevStep, goToNextStep } = useOnboardingGuide();

  return (
    <div className="flex h-full flex-col gap-6 md:gap-8">
      <div className="flex flex-col gap-2">
        <p className="text-default-primary">Your security deposit payment is pending.</p>
        <div className="bg-soft-primary border-soft rounded-lg border p-4">
          <div className="flex justify-between">
            <div className="text-default-secondary">Credit Limit</div>
            <div className="text-default-primary font-bold">
              ${securityDeposit.credit_limit_cents / 100}
            </div>
          </div>
          <div className="flex justify-between">
            <div className="text-default-secondary">Security Deposit</div>
            <div className="text-default-primary font-bold">
              ${securityDeposit.amount_cents / 100}
            </div>
          </div>
        </div>
      </div>

      <Alert
        className="w-full"
        type="info"
        title="Your credit line will be activated once your payment is successful"
        description={
          securityDeposit.payment_method_type === 'ach'
            ? 'This generally takes 2-3 business days. We will email you when the process succeeds.'
            : 'This should only take a few minutes and we will email you when the process succeeds.'
        }
      />

      <FooterActionButtons handleClickBack={goToPrevStep} handleClickNext={goToNextStep} />
    </div>
  );
}
