import { PaginationMeta } from '@app/@types/api.types';
import { DriverAccountStatus, DriverData } from '@app/@types/driver.types';
import {
  DriverSidebar,
  DriverSidebarType,
} from '@app/components/Sidebars/DriverSidebar/DriverSidebar';
import { MobileListItem } from '@app/components/TableV2/MobileListItem';
import ResponsiveTable from '@app/components/TableV2/ResponsiveTable';
import { ColumnDefinition } from '@app/components/TableV2/Table';
import useProduct from '@app/hooks/useProduct';
import { phoneFormat } from '@atob-developers/shared/src/utils/formatters';
import { faLink } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from '@mui/material';
import { GridSortModel } from '@mui/x-data-grid-pro';
import { ReactElement, useMemo, useState } from 'react';

const DriverName = ({ driver }: { driver: DriverData }) => {
  const { name, source, synchronized } = driver;
  if (!synchronized) {
    return <>{name}</>;
  }

  return (
    <div className="flex items-center gap-2">
      <Tooltip title={`Synced via ${source}`}>
        <FontAwesomeIcon icon={faLink} className="text-default-primary" />
      </Tooltip>
      {name}
    </div>
  );
};

const MobileDriverRenderer = ({ row, onClick }: { row: DriverData; onClick?: () => void }) => (
  <MobileListItem onClick={onClick}>
    <p className="text-default-primary font-semibold">{row.name}</p>
    <p className="text-default-primary">{phoneFormat({ value: row.phone })}</p>
    <p className="text-default-primary">{row.email}</p>
  </MobileListItem>
);

interface DriversListProps {
  data: DriverData[];
  paginationMeta: PaginationMeta | undefined;
  loading: boolean;
  enableStreamlinedDriverAppOnboarding: boolean;
  onDriversUpdate: () => void;
  onPageIndexChange: (pageIndex: number) => void;
  onSortModelChange: (model: GridSortModel) => void;
}

const DriversList = ({
  data,
  paginationMeta,
  loading,
  enableStreamlinedDriverAppOnboarding,
  onPageIndexChange,
  onDriversUpdate,
  onSortModelChange,
}: DriversListProps): ReactElement => {
  const [sidebar, setSidebar] = useState<{
    sidebarType: DriverSidebarType | null;
    driver: DriverData | null;
  }>({
    driver: null,
    sidebarType: null,
  });

  const handleRowClick = (row: DriverData) => {
    if (row) {
      setSidebar({
        driver: row,
        sidebarType: DriverSidebarType.READ,
      });
    }
  };

  const accountStatusReadable = (accountStatus: DriverAccountStatus): string => {
    switch (accountStatus) {
      case 'account_created':
        return 'Logged In';
      case 'invited':
        return 'Ready to Use';
      case 'not_invited':
        return 'Not Invited';
    }
  };

  const updateSidebarType = (sidebarType: DriverSidebarType) => {
    setSidebar((previousState) => ({ ...previousState, sidebarType }));
  };

  const [allowSelfServePins] = useProduct('drivers_unlock_id');

  const columns = useMemo(
    () =>
      [
        {
          field: 'name',
          headerName: 'Driver',
          minWidth: 200,
          flex: 2,
          renderCell: (params) => <DriverName driver={params.row} />,
        },
        {
          field: 'phone',
          headerName: 'Phone Number',
          flex: 2,
          minWidth: 200,
          renderCell: ({ row }) => {
            return phoneFormat({ value: row.phone });
          },
        },
        {
          field: 'email',
          headerName: 'Email',
          minWidth: 100,
          flex: 1,
        },
        {
          field: 'unlock_id',
          headerName: 'Unlock ID',
          minWidth: 100,
          flex: 1,
          canBeShown: !!allowSelfServePins,
        },
        {
          field: 'notes',
          headerName: 'Notes',
          minWidth: 100,
          flex: 2,
        },
        {
          field: 'account_status',
          headerName: 'AtoB App',
          minWidth: 100,
          flex: 1,
          canBeShown: enableStreamlinedDriverAppOnboarding,
          valueFormatter: (value: DriverAccountStatus) => accountStatusReadable(value),
        },
      ] as ColumnDefinition[],
    [allowSelfServePins, enableStreamlinedDriverAppOnboarding],
  );

  return (
    <>
      <ResponsiveTable
        columns={columns}
        data={data}
        loading={loading}
        onRowClick={handleRowClick}
        onSortModelChange={onSortModelChange}
        paginationMeta={paginationMeta}
        onPageChange={(page) => onPageIndexChange(page)}
        mobileItemRenderer={MobileDriverRenderer}
      />
      <DriverSidebar
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        driver={sidebar.driver}
        open={!!sidebar?.driver}
        setOpen={(open: boolean) => !open && setSidebar({ driver: null, sidebarType: null })}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        sidebarType={sidebar.sidebarType}
        updateSidebarType={updateSidebarType}
        enableStreamlinedDriverAppOnboarding={enableStreamlinedDriverAppOnboarding}
        onDriversUpdate={(driver: DriverData) => {
          setSidebar((previousState) => ({ ...previousState, driver }));
          onDriversUpdate();
        }}
      />
    </>
  );
};

export default DriversList;
