import { InstantPayoutRecipient } from '@app/@types/instant_payout_method.types';
import Avatar from '@app/components/Avatar/Avatar';
import ResponsiveTable from '@app/components/TableV2/ResponsiveTable';
import { ColumnDefinition } from '@app/components/TableV2/Table';
import { ErrorNotification } from '@app/components/layout';
import { Chip } from '@mui/material';
import { ReactElement, useMemo } from 'react';
import RecipientAccounts from './RecipientAccounts';

export default function RecipientsList({
  recipients,
  isLoading,
  onRowClick,
  recipientsError,
}: {
  recipients: InstantPayoutRecipient[];
  isLoading: boolean;
  onRowClick: (row: InstantPayoutRecipient) => void;
  recipientsError: Error | null;
}): ReactElement {
  const customerOwnedRecipient = recipients.find((recipient) => recipient.customer_owned === true);

  const columns = useMemo(
    () =>
      [
        {
          field: 'name',
          headerName: 'Name',
          minWidth: 200,
          flex: 1,
          renderCell: (params) => {
            const customerOwned = params.row?.customer_owned;
            return (
              <div className="flex items-center gap-2">
                <Avatar
                  initials={`${params.row?.name
                    ?.split(' ')
                    .map((name: string) => name[0])
                    .slice(0, 2)
                    .join('')}`}
                  color={customerOwned ? 'dark' : 'light'}
                />
                <div className="flex items-center gap-3">
                  <p>{params.row.name}</p>
                  {customerOwned && <Chip color="green" label="You" size="small" />}
                </div>
              </div>
            );
          },
        },
        {
          field: 'nickname',
          headerName: 'Nickname',
          minWidth: 200,
          flex: 1,
        },
        {
          field: 'accounts',
          headerName: 'Accounts',
          minWidth: 200,
          flex: 1,
          renderCell: (params) => {
            return (
              <RecipientAccounts
                instantPayoutMethods={params.row.instant_payout_methods || []}
                treasuryRecipients={params.row.treasury_recipients || []}
              />
            );
          },
        },
      ] as ColumnDefinition[],
    [],
  );

  return (
    <>
      {recipientsError && <ErrorNotification error={recipientsError.message} />}
      {isLoading || recipients.length > 0 ? (
        <ResponsiveTable
          columns={columns}
          data={recipients}
          loading={isLoading}
          onRowClick={onRowClick}
          pinnedRows={{
            top: customerOwnedRecipient ? [customerOwnedRecipient] : undefined,
          }}
          mobileItemRenderer={MobileRecipientsRenderer}
        />
      ) : (
        <NoRecipients />
      )}
    </>
  );
}

const MobileRecipientsRenderer = ({
  row,
  onClick,
}: {
  row: InstantPayoutRecipient;
  onClick?: () => void;
}) => (
  <div className="border-soft border-b px-4 py-3" onClick={onClick}>
    <div className="flex justify-between">
      <div className="flex items-center gap-2">
        <Avatar
          initials={`${row?.name
            ?.split(' ')
            .map((name: string) => name[0])
            .slice(0, 2)
            .join('')}`}
          color={row?.customer_owned ? 'dark' : 'light'}
        />
        <div>
          <p className="text-default-primary text-sm font-medium">{row.name}</p>
          <p className="text-default-secondary text-xs">
            {row?.customer_owned ? 'You' : row.nickname}
          </p>
        </div>
      </div>
      <RecipientAccounts
        instantPayoutMethods={row.instant_payout_methods || []}
        treasuryRecipients={row.treasury_recipients || []}
      />
    </div>
  </div>
);

const NoRecipients = (): ReactElement => {
  return (
    <div className="flex h-64 w-full flex-col items-center justify-center">
      <img className="w-24 md:w-40" src="/file-folder.svg" alt="Card" />
      <div className="mt-4 flex max-w-[300px] flex-col items-center text-center">
        <h2 className="text-default-secondary text-[18px] font-semibold">No recipients added.</h2>
        <p className="text-default-secondary text-[14px]">Click 'Add recipient' above.</p>
      </div>
    </div>
  );
};
