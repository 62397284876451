import { MerchantData } from '@app/@types/spend_restriction.types';
import CardUnlockSecurityModal from '@app/components/CardDetails/AssignSection/CardUnlockSecurityModal';
import { MerchantRestrictionList } from '@app/components/Sidebars/RestrictionsSidebar/MerchantRestrictionList';
import constants from '@app/utils/constants';
import {
  AUTOMATED_FUEL_DISPENSERS,
  ExpenseCategories,
  generateExpenseCategory,
} from '@app/utils/expenseCategories';
import Card, { CardHeader, CardSection } from '@atob-developers/shared/src/components/Card';
import { faDollar } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MenuItem, Select, Switch, TextField } from '@mui/material';
import { Field, FieldProps, FormikErrors, FormikTouched } from 'formik';
import { ReactElement, ChangeEvent, PropsWithChildren, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MerchantRestrictionDialog from './MerchantRestrictionDialog';
import { OperationalPeriods } from './OperationalPeriods/OperationalPeriods';
import { FormValues } from './policy.types';

const TelematicsOptions = constants.TelematicsOptions;

const MoneyInput = (props: FieldProps): ReactElement => {
  return (
    <TextField
      {...props.field}
      fullWidth
      size="small"
      type="number"
      slotProps={{
        input: {
          startAdornment: <FontAwesomeIcon icon={faDollar} className="text-default-primary pr-2" />,
        },
      }}
    />
  );
};

const BaseTextInput = (props: FieldProps): ReactElement => {
  return <TextField {...props.field} fullWidth size="small" />;
};

type SpendControlFormSection = {
  saveButton?: ReactElement;
  errors?: FormikErrors<FormValues>;
  touched?: FormikTouched<FormValues>;
};

const LimitInput = ({ label, inputId }: { label: string; inputId: string }): ReactElement => {
  return (
    <SectionField>
      <label htmlFor={inputId}>{label}</label>
      <div className="text-default-primary relative flex items-center">
        <div className="absolute left-[10px]">$</div>
        <Field name={inputId} type="number" label="" component={MoneyInput} />
      </div>
    </SectionField>
  );
};

const TextInput = ({ label, inputId }: { label: string; inputId: string }): ReactElement => {
  return (
    <SectionField>
      <label htmlFor={inputId} className="text-default-secondary">
        {label}
      </label>
      <div className="text-default-primary relative flex items-center">
        <Field name={inputId} type="text" label="" component={BaseTextInput} />
      </div>
    </SectionField>
  );
};

const SectionField = ({ children }: PropsWithChildren<unknown>): ReactElement => {
  return (
    <div className="text-default-primary flex flex-col justify-between text-sm sm:flex-row sm:items-center">
      {children}
    </div>
  );
};

const TagNameSection = ({ saveButton, errors, touched }: SpendControlFormSection): ReactElement => {
  return (
    <Card noPadding>
      <CardSection>
        <CardHeader title="Name" />
      </CardSection>
      <CardSection>
        <TextInput inputId="policy.name" label="Tag Name" />
        {touched?.policy?.name && errors?.policy?.name && (
          <span className="text-error text-sm">{errors?.policy?.name}</span>
        )}
      </CardSection>
      <CardSection>
        <TextInput inputId="policy.description" label="Description" />
      </CardSection>
      {saveButton}
    </Card>
  );
};

const TagRulesLimitsSection = ({
  isDefaultSettings,
  allowRestrictions,
  allowSetSpendLimit,
  saveButton,
}: {
  isDefaultSettings?: boolean;
  allowRestrictions: boolean;
  allowSetSpendLimit: boolean;
} & SpendControlFormSection): ReactElement | null => {
  if (!allowRestrictions && !allowSetSpendLimit) {
    return null;
  }

  if (isDefaultSettings) {
    // Nothing to show
    return null;
  }

  return (
    <Card noPadding>
      <CardSection>
        <CardHeader title="Spend Rules & Limits" />
      </CardSection>
      {allowSetSpendLimit && (
        <>
          <CardSection>
            <LimitInput inputId="policy.per_transaction_limit" label="Per Transaction" />
          </CardSection>
          <CardSection>
            <LimitInput inputId="policy.daily_spend_limit" label="Daily" />
          </CardSection>
          <CardSection>
            <LimitInput inputId="policy.weekly_spend_limit" label="Weekly" />
          </CardSection>
        </>
      )}
      {saveButton}
    </Card>
  );
};

type MerchantRestrictionProps = {
  merchants: MerchantData[];
  removeMerchantRestriction: (merchantID: string) => void;
};

const MerchantRestrictionWrapper = (props: FieldProps & MerchantRestrictionProps): ReactElement => {
  return (
    <div className="max-w-[720px]">
      <input type="hidden" value={props.merchants.map((m) => m.id)} />
      <MerchantRestrictionList
        {...props.field}
        merchants={props.merchants}
        removeMerchantRestriction={props.removeMerchantRestriction}
        emptyMessage=""
      />
    </div>
  );
};

const MerchantRestrictionField = (
  props: {
    name: string;
  } & MerchantRestrictionProps,
): ReactElement => {
  return <Field id={props.name} {...props} component={MerchantRestrictionWrapper} />;
};

const MerchantRestrictionSection = ({
  allowedMerchants,
  removeAllowedMerchant,
  blockedMerchants,
  removeBlockedMerchant,
  showModal,
  setShowModal,
  saveButton,
}: {
  allowedMerchants: MerchantData[];
  removeAllowedMerchant: (merchantID: string) => void;
  blockedMerchants: MerchantData[];
  removeBlockedMerchant: (merchantID: string) => void;
  showModal: boolean;
  setShowModal: (val: boolean) => void;
} & SpendControlFormSection): ReactElement => {
  return (
    <Card noPadding>
      <CardSection>
        <MerchantRestrictionDialog open={showModal} handleClose={() => setShowModal(false)} />
        <CardHeader
          title="Merchant Restrictions"
          description="Block or restrict spending on this card to specific merchants. This overrides any category
          restrictions"
        />
        {allowedMerchants.length === 0 && blockedMerchants.length === 0 && (
          <SectionField>
            <button
              type="button"
              className="text-green mt-4 text-sm"
              onClick={() => setShowModal(true)}
            >
              How to add a merchant restriction
            </button>
          </SectionField>
        )}
      </CardSection>
      {allowedMerchants.length > 0 && (
        <>
          <CardSection>
            <SectionField>
              <label className="font-bold">Allowed</label>
            </SectionField>
          </CardSection>
          <CardSection>
            <MerchantRestrictionField
              name="restrictions.merchantWhitelist"
              merchants={allowedMerchants}
              removeMerchantRestriction={removeAllowedMerchant}
            />
          </CardSection>
        </>
      )}
      {blockedMerchants.length > 0 && (
        <>
          <CardSection>
            <SectionField>
              <label className="font-bold">Blocked</label>
            </SectionField>
          </CardSection>
          <CardSection>
            <MerchantRestrictionField
              name="restrictions.merchantBlacklist"
              merchants={blockedMerchants}
              removeMerchantRestriction={removeBlockedMerchant}
            />
          </CardSection>
        </>
      )}
      {saveButton}
    </Card>
  );
};

const CategoryRestrictionContainer = ({
  afdOnly,
  toggleAfdOnly,
  selectedCategoryIds,
  expenseCategories,
  toggleCategory,
  field,
  saveButton,
}: {
  selectedCategoryIds: string[];
  afdOnly: boolean;
  toggleAfdOnly: (checked: boolean) => void;
  expenseCategories: ExpenseCategories;
  toggleCategory: (category: string, checked: boolean) => void;
  saveButton: ReactElement;
} & FieldProps &
  SpendControlFormSection): ReactElement => {
  return (
    <Card noPadding>
      <input type="hidden" value={selectedCategoryIds} />
      <CardSection>
        <CardHeader
          title="Category Restrictions"
          description="Block or restrict spending on this card to merchant categories."
        />
      </CardSection>
      <CardSection>
        <SectionField>
          <label className="font-bold">Fuel Expenses</label>
        </SectionField>
      </CardSection>
      <CardSection>
        <SectionField>
          <label>Fuel</label>
          <Switch {...field} disabled checked />
        </SectionField>
      </CardSection>
      <CardSection>
        <SectionField>
          <label className="ml-0 sm:ml-6">{AUTOMATED_FUEL_DISPENSERS.name}</label>
          <Switch
            {...field}
            checked={afdOnly}
            onChange={(event: ChangeEvent<HTMLInputElement>) => toggleAfdOnly(event.target.checked)}
          />
        </SectionField>
      </CardSection>
      {expenseCategories.map((ec) => (
        <div key={ec?.title}>
          <CardSection>
            <SectionField>
              <label className="font-bold">{ec?.title}</label>
            </SectionField>
          </CardSection>
          {ec?.categories.map((category) => (
            <CardSection key={category.name}>
              <SectionField>
                <label>{category.name}</label>
                <Switch
                  {...field}
                  checked={selectedCategoryIds.includes(category.id)}
                  onChange={(event: ChangeEvent<HTMLInputElement>) =>
                    toggleCategory(category.id, event.target.checked)
                  }
                />
              </SectionField>
            </CardSection>
          ))}
        </div>
      ))}
      {saveButton}
    </Card>
  );
};

const CategoryRestrictionSection = (props: {
  afdOnly: boolean;
  selectedCategoryIds: string[];
  expenseCategories: ExpenseCategories;
  toggleCategory: (category: string, checked: boolean) => void;
  toggleAfdOnly: (checked: boolean) => void;
}): ReactElement => {
  return (
    <Field
      type="hidden"
      component={CategoryRestrictionContainer}
      name="restrictions.selectedCategoryIds"
      {...props}
    />
  );
};

const TelematicsSelect = (props: FieldProps): ReactElement => {
  return (
    <Select
      id="telematics_setting"
      displayEmpty
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      value={props.field.value}
      renderValue={(value: string) =>
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        TelematicsOptions.find((o) => o.id === value).name
      }
      {...props.field}
    >
      {TelematicsOptions.map((option) => (
        <MenuItem value={option.id} key={option.id}>
          <div className="flex-col">
            <strong>{option.name}</strong>
            <p>{option.description}</p>
          </div>
        </MenuItem>
      ))}
    </Select>
  );
};

const CardSecuritySwitch = (
  props: { clickCardSecurity: (checked: boolean) => void } & FieldProps,
): ReactElement => {
  return (
    <Switch
      checked={props.field.value}
      inputProps={{ 'aria-label': 'controlled' }}
      onChange={(event: ChangeEvent<HTMLInputElement>) =>
        props.clickCardSecurity(event.target.checked)
      }
    />
  );
};

const CategorySecuritySection = ({
  allowTelematics,
  cardSecurityEnabled,
  toggleCardSecurity,
  saveButton,
}: {
  allowTelematics: boolean;
  cardSecurityEnabled: boolean;
  toggleCardSecurity: (checked: boolean) => void;
} & SpendControlFormSection): ReactElement => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const clickCardSecurity = (checked: boolean) => {
    if (checked) {
      setShowModal(true);
    } else {
      toggleCardSecurity(checked);
    }
  };

  return (
    <Card noPadding>
      <CardSection>
        <CardHeader title="Card Security" />
      </CardSection>
      <CardSection>
        <SectionField>
          <div>
            <p>Card Unlock</p>
            <p className="text-default-secondary mt-2 max-w-[340px]">
              Ensures card can only be used by a verified driver
            </p>
            <button
              type="button"
              className="text-green mt-4 text-sm"
              onClick={() => {
                setShowModal(true);
              }}
            >
              How it works
            </button>
          </div>
          <Field
            name="policy.card_security_enabled"
            clickCardSecurity={clickCardSecurity}
            component={CardSecuritySwitch}
          />
          <CardUnlockSecurityModal
            cardSecurityEnabled={cardSecurityEnabled}
            loading={false}
            open={showModal}
            confirm={() => {
              toggleCardSecurity(!cardSecurityEnabled);
              setShowModal(false);
            }}
            reset={() => {
              setShowModal(false);
            }}
          />
        </SectionField>
      </CardSection>
      {allowTelematics && (
        <CardSection>
          <SectionField>
            <div>
              <p>Telematics</p>
              <p className="text-default-secondary mt-2 max-w-[340px]">
                Get alerted when cards are used far away from the vehicle location
              </p>
              <button
                type="button"
                className="text-green mt-4 text-sm"
                onClick={() => navigate('/integrations')}
              >
                Connect a telematics provider to get started
              </button>
            </div>
            <div className="max-w-[340px]">
              <Field name="policy.telematics_setting" component={TelematicsSelect} />
            </div>
          </SectionField>
        </CardSection>
      )}
      {saveButton}
    </Card>
  );
};

type SpendControlFormProps = {
  isDefaultSettings?: boolean;
  allowTelematics: boolean;
  allowSetSpendLimit: boolean;
  allowRestrictions: boolean;
  spendTier: number;
  values: FormValues;
  setFieldValue: (key: string, val: unknown) => void;
  saveButton?: ReactElement;
  tagId?: number;
  errors: FormikErrors<FormValues>;
  touched: FormikTouched<FormValues>;
};

const SpendControlForm = ({
  isDefaultSettings,
  allowSetSpendLimit,
  allowRestrictions,
  allowTelematics,
  spendTier,
  values,
  setFieldValue,
  saveButton,
  tagId,
  errors,
  touched,
}: SpendControlFormProps): ReactElement => {
  const { policy, restrictions } = values;
  const [showModal, setShowModal] = useState(false);

  let operationalHoursContainer;

  if (!isDefaultSettings && tagId) {
    // Only show the form if the tag has been saved (tagId is not null)
    operationalHoursContainer = <OperationalPeriods resourceType="Tag" resourceId={tagId} />;
  } else if (isDefaultSettings) {
    // If it is the default settings, then we edit the customer operational hours
    operationalHoursContainer = <OperationalPeriods resourceType="Customer" />;
  }

  return (
    <>
      <h1 className="text-default-primary flex h-auto flex-col gap-6 font-bold md:gap-8">
        {isDefaultSettings ? 'Default Settings' : 'Tag Settings'}
      </h1>
      {!isDefaultSettings && (
        <TagNameSection saveButton={saveButton} errors={errors} touched={touched} />
      )}
      <TagRulesLimitsSection
        allowSetSpendLimit={allowSetSpendLimit}
        allowRestrictions={allowRestrictions}
        isDefaultSettings={isDefaultSettings}
        saveButton={saveButton}
      />
      {operationalHoursContainer}
      {allowRestrictions && (
        <>
          <MerchantRestrictionSection
            showModal={showModal}
            setShowModal={setShowModal}
            allowedMerchants={restrictions.merchantWhitelist}
            removeAllowedMerchant={(merchantID: string) =>
              setFieldValue(
                'restrictions.merchantWhitelist',
                restrictions.merchantWhitelist.filter((m) => m.id !== merchantID),
              )
            }
            blockedMerchants={restrictions.merchantBlacklist}
            removeBlockedMerchant={(merchantID: string) =>
              setFieldValue(
                'restrictions.merchantBlacklist',
                restrictions.merchantBlacklist.filter((m) => m.id !== merchantID),
              )
            }
            saveButton={saveButton}
          />
          <CategoryRestrictionSection
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            afdOnly={restrictions.afdOnly}
            expenseCategories={generateExpenseCategory(restrictions.selectedCategoryIds, spendTier)}
            toggleAfdOnly={(checked: boolean) => {
              setFieldValue('restrictions.afdOnly', checked);
            }}
            toggleCategory={(category: string, checked: boolean) => {
              setFieldValue(
                'restrictions.selectedCategoryIds',
                checked
                  ? [...restrictions.selectedCategoryIds, category]
                  : restrictions.selectedCategoryIds.filter((c) => c !== category),
              );
            }}
            selectedCategoryIds={restrictions.selectedCategoryIds}
            saveButton={saveButton}
          />
        </>
      )}
      {!isDefaultSettings && (
        <CategorySecuritySection
          allowTelematics={allowTelematics}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          cardSecurityEnabled={policy.card_security_enabled}
          toggleCardSecurity={(checked: boolean) =>
            setFieldValue('policy.card_security_enabled', checked)
          }
          saveButton={saveButton}
        />
      )}
    </>
  );
};

export default SpendControlForm;
