import { PaginationMeta } from '@app/@types/api.types';
import { SxProps, Theme } from '@mui/material';
import {
  DataGridPro,
  DataGridProProps,
  GridColTypeDef,
  GridRowModel,
  GridRowParams,
  GridValidRowModel,
} from '@mui/x-data-grid-pro';
import classNames from 'classnames';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import tw from 'twin.macro';
import Pagination from '../Pagination/Pagination';
import SpinnerBoundary from '../Spinner/SpinnerBoundary';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RowData = { [key: string]: any };
export type RowId = string | number;

export type ColumnDefinition = GridColTypeDef & {
  canBeShown?: boolean;
  field: string;
};

const LoadingOverlay = (
  <div className="relative h-full w-full">
    <SpinnerBoundary />
  </div>
);

export type TableProps<T extends GridValidRowModel> = {
  columns: ColumnDefinition[];
  data?: GridRowModel<T>[];
  loading: boolean;
  onRowClick?: (row: GridRowModel<T>) => void;
  pinLeftColumns?: string[];
  pinRightColumns?: string[];
  selectable?: boolean;
  onPageChange?: (page: number) => void;
  paginationMeta?: PaginationMeta;
  pageSize?: number;
  customStyles?: {
    sx?: SxProps<Theme>;
    rowHeight?: number;
    columnHeaderHeight?: number;
    pagination?: boolean;
  };
  /**
   * @deprecated
   * Detail panes provide a poor and confusing experience. Instead, open a sidebar, modal, or new page on click.
   */
  getDetailPaneContent?: (row: GridRowParams<T>) => React.ReactNode;
} & Pick<
  DataGridProProps,
  | 'getRowId'
  | 'onSortModelChange'
  | 'onRowSelectionModelChange'
  | 'rowSelectionModel'
  | 'getRowClassName'
  | 'getCellClassName'
  | 'pinnedRows'
>;

export default function Table<T extends GridValidRowModel>({
  columns,
  data = [],
  loading,
  onRowClick,
  pinLeftColumns,
  pinRightColumns,
  pinnedRows,
  selectable = false,
  onPageChange,
  paginationMeta,
  pageSize,
  onSortModelChange,
  getRowId,
  getDetailPaneContent,
  onRowSelectionModelChange,
  rowSelectionModel,
  customStyles,
  getRowClassName,
  getCellClassName,
}: TableProps<T>): ReactElement {
  const handleRowClick = useCallback(
    (params: GridRowParams) => {
      if (onRowClick) {
        onRowClick(params.row);
      }
    },
    [onRowClick],
  );

  // To help us simplify our column definitions, we filter out columns that have `canBeShown` set to false
  // This is useful for columns that depend on certain features or products to be shown
  const columnsToShow = useMemo(() => {
    return columns.filter((column) => column.canBeShown !== false);
  }, [columns]);

  // Make rows look clickable when we have onRowClick.
  const finalStyles = useMemo(() => {
    if (onRowClick) {
      return {
        '& .MuiDataGrid-row': {
          ...tw`cursor-pointer`,
          '&.MuiDataGrid-row:active': {
            ...tw`bg-soft-primary-clicked`,
            '& .MuiDataGrid-cell--pinnedLeft, & .MuiDataGrid-cell--pinnedRight': {
              ...tw`bg-inherit`,
            },
          },
        },
        '& .MuiDataGrid-row.MuiDataGrid-row:hover': {
          ...tw`bg-soft-primary-hover`,
          '& .MuiDataGrid-cell--pinnedLeft, & .MuiDataGrid-cell--pinnedRight': {
            ...tw`bg-inherit`,
          },
        },
      };
    }
  }, [onRowClick]);

  // Some API clients return undefined while loading
  // Following lines are here to prevent `rowCountState` from being undefined during the loading
  // https://mui.com/x/react-data-grid/pagination/#:~:text=Since,loading%20as%20follow%3A
  const [rowCountState, setRowCountState] = useState(
    paginationMeta?.pagination.total_count || undefined,
  );

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      paginationMeta?.pagination.total_count !== undefined
        ? paginationMeta?.pagination.total_count
        : prevRowCountState,
    );
  }, [paginationMeta?.pagination.total_count, setRowCountState]);

  if (onPageChange && rowCountState === undefined) {
    return <div className="h-[225px] w-full">{LoadingOverlay}</div>;
  }

  return (
    <>
      <div className={classNames('flex flex-col', loading && 'min-h-[225px]')}>
        <DataGridPro
          loading={loading}
          rows={data}
          columns={columnsToShow}
          initialState={{
            pinnedColumns: {
              left: pinLeftColumns,
              right: pinRightColumns,
            },
          }}
          pinnedRows={pinnedRows}
          slots={{
            loadingOverlay: () => LoadingOverlay,
          }}
          isRowSelectable={() => selectable}
          onRowClick={handleRowClick}
          sx={{
            ...customStyles?.sx,
            ...finalStyles,
          }}
          checkboxSelection={selectable}
          sortingMode={onSortModelChange ? 'server' : 'client'}
          onSortModelChange={onSortModelChange}
          rowHeight={56}
          columnHeaderHeight={40}
          getRowId={getRowId}
          getDetailPanelContent={getDetailPaneContent}
          onRowSelectionModelChange={onRowSelectionModelChange}
          rowSelectionModel={rowSelectionModel}
          getRowClassName={
            getRowClassName
              ? getRowClassName
              : (params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')
          }
          getCellClassName={getCellClassName}
          pagination={false}
          hideFooter
          disableColumnMenu
          disableColumnReorder
          disableRowSelectionOnClick
          disableVirtualization
        />
      </div>
      {!loading && (
        <Pagination
          pageSize={pageSize}
          paginationMeta={paginationMeta}
          onPageChange={onPageChange}
        />
      )}
    </>
  );
}
