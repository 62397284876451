import { SettingsCurrentValue } from '@app/components/Cards/Settings/SettingsCurrentValue';
import { SettingsPageEntityType } from '@app/constants/settings';
import { SETTINGS_FORM_DATA, SETTINGS_ROW_OPTIONS } from '@app/constants/settingsRow';
import { InputFormElement } from '@atob-developers/shared/src/@types/form.types';
import FormElement from '@atob-developers/shared/src/components/FormElement';
import { ReactElement } from 'react';

export const EmailPhoneForm = ({
  type,
  values,
  errors,
  setErrors,
  setValues,
  currentValue,
}: {
  type:
    | SettingsPageEntityType.OTP_BACKUP_EMAIL
    | SettingsPageEntityType.PHONE_NUMBER
    | SettingsPageEntityType.EMAIL_ADDRESS
    | SettingsPageEntityType.CUSTOMER_COMPANY_PREFERRED_NAME;
  values: Record<string, string | null>;
  setValues: (values: Record<string, string | null>) => void;
  errors: Record<string, string | undefined>;
  setErrors: (errors: Record<string, string | undefined>) => void;
  currentValue: string;
}): ReactElement => {
  return (
    <div className="flex flex-col gap-2">
      <div className="bg-soft-secondary flex flex-col gap-2 rounded-lg p-5">
        <p className="text-default-secondary flex items-center text-xs font-normal uppercase">
          {`current ${SETTINGS_ROW_OPTIONS[type].title}`}
        </p>
        <SettingsCurrentValue type={type} value={currentValue} />
      </div>

      <div className="flex flex-col gap-2">
        {SETTINGS_FORM_DATA[type].map((item, idx) => (
          <FormElement
            key={`modal-element-${idx}`}
            element={{ ...item } as InputFormElement}
            value={values?.[item.key] || ''}
            handleOnChange={(val) => {
              setErrors({ ...errors, [item.key]: undefined });
              setValues({
                ...values,
                [item.key]: val,
              });
            }}
            error={errors[item.key]}
          />
        ))}
      </div>
    </div>
  );
};
