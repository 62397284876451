import { ChargeEventData } from '@app/@types/charge_events.types';
import { GridRowClassNameParams } from '@mui/x-data-grid-pro';

export const getStatusesHelper = (row: ChargeEventData) => {
  const {
    auth_approved,
    auth_status,
    discount_amount_cents,
    potential_savings,
    quantity,
    transact_dispute_request,
  } = row;
  const quantityNumber = parseFloat(quantity ?? '0');
  const totalSavings = (potential_savings?.savings_amount_in_cents ?? 0) * quantityNumber;

  return {
    hasSaved: auth_approved && (discount_amount_cents ?? 0) > 0,
    hasMissed: auth_approved && totalSavings > 0,
    hasDeclined: auth_status === 'closed' && !auth_approved,
    isPending: auth_status === 'pending',
    isDisputed: transact_dispute_request != null,
    totalSavings,
  };
};

export const getRowClassName = (params: GridRowClassNameParams<ChargeEventData>): string => {
  const { hasSaved, hasMissed, isDisputed } = getStatusesHelper(params.row);

  if (isDisputed) {
    return '!bg-red-secondary hover:!bg-red-secondary-hover active:!bg-red-secondary-clicked';
  }

  if (hasSaved) {
    return '!bg-green-secondary hover:!bg-green-secondary-hover active:!bg-green-secondary-clicked';
  }

  if (hasMissed) {
    return '!bg-orange-secondary hover:!bg-orange-secondary-hover active:!bg-orange-secondary-clicked';
  }

  return 'bg-soft-primary, hover:bg-soft-primary-hover active:bg-soft-primary-clicked';
};
