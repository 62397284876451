import { CustomerData } from '@app/@types/customer.types';
import {
  NotificationChannelsList,
  UserNotificationSettingsData,
} from '@app/@types/notification_setting.types';
import { UserData } from '@app/@types/users.types';
import AddUserPhoneModal from '@app/components/Modals/UserModals/AddUserPhoneModal';
import { ErrorNotification, Loading } from '@app/components/layout';
import { CustomerProduct } from '@app/constants/customerProducts';
import { notificationsList, roleNotificationsList } from '@app/constants/notifications';
import {
  PersonalNotificationSettingsParam,
  useDestroyRoleNotificationSettings,
  usePersonalNotificationSettings,
  useRoleNotificationSettings,
  useUpdatePersonalNotificationSettings,
  useUpdateRoleNotificationSettings,
} from '@app/hooks/query/useNotificationSettings';
import useThemeMode from '@app/hooks/useThemeMode';
import { triggerResendPhoneVerification } from '@app/pages/Users/UsersUtil';
import Card, { CardHeader, CardSection } from '@atob-developers/shared/src/components/Card';
import { CustomTooltip } from '@atob-developers/shared/src/components/Tooltip';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { Checkbox, Switch } from '@mui/material';
import * as Sentry from '@sentry/react';
import classNames from 'classnames';
import { ReactElement, useState } from 'react';

const NotificationSettings = ({
  products,
}: {
  products: CustomerProduct[];
  customer: CustomerData;
}): ReactElement | null => {
  const hasNotificationProducts = products.some((p) => p.indexOf('notification') >= 0);
  const allowManageUserNotifications = products.includes('workspace');
  if (!hasNotificationProducts) {
    return null;
  }

  return (
    <>
      <div>
        <PersonalNotificationsCard products={products} />
      </div>
      {allowManageUserNotifications && (
        <p className="text-default-secondary text-sm font-medium">
          To manage notifications for other users in your company, go to the{' '}
          <a className="underline" href="/users">
            Users
          </a>{' '}
          page and click on a user.
        </p>
      )}
      {products.includes('driver_notification_settings') && (
        <div>
          <RoleNotificationCard products={products} />
        </div>
      )}
    </>
  );
};

const PersonalNotificationsCard = ({ products }: { products: CustomerProduct[] }): ReactElement => {
  const {
    data: personalSettingsDataResponse,
    error,
    isLoading,
  } = usePersonalNotificationSettings();
  const {
    updatePersonalNotificationSettings,
    updateSettingsInProgress,
    error: updateError,
  } = useUpdatePersonalNotificationSettings();

  const personalSettingsData = personalSettingsDataResponse?.data;
  const defaultResource: Record<string, string | null> = {
    resource_id: null,
    resource_type: null,
    resource_name: 'All Cards',
  };
  const user = personalSettingsData && personalSettingsData?.[0]?.user;
  const smsEnabled =
    personalSettingsData &&
    personalSettingsData?.some((d) => d.enabled && d.notification_channels.includes('SMS'));
  const enabledSettings =
    personalSettingsData && personalSettingsData?.length > 0
      ? personalSettingsData?.sort((a, b) => a.id - b.id)
      : // Default to showing notification settings for 'All Cards'
        notificationsList.map(
          (n) =>
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            ({
              enabled: true,
              notification_channels: [],
              notification: { notification_type: n.id },
              ...defaultResource,
            }) as UserNotificationSettingsData,
        );

  const customerResource = {
    all: { resource_type: 'Customer' },
  };
  const selectedResources = enabledSettings.reduce(
    (acc, setting) => {
      const key = setting.resource_type !== 'Tag' ? 'all' : setting.resource_id;
      acc[key] = {
        resource_name: setting.resource_name,
        resource_id: setting.resource_id?.toString(),
        resource_type: setting.resource_type,
      };
      return acc;
    },
    {} as { [key: string]: Record<string, string | null> },
  );

  if (isLoading || updateSettingsInProgress) {
    return <Loading />;
  }

  if (error) {
    Sentry.captureException(error);
    return (
      <ErrorNotification error="We are having issues loading your notification settings. Please try back later." />
    );
  }

  if (updateError) {
    Sentry.captureException(updateError);
    return (
      <ErrorNotification error="We are having issues updating your notification settings. Please try back later." />
    );
  }

  return (
    <Card noPadding>
      <div className="border-soft flex justify-between border-b-[1px] px-4 py-4 md:px-6">
        <CardHeader title="My Notifications" />
        <div className="flex">
          <p className="text-default-secondary flex w-[60px] justify-center text-sm font-medium">
            Email
          </p>
          <p className="text-default-secondary flex w-[60px] justify-center text-sm font-medium">
            SMS
            {smsEnabled && user && !user?.phone && <AddUserPhoneTooltip user={user} />}
            {smsEnabled && user?.phone && !user?.phone_verified_at && (
              <VerifyUserPhoneTooltip user={user} />
            )}
          </p>
        </div>
      </div>
      {notificationsList
        .filter((n) => products.includes(n.product as CustomerProduct))
        .map((notification) => {
          const settings = enabledSettings.filter(
            (s) => s.notification.notification_type === notification.id && (!s.id || s.enabled),
          );
          return (
            <NotificationSettingRow
              key={notification.id}
              settings={settings}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              selectedResources={notification.globalOnly ? customerResource : selectedResources}
              notification={notification}
              updateSettingsInProgress={updateSettingsInProgress}
              updatePersonalNotificationSettings={updatePersonalNotificationSettings}
            />
          );
        })}
    </Card>
  );
};

const VerifyUserPhoneTooltip = ({ user }: { user: UserData }): ReactElement => {
  const { addToast } = useToasts();

  const onResendPhoneVerificationClick = (user: Partial<UserData>) => {
    triggerResendPhoneVerification(user)
      .then(() => {
        const successMsg =
          'Sent a verification link to your phone number. It will start receiving messages when you click on the link.';
        addToast({ type: 'success', title: successMsg });
      })
      .catch(() => {
        addToast({
          type: 'error',
          title:
            'There was an error resending verification text to your phone number. Please try again later',
        });
      });
  };

  return (
    <span className="ml-2">
      <CustomTooltip icon={faExclamationCircle} iconClassName="text-red">
        To receive SMS notifications, make sure the phone number is verified.{' '}
        <a
          className="text-contrast-secondary cursor-pointer underline"
          onClick={() => {
            onResendPhoneVerificationClick(user);
          }}
        >
          Resend verification link
        </a>
      </CustomTooltip>
    </span>
  );
};

const AddUserPhoneTooltip = ({ user }: { user: UserData }): ReactElement => {
  const [showPhoneModal, setShowPhoneModal] = useState(false);
  const { darkClassName } = useThemeMode();

  const clickHandler = () => setShowPhoneModal(true);

  return (
    <>
      <AddUserPhoneModal
        open={showPhoneModal}
        toggle={() => setShowPhoneModal(false)}
        user={user}
      />
      <span className="ml-2">
        <CustomTooltip icon={faExclamationCircle} iconClassName="text-red">
          There’s no phone number associated to your user account. A verified phone number is
          required to receive SMS notifications.{' '}
          <a
            className={classNames(darkClassName, 'text-green cursor-pointer underline')}
            onClick={clickHandler}
            onTouchStart={clickHandler}
          >
            Add your phone number.
          </a>
        </CustomTooltip>
      </span>
    </>
  );
};

const NotificationSettingRow = ({
  settings,
  updateSettingsInProgress,
  selectedResources,
  notification,
  updatePersonalNotificationSettings,
}: {
  settings: UserNotificationSettingsData[];
  notification: (typeof notificationsList)[0];
  selectedResources: { [key: string]: Record<string, string> };
  updateSettingsInProgress: boolean;
  updatePersonalNotificationSettings: (params: PersonalNotificationSettingsParam) => void;
}): ReactElement => {
  if (settings.length === 0) {
    // This means there are no settings yet for the user and we can fallback to the Global level
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    settings.push({
      resource_id: null,
      resource_type: null,
      resource_name: 'All Cards',
      notification_channels: [],
      notification: { notification_type: notification.id },
    } as UserNotificationSettingsData);
  }
  return (
    <div
      key={notification.id}
      className="border-soft flex justify-between border-b px-4 pb-6 pt-6 last:border-b-0 md:justify-start md:px-6"
    >
      <div className="flex-1 flex-col pr-4 text-sm">
        <div className="text-default-primary font-semibold">{notification.name}</div>
        <div className="text-default-secondary font-medium">{notification.description}</div>
      </div>
      {NotificationChannelsList.map((channel) => {
        if (!notification.channels.includes(channel)) {
          return (
            <div className="flex w-[60px] justify-center" key={channel}>
              <Checkbox disabled name={channel} checked={false} />
            </div>
          );
        }

        const isChecked = !!settings[0].notification_channels.includes(channel);
        const type = settings[0].notification.notification_type;
        const notificationChannels = isChecked
          ? settings[0].notification_channels.filter((n) => n !== channel)
          : settings[0].notification_channels.concat(channel);
        return (
          <div className="flex w-[60px] justify-center" key={channel}>
            <Checkbox
              name={channel}
              checked={isChecked}
              disabled={updateSettingsInProgress}
              onChange={() => {
                Object.values(selectedResources).forEach((resource) => {
                  updatePersonalNotificationSettings({
                    resource_id: resource.resource_id,
                    resource_type: resource.resource_type,
                    type: type,
                    enabled: true,
                    notification_types: notificationChannels || [],
                  });
                });
              }}
            />
          </div>
        );
      })}
    </div>
  );
};

const RoleNotificationCard = ({
  products,
}: {
  products: CustomerProduct[];
}): ReactElement | null => {
  const { data: roleSettingsDataResponse, error, isLoading } = useRoleNotificationSettings();
  const {
    updateRoleNotificationSettings,
    updateSettingsInProgress: updateInProgress,
    error: updateError,
  } = useUpdateRoleNotificationSettings();
  const {
    destroyRoleNotificationSettings,
    updateSettingsInProgress: deleteInProgress,
    error: deleteError,
  } = useDestroyRoleNotificationSettings();

  const roleSettingsData = roleSettingsDataResponse?.data;
  const updateSettingsInProgress = updateInProgress || deleteInProgress;

  if (isLoading || updateSettingsInProgress) {
    return <Loading />;
  }

  if (error || updateError || deleteError) {
    Sentry.captureException(error || updateError || deleteError);
    return (
      <ErrorNotification error="We are having issues loading your role settings. Please try back later." />
    );
  }

  const roleNotifications = roleNotificationsList.filter((n) =>
    products.includes(n.product as CustomerProduct),
  );

  if (roleNotifications.length === 0) {
    return null;
  }

  return (
    <Card noPadding>
      <CardSection>
        <CardHeader
          title="Driver Notifications"
          description="Drivers will only receive notifications for cards that are assigned to them"
        />
      </CardSection>
      <div>
        {roleNotifications.map((notificationItem) => {
          const roleSettings =
            roleSettingsData?.filter((r) => r.customer_role === notificationItem.customer_role) ||
            [];
          const specificRoleSetting = roleSettings.find(
            (setting) => setting.notification.notification_type === notificationItem.id,
          );
          const updateSetting = () => {
            if (specificRoleSetting) {
              destroyRoleNotificationSettings({ id: specificRoleSetting.id });
            } else {
              updateRoleNotificationSettings({
                customer_role: notificationItem.customer_role,
                type: notificationItem.id,
              });
            }
          };

          return (
            <div key={notificationItem.id} className="border-soft border-b-[1px] last:border-b-0">
              <div className="flex justify-between px-6 pb-6 pt-6 md:justify-start">
                <div className="flex-1 flex-col pr-4 text-sm">
                  <div className="text-default-primary font-semibold">{notificationItem.name}</div>
                  <div className="text-default-secondary font-medium">
                    {notificationItem.description}
                  </div>
                </div>
                <div>
                  <Switch checked={!!specificRoleSetting} onChange={updateSetting} />
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </Card>
  );
};

export default NotificationSettings;
