import useProduct from '@app/hooks/useProduct';
import { Button } from '@mui/material';

export interface ProviderCardProps {
  logo: string;
  displayName: string;
  connected: boolean;
  connectionStatus?: string;
  onAction: () => void;
  actionLabel: string;
  numberOfConnections?: number;
  DetailPane?: React.ComponentType | null;
}

interface WithProviderCardProps extends ProviderCardProps {
  children?: React.ReactNode;
  buttonColor: 'secondary' | 'primary';
  buttonText: string;
}

function withProviderCard<T extends WithProviderCardProps = WithProviderCardProps>(
  WrappedComponent: React.ComponentType<T> | null,
) {
  return function Component(props: T) {
    const [showDashboard] = useProduct('dashboard');
    const { logo, displayName, buttonColor, buttonText, onAction, DetailPane } = props;

    return (
      <div className="divide-soft bg-soft-primary border-soft flex flex-col space-x-6 divide-y rounded-md border md:flex-row md:divide-x md:divide-y-0">
        <div className="flex space-x-6 p-4">
          <div className="flex items-center justify-center">
            <div className="border-soft bg-soft-primary flex h-20 w-20 items-center justify-center rounded-lg border p-4">
              <img src={logo} alt={displayName} className="w-full" />
            </div>
          </div>
          <div className="flex flex-grow flex-col items-start justify-between">
            <div className="text-default-primary font-semibold">{displayName}</div>
            {WrappedComponent && <WrappedComponent {...props} />}
            {showDashboard && (
              <Button size="small" color={buttonColor} onClick={onAction}>
                {buttonText}
              </Button>
            )}
          </div>
        </div>
        {DetailPane && <DetailPane />}
      </div>
    );
  };
}

export default withProviderCard;
