import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement } from 'react';

export default function PaymentSuccessful({
  title = 'Payment Successful',
  text = 'Your payment will be reflected in your account in up to 5 minutes.',
}: {
  title?: string;
  text?: string;
}): ReactElement {
  return (
    <div className="flex flex-col items-center p-6">
      <div className="text-green mb-3">
        <FontAwesomeIcon className="fa-4x" icon={faCheckCircle} />
      </div>
      <h1 className="mb-1 text-xl font-bold">{title}</h1>
      <p className="text-center text-base">{text}</p>
    </div>
  );
}
