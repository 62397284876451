import { confetti } from '@app/assets/confetti';
import { Button } from '@mui/material';
import { ReactElement, useEffect } from 'react';

const WelcomeTask = ({
  header,
  subheader,
  buttonText,
  navigateTo,
}: {
  header: string;
  subheader: string;
  buttonText: string;
  navigateTo: string;
}) => {
  return (
    <div className="border-soft flex flex-col justify-between border-b py-4 sm:flex-row sm:items-center">
      <div className="flex flex-col sm:mr-12">
        <div className="text-default-primary text-lg font-medium">{header}</div>
        <div className="text-default-secondary text-[16px] font-medium">{subheader}</div>
      </div>
      <div className="py-4 sm:py-0">
        <a target="_blank" rel="noopener noreferrer" href={navigateTo}>
          <Button size="small" color="secondary">
            {buttonText}
          </Button>
        </a>
      </div>
    </div>
  );
};

const WelcomeToAtoBPremium = (): ReactElement => {
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    confetti.start();
    const timeout = setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      confetti.stop();
    }, 2000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div className="flex w-full flex-col items-center">
      <h1 className="text-default-primary text-center text-3xl font-bold">
        Welcome to the AtoB Premium Family!
      </h1>
      <div className="text-default-primary my-6 font-bold">Here are a few features to try:</div>
      <div className="mw-[700px] flex flex-col justify-center">
        <WelcomeTask
          header="Turn on Card Unlock"
          subheader="Choose a card, and toggle on Card Unlock"
          buttonText="Go to Cards"
          navigateTo="/cards"
        />
        <WelcomeTask
          header="Set operational hours"
          subheader="Auto-decline transactions outside of these operational hours"
          buttonText="Set Hours"
          navigateTo="/cards?showRestrictions=true"
        />
        <WelcomeTask
          header="Invite your team"
          subheader="So they can access the dashboard with their own login"
          buttonText="Invite New Users"
          navigateTo="/users"
        />
      </div>
    </div>
  );
};

export default WelcomeToAtoBPremium;
