import { ReactElement } from 'react';

interface BankBrokeConnectionErrorProps {
  navigateToPaymentMethods: () => void;
}

const BankBrokeConnectionError = ({
  navigateToPaymentMethods,
}: BankBrokeConnectionErrorProps): ReactElement => {
  return (
    <span className="text-error text-xs">
      Error connecting to this bank account.
      <br />
      <span className="underline" onClick={() => navigateToPaymentMethods()}>
        Reconnect this account
      </span>{' '}
      and try again.
    </span>
  );
};

export default BankBrokeConnectionError;
