import { PaginatedEndpointResponse } from '@app/@types/api.types';
import { UserData } from '@app/@types/users.types';
import { ErrorNotification } from '@app/components/layout';
import { WorkspaceProduct } from '@app/constants/customerProducts';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import { ReactElement } from 'react';
import useSWR from 'swr';
import UsersPage from './UsersPage';

export default function Users(props: {
  workspaceProducts: WorkspaceProduct[];
  rolesEnabled: boolean;
  addUsersEnabled: boolean;
  allowManageNotifications: boolean;
}): ReactElement {
  const {
    data: userData,
    isLoading,
    error,
    mutate,
  } = useSWR<PaginatedEndpointResponse<UserData>>(
    { url: '/users?page=1&per=200&all=true' },
    apiGetFetcher,
  );

  if (error) {
    return (
      <ErrorNotification error="We're having issues loading your user data. Please try again or if the issue persists, contact support." />
    );
  } else if (userData == null || isLoading) {
    return (
      <UsersPage
        workspaceProducts={props.workspaceProducts}
        allowManageNotifications={false}
        loading={true}
        data={[]}
        fetchData={() => {}}
        workspaceAddUpdateUsers={false}
        workspaceRoles={false}
      />
    );
  } else {
    return (
      <UsersPage
        workspaceProducts={props.workspaceProducts}
        allowManageNotifications={props.allowManageNotifications}
        loading={false}
        data={userData.data ?? []}
        fetchData={mutate}
        workspaceAddUpdateUsers={props.addUsersEnabled}
        workspaceRoles={props.rolesEnabled}
      />
    );
  }
}
