/* global google */
// eslint-disable-next-line no-use-before-define
import TelematicsMapContext from '@app/contexts/telematicsMapContext';
import React, { ReactElement, useState, useEffect, useContext } from 'react';
import './TruckDetailContainer.scss';

function getFormattedCardsString(cards: string[]) {
  if (cards === undefined || cards === null || cards.length === 0) {
    return '';
  }
  return cards.join(', ');
}

function getAddressByLatLng(
  geocoder: google.maps.Geocoder,
  lat: number,
  lng: number,
  setLocationAddress: React.Dispatch<React.SetStateAction<string>>,
): void {
  geocoder.geocode(
    {
      location: { lat, lng },
    },
    (results) => {
      // eslint-disable-next-line no-param-reassign
      if (results) setLocationAddress(results[0].formatted_address);
    },
  );
}

const TruckDetailContainer = (): ReactElement => {
  const [locationAddress, setLocationAddress] = useState<string>('');
  const { activeTruck: selectedTruck } = useContext(TelematicsMapContext);

  useEffect(() => {
    const geocoder = new google.maps.Geocoder();
    getAddressByLatLng(
      geocoder,
      selectedTruck?.location?.latitude || 0,
      selectedTruck?.location?.longitude || 0,
      setLocationAddress,
    );
  }, [setLocationAddress, locationAddress, selectedTruck]);

  const DetailRow = ({ label, value }: { label: string; value: string }) => (
    <div className="mb-2 flex flex-col">
      <div className="text-default-secondary text-sm font-medium">{label}</div>
      <div className="text-default-primary">{value || 'N/A'}</div>
    </div>
  );

  return (
    <div className="bg-soft-primary w-64 rounded-md">
      <div className="flex flex-col p-4">
        <DetailRow
          label="Driver"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          value={selectedTruck?.driver.name}
        />
        <DetailRow
          label="Cards"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          value={selectedTruck?.driver.cards && getFormattedCardsString(selectedTruck.driver.cards)}
        />
        <DetailRow
          label="VIN"
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          value={selectedTruck?.vin}
        />
        <DetailRow label="Location" value={locationAddress} />
      </div>
    </div>
  );
};

export default TruckDetailContainer;
