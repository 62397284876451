import { createTheme } from '@mui/material';
import dayjs from 'dayjs';
import haversine from 'haversine-distance';
import { mapValues, orderBy, partition, groupBy } from 'lodash-es';
import { Coordinates, FuelGrade, FuelListing, FuelPrice } from '../@types/fuel_listings.types';

export const METERS_PER_MILE = 1609.34;
const DISTANCE_DIGITS_TO_DISPLAY = 1;

export function formatFuelPrice(fuelSignPriceCents: number): string {
  return (fuelSignPriceCents / 100).toFixed(2);
}

export function choosePreferredFuelPrice(
  prices: FuelPrice[],
  selectedGrade: FuelGrade | 'TRUCK DIESEL',
): FuelPrice | undefined {
  return (
    prices.find((pricing: FuelPrice) => {
      return pricing.attributes.fuel_grade === selectedGrade;
    }) ?? prices[0]
  );
}

export function getReadableDistanceFromFuelListing(
  location: Coordinates,
  fuelListing: FuelListing,
): string {
  const computedDistanceMiles = getDistanceFromFuelListing(location, fuelListing);
  const readableDistance =
    computedDistanceMiles > 100
      ? '100+'
      : computedDistanceMiles.toFixed(DISTANCE_DIGITS_TO_DISPLAY);

  return `${readableDistance} mi away`;
}

export function getDistanceFromFuelListing(
  location: Coordinates,
  fuelListing: FuelListing,
): number {
  if (!location?.latitude || !location?.longitude) {
    return 0;
  }

  const coordinatesA = { latitude: location.latitude, longitude: location.longitude };
  const coordinatesB = {
    latitude: fuelListing.attributes.location.coordinates.latitude,
    longitude: fuelListing.attributes.location.coordinates.longitude,
  };
  const computedDistanceMeters = haversine(coordinatesA, coordinatesB);

  const computedDistanceMiles = computedDistanceMeters / METERS_PER_MILE;
  return computedDistanceMiles;
}

export function getValidDiscountedPrice(price: FuelPrice | undefined): number | null {
  if (!price) {
    return null;
  }
  if (price.attributes.fuel_sign_time != null && price.attributes.fuel_sign_time != '') {
    const isValid = getIsValidPrice(price);
    if (isValid) {
      return price.attributes.effective_cost_per_gallon_cents;
    }
  }
  return null;
}

export const sortDiscountedPrice = (
  selectedGrade: FuelGrade | 'TRUCK DIESEL',
): ((a: FuelListing, b: FuelListing) => number) => {
  return (a: FuelListing, b: FuelListing) => {
    const fuelPriceAObj = (a.attributes.fuel_prices.data ?? []).find(
      (fuelPrice: FuelPrice) => fuelPrice.attributes.fuel_grade === selectedGrade,
    );
    const fuelPriceBObj = (b.attributes.fuel_prices.data ?? []).find(
      (fuelPrice: FuelPrice) => fuelPrice.attributes.fuel_grade === selectedGrade,
    );

    const fuelPriceA =
      a.attributes.discount_type == 'RETAIL_MINUS'
        ? getValidDiscountedPrice(fuelPriceAObj)
        : fuelPriceAObj?.attributes.effective_cost_per_gallon_cents;
    const fuelPriceB =
      b.attributes.discount_type == 'RETAIL_MINUS'
        ? getValidDiscountedPrice(fuelPriceBObj)
        : fuelPriceBObj?.attributes.effective_cost_per_gallon_cents;

    if (fuelPriceA && !fuelPriceB) {
      return -1;
    }

    if (fuelPriceB && !fuelPriceA) {
      return 1;
    }

    if (!fuelPriceA || !fuelPriceB) {
      return 0;
    } else if (fuelPriceA < fuelPriceB) {
      return -1;
    } else if (fuelPriceA > fuelPriceB) {
      return 1;
    } else {
      return 0;
    }
  };
};

export const sortDistance = (location: Coordinates) => {
  return (a: FuelListing, b: FuelListing) => {
    const distanceA = getDistanceFromFuelListing(location, a);
    const distanceB = getDistanceFromFuelListing(location, b);

    // If only one station has a distance, prefer that one
    if (distanceA && !distanceB) {
      return -1;
    }

    if (distanceB && !distanceA) {
      return 1;
    }

    if (!distanceA || !distanceB) {
      return 0;
    } else if (distanceA < distanceB) {
      return -1;
    } else if (distanceA > distanceB) {
      return 1;
    } else {
      return 0;
    }
  };
};

export const sortRecommended = (fuelData: FuelListing[], selectedGrade: FuelGrade) => {
  const groups = groupBy(fuelData, (data) => {
    const { name } = data.attributes.location;
    if (
      [
        'travelcenters of america',
        'petro stopping center',
        'petro stopping centers',
        'ta express',
      ].includes(name.toLowerCase())
    ) {
      return 'ta';
    }

    if (['7-eleven', 'speedway'].includes(name.toLowerCase())) {
      return '711';
    }

    return 'other';
  });

  const groupedAndSorted = mapValues(groups, (group) =>
    orderBy(group, (data) => {
      return findFuelPriceByFuelGrade(data, selectedGrade)?.attributes
        .effective_cost_per_gallon_cents;
    }),
  );

  const [moveToBottom, leftInPlace] = partition(groupedAndSorted['other'] || [], (data) => {
    const fuelPrice = findFuelPriceByFuelGrade(data, selectedGrade);

    if (!fuelPrice) {
      return true;
    }

    return (
      (fuelPrice?.attributes.discount_type === 'RETAIL_MINUS' ||
        fuelPrice?.attributes.discount_per_gallon_cents === 0) &&
      !getIsValidPrice(fuelPrice)
    );
  });

  return [
    ...(groupedAndSorted['ta'] || []),
    ...(groupedAndSorted['711'] || []),
    ...leftInPlace,
    ...moveToBottom,
  ];
};

export const getIsValidPrice = (fuelPrice: FuelPrice | undefined) => {
  return (
    fuelPrice != null && dayjs().subtract(24, 'hour').isBefore(fuelPrice.attributes.fuel_sign_time)
  );
};

export const findFuelPriceByFuelGrade = (fuelData: FuelListing, fuelGrade: FuelGrade) => {
  return (fuelData.attributes.fuel_prices.data ?? []).find(
    (fuelPrice) => fuelPrice.attributes.fuel_grade === fuelGrade,
  );
};

export function bestFuelListingsWithRadius(
  fuelListings: FuelListing[],
  radius: number,
  selectedGrade: FuelGrade | 'TRUCK DIESEL',
  origin: Coordinates,
): FuelListing[] {
  // Sort fuel listings by price
  fuelListings.sort((a, b) => sortDiscountedPrice(selectedGrade)(a, b));

  const bestFuelListings: FuelListing[] = [];
  for (const fuelListing of fuelListings) {
    // Check if fuel listing is within the radius of any of the best listings
    const isWithinRadius = bestFuelListings.some(
      (bestListing) =>
        haversine(
          getCoordinatesFromFuelListing(fuelListing),
          getCoordinatesFromFuelListing(bestListing),
        ) <= radius,
    );

    // If the fuel listing is not within the radius of any of the best listings,
    // add it to the best listings array
    if (!isWithinRadius) {
      bestFuelListings.push(fuelListing);
    }
  }

  return bestFuelListings.sort(sortDistance(origin));
}

export function getCoordinatesFromFuelListing(fuelListing: FuelListing): Coordinates {
  return {
    latitude: fuelListing.attributes.location.coordinates.latitude,
    longitude: fuelListing.attributes.location.coordinates.longitude,
  };
}

export function getRadiusFromDistance(distance: number): number {
  const distanceInMiles = distance / METERS_PER_MILE;

  let desiredAmountOfResults = 5;

  if (distanceInMiles >= 500) {
    desiredAmountOfResults = 15;
  } else if (distanceInMiles >= 120) {
    desiredAmountOfResults = 10;
  }

  // this will return the closest multiple of 5 to the desired radius in miles. The computation is done in meters
  // but we show that value in miles to the user. The max radius is 200 miles.
  return (
    Math.min(Math.ceil(distanceInMiles / desiredAmountOfResults / 5) * 5, 200) * METERS_PER_MILE
  );
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    'xxs': true;
    'xs': true;
    'sm': true;
    'md': true;
    'lg': true;
    'xl': true;
    '2xl': true;
  }
}

export const fuelListingsTheme = createTheme({
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          'textTransform': 'none',
          'boxShadow': 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },
      defaultProps: {
        variant: 'default',
        color: 'secondary',
        size: 'medium',
      },
    },
    MuiLoadingButton: {
      defaultProps: {
        variant: 'default',
        color: 'secondary',
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '0.75rem',
          lineHeight: '1rem',
          fontWeight: '500',
          fontFamily: 'inherit',
          borderRadius: '0.25rem',
          color: 'rgba(0, 0, 0, 0.60)',
          backgroundColor: '#FFF',
          padding: '8px 12px',
          boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25);',
        },
        tooltipPlacementBottom: {
          marginTop: '8px !important',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#182029',
    },
    secondary: {
      main: '#e4e3e4',
      dark: '#d9dad9',
    },
    warning: {
      main: '#FDF0E6',
      contrastText: '#5F2B01',
    },
    info: {
      main: '#2563EB',
    },
    error: {
      main: '#FEF2F2',
      contrastText: '#DC2626',
    },
    success: {
      main: '#E6FDE6',
      contrastText: '#014F01',
    },
  },
  typography: {
    fontFamily: 'inherit',
    fontSize: 14,
  },
  breakpoints: {
    // Keep in sync with tailwind.config
    values: {
      'xxs': 0,
      'xs': 375,
      'sm': 475,
      'md': 640,
      'lg': 768,
      'xl': 1024,
      '2xl': 1280,
    },
  },
});
