import Modal, { ModalBodyContent, ModalHeader } from '@atob-developers/shared/src/components/Modal';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import { Formik } from 'formik';
import { capitalize } from 'lodash-es';
import { mutate } from 'swr';
import * as Yup from 'yup';
import { addInstantPayoutMethodAccount } from '../../utils';

const AddInstantPayoutMethodAccountModal = ({
  open,
  reset,
  type,
  instantPayoutRecipientId,
}: {
  open: boolean;
  reset: () => void;
  type: 'zelle' | 'paypal' | 'venmo';
  instantPayoutRecipientId?: string;
}) => {
  const { addToast } = useToasts();

  const initialValues = {
    email: '',
    nickname: '',
  };

  const displayType = type === 'paypal' ? 'PayPal' : capitalize(type);

  const validationSchema = Yup.object({
    email: Yup.string().required(`${displayType} user ID is required`),
  });

  const handleSubmit = async ({ email, nickname }: { email: string; nickname: string }) => {
    try {
      if (instantPayoutRecipientId) {
        await addInstantPayoutMethodAccount({
          username: email,
          nickname,
          type,
          username_type: 'email',
          instant_payout_recipient_id: instantPayoutRecipientId,
        });
        await mutate({
          url: `/instant_payout/recipients/${instantPayoutRecipientId}?include=treasury_recipients,instant_payout_methods`,
        });
      }
      reset();
    } catch (e) {
      addToast({ type: 'error', title: `Failed to add ${displayType} account` });
    }
  };

  return (
    <Modal open={open} toggle={reset}>
      <ModalHeader title={`Add ${displayType} account`} onClose={reset} />
      <ModalBodyContent>
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          {({ values, isSubmitting, submitForm, handleChange, handleBlur, touched, errors }) => (
            <div className="mb-4 flex flex-col gap-6">
              <p className="text-sm">
                Please ensure the {displayType} account has already been created before adding to a
                recipient
              </p>
              <TextField
                label={`${displayType} user ID (phone number or e-mail)`}
                placeholder={`${displayType} user ID`}
                name="email"
                value={values?.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && !!errors?.email}
                helperText={touched.email && errors?.email}
              />
              <TextField
                label={
                  <div>
                    Nickname <span className="text-default-secondary text-sm">(optional)</span>
                  </div>
                }
                name="nickname"
                placeholder={`${displayType} user nickname`}
                value={values?.nickname}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <LoadingButton
                color="primary"
                fullWidth
                loading={isSubmitting}
                onClick={submitForm}
                startIcon={<FontAwesomeIcon icon={faPlus} />}
              >
                <span>Add {displayType} account</span>
              </LoadingButton>
            </div>
          )}
        </Formik>
      </ModalBodyContent>
    </Modal>
  );
};

export default AddInstantPayoutMethodAccountModal;
