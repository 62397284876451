import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import { IftaFilingStage } from '@app/constants/iftaFiling';
import { useGetUploadedFiles } from '@app/hooks/query/useIftaFilingQuery';
import { Button } from '@mui/material';
import { CSSProperties, ReactElement, useState } from 'react';
import { DropzoneRootProps } from 'react-dropzone/.';
import { formatFileSize, useCSVReader } from 'react-papaparse';
import { useNavigate } from 'react-router-dom';

type UploadIftaReportInterface = {
  handleFileUpload: (file: File, results: Array<Array<string>>) => void;
};

export const GREY = '#CCC';
export const GREY_LIGHT = 'rgba(255, 255, 255, 0.4)';
export const GREY_DIM = '#686868';

export const styles = {
  zone: {
    alignItems: 'center',
    border: `2px dashed ${GREY}`,
    borderRadius: 20,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    padding: 20,
  } as CSSProperties,
  file: {
    background: 'linear-gradient(to bottom, #EEE, #DDD)',
    borderRadius: 20,
    display: 'flex',
    height: 120,
    width: 120,
    position: 'relative',
    zIndex: 10,
    flexDirection: 'column',
    justifyContent: 'center',
  } as CSSProperties,
  info: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    paddingRight: 10,
  } as CSSProperties,
  size: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    marginBottom: '0.5em',
    justifyContent: 'center',
    display: 'flex',
  } as CSSProperties,
  name: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    fontSize: 12,
    marginBottom: '0.5em',
  } as CSSProperties,
  progressBar: {
    bottom: 14,
    position: 'absolute',
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
  } as CSSProperties,
  zoneHover: {
    borderColor: GREY_DIM,
  } as CSSProperties,
  default: {
    borderColor: GREY,
  } as CSSProperties,
  remove: {
    height: 23,
    position: 'absolute',
    right: 6,
    top: 6,
    width: 23,
  } as CSSProperties,
};
export interface UploadHandlerProps {
  getRootProps: () => DropzoneRootProps;
  acceptedFile: File | null;
  ProgressBar: React.FC;
}

const UploadIftaReport = ({ handleFileUpload }: UploadIftaReportInterface): ReactElement => {
  const [zoneHover, setZoneHover] = useState(false);
  const { data: files } = useGetUploadedFiles('0');
  const navigate = useNavigate();
  const { CSVReader } = useCSVReader();

  const handleDownload = () => {
    const headers = ['S. No', 'Month/Quarter', 'Gallons', 'State', 'Fuel Type', 'Vehicle', 'year'];
    const data = [[1, 'December', 23, 'TX', 'Diesel', 'vehicle1', 2025]];

    let csvContent = 'data:text/csv;charset=utf-8,';
    csvContent += headers.join(',') + '\n';
    csvContent += data.map((row) => row.join(',')).join('\n');

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'table_data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const onClickSkip = async () => {
    const isUploaded = files && Array.isArray(files.data) && files.data.length == 0;
    navigate(
      isUploaded
        ? `/ifta-filing?stage=${IftaFilingStage.ATOB_REPORTS}`
        : `/ifta-filing?stage=${IftaFilingStage.NON_ATOB_REPORTS}`,
    );
  };
  return (
    <>
      <RoundedCard className="relative flex-1 py-4">
        <div className="flex h-[75vh] items-center justify-center bg-white">
          <div className="rounded-lg bg-white text-center">
            <h2 className="mb-4 text-lg font-semibold text-gray-800">
              Upload IFTA Report from other Fuel Cards
            </h2>

            <CSVReader
              onUploadAccepted={(
                results: {
                  data: Array<Array<string>>;
                },
                file: File,
              ) => {
                setZoneHover(false);
                handleFileUpload(file, results.data);
              }}
              onDragOver={(event: DragEvent) => {
                event.preventDefault();
                setZoneHover(true);
              }}
              onDragLeave={(event: DragEvent) => {
                event.preventDefault();
                setZoneHover(false);
              }}
            >
              {({ getRootProps, acceptedFile, ProgressBar }: UploadHandlerProps) => (
                <>
                  <div
                    {...getRootProps()}
                    className="cursor-pointer"
                    style={Object.assign({}, styles.zone, zoneHover && styles.zoneHover)}
                  >
                    {acceptedFile ? (
                      <>
                        <div style={styles.file}>
                          <div style={styles.info}>
                            <span style={styles.size}>{formatFileSize(acceptedFile.size)}</span>
                            <span style={styles.name}>{acceptedFile.name}</span>
                          </div>
                          <div style={styles.progressBar}>
                            <ProgressBar />
                          </div>
                        </div>
                      </>
                    ) : (
                      'Drop CSV file here or click to upload'
                    )}
                  </div>
                </>
              )}
            </CSVReader>

            <div
              onClick={handleDownload}
              className="mt-4 block cursor-pointer text-sm text-blue-500"
            >
              Download the format
            </div>

            <Button
              className="mt-4 w-full px-6 py-2 sm:w-auto"
              size="small"
              onClick={onClickSkip}
              data-testid={'start-ifta-filing-button'}
            >
              Skip
            </Button>
          </div>
        </div>
      </RoundedCard>
    </>
  );
};

export default UploadIftaReport;
