import { ChargeEventData } from '@app/@types/charge_events.types';
import RoundedCardCore from '@app/components/RoundedCard/RoundedCardCore';
import { panMapToBounds } from '@app/utils/Telematics/TelematicsMapsUtils';
import { getEnvironment } from '@app/utils/environment';
import { faCheck, faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip, Tooltip } from '@mui/material';
import classNames from 'classnames';
import GoogleMap from 'google-maps-react-markers';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import {
  MerchantAndVehicleMarker,
  MerchantMarker,
  VehicleMarker,
} from '../../components/Maps/MapMarkers';
import NoDataAvailable from './NoDataAvailable';
import { relativeTimestamp } from './utils';

const TOOLTIP_TEXT =
  'Vehicle location at the time of the transaction is compared to merchant location to verify legitimacy of the transaction. ' +
  'If the vehicle is not within a reasonable distance of the merchant (factoring in speed and direction of travel), ' +
  'the transaction will be flagged as suspicious.';

const ChargeEventVehicleLocation = ({
  chargeEvent,
}: {
  chargeEvent: ChargeEventData;
}): ReactElement => {
  const merchantPos = useMemo(
    () =>
      chargeEvent?.merchant_location && {
        lat: chargeEvent.merchant_location?.latitude,
        lng: chargeEvent.merchant_location?.longitude,
      },
    [chargeEvent],
  );

  const vehiclePos = useMemo(
    () =>
      chargeEvent?.vehicle_location && {
        lat: chargeEvent.vehicle_location.latitude,
        lng: chargeEvent.vehicle_location.longitude,
      },
    [chargeEvent],
  );

  const { VITE_GOOGLE_API_KEY, VITE_MAP_ID_FRAUD_HIGHLIGHT } = getEnvironment();

  const [googleMap, setGoogleMap] = useState<google.maps.Map | null>(null);
  const [mapsApi, setMapsApi] = useState<typeof google.maps | null>(null);

  useEffect(() => {
    if (chargeEvent && googleMap && mapsApi && !!merchantPos && !!vehiclePos) {
      panMapToBounds(googleMap, mapsApi, [merchantPos, vehiclePos].filter(Boolean));
    }
  }, [chargeEvent, googleMap, mapsApi, merchantPos, vehiclePos]);

  const telematicsDataAvailable = chargeEvent?.vehicle_location;

  const isDataAvailable = chargeEvent.merchant_location && merchantPos && telematicsDataAvailable;

  const suspicious = telematicsDataAvailable && chargeEvent.vehicle_location?.suspicious_location;

  const distance = chargeEvent?.vehicle_location?.distance_from_merchant_miles;
  const distanceAvailable = distance !== null && distance !== undefined;
  const distanceDisplay =
    distanceAvailable && (distance < 0.1 ? 'Less than 0.1 miles' : `${distance.toFixed(1)} miles`);

  const markers = useMemo(() => {
    const queue = [];
    if (merchantPos && distanceAvailable && distance < 0.1) {
      queue.push(<MerchantAndVehicleMarker key="0" lat={merchantPos.lat} lng={merchantPos.lng} />);
    } else {
      if (merchantPos) {
        queue.push(<MerchantMarker key="1" lat={merchantPos.lat} lng={merchantPos.lng} />);
      }

      if (vehiclePos) {
        queue.push(<VehicleMarker key="2" lat={vehiclePos.lat} lng={vehiclePos.lng} />);
      }
    }

    return queue;
  }, [distance, distanceAvailable, merchantPos, vehiclePos]);

  return (
    <RoundedCardCore className="border-soft border-b lg:w-1/2 lg:border-b-0 lg:border-r">
      <div className="flex w-full flex-col gap-2">
        <div className="flex w-full items-center justify-between gap-1">
          <div className="flex items-center gap-1">
            <span className="text-default-secondary text-xs leading-5 md:text-sm">
              Vehicle location check
            </span>
            <Tooltip title={TOOLTIP_TEXT}>
              <FontAwesomeIcon icon={faInfoCircle} className="text-default-secondary h-4 w-4" />
            </Tooltip>
          </div>
          {isDataAvailable && (
            <Chip
              size="small"
              color={suspicious ? 'orange' : 'green'}
              label={suspicious ? 'Suspicious' : 'Passed'}
              icon={
                <FontAwesomeIcon icon={suspicious ? faInfoCircle : faCheck} className="h-3 w-3" />
              }
            />
          )}
        </div>
        {isDataAvailable && (
          <span className="text-default-primary text-xs font-medium leading-5 md:text-sm">
            {`${distanceDisplay} from the merchant, ${
              chargeEvent.vehicle_location?.captured_at &&
              relativeTimestamp(chargeEvent.vehicle_location.captured_at, chargeEvent.authorized_at)
            }`}
          </span>
        )}
      </div>
      <div className="relative h-40 w-full overflow-hidden lg:h-full">
        <div
          className={classNames(
            'border-soft h-full w-full overflow-hidden rounded-md border',
            !isDataAvailable && 'blur-[6px]',
          )}
        >
          <GoogleMap
            apiKey={String(VITE_GOOGLE_API_KEY)}
            defaultCenter={merchantPos ?? { lat: 0, lng: 0 }}
            defaultZoom={1}
            onGoogleApiLoaded={({
              map,
              maps,
            }: {
              map: google.maps.Map;
              maps: typeof google.maps;
            }) => {
              setGoogleMap(map);
              setMapsApi(maps);
            }}
            options={{
              zoomControlOptions: {
                position: mapsApi?.ControlPosition.RIGHT_CENTER || 0,
              },
              mapId: String(VITE_MAP_ID_FRAUD_HIGHLIGHT),
              disableDefaultUI: true,
              scaleControl: true,
              zoomControl: true,
              fullscreenControl: true,
              clickableIcons: false,
            }}
          >
            {markers}
          </GoogleMap>
        </div>
        {!isDataAvailable && <NoDataAvailable />}
      </div>
    </RoundedCardCore>
  );
};

export default ChargeEventVehicleLocation;
