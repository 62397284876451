import { TagBadgeData } from '@app/@types/tag.types';
import { BlankPlaceholder } from '@atob-developers/shared/src/components/Table';
import { Chip } from '@mui/material';
import classNames from 'classnames';
import { ReactElement } from 'react';

interface TagBadgeProps {
  tag?: TagBadgeData;
  truncate?: boolean;
}

const TagBadge = ({ tag, truncate = true }: TagBadgeProps): ReactElement => {
  // Tag data passed can be the tag itself shown in the tags table or
  // the "resource tag" which is a tag attached to a resource and shown in resource tables.
  const tagId = tag?.resource_tag_id || tag?.tag_id;

  return (
    <div className="xs:mb-0 mb-4 flex flex-wrap items-center justify-start overflow-hidden">
      {tag ? (
        <Chip
          key={`badge-tag-${tagId}`}
          label={tag.name}
          color="green"
          className={classNames('relative ml-1 mr-1', {
            'overflow-hidden text-ellipsis whitespace-nowrap': truncate,
          })}
        />
      ) : (
        <BlankPlaceholder />
      )}
    </div>
  );
};

export default TagBadge;
