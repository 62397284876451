export const cssWithWidth = (width: string) => ({
  'background': '#FFFFFF',
  'border': '1px solid #E2E4E9',
  'boxSizing': 'border-box',
  'borderRadius': '4px',
  'height': '48px',
  'padding': '12px 16px',
  'fontSize': '16px',
  width,
  '&::placeholder': {
    color: '#566076',
  },
  '&:hover': {
    border: '2px solid #D1D4DBE9',
    padding: '11px 15px',
  },
  '&:focus': {
    border: '2px solid black',
    padding: '11px 15px',
  },
  '&.invalid.touched': {
    border: '2px solid #E05353',
    padding: '11px 15px',
  },
});
