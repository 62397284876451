import { CustomerData } from '@app/@types/customer.types';
import { PrimaryPaymentMethod } from '@app/components/PaymentModal/@types/paymentMethod.types';
import ApiEndpoints from '@app/utils/data/apiEndpoints';
import { getFetcher } from '@app/utils/data/fetchers';
import Modal, {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import useSWR, { useSWRConfig } from 'swr';
import { AmountDueProps } from '../PaymentModalWithEstimatesController/amountDueConverter';
import PaymentModalWithEstimatesWrapper from './PaymentModalWithEstimatesWrapper';

interface PaymentModalWithEstimatesProps {
  isModalActive: boolean;
  customer: CustomerData;
  upcomingPayment: AmountDueProps['upcomingPayment'];
  walletAvailable: boolean;
  closeModal: () => void;
}

const PaymentModalWithEstimates = ({
  isModalActive,
  customer,
  upcomingPayment,
  walletAvailable = false,
  closeModal,
}: PaymentModalWithEstimatesProps): ReactElement => {
  const {
    error: primaryPaymentMethodError,
    data: primaryPaymentMethodData,
    isLoading: isLoadingPrimaryPaymentMethod,
  } = useSWR<PrimaryPaymentMethod>(
    ApiEndpoints.PAYMENTS_ENDPOINTS.PRIMARY_PAYMENT_METHOD_ENDPOINT,
    getFetcher,
  );

  const { mutate } = useSWRConfig();

  const closeModalInternal = () => {
    // We used to refresh the page here, but instead now we
    // force everything to revalidate to get the latest data.
    // Like a refresh, but better.
    mutate(
      (_) => true, // which cache keys are updated
      undefined, // update cache data to `undefined`
      { revalidate: true }, // revalidate
    );
    closeModal();
  };

  const noPrimaryPaymentMethod =
    primaryPaymentMethodData == null || primaryPaymentMethodData?.data == null;

  if (!isLoadingPrimaryPaymentMethod && noPrimaryPaymentMethod) {
    return (
      <Modal open={isModalActive} toggle={closeModalInternal}>
        <ModalHeader title="Pay Now" onClose={closeModalInternal} />
        <ModalBodyContent>
          <div className="text-default-primary mb-2 text-lg font-medium">
            No primary payment method found.
          </div>
          <div className="text-default-primary text-base font-medium">
            Please select one of your{' '}
            <Link to="/billing/payment-methods" className="text-green underline">
              payment methods
            </Link>{' '}
            as primary.
          </div>
        </ModalBodyContent>
        <ModalFooter />
      </Modal>
    );
  }

  return (
    <Modal open={isModalActive} toggle={closeModalInternal}>
      <ModalHeader title="Pay Now" onClose={closeModalInternal} />
      <PaymentModalWithEstimatesWrapper
        isLoading={isLoadingPrimaryPaymentMethod}
        hasError={primaryPaymentMethodError != null}
        closeModal={closeModalInternal}
        customer={customer}
        upcomingPayment={upcomingPayment}
        walletAvailable={walletAvailable}
        primaryPaymentMethod={primaryPaymentMethodData}
      />
    </Modal>
  );
};

export default PaymentModalWithEstimates;
