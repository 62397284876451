import { PaymentMethodCombined } from '@app/@types/payments.types';
import useWindowWidth from '@app/hooks/useWindowWidth';
import { RadioItem } from '@atob-developers/shared/src/components/RadioButton';
import { formatCurrency } from '@atob-developers/shared/src/utils/formatters';
import { faCreditCardFront } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import classNames from 'classnames';
import { ReactElement } from 'react';

const DebitOption = ({
  isDebitSelected,
  setDebitSelected,
  paymentSourceDebit,
  debitDescription,
  lastFourDebit,
  feesInfo,
}: {
  isDebitSelected: boolean;
  setDebitSelected: (_: boolean) => void;
  paymentSourceDebit: PaymentMethodCombined | null;
  debitDescription: string | null;
  lastFourDebit: string | null;
  feesInfo: { amount_in_cents: number };
}): ReactElement => {
  const { isMobile } = useWindowWidth();
  return (
    <div
      className={classNames(
        'mb-2 flex items-center gap-x-4 rounded-lg p-4',
        isDebitSelected && 'bg-green-secondary',
      )}
    >
      <RadioItem
        id="payroll_type_debit"
        name="payroll_type_debit"
        label=""
        value="payroll_type_debit"
        checked={isDebitSelected}
        setChecked={() => setDebitSelected(true)}
      />
      <div className="w-full">
        <div className="md:flex md:justify-between">
          {isMobile && (
            <div className="bg-green-primary text-contrast-primary mb-2 inline-flex items-center rounded-full px-2.5 py-1 text-sm font-bold">
              Driver Preferred
            </div>
          )}
          <div className="mb-2 flex items-center">
            <FontAwesomeIcon
              icon={faCreditCardFront}
              className="fa-light text-default-primary mr-1 p-1"
            />
            <span className="text-default-primary text-lg font-bold">Instant Pay</span>
          </div>
          {!isMobile && (
            <div className="bg-green-primary text-contrast-primary mb-2 inline-flex items-center rounded-full px-2.5 py-1 text-sm font-bold">
              Driver Preferred
            </div>
          )}
        </div>
        {paymentSourceDebit ? (
          <>
            <div className="text-default-primary mb-2 text-lg">Should arrive in 30 minutes</div>
            <div className="text-default-primary mb-2 text-lg">
              {debitDescription} ending in {lastFourDebit}
            </div>
            <div className="text-default-primary text-base">
              {formatCurrency({
                value: feesInfo.amount_in_cents,
                options: { fromCents: true },
              })}{' '}
              fee
            </div>
          </>
        ) : (
          <div className="text-default-primary text-base">
            No debit card connected - go back to
            <span className="font-semibold"> Payroll Overview</span> to add a debit card
          </div>
        )}
      </div>
    </div>
  );
};
const PaymentSpeedFooter = ({
  isContinueButtonDisabled,
  resetPage,
  handleClick,
}: {
  isContinueButtonDisabled: boolean;
  resetPage: () => void;
  handleClick: () => void;
}): ReactElement => {
  return (
    <footer className="flex w-full items-center justify-end pb-8">
      <div className="flex max-w-[350px] gap-2">
        <Button color="secondary" size="medium" onClick={resetPage}>
          Cancel
        </Button>
        <Button size="medium" disabled={isContinueButtonDisabled} onClick={handleClick}>
          Continue
        </Button>
      </div>
    </footer>
  );
};

export { DebitOption, PaymentSpeedFooter };
