import { ThemeClassName } from '@atob-developers/shared/src/constants/theme';
import { useMemo } from 'react';
import useCustomer from './useCustomer';

export default function useThemeMode() {
  const customer = useCustomer();

  const [lightClassName, darkClassName] = useMemo(() => {
    const themeName = customer.channel_partner?.theme_name;
    if (themeName === 'ufgrayscale') {
      return [ThemeClassName.UBER_LIGHT, ThemeClassName.UBER_DARK];
    }

    return [ThemeClassName.LIGHT, ThemeClassName.DARK];
  }, [customer]);

  const isDarkThemeOn = useMemo(() => {
    return (
      document.body.classList.contains(ThemeClassName.DARK) ||
      document.body.classList.contains(ThemeClassName.UBER_DARK)
    );
  }, []);

  return { lightClassName, darkClassName, isDarkThemeOn };
}
