import { Alert as MuiAlert, AlertTitle, SnackbarCloseReason } from '@mui/material';

import classNames from 'classnames';
import { ReactNode } from 'react';

export default function Alert({
  type,
  title,
  description,
  actionText,
  action,
  secondaryActionText,
  secondaryAction,
  onClose,
  className,
  icon,
  variant = 'standard',
}: {
  type: 'success' | 'error' | 'info' | 'warning';
  title?: string | ReactNode;
  description?: string | ReactNode;
  actionText?: string | ReactNode;
  action?: () => void;
  secondaryActionText?: string | ReactNode;
  secondaryAction?: () => void;
  onClose?: (_: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => void;
  className?: string;
  icon?: ReactNode;
  variant?: 'standard' | 'filled';
}) {
  return (
    <MuiAlert severity={type} onClose={onClose} className={className} icon={icon} variant={variant}>
      <AlertTitle>{title}</AlertTitle>
      {description}
      {(actionText || secondaryActionText) && (
        <div
          className={classNames(
            'flex gap-3 pt-2',
            variant === 'filled' ? 'text-contrast-primary' : 'text-default-primary',
          )}
        >
          <button className="font-medium underline" onClick={action}>
            {actionText}
          </button>
          {secondaryActionText && (
            <button className="font-medium underline" onClick={secondaryAction}>
              {secondaryActionText}
            </button>
          )}
        </div>
      )}
    </MuiAlert>
  );
}
