import { Button } from '@mui/material';
import { ReactElement } from 'react';

const NoPaymentMethods = ({ onBack }: { onBack: () => void }): ReactElement => {
  return (
    <div className="p-6 text-center">
      <p className="text-default-secondary mb-2 text-left text-base">
        You have not selected a bank account. Go back to the previous screen and select a payment
        method to continue.
      </p>
      <Button size="small" onClick={onBack} data-testid="ModalFooter-Cancel-Button">
        Back
      </Button>
    </div>
  );
};

export default NoPaymentMethods;
