import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function PaidFeeCard() {
  return (
    <div className="bg-soft-primary border-soft flex w-full flex-col items-center gap-6 rounded-2xl border p-8">
      <div className="flex flex-col items-center gap-4">
        <div className="bg-green-secondary flex items-center justify-center rounded-full p-3">
          <FontAwesomeIcon icon={faCheck} className="text-green h-6 w-6" />
        </div>
        <div className="flex flex-col items-center gap-1">
          <p className="text-default-primary text-center font-semibold md:text-lg md:leading-6">
            Set-up fee has been paid
          </p>
          <p className="text-default-secondary text-center text-sm leading-6 md:text-base">
            Your account is now activated and your cards have been shipped.
          </p>
        </div>
      </div>
    </div>
  );
}
