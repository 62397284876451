import PhoneInput from '@app/components/Inputs/PhoneInput';
import { getPhoneWithoutDialingCode } from '@atob-developers/shared/src/utils/formatters/phoneFormat';
import { faCheck, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import { Formik, useFormikContext } from 'formik';
import { ReactElement } from 'react';
import * as Yup from 'yup';

export type Recipient = {
  name: string;
  phone: string | null | undefined;
  email: string | null | undefined;
};

export const basicInformationValidationSchema: Yup.ObjectSchema<Recipient> = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().nullable().email('Email address is not valid'),
  phone: Yup.string()
    .nullable()
    .test('len', 'Phone number is not valid', (val) => {
      if (val == null || val == '') {
        return true;
      }
      const number = getPhoneWithoutDialingCode(val);
      return number?.length === 10;
    }),
});

type RecipientFormProps = {
  type: 'add' | 'edit';
  onSubmit: (values: Recipient) => void;
  initialValues: Recipient;
};

const BasicInformationForm = () => {
  const { values, errors, handleChange, handleBlur, touched, setFieldValue } =
    useFormikContext<Recipient>();

  return (
    <>
      <TextField
        name="name"
        value={values?.name}
        label="Legal Name"
        placeholder="Monica Johnson"
        onChange={handleChange}
        required
        onBlur={handleBlur}
        error={touched.name && !!errors?.name}
        helperText={touched.name && errors?.name}
        slotProps={{
          input: {
            inputProps: {
              'data-testid': 'input:name',
            },
          },
        }}
      />
      <TextField
        label="Email"
        id="email"
        value={values?.email}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.email && !!errors?.email}
        helperText={touched.email && errors?.email}
      />
      <PhoneInput
        id="phone"
        label="Phone"
        value={values?.phone}
        onChange={(e) => {
          setFieldValue('phone', getPhoneWithoutDialingCode(e.target.value));
        }}
        onBlur={(e) => {
          handleBlur(e);
        }}
        error={touched.phone && !!errors?.phone}
        helperText={touched.phone && errors?.phone}
      />
    </>
  );
};

export default function RecipientForm({
  type,
  initialValues,
  onSubmit,
}: RecipientFormProps): ReactElement {
  return (
    <Formik
      initialValues={initialValues}
      validateOnMount
      validationSchema={basicInformationValidationSchema}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ isSubmitting, submitForm, isValid, touched }) => (
        <>
          <div className="flex flex-col gap-4 pt-4">
            <BasicInformationForm />
            <div className="mb-4 mt-4">
              <LoadingButton
                size="medium"
                loading={isSubmitting}
                disabled={!isValid || !touched}
                onClick={submitForm}
                startIcon={<FontAwesomeIcon icon={type === 'add' ? faPlus : faCheck} />}
                fullWidth
              >
                {type === 'add' ? 'Add Recipient' : 'Save Changes'}
              </LoadingButton>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
}
