import { PaginationMeta } from '@app/@types/api.types';
import { ErrorNotification } from '@app/components/layout';
import { ReactElement } from 'react';
import FundingRequestsTable from './FundingRequestsTable';
import type { FundingRequest } from './Funding';

const defaultMetadata: PaginationMeta = {
  pagination: {
    total_pages: 1,
    current_page: 1,
    total_count: 1,
  },
};

interface FundingRequestsProps {
  fundingRequestsPage: FundingRequest[];
  fundingRequestsError: unknown;
  metadata: PaginationMeta | undefined;
  goToPage: (page: number) => void;
  onRevertRequest: (id: number) => Promise<string | null>;
}

export default function FundingRequests({
  fundingRequestsPage,
  fundingRequestsError,
  metadata,
  goToPage,
  onRevertRequest,
}: FundingRequestsProps): ReactElement {
  if (fundingRequestsError) {
    return (
      <ErrorNotification error="We're having issues loading your funding request data. Please try again; if the issue persists, please contact support." />
    );
  }

  if (fundingRequestsPage.length === 0) {
    return <NoFundingRequests />;
  }

  return (
    <div>
      <FundingRequestsTable
        fundingRequestsPage={fundingRequestsPage}
        metadata={metadata || defaultMetadata}
        onPageIndexChange={goToPage}
        onRevertRequest={onRevertRequest}
      />
    </div>
  );
}

const NoFundingRequests = (): ReactElement => {
  return (
    <div className="flex h-64 w-full flex-col items-center justify-center">
      <img className="w-24 md:w-40" src="/card.svg" alt="Card" />
      <div className="mt-4 flex max-w-[300px] flex-col items-center text-center">
        <h2 className="text-default-secondary text-lg font-semibold">No Funding Requests found.</h2>
        <p className="text-default-primary text-sm">
          Funding Requests will display here when you start funding carriers.
        </p>
      </div>
    </div>
  );
};
