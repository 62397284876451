import { TileContainer } from '@app/components/OverviewTiles/Tile';
import TilesContainer from '@app/components/OverviewTiles/TilesContainer';
import useProduct from '@app/hooks/useProduct';
import { faArrowUp, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { ReactElement, useState } from 'react';
import AccountInfoModal from './WalletOverview/AccountInfoModal';
import { SecurityDepositDetails } from './WalletOverview/SecurityDepositDetails';
import AddFunds from './WalletOverview/TransferFunds/AddFunds';
import { WithdrawFundsSidebarNew } from './WalletOverview/TransferFunds/WithdrawFundsV2/WithdrawFundsSidebarNew';
import WalletBalance from './WalletOverview/WalletBalance';
import { WalletWelcomePopup } from './WalletWelcomePopup';
import type { AddressType, TreasuryFinancialAccount } from '@app/@types/customer.types';

type WalletOverviewProps = {
  financialAccount?: TreasuryFinancialAccount | null;
};

export const AccountInfoLink = ({
  financialAccount,
}: {
  financialAccount?: TreasuryFinancialAccount | null;
}): ReactElement => {
  const [showAccountInfoModal, setShowAccountInfoModal] = useState(false);
  const [treasuryTrusted, walletLite] = useProduct('treasury_trusted', 'wallet_lite');

  const modalToggle = () => {
    setShowAccountInfoModal(!showAccountInfoModal);
  };

  return (
    <>
      {treasuryTrusted && !walletLite && (
        <div
          onClick={modalToggle}
          className="text-default-secondary cursor-pointer whitespace-nowrap text-sm font-medium underline"
        >
          Show account info
        </div>
      )}
      {showAccountInfoModal && (
        <AccountInfoModal
          showAccountInfoModal={showAccountInfoModal}
          modalToggle={modalToggle}
          accountNumber={financialAccount?.account_number}
          routingNumber={financialAccount?.routing_number}
        />
      )}
    </>
  );
};

function Overview({
  financialAccount,
  openWithdrawSidebar,
  customerCompanyName,
  customerCompanyAddress,
  onAddFunds,
}: WalletOverviewProps & { openWithdrawSidebar: () => void } & Pick<
    StatusProps,
    'onAddFunds' | 'customerCompanyName' | 'customerCompanyAddress'
  >) {
  const [hasSecurityDeposit, walletLite, walletTransferFunds] = useProduct(
    'security_deposits',
    'wallet_lite',
    'wallet_transfer_funds',
  );

  const transferFundsButton = (
    <>
      {walletTransferFunds && (
        <div data-tour="wallet_send_and_withdraw" className="w-full">
          <Button
            size="small"
            fullWidth
            onClick={openWithdrawSidebar}
            startIcon={<FontAwesomeIcon icon={faArrowUp} className="text-base" />}
          >
            Send money
          </Button>
        </div>
      )}
    </>
  );

  const addFundsButton = (
    <AddFunds
      onAddFunds={onAddFunds}
      customerCompanyName={customerCompanyName}
      customerCompanyAddress={customerCompanyAddress}
      buttonProps={{
        text: 'Add money',
        startIcon: <FontAwesomeIcon icon={faPlus} className="text-base" />,
        showInMobileHeaderMenu: false,
        size: 'small',
        fullWidth: true,
      }}
    />
  );

  return (
    <TilesContainer
      header={
        <div className="flex items-center justify-between p-4">
          <p className="text-default-primary font-semibold">Balance</p>
          {hasSecurityDeposit && <SecurityDepositDetails />}
        </div>
      }
    >
      <TileContainer className="border-soft border-t">
        <div className="mr-4 flex justify-between">
          <WalletBalance balance={financialAccount?.balance} />
          <div className="self-end">
            <AccountInfoLink financialAccount={financialAccount} />
          </div>
        </div>
        <div className="mt-4 flex flex-col gap-3 md:flex-row">
          {transferFundsButton}
          {!walletLite && addFundsButton}
        </div>
      </TileContainer>
    </TilesContainer>
  );
}

export type StatusProps = {
  accountNumber?: string | null;
  routingNumber?: string | null;
  balance?: string | null;
  onAddFunds: () => void;
  onWithdrawFunds: () => void;
  loading?: boolean;
  customerCompanyName: string;
  customerCompanyAddress: AddressType;
  isTrusted: boolean;
  status:
    | 'enrolled'
    | 'not_enrolled'
    | 'started'
    | 'pending_verification'
    | 'update_required'
    | 'suspended';
};

export default function WalletOverview({
  financialAccount,
  onWithdrawFunds,
  customerCompanyName,
  customerCompanyAddress,
  onAddFunds,
}: WalletOverviewProps &
  Pick<
    StatusProps,
    'loading' | 'onWithdrawFunds' | 'onAddFunds' | 'customerCompanyName' | 'customerCompanyAddress'
  >): ReactElement {
  const [withdrawFundsModalActive, setWithdrawFundsModalActive] = useState(false);

  const toggleWithdrawFundsModal = () => setWithdrawFundsModalActive(!withdrawFundsModalActive);

  return (
    <div className="bg-soft-primary rounded-lg">
      <div className="flex justify-between">
        <Overview
          customerCompanyAddress={customerCompanyAddress}
          customerCompanyName={customerCompanyName}
          openWithdrawSidebar={toggleWithdrawFundsModal}
          onAddFunds={onAddFunds}
          financialAccount={financialAccount}
        />
      </div>
      <WithdrawFundsSidebarNew
        open={withdrawFundsModalActive}
        reset={toggleWithdrawFundsModal}
        onCompletedTransfer={onWithdrawFunds}
        walletBalance={financialAccount?.balance}
      />
      <WalletWelcomePopup />
    </div>
  );
}
