import SidebarHeader from '@app/components/Sidebars/SidebarHeader';
import { Drawer } from '@mui/material';
import { AddFundsForm } from './AddFundsForm';
import useAddFundsSources from './useAddFundsSources';
import useFeeConfigurations from './useFeeConfigurations';
import { PaymentMethodType } from './utils';

const AddFundsSidebar = ({
  open,
  reset,
  onAddFunds,
  minimumDepositAmountCents,
}: {
  open: boolean;
  reset: () => void;
  onAddFunds: () => void;
  minimumDepositAmountCents?: number;
}) => {
  const paymentMethods: PaymentMethodType[] = ['debit', 'ach'];
  const { addFundsSources, loadingAddFundsSources } = useAddFundsSources();
  const { feeConfigurations, loading: loadingFeeConfigurations } = useFeeConfigurations('inbound');

  return (
    <Drawer open={open} onClose={reset} anchor="right">
      <div className="bg-soft-primary flex h-screen w-screen flex-col overflow-y-auto md:max-w-xl">
        <SidebarHeader title="Add money" onClose={reset} />
        <AddFundsForm
          availableMethods={paymentMethods}
          loadingMethods={loadingFeeConfigurations}
          availableSources={addFundsSources}
          loadingSources={loadingAddFundsSources}
          onCompletedTransfer={onAddFunds}
          minimumDepositAmountCents={minimumDepositAmountCents}
          feeConfigurations={feeConfigurations}
        />
      </div>
    </Drawer>
  );
};

export default AddFundsSidebar;
