import { EndpointResponse } from '@app/@types/api.types';
import { InstantPayoutRecipient } from '@app/@types/instant_payout_method.types';
import { apiPostFetcher, apiPutFetcher, FetcherKey } from '@app/utils/data/fetchers';
import { guardAxiosError } from '@app/utils/error/guards';

import Modal, { ModalBodyContent, ModalHeader } from '@atob-developers/shared/src/components/Modal';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { AxiosError } from 'axios';
import { startCase } from 'lodash-es';
import useSWRMutation from 'swr/mutation';
import RecipientForm, { Recipient } from './RecipientForm';

export default function RecipientModal({
  mode,
  currentRecipient,
  onSuccess,
  onClose,
  open,
}: {
  mode: 'edit' | 'add';
  currentRecipient?: InstantPayoutRecipient;
  onSuccess: () => Promise<void>;
  onClose: () => void;
  open: boolean;
}) {
  const { addToast } = useToasts();

  const { trigger: addRecipient } = useSWRMutation<
    EndpointResponse<InstantPayoutRecipient>,
    AxiosError,
    FetcherKey,
    Pick<InstantPayoutRecipient, 'name' | 'nickname' | 'customer_owned' | 'phone' | 'email'>
  >({ url: '/instant_payout/recipients' }, apiPostFetcher, {
    onSuccess: () => onSuccess(),
  });

  const { trigger: editRecipient } = useSWRMutation<
    EndpointResponse<InstantPayoutRecipient>,
    AxiosError,
    FetcherKey,
    Pick<InstantPayoutRecipient, 'name' | 'nickname' | 'customer_owned' | 'phone' | 'email'>
  >({ url: `/instant_payout/recipients/${currentRecipient?.id}` }, apiPutFetcher, {
    onSuccess: () => onSuccess(),
  });

  const initialValues: Recipient = currentRecipient
    ? {
        name: currentRecipient.name,
        phone: currentRecipient.phone,
        email: currentRecipient.email,
      }
    : {
        name: '',
        phone: null,
        email: null,
      };

  const onSubmit = async (values: Recipient) => {
    const { name, phone, email } = values;

    try {
      if (mode === 'add') {
        await addRecipient({
          name,
          phone,
          email,
          customer_owned: false,
        });
        addToast({ type: 'success', title: 'New recipient added' });
      }
      if (mode === 'edit') {
        await editRecipient({
          name,
          phone,
          email,
          customer_owned: false,
        });
        addToast({ type: 'success', title: 'Recipient updated' });
      }
    } catch (e: unknown) {
      if (!guardAxiosError(e)) {
        addToast({ type: 'error', title: 'Something went wrong. Please try again.' });
        return;
      }
      const errorMessage = e.response?.data?.errors?.[0] || e.message;
      addToast({ type: 'error', title: errorMessage });
    }
  };

  return (
    <Modal maxWidth="xs" open={open} toggle={onClose}>
      <ModalHeader title={`${startCase(mode)} Recipient`} onClose={onClose} />
      <ModalBodyContent>
        <RecipientForm initialValues={initialValues} type="add" onSubmit={onSubmit} />
      </ModalBodyContent>
    </Modal>
  );
}
