import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import { NavbarOption } from '@app/interfaces/navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';

const EmbeddedNav = ({ items }: { items: NavbarOption[] }): ReactElement => {
  return (
    <div data-testid="embedded-navbar">
      <div className="rounded-card bg-soft-primary flex flex-row divide-x overflow-y-auto rounded-md p-2 sm:gap-4 sm:divide-none sm:rounded-none sm:border-none sm:bg-inherit sm:p-0">
        {items.map((option: NavbarOption) => {
          return <NavbarOptionLink option={option} key={`nav-option-${option.key}`} />;
        })}
      </div>
    </div>
  );
};

const NavbarOptionLink = ({ option }: { option: NavbarOption }): ReactElement => {
  const linkClasses = classNames(
    'px-6 py-4 h-full flex flex-col sm:flex-row text-center sm:text-left flex-grow cursor-pointer items-center text-default-primary bg-soft-primary hover:bg-soft-primary-hover font-bold gap-2 text-sm rounded-md',
  );

  return (
    <NavLink to={option.link || '#'} className="w-full">
      <RoundedCard breakpoint="sm" className={linkClasses}>
        {option.icon && (
          <div className="bg-green-primary min-h-8 min-w-8 text-contrast-primary flex h-8 w-8 items-center justify-center rounded-full">
            <FontAwesomeIcon icon={option.icon} />
          </div>
        )}
        <span>{option.message}</span>
      </RoundedCard>
    </NavLink>
  );
};

export default EmbeddedNav;
