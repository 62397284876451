import { FilterDropdownDate } from '@app/components/Filters/FilterDropdownDate';
import FilterGroup from '@app/components/Filters/FilterGroup';
import { DriversFilter } from '@app/components/Filters/ResourceFilters/DriversFilter';
import { TagsFilter } from '@app/components/Filters/ResourceFilters/TagsFilter';
import { VehiclesFilter } from '@app/components/Filters/ResourceFilters/VehiclesFilter';
import { ChargeEventQueryParams } from '@app/contexts/ChargeEventsContextComponent';
import { Checkbox, FormControl, ListItemText, MenuItem, Select } from '@mui/material';
import { ReactElement } from 'react';

export const SuspiciousActivitiesFilters = ({
  selectedFilters,
  setSelectedFilters,
  clearFilters,
}: {
  selectedFilters: ChargeEventQueryParams;
  setSelectedFilters: (v: Partial<ChargeEventQueryParams>) => void;
  clearFilters?: () => void;
}): ReactElement => {
  return (
    <div className="hidden w-full items-center md:flex">
      <FilterGroup className="border-b-soft !mx-0 border-b !py-4 px-6" clearFilters={clearFilters}>
        <FilterDropdownDate
          onDateRangeChange={(start, to) =>
            setSelectedFilters({
              'filter_authorized_at[from]': start,
              'filter_authorized_at[to]': to,
            })
          }
          fromDate={selectedFilters['filter_authorized_at[from]']}
          toDate={selectedFilters['filter_authorized_at[to]']}
        />
        <TagsFilter
          onTagsSelect={(values) => setSelectedFilters({ by_tag_ids: values })}
          selectedTagIds={selectedFilters.by_tag_ids}
        />
        <DriversFilter
          onDriversSelect={(values) => setSelectedFilters({ by_driver_ids: values })}
          selectedDriverIDs={selectedFilters.by_driver_ids}
        />
        <VehiclesFilter
          onVehiclesSelect={(values) => setSelectedFilters({ by_vehicle_ids: values })}
          selectedVehicleIDs={selectedFilters.by_vehicle_ids}
        />
        <SuspiciousReasonsFilter
          selectedSuspiciousReasons={selectedFilters.by_suspicious_reasons ?? []}
          onSuspiciousReasonsSelect={(values) =>
            setSelectedFilters({ by_suspicious_reasons: values })
          }
        />
      </FilterGroup>
    </div>
  );
};

function SuspiciousReasonsFilter({
  selectedSuspiciousReasons,
  onSuspiciousReasonsSelect,
}: {
  selectedSuspiciousReasons: string[];
  onSuspiciousReasonsSelect: (v: string[]) => void;
}): ReactElement {
  return (
    <FormControl className="min-w-min flex-1">
      <Select
        size="small"
        displayEmpty
        value={selectedSuspiciousReasons}
        renderValue={() => `${selectedSuspiciousReasons.length} Reason`.replace('0', '').trim()}
        onChange={(e) => {
          if (Array.isArray(e.target.value)) {
            onSuspiciousReasonsSelect(e.target.value);
          } else {
            onSuspiciousReasonsSelect([e.target.value]);
          }
        }}
        multiple
      >
        {[
          { label: 'Vehicle Location', resourceID: 'location' },
          { label: 'Fuel Level', resourceID: 'fuel_level' },
          { label: 'Fuel Type', resourceID: 'fuel_type' },
          { label: 'Obsolete Data', resourceID: 'obsolete_data' },
        ].map((item) => {
          const isSelected = selectedSuspiciousReasons.indexOf(item.resourceID) > -1;
          return (
            <MenuItem
              key={item.resourceID}
              value={item.resourceID}
              selected={isSelected}
              className="gap-1 px-2"
            >
              <Checkbox checked={isSelected} />
              <ListItemText>{item.label}</ListItemText>
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
