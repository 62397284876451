import { CustomerData } from '@app/@types/customer.types';
import { FlexOfferResponse } from '@app/@types/promotion.types';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import useProduct from '@app/hooks/useProduct';
import Button from '@atob-developers/shared/src/components/Button';
import axios from 'axios';
import classNames from 'classnames';
import { deserialize } from 'deserialize-json-api';
import { ReactElement, useEffect, useState } from 'react';
import UnlimitedToFlexMigrationModal from '../../pages/Wallet/UnlimitedToFlexMigration/UnlimitedToFlexMigrationModal';

const PrepaidUnlimitedToFlexV2Banner = ({
  customer,
}: {
  customer: CustomerData;
}): ReactElement | null => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggle = () => {
    setIsModalOpen(!isModalOpen);
  };
  const [loading, setLoading] = useState<boolean>(false);

  const [flexOffer, setFlexOffer] = useState<FlexOfferResponse>({
    workflow_state: 'migrated',
    fee_cents: 10000,
    credit_limit: 100,
    bank_balance_required_cents: 10000,
  });

  useEffect(() => {
    setLoading(true);
    axios
      .get('/flex_offers', { params: { customer_id: customer.id } })
      .then((response) => {
        const offer = deserialize(response.data).data;
        setFlexOffer(offer);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [customer.id]);

  const [migratingToFlex] = useFeatureFlags('ul_to_flex_promo_v2');
  const [prepaid] = useProduct('prepaid');
  if (
    !prepaid ||
    !migratingToFlex ||
    ![
      'created',
      'accepted',
      'account_linked',
      'account_linked_failed',
      'payment_processing',
      'migrated',
    ].includes(flexOffer.workflow_state)
  ) {
    return null;
  }

  return (
    <>
      <div>
        <div
          className={classNames(
            'bg-green-primary',
            'relative flex w-full items-center justify-between overflow-hidden bg-no-repeat',
          )}
        >
          <div className="text-contrast-primary flex flex-row justify-between gap-x-2 space-x-2 px-[32px] py-[22px] text-base md:w-full">
            <div className="space-y-2">
              <b className="font-bold">Congrats! </b>
              You are eligible to be converted to Flex (Credit) for a $
              {flexOffer.credit_limit + ' '}
              credit line after linking your primary bank account.
            </div>
            <div className="flex-col self-center">
              <Button
                className="rounded-full px-4 py-3 text-sm font-medium"
                loading={loading}
                onClick={toggle}
              >
                Continue
              </Button>
              <UnlimitedToFlexMigrationModal
                isOpen={isModalOpen}
                toggle={toggle}
                customer={customer}
                flexOffer={flexOffer}
                setFlexOffer={setFlexOffer}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrepaidUnlimitedToFlexV2Banner;
