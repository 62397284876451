import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import { Cell } from '@app/components/TableV2/Cell';
import { MobileListItem } from '@app/components/TableV2/MobileListItem';
import ResponsiveTable from '@app/components/TableV2/ResponsiveTable';
import { ColumnDefinition } from '@app/components/TableV2/Table';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { RowData } from './GeneratingReport';

type ReviewIftaFilingInterface = {
  data: RowData[];
};

export const COLUMNS = [
  {
    field: 'fuel_type',
    headerName: 'Fuel Type',
    minWidth: 150,
    flex: 1,
    renderCell: (params) => <Cell>{params.row.fuel_type}</Cell>,
  },
  {
    field: 'no_of_vehicle',
    headerName: 'No of Vehicle',
    minWidth: 150,
    flex: 1,
    renderCell: (params) => <Cell>{params.row.vehicle_count}</Cell>,
  },
  {
    field: 'miles_driven',
    headerName: 'Miles Driven',
    minWidth: 150,
    flex: 1,
    renderCell: (params) => <Cell>{params.row.total_miles}</Cell>,
  },
  {
    field: 'gallons_purchased',
    headerName: 'Gallons Purchased',
    minWidth: 150,
    flex: 1,
    renderCell: (params) => <Cell>{params.row.total_fuel_purchased}</Cell>,
  },
  {
    field: 'states_driven',
    headerName: 'States Driven',
    minWidth: 150,
    flex: 1,
    renderCell: (params) => <Cell>{params.row.jurisdictions_count}</Cell>,
  },
  {
    field: 'avg_mpg',
    headerName: 'Avg. MPG',
    minWidth: 150,
    flex: 1,
    renderCell: (params) => <Cell>{params.row.avg_mpg ? params.row.avg_mpg : null}</Cell>,
  },
] as ColumnDefinition[];

const MobileVehicleItemRenderer = ({ row, onClick }: { row: RowData; onClick?: () => void }) => (
  <MobileListItem onClick={onClick}>
    <div className="font-bold">
      <Cell>{row.fuel_type}</Cell>
    </div>
    <div>{row.vehicle_count}</div>
    <div>{row.total_miles}</div>
  </MobileListItem>
);
const ReviewIftaFiling = ({ data }: ReviewIftaFilingInterface): ReactElement => {
  const navigate = useNavigate();

  const handleRowClick = (row: RowData) => {
    navigate('/ifta-filing?stage=5', { state: row });
  };

  return (
    <>
      <RoundedCard className="relative flex-1 py-4">
        <div className="flex items-center justify-center bg-white">
          <h2 className="mb-4 text-lg font-semibold text-gray-800">Review Your IFTA filing</h2>
        </div>
        <div className="mb-4">
          {data?.length > 0 ? (
            <ResponsiveTable
              loading={false}
              columns={COLUMNS}
              data={data || []}
              onRowClick={handleRowClick}
              mobileItemRenderer={MobileVehicleItemRenderer}
            />
          ) : (
            <div className="flex items-center justify-center bg-white">
              <h2 className="font-regular mb-4 text-sm text-gray-800">No data found</h2>
            </div>
          )}
        </div>
      </RoundedCard>
    </>
  );
};

export default ReviewIftaFiling;
