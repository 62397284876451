import { UserCustomerData, UserCustomersResponseType } from '@app/@types/user_customer.types';
import {
  Backdrop,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import axios from 'axios';
import { deserialize } from 'deserialize-json-api';
import { ReactElement, useEffect, useState } from 'react';
import tw from 'twin.macro';

const AccountOverviewHeader = ({ title }: { title: string }): ReactElement => {
  const [customers, setCustomers] = useState([]);
  const [currentCustomer, setCurrentCustomer] = useState('');
  const [startSwitching, setStartSwitching] = useState(false);

  useEffect(() => {
    const fetchCustomers = async () => {
      const customersData = await axios.get<UserCustomersResponseType>('/customers');
      const customers = deserialize(customersData.data).data;
      const current = customers.find((customer: UserCustomerData) => customer.current);
      setCurrentCustomer(current.uuid);
      setCustomers(customers);
    };

    fetchCustomers();
  }, []);

  const handleChange = async (event: SelectChangeEvent) => {
    const selectedCustomer = event.target.value;
    if (selectedCustomer !== currentCustomer) {
      setStartSwitching(true);
      // Call API to safely set cookie
      await axios.post<unknown>('/customers/switch', {
        uuid: selectedCustomer,
      });
      // No need to handle error. If the response is 404, we will still refresh the page to load the latest customer.
      window.location.reload();
    }
  };

  if (customers.length <= 1) {
    return (
      <div className="px-4 py-3">
        <h1 className="text-default-primary text-xl font-bold">{title}</h1>
      </div>
    );
  }

  return (
    <>
      <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={startSwitching}>
        <CircularProgress />
      </Backdrop>

      <FormControl>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={currentCustomer}
          onChange={handleChange}
          variant="standard"
          disableUnderline
          className="w-fit bg-transparent"
          sx={{
            '& .MuiSelect-select.MuiSelect-standard': {
              ...tw`text-xl font-bold`,
            },
          }}
        >
          {customers.map((item: UserCustomerData) => (
            <MenuItem key={item.uuid} value={item.uuid}>
              {item.company_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default AccountOverviewHeader;
