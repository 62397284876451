import { CardRequestParams } from '@app/@types/card_requests.types';
import AddressInput from '@app/components/elements/AddressInput';
import { addressSchema } from '@app/utils/validation/schema-validation';
import {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { Button } from '@mui/material';
import { Form, Formik, FormikProps } from 'formik';
import { ReactElement } from 'react';
import type { AddressType } from '@app/@types/customer.types';

export const CardOrderAddress = ({
  params,
  setParams,
  onNext,
  onBack,
  closeModal,
}: {
  params: CardRequestParams;
  setParams: (_: CardRequestParams) => void;
  onNext: () => void;
  onBack: () => void;
  closeModal: () => void;
}): ReactElement => {
  const handleContinue = (values: AddressType) => {
    setParams({ ...params, shipping_address: values });
    onNext();
  };

  return (
    <>
      <ModalHeader title="Shipping Address" onClose={closeModal} />
      <Formik
        initialValues={params.shipping_address || {}}
        validationSchema={addressSchema}
        onSubmit={handleContinue}
      >
        {(props: FormikProps<AddressType>) => (
          <>
            <ModalBodyContent overflowVisible={true}>
              <Form onSubmit={props.handleSubmit}>
                <div className="mb-12 py-4">
                  <AddressInput
                    addressValues={props.values}
                    setAddressValues={(addressValues) =>
                      props.setValues(addressValues as AddressType)
                    }
                    touched={props.touched}
                    setTouched={props.setTouched}
                    label=""
                    errors={props.errors || {}}
                    setErrors={() => {}}
                  />
                </div>
              </Form>
            </ModalBodyContent>
            <ModalFooter>
              <Button className="flex-1" size="small" color="secondary" onClick={onBack}>
                Back
              </Button>
              <Button className="flex-1" size="small" onClick={() => props.handleSubmit()}>
                Continue
              </Button>
            </ModalFooter>
          </>
        )}
      </Formik>
    </>
  );
};
