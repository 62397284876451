import { mapBankAndDebitCardData } from '@app/components/PaymentMethods/PaymentMethodUtils';
import SpinnerBoundary from '@app/components/Spinner/SpinnerBoundary';
import { useConfirmCreditEnablementNetNewMutation } from '@app/hooks/mutation/useConfirmCreditEnablementNetNewMutation';
import { usePaymentMethodsQuery } from '@app/hooks/query/usePaymentMethodsQuery';
import useShippingAddress from '@app/hooks/use-shipping-address';
import useCustomer from '@app/hooks/useCustomer';
import Modal, { ModalBodyContent } from '@atob-developers/shared/src/components/Modal';
import { useState } from 'react';
import { CreditEnablementNetNewConfirmBankAccount } from '../CreditEnablementNetNew/CreditEnablementNetNewConfirmBankAccount';
import { CreditEnablementNetNewConfirmShipping } from '../CreditEnablementNetNew/CreditEnablementNetNewConfirmShipping';
import { CreditEnablementNetNewConfirmation } from '../CreditEnablementNetNew/CreditEnablementNetNewConfirmation';
import { CreditEnablementNetNewConnectBankAccount } from '../CreditEnablementNetNew/CreditEnablementNetNewConnectBankAccount';
import { CreditEnablementNetNewOffer } from '../CreditEnablementNetNew/CreditEnablementNetNewOffer';
import { CreditEnablementNetNewReviewDetails } from '../CreditEnablementNetNew/CreditEnablementNetNewReviewDetails';

export interface CreditEnablementNetNewModalProps {
  isOpen: boolean;
  closeModal: () => void;
  onComplete?: () => void;
}

type CreditEnablementNetNewModalStep =
  | 'offer'
  | 'connect_bank_account'
  | 'confirm_bank_account'
  | 'confirm_shipping'
  | 'review_details'
  | 'confirmation';

export const CreditEnablementNetNewModal = ({
  isOpen,
  closeModal,
  onComplete = () => {},
}: CreditEnablementNetNewModalProps) => {
  const customer = useCustomer();
  const {
    error: paymentMethodsError,
    data: paymentMethodsData,
    isLoading: paymentMethodsLoading,
  } = usePaymentMethodsQuery();
  const shippingAddress = useShippingAddress(customer);

  const {
    trigger: confirmCreditEnablementNetNew,
    error: confirmCreditEnablementNetNewError,
    isMutating: isConfirmCreditEnablementNetNewMutating,
  } = useConfirmCreditEnablementNetNewMutation();

  const [currentStep, setCurrentStep] = useState<CreditEnablementNetNewModalStep>('offer');

  if (paymentMethodsLoading || isConfirmCreditEnablementNetNewMutating) {
    return <SpinnerBoundary />;
  }

  if (!paymentMethodsData || paymentMethodsError || confirmCreditEnablementNetNewError) {
    return (
      <Modal open={isOpen} toggle={closeModal}>
        <ModalBodyContent>
          Error retrieving payment data.
          {paymentMethodsError}
          {confirmCreditEnablementNetNewError}
        </ModalBodyContent>
      </Modal>
    );
  }

  const { combinedPaymentMethods } = mapBankAndDebitCardData(paymentMethodsData.data || []);
  const { bankAccounts } = combinedPaymentMethods;
  const hasBankAccounts = bankAccounts.length > 0;

  return (
    <Modal open={isOpen} toggle={closeModal}>
      {currentStep === 'offer' && (
        <CreditEnablementNetNewOffer
          toggle={closeModal}
          onNext={() =>
            setCurrentStep(hasBankAccounts ? 'confirm_bank_account' : 'connect_bank_account')
          }
          onBack={closeModal}
        />
      )}
      {currentStep === 'confirm_bank_account' && (
        <CreditEnablementNetNewConfirmBankAccount
          toggle={closeModal}
          bankAccounts={bankAccounts}
          onNext={() => setCurrentStep('confirm_shipping')}
          onBack={() => setCurrentStep('offer')}
        />
      )}
      {currentStep === 'connect_bank_account' && (
        <CreditEnablementNetNewConnectBankAccount
          toggle={closeModal}
          bankAccounts={bankAccounts}
          onNext={() => setCurrentStep('confirm_bank_account')}
          onBack={() => setCurrentStep('offer')}
        />
      )}
      {currentStep === 'confirm_shipping' && (
        <CreditEnablementNetNewConfirmShipping
          toggle={closeModal}
          shippingAddress={shippingAddress}
          onNext={() => setCurrentStep('review_details')}
          onBack={() => setCurrentStep('confirm_bank_account')}
        />
      )}
      {currentStep === 'review_details' && (
        <CreditEnablementNetNewReviewDetails
          toggle={closeModal}
          onNext={() => {
            confirmCreditEnablementNetNew({});
            setCurrentStep('confirmation');
          }}
          onBack={() => setCurrentStep('confirm_shipping')}
        />
      )}
      {currentStep === 'confirmation' && (
        <CreditEnablementNetNewConfirmation
          toggle={closeModal}
          onNext={onComplete}
          onBack={() => setCurrentStep('offer')}
        />
      )}
    </Modal>
  );
};
