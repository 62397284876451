import useChannelPartner from '@app/hooks/useChannelPartner';
import logger from '@app/utils/datadog-logger';
import * as Sentry from '@sentry/react';
import { ReactElement, ReactNode, useEffect, useRef } from 'react';

interface ErrorMessageProps {
  error?: string | Error;
  generic?: boolean;
}

const ErrorMessage = ({ error = '', generic }: ErrorMessageProps): ReactElement => {
  const { supportEmailAddress, supportPhoneNumber } = useChannelPartner();
  const errorMessage = error instanceof Error ? error.message : error;
  const serverError = errorMessage.match(/500/) !== null;
  const networkError = errorMessage.match(/Network Error/gi) !== null;
  const fourTwentyTwoError = errorMessage.match(/422/) !== null;
  const fourTwentyNineError = errorMessage.match(/429/) !== null;

  logger.error('User hit an error', {
    errorMessage: errorMessage,
    error: error,
  });

  if (generic || serverError || networkError) {
    return (
      <p>
        We&apos;ve had an unexpected issue with your request and are looking into it. Please{' '}
        <a
          className="underline"
          href="#"
          onClick={() => {
            window.location.reload();
          }}
        >
          reload the page
        </a>{' '}
        and try again. If you continue to have problems, please{' '}
        <a className="underline" href={`tel:${supportPhoneNumber}`}>
          call
        </a>{' '}
        or{' '}
        <a className="underline" href={`mailto:${supportEmailAddress}`}>
          email
        </a>{' '}
        support for assistance.
      </p>
    );
  }

  if (fourTwentyTwoError || fourTwentyNineError) {
    return <div>Something went wrong. Please try again.</div>;
  }

  return <>{errorMessage}</>;
};

interface ErrorNotificationProps {
  children?: ReactNode;
  error: string | Error;
  generic?: boolean;
  className?: string;
}

export default function ErrorNotification(props: ErrorNotificationProps): ReactElement {
  const { children, className = '', error, ...rest } = props;

  const sentryErrorRef = useRef(false);
  useEffect(() => {
    if (sentryErrorRef.current == false) {
      sentryErrorRef.current = true;
      Sentry.captureException(error);
    }
  });

  return (
    <section className={className}>
      <div className="container">
        <div className="border-error bg-red-secondary mb-4 border-l-4 p-4">
          <div className="flex">
            <div className="ml-3">
              <div className="text-error text-sm">
                {children || <ErrorMessage error={error} {...rest} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
