import { PayrollCalculation } from '@app/@types/payroll.types';
import { sumPayrollPayment } from '@app/utils/payroll-itemization';
import { formatCurrency } from '@atob-developers/shared/src/utils/formatters';
import {
  convertCentsToDollars,
  convertDollarsToCents,
  multiplyCurrency,
} from '@atob-developers/shared/src/utils/formatters/currencyFormat';
import { Button, MenuItem, Select, TextField } from '@mui/material';
import { ReactElement, useState, useEffect } from 'react';
import { PAYOUT_LIMIT_CENTS, FORMATTED_LIMIT } from '../Table/PayAmountInput';
import { PayrollItemization } from './PaymentItemization';

const Calculations: { id: number; name: PayrollCalculation }[] = [
  { id: 1, name: 'Percentage of Load' },
  { id: 2, name: 'Flat Amount Payroll' },
  { id: 3, name: 'Pay per Mile' },
  { id: 4, name: 'Hourly' },
];

export interface PaymentCalculation {
  amount_cents: number;
  calculationMethod: PayrollCalculation | null;
  description: string;
  rateCents?: number;
  units?: number;
  loadValueCents?: number;
  loadRate?: number;
  itemizations: PayrollItemization[];
}

export type CalculationErrors =
  | 'gross_total_limit'
  | 'load_rate_range_invalid'
  | 'gross_total_below_zero';

const PaymentCalculationBody = ({
  updateCalculation,
  updateError,
  default_calculation_method,
}: {
  updateCalculation: (calculation: Omit<PaymentCalculation, 'itemizations'>) => void;
  updateError: (key: CalculationErrors, value: string | null) => void;
  default_calculation_method?: PayrollCalculation;
}): ReactElement => {
  /**
   * Calculation method that is being used to calculate grossTotalCents
   */
  const [calculationMethod, setCalculationMethod] = useState<PayrollCalculation>(
    default_calculation_method ?? 'Percentage of Load',
  );

  /**
   * The amount to pay driver, after doing a calculation
   */
  const [grossTotalCents, setGrossTotalCents] = useState<number>(0);

  /**
   * units refers to miles driven or hours worked.
   * rateCents refers to hourly or per mile rate. Stored in cents.
   *
   * Ex: $20 per hour * 5 hours
   *
   * units = 5
   * rateCents = 2000
   *
   * ==> grossTotalCents = 10000
   */
  const [units, setUnits] = useState<number | undefined>(undefined);
  const [rateCents, setRateCents] = useState<number | undefined>(undefined);

  /**
   * loadValueCents refers to the value of a load. Stored in cents.
   * loadRate refers to a number 1 - 100 that the driver earns as a percentage of the loadValue.
   *   (We can't use rateCents for this rate because this rate is not stored in cents!)
   *
   * Ex: $100 load paid at 15%
   *
   * loadValueCents = 10000
   * loadRate = 15
   *
   * ==> grossTotalCents = 1500
   */
  const [loadValueCents, setLoadValueCents] = useState<number | undefined>(undefined);
  const [loadRate, setLoadRate] = useState<number | undefined>(undefined);

  /**
   * Description for calculation. If only a calculation is used (no itemizations),
   * this will be the description for the entire payment as well.
   */
  const [description, setDescription] = useState('');

  useEffect(() => {
    updateCalculation({
      calculationMethod: calculationMethod,
      rateCents: rateCents,
      units: units,
      amount_cents: grossTotalCents,
      description: description,
      loadValueCents: loadValueCents,
      loadRate: loadRate,
    });
  }, [
    updateCalculation,
    calculationMethod,
    rateCents,
    units,
    grossTotalCents,
    description,
    loadValueCents,
    loadRate,
  ]);

  const onCalculationMethodChange = (calculationMethodName: PayrollCalculation | null) => {
    if (calculationMethodName) {
      setCalculationMethod(calculationMethodName);
    }
    setRateCents(undefined);
    setUnits(undefined);
    setLoadRate(undefined);
    setLoadValueCents(undefined);
    setDescription('');
    setGrossTotalCents(0);
    updateError('gross_total_limit', null);
    updateError('load_rate_range_invalid', null);
    updateError('gross_total_below_zero', null);
  };

  const handleGrossTotalChange = (total: number) => {
    const newGrossTotalCents = convertDollarsToCents({ value: total }).value as number;
    setGrossTotalCents(newGrossTotalCents);
    if (newGrossTotalCents >= PAYOUT_LIMIT_CENTS) {
      updateError('gross_total_limit', `Payment must be less than ${FORMATTED_LIMIT}`);
    } else {
      updateError('gross_total_limit', null);
    }
  };

  return (
    <>
      <Select
        value={calculationMethod}
        data-testid="dropdown-button"
        onChange={(e) => onCalculationMethodChange(e.target.value as PayrollCalculation)}
        fullWidth
      >
        {Calculations.map((item) => (
          <MenuItem key={item.id} value={item.name}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
      <GrossDescription description={description} onDescriptionChange={setDescription} />
      <div>
        <div className="border-soft w-full border-b border-solid pb-5 md:w-fit">
          <CalculationBody
            calculationMethod={calculationMethod}
            rate={
              rateCents ? (convertCentsToDollars({ value: rateCents }).value as number) : undefined
            }
            setRate={(rate) => setRateCents(convertDollarsToCents({ value: rate }).value as number)}
            units={units}
            setUnits={setUnits}
            load={
              loadValueCents
                ? (convertCentsToDollars({ value: loadValueCents }).value as number)
                : undefined
            }
            setLoad={(load) =>
              setLoadValueCents(convertDollarsToCents({ value: load }).value as number)
            }
            loadRate={loadRate}
            setLoadRate={setLoadRate}
            grossTotal={convertCentsToDollars({ value: grossTotalCents }).value as number}
            setGrossTotal={handleGrossTotalChange}
            updateError={updateError}
          />
        </div>
        <span className="text-default-primary mt-2 flex justify-end text-base font-semibold">
          Gross Payment: {formatCurrency({ value: grossTotalCents, options: { fromCents: true } })}
        </span>
      </div>
    </>
  );
};

const PaymentCalculatorTotal = ({
  paymentDetails,
}: {
  paymentDetails: PaymentCalculation;
}): ReactElement => {
  const total = sumPayrollPayment(paymentDetails);
  return (
    <>
      <span className="text-default-primary text-base font-semibold">Total Payment </span>
      <span className="text-red text-base font-semibold">{formatCurrency({ value: total })}</span>
    </>
  );
};

const PaymentCalculationFooter = ({
  isContinueButtonDisabled,
  confirmDescription,
  canDeleteItems,
  deleteItems,
}: {
  isContinueButtonDisabled: boolean;
  confirmDescription: () => void;
  canDeleteItems: boolean;
  deleteItems: (deleteInProgress: boolean) => void;
}): ReactElement => {
  return (
    <div className="contents justify-end gap-x-4 sm:flex">
      <div className="mt-2 hidden px-6 sm:flex sm:justify-end sm:px-0 ">
        <Button onClick={() => deleteItems(!canDeleteItems)} size="medium" variant="destructive">
          {canDeleteItems ? 'Delete' : 'Remove Items'}
        </Button>
      </div>
      <div className="mt-2 w-full px-6 sm:flex sm:w-auto sm:justify-end sm:px-0">
        <Button size="medium" disabled={isContinueButtonDisabled} onClick={confirmDescription}>
          Continue
        </Button>
      </div>
    </div>
  );
};

const GrossDescription = ({
  description,
  onDescriptionChange,
}: {
  description: string;
  onDescriptionChange: (_: string) => void;
}): ReactElement => {
  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (value.length <= 280) {
      onDescriptionChange(value);
    }
  };
  const characterLimitReached = description.length >= 280;
  return (
    <div className="w-full">
      <div className="my-2 flex w-full flex-col font-[400]">
        <TextField
          fullWidth
          id="item-description"
          size="medium"
          placeholder="Payment description (optional)"
          value={description}
          onChange={handleDescriptionChange}
        />
        <div className={`${characterLimitReached ? 'text-red' : 'text-default-secondary'}`}>
          {characterLimitReached && `${description.length} character limit reached`}
        </div>
      </div>
    </div>
  );
};

export const LOAD_RATE_RANGE_INVALID_MESSAGE = 'Load rate should be between 1 and 100';

const CalculationBody = ({
  calculationMethod,
  rate,
  setRate,
  units,
  setUnits,
  load,
  setLoad,
  loadRate,
  setLoadRate,
  grossTotal,
  setGrossTotal,
  updateError,
}: {
  calculationMethod: PayrollCalculation;
  rate: number | undefined;
  setRate: (_: number) => void;
  units: number | undefined;
  setUnits: (_: number) => void;
  load: number | undefined;
  setLoad: (_: number) => void;
  loadRate: number | undefined;
  setLoadRate: (_: number) => void;
  grossTotal: number;
  setGrossTotal: (_: number) => void;
  updateError: (key: CalculationErrors, value: string | null) => void;
}): ReactElement => {
  const onChangeUnits = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    amountType: 'load' | 'hours' | 'miles',
  ) => {
    e.stopPropagation();
    const { value } = e.target;
    if (value.startsWith('-')) {
      return;
    }
    if (amountType === 'load') {
      const newLoadRate = parseFloat(value);
      if (newLoadRate > 100 || newLoadRate < 1) {
        updateError('load_rate_range_invalid', LOAD_RATE_RANGE_INVALID_MESSAGE);
      } else {
        updateError('load_rate_range_invalid', null);
      }
      setLoadRate(newLoadRate);
      if (load && newLoadRate) {
        setGrossTotal(
          multiplyCurrency({
            value: Number.isNaN(load) ? 0 : load,
            multiplyValue: Number.isNaN(newLoadRate) ? 0 : newLoadRate / 100,
            options: { fromCents: false },
          }).value,
        );
      } else {
        setGrossTotal(0);
      }
    } else {
      const newUnits = parseFloat(value);
      setUnits(newUnits);
      if (rate && newUnits) {
        setGrossTotal(
          multiplyCurrency({
            value: Number.isNaN(rate) ? 0 : rate,
            multiplyValue: Number.isNaN(newUnits) ? 0 : newUnits,
            options: { fromCents: false },
          }).value,
        );
      } else {
        setGrossTotal(0);
      }
    }
  };

  const onChangeDollarAmount = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    amountType: 'load' | 'hours' | 'miles',
  ) => {
    e.stopPropagation();
    const { value } = e.target;
    if (value.startsWith('-')) {
      return;
    }
    if (amountType === 'load') {
      const newLoadValue = parseFloat(value);
      setLoad(newLoadValue);
      if (newLoadValue && loadRate) {
        setGrossTotal(
          multiplyCurrency({
            value: Number.isNaN(newLoadValue) ? 0 : newLoadValue,
            multiplyValue: Number.isNaN(loadRate) ? 0 : loadRate / 100,
            options: { fromCents: false },
          }).value,
        );
      } else {
        setGrossTotal(0);
      }
    } else {
      const newRate = parseFloat(value);
      setRate(newRate);
      if (newRate && units) {
        setGrossTotal(
          multiplyCurrency({
            value: Number.isNaN(newRate) ? 0 : newRate,
            multiplyValue: Number.isNaN(units) ? 0 : units,
            options: { fromCents: false },
          }).value,
        );
      } else {
        setGrossTotal(0);
      }
    }
  };

  const onChangeFlatAmount = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.stopPropagation();
    const { value } = e.target;
    const isNotANumber = Number.isNaN(parseFloat(value));
    const isEmpty = value === '';
    if (isNotANumber || isEmpty) {
      setGrossTotal(0);
      return;
    }
    if (value.startsWith('-')) {
      return;
    }
    setGrossTotal(parseFloat(value));
  };

  switch (calculationMethod) {
    case 'Pay per Mile':
      return (
        <div className="flex flex-col items-end">
          <div className="flex items-center">
            <span className="mr-4 whitespace-nowrap text-base">Rate per Mile $</span>
            <div className="my-2 w-[150px]">
              <TextField
                fullWidth
                id="rate-per-mile"
                placeholder="0.00"
                value={rate}
                onChange={(e) => onChangeDollarAmount(e, 'miles')}
                slotProps={{
                  htmlInput: {
                    min: 0,
                  },
                }}
              />
            </div>
          </div>
          <div className="flex items-center">
            <span className="mr-4 text-base">Miles</span>
            <div className="my-2 w-[150px]">
              <TextField
                fullWidth
                id="miles"
                placeholder="0"
                value={units}
                onChange={(e) => onChangeUnits(e, 'miles')}
                slotProps={{
                  htmlInput: {
                    min: 0,
                  },
                }}
              />
            </div>
          </div>
        </div>
      );
    case 'Hourly':
      return (
        <div className="flex flex-col items-end">
          <div className="flex items-center">
            <span className="mr-4 whitespace-nowrap text-base">Rate per Hour $</span>
            <div className="my-2 w-[150px]">
              <TextField
                fullWidth
                id="rate-hourly"
                placeholder="0.00"
                value={rate}
                onChange={(e) => onChangeDollarAmount(e, 'hours')}
              />
            </div>
          </div>
          <div className="flex items-center">
            <span className="mr-4 text-base">Hours</span>
            <div className="my-2 w-[150px]">
              <TextField
                fullWidth
                id="rate-hourly"
                placeholder="0.00"
                value={units}
                onChange={(e) => onChangeUnits(e, 'hours')}
              />
            </div>
          </div>
        </div>
      );
    case 'Percentage of Load':
      return (
        <div className="flex flex-col items-end">
          <div className="flex items-center">
            <span className="mr-4 whitespace-nowrap text-base">Total Load $</span>
            <div className="my-2 w-[150px]">
              <TextField
                fullWidth
                type="number"
                id="total-load"
                name="total-load"
                placeholder="0"
                value={load}
                onChange={(e) => onChangeDollarAmount(e, 'load')}
              />
            </div>
          </div>
          <div className="flex items-center">
            <span className="mr-4 whitespace-nowrap text-base">Rate per load</span>
            <div className="my-2 w-[150px]">
              <TextField
                fullWidth
                type="number"
                id="rate-load"
                name="rate-load"
                placeholder="0.00"
                value={loadRate}
                onChange={(e) => onChangeUnits(e, 'load')}
              />
            </div>
            <span className="ml-2">%</span>
          </div>
        </div>
      );
    case 'Flat Amount Payroll':
    case 'Flat Amount Other':
      return (
        <div className="flex items-center justify-end">
          <span className="mr-4 whitespace-nowrap text-base">Amount $</span>
          <div className="flex justify-end">
            <div className="my-2 w-[150px]">
              <TextField
                fullWidth
                id="flat-amount"
                placeholder="0.00"
                value={grossTotal}
                onChange={(e) => onChangeFlatAmount(e)}
              />
            </div>
          </div>
        </div>
      );
  }
};
export { PaymentCalculationFooter, PaymentCalculationBody, PaymentCalculatorTotal };
