import { EndpointResponse, Entity } from '@app/@types/api.types';
import { BankAccountWithEstimateData, PaymentsData } from '@app/@types/payments.types';
import PaymentDetailItem from '@app/components/PaymentModal/PaymentDetailSection/PaymentDetailItem/PaymentDetailItem';
import NoPaymentMethods from '@app/components/PaymentModal/components/NoPaymentMethods';
import PaymentDetailsHeaderText from '@app/components/PaymentModal/components/PaymentDetailsHeaderText';
import ReviewPaymentFooter from '@app/components/PaymentModal/components/ReviewPaymentFooter';
import { getProcessingDate } from '@app/components/PaymentModal/helpers/getProcessingDate';
import logger from '@app/utils/datadog-logger';
import { guardAxiosError } from '@app/utils/error/guards';
import { convertNumbersWithCurrency } from '@atob-developers/shared/src/utils/formatters/currencyFormat';
import axios from 'axios';
import { ReactElement, useState } from 'react';
import { ErrorState, onFailure } from '../helpers/handlePaymentUtils';

interface BankTransferReviewPaymentDetailsProps {
  selectedBankAccount?: BankAccountWithEstimateData;
  onBack: () => void;
  paymentAmount: string;
  setPaymentStatus: (paymentStatus: 'success' | 'error', message?: string) => void;
}

const BankTransferReviewPaymentDetails = ({
  selectedBankAccount,
  onBack,
  paymentAmount,
  setPaymentStatus,
}: BankTransferReviewPaymentDetailsProps): ReactElement => {
  const [submittingPayment, setSubmittingPayment] = useState(false);

  if (!selectedBankAccount) {
    return <NoPaymentMethods onBack={onBack} />;
  }

  const { attributes: defaultBankDataAttributes } = selectedBankAccount.bank_account.data;
  const estimatedApplyAtDate = getProcessingDate(selectedBankAccount.estimated_apply_at);

  const submitPayment = async () => {
    setSubmittingPayment(true);
    try {
      await axios.post<EndpointResponse<Entity<PaymentsData>>>('/ach_charges?include=customer', {
        ach_charge: {
          bank_account_id: defaultBankDataAttributes.bank_account_id,
          amount: convertNumbersWithCurrency(paymentAmount),
        },
      });
      setPaymentStatus('success');
      logger.info('Bank Transfer Payment Succeeded', { paymentAmount, selectedBankAccount });
    } catch (error: unknown) {
      if (guardAxiosError(error)) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        onFailure(error, (err: ErrorState) => setPaymentStatus('error', err.message));
      } else {
        setPaymentStatus('error');
      }
      logger.error('Error processing bank transfer payment', {
        error,
        selectedBankAccount,
        paymentAmount,
      });
    }
    setSubmittingPayment(false);
  };

  return (
    <>
      <div className="bg-soft-primary w-full space-y-10 px-6 py-6 sm:px-8">
        <div className="flex flex-col gap-4">
          <PaymentDetailsHeaderText>Review Payment Details</PaymentDetailsHeaderText>
          <p className="text-default-primary text-left text-base">
            An amount of <strong>{paymentAmount}</strong> will be credited to your AtoB account by
            approximately <strong>{estimatedApplyAtDate}</strong>.
          </p>
          <div className="flex flex-col gap-4">
            <PaymentDetailItem text="Bank Account">
              {defaultBankDataAttributes.institution_name} ••••
              {defaultBankDataAttributes.mask}
            </PaymentDetailItem>
            <div className="border-soft border-b" />
            <PaymentDetailItem text="Amount">{paymentAmount}</PaymentDetailItem>
            <div className="border-soft border-b" />
            <PaymentDetailItem text="Est. Processing Date">
              {estimatedApplyAtDate}
            </PaymentDetailItem>
          </div>
        </div>
      </div>
      <ReviewPaymentFooter
        onBack={onBack}
        payNowHandler={submitPayment}
        paymentAmount={paymentAmount}
        loading={submittingPayment}
      />
    </>
  );
};

export default BankTransferReviewPaymentDetails;
