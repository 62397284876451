import { PaginationMeta } from '@app/@types/api.types';
import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import { DateAndTimeColumn, DefaultColumn } from '@app/components/TableV2/StandardColumns';
import Table, { ColumnDefinition } from '@app/components/TableV2/Table';
import { ErrorNotification } from '@app/components/layout';
import ConfirmActionDialog from '@app/components/layout/ConfirmActionDialog';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import UndoIcon from '@mui/icons-material/Undo';
import { Chip, IconButton } from '@mui/material';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { ReactElement, useState } from 'react';
import { FundingStatus, FundingRequest } from './Funding';

dayjs.extend(utc);
dayjs.extend(timezone);

const StatusPill = ({ status }: { status: FundingStatus }) => {
  if (status === 'created') {
    return <Chip label="Created" color="orange" />;
  }
  if (status === 'processing') {
    return <Chip label="Processing" color="orange" />;
  }
  if (status === 'failed') {
    return <Chip label="Failed" color="red" />;
  }
  if (status === 'succeeded') {
    return <Chip label="Succeeded" color="green" />;
  }
  if (status === 'reverted') {
    return <Chip label="Reverted" color="grey" />;
  }
  if (status === 'abandoned') {
    return <Chip label="Abandoned (do not retry)" color="grey" />;
  }
  return null;
};

interface FundingRequestsTableProps {
  fundingRequestsPage: FundingRequest[];
  metadata: PaginationMeta;
  onPageIndexChange: (pageIndex: number) => void;
  onRevertRequest: (id: number) => Promise<string | null>;
}

export default function FundingRequestsTable({
  fundingRequestsPage,
  metadata,
  onPageIndexChange,
  onRevertRequest,
}: FundingRequestsTableProps): ReactElement {
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState<FundingRequest | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [revertFundingRequestEnabled] = useFeatureFlags('revert_funding_request');

  const handleRevertClick = (request: FundingRequest) => {
    setSelectedRequest(request);
    setConfirmDialogOpen(true);
  };

  const handleConfirmRevert = async () => {
    if (!selectedRequest) return;

    const errorMessage = await onRevertRequest(selectedRequest.id);
    setError(errorMessage);

    setConfirmDialogOpen(false);
    setSelectedRequest(null);
  };

  const handleCancelRevert = () => {
    setConfirmDialogOpen(false);
    setSelectedRequest(null);
  };

  const columns = [
    {
      field: 'id',
      headerName: 'ID',
      minWidth: 50,
      flex: 1,
    },
    {
      field: 'created_at',
      valueGetter: (_, row: FundingRequest) =>
        dayjs(row.created_at).local().format('YYYY-MM-DD HH:mm:ss'),
      ...DateAndTimeColumn('Created'),
    },
    {
      field: 'processed_at',
      valueGetter: (_, row: FundingRequest) =>
        dayjs(row.processed_at).local().format('YYYY-MM-DD HH:mm:ss'),
      ...DateAndTimeColumn('Processed', 150),
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 120,
      flex: 1,
      renderCell: ({ row }) => <StatusPill status={row.status} />,
    },
    {
      field: 'customer_id',
      headerName: 'AtoB ID',
      minWidth: 50,
      flex: 1,
    },
    {
      field: 'customer_name',
      headerName: 'Carrier Name',
      minWidth: 200,
      flex: 3,
    },
    {
      field: 'reference_id',
      headerName: 'Reference ID',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      minWidth: 50,
      align: 'right',
      headerAlign: 'right',
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 2,
      ...DefaultColumn,
    },
    ...(revertFundingRequestEnabled
      ? [
          {
            field: 'actions',
            headerName: 'Actions',
            minWidth: 100,
            flex: 1,
            renderCell: ({ row }: { row: FundingRequest }) => {
              if (row.status === 'succeeded') {
                return (
                  <IconButton
                    onClick={() => handleRevertClick(row)}
                    size="small"
                    title="Undo funding request"
                    aria-label="Undo funding request"
                  >
                    <UndoIcon />
                  </IconButton>
                );
              }
              return null;
            },
          },
        ]
      : []),
  ] as ColumnDefinition[];

  return (
    <>
      {error && <ErrorNotification error={error} />}
      <ConfirmActionDialog
        open={confirmDialogOpen}
        handleConfirm={handleConfirmRevert}
        handleCancel={handleCancelRevert}
        title="Undo Funding Request"
      >
        Are you sure you want to undo this funding request? This action cannot be undone.
      </ConfirmActionDialog>
      <RoundedCard>
        <Table
          columns={columns}
          data={fundingRequestsPage}
          paginationMeta={metadata}
          onPageChange={onPageIndexChange}
          loading={false}
        />
      </RoundedCard>
    </>
  );
}
