import GreenHands from '@app/assets/images/svg/green_hands_with_dollar_sign.svg';
import GreenMessageBubble from '@app/assets/images/svg/green_heart_in_message_bubble.svg';
import GreenPerson from '@app/assets/images/svg/green_person.svg';
import CopyIcon from '@app/assets/images/svg/white_copy_icon.svg';
import Header from '@app/components/Navigation/Header';
import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import Divider from '@app/components/elements/Divider';
import PageContentWrapper from '@app/components/wrappers/PageContentWrapper';
import useCustomer from '@app/hooks/useCustomer';
import useWindowWidth from '@app/hooks/useWindowWidth';
import Modal, {
  ModalBodyContent,
  ModalHeader,
  ModalFooter,
} from '@atob-developers/shared/src/components/Modal';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { Button } from '@mui/material';
import { useState } from 'react';

const CopyLinkSection = () => {
  const customer = useCustomer();
  const windowWidth = useWindowWidth();
  const { addToast } = useToasts();

  const messageCopy =
    "Hey! I'm loving the fuel discounts on my AtoB Fuel Card and think you will too. Plus you'll get a $250 welcome offer if you sign up using my link: ";
  const referralLink = customer.unique_referral_url;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(messageCopy + referralLink).then(() => {
      addToast({ type: 'success', title: 'Message copied to clipboard' });
    });
  };

  if (!customer.show_referral_url) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2">
      <p className="text-default-primary font-semibold">Referral link</p>
      <div className="border-soft flex w-full items-center gap-2 rounded-md border px-3 py-2">
        <div className="text-default-primary max-h-30 flex-grow overflow-y-scroll text-sm sm:text-base">
          {messageCopy}
          <a
            href={referralLink}
            className="text-default-primary hover:text-default-secondary font-semibold underline"
          >
            {referralLink}
          </a>
        </div>
        <Button size="small" onClick={copyToClipboard}>
          <img src={CopyIcon} alt="Copy" />
          {windowWidth.isDesktop ? 'Copy message' : 'Copy'}
        </Button>
      </div>
    </div>
  );
};

const HowItWorksStep = ({
  icon,
  title,
  description,
}: {
  icon: string;
  title: string;
  description: string;
}) => (
  <div className="m-1 flex items-start gap-4">
    <div className="flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-full">
      <img src={icon} alt={title} />
    </div>
    <div>
      <p className="text-default-primary font-semibold">{title}</p>
      <p className="text-default-secondary">{description}</p>
    </div>
  </div>
);

export default function ReferralProgram() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const customer = useCustomer();

  return (
    <PageContentWrapper header={<Header title="Referrals" />}>
      <RoundedCard className="p-5">
        <h3 className="text-default-primary mb-2 text-lg font-semibold">
          Refer a Friend. Give $250, get $250 in rewards.
        </h3>
        <p className="text-default-secondary mb-4">
          {!customer.show_referral_url
            ? 'Unlock your unique referral link after making your first transaction with your new AtoB Fuel Card.'
            : 'Send a referral with a few clicks and earn rewards if your friend is approved for the AtoB Fuel Card & makes a first purchase'}
        </p>
        {customer.show_referral_url && <CopyLinkSection />}
        <Divider />
        <div>
          <h4 className="text-default-primary mb-6 font-semibold">How it works?</h4>
          <div className="flex flex-col gap-4">
            <HowItWorksStep
              icon={GreenMessageBubble}
              title="Invite your friends"
              description="Share your personalized referral link via email, text or any way you choose."
            />
            <HowItWorksStep
              icon={GreenPerson}
              title="Your friend signs up"
              description="They’ll get a $250 reward when they activate their card and meet spend goals within 90 days"
            />
            <HowItWorksStep
              icon={GreenHands}
              title="Earn $250 in rewards"
              description="Once your friend is approved for a credit line with the AtoB Fuel Card and makes their first purchase within 60 days of card approval, you'll earn a $250 referral reward applied as a statement credit to your account within 10 days."
            />
          </div>
          <div className="text-default-primary ml-3 mt-5">
            By taking part in the Referral Program you agree with the{' '}
            <span className="cursor-pointer font-semibold underline" onClick={openModal}>
              Terms and Conditions.
            </span>
          </div>
        </div>
      </RoundedCard>

      <Modal open={isModalOpen} toggle={closeModal}>
        <ModalHeader title="Referral Program Terms & Conditions" onClose={closeModal} />
        <ModalBodyContent>
          <div className="text-default-primary mb-4 text-sm">
            AtoB reserves the right to withhold or revoke rewards if it determines, at its sole
            discretion, that any fraudulent, abusive, or suspicious behavior has occurred in
            connection with the referral program.
          </div>
          <div className="text-default-primary mb-4 text-sm">
            Rewards are not retroactive and will not be issued to accounts closed, canceled, or
            otherwise inactive at the time the reward is processed.
          </div>
          <div className="text-default-primary mb-4 text-sm">
            <p className="text-default-primary font-semibold">Eligibility & Requirements:</p>
            <ul className="list-disc pl-6">
              <li className="text-default-primary mb-1">
                Referrals must be new customers and not engaged in any active sales process with
                AtoB.
              </li>
              <li className="text-default-primary mb-1">
                The referred party must apply using the unique referral link provided by AtoB.
              </li>
              <li className="text-default-primary mb-1">
                Rewards are not retroactive and will not be issued to accounts that are closed,
                canceled, or otherwise inactive at the time the reward is processed.
              </li>
            </ul>
          </div>
          <div className="text-default-primary mb-4 text-sm">
            <p className="text-default-primary font-semibold">Referrer Rewards:</p>
            <ul className="list-disc pl-6">
              <li className="text-default-primary mb-1">
                AtoB customers can refer as many people as they’d like.
              </li>
              <li className="text-default-primary mb-1">
                Referrers earn $250 per referred customer who completes a first transaction, with a
                maximum total reward of $500.
              </li>
              <li className="text-default-primary mb-1">
                This means customers can earn rewards for up to two referrals ($250 per referral)
                but will not receive additional rewards beyond $500, regardless of the number of
                customers successfully referred.
              </li>
            </ul>
          </div>
          <div className="text-default-primary mb-4 text-sm">
            <p className="text-default-primary font-semibold">Referral (Referee) Rewards:</p>
            <ul className="list-disc pl-6">
              <li className="text-default-primary mb-1">
                Referred customers are eligible to earn up to $500 in statement credits if they meet
                specific spend requirements within 90 days of account activation:
              </li>
              <ul className="list-disc pl-6">
                <li className="text-default-primary mb-1">
                  $250 statement credit after spending $3,000 within the first 90 days.
                </li>
                <li className="text-default-primary mb-1">
                  An additional $250 statement credit after spending $6,000 within the first 90
                  days.
                </li>
                <li className="text-default-primary mb-1">
                  Rewards are issued after 90 days as a statement credit once spending thresholds
                  are met.
                </li>
              </ul>
            </ul>
          </div>
          <div className="text-default-primary mb-4 text-sm">
            <p className="text-default-primary font-semibold">Program Modifications:</p>
            <ul className="list-disc pl-6">
              <li className="text-default-primary mb-1">
                AtoB reserves the right to modify, suspend, or terminate the referral program at any
                time without prior notice.
              </li>
              <li className="text-default-primary mb-1">
                All decisions regarding reward issuance and eligibility are final.
              </li>
            </ul>
          </div>
        </ModalBodyContent>
        <ModalFooter>
          <Button size="medium" onClick={closeModal} fullWidth>
            I Agree
          </Button>
        </ModalFooter>
      </Modal>
    </PageContentWrapper>
  );
}
