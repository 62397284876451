import { PaginationMeta } from '@app/@types/api.types';
import WalletIcon from '@app/assets/images/svg/wallet_icon.svg';
import ResponsiveTable from '@app/components/TableV2/ResponsiveTable';
import { DateAndTimeColumn, monospacedStyle } from '@app/components/TableV2/StandardColumns';
import { ColumnDefinition } from '@app/components/TableV2/Table';
import { DEFAULT_TRANSACTIONS_PAGE_SIZE } from '@app/hooks/paging/types';
import { formatDate } from '@atob-developers/shared/src/utils/formatters';
import {
  faReceipt,
  faArrowLeftToLine,
  faArrowRightFromLine,
  faClock,
  faCheck,
  faXmark,
  IconDefinition,
} from '@fortawesome/pro-regular-svg-icons';
import { faClock as faClockSolid } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip, IconButton } from '@mui/material';
import dayjs from 'dayjs';
import { ReactElement, ReactNode, useCallback, useMemo, useState } from 'react';
import { TransactionStatus, WalletTransaction } from '../WalletHome';
import TransactionReceiptModal from './TransactionReceiptModal';

const StatusPill = ({ status }: { status: TransactionStatus }) => {
  if (status === 'success') {
    return (
      <Chip
        color="green"
        size="small"
        label="Completed"
        icon={<FontAwesomeIcon icon={faCheck} />}
      />
    );
  }

  if (status === 'pending') {
    return (
      <Chip color="orange" size="small" label="Pending" icon={<FontAwesomeIcon icon={faClock} />} />
    );
  }

  if (status === 'failed') {
    return (
      <Chip color="red" size="small" label="Failed" icon={<FontAwesomeIcon icon={faXmark} />} />
    );
  }

  return null;
};

const extractNameAndAccountNumber = (data: string) => {
  const maskedPattern = /([•*xX]{4}\s?\d{4})/;
  const match = data.match(maskedPattern);
  if (match) {
    const number = match[0].replace(/[•*xX\s]/g, '');
    const name = data.replace(maskedPattern, '').trim();
    return [name, number];
  } else {
    return [data, ''];
  }
};

const displayAccountNumber = (number: string): string => `**** ${number.slice(-4)}`;

const displayTransferEndpoint = (
  data: string | null,
  method: string | null = null,
): string | ReactElement => {
  if (!data) {
    return (
      <div className="text-default-primary hidden items-center gap-2 md:flex">
        <img src={WalletIcon} alt="wallet" />
        Wallet
      </div>
    );
  }

  if (!isNaN(+data)) {
    return data.length > 4 ? displayAccountNumber(data) : data;
  }

  const [name, number] = extractNameAndAccountNumber(data);

  return (
    <div className="flex md:flex-col">
      {name}
      {number && (
        <div className="md:text-default-secondary md:font-normal">
          {displayAccountNumber(number)}
        </div>
      )}
      {method && (
        <div className="md:text-default-secondary md:font-normal">{formatMethod(method)}</div>
      )}
    </div>
  );
};

const formatMethod = (method: string): string => {
  switch (method) {
    case 'bank':
      return 'ACH';
    default:
      return method.toUpperCase();
  }
};

const IconWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <div className="border-soft bg-soft-primary absolute bottom-0.5 left-6 h-5 w-5 rounded-full border-2">
      {children}
    </div>
  );
};

const OperationIcon = ({ icon }: { icon: IconDefinition }) => {
  return (
    <FontAwesomeIcon
      icon={icon}
      className="border-soft text-default-secondary h-4 w-4 rounded-full border p-3"
    />
  );
};

const TransactionIcon = ({
  direction,
  status,
}: {
  direction: 'inbound' | 'outbound';
  status: TransactionStatus;
}) => {
  const renderIcon = useCallback(() => {
    if (status === 'pending') {
      return (
        <IconWrapper>
          <div className="flex h-full w-full items-center justify-center rounded-full">
            <FontAwesomeIcon icon={faClockSolid} className="text-orange h-4 w-4" />
          </div>
        </IconWrapper>
      );
    }

    if (status === 'success') {
      return (
        <IconWrapper>
          <div className="bg-success-primary flex h-full w-full items-center justify-center rounded-full">
            <FontAwesomeIcon icon={faCheck} className="text-contrast-primary h-3 w-3" />
          </div>
        </IconWrapper>
      );
    }

    if (status === 'failed') {
      return (
        <IconWrapper>
          <div className="bg-error-primary flex h-full w-full items-center justify-center rounded-full">
            <FontAwesomeIcon icon={faXmark} className="text-contrast-primary h-3 w-3" />
          </div>
        </IconWrapper>
      );
    }

    return null;
  }, [status]);

  return (
    <div className="relative shrink-0">
      {direction === 'outbound' ? (
        <OperationIcon icon={faArrowRightFromLine} />
      ) : (
        <OperationIcon icon={faArrowLeftToLine} />
      )}
      {renderIcon()}
    </div>
  );
};

const MobileRow = ({ row, onClick }: { row: WalletTransaction; onClick?: () => void }) => {
  const { amount, created_timestamp, direction, fee, status, receipt_url, source, destination } =
    row;
  const isOutbound = direction === 'outbound';

  const [sourceName, sourceNumber] = extractNameAndAccountNumber(source || 'Wallet');
  const [destinationName, destinationNumber] = extractNameAndAccountNumber(destination || 'Wallet');

  const displayEntity = (name: string, number: string) =>
    `${name} ${number.length >= 4 ? displayAccountNumber(number) : ''}`;

  const primaryDescription = isOutbound
    ? `To ${displayEntity(destinationName, destinationNumber)}`
    : `From ${displayEntity(sourceName, sourceNumber)}`;

  const secondaryDescription = isOutbound ? (
    <>
      <span className="text-default-secondary">From </span>
      <span className="font-medium">{displayEntity(sourceName, sourceNumber)}</span>
    </>
  ) : (
    <>
      <span className="text-default-secondary">To </span>
      <span className="font-medium">{displayEntity(destinationName, destinationNumber)}</span>
    </>
  );

  return (
    <div
      className="border-soft flex items-center justify-between border-b px-4 py-3"
      onClick={onClick}
    >
      <div className="flex flex-1 items-center gap-x-3">
        <TransactionIcon status={status} direction={direction} />
        <div className="flex flex-1 flex-col gap-0.5">
          <div className="flex items-center">
            <div className="text-default-primary mr-2 inline text-sm font-medium">
              {primaryDescription}
              {receipt_url && (
                <FontAwesomeIcon
                  icon={faReceipt}
                  className="text-default-tertiary align-center ml-2"
                  size="lg"
                />
              )}
            </div>
          </div>
          <div className="text-sm">{secondaryDescription}</div>
          <div className="text-default-secondary text-xs font-normal">
            {formatDate({
              dateValue: dayjs.unix(created_timestamp).toDate(),
              pattern: 'MMM D, YYYY, hh:mm A ',
            })}
          </div>
        </div>
      </div>
      <div className="flex flex-col items-end">
        <div className="text-base font-medium">
          {isOutbound ? '-' : '+'}
          {amount}
        </div>
        {fee && <div className="text-default-secondary text-xs font-normal">Fee: {fee}</div>}
      </div>
    </div>
  );
};
interface WalletTransactionsTableProps {
  transactionsPage: WalletTransaction[];
  metadata: PaginationMeta;
  onPageIndexChange: (pageIndex: number) => void;
}

export default function WalletTransactionsTable({
  transactionsPage,
  metadata,
  onPageIndexChange,
}: WalletTransactionsTableProps): ReactElement {
  const [selectedTransactionId, setSelectedTransactionId] = useState<string>();
  const [receiptModalOpen, setReceiptModalOpen] = useState(false);

  const columns = [
    {
      field: 'amount',
      headerName: 'Operation amount',
      sortable: false,
      minWidth: 180,
      renderCell: ({ row }) => (
        <div className="flex items-center">
          {row.direction === 'outbound' ? (
            <FontAwesomeIcon icon={faArrowRightFromLine} size="xl" />
          ) : (
            <FontAwesomeIcon icon={faArrowLeftToLine} size="xl" />
          )}
          <div style={monospacedStyle} className="ml-6">
            <div className="text-sm font-medium">
              {row.direction === 'outbound' ? '-' : '+'}
              {row.amount}
            </div>
            {row.fee && (
              <div className="text-default-secondary text-xs font-normal">Fee: {row.fee}</div>
            )}
          </div>
        </div>
      ),
    },
    {
      field: 'created_timestamp',
      ...DateAndTimeColumn('Date', 130, false),
      valueGetter: (value: number) => dayjs.unix(value).toDate(),
      minWidth: 140,
      sortable: false,
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 140,
      renderCell: ({ row }) => <StatusPill status={row.status} />,
      sortable: false,
    },
    {
      field: 'source',
      headerName: 'Source',
      minWidth: 180,
      flex: 3,
      cellClassName: 'font-medium',
      renderCell: ({ row }) => displayTransferEndpoint(row.source),
      sortable: false,
    },
    {
      field: 'destination',
      headerName: 'Destination',
      minWidth: 180,
      flex: 3,
      cellClassName: 'font-medium',
      renderCell: ({ row }) => displayTransferEndpoint(row.destination, row.method),
      sortable: false,
    },
    {
      field: 'description',
      headerName: 'Description',
      minWidth: 250,
      flex: 3,
      cellClassName: 'font-medium',
      sortable: false,
    },
    {
      field: 'receipt_button',
      headerName: '',
      minWidth: 70,
      flex: 1,
      renderCell: ({ row }) => (
        <>
          {row.receipt_url && (
            <IconButton onClick={() => onReceiptButtonClick(row.id)}>
              <FontAwesomeIcon icon={faReceipt} className="text-default-primary h-4 w-4" />
            </IconButton>
          )}
        </>
      ),
      sortable: false,
    },
  ] as ColumnDefinition[];

  const handlePageChange = (pageNumber: number) => {
    if (onPageIndexChange) {
      onPageIndexChange(pageNumber);
    }
  };

  const toggleModal = () => {
    setReceiptModalOpen(!receiptModalOpen);
  };

  const onReceiptButtonClick = (id: string) => {
    setReceiptModalOpen(true);
    setSelectedTransactionId(id);
  };

  const currentTransaction = useMemo(
    () => transactionsPage.find((row) => row.id === selectedTransactionId),
    [selectedTransactionId, transactionsPage],
  );

  return (
    <>
      <ResponsiveTable
        columns={columns}
        data={transactionsPage}
        loading={false}
        pageSize={DEFAULT_TRANSACTIONS_PAGE_SIZE}
        onPageChange={handlePageChange}
        paginationMeta={metadata}
        mobileItemRenderer={({ row }) => (
          <MobileRow row={row} onClick={() => onReceiptButtonClick(row.id)} />
        )}
      />
      {currentTransaction && (
        <TransactionReceiptModal
          merchant=""
          transaction={currentTransaction.id}
          destination={currentTransaction.destination}
          amount={currentTransaction.amount}
          datePosted={formatDate({
            dateValue: dayjs.unix(currentTransaction.created_timestamp).toDate(),
            pattern: 'MMMM D, YYYY',
          })}
          description={currentTransaction.description}
          receiptUrl={currentTransaction.receipt_url}
          modalToggle={toggleModal}
          showTransactionReceiptModal={receiptModalOpen}
        />
      )}
    </>
  );
}
