import { Coordinates, MyLocation } from '@app/@types/fuel_listings.types';
import { useFuelMapContext } from '@app/pages/FuelMap/FuelMapContext';
import { Button } from '@mui/material';

const mapLocationGenerator = (coordinates: Coordinates): MyLocation => ({
  location_type: 'map_area',
  place_id: 'map_location',
  formatted_address: 'Current Map Area',
  description: 'Current Map Area',
  address_components: [],
  structured_formatting: {
    main_text: 'Current Map Area',
    secondary_text: '',
  },
  geometry: {
    location: {
      lat: () => coordinates.latitude,
      lng: () => coordinates.longitude,
    },
  },
});

const SearchMoreButtonFixed = () => {
  const {
    gMap,
    mapCenterCoords,
    setBounds,
    setAddressCoords,
    setAddress,
    setFitBounds,
    setShowMoreButton,
    setOriginAddressIsEligibleForMarker,
  } = useFuelMapContext();

  const onFetchMore = () => {
    const bounds = gMap?.getBounds();
    if (bounds) {
      setBounds({
        top_left_longitude: bounds.getNorthEast().lng(),
        top_left_latitude: bounds.getNorthEast().lat(),
        bottom_right_longitude: bounds.getSouthWest().lng(),
        bottom_right_latitude: bounds.getSouthWest().lat(),
      });
    }
    setOriginAddressIsEligibleForMarker(false);
    mapCenterCoords && setAddress(mapLocationGenerator(mapCenterCoords));
    setAddressCoords(undefined);
    setFitBounds(false);
    setShowMoreButton(false);
  };

  return (
    <Button
      onClick={onFetchMore}
      disableElevation={false}
      classes={{ root: 'bg-soft-primary text-default-primary shadow-md' }}
    >
      Search this area
    </Button>
  );
};

export default SearchMoreButtonFixed;
