import useThemeMode from '@app/hooks/useThemeMode';
import classNames from 'classnames';

export default function OnboardingCard() {
  const { darkClassName } = useThemeMode();

  return (
    <div className={classNames(darkClassName, 'hidden h-screen flex-1 p-6 md:flex')}>
      <div className="border-soft bg-soft-secondary flex w-full flex-col justify-center gap-14 rounded-2xl border px-16 py-20">
        {/* TODO: placeholder for the onboarding visual */}
      </div>
    </div>
  );
}
