import ErrorMessage from '@atob-developers/shared/src/components/ErrorMessage';
import { faTruck, faTruckContainer, faTruckPickup } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { capitalize } from 'lodash-es';
import { ReactElement, useEffect } from 'react';

type fleetTypes = 'heavy' | 'mixed' | 'light';

const fleetTypeDescriptions = {
  heavy: 'Trucking fleet, diesel only',
  mixed: 'Fleet operating both light and heavy vehicles',
  light: 'Fleet operating local routes, unleaded and/or diesel',
};

const fleetTypeIcons = {
  heavy: faTruckContainer,
  mixed: faTruck,
  light: faTruckPickup,
};

export const FleetTypeForm = ({
  values,
  errors,
  setErrors,
  setValues,
  currentValue,
}: {
  values: Record<string, string | null>;
  setValues: (values: Record<string, string | null>) => void;
  errors: Record<string, string | undefined>;
  setErrors: (errors: Record<string, string | undefined>) => void;
  currentValue: string;
}): ReactElement => {
  useEffect(() => {
    if (values.fleet_type === undefined) {
      setValues({ ...values, fleet_type: currentValue });
    }
  }, [currentValue, setValues, values]);

  return (
    <>
      <div className="flex flex-col">
        <p className="text-default-secondary mb-2 text-sm">
          Knowing the type of vehicles in your fleet helps us filter the best fuel discounts for
          you.
        </p>
        <div className="flex flex-col gap-4">
          {Object.entries(fleetTypeDescriptions).map(([fleetType, description]) => (
            <button
              key={fleetType}
              onClick={(e) => {
                e.preventDefault();
                setErrors({ ...errors, fleet_type: undefined });
                setValues({ ...values, fleet_type: fleetType });
              }}
              className={classNames(
                'text-default-primary flex flex-col rounded-lg border-2 p-4 text-left transition-colors',
                fleetType === values.fleet_type
                  ? 'border-strong hover:border-strong-hover'
                  : 'border-soft hover:border-soft-hover',
              )}
            >
              <div className="flex items-start gap-4">
                <FontAwesomeIcon
                  icon={fleetTypeIcons[fleetType as fleetTypes]}
                  data-testid="vehicle-marker"
                  className="text-default-primary text-xl"
                />
                <div>
                  <h4 className="font-semibold">{capitalize(fleetType)}</h4>
                  <p className="text-default-secondary text-sm">{description}</p>
                </div>
              </div>
            </button>
          ))}
        </div>
        <ErrorMessage error={errors['fleet_type']} />
      </div>
    </>
  );
};
