import defaultTheme from '@atob-developers/shared/src/theme/defaultTheme';
import { createTheme } from '@mui/material';
import tw from 'twin.macro';

export default createTheme({
  ...defaultTheme,
  shape: {
    ...tw`!rounded-none`,
  },
});
