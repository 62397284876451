import RaceTracIcon from '@app/assets/images/svg/racetrac-logo.svg';
import SpeedwayIcon from '@app/assets/images/svg/speedway-logo.svg';
import TAPetroIcon from '@app/assets/images/svg/ta-petro-logo.svg';
import { formatCurrencyToNearestDollarFloor } from '@app/utils/challenge-match-card';
import logger from '@app/utils/datadog-logger';
import { MobileFriendlyTooltip } from '@atob-developers/shared/src/components/MobileFriendlyTooltip';
import Modal, {
  ModalBodyContent,
  ModalInfo,
  ModalCoreItem,
  ModalHeader,
  ModalFooter,
} from '@atob-developers/shared/src/components/Modal';
import { faChevronDown, faClock, faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import ChallengeMatchTracker from './ChallengeMatchTracker';
import { useChallengeMatch } from './useChallengeMatch';

export default function WeeklyDiscountBoostModal({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const { challengeMatch, currentDiscountBoost, isTAOnly } = useChallengeMatch();

  return (
    <Modal open={open} toggle={onClose} maxWidth="lg">
      <ModalHeader
        onClose={onClose}
        title={isMobile ? 'Unlock extra discounts' : ''}
        secondaryTitle={
          isMobile ? 'Get extra discounts by using your AtoB card at TA, RaceTrac & Speedway.' : ''
        }
      ></ModalHeader>
      <ModalBodyContent>
        <div className="flex flex-col md:flex-row">
          <div className="border-l-soft absolute left-1/2 top-0 hidden h-full -translate-x-px border-l md:block" />
          <div className="flex flex-1 flex-col items-center md:justify-between md:pr-8">
            <div className="my-auto">
              <ChallengeMatchTracker />
            </div>
            <ModalInfo className="w-full justify-self-end">
              <ModalCoreItem
                leftContent="Current discount boost"
                rightContent={`${currentDiscountBoost}¢ per gal`}
                info={
                  <MobileFriendlyTooltip
                    arrow
                    placement="top"
                    onMouseEnter={() => {
                      logger.info('weekly-discount-boost-modal-current-boost-tooltip', {
                        from: 'tooltip',
                      });
                    }}
                    onTouchStart={() => {
                      logger.info('weekly-discount-boost-modal-current-boost-tooltip', {
                        from: 'tooltip',
                      });
                    }}
                    title={
                      <div className="space-y-1">
                        <p className="text-contrast-primary">Current boost</p>
                        <p className="text-contrast-secondary font-normal">
                          Your current boost is whichever is larger: the boost you achieved last
                          week or so far this week.
                        </p>
                      </div>
                    }
                  >
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      className="text-default-secondary h-4 w-4"
                    />
                  </MobileFriendlyTooltip>
                }
              />
              {isTAOnly ? (
                <ModalCoreItem
                  leftContent="Merchants to spend at"
                  rightContent={<img src={TAPetroIcon} className="h-8 w-8 flex-shrink-0" />}
                />
              ) : challengeMatch?.partners && challengeMatch?.partners.length > 1 ? (
                <ModalCoreItem
                  leftContent="Merchants to spend at"
                  rightContent={
                    <div className="flex gap-1">
                      <img src={TAPetroIcon} className="h-8 w-8 flex-shrink-0" />
                      <img src={RaceTracIcon} className="h-8 w-8 flex-shrink-0" />
                      <img src={SpeedwayIcon} className="h-8 w-8 flex-shrink-0" />
                    </div>
                  }
                />
              ) : null}
            </ModalInfo>
          </div>
          <div className="flex-1 space-y-4 md:pl-8">
            <div className="hidden flex-col gap-1.5 md:flex">
              <div className="min-h-8 text-default-primary text-2xl font-semibold tracking-tight">
                Weekly Discount Boost
              </div>
              <p className="text-default-secondary text-base font-normal">
                Meet weekly spend goals using your AtoB card at TA, RaceTrac, and Speedway to unlock
                extra fuel discounts.
              </p>
            </div>
            <ModalInfo title="This week">
              <ModalCoreItem
                leftContent="Spend so far"
                rightContent={formatCurrencyToNearestDollarFloor(
                  challengeMatch?.this_week.settled_amt.cents ?? 0,
                )}
              />
              <ModalCoreItem
                leftContent="Boost level achieved"
                rightContent={`${
                  challengeMatch?.this_week.achieved_level?.cents_back ?? 0
                }¢ per gal`}
              />
            </ModalInfo>
            <ModalInfo title="Last week">
              <ModalCoreItem
                leftContent="Total spent"
                rightContent={formatCurrencyToNearestDollarFloor(
                  challengeMatch?.last_week.settled_amt.cents ?? 0,
                )}
              />
              <ModalCoreItem
                leftContent="Boost level achieved"
                rightContent={`${
                  challengeMatch?.last_week.achieved_level?.cents_back ?? 0
                }¢ per gal`}
              />
              {challengeMatch?.last_week.discount_boost_amt ? (
                <ModalCoreItem
                  leftContent="Discount boost earnings"
                  rightContent={formatCurrencyToNearestDollarFloor(
                    challengeMatch?.last_week.discount_boost_amt.cents ?? 0,
                  )}
                />
              ) : (
                <ModalCoreItem
                  leftContent="Discount boost earnings"
                  rightContent={
                    <MobileFriendlyTooltip
                      arrow
                      placement="top"
                      title={
                        <span className="text-default-tertiary font-normal">
                          Earnings for the prior week are calculated every Wednesday.
                        </span>
                      }
                      onMouseEnter={() => {
                        logger.info('weekly-discount-boost-modal-pending-tooltip', {
                          from: 'tooltip',
                        });
                      }}
                      onTouchStart={() => {
                        logger.info('weekly-discount-boost-modal-pending-tooltip', {
                          from: 'tooltip',
                        });
                      }}
                    >
                      <Chip
                        label="Pending"
                        icon={<FontAwesomeIcon icon={faClock} />}
                        color="grey"
                        size="medium"
                      />
                    </MobileFriendlyTooltip>
                  }
                />
              )}
            </ModalInfo>
            <Accordion className="rounded-lg">
              <AccordionSummary
                expandIcon={
                  <FontAwesomeIcon icon={faChevronDown} className="text-default-primary" />
                }
                classes={{ root: 'min-h-fit', content: 'mt-4 mb-3' }}
              >
                <span className="text-default-primary text-base font-medium">
                  How does it work?
                </span>
              </AccordionSummary>
              <AccordionDetails>
                <div className="space-y-3">
                  <ol className="text-default-secondary list-decimal px-4 text-sm">
                    <li>
                      Spend enough to reach the weekly boost targets – the more you spend, the
                      bigger your discount boost.
                    </li>
                    <li>Use your discount boost for the rest of the week AND all of next week.</li>
                    <li>Repeat every week to keep getting your discount boost.</li>
                  </ol>
                  <p className="text-default-secondary text-sm">Yes, it's that simple</p>
                </div>
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={
                  <FontAwesomeIcon icon={faChevronDown} className="text-default-primary" />
                }
                classes={{ root: 'min-h-fit', content: 'mt-4 mb-3' }}
              >
                <span className="text-default-primary text-base font-medium">
                  How is my Discount Boost credit calculated?
                </span>
              </AccordionSummary>
              <AccordionDetails>
                <div className="text-default-secondary space-y-3 text-sm">
                  <p>
                    The Discount Boost applies to every applicable gallon of diesel and unleaded
                    fuel you buy after you achieve the boost.
                  </p>
                  <p>
                    Your boost remains active for the rest of the week AND all of the week after.
                  </p>
                  <p>
                    Boost earnings will be calculated at the end of the week and shown as a credit
                    on your next billing statement.
                  </p>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </div>
      </ModalBodyContent>
      <ModalFooter />
    </Modal>
  );
}
