import classNames from 'classnames';
import { ReactElement } from 'react';
import { Step, StepStatus, UpcomingStep } from '../steps.types';

export const isUpcoming = (step: Step): step is UpcomingStep => step.status === StepStatus.UPCOMING;

export const Upcoming = ({ step }: { step: UpcomingStep }): ReactElement => {
  const { id, name, href, required, renderIcon, disableIcon } = step;
  return (
    <a
      data-testid={`upcoming-${id}`}
      href={href}
      className={classNames('group flex ', {
        'cursor-default': !href,
        'flex-col items-start': !!step.description,
        'items-center': !step.description,
      })}
    >
      <span className="flex items-center px-6 py-4 text-sm font-medium">
        {!disableIcon && (
          <span className="border-soft group-hover:border-soft-hover flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2">
            <span className="text-default-secondary group-hover:text-default-primary">
              {renderIcon ? renderIcon(id) : id}
            </span>
          </span>
        )}
        <div className={classNames('flex flex-col text-sm font-medium', !disableIcon && 'ml-4')}>
          {required && <span className="text-default-secondary">Required</span>}
          <span
            className={
              required
                ? 'text-default-primary'
                : 'text-default-secondary group-hover:text-default-primary'
            }
          >
            {name}
          </span>
        </div>
      </span>

      {step.description && (
        <div className="text-default-secondary hidden pb-4 pl-6 text-xs sm:block">
          {step.description}
        </div>
      )}
    </a>
  );
};
