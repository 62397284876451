export default function SyncTelematicsVisual() {
  return (
    <div className="relative hidden h-screen flex-1 p-8 md:flex">
      <img
        src="images/onboarding/onboarding-gradient-background.webp"
        className="w-full rounded-2xl object-cover"
      />
      <img
        src="images/onboarding/telematics-asset.webp"
        className="absolute left-1/2 top-1/2 max-h-[80vh] max-w-[30vw] -translate-x-1/2 -translate-y-1/2 transform"
      />
    </div>
  );
}
