import { FlattenedPayrollData } from '@app/@types/payroll.types';
import { ErrorNotification } from '@app/components/layout';
import WarningNotification from '@app/components/layout/WarningNotification';
import { guardAxiosError } from '@app/utils/error/guards';
import Modal, {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { ReactElement, useContext, useState } from 'react';

import PayrollContext from '../payroll-context';
import StatusPill from './StatusPill';

export default function DriverOptionsModal({
  isActive,
  reset,
  driver,
}: {
  isActive: boolean;
  reset: () => void;
  driver?: FlattenedPayrollData;
}): ReactElement {
  const { currentDriver } = useContext(PayrollContext);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const { addToast } = useToasts();
  const driverToActUpon = driver || currentDriver;

  const closeModal = () => {
    setError('');
    setSuccess(false);
    reset();
  };

  const resendInvite = async () => {
    if (success) {
      return;
    }
    setLoading(true);

    try {
      await axios.post(`/drivers/${driverToActUpon.id}/payroll_invite`);
      setLoading(false);
      setSuccess(true);
      addToast({
        type: 'success',
        title: `Link sent to ${driverToActUpon.first_name} ${
          driverToActUpon.email ? `at ${driverToActUpon.email}.` : ''
        }`,
      });
    } catch (e: unknown) {
      if (!guardAxiosError(e)) {
        return;
      }

      const message = e.response?.data?.errors?.[0] || '';
      setLoading(false);
      setSuccess(false);
      setError(
        `There was a problem sending the update link. ${message.concat(
          message.length > 0 ? '. ' : '',
        )}`,
      );
    }
  };

  const buttonText = success ? 'Link sent!' : 'Send reset link';

  return (
    <>
      <Modal open={isActive} toggle={closeModal}>
        <ModalHeader title="Driver Options" onClose={closeModal} />
        <ModalBodyContent>
          {error && <ErrorNotification error={error} />}
          <div className="flex w-full items-baseline justify-between leading-4">
            <h3 className="text-default-primary font-bold leading-5">{driverToActUpon.name}</h3>
            <div>
              <StatusPill
                isMobile={false}
                status={driverToActUpon.status}
                taxClassification={driverToActUpon.tax_classification}
              />
            </div>
          </div>
          <div className="text-default-primary text-sm">{driverToActUpon.email}</div>
          <div className="text-default-primary text-sm">{driverToActUpon.phone}</div>
          {driverToActUpon.status === 'update_required' && (
            <div className="my-4">
              <WarningNotification>
                {driverToActUpon.name} needs to update their payment details. Please send a reset
                link below to get them back online.{' '}
              </WarningNotification>
            </div>
          )}
          <div className="align-start w-full flex-col">
            <p className="text-default-secondary mt-4 text-base">
              Send {driverToActUpon.first_name} a link to update or reset their payment information.
            </p>
          </div>
        </ModalBodyContent>
        <ModalFooter>
          {driver ? (
            <LoadingButton onClick={resendInvite} size="small" loading={loading}>
              {buttonText}
            </LoadingButton>
          ) : (
            <LoadingButton size="small" loading={loading} onClick={resendInvite}>
              {buttonText}
            </LoadingButton>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
}
