import useThemeMode from '@app/hooks/useThemeMode';
import { useFuelMapContext } from '@app/pages/FuelMap/FuelMapContext';
import { useFuelMap } from '@app/pages/FuelMap/useFuelMap';
import { getEnvironment } from '@app/utils/environment';
import GoogleMap from 'google-maps-react-markers';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MapLabel from './FuelListingMapLabel';
import FuelListingMapLabelFixed from './FuelListingMapLabelFixed';
import LoadingComponent from './LoadingComponent';
import darkMap from './MapStyles/darkMap.json';
import lightMap from './MapStyles/lightMap.json';

const DEFAULT_ZOOM_LEVEL = 12;

const FuelListingsMap = () => {
  const [googleMap, setGoogleMap] = useState<google.maps.Map | null>(null);
  const [mapsApi, setMapsApi] = useState<typeof google.maps | null>(null);

  const { id: selectedFuelListingId } = useParams();

  const { VITE_GOOGLE_API_KEY, VITE_MAP_ID_FUEL_FINDER } = getEnvironment();

  const { isDarkThemeOn } = useThemeMode();

  const {
    setPlacesService,
    setGMap,
    routing,
    setMapCenterCoords,
    setShowMoreButton,
    fitBounds,
    selectedGrade,
    mapCenterCoords,
    destinationCoords,
    addressCoords,
    originAddressIsEligibleForMarker,
    gmapScriptStatus: status,
    setSelectedFuelListingId,
    discountOnly,
  } = useFuelMapContext();

  const navigate = useNavigate();

  const showCenterMarker = destinationCoords === undefined && originAddressIsEligibleForMarker;

  const {
    isFetching,
    filteredFuelData: fuelListings,
    routeCoordinates,
    onSelectFuelListing: onSelect,
  } = useFuelMap();

  const onMapLoad = (map: google.maps.Map) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const placesService = new (window as any).google.maps.places.PlacesService(map);
    setPlacesService(placesService);
    setGMap(map);
  };

  const onMapChange = useCallback(
    ({ latitude, longitude }: { latitude?: number; longitude?: number }) => {
      if (routing) return;
      if (!latitude || !longitude) {
        setMapCenterCoords(undefined);
      } else {
        setMapCenterCoords({ latitude, longitude });
      }

      setShowMoreButton(true);
      setSelectedFuelListingId(null);
      navigate('/fuel-map');
    },
    [navigate, routing, setMapCenterCoords, setSelectedFuelListingId, setShowMoreButton],
  );

  const apiIsLoaded = ({ map, maps }: { map: google.maps.Map; maps: typeof google.maps }) => {
    setGoogleMap(map);
    setMapsApi(maps);
    onMapLoad(map);
  };

  const onCenterMove = useCallback(() => {
    if (googleMap != null) {
      const center = googleMap.getCenter();
      onMapChange({ latitude: center?.lat(), longitude: center?.lng() });
    }
  }, [googleMap, onMapChange]);

  useEffect(() => {
    let dragListener: google.maps.MapsEventListener | null;
    if (mapsApi && googleMap) {
      dragListener = mapsApi.event.addListener(googleMap, 'dragend', () => {
        // 'dragend' fires on mouse release.
        // 'idle' listener waits until drag inertia ends before firing `onDragEnd`
        const idleListener = mapsApi.event.addListener(googleMap, 'idle', () => {
          mapsApi.event.removeListener(idleListener);
          onCenterMove();
        });
      });
    }
    return () => {
      dragListener && mapsApi && mapsApi.event.removeListener(dragListener);
    };
  }, [googleMap, mapsApi, onCenterMove]);

  const lineRef = useRef<google.maps.Polyline | null>(null);
  useEffect(() => {
    if (!googleMap || !mapsApi) {
      return;
    }

    if (!routeCoordinates || routeCoordinates.length === 0) {
      lineRef.current?.setMap(null);
      lineRef.current = null;
      if (fitBounds) {
        if (fuelListings.length > 0) {
          const bounds = new mapsApi.LatLngBounds();
          fuelListings.forEach((marker) => {
            bounds.extend(
              new mapsApi.LatLng(
                marker.attributes.location.coordinates.latitude,
                marker.attributes.location.coordinates.longitude,
              ),
            );
          });
          if (addressCoords != null) {
            bounds.extend(new mapsApi.LatLng(addressCoords.latitude, addressCoords.longitude));
          }
          googleMap.fitBounds(bounds);
        } else {
          if (addressCoords != null) {
            googleMap.panTo(
              new google.maps.LatLng(addressCoords.latitude, addressCoords.longitude),
            );
          }
          setZoom(DEFAULT_ZOOM_LEVEL);
          googleMap.setZoom(DEFAULT_ZOOM_LEVEL);
        }
      }
      return;
    }

    const latLngCoordinates = routeCoordinates.map((coordinate) => ({
      lat: coordinate.latitude,
      lng: coordinate.longitude,
    }));

    const line = new mapsApi.Polyline({
      path: latLngCoordinates,
      geodesic: true,
      strokeColor: '#111827',
      strokeOpacity: 1.0,
      strokeWeight: 4,
      icons: [
        {
          icon: {
            path: mapsApi.SymbolPath.CIRCLE,
            fillColor: '#ffffff',
            fillOpacity: 1,
            strokeColor: '#111827',
            strokeWeight: 3,
            scale: 5,
          },
          repeat: '100%',
        },
      ],
    });

    line.setMap(googleMap);
    lineRef.current = line;

    const bounds = new mapsApi.LatLngBounds();
    latLngCoordinates.forEach((latLng) => {
      bounds.extend(latLng);
    });

    googleMap.fitBounds(bounds);
  }, [addressCoords, fitBounds, fuelListings, googleMap, mapsApi, routeCoordinates]);

  const [zoom, setZoom] = useState(DEFAULT_ZOOM_LEVEL);

  const markers = useMemo(
    () =>
      fuelListings
        .filter((item) => item.attributes.site_id.toString() != selectedFuelListingId)
        .map((fuelListing) => (
          <MapLabel
            lat={fuelListing.attributes.location.coordinates.latitude}
            lng={fuelListing.attributes.location.coordinates.longitude}
            key={fuelListing.id + String(discountOnly)}
            fuelListing={fuelListing}
            selectedGrade={selectedGrade}
            highlighted={selectedFuelListingId === fuelListing.attributes.site_id.toString()}
            onSelect={onSelect}
          />
        ))
        .reverse(),
    [discountOnly, fuelListings, onSelect, selectedFuelListingId, selectedGrade],
  );

  const selectedMarker =
    selectedFuelListingId != null
      ? fuelListings
          .filter((item) => item.attributes.site_id.toString() === selectedFuelListingId)
          .map((fuelListing) => (
            <MapLabel
              lat={fuelListing.attributes.location.coordinates.latitude}
              lng={fuelListing.attributes.location.coordinates.longitude}
              key={fuelListing.id}
              fuelListing={fuelListing}
              selectedGrade={selectedGrade}
              highlighted={selectedFuelListingId === fuelListing.attributes.site_id.toString()}
              onSelect={onSelect}
            />
          ))
      : null;

  const mapCenterCoordsForGoogle = useMemo(() => {
    if (mapCenterCoords) {
      return { lat: mapCenterCoords?.latitude, lng: mapCenterCoords?.longitude };
    }
    return null;
  }, [mapCenterCoords]);

  if (isFetching || !mapCenterCoordsForGoogle || status !== 'ready') {
    return <LoadingComponent />;
  }

  return (
    <GoogleMap
      apiKey={String(VITE_GOOGLE_API_KEY)}
      defaultCenter={mapCenterCoordsForGoogle}
      defaultZoom={zoom}
      loadScriptExternally={true}
      status={status}
      onChange={(e: { zoom: number }) => {
        setZoom(e.zoom);
      }}
      onGoogleApiLoaded={(api: { map: google.maps.Map; maps: typeof google.maps }) =>
        apiIsLoaded(api)
      }
      options={{
        disableDefaultUI: true,
        scaleControl: true,
        zoomControl: true,
        fullscreenControl: false,
        clickableIcons: false,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_TOP,
        },
        mapId: VITE_MAP_ID_FUEL_FINDER,
        styles: isDarkThemeOn ? darkMap : lightMap,
      }}
    >
      {markers}
      {selectedMarker}
      {showCenterMarker && addressCoords && (
        <FuelListingMapLabelFixed lat={addressCoords.latitude} lng={addressCoords.longitude} />
      )}
    </GoogleMap>
  );
};

export default FuelListingsMap;
