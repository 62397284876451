import { SecurityDepositNetNewModal } from '@app/components/Modals/SecurityDepositModals/SecurityDepositNetNewModal';
import { SecurityDeposit } from '@app/components/Modals/SecurityDepositModals/SecurityDepositOfferModal';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import { getFetcher } from '@app/utils/data/fetchers';
import { faCircleExclamation, faPartyHorn } from '@fortawesome/pro-regular-svg-icons';
import { ReactNode, useState } from 'react';
import useSWR from 'swr';
import { MarketingBanner, TitleVariant } from './Designs/MarketingBanner';

export type SecurityDepositNetNewOfferDetails = {
  offer_type: 'fixed' | 'variable';
  offer_open: boolean;
  offer_accepted: boolean;
  deposit_amount?: number;
  credit_limit?: number;
  max_credit_limit?: number;
  deposit_percentage?: number;
  existing_deposit?: SecurityDeposit;
};

export const SecurityDepositNetNewBanner = () => {
  const [SECURITY_DEPOSIT_NET_NEW_OFFER_OPEN, SECURITY_DEPOSIT_NET_NEW_ENROLLED] = useFeatureFlags(
    'security_deposit_net_new_offer_open',
    'security_deposit_net_new_enrolled',
  );

  const isSecurityDepositCustomer =
    SECURITY_DEPOSIT_NET_NEW_OFFER_OPEN || SECURITY_DEPOSIT_NET_NEW_ENROLLED;

  const {
    data: offerDetails,
    isLoading,
    mutate: refetchOfferDetails,
  } = useSWR<SecurityDepositNetNewOfferDetails>(
    isSecurityDepositCustomer ? { url: `/security_deposits/net_new_offer_details` } : null,
    getFetcher,
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  let bannerCopy: ReactNode = '';
  let ctaCopy = '';
  let icon = faPartyHorn;
  let title: TitleVariant = 'Offer';

  if (offerDetails?.offer_open) {
    bannerCopy =
      'You are approved for a credit line by paying a security deposit! Complete steps to activate and get your fuel cards shipped.';
    ctaCopy = 'Activate Credit Line';
  } else if (offerDetails?.offer_accepted) {
    icon = faCircleExclamation;
    title = 'Attention';
    bannerCopy = (
      <>
        <div className="font-bold">ACTION NEEDED to activate your credit line</div>
        <div>
          Your fuel cards have been shipped. Pay the security deposit to activate your credit line.
        </div>
      </>
    );
    ctaCopy = 'Pay Security Deposit';
  }

  if (!isSecurityDepositCustomer) {
    return null;
  }

  if (
    isLoading ||
    !offerDetails ||
    ['deposit_paid', 'fully_refunded', 'partially_refunded', 'closed'].includes(
      offerDetails.existing_deposit?.status || '',
    )
  ) {
    return null;
  }

  return (
    <>
      <SecurityDepositNetNewModal
        open={isModalOpen}
        toggleModal={() => setIsModalOpen(false)}
        offerDetails={offerDetails}
        refetchOfferDetails={refetchOfferDetails}
      />
      <MarketingBanner
        onClick={() => setIsModalOpen(true)}
        actionText={ctaCopy}
        bannerKey="security-deposit-net-new"
        title={title}
        icon={icon}
        nonDismissable
      >
        {bannerCopy}
      </MarketingBanner>
    </>
  );
};
