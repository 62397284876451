import WelcomeBonusBanner from '@app/components/Banner/WelcomeBonusBanner';
import Header from '@app/components/Onboarding/Header';
import useCustomer from '@app/hooks/useCustomer';
import { Button, Step, StepLabel, Stepper } from '@mui/material';
import { groupBy } from 'lodash-es';
import { STEP_MAP } from '../OnboardingStepMap';
import { useOnboardingGuide } from '../useOnboardingGuide';

export default function OnboardingWelcomeToAtoB() {
  const { goToNextStep } = useOnboardingGuide();
  const { interstitials } = useCustomer();

  if (interstitials == null) {
    return null;
  }

  const groups = groupBy(interstitials.steps, ({ grouping }) => grouping);

  const groupArr = Object.values(groups).filter(
    (group) => group && STEP_MAP[group[0].name] && STEP_MAP[group[0].name].blurb != null,
  );

  let startingStep = groupArr.findIndex((groupItems) =>
    groupItems.every((item) => item.status === true),
  );

  if (startingStep > -1) {
    startingStep += 1;
  }

  const [title, ...subtitles] = interstitials.title;

  return (
    <div className="flex flex-1 flex-col gap-6 md:gap-8">
      <WelcomeBonusBanner />
      <Header title={title} description={subtitles.join(' ')} />
      <div className="grow">
        <Stepper activeStep={startingStep} orientation="vertical">
          {groupArr.map((group) => (
            <Step key={group[0].grouping}>
              <StepLabel
                classes={{
                  active: 'shadow-none',
                }}
              >
                {STEP_MAP[group[0].name].blurb}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
      <Button onClick={goToNextStep}>Get started</Button>
    </div>
  );
}
