import FleetioLogo from '@app/assets/images/png/fleetio-small.png';
import { useFleetioConnectionQuery } from '@app/hooks/query/useFleetioConnectionQuery';
import { CircularProgress } from '@mui/material';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import ProviderCard from '../ProviderCard';

const FleetioIntegration = (): ReactElement => {
  const { fleetioConnected, isLoading } = useFleetioConnectionQuery();
  const navigate = useNavigate();

  return (
    <div className="mb-8">
      <h2 className="text-default-primary mb-4 font-semibold">Other Integrations</h2>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3">
          <ProviderCard
            displayName="Fleetio"
            logo={FleetioLogo}
            connected={fleetioConnected}
            onAction={() => navigate('/fleetio/connect')}
            actionLabel={fleetioConnected ? 'Disconnect' : 'Connect'}
          />
        </div>
      )}
    </div>
  );
};

export default FleetioIntegration;
