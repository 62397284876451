import { FilterDropdownDate } from '@app/components/Filters/FilterDropdownDate';
import FilterGroup from '@app/components/Filters/FilterGroup';
import { CardsFilter } from '@app/components/Filters/ResourceFilters/CardsFilter';
import { DriversFilter } from '@app/components/Filters/ResourceFilters/DriversFilter';
import { TagsFilter } from '@app/components/Filters/ResourceFilters/TagsFilter';
import { TransactionSavingsFilter } from '@app/components/Filters/ResourceFilters/TransactionSavingsFilter';
import { VehiclesFilter } from '@app/components/Filters/ResourceFilters/VehiclesFilter';
import { ChargeEventQueryParams } from '@app/contexts/ChargeEventsContextComponent';
import useProduct from '@app/hooks/useProduct';
import { ReactElement } from 'react';

const TransactionFilters = ({
  selectedFilters,
  setSelectedFilters,
  clearFilters,
}: {
  selectedFilters: ChargeEventQueryParams;
  setSelectedFilters: (v: Partial<ChargeEventQueryParams>) => void;
  clearFilters?: () => void;
}): ReactElement => {
  const [resourceTagsEnabled] = useProduct('resource_tags');

  return (
    <FilterGroup className="border-b-soft !mx-0 border-b !py-4 px-6" clearFilters={clearFilters}>
      <TransactionSavingsFilter
        onTypeSelect={(value: 'all' | 'discounted' | 'potential_savings') => {
          if (value === 'all') {
            setSelectedFilters({ with_savings: false, with_potential_savings: false });
          }
          if (value === 'discounted') {
            setSelectedFilters({ with_savings: true, with_potential_savings: false });
          }
          if (value === 'potential_savings') {
            setSelectedFilters({ with_savings: false, with_potential_savings: true });
          }
        }}
      />
      {resourceTagsEnabled && (
        <TagsFilter
          onTagsSelect={(values) => setSelectedFilters({ by_tag_ids: values })}
          selectedTagIds={selectedFilters.by_tag_ids}
        />
      )}
      <FilterDropdownDate
        onDateRangeChange={(start, to) =>
          setSelectedFilters({
            'filter_authorized_at[from]': start,
            'filter_authorized_at[to]': to,
          })
        }
        fromDate={selectedFilters['filter_authorized_at[from]']}
        toDate={selectedFilters['filter_authorized_at[to]']}
      />
      <CardsFilter
        onCardsSelect={(values) => setSelectedFilters({ by_card_ids: values })}
        selectedCardIDs={selectedFilters.by_card_ids}
      />
      <DriversFilter
        onDriversSelect={(values) => setSelectedFilters({ by_driver_ids: values })}
        selectedDriverIDs={selectedFilters.by_driver_ids}
      />
      <VehiclesFilter
        onVehiclesSelect={(values) => setSelectedFilters({ by_vehicle_ids: values })}
        selectedVehicleIDs={selectedFilters.by_vehicle_ids}
      />
    </FilterGroup>
  );
};

export default TransactionFilters;
