import {
  faCircleExclamation,
  faCircleInfo,
  faCheck,
  faCircleCheck,
  faMinus,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StepIconProps } from '@mui/material';
import { createTheme, ThemeOptions } from '@mui/material/styles';
import classNames from 'classnames';
import tw, { theme as tailwindTheme } from 'twin.macro';

function StepIconComponent(props: StepIconProps) {
  const { active, completed, icon } = props;

  if (completed) {
    return <FontAwesomeIcon icon={faCheck} className="text-contrast-primary h-5 w-5" />;
  }

  return (
    <span
      className={classNames(
        'text-center text-base font-semibold',
        active ? 'text-default-primary' : 'text-default-secondary',
      )}
    >
      {icon}
    </span>
  );
}

export default createTheme({
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          ...tw`rounded-t-lg bg-strong-secondary shadow-none border-none before:content-none last-of-type:rounded-b-lg`,
        },
      },
    },
    MuiStepper: {
      variants: [
        {
          props: { orientation: 'vertical' },
          style: {
            '&': {
              ...tw`py-6 pr-6`,
            },
            '& .MuiStepConnector-root': {
              ...tw`ml-4 w-0.5 -translate-x-1/2`,
              '& .MuiStepConnector-line': {
                ...tw`border-none h-8 bg-strong-secondary my-1`,
              },
            },
            '& .MuiStep-root': {
              ...tw`relative`,
              '&.Mui-completed + .MuiStepConnector-root .MuiStepConnector-line': {
                ...tw`bg-strong-primary`,
              },
              '& + .MuiStepConnector-root': {
                '&.Mui-active .MuiStepConnector-line': {
                  ...tw`mt-1 mb-0.5`,
                },
              },
              '& .MuiStepLabel-root': {
                ...tw`p-0 space-x-3`,
                '& .MuiStepLabel-iconContainer': {
                  ...tw`flex items-center justify-center w-8 h-8 p-1 rounded-full bg-strong-secondary`,
                },
                '& .MuiStepLabel-iconContainer.Mui-completed': {
                  ...tw`p-1.5 bg-strong-primary`,
                },
                '& .MuiStepLabel-iconContainer.Mui-active': {
                  ...tw`outline outline-offset-2`,
                },
                '& .MuiStepLabel-labelContainer .MuiStepLabel-label': {
                  ...tw`text-default-primary text-sm font-medium`,
                },
              },
            },
          },
        },
      ],
    },
    MuiStepLabel: {
      defaultProps: {
        StepIconComponent,
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          ...tw`h-[6px] rounded-full`,
        },
        rail: {
          ...tw`bg-strong-secondary opacity-100`,
        },
        track: {
          ...tw`bg-strong-primary`,
        },
        thumb: {
          ...tw`w-6 h-6 bg-soft-secondary border-2 border-strong rounded-xl shadow-marker`,
          '&:hover': {
            ...tw`bg-soft-secondary-hover`,
          },
          '&:active': {
            ...tw`shadow-primary bg-soft-secondary-clicked`,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          ...tw`flex items-center self-stretch rounded bg-soft-primary`,
          '& .Mui-disabled': {
            ...tw`opacity-40`,
            '-webkit-text-fill-color': 'unset',
          },
          '& .MuiInputBase-input': {
            ...tw`p-0 placeholder:text-default-secondary text-default-primary placeholder:opacity-100 h-auto`,
            '&.Mui-disabled': {
              ...tw`opacity-40`,
              '-webkit-text-fill-color': 'unset',
            },
          },
          '& fieldset': {
            ...tw`border border-soft top-0`,
            '& legend': {
              ...tw`h-px absolute`,
            },
          },
          '&&:hover fieldset': {
            ...tw`border-soft-hover`,
          },
        },
      },
      variants: [
        {
          props: { size: 'small' },
          style: {
            '&': {
              ...tw`px-3 py-2.5 h-10`,
              '& .MuiInputBase-input': {
                ...tw`text-sm`,
              },
            },
          },
        },
        {
          props: { size: 'medium' },
          style: {
            '&': {
              ...tw`px-4 py-3 h-12`,
              '& .MuiInputBase-input': {
                ...tw`text-base`,
              },
            },
          },
        },
        {
          props: { size: 'small', multiline: true },
          style: {
            '&': {
              ...tw`h-auto min-h-[40px]`,
            },
          },
        },
        {
          props: { size: 'medium', multiline: true },
          style: {
            '&': {
              ...tw`h-auto min-h-[48px]`,
            },
          },
        },
        {
          props: { size: 'otp' },
          style: {
            '&': {
              ...tw`h-auto min-h-[64px] w-12`,
              '& .MuiInputBase-input': {
                ...tw`text-lg font-semibold text-center`,
              },
            },
          },
        },
      ],
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          ...tw`static translate-x-0 translate-y-0 pointer-events-auto mb-2 font-medium text-default-primary`,
          '&.Mui-focused, &.Mui-disabled, &.Mui-error': {
            ...tw`text-default-primary`,
            '-webkit-text-fill-color': 'unset',
          },
        },
        asterisk: {
          ...tw`hidden`,
        },
      },
      variants: [
        {
          props: { size: 'small' },
          style: {
            ...tw`text-sm`,
          },
        },
        {
          props: { size: 'medium' },
          style: {
            ...tw`text-base`,
          },
        },
      ],
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          ...tw`mt-2 mx-0 my-0 text-default-secondary flex items-center gap-1 text-sm`,
          '&.Mui-error': {
            ...tw`text-error`,
          },
          '&.Mui-disabled': {
            ...tw`text-default-secondary opacity-40`,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& label[data-shrink="false"] + .MuiInputBase-root .MuiInputBase-input::placeholder': {
            ...tw`!opacity-100`,
          },
        },
      },
      defaultProps: {
        size: 'medium',
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          ...tw`rounded`,
          '&.MuiOutlinedInput-root': {
            ...tw`py-1 pr-1`,
          },
        },
        paper: {
          ...tw`mt-2 rounded shadow-sm border border-soft bg-soft-primary`,
          '& .MuiAutocomplete-option': {
            ...tw`hover:bg-soft-primary-hover`,
          },
        },
        listbox: {
          ...tw`py-1`,
          '& .MuiAutocomplete-option': {
            ...tw`py-2.5 px-4`,
          },
        },
        endAdornment: {
          ...tw`right-0`,
          '& .MuiIconButton-root': {
            ...tw`!min-w-fit ml-2`,
          },
          '& .MuiAutocomplete-popupIndicator': {
            ...tw`!w-6 !h-6`,
          },
          '& .MuiAutocomplete-clearIndicator': {
            ...tw`!w-6 !h-6`,
          },
        },
      },
      defaultProps: {
        clearIcon: <FontAwesomeIcon icon={faXmark} size="xs" />,
        popupIcon: <FontAwesomeIcon icon={faChevronDown} size="xs" />,
        size: 'medium',
        ChipProps: {
          color: 'white',
          size: 'medium',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          '&.MuiButton-root': {
            ...tw`min-w-fit normal-case rounded font-semibold gap-2`,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        startIcon: {
          ...tw`m-0`,
        },
        endIcon: {
          ...tw`m-0`,
        },
      },
      variants: [
        {
          props: { size: 'small' },
          style: {
            '&': {
              ...tw`h-10 max-h-[40px] px-2.5 text-sm`,
            },
          },
        },
        {
          props: { size: 'medium' },
          style: {
            '&': {
              ...tw`h-12 max-h-[48px] px-2.5 text-base`,
            },
          },
        },
        {
          props: { color: 'primary' },
          style: {
            '&.MuiButton-root': {
              ...tw`bg-strong-primary text-contrast-primary`,
            },
            '&:hover': {
              ...tw`bg-strong-primary-hover`,
            },
            '&:active': {
              ...tw`bg-strong-primary-clicked`,
            },
            '&:disabled': {
              ...tw`opacity-40`,
            },
          },
        },
        {
          props: { color: 'secondary' },
          style: {
            '&.MuiButton-root': {
              ...tw`bg-strong-secondary text-default-primary`,
            },
            '&:hover': {
              ...tw`bg-strong-secondary-hover`,
            },
            '&:active': {
              ...tw`bg-strong-secondary-clicked`,
            },
            '&:disabled': {
              ...tw`opacity-40`,
            },
          },
        },
        {
          props: { color: 'tertiary' },
          style: {
            '&.MuiButton-root': {
              ...tw`bg-transparent backdrop-blur-md text-default-primary`,
            },
            '&:hover': {
              ...tw`bg-strong-secondary-hover`,
            },
            '&:active': {
              ...tw`bg-strong-secondary-clicked`,
            },
            '&:disabled': {
              ...tw`opacity-40`,
            },
          },
        },
        {
          props: { variant: 'destructive', color: 'primary' },
          style: {
            '&.MuiButton-root': {
              ...tw`bg-error-primary text-contrast-primary`,
            },
            '&:hover': {
              ...tw`bg-error-primary-hover`,
            },
            '&:active': {
              ...tw`bg-error-primary-clicked`,
            },
            '&:disabled': {
              ...tw`opacity-40`,
            },
          },
        },
        {
          props: { variant: 'destructive', color: 'secondary' },
          style: {
            '&.MuiButton-root': {
              ...tw`bg-error-secondary text-default-primary`,
            },
            '&:hover': {
              ...tw`bg-error-secondary-hover`,
            },
            '&:active': {
              ...tw`bg-error-secondary-clicked`,
            },
            '&:disabled': {
              ...tw`opacity-40`,
            },
          },
        },
        {
          props: { variant: 'destructive', color: 'tertiary' },
          style: {
            '&.MuiButton-root': {
              ...tw`bg-transparent backdrop-blur-md text-error`,
            },
            '&:hover': {
              ...tw`bg-error-secondary-hover`,
            },
            '&:active': {
              ...tw`bg-error-secondary-clicked`,
            },
            '&:disabled': {
              ...tw`opacity-40`,
            },
          },
        },
      ],
      defaultProps: {
        variant: 'default',
        color: 'primary',
        size: 'medium',
      },
    },
    MuiLoadingButton: {
      variants: [
        {
          props: { size: 'small' },
          style: {
            '& .MuiCircularProgress-root': {
              ...tw`!w-5 !h-5`,
            },
          },
        },
        {
          props: { size: 'medium' },
          style: {
            '& .MuiCircularProgress-root': {
              ...tw`!w-6 !h-6`,
            },
          },
        },
        {
          props: { color: 'primary' },
          style: {
            '& .MuiLoadingButton-loadingIndicator': {
              ...tw`text-contrast-primary`,
              '& .MuiCircularProgress-root': {
                circle: {
                  ...tw`stroke-soft-primary`,
                },
              },
            },
          },
        },
        {
          props: { color: 'secondary' },
          style: {
            '& .MuiLoadingButton-loadingIndicator': {
              ...tw`text-default-primary`,
            },
          },
        },
        {
          props: { color: 'tertiary' },
          style: {
            '& .MuiLoadingButton-loadingIndicator': {
              ...tw`text-default-primary`,
            },
          },
        },
        {
          props: { variant: 'destructive', color: 'tertiary' },
          style: {
            '& .MuiLoadingButton-loadingIndicator': {
              ...tw`text-error`,
            },
          },
        },
      ],
      defaultProps: {
        variant: 'default',
        color: 'primary',
        size: 'medium',
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          ...tw`rounded px-[12px]`,
        },
      },
      variants: [
        {
          props: { size: 'small' },
          style: {
            '&.MuiIconButton-root': {
              ...tw`text-sm h-10 min-w-[40px]`,
            },
          },
        },
        {
          props: { size: 'medium' },
          style: {
            '&.MuiIconButton-root': {
              ...tw`text-base h-12 min-w-[48px]`,
            },
          },
        },
        {
          props: { color: 'primary' },
          style: {
            '&.MuiIconButton-root': {
              ...tw`bg-strong-primary text-contrast-primary`,
            },
            '&:hover': {
              ...tw`bg-strong-primary-hover`,
            },
            '&:active': {
              ...tw`bg-strong-primary-clicked`,
            },
            '&:disabled': {
              ...tw`opacity-40`,
            },
          },
        },
        {
          props: { color: 'secondary' },
          style: {
            '&.MuiIconButton-root': {
              ...tw`bg-strong-secondary text-default-primary`,
            },
            '&:hover': {
              ...tw`bg-strong-secondary-hover`,
            },
            '&:active': {
              ...tw`bg-strong-secondary-clicked`,
            },
            '&:disabled': {
              ...tw`opacity-40`,
            },
          },
        },
        {
          props: { color: 'tertiary' },
          style: {
            '&.MuiIconButton-root': {
              ...tw`bg-transparent backdrop-blur-md text-default-primary`,
            },
            '&:hover': {
              ...tw`bg-strong-secondary-hover`,
            },
            '&:active': {
              ...tw`bg-strong-secondary-clicked`,
            },
            '&:disabled': {
              ...tw`opacity-40`,
            },
          },
        },
        {
          props: { variant: 'destructive', color: 'primary' },
          style: {
            '&.MuiIconButton-root': {
              ...tw`bg-error-primary text-contrast-primary`,
            },
            '&:hover': {
              ...tw`bg-error-primary-hover`,
            },
            '&:active': {
              ...tw`bg-error-primary-clicked`,
            },
            '&:disabled': {
              ...tw`opacity-40`,
            },
          },
        },
        {
          props: { variant: 'destructive', color: 'secondary' },
          style: {
            '&.MuiIconButton-root': {
              ...tw`bg-error-secondary text-default-primary`,
            },
            '&:hover': {
              ...tw`bg-error-secondary-hover`,
            },
            '&:active': {
              ...tw`bg-error-secondary-clicked`,
            },
            '&:disabled': {
              ...tw`opacity-40`,
            },
          },
        },
        {
          props: { variant: 'destructive', color: 'tertiary' },
          style: {
            '&.MuiIconButton-root': {
              ...tw`bg-transparent backdrop-blur-md text-error`,
            },
            '&:hover': {
              ...tw`bg-error-secondary-hover`,
            },
            '&:active': {
              ...tw`bg-error-secondary-clicked`,
            },
            '&:disabled': {
              ...tw`opacity-40`,
            },
          },
        },
      ],
      defaultProps: {
        variant: 'default',
        color: 'secondary',
        size: 'medium',
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          ...tw`gap-1`,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          ...tw`flex items-center justify-center border border-solid rounded w-fit`,
          '& .MuiChip-label': {
            ...tw`px-0 text-xs font-medium`,
          },
          '& .MuiChip-icon': {
            ...tw`mx-0 w-4 h-4 text-inherit`,
          },
          '& .MuiChip-deleteIcon': {
            ...tw`mx-0 w-4 h-4 text-inherit`,
            '&:hover': {
              ...tw`text-inherit`,
            },
          },
        },
      },
      variants: [
        {
          props: { size: 'extra-small' },
          style: {
            ...tw`h-4 px-1 gap-0.5`,
          },
        },
        {
          props: { size: 'small' },
          style: {
            ...tw`h-5 px-1.5 gap-1`,
          },
        },
        {
          props: { size: 'medium' },
          style: {
            ...tw`h-6 px-1.5 gap-1`,
          },
        },
        {
          props: { color: 'white' },
          style: {
            '&.MuiChip-root': {
              ...tw`border-soft text-default-secondary bg-soft-primary`,
              '&:hover': {
                ...tw`border-soft-hover bg-soft-primary-hover`,
              },
              '&:active': {
                ...tw`border-soft-clicked bg-soft-primary-clicked`,
              },
            },
          },
        },
        {
          props: { color: 'grey' },
          style: {
            '&.MuiChip-root': {
              ...tw`border-transparent text-default-secondary bg-strong-secondary`,
              '&:hover': {
                ...tw`bg-strong-secondary-hover`,
              },
              '&:active': {
                ...tw`bg-strong-secondary-clicked`,
              },
            },
          },
        },
        {
          props: { color: 'black' },
          style: {
            '&.MuiChip-root': {
              ...tw`text-contrast-primary bg-strong-primary`,
              '&:hover': {
                ...tw`bg-strong-primary-hover`,
              },
              '&:active': {
                ...tw`bg-strong-primary-clicked`,
              },
            },
          },
        },
        {
          props: { color: 'green' },
          style: {
            '&.MuiChip-root': {
              ...tw`border-transparent text-green bg-green-secondary`,
              '&:hover': {
                ...tw`bg-green-secondary-hover`,
              },
              '&:active': {
                ...tw`bg-green-secondary-clicked`,
              },
            },
          },
        },
        {
          props: { color: 'red' },
          style: {
            '&.MuiChip-root': {
              ...tw`border-transparent text-red bg-red-secondary`,
              '&:hover': {
                ...tw`bg-red-secondary-hover`,
              },
              '&:active': {
                ...tw`bg-red-secondary-clicked`,
              },
            },
          },
        },
        {
          props: { color: 'orange' },
          style: {
            '&.MuiChip-root': {
              ...tw`border-transparent text-orange bg-orange-secondary`,
              '&:hover': {
                ...tw`bg-orange-secondary-hover`,
              },
              '&:active': {
                ...tw`bg-orange-secondary-clicked`,
              },
            },
          },
        },
      ],
      defaultProps: {
        color: 'black',
        size: 'small',
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          ...tw`p-0 w-5 h-5 shrink-0 rounded border border-solid`,
          '&.Mui-disabled': {
            ...tw`opacity-40`,
          },
          '&.Mui-checked, &.MuiCheckbox-indeterminate': {
            ...tw`border-none text-contrast-primary`,
          },
          '&:hover, &:active': {
            ...tw`border-[1.5px]`,
          },
        },
      },
      variants: [
        {
          props: { color: 'default' },
          style: {
            ...tw`border-soft bg-soft-primary`,
            '&:hover': {
              ...tw`border-soft-hover bg-soft-primary-hover`,
            },
            '&:active': {
              ...tw`border-soft-clicked bg-soft-primary-clicked`,
            },
            '&.Mui-checked, &.MuiCheckbox-indeterminate': {
              ...tw`bg-strong-primary`,
              '&:hover': {
                ...tw`bg-strong-primary-hover`,
              },
              '&:active': {
                ...tw`bg-strong-primary-clicked`,
              },
            },
          },
        },
        {
          props: { color: 'error' },
          style: {
            ...tw`border-error bg-error-secondary`,
            '&:hover': {
              ...tw`border-error-hover bg-error-secondary-hover`,
            },
            '&:active': {
              ...tw`border-error-clicked bg-error-secondary-clicked`,
            },
            '&.Mui-checked, &.MuiCheckbox-indeterminate': {
              ...tw`bg-error-primary`,
              '&:hover': {
                ...tw`bg-error-primary-hover`,
              },
              '&:active': {
                ...tw`bg-error-primary-clicked`,
              },
            },
          },
        },
        {
          props: { disabled: true },
          style: {
            '&.Mui-disabled, &.Mui-disabled:hover, &.Mui-disabled:active': {
              ...tw`border border-soft bg-soft-primary`,
            },
            '&.Mui-disabled.Mui-checked, &.Mui-disabled.MuiCheckbox-indeterminate': {
              ...tw`border-solid text-default-primary`,
            },
          },
        },
      ],
      defaultProps: {
        disableRipple: true,
        icon: <div />,
        color: 'default',
        checkedIcon: <FontAwesomeIcon icon={faCheck} className="h-3 w-3" />,
        indeterminateIcon: <FontAwesomeIcon icon={faMinus} className="h-3 w-3" />,
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          ...tw`p-0 w-10 h-5 flex items-center rounded-full shrink-0`,
          '& .MuiSwitch-switchBase': {
            ...tw`p-0 top-0.5 left-0.5 !opacity-100`,
          },
          '& .MuiSwitch-thumb': {
            ...tw`w-4 h-4 shadow-none rounded-full !opacity-100 transition-colors bg-soft-primary`,
          },
          '& .MuiSwitch-track': {
            ...tw`!opacity-100`,
          },
          '&:hover .MuiSwitch-thumb': {
            ...tw`bg-soft-primary-hover`,
          },
          '&:active .MuiSwitch-thumb': {
            ...tw`bg-soft-primary-clicked`,
          },
        },
      },
      variants: [
        {
          props: { color: 'default' },
          style: {
            '&': {
              '& .MuiSwitch-switchBase': {
                '&.Mui-checked + .MuiSwitch-track': {
                  ...tw`bg-strong-primary`,
                },
              },
              '& .MuiSwitch-track': {
                ...tw`bg-strong-secondary`,
              },
              '&:hover': {
                '& .MuiSwitch-switchBase': {
                  '&.Mui-checked + .MuiSwitch-track': {
                    ...tw`bg-strong-primary-hover`,
                  },
                },
                '& .MuiSwitch-track': {
                  ...tw`bg-strong-secondary-hover`,
                },
              },
              '&:active': {
                '& .MuiSwitch-switchBase': {
                  '&.Mui-checked + .MuiSwitch-track': {
                    ...tw`bg-strong-primary-clicked`,
                  },
                },
                '& .MuiSwitch-track': {
                  ...tw`bg-strong-secondary-clicked`,
                },
              },
            },
          },
        },
        {
          props: { color: 'error' },
          style: {
            '&': {
              '& .MuiSwitch-switchBase': {
                '&.Mui-checked + .MuiSwitch-track': {
                  ...tw`bg-error-primary`,
                },
              },
              '& .MuiSwitch-track': {
                ...tw`bg-error-secondary`,
              },
              '&:hover': {
                '& .MuiSwitch-switchBase': {
                  '&.Mui-checked + .MuiSwitch-track': {
                    ...tw`bg-error-primary-hover`,
                  },
                },
                '& .MuiSwitch-track': {
                  ...tw`bg-error-secondary-hover`,
                },
              },
              '&:active': {
                '& .MuiSwitch-switchBase': {
                  '&.Mui-checked + .MuiSwitch-track': {
                    ...tw`bg-error-primary-clicked`,
                  },
                },
                '& .MuiSwitch-track': {
                  ...tw`bg-error-secondary-clicked`,
                },
              },
            },
          },
        },
        {
          props: { disabled: true },
          style: {
            '&': {
              ...tw`opacity-40`,
              '&, &:hover, &:active': {
                '& .MuiSwitch-switchBase': {
                  '&.Mui-checked + .MuiSwitch-track': {
                    ...tw`bg-strong-primary`,
                  },
                },
                '& .MuiSwitch-track': {
                  ...tw`bg-strong-secondary`,
                },
              },
              '& .MuiSwitch-thumb, &:hover .MuiSwitch-thumb, &:active .MuiSwitch-thumb': {
                ...tw`bg-soft-primary`,
              },
            },
          },
        },
      ],
      defaultProps: {
        color: 'default',
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-root': {
            '& fieldset': {
              ...tw`border border-soft top-0`,
              '& legend': {
                ...tw`h-px absolute`,
              },
            },
            '&:hover fieldset': {
              ...tw`border-soft-hover`,
            },
            '&:active fieldset': {
              ...tw`border-soft-clicked`,
            },
            '&.Mui-focused fieldset': {
              ...tw`border-2 border-strong`,
            },
            '&.Mui-focused:hover fieldset': {
              ...tw`border-strong-hover`,
            },
            '&.Mui-focused:active fieldset': {
              ...tw`border-strong-clicked`,
            },
            '&.Mui-error': {
              '& fieldset': {
                ...tw`border border-error`,
              },
              '&:hover fieldset': {
                ...tw`border-error-hover`,
              },
              '&:active fieldset': {
                ...tw`border-error-clicked`,
              },
            },
            '&.Mui-disabled': {
              '& fieldset, &:hover fieldset, &:active fieldset': {
                ...tw`border border-soft`,
              },
            },
            '& .MuiOutlinedInput-input': {
              ...tw`ring-transparent`,
            },
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          '&.MuiFormControlLabel-root': {
            ...tw`mx-0 gap-3`,
          },
          '& .MuiFormControlLabel-label': {
            ...tw`text-default-primary text-sm font-medium`,
          },
          '& .Mui-disabled': {
            ...tw`opacity-40`,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            ...tw`border-strong`,
          },
          '& .MuiInputBase-input.Mui-disabled': {
            '-webkit-text-fill-color': 'unset',
          },
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            ...tw`border-soft`,
          },
        },
        icon: {
          ...tw`right-4`,
        },
      },
      defaultProps: {
        IconComponent: ({ className }: { className: string }) => (
          <FontAwesomeIcon
            className={classNames(className, 'text-default-primary duration-200')}
            icon={faChevronDown}
            size="xs"
          />
        ),
        size: 'medium',
        MenuProps: {
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
      },
      styleOverrides: {
        root: {
          ...tw`transform translate-y-1`,
        },
        paper: {
          ...tw`rounded-lg shadow-lg !max-h-menulist border border-soft bg-soft-primary`,
          '& .MuiMenuItem-root': {
            ...tw`!px-4 py-2.5 !gap-2 text-sm font-medium text-default-secondary hover:bg-soft-primary-hover`,
            '& .MuiListItemText-primary': {
              ...tw`text-sm text-default-secondary`,
            },
            '&.Mui-selected': {
              '& .MuiListItemText-primary': {
                ...tw`text-default-primary`,
              },
            },
            '& .MuiListItemIcon-root': {
              ...tw`justify-end text-default-primary`,
            },
          },
        },
      },
    },
    MuiSnackbar: {
      styleOverrides: {
        root: {
          ...tw`max-w-toast`,
        },
      },
    },
    MuiAlert: {
      defaultProps: {
        variant: 'standard',
        slots: {
          closeButton: ({ onClick }) => {
            return <FontAwesomeIcon onClick={onClick} icon={faXmark} className="h-5 w-5 p-0.5" />;
          },
        },
        iconMapping: {
          success: <FontAwesomeIcon icon={faCircleCheck} className="h-5 w-5 p-0.5" />,
          error: <FontAwesomeIcon icon={faCircleExclamation} className="h-5 w-5 p-0.5" />,
          warning: <FontAwesomeIcon icon={faCircleExclamation} className="h-5 w-5 p-0.5" />,
          info: <FontAwesomeIcon icon={faCircleInfo} className="h-5 w-5 p-0.5" />,
        },
      },
      styleOverrides: {
        root: {
          ...tw`rounded-lg p-4 flex items-start gap-3`,
          '& .MuiAlert-message': {
            ...tw`text-default-secondary text-sm p-0`,
            '& .MuiAlertTitle-root': {
              ...tw`m-0 text-base text-default-primary`,
            },
          },
          '& .MuiAlert-icon': {
            ...tw`py-0 pr-0 mr-0 self-start`,
          },
          '& .MuiAlert-action': {
            ...tw`mr-0 pl-0 pt-0`,
          },
        },
      },
      variants: [
        {
          props: {
            variant: 'filled',
          },
          style: {
            '& .MuiAlert-message': {
              ...tw`text-contrast-primary`,
              '& .MuiAlertTitle-root': {
                ...tw`text-contrast-primary`,
              },
            },
          },
        },
        {
          props: { severity: 'success', variant: 'standard' },
          style: {
            ...tw`bg-success-secondary`,
            '& .MuiAlert-icon': {
              ...tw`text-success`,
            },
            '& .MuiAlert-action': {
              ...tw`text-success`,
            },
          },
        },
        {
          props: { severity: 'error', variant: 'standard' },
          style: {
            ...tw`bg-error-secondary`,
            '& .MuiAlert-icon': {
              ...tw`text-error`,
            },
            '& .MuiAlert-action': {
              ...tw`text-error`,
            },
          },
        },
        {
          props: { severity: 'warning', variant: 'standard' },
          style: {
            ...tw`bg-warning-secondary`,
            '& .MuiAlert-icon': {
              ...tw`text-warning`,
            },
            '& .MuiAlert-action': {
              ...tw`text-warning`,
            },
          },
        },
        {
          props: { severity: 'info', variant: 'standard' },
          style: {
            ...tw`bg-strong-secondary`,
            '& .MuiAlert-icon': {
              ...tw`text-default-primary`,
            },
            '& .MuiAlert-action': {
              ...tw`text-default-primary`,
            },
          },
        },
        {
          props: { severity: 'success', variant: 'filled' },
          style: {
            ...tw`bg-success-primary`,
            '& .MuiAlert-icon': {
              ...tw`text-contrast-primary`,
            },
            '& .MuiAlert-action': {
              ...tw`text-contrast-primary`,
            },
          },
        },
        {
          props: { severity: 'error', variant: 'filled' },
          style: {
            ...tw`bg-error-primary`,
            '& .MuiAlert-icon': {
              ...tw`text-contrast-primary`,
            },
            '& .MuiAlert-action': {
              ...tw`text-contrast-primary`,
            },
          },
        },
        {
          props: { severity: 'warning', variant: 'filled' },
          style: {
            ...tw`bg-warning-primary`,
            '& .MuiAlert-icon': {
              ...tw`text-contrast-primary`,
            },
            '& .MuiAlert-action': {
              ...tw`text-contrast-primary`,
            },
          },
        },
        {
          props: { severity: 'info', variant: 'filled' },
          style: {
            ...tw`bg-strong-primary`,
            '& .MuiAlert-icon': {
              ...tw`text-contrast-primary`,
            },
            '& .MuiAlert-action': {
              ...tw`text-contrast-primary`,
            },
          },
        },
      ],
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          ...tw`rounded-xl md:rounded-2xl absolute m-0 bottom-0 w-full md:relative bg-soft-primary`,
        },
      },
      variants: [
        {
          props: { fullScreen: true },
          style: {
            '& .MuiPaper-root': {
              ...tw`rounded-none`,
            },
          },
        },
        {
          props: { fullScreen: false },
          style: {
            ...tw`m-4`,
          },
        },
      ],
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          ...tw`px-8 pt-8 pb-0 text-default-primary`,
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          ...tw`px-8 !py-4`,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          ...tw`px-8 pb-8 pt-0`,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          ...tw`mx-4 text-sm rounded bg-strong-primary text-contrast-primary p-3 font-medium shadow-tooltip min-w-tooltip max-w-tooltip`,
        },
        arrow: {
          ...tw`text-default-primary`,
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          ...tw`border-none rounded-none`,
          '& .MuiDataGrid-columnSeparator, & .MuiDataGrid-scrollbar--horizontal': {
            ...tw`hidden`,
          },
          '& .MuiDataGrid-columnHeaders': {
            '& .MuiDataGrid-row--borderBottom': {
              ...tw`bg-soft-secondary`,
              '& > div[role="presentation"] + .MuiDataGrid-columnHeader': {
                ...tw`pl-6`,
              },
            },
            '& .MuiDataGrid-columnHeader': {
              ...tw`px-3 py-2.5 outline-none first-of-type:pl-6 last-of-type:pr-6`,
              '&.MuiDataGrid-columnHeader--pinnedLeft': {
                ...tw`bg-soft-secondary`,
                '&.MuiDataGrid-columnHeader--withRightBorder': {
                  ...tw`pr-6`,
                },
              },
              '&.MuiDataGrid-columnHeader--last': {
                ...tw`pr-6`,
              },
              '&.MuiDataGrid-columnHeader--pinnedRight': {
                ...tw`bg-soft-secondary`,
              },
              '&:has(+ .MuiDataGrid-filler)': {
                ...tw`pr-6`,
              },
              '& .MuiDataGrid-columnHeaderTitle': {
                ...tw`text-xs text-default-secondary font-semibold uppercase`,
              },
            },
            // Sort header icons
            '& .MuiDataGrid-iconButtonContainer': {
              ...tw`pl-2 invisible`,
              '& .MuiIconButton-root': {
                ...tw`w-[18px] min-w-[18px] h-[18px] rounded-full p-0`,
              },
              '& .MuiDataGrid-sortIcon': {
                ...tw`w-3.5 h-3.5`,
              },
            },
            '& .MuiDataGrid-columnHeader--sorted .MuiDataGrid-iconButtonContainer': {
              ...tw`visible`,
            },
          },
          '& .MuiDataGrid-virtualScrollerRenderZone .MuiDataGrid-row': {
            ...tw`!bg-soft-primary hover:!bg-soft-primary-hover active:!bg-soft-primary-clicked`,
            '& .MuiDataGrid-cellOffsetLeft + .MuiDataGrid-cell': {
              ...tw`pl-6`,
            },
            '& .MuiDataGrid-cell': {
              ...tw`text-default-primary flex items-center px-3 py-2.5 text-xs font-medium outline-none border-soft border-t-0 border-b first-of-type:pl-6 last-of-type:pr-6`,
              '&.MuiDataGrid-cell--withRightBorder.MuiDataGrid-cell--pinnedLeft': {
                ...tw`pr-6 bg-soft-primary`,
              },
              '&.MuiDataGrid-cell--withLeftBorder.MuiDataGrid-cell--pinnedRight': {
                ...tw`bg-soft-primary`,
              },
              '&:has(+ .MuiDataGrid-filler)': {
                ...tw`pr-6`,
                '& + .MuiDataGrid-filler + .MuiDataGrid-cell': {
                  ...tw`pl-6`,
                },
              },
            },
          },
          '& .MuiDataGrid-pinnedRows--top .MuiDataGrid-row': {
            '& .MuiDataGrid-cell': {
              ...tw`flex items-center px-3 py-2.5 text-xs font-medium outline-none border-soft border-t-0 border-b first-of-type:pl-6 last-of-type:pr-6`,
              '&.MuiDataGrid-cell--withRightBorder.MuiDataGrid-cell--pinnedLeft': {
                ...tw`pr-6`,
              },
            },
            '& .MuiDataGrid-cellOffsetLeft + .MuiDataGrid-cell': {
              ...tw`pl-6`,
            },
          },
          // Checkboxes when row selection is turn on
          '& .MuiDataGrid-columnHeaderCheckbox, & .MuiDataGrid-cellCheckbox': {
            ...tw`min-w-[54px]`,
            '& .MuiCheckbox-root': {
              ...tw`w-4 h-4`,
            },
            'svg': {
              ...tw`w-4 h-4`,
            },
          },
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          ...tw`relative p-0 w-5 h-5 rounded-full shrink-0`,
          '&.Mui-disabled': {
            ...tw`opacity-40`,
          },
          '&.Mui-checked': {
            ...tw`border-none`,
            '&::after': {
              ...tw`content-[''] absolute w-2 h-2 rounded-full top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2`,
            },
          },
        },
      },
      variants: [
        {
          props: { color: 'default' },
          style: {
            ...tw`border border-solid border-soft bg-soft-primary`,
            '&:hover': {
              ...tw`border-[1.5px] border-soft-hover bg-soft-primary-hover`,
            },
            '&:active': {
              ...tw`border-[1.5px] border-soft-clicked bg-soft-primary-clicked`,
            },
            '&.Mui-checked': {
              ...tw`bg-strong-primary`,
              '&::after': {
                ...tw`bg-soft-primary`,
              },
              '&:hover': {
                ...tw`bg-strong-primary-hover`,
                '&::after': {
                  ...tw`bg-soft-primary-hover`,
                },
              },
              '&:active': {
                ...tw`bg-strong-primary-clicked`,
                '&::after': {
                  ...tw`bg-soft-primary-clicked`,
                },
              },
            },
          },
        },
        {
          props: { color: 'error' },
          style: {
            ...tw`border border-solid border-error bg-error-secondary`,
            '&:hover': {
              ...tw`border-[1.5px] border-error-hover bg-error-secondary-hover`,
            },
            '&:active': {
              ...tw`border-[1.5px] border-error-clicked bg-error-secondary-clicked`,
            },
            '&.Mui-checked': {
              ...tw`bg-error-primary`,
              '&::after': {
                ...tw`bg-soft-primary`,
              },
              '&:hover': {
                ...tw`bg-error-primary-hover`,
                '&::after': {
                  ...tw`bg-soft-primary-hover`,
                },
              },
              '&:active': {
                ...tw`bg-error-primary-clicked`,
                '&::after': {
                  ...tw`bg-soft-primary-clicked`,
                },
              },
            },
          },
        },
        {
          props: { disabled: true },
          style: {
            ...tw`border border-solid border-soft bg-soft-primary`,
            '&.Mui-checked': {
              ...tw`bg-strong-primary`,
              '&::after': {
                ...tw`bg-soft-primary`,
              },
            },
          },
        },
      ],
      defaultProps: {
        color: 'default',
        icon: <div />,
        checkedIcon: <div />,
      },
    },
    MuiPickersLayout: {
      styleOverrides: {
        root: {
          '&': {
            ...tw`bg-soft-primary`,
          },
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        root: {
          '& .MuiPickersCalendarHeader-labelContainer': {
            ...tw`text-default-primary`,
          },
        },
      },
    },
    MuiDayCalendar: {
      styleOverrides: {
        root: {
          '& .MuiDayCalendar-header .MuiDayCalendar-weekDayLabel': {
            ...tw`text-default-primary`,
          },
          '& .MuiDateRangePickerDay-root:first-of-type .MuiDateRangePickerDay-rangeIntervalDayPreview, & .MuiDateRangePickerDay-root .MuiDateRangePickerDay-rangeIntervalDayPreview.MuiDateRangePickerDay-rangeIntervalDayPreviewStart':
            {
              ...tw`border-l-soft`,
            },
          '& .MuiDateRangePickerDay-root:last-of-type .MuiDateRangePickerDay-rangeIntervalDayPreview, & .MuiDateRangePickerDay-root .MuiDateRangePickerDay-rangeIntervalDayPreview.MuiDateRangePickerDay-rangeIntervalDayPreviewEnd':
            {
              ...tw`border-r-soft`,
            },
          '& .MuiDateRangePickerDay-root.MuiDateRangePickerDay-hiddenDayFiller .MuiDateRangePickerDay-rangeIntervalDayPreview':
            {
              ...tw`!border-transparent`,
            },
          '& .MuiDateRangePickerDay-rangeIntervalPreview': {
            ...tw`leading-none`,
            '&.MuiDateRangePickerDay-rangeIntervalDayPreview': {
              ...tw`border-soft border-l-transparent border-r-transparent`,
            },
            '& .MuiPickersDay-root': {
              ...tw`text-default-primary w-10 h-10`,
              '&.MuiButtonBase-root:not(.Mui-selected)': {
                ...tw`border-strong`,
              },
              '&.Mui-selected': {
                ...tw`bg-strong-primary text-contrast-primary hover:bg-strong-primary-hover active:bg-strong-primary-clicked`,
              },
            },
          },
          '& .MuiDateRangePickerDay-rangeIntervalDayHighlight': {
            ...tw`bg-strong-secondary`,
            '&.MuiDateRangePickerDay-hiddenDayFiller': {
              ...tw`bg-transparent`,
            },
          },
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: {
          ...tw`text-default-primary`,
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        circle: {
          ...tw`stroke-strong-primary`,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          ...tw`bg-strong-primary`,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          ...tw`!text-default-tertiary`,
          '&.Mui-selected': {
            ...tw`!text-default-primary`,
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#182029',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#e4e3e4',
      dark: '#d9dad9',
    },
    tertiary: {
      main: 'rgba(17, 24, 39, 0.06)',
      contrastText: '#111827',
    },
    alert: {
      main: '#C83933',
      contrastText: '#F9FAFB',
    },
    warning: {
      main: '#FDF0E6',
      contrastText: '#5F2B01',
    },
    info: {
      main: '#EFF6FF',
      contrastText: '#2563EB',
    },
    error: {
      main: '#e05353',
      contrastText: '#DC2626',
    },
    success: {
      main: '#EBF9EB',
      contrastText: '#014F01',
    },
    black: {
      main: '#1C2436',
      contrastText: '#FFFFFF',
    },
    white: {
      main: '#FFFFFF',
      contrastText: '#FFF',
    },
    orange: {
      main: 'rgba(230, 138, 29, 0.08)',
      contrastText: '#E68A1D',
    },
    red: {
      main: 'rgba(224, 83, 83, 0.08)',
      contrastText: '#E05353',
    },
    green: {
      main: 'rgba(62, 158, 104, 0.08)',
      contrastText: '#3E9E68',
    },
    grey: {
      main: '#F0F1F4',
      contrastText: '#566076',
    },
  },
  typography: {
    fontFamily: 'inherit',
    fontSize: 14,
  },
  breakpoints: {
    values: {
      'xxs': pxToNumber(tailwindTheme`screens.xxs`),
      'xs': pxToNumber(tailwindTheme`screens.xs`),
      'sm': pxToNumber(tailwindTheme`screens.sm`),
      'md': pxToNumber(tailwindTheme`screens.md`),
      'lg': pxToNumber(tailwindTheme`screens.lg`),
      'xl': pxToNumber(tailwindTheme`screens.xl`),
      '2xl': pxToNumber(tailwindTheme`screens.2xl`),
    },
  },
} as ThemeOptions);

export function ensureAlphaValue(color: string) {
  if (color.includes(' / <alpha-value>')) {
    return color.replace(' / <alpha-value>', '');
  }

  return color;
}

function pxToNumber(pxString: string) {
  return parseInt(pxString.replace('px', ''), 10);
}
