import {
  BankAccountPaymentMethod,
  BankAccountPaymentMethodCombined,
} from '@app/@types/bankAccount.types';
import {
  DebitCardPaymentMethod,
  DebitCardPaymentMethodCombined,
} from '@app/@types/debitCard.types';
import {
  BankAccountDetails,
  CardDetails,
  CombinedAndDeletedPaymentMethods,
  DeserializedPaymentsData,
  PaymentMethod,
  PaymentMethodCombined,
  PaymentMethodResponseType,
  WalletPaymentMethod,
} from '@app/@types/payments.types';
import { ExtendedFinancialAccountData } from '@app/components/PaymentModal/@types/paymentMethod.types';
import { guardAxiosError } from '@app/utils/error/guards';
import * as Sentry from '@sentry/react';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { deserialize } from 'deserialize-json-api';
import { capitalize } from 'lodash-es';

enum PaymentMethodType {
  'DEBIT_CARD' = 'Debit Card',
  'BANK_ACCOUNT' = 'Bank Account',
  'WALLET' = 'Wallet',
}

export enum DebitCardBrand {
  VISA = 'visa',
  MASTERCARD = 'mastercard',
}

type AxiosResult = AxiosResponse<unknown, unknown> | AxiosError<unknown, unknown>;

const handlePaymentMethodDelete = async (
  id: string,
  ownerEmail?: string,
): Promise<AxiosResponse> => {
  try {
    return await axios.delete(`/payments/payment_methods/${id}`);
  } catch (err) {
    const user = {
      owner_email: ownerEmail ?? '',
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      request_id: undefined as string,
    };

    if (axios.isAxiosError(err)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      user.request_id = err?.response?.headers?.['x-request-id'];
    }

    Sentry.captureException(err, { user });

    throw err;
  }
};

const handleMakePaymentMethodPrimary = async (
  id: string,
  ownerEmail?: string,
): Promise<AxiosResult> => {
  try {
    const response = await axios.patch(`/payments/payment_methods/${id}`, {
      payment_method: { is_primary: true },
    });

    return response;
  } catch (e: unknown) {
    if (!guardAxiosError(e)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      return null;
    }

    Sentry.captureException(e, {
      user: {
        request_id: e?.response?.headers?.['x-request-id'],
        owner_email: ownerEmail ?? '',
      },
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return e.response;
  }
};

const filterDeletedDebitCards = (
  debitCards: DebitCardPaymentMethodCombined[],
): {
  debitCards: DebitCardPaymentMethodCombined[];
  deletedDebitCards: DebitCardPaymentMethodCombined[];
} => {
  const debitCardsData: DebitCardPaymentMethodCombined[] = [];
  const deletedDebitCards: DebitCardPaymentMethodCombined[] = [];

  debitCards.forEach((debitCard: DebitCardPaymentMethodCombined) =>
    debitCard.deleted_at ?? true
      ? debitCardsData.push(debitCard)
      : deletedDebitCards.push(debitCard),
  );

  return { debitCards: debitCardsData, deletedDebitCards };
};

const filterDeletedBankAccounts = (
  accounts: BankAccountPaymentMethodCombined[],
): {
  bankAccounts: BankAccountPaymentMethodCombined[];
  deletedBankAccounts: BankAccountPaymentMethodCombined[];
} => {
  const bankAccounts: BankAccountPaymentMethodCombined[] = [];
  const deletedBankAccounts: BankAccountPaymentMethodCombined[] = [];

  accounts.forEach((bankAccount: BankAccountPaymentMethodCombined) =>
    bankAccount.state !== 'deleted'
      ? bankAccounts.push(bankAccount)
      : deletedBankAccounts.push(bankAccount),
  );

  return { bankAccounts, deletedBankAccounts };
};

export const isActiveDebitCard = (debitCard: PaymentMethod): debitCard is DebitCardPaymentMethod =>
  debitCard?.payment_method_detail?.type === 'debit_card' &&
  !(debitCard as DebitCardPaymentMethodCombined)?.deleted_at;

export const isDebitCard = (debitCard: PaymentMethod | null): debitCard is DebitCardPaymentMethod =>
  debitCard?.payment_method_detail?.type === 'debit_card';

export function isPrimary(paymentMethod: PaymentMethodCombined): boolean {
  return !!paymentMethod?.is_primary;
}

const isBankAccount = (
  bankAccount: PaymentMethod | null,
): bankAccount is BankAccountPaymentMethod =>
  bankAccount?.payment_method_detail?.type === 'bank_account';

const isWallet = (paymentMethod: PaymentMethod): paymentMethod is WalletPaymentMethod =>
  paymentMethod?.payment_method_detail?.type === 'customer_treasury_detail';

const isFinancialAccount = (
  paymentMethod: PaymentMethod,
): paymentMethod is ExtendedFinancialAccountData =>
  paymentMethod?.payment_method_detail?.type === 'financial_account';

const mapDebitCardData = (
  paymentMethodsReponse: PaymentMethodResponseType,
): DebitCardPaymentMethodCombined[] => {
  const { data } = deserialize(paymentMethodsReponse);
  const debitCards: DebitCardPaymentMethodCombined[] = data
    .filter(isDebitCard)
    .map((debitCard: DebitCardPaymentMethod) => ({
      ...debitCard?.payment_method_detail,
      ...debitCard,
    }));
  return debitCards;
};

const mapBankAndDebitCardData = (
  paymentMethods: PaymentMethodCombined[] = [],
): CombinedAndDeletedPaymentMethods => {
  const debitCardsData: DebitCardPaymentMethodCombined[] = (
    paymentMethods.filter(isDebitCard) as DebitCardPaymentMethod[]
  ).map((debitCard: DebitCardPaymentMethod) => ({
    ...debitCard?.payment_method_detail,
    ...debitCard,
  }));

  const bankAccountsData: BankAccountPaymentMethodCombined[] = (
    paymentMethods.filter(isBankAccount) as BankAccountPaymentMethod[]
  ).map((bankAccount: BankAccountPaymentMethod) => ({
    ...bankAccount?.payment_method_detail,
    ...bankAccount,
    is_primary: bankAccount?.payment_method_detail?.is_primary,
    // The return value of this function stores the payment_method ID in the 'id'
    // field and we lose the ID of the bank account. The payment method
    // details are flattened into a combined object.
    // We must preserve the bank account ID so we can interact with API
    // endpoints that expect it, e.g. /plaid/create_link_token.
    bank_account_id: bankAccount?.payment_method_detail?.id,
    key: bankAccount.id,
  }));

  const { debitCards, deletedDebitCards } = filterDeletedDebitCards(debitCardsData);
  const { bankAccounts, deletedBankAccounts } = filterDeletedBankAccounts(bankAccountsData);

  const combinedPaymentMethods = {
    bankAccounts,
    debitCards,
    wallet: paymentMethods.find(isWallet),
  };

  return {
    combinedPaymentMethods,
    deletedPaymentMethods: { debitCards: deletedDebitCards, bankAccounts: deletedBankAccounts },
  };
};

const isDebitCardPayment = (data: DeserializedPaymentsData): boolean =>
  data.payment_method?.payment_method_detail?.type === 'debit_card';

const isWalletPayment = (data: DeserializedPaymentsData): boolean =>
  data.payment_method?.payment_method_detail?.type === 'customer_treasury_detail';

const getPaymentMethodName = (
  paymentMethod: PaymentMethodCombined | ExtendedFinancialAccountData,
  endingIn = true,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
): string => {
  if (isBankAccount(paymentMethod)) {
    if (endingIn) {
      return `${(paymentMethod?.payment_method_detail as BankAccountDetails)
        ?.institution_name} account ending in ${(
        paymentMethod.payment_method_detail as BankAccountDetails
      )?.mask}`;
    }
    return `${capitalize(paymentMethod?.payment_method_detail.name)} ••••${paymentMethod
      ?.payment_method_detail.mask}`;
  } else if (isDebitCard(paymentMethod)) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    return `${capitalize(
      (paymentMethod?.payment_method_detail as CardDetails)?.brand || '',
    )} ••••${(paymentMethod.payment_method_detail as CardDetails)?.last_four}`;
  } else if (isWallet(paymentMethod)) {
    return `AtoB Wallet ••••${paymentMethod.payment_method_detail?.financial_account.last_4 || ''}`;
  } else if (isFinancialAccount(paymentMethod)) {
    return `AtoB Wallet ••••${paymentMethod.payment_method_detail.last_4 || ''}`;
  }
};

export {
  PaymentMethodType,
  getPaymentMethodName,
  handleMakePaymentMethodPrimary,
  handlePaymentMethodDelete,
  isBankAccount,
  isDebitCardPayment,
  isFinancialAccount,
  isWalletPayment,
  mapBankAndDebitCardData,
  mapDebitCardData,
};
