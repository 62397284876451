import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import classnames from 'classnames';
import { ReactElement } from 'react';

interface EmptyTableProps {
  imgComponent?: ReactElement;
  icon?: IconProp;
  title: string;
  subtitle?: string;
  buttonText?: string;
  onClick?: () => void;
}

const EmptyTable = ({
  icon,
  title,
  subtitle,
  buttonText,
  onClick,
  imgComponent,
}: EmptyTableProps) => {
  return (
    <div className="mx-auto flex w-52 flex-col items-center justify-center py-10">
      {imgComponent && <div className="mb-8"> {imgComponent} </div>}
      {icon && <FontAwesomeIcon icon={icon} className="text-default-secondary mb-8 h-14 w-14" />}
      <p
        className={classnames('text-default-primary mb-4 text-xl font-normal not-italic', {
          'text-center': !onClick,
        })}
      >
        {title}
      </p>
      <p
        className={classnames('text-default-secondary text-base font-normal not-italic', {
          'text-center': !onClick,
        })}
      >
        {subtitle}
      </p>
      {onClick && (
        <div className="hidden w-full pt-2 sm:inline-block">
          <Button color="primary" fullWidth onClick={onClick}>
            {buttonText}
          </Button>
        </div>
      )}
    </div>
  );
};

export default EmptyTable;
