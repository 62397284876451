import classNames from 'classnames';

export default function Avatar({
  size = 'md',
  initials,
  className,
  color = 'light',
}: {
  size?: 'sm' | 'md' | 'lg';
  initials?: string;
  className?: string;
  color?: 'light' | 'dark';
}) {
  return (
    <div
      className={classNames(
        'flex items-center justify-center rounded-full',
        size === 'sm' && 'h-6 w-6',
        size === 'md' && 'h-8 w-8',
        size === 'lg' && 'h-12 w-12',
        color === 'light' && 'bg-strong-secondary',
        color === 'dark' && 'bg-strong-primary',
        className,
      )}
    >
      <p
        className={classNames(
          'font-semibold',
          size === 'sm' && 'text-xs',
          size === 'md' && 'text-sm',
          size === 'lg' && 'text-md',
          color === 'light' && 'text-default-secondary',
          color === 'dark' && 'text-contrast-primary',
        )}
      >
        {initials}
      </p>
    </div>
  );
}
