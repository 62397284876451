import { PartnerLogo } from '../layout/PartnerLogo';

type HeaderProps = {
  title: string;
  description?: string;
};

export default function Header({ title, description }: HeaderProps) {
  return (
    <div className="flex flex-col items-start justify-end gap-4">
      <PartnerLogo className="h-[28px]" />
      <div className="flex flex-col gap-2 md:gap-4">
        <h2 className="text-default-primary text-2xl font-semibold">{title}</h2>
        {description && (
          <p className="text-default-secondary text-sm md:text-base">{description}</p>
        )}
      </div>
    </div>
  );
}
