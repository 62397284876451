import defaultTheme from '@atob-developers/shared/src/theme/defaultTheme';
import { Theme } from '@mui/material';
import uberFreight from './Themes/uberFreight';

export enum ThemeIdentifier {
  UBER_FREIGHT,
  DEFAULT,
}

export type ThemeConfiguration = {
  // Store theme specific variables here
  id: ThemeIdentifier;
  muiTheme: Theme;
  showAppNav: boolean;
  showZendesk: boolean;
  showPlans: boolean;
  hideDismissableBanners: boolean;

  // Hides components that use local storage.
  supportsLocalStorage: boolean;

  showPremiumUpsell: boolean;

  // Money deposit is forbidden if a partner manages the back account we use for the customer
  allowMoneyDeposit: boolean;

  // Show a card with the total monthly spend on the dashboard page
  showTotalSpend: boolean;

  showBackupPaymentMethodLabels: boolean;
};

export const THEME_CONFIGURATIONS: Record<ThemeIdentifier, ThemeConfiguration> = {
  [ThemeIdentifier.DEFAULT]: {
    id: ThemeIdentifier.DEFAULT,
    muiTheme: defaultTheme,
    showAppNav: true,
    allowMoneyDeposit: true,
    showTotalSpend: false,
    showZendesk: true,
    showPlans: true,
    hideDismissableBanners: false,
    supportsLocalStorage: true,
    showPremiumUpsell: true,
    showBackupPaymentMethodLabels: false,
  },
  [ThemeIdentifier.UBER_FREIGHT]: {
    id: ThemeIdentifier.UBER_FREIGHT,
    muiTheme: uberFreight,
    showAppNav: true,
    allowMoneyDeposit: true,
    showTotalSpend: true,
    showZendesk: false,
    showPlans: false,
    hideDismissableBanners: false,
    supportsLocalStorage: true,
    showPremiumUpsell: false,
    showBackupPaymentMethodLabels: false,
  },
};
