import { PaginationMeta } from '@app/@types/api.types';
import { ErrorNotification } from '@app/components/layout';
import UnderlinedTabs from '@atob-developers/shared/src/components/UnderlinedTabs';
import { Skeleton } from '@mui/material';
import { ReactElement } from 'react';
import WalletTransactionsTable from './WalletTransactions/WalletTransactionsTable';
import type { WalletTransaction } from './WalletHome';
import type { GoToPageAction } from '@app/hooks/paging/types';

const TRANSACTIONS_TAB: TransactionsTab[] = [
  {
    name: 'All transactions',
    tab: 'all',
  },
  {
    name: 'Completed',
    tab: 'success',
  },
  {
    name: 'Pending',
    tab: 'pending',
  },
  {
    name: 'Failed',
    tab: 'failed',
  },
];

type TransactionsTab = {
  name: 'All transactions' | 'Completed' | 'Pending' | 'Failed';
  tab: 'all' | 'success' | 'pending' | 'failed';
};

export default function WalletTransactions({
  transactionsPage,
  transactionsError,
  metadata,
  goToPage,
  tab,
  setTab,
  isLoading,
}: {
  transactionsPage: WalletTransaction[];
  transactionsError: unknown;
  metadata: PaginationMeta | undefined;
  goToPage: GoToPageAction;
  tab: 'all' | 'success' | 'pending' | 'failed';
  setTab: (tab: 'all' | 'success' | 'pending' | 'failed') => void;
  isLoading: boolean;
}): ReactElement {
  if (transactionsError) {
    return (
      <ErrorNotification error="We're having issues loading your transactions data. Please try again; if the issue persists, please contact support." />
    );
  }

  const TransactionsUI = () => {
    if (isLoading) {
      return (
        <>
          <Skeleton className="mx-4 h-16" />
          <Skeleton className="mx-4 h-16" />
          <Skeleton className="mx-4 h-16" />
          <Skeleton className="mx-4 h-16" />
          <Skeleton className="mx-4 h-16" />
        </>
      );
    }

    if (metadata == null || metadata?.pagination.total_count === 0) {
      return <NoTransactions />;
    }

    return (
      <WalletTransactionsTable
        transactionsPage={transactionsPage}
        metadata={metadata}
        onPageIndexChange={(pageIndex: number) => goToPage(pageIndex)}
      />
    );
  };

  return (
    <div className="border-soft bg-soft-primary mb-20 rounded-lg border md:mb-0">
      <p data-tour="wallet_transactions" className="text-default-primary p-6 pb-1 font-semibold">
        Wallet Transactions
      </p>
      {isLoading && metadata == null && <Skeleton className="mx-4 mt-3 h-12 pl-6 md:mt-0" />}
      {metadata == null ? (
        <div className="border-soft border-b pb-4" />
      ) : (
        <UnderlinedTabs
          className="mt-3 pl-6 md:mt-0 md:h-12"
          selectedTab={tab}
          handleTabChange={(tab) => setTab(tab)}
          tabs={TRANSACTIONS_TAB}
        />
      )}
      <TransactionsUI />
    </div>
  );
}

const NoTransactions = (): ReactElement => {
  return (
    <div className="flex h-[432px] w-full flex-col items-center justify-center">
      <img className="w-24 md:w-40" src="/card.svg" alt="Card" />
      <div className="mt-4 flex max-w-[350px] flex-col items-center text-center">
        <h2 className="text-default-primary mb-3 text-2xl font-semibold">No transactions found</h2>
        <p className="text-default-secondary text-lg">
          Transactions will display here when you start using card
        </p>
      </div>
    </div>
  );
};
