import FilterGroup from '@app/components/Filters/FilterGroup';
import { DriversFilter } from '@app/components/Filters/ResourceFilters/DriversFilter';
import { TagsFilter } from '@app/components/Filters/ResourceFilters/TagsFilter';
import { VehiclesFilter } from '@app/components/Filters/ResourceFilters/VehiclesFilter';
import useProduct from '@app/hooks/useProduct';
import { ReactElement } from 'react';
import { CardQueryFilters } from './CardFilterTypes';

type CardsFiltersProps = {
  selectedFilters: CardQueryFilters;
  setSelectedFilters: (v: Partial<CardQueryFilters>) => void;
  clearFilters?: () => void;
};

const CardsFilters = ({
  selectedFilters,
  setSelectedFilters,
  clearFilters,
}: CardsFiltersProps): ReactElement => {
  const [resourceTagsEnabled] = useProduct('resource_tags');

  return (
    <FilterGroup className="border-b-soft !mx-0 border-b !py-4 px-6" clearFilters={clearFilters}>
      {resourceTagsEnabled && (
        <TagsFilter
          onTagsSelect={(values) => setSelectedFilters({ tag_id: values })}
          selectedTagIds={selectedFilters.tag_id}
        />
      )}
      <DriversFilter
        onDriversSelect={(values) => setSelectedFilters({ driver_id: values })}
        selectedDriverIDs={selectedFilters.driver_id}
      />
      <VehiclesFilter
        onVehiclesSelect={(values) => setSelectedFilters({ vehicle_id: values })}
        selectedVehicleIDs={selectedFilters.vehicle_id}
      />
    </FilterGroup>
  );
};

export default CardsFilters;
