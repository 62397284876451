import { ChargeEventData } from '@app/@types/charge_events.types';
import { BrandLogo } from '@app/components/FuelListings/Logos/BrandLogo';
import useWindowWidth from '@app/hooks/useWindowWidth';
import { faArrowTrendUp, faArrowTrendDown, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { faClock } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { getStatusesHelper } from '../Transactions/utils';

type HeaderProps = {
  chargeEvent: ChargeEventData;
};

const IconWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <div className="absolute -bottom-0.5 right-0 h-5 w-5 rounded-full md:h-6 md:w-6">
      {children}
    </div>
  );
};

export default function HeaderTitle({ chargeEvent }: HeaderProps) {
  const { hasDeclined, hasMissed, hasSaved, isPending } = getStatusesHelper(chargeEvent);
  const { isMobile } = useWindowWidth();

  const renderIcon = () => {
    if (isPending) {
      return (
        <IconWrapper>
          <div className="flex h-full w-full items-center justify-center rounded-full">
            <FontAwesomeIcon
              icon={faClock}
              className="text-default-tertiary bg-soft-primary h-5 w-5 rounded-full md:h-6 md:w-6"
            />
          </div>
        </IconWrapper>
      );
    }

    if (hasSaved) {
      return (
        <IconWrapper>
          <div className="bg-green-primary flex h-full w-full items-center justify-center rounded-full">
            <FontAwesomeIcon
              icon={faArrowTrendUp}
              className={'text-contrast-primary h-3 w-3 md:h-3.5 md:w-3.5'}
            />
          </div>
        </IconWrapper>
      );
    }

    if (hasMissed) {
      return (
        <IconWrapper>
          <div className="bg-orange-primary flex h-full w-full items-center justify-center rounded-full">
            <FontAwesomeIcon
              icon={faArrowTrendDown}
              className="text-contrast-primary h-3 w-3 md:h-3.5 md:w-3.5"
            />
          </div>
        </IconWrapper>
      );
    }

    if (hasDeclined) {
      return (
        <IconWrapper>
          <div className="bg-red-primary flex h-full w-full items-center justify-center rounded-full">
            <FontAwesomeIcon
              icon={faXmark}
              className="text-contrast-primary h-3 w-3 md:h-4 md:w-4"
            />
          </div>
        </IconWrapper>
      );
    }

    return null;
  };

  return (
    <>
      <div className="flex flex-1 items-center gap-4">
        <div className="relative">
          <BrandLogo border rounded logoURL={chargeEvent.logo_url} size={isMobile ? 48 : 56} />
          {renderIcon()}
        </div>
        <div className="flex flex-col items-start">
          <span className="text-default-secondary text-sm leading-5 md:text-base">
            {chargeEvent.location}
          </span>
          <span className="text-default-primary text-xl font-semibold tracking-[-0.5px] md:text-2xl md:tracking-[-0.6px]">
            {chargeEvent.merchant_name}
          </span>
        </div>
      </div>
    </>
  );
}
