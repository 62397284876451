import PhoneInput from '@app/components/Inputs/PhoneInput';
import { Checkbox, TextField } from '@mui/material';
import { FormikErrors, useFormikContext } from 'formik';
import { isEqual } from 'lodash-es';
import { memo } from 'react';
import { NewDriverField, useOnboardingInviteDrivers } from '../useOnboardingInviteDrivers';

export default function NewDriversList() {
  const { disabled } = useOnboardingInviteDrivers();

  const { values } = useFormikContext<{ drivers: NewDriverField[] }>();

  return (
    <>
      <h4 className="text-default-primary text-sm font-semibold leading-6">New drivers</h4>
      <div className="flex w-full flex-col gap-4">
        {values.drivers.map((driver, index) => (
          <DriverField key={index} driver={driver} index={index} isDisabled={disabled} />
        ))}
      </div>
    </>
  );
}

type DriverFieldProps = {
  driver: NewDriverField;
  index?: number;
  isDisabled?: boolean;
};

const DriverField = memo(
  function DriverField({ driver, index = -1, isDisabled }: DriverFieldProps) {
    const { handleChange, handleBlur, touched, errors } = useFormikContext<{
      drivers: NewDriverField[];
    }>();

    const driverErrors = errors.drivers as FormikErrors<NewDriverField[]> | undefined;

    return (
      <div className="flex flex-col items-start justify-center gap-2 md:flex-row md:gap-4">
        <div className="flex w-full items-start gap-2 md:gap-4">
          <Checkbox
            className="self-center"
            name={`drivers.${index}.checked`}
            checked={driver.checked}
            disabled={isDisabled}
            onBlur={handleBlur}
            onChange={handleChange}
          />
          <TextField
            size="medium"
            name={`drivers.${index}.first_name`}
            placeholder="First Name"
            value={driver.first_name}
            onBlur={handleBlur}
            onChange={handleChange}
            disabled={isDisabled}
            error={touched.drivers?.[index]?.first_name && !!driverErrors?.[index]?.first_name}
            helperText={touched.drivers?.[index]?.first_name && driverErrors?.[index]?.first_name}
          />
          <TextField
            size="medium"
            name={`drivers.${index}.last_name`}
            placeholder="Last Name"
            value={driver.last_name}
            onBlur={handleBlur}
            onChange={handleChange}
            disabled={isDisabled}
            error={touched.drivers?.[index]?.last_name && !!driverErrors?.[index]?.last_name}
            helperText={touched.drivers?.[index]?.last_name && driverErrors?.[index]?.last_name}
          />
        </div>
        <div className="w-full pl-7 md:pl-0">
          <PhoneInput
            fullWidth
            name={`drivers.${index}.phone`}
            size="medium"
            value={driver.phone}
            onBlur={handleBlur}
            onChange={handleChange}
            disabled={isDisabled}
            error={touched.drivers?.[index]?.phone && !!driverErrors?.[index]?.phone}
            helperText={touched.drivers?.[index]?.phone && driverErrors?.[index]?.phone}
          />
        </div>
      </div>
    );
  },
  (prevProps, nextProps) => isEqual(prevProps, nextProps),
);
