import AssignTag from '@app/components/AssignEntity/AssignTag';
import { SidebarWrapper } from '@app/components/wrappers/SidebarWrapper';
import { SideBarBody, SideBarFooter } from '@atob-developers/shared/src/components/SideBar';
import { Button } from '@mui/material';
import { ReactElement, useState } from 'react';

interface AssignTagSidebarProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onSaveTagClick: (tagID: number) => void;
  selectedID: number | null;
}

export const AssignTagSidebar = ({
  open,
  setOpen,
  onSaveTagClick,
  selectedID,
}: AssignTagSidebarProps): ReactElement => {
  const [selectedIDInner, setSelectedID] = useState<number | null>(selectedID);

  return (
    <SidebarWrapper title="Assign Tag" open={open} setOpen={setOpen}>
      <SideBarBody>
        <div className="mb-6 mt-3">
          <AssignTag
            tagID={selectedIDInner}
            onChange={(id) => setSelectedID(id ? Number(id) : null)}
          />
        </div>
      </SideBarBody>
      <SideBarFooter>
        <Button
          size="small"
          disabled={selectedIDInner === null}
          onClick={() => selectedIDInner && onSaveTagClick(selectedIDInner)}
          fullWidth
        >
          Assign Tag
        </Button>
      </SideBarFooter>
    </SidebarWrapper>
  );
};
