import { CUSTOMER_QUERY_KEY } from '@app/hooks/query/useCustomerQuery';
import { guardAxiosError } from '@app/utils/error/guards';
import { isOTPError } from '@app/utils/error/isRetryableError';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { LoadingButton } from '@mui/lab';
import { Button, Divider } from '@mui/material';
import { useMemo, useState } from 'react';
import { mutate } from 'swr';
import { v4 as uuid } from 'uuid';
import {
  AmountInput,
  DescriptionInput,
  DestinationSelector,
  PaymentMethodSelector,
} from '../Inputs';
import { ConfirmationModal, SuccessModal } from '../Modals';
import { SuccessIcon } from '../Modals/SuccessModal';
import {
  estimatedReceptionTimeMapping,
  FeeConfiguration,
  filterDestinationsBySelectedMethod,
  NormalizedDestination,
  PaymentMethodType,
  TransferKind,
  WithdrawalState,
  withdrawFunds,
} from '../utils';
import { useWalletTransfer } from './useWalletTransfer';

export const TransferPanel = ({
  loadingMethods,
  loadingDestinations,
  availableMethods,
  availableDestinations,
  reset,
  onCompletedTransfer,
  walletBalance,
  transferKind,
}: {
  availableMethods: FeeConfiguration[];
  availableDestinations: NormalizedDestination[];
  loadingMethods: boolean;
  loadingDestinations: boolean;
  reset: () => void;
  onCompletedTransfer: () => void;
  walletBalance?: string | null;
  transferKind: TransferKind;
}) => {
  const [withdrawalState, setWithdrawalState] = useState<WithdrawalState>('initial');
  const { goPrevStep, selectedRecipient } = useWalletTransfer();
  const [loading, setLoading] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState<PaymentMethodType | null>(null);
  const [selectedDestinationID, setSelectedDestinationID] = useState<
    NormalizedDestination['id'] | null
  >(null);
  const [amount, setAmount] = useState('');
  const [description, setDescription] = useState('');
  const [validAmount, setValidAmount] = useState(true);
  const { addToast } = useToasts();
  const filteredDestinations = useMemo(() => {
    return filterDestinationsBySelectedMethod(availableDestinations, selectedMethod);
  }, [availableDestinations, selectedMethod]);
  const submitFunds = async ({
    destination,
    selectedTransferMethod,
  }: {
    destination: NormalizedDestination;
    selectedTransferMethod: FeeConfiguration;
  }) => {
    if (withdrawalState === 'initial') {
      setWithdrawalState('confirmation');
      return;
    }

    setLoading(true);

    try {
      await withdrawFunds(
        amount,
        description,
        uuid(),
        selectedTransferMethod,
        destination.id,
        destination.recipientType === 'own' ? 'own_transfer' : 'external_transfer',
      );
      onCompletedTransfer();
      setWithdrawalState('success');
    } catch (e: unknown) {
      if (isOTPError(e)) {
        // Skip showing this error, because it will be intercepted by the OTP handler
        return;
      }

      const defaultError = 'Something went wrong. Please try again later.';
      if (guardAxiosError(e)) {
        const message = `There was an error: ${e?.response?.data?.errors?.[0] || defaultError}`;
        addToast({ type: 'error', title: message });
      } else {
        addToast({ type: 'error', title: defaultError });
      }
    } finally {
      setLoading(false);
      setTimeout(() => mutate(CUSTOMER_QUERY_KEY), 1000);
    }
  };

  const selectedMethodConfiguration = availableMethods.find(
    (method) => method.type === selectedMethod,
  );

  const selectedDestination =
    availableDestinations.find((destination) => destination.id === selectedDestinationID) ?? null;

  const estimatedReceptionTime =
    selectedMethodConfiguration && estimatedReceptionTimeMapping[selectedMethodConfiguration.type];
  const isInstant = estimatedReceptionTime === 'shortly';
  const title = isInstant ? 'Transfer complete' : 'Transfer initiated';
  const secondaryTitle = isInstant
    ? 'The funds were successfully transferred from your AtoB Wallet.'
    : `Transferred funds should arrive ${estimatedReceptionTime}.`;

  return (
    <>
      <div className="mb-[72px] flex-1">
        <div className="mb-12 flex flex-col gap-6">
          <PaymentMethodSelector
            feeConfiguration={availableMethods}
            availableMethods={availableMethods.map((method) => method.type)}
            availableDestinations={availableDestinations}
            selectedDestinationID={selectedDestinationID}
            selectedMethod={selectedMethod}
            setSelectedDestinationID={setSelectedDestinationID}
            setSelectedMethod={setSelectedMethod}
            loadingMethods={loadingMethods}
            transferKind={transferKind}
          />
          <DestinationSelector
            availableDestinations={filteredDestinations}
            selectedDestination={selectedDestination}
            selectedMethod={selectedMethod}
            setSelectedDestinationID={setSelectedDestinationID}
            loadingDestinations={loadingDestinations}
            transferKind={transferKind}
            selectedRecipient={selectedRecipient}
          />
          <AmountInput
            validAmount={validAmount}
            amount={amount}
            setAmount={setAmount}
            walletBalance={walletBalance}
            setValidAmount={setValidAmount}
            feeConfiguration={selectedMethodConfiguration}
          />
          <DescriptionInput description={description} setDescription={setDescription} />
        </div>
      </div>
      <div className="bg-soft-primary absolute bottom-0 left-0 right-0 w-full overflow-hidden">
        <Divider />
        <div className="flex gap-3 px-6 pb-6 pt-4 md:px-8 md:pb-8">
          <Button
            className="w-full"
            onClick={() => {
              goPrevStep();
            }}
            color="secondary"
          >
            Previous step
          </Button>

          <LoadingButton
            color="primary"
            fullWidth
            onClick={() =>
              submitFunds({
                destination: selectedDestination!,
                selectedTransferMethod: selectedMethodConfiguration!,
              })
            }
            disabled={
              !selectedMethod ||
              !selectedDestination ||
              !amount ||
              !validAmount ||
              (selectedMethod == 'us_domestic_wire' && selectedDestination?.needsAddress)
            }
            loading={loading}
          >
            Transfer funds
          </LoadingButton>
        </div>
      </div>

      {withdrawalState === 'confirmation' && selectedMethod != null && (
        <ConfirmationModal
          open
          onClose={() => setWithdrawalState('initial')}
          destination={selectedDestination!}
          method={selectedMethod}
          amount={amount}
          fee={selectedMethodConfiguration}
          description={description}
          onConfirm={() =>
            submitFunds({
              destination: selectedDestination!,
              selectedTransferMethod: selectedMethodConfiguration!,
            })
          }
          loading={loading}
          buttonText="Transfer now"
          title="Review Transfer"
        />
      )}
      {withdrawalState === 'success' && selectedMethod != null && (
        <SuccessModal
          open
          onClose={reset}
          destination={selectedDestination!}
          method={selectedMethod}
          amount={amount}
          fee={selectedMethodConfiguration}
          description={description}
          title={title}
          secondaryTitle={secondaryTitle}
          icon={isInstant ? SuccessIcon.CHECK : SuccessIcon.CLOCK}
        />
      )}
    </>
  );
};
