import useChannelPartner from '@app/hooks/useChannelPartner';
import useProduct from '@app/hooks/useProduct';
import React, { ReactElement } from 'react';

type LogoProps = {
  darkStyle?: boolean;
} & React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

export function PartnerLogo({ darkStyle, ...props }: LogoProps): ReactElement {
  const [showPremiumLogo] = useProduct('premium');
  const { partnerLogoUrl, partnerDarkLogoUrl, partnerName } = useChannelPartner(showPremiumLogo);

  return (
    <img
      alt={partnerName || 'Partner'}
      src={darkStyle && partnerDarkLogoUrl ? partnerDarkLogoUrl : partnerLogoUrl}
      {...props}
    />
  );
}
