import { CustomerData } from '@app/@types/customer.types';
import UpdateSettingsModal from '@app/components/Modals/SettingsModals/UpdateSettingsModal';
import SettingsPageUpdatePhonePrompt from '@app/components/Prompt/SettingsPageUpdateInfoPrompt';
import { CustomerProduct } from '@app/constants/customerProducts';
import { SettingsPageAddressType, SettingsPageEntityType } from '@app/constants/settings';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import phoneFormat from '@atob-developers/shared/src/utils/formatters/phoneFormat';
import { ReactElement, useState } from 'react';
import SettingsPageRow, { IAddressProps } from './SettingsPageRow';

const getTypeValue = (data: CustomerData, entityType: SettingsPageEntityType) => {
  switch (entityType) {
    case SettingsPageEntityType.EMAIL_ADDRESS:
      return data.owner_email;
    case SettingsPageEntityType.TELEMATICS_NOTIFICATIONS_ENABLED:
      return data.telematics_notifications_enabled;
    case SettingsPageEntityType.CUSTOMER_COMPANY_PREFERRED_NAME:
      return data.company_preferred_name;
    case SettingsPageEntityType.PHONE_NUMBER:
      return phoneFormat({ value: data.owner_phone });
    case SettingsPageEntityType.COMPANY_ADDRESS:
      return {
        ...data.company_address,
        typeOfAddress: SettingsPageAddressType.CARD_SHIPPING_ADDRESS,
      };
    case SettingsPageEntityType.CARD_SHIPPING_ADDRESS:
      return {
        // If the company does not have a separate address to start
        // Show the company address in the form
        ...(data.card_shipping_address ?? data.company_address),
        typeOfAddress: SettingsPageAddressType.CARD_SHIPPING_ADDRESS,
      };
    case SettingsPageEntityType.FLEET_TYPE:
      return data.fleet_type;
    case SettingsPageEntityType.OTP_BACKUP_EMAIL:
      return data.otp_backup_email ?? 'Not Set';
    default:
      return '';
  }
};

const ProfileSettings = ({
  products,
  customer,
  setCustomer,
}: {
  products: CustomerProduct[];
  customer: CustomerData;
  setCustomer: (customer: CustomerData) => void;
}): ReactElement => {
  const [payrollEnabled, fuelCardEnabled, updateEmailEnabled] = [
    products.includes('payroll'),
    products.includes('fuel_card'),
    products.includes('settings_update_email'),
  ];
  const {
    owner_email: email,
    owner_phone: phone,
    company_address: companyAddress,
    card_shipping_address: cardShippingAddress,
    company_preferred_name: companyPreferredName,
    fleet_type: fleetType,
    otp_backup_email: backupOTPEmail,
  } = customer;
  const [backupOTPEmailEnabled] = useFeatureFlags('allow_set_otp_backup_email');
  const [modalType, setModalType] = useState<SettingsPageEntityType | undefined>(undefined);
  const [confirmationPrompt, setConfirmationPrompt] = useState<SettingsPageEntityType | null>(null);
  const showPreferredNameSetting = !!companyPreferredName && payrollEnabled;
  const cardShippingAddressValues = cardShippingAddress ?? companyAddress;

  return (
    <>
      {modalType && (
        <UpdateSettingsModal
          open={!!modalType}
          type={modalType}
          toggle={() => {
            setModalType(undefined);
          }}
          value={getTypeValue(customer, modalType) as string | IAddressProps}
          setUpdateInfoPrompt={setConfirmationPrompt}
          setCustomer={setCustomer}
          customer={customer}
        />
      )}
      {confirmationPrompt !== null && (
        <SettingsPageUpdatePhonePrompt
          type={confirmationPrompt}
          clickHandler={() => setConfirmationPrompt(null)}
        />
      )}
      <div className="flex grow flex-col gap-6 p-2 pb-10 md:gap-8 md:p-4 lg:p-8">
        <SettingsPageRow
          type={SettingsPageEntityType.EMAIL_ADDRESS}
          value={email}
          disableUpdate={!updateEmailEnabled}
          onClickUpdate={() => {
            setModalType(SettingsPageEntityType.EMAIL_ADDRESS);
          }}
        />
        <hr />
        <SettingsPageRow
          type={SettingsPageEntityType.PHONE_NUMBER}
          value={phoneFormat({ value: phone })}
          onClickUpdate={() => {
            setModalType(SettingsPageEntityType.PHONE_NUMBER);
          }}
        />
        <hr />
        <SettingsPageRow
          type={SettingsPageEntityType.COMPANY_ADDRESS}
          value={{
            ...companyAddress,
            typeOfAddress: SettingsPageAddressType.COMPANY_ADDRESS,
            type: SettingsPageAddressType.COMPANY_ADDRESS,
          }}
          onClickUpdate={() => {
            setModalType(SettingsPageEntityType.COMPANY_ADDRESS);
          }}
        />
        <hr />
        {fuelCardEnabled && (
          <>
            <SettingsPageRow
              type={SettingsPageEntityType.CARD_SHIPPING_ADDRESS}
              value={{
                ...cardShippingAddressValues,
                typeOfAddress: SettingsPageAddressType.CARD_SHIPPING_ADDRESS,
                type: SettingsPageAddressType.CARD_SHIPPING_ADDRESS,
              }}
              onClickUpdate={() => {
                setModalType(SettingsPageEntityType.CARD_SHIPPING_ADDRESS);
              }}
            />
            <hr />
          </>
        )}
        {showPreferredNameSetting && (
          <>
            <SettingsPageRow
              type={SettingsPageEntityType.CUSTOMER_COMPANY_PREFERRED_NAME}
              value={companyPreferredName}
              onClickUpdate={() => {
                setModalType(SettingsPageEntityType.CUSTOMER_COMPANY_PREFERRED_NAME);
              }}
            />
            <hr />
          </>
        )}
        <SettingsPageRow
          type={SettingsPageEntityType.FLEET_TYPE}
          value={fleetType}
          onClickUpdate={() => {
            setModalType(SettingsPageEntityType.FLEET_TYPE);
          }}
        />
        {backupOTPEmailEnabled && (
          <>
            <hr />
            <SettingsPageRow
              type={SettingsPageEntityType.OTP_BACKUP_EMAIL}
              value={backupOTPEmail}
              onClickUpdate={() => {
                setModalType(SettingsPageEntityType.OTP_BACKUP_EMAIL);
              }}
            />
          </>
        )}
      </div>
    </>
  );
};

export default ProfileSettings;
