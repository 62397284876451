import classNames from 'classnames';
import { useEffect, useState } from 'react';

export interface RadioItemProps {
  id: string;
  name: string;
  label?: string;
  value: string;
  checked: boolean;
  setChecked?: () => void;
}

const RADIO_BUTTON_SIZE = {
  s: 'w-3 h-3',
  m: 'w-[30px] h-[30px]',
  l: 'w-7 h-7',
};

export const RadioItem = ({ id, name, value, setChecked, checked, label }: RadioItemProps) => {
  return (
    <div className="mb-2 flex cursor-pointer items-center" onClick={setChecked}>
      <input
        type="checkbox"
        id={id}
        name={name}
        onChange={setChecked}
        checked={checked}
        value={value || ''}
        className={classNames(
          'border-soft text-default-secondary cursor-pointer rounded-2xl',
          RADIO_BUTTON_SIZE['m'],
        )}
      ></input>
      <label className="text-default-primary ml-2 text-sm" htmlFor={id}>
        {label}
      </label>
    </div>
  );
};

interface RadioGroupProps {
  data: RadioItemProps[];
  onSelect: (val: string) => void;
  className?: string;
}

const RadioGroup = ({ data, onSelect, className }: RadioGroupProps) => {
  const [checked, setChecked] = useState<string | undefined>(() => {
    // Check if there is option that needs to be checked by default
    const item = data.find((item) => item.checked);
    return item?.id;
  });

  useEffect(() => {
    const item = data.find((item) => item.checked);
    if (item && item.id !== checked) {
      setChecked(data.find((item) => item.checked)?.id);
    }
  }, [data, checked]);

  const handleChecked = (item: RadioItemProps) => {
    // Handle option change
    onSelect(item.value);
    // If there is something that needs to happen when certain item is checked call that function here
    item.setChecked && item.setChecked();
    setChecked(item.id);
  };

  return (
    <div className={className || ''}>
      {data.map((item: RadioItemProps) => (
        <div key={item.id}>
          <RadioItem
            id={item.id}
            name={item.name}
            label={item.label}
            checked={checked === item.id}
            setChecked={() => handleChecked(item)}
            value={item.value}
          />
        </div>
      ))}
    </div>
  );
};

export default RadioGroup;
