import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import { useGetUploadedFiles } from '@app/hooks/query/useIftaFilingQuery';
import { Button } from '@mui/material';
import { ReactElement, useState } from 'react';
import { useCSVReader, formatFileSize } from 'react-papaparse';
import { useNavigate } from 'react-router-dom';
import { styles, UploadHandlerProps } from './UploadIftaReport';

type UploadIftaReportFromTelematicsInterface = {
  handleFileUpload: (file: File | null, results: Array<Array<string>>) => void;
};

const UploadIftaReportFromTelematics = ({
  handleFileUpload,
}: UploadIftaReportFromTelematicsInterface): ReactElement => {
  const [zoneHover, setZoneHover] = useState(false);
  const { data: files } = useGetUploadedFiles('1');
  const { CSVReader } = useCSVReader();

  const navigate = useNavigate();

  const handleDownload = () => {
    const headers = ['VIN', 'Jurisdiction', 'Enter Time', 'Distance', 'Year', 'Month', 'Fuel Type'];
    const data = [['vehicle1', 'OK', 'Jul 01 2024 12:00:00 AM', '23', '2025', 7, 'diesel']];

    let csvContent = 'data:text/csv;charset=utf-8,';
    csvContent += headers.join(',') + '\n';
    csvContent += data.map((row) => row.join(',')).join('\n');

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'table_data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onClickSkip = async () => {
    const isUploaded = files && Array.isArray(files.data) && files.data.length == 0;
    navigate(isUploaded ? '/ifta-filing?stage=4' : '/ifta-filing?stage=3');
  };
  const onClickIntegrate = () => {
    navigate('/integrations');
  };
  return (
    <>
      <RoundedCard className="relative flex-1 py-4">
        <div className="flex h-[75vh] items-center justify-center bg-white">
          <div className="rounded-lg bg-white text-center">
            <h2 className="mb-4 text-lg font-semibold text-gray-800">
              Please upload your telematics IFTA <br /> report here
            </h2>
            <CSVReader
              onUploadAccepted={(
                results: {
                  data: Array<Array<string>>;
                },
                file: File,
              ) => {
                setZoneHover(false);
                handleFileUpload(file, results.data);
              }}
              onDragOver={(event: DragEvent) => {
                event.preventDefault();
                setZoneHover(true);
              }}
              onDragLeave={(event: DragEvent) => {
                event.preventDefault();
                setZoneHover(false);
              }}
            >
              {({ getRootProps, acceptedFile, ProgressBar }: UploadHandlerProps) => (
                <>
                  <div
                    {...getRootProps()}
                    className="cursor-pointer"
                    style={Object.assign({}, styles.zone, zoneHover && styles.zoneHover)}
                  >
                    {acceptedFile ? (
                      <>
                        <div style={styles.file}>
                          <div style={styles.info}>
                            <span style={styles.size}>{formatFileSize(acceptedFile.size)}</span>
                            <span style={styles.name}>{acceptedFile.name}</span>
                          </div>
                          <div style={styles.progressBar}>
                            <ProgressBar />
                          </div>
                        </div>
                      </>
                    ) : (
                      'Drop CSV file here or click to upload'
                    )}
                  </div>
                </>
              )}
            </CSVReader>
            <div
              onClick={handleDownload}
              className="mt-4 block cursor-pointer text-sm text-blue-500"
            >
              Download format
            </div>
            <div className="flex items-center justify-center">
              <Button
                className="mt-4 w-full px-6 py-2 sm:w-auto"
                size="small"
                onClick={onClickSkip}
                data-testid={'skip-ifta-button'}
              >
                Skip
              </Button>
              <Button
                className="mx-4 mt-4 w-full px-6 py-2 sm:w-auto"
                size="small"
                onClick={onClickIntegrate}
                data-testid={'integrate-telematics-button'}
              >
                Integrate with Telematics
              </Button>
            </div>
          </div>
        </div>
      </RoundedCard>
    </>
  );
};

export default UploadIftaReportFromTelematics;
