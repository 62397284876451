import { BankAccountPaymentMethodCombined } from '@app/@types/bankAccount.types';
import { DebitCardPaymentMethodCombined } from '@app/@types/debitCard.types';
import PaymentMethodSection from '@app/pages/PaymentMethods/PaymentMethodsSection';
import NoPaymentsSection from '../../pages/PaymentMethods/NoPaymentsSection';
import type { WalletPaymentMethodInfo } from '../../pages/PaymentMethods/PaymentMethods';

interface PaymentMethodsListProps {
  bankAccounts: BankAccountPaymentMethodCombined[];
  debitCards: DebitCardPaymentMethodCombined[];
  wallet?: WalletPaymentMethodInfo;
  setError: (e: Error) => void;
  handleRefreshPaymentMethods: () => void;
  onLinkingCallback: (phase: string) => void;
}

const PaymentMethodsList = ({
  bankAccounts,
  debitCards,
  wallet,
  setError,
  handleRefreshPaymentMethods,
  onLinkingCallback,
}: PaymentMethodsListProps) => {
  return (
    <>
      {!wallet && !debitCards && !bankAccounts && (
        <NoPaymentsSection
          setError={setError}
          handleRefreshPaymentMethods={handleRefreshPaymentMethods}
          onLinkingCallback={onLinkingCallback}
        />
      )}
      <PaymentMethodSection
        bankAccounts={bankAccounts}
        debitCards={debitCards}
        wallet={wallet}
        setError={setError}
        handleRefreshPaymentMethods={handleRefreshPaymentMethods}
        onLinkingCallback={onLinkingCallback}
      />
    </>
  );
};

export default PaymentMethodsList;
