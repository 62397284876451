import useThemeConfiguration from '@app/app/useThemeConfiguration';
import RaceTracIcon from '@app/assets/images/svg/racetrac-logo.svg';
import SpeedwayIcon from '@app/assets/images/svg/speedway-logo.svg';
import TAPetroIcon from '@app/assets/images/svg/ta-petro-logo.svg';
import { useEventDismissed } from '@app/hooks/useEventDismissed';
import useProduct from '@app/hooks/useProduct';
import useThemeMode from '@app/hooks/useThemeMode';
import WeeklyDiscountBoostModal from '@app/pages/AccountOverview/WeeklyDiscountBoostModal';
import { useChallengeMatch } from '@app/pages/AccountOverview/useChallengeMatch';
import { formatCurrencyToNearestDollarCeil } from '@app/utils/challenge-match-card';
import logger from '@app/utils/datadog-logger';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faDollar, faXmark } from '@fortawesome/pro-regular-svg-icons';
import { faRocketLaunch } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import classNames from 'classnames';
import { PropsWithChildren, ReactElement, useState } from 'react';
import { useInView } from 'react-intersection-observer';

export interface ProductBannerProps {
  bannerKey: string;
  nonDismissable?: boolean;
  icon?: IconProp;
  iconImg?: React.ReactNode;
}

export const DiscountBoostBanner = ({
  bannerKey,
  nonDismissable = false,
  icon,
  iconImg,
}: PropsWithChildren<ProductBannerProps>): ReactElement | null => {
  const { dismissed, loading, setDismissed } = useEventDismissed(bannerKey);
  const { hideDismissableBanners } = useThemeConfiguration();
  const { darkClassName } = useThemeMode();

  const [showModal, setShowModal] = useState(false);
  const [challengeMatchEnabled] = useProduct('challenge_match');
  const { challengeMatch, currentDiscountBoost, currentLevel, nextLevel, isTAOnly } =
    useChallengeMatch();

  const { ref } = useInView({
    triggerOnce: true,
    rootMargin: '50%',
    onChange: (inView) => {
      if (inView) {
        logger.info('banner-shown', { key: bannerKey });
      }
    },
  });

  if (loading || dismissed || hideDismissableBanners || !challengeMatchEnabled) {
    return null;
  }

  const max =
    (nextLevel
      ? nextLevel.threshold_amount.cents
      : challengeMatch?.levels[challengeMatch?.levels.length - 1]?.threshold_amount.cents) ?? 0;

  return (
    <div
      ref={ref}
      className={classNames(
        darkClassName,
        'bg-soft-primary bg-discount-boost-banner-bg-mobile md:bg-discount-boost-banner-bg-desktop border-soft relative w-full rounded-md border bg-[length:auto_100%] bg-right bg-no-repeat px-4 py-4 text-sm',
      )}
    >
      <WeeklyDiscountBoostModal open={showModal} onClose={() => setShowModal(false)} />
      <div className="flex gap-4">
        <div className="hidden justify-center md:flex">
          {iconImg ? (
            <div className="h-11 w-11">{iconImg}</div>
          ) : icon ? (
            <div
              className={classNames(
                'flex h-10 w-10 items-center justify-center rounded-full',
                currentLevel ? 'bg-green-secondary' : 'bg-strong-secondary',
              )}
            >
              <FontAwesomeIcon
                icon={currentLevel ? faRocketLaunch : faDollar}
                className={classNames(
                  'h-4 w-4',
                  currentLevel ? 'text-green' : 'text-default-secondary',
                )}
              />
            </div>
          ) : (
            <div className="border-soft text-default-primary h-6 rounded border px-2 py-1 text-xs uppercase">
              New
            </div>
          )}
        </div>

        <div className="text-default-primary flex grow flex-col justify-between gap-3 xl:flex-row xl:gap-1">
          <div className="flex flex-col justify-center">
            <p className="text-green text-xs font-semibold uppercase tracking-[0.8px]">
              Discount boost
            </p>
            <div className="flex flex-wrap items-center">
              {currentDiscountBoost > 0 ? (
                <p className="whitespace-nowrap">
                  You've unlocked a{' '}
                  <span className="text-green">{currentDiscountBoost}¢ per gallon discount</span> at
                </p>
              ) : (
                <p className="whitespace-nowrap">
                  Unlock <span className="text-green">extra discounts</span> by spending at
                </p>
              )}
              {isTAOnly ? (
                <>
                  <span className="flex whitespace-nowrap">
                    <img src={TAPetroIcon} className="mx-1 h-5 w-5 flex-shrink-0" /> TA Petro
                  </span>
                </>
              ) : (
                <>
                  <span className="flex whitespace-nowrap">
                    <img src={TAPetroIcon} className="mx-1 h-5 w-5 flex-shrink-0" /> TA Petro,
                  </span>
                  <span className="flex whitespace-nowrap">
                    <img src={SpeedwayIcon} className="mx-1 h-5 w-5 flex-shrink-0" />
                    Speedway &
                  </span>
                  <span className="flex whitespace-nowrap">
                    <img src={RaceTracIcon} className="mx-1 h-5 w-5 flex-shrink-0" />
                    Racetrac
                  </span>
                </>
              )}

              {nextLevel && (
                <p className="whitespace-nowrap">
                  , only{' '}
                  {formatCurrencyToNearestDollarCeil(
                    max - (challengeMatch?.this_week.settled_amt.cents ?? 0),
                  )}{' '}
                  to your next tier!
                </p>
              )}
            </div>
          </div>

          <div className="flex gap-2">
            <Button
              size="small"
              className="w-1/2 md:w-fit"
              onClick={() => {
                logger.info('banner-cta-clicked', { key: bannerKey });
                setShowModal(true);
              }}
            >
              See details
            </Button>

            {!nonDismissable && (
              <>
                <IconButton
                  size="small"
                  color="secondary"
                  className="hidden xl:block"
                  onClick={() => {
                    logger.info('banner-dismissed', { key: bannerKey });
                    setDismissed();
                  }}
                >
                  <FontAwesomeIcon icon={faXmark} className="text-base" />
                </IconButton>
                <Button
                  size="small"
                  color="secondary"
                  className="w-1/2 md:w-fit xl:hidden"
                  onClick={() => {
                    logger.info('banner-dismissed', { key: bannerKey });
                    setDismissed();
                  }}
                >
                  Dismiss
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
