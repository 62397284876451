import BankAccountIcon from '@app/assets/images/svg/bank_account_icon.svg';
import PaypalIcon from '@app/assets/images/svg/paypal_icon.svg';
import VenmoIcon from '@app/assets/images/svg/venmo_icon.svg';
import VisaIcon from '@app/assets/images/svg/visa_icon.svg';
import ZelleIcon from '@app/assets/images/svg/zelle_icon.svg';
import DebitCardModal from '@app/components/PaymentMethods/DebitCardModal';
import RadioSelector from '@app/components/RadioSelector/RadioSelector';
import { PAYMENT_METHOD_QUERY_KEY } from '@app/hooks/query/usePaymentMethodsQuery';
import usePlaid from '@app/hooks/usePlaid';
import { usePlaidConnectHandler, usePlaidUpdateHandler } from '@app/hooks/usePlaidHandlers';
import { faExclamationCircle, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Chip, RadioGroup } from '@mui/material';
import {
  PropsWithChildren,
  ReactElement,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { mutate } from 'swr';
import { accountNameMapping, NormalizedSource, PaymentMethodType } from '../utils';

const SourceItemWrapper = ({
  name,
  icon,
  children,
}: PropsWithChildren<{
  name: string | ReactElement;
  icon: ReactElement;
}>) => {
  return (
    <div className="flex w-full flex-grow items-center gap-2">
      <div className="flex w-full flex-wrap items-center gap-2">
        <div className="text-default-primary flex items-center gap-2 text-base">
          {icon}
          <span>{name}</span>
        </div>
        {children}
      </div>
    </div>
  );
};

const iconMapping = {
  debit_card: VisaIcon,
  zelle_account: ZelleIcon,
  bank_account: BankAccountIcon,
  paypal_account: PaypalIcon,
  venmo_account: VenmoIcon,
};

const SourceItem = ({
  source,
  isPendingAccount,
}: {
  source: NormalizedSource;
  isPendingAccount: boolean;
}) => {
  if (!source) return null;

  return (
    <SourceItemWrapper
      icon={<img src={iconMapping[source.type]} alt={source.type} />}
      name={<>••••&nbsp;{source.lastFour}</>}
    >
      <Chip
        color="grey"
        size="small"
        label={source.name + (source.alternateName ? ` (${source.alternateName})` : '')}
      />
      {isPendingAccount && (
        <Chip
          color="orange"
          size="small"
          label="Verification needed"
          icon={<FontAwesomeIcon icon={faExclamationCircle} />}
        />
      )}
    </SourceItemWrapper>
  );
};

const SourceRadioButton = ({
  source,
  isSelected,
  setSelectedSource,
  selectedMethod,
  disabled,
}: {
  source: NormalizedSource;
  isSelected: boolean;
  setSelectedSource: (source: NormalizedSource) => void;
  selectedMethod: PaymentMethodType;
  disabled: boolean;
}) => {
  const { onSuccess, onEvent } = usePlaidUpdateHandler(source.id);

  const { isPlaidReady, openPlaidModal } = usePlaid({
    updateBankAccountId: source.id,
    onEvent,
    onSuccess,
    onSuccessCallback: () => mutate(PAYMENT_METHOD_QUERY_KEY),
  });

  const isPendingAccount = selectedMethod === 'ach' && source.state === 'pending';

  return (
    <RadioSelector
      key={source.id}
      value={source.id}
      label={<SourceItem source={source} isPendingAccount={isPendingAccount} />}
      onChange={() => {
        if (isPendingAccount && isPlaidReady) {
          openPlaidModal();
          return;
        }
        setSelectedSource(source);
      }}
      labelPlacement="start"
      checked={isSelected}
      disabled={disabled}
    />
  );
};

export const SourceSelector = ({
  selectedMethod,
  availableSources,
  loadingSources,
  selectedSource,
  setSelectedSource,
}: {
  selectedMethod: PaymentMethodType | null;
  availableSources: NormalizedSource[];
  loadingSources: boolean;
  selectedSource: NormalizedSource | null;
  setSelectedSource: (value: SetStateAction<NormalizedSource | null>) => void;
}) => {
  const [showAddDebitCardModal, setShowAddDebitCardModal] = useState(false);

  const divRef = useRef<HTMLDivElement>(null);

  const { onSuccess, onEvent } = usePlaidConnectHandler();

  const { isPlaidReady, openPlaidModal } = usePlaid({
    onEvent,
    onSuccess,
    onSuccessCallback: () => mutate(PAYMENT_METHOD_QUERY_KEY),
  });

  useEffect(() => {
    if (selectedMethod !== null) {
      divRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [availableSources.length, loadingSources, selectedMethod]);

  return (
    <div className="flex flex-col" ref={divRef}>
      <h4 className="text-default-primary mb-2 font-medium">
        {selectedMethod === 'debit' ? 'Debit card' : 'Account'}
      </h4>
      {selectedMethod && availableSources.length === 0 ? (
        <p className="text-default-secondary text-sm">{`There is no ${accountNameMapping[selectedMethod]} attached to your AtoB account yet.`}</p>
      ) : (
        <RadioGroup className="gap-2">
          {availableSources.map((source) => {
            return (
              <SourceRadioButton
                key={source.id}
                selectedMethod={selectedMethod!}
                source={source}
                isSelected={selectedSource?.id === source.id}
                setSelectedSource={setSelectedSource}
                disabled={loadingSources || !selectedMethod}
              />
            );
          })}
        </RadioGroup>
      )}
      <Button
        size="small"
        className="mt-3"
        color="secondary"
        startIcon={<FontAwesomeIcon icon={faPlus} />}
        onClick={() => {
          if (selectedMethod === 'ach') {
            isPlaidReady && openPlaidModal();
          } else if (selectedMethod === 'debit') {
            setShowAddDebitCardModal(true);
          }
        }}
        disabled={selectedMethod === null}
      >
        <span className="ml-2 text-sm">
          Add {selectedMethod && accountNameMapping[selectedMethod]}
        </span>
      </Button>
      <DebitCardModal
        isActive={showAddDebitCardModal && selectedMethod == 'debit'}
        setIsActive={setShowAddDebitCardModal}
        onSuccess={() => {
          mutate(PAYMENT_METHOD_QUERY_KEY);
          setShowAddDebitCardModal(false);
        }}
      />
    </div>
  );
};
