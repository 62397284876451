import { InstantPayoutRecipient } from '@app/@types/instant_payout_method.types';
import BankAccountIcon from '@app/assets/images/svg/bank_account_icon.svg';
import PaypalIcon from '@app/assets/images/svg/paypal_icon.svg';
import VenmoIcon from '@app/assets/images/svg/venmo_icon.svg';
import VisaIcon from '@app/assets/images/svg/visa_icon.svg';
import ZelleIcon from '@app/assets/images/svg/zelle_icon.svg';
import DebitCardModal from '@app/components/PaymentMethods/DebitCardModal';
import RadioSelector from '@app/components/RadioSelector/RadioSelector';
import { PAYMENT_METHOD_QUERY_KEY } from '@app/hooks/query/usePaymentMethodsQuery';
import useProduct from '@app/hooks/useProduct';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Chip, RadioGroup } from '@mui/material';
import {
  PropsWithChildren,
  ReactElement,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { mutate } from 'swr';
import AddBankAccountModal from '../PayNow/AddBankAccountModal';
import EditBankAccountModal from '../PayNow/EditBankAccountModal';
import { addRecipient } from '../WalletPaymentMethods';
import AddInstantPayoutMethodAccountModal from '../WithdrawFundsV2/Modals/AddInstantPayoutMethodAccountModal';
import { useWalletTransfer } from '../WithdrawFundsV2/useWalletTransfer';
import { recipientDataToNewRecipient, RecipientToCreate } from '../transfer.types';
import {
  accountNameMapping,
  editRecipient,
  NormalizedDestination,
  PaymentMethodType,
  TransferKind,
} from '../utils';

const addRecipientAccount = async (recipient: RecipientToCreate) => {
  await addRecipient(recipient);

  await mutate({ url: '/treasury/recipients' });
  if (recipient.instant_payout_recipient_id) {
    await mutate({
      url: `/instant_payout/recipients/${recipient.instant_payout_recipient_id}?include=treasury_recipients,instant_payout_methods`,
    });
  }
};

const DestinationItemWrapper = ({
  name,
  icon,
  children,
}: PropsWithChildren<{
  name: string | ReactElement;
  icon: ReactElement;
}>) => {
  return (
    <div className="flex w-full flex-grow items-center gap-2">
      <div className="flex w-full flex-wrap items-center gap-2">
        <div className="text-default-primary flex items-center gap-2 text-base">
          {icon}
          <span>{name}</span>
        </div>
        {children}
      </div>
    </div>
  );
};

const iconMapping = {
  debit_card: VisaIcon,
  zelle_account: ZelleIcon,
  bank_account: BankAccountIcon,
  paypal_account: PaypalIcon,
  venmo_account: VenmoIcon,
};

const DestinationItem = ({
  destination,
  selectedMethod,
}: {
  destination: NormalizedDestination;
  selectedMethod: PaymentMethodType | null;
}) => {
  if (!destination) return null;

  if (destination.type !== 'debit_card' && destination.type !== 'bank_account') {
    return (
      <DestinationItemWrapper
        name={destination.name}
        icon={<img src={iconMapping[destination.type]} />}
      >
        {destination.alternateName && (
          <Chip color="grey" size="small" label={destination.alternateName} />
        )}
      </DestinationItemWrapper>
    );
  }

  return (
    <DestinationItemWrapper
      icon={<img src={iconMapping[destination.type]} alt={destination.type} />}
      name={<>••••&nbsp;{destination.lastFour}</>}
    >
      <Chip
        color="grey"
        size="small"
        label={
          destination.name + (destination.alternateName ? ` (${destination.alternateName})` : '')
        }
      />
      {selectedMethod === 'us_domestic_wire' && destination.needsAddress && (
        <Chip color="orange" size="small" label="Address required" />
      )}
    </DestinationItemWrapper>
  );
};

const DestinationRadioButton = ({
  selectedMethod,
  availableDestinations,
  selectedDestination,
  setSelectedDestinationID,
  loadingDestinations,
  destination,
}: {
  selectedMethod: PaymentMethodType;
  availableDestinations: NormalizedDestination[];
  selectedDestination: NormalizedDestination;
  setSelectedDestinationID: (destination: NormalizedDestination['id']) => void;
  loadingDestinations: boolean;
  destination: NormalizedDestination;
}) => {
  return (
    <RadioSelector
      key={destination.id}
      value={destination.id}
      label={<DestinationItem selectedMethod={selectedMethod} destination={destination} />}
      onChange={() => {
        if (destination.id === 'ignore') return;
        const matchedDestination = availableDestinations.find((dest) => dest.id === destination.id);
        if (matchedDestination) {
          setSelectedDestinationID(matchedDestination.id);
        }
      }}
      labelPlacement="start"
      checked={selectedDestination?.id === destination.id}
      disabled={loadingDestinations || !selectedMethod}
    />
  );
};

export const DestinationSelector = ({
  selectedDestination,
  availableDestinations,
  loadingDestinations,
  selectedMethod,
  setSelectedDestinationID,
  transferKind,
  selectedRecipient,
}: {
  availableDestinations: NormalizedDestination[];
  selectedDestination: NormalizedDestination | null;
  selectedMethod: PaymentMethodType | null;
  setSelectedDestinationID: (value: SetStateAction<NormalizedDestination['id'] | null>) => void;
  loadingDestinations: boolean;
  transferKind: TransferKind;
  selectedRecipient: InstantPayoutRecipient | null;
}) => {
  const [showAddDestinationModal, setShowAddDestinationModal] = useState(false);
  const [showEditBankAccountModal, setShowEditBankAccountModal] = useState(false);
  const { refetchRecipients } = useWalletTransfer();

  const [manageWalletRecipients] = useProduct('manage_wallet_recipients');

  const divRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (selectedMethod !== null) {
      divRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [availableDestinations.length, loadingDestinations, selectedMethod]);

  const selectedTreasuryRecipient = (selectedRecipient?.treasury_recipients ?? []).find(
    (recip) => recip.id == selectedDestination?.id,
  );

  return (
    <div className="flex flex-col" ref={divRef}>
      <h4 className="text-default-primary mb-2 font-medium">
        {selectedMethod === 'debit' ? 'Debit card' : 'Account'}
      </h4>
      {selectedMethod && availableDestinations.length === 0 ? (
        <p className="text-default-secondary text-sm">{`There is no ${accountNameMapping[selectedMethod]} attached to your AtoB account yet.`}</p>
      ) : (
        <RadioGroup className="gap-2">
          {availableDestinations.map((destination) => {
            return (
              <DestinationRadioButton
                key={destination.id}
                destination={destination}
                selectedMethod={selectedMethod!}
                selectedDestination={selectedDestination!}
                availableDestinations={availableDestinations}
                setSelectedDestinationID={setSelectedDestinationID}
                loadingDestinations={loadingDestinations}
              />
            );
          })}
        </RadioGroup>
      )}
      {selectedMethod == 'us_domestic_wire' && selectedDestination?.needsAddress && (
        <div className="text-warning mt-2 text-sm">
          The account requires an address to support wire transfers.{' '}
          <button onClick={() => setShowEditBankAccountModal(true)} className="underline">
            Enter address
          </button>
        </div>
      )}
      {manageWalletRecipients && (
        <Button
          size="small"
          className="mt-3"
          color="secondary"
          startIcon={<FontAwesomeIcon icon={faPlus} />}
          onClick={() => setShowAddDestinationModal(true)}
          disabled={selectedMethod === null}
        >
          <span className="ml-2 text-sm">
            Add {selectedMethod && accountNameMapping[selectedMethod]}
          </span>
        </Button>
      )}
      <DebitCardModal
        isActive={
          showAddDestinationModal &&
          (transferKind === 'own_transfer' || transferKind === 'deposit') &&
          selectedMethod == 'debit'
        }
        setIsActive={() => setShowAddDestinationModal(false)}
        onSuccess={() => {
          mutate(PAYMENT_METHOD_QUERY_KEY);
          setShowAddDestinationModal(false);
        }}
      />
      {selectedTreasuryRecipient && (
        <EditBankAccountModal
          recipient={recipientDataToNewRecipient(selectedTreasuryRecipient)}
          onEditRecipient={async (updatedRecipient) => {
            await editRecipient(updatedRecipient);
            await mutate({ url: '/treasury/recipients' });
            if (selectedRecipient?.id) {
              await mutate({
                url: `/instant_payout/recipients/${selectedRecipient?.id}?include=treasury_recipients,instant_payout_methods`,
              });
            }
            await refetchRecipients();
            mutate(PAYMENT_METHOD_QUERY_KEY);
            setShowEditBankAccountModal(false);
          }}
          reset={() => setShowEditBankAccountModal(false)}
          open={showEditBankAccountModal}
        />
      )}
      <AddBankAccountModal
        reset={() => setShowAddDestinationModal(false)}
        onAddRecipient={async (recipient) => {
          if (selectedRecipient) {
            await addRecipientAccount({
              ...recipient,
              instant_payout_recipient_id: selectedRecipient.id,
            });
            refetchRecipients();
          } else {
            await addRecipientAccount(recipient);
          }
          setShowAddDestinationModal(false);
        }}
        isOwned={transferKind === 'own_transfer' ? true : false}
        open={showAddDestinationModal && ['ach', 'us_domestic_wire'].includes(selectedMethod || '')}
      />
      {['zelle', 'paypal', 'venmo'].map((type) => (
        <AddInstantPayoutMethodAccountModal
          key={type}
          instantPayoutRecipientId={selectedRecipient?.id}
          reset={() => setShowAddDestinationModal(false)}
          open={showAddDestinationModal && selectedMethod === type}
          type={type as 'zelle' | 'paypal' | 'venmo'}
        />
      ))}
    </div>
  );
};
