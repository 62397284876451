import Modal, {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

export const SwitchPartnersSuccess = (): ReactElement => {
  const navigate = useNavigate();

  const onClose = () => {
    navigate('/');
  };

  return (
    <Modal open={true}>
      <ModalHeader title="" onClose={() => onClose()} />
      <ModalBodyContent overflowVisible={true}>
        <div className="flex flex-col items-center">
          <div className="text-default-primary mb-3 mt-16 md:mt-2">
            <FontAwesomeIcon className="fa-3x" icon={faCircleCheck} />
          </div>
          <h1 className="text-default-primary mb-1 text-center text-2xl font-bold">Success!</h1>
          <p className="text-default-primary my-8 mb-2 w-3/4 text-center text-base">
            Congrats! Your account has been switched successfully. Your existing cards will continue
            to work as usual, and if you wish you can order new ones from the dashboard.
          </p>
        </div>
      </ModalBodyContent>
      <ModalFooter>
        <Button size="medium" onClick={() => onClose()} fullWidth>
          Continue
        </Button>
      </ModalFooter>
    </Modal>
  );
};
