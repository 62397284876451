import Modal, {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { ReactElement } from 'react';
import { TransferSummary } from '../TransferSummary';
import {
  computeTotalFeeAmount,
  transferTypeMapping,
  FeeConfiguration,
  NormalizedDestination,
  PaymentMethodType,
} from '../utils';

export const ErrorModal = ({
  open,
  onClose,
  onRetry,
  destination,
  amount,
  fee,
  description,
  method,
  title = 'Transfer denied',
  secondaryTitle,
}: {
  open: boolean;
  onClose: () => void;
  onRetry: () => void;
  destination: NormalizedDestination;
  amount: string;
  fee?: FeeConfiguration;
  description: string;
  method: PaymentMethodType;
  title?: string;
  secondaryTitle?: string | ReactElement;
}) => {
  const transferAmountCents = Math.round(parseFloat(amount) * 100);
  const feeAmount = fee && computeTotalFeeAmount({ transferAmountCents, feeData: fee });

  return (
    <Modal open={open} toggle={onClose} maxWidth="xs">
      <ModalHeader
        title={title}
        secondaryTitle={secondaryTitle}
        className="justify-center text-center"
        headerImage={
          <FontAwesomeIcon
            icon={faXmark}
            className="bg-red-secondary text-error flex h-5 w-5 justify-self-center rounded-full p-4"
          />
        }
      />
      <ModalBodyContent>
        <TransferSummary
          transferType={transferTypeMapping[method]}
          recipientName={destination.recipientName}
          fee={fee}
          feeAmount={feeAmount}
          description={description}
          amount={transferAmountCents}
        />
      </ModalBodyContent>
      <ModalFooter>
        <Button color="secondary" fullWidth onClick={onClose} className="p-3">
          Close
        </Button>
        <Button color="primary" fullWidth onClick={onRetry} className="p-3">
          Try Again
        </Button>
      </ModalFooter>
    </Modal>
  );
};
