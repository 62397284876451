import { SettingsPageEntityType } from '@app/constants/settings';
import { SETTINGS_ROW_OPTIONS } from '@app/constants/settingsRow';
import { IRowProps } from '@app/pages/Settings/SettingsPageRow';
import { Button } from '@mui/material';
import { ReactElement } from 'react';
import { SettingsCurrentValue } from './SettingsCurrentValue';

export const SettingsMobileDropdown = ({
  type,
  onClickUpdate,
  value,
  disableUpdate,
}: IRowProps): ReactElement => {
  return (
    <div className="flex basis-10/12 flex-col gap-4 xl:hidden">
      <div className="flex flex-col gap-2">
        <p className="text-default-primary flex text-sm font-normal">
          {SETTINGS_ROW_OPTIONS[type].title}
        </p>
        {type === SettingsPageEntityType.EMAIL_ADDRESS ||
        type === SettingsPageEntityType.PHONE_NUMBER ? (
          <SettingsCurrentValue type={type} value={value} />
        ) : (
          <SettingsCurrentValue type={type} value={value} />
        )}
      </div>
      {!disableUpdate && (
        <Button color="secondary" size="medium" onClick={onClickUpdate} fullWidth>
          Update
        </Button>
      )}
    </div>
  );
};
