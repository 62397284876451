import FooterActionButtons from '@app/components/Onboarding/FooterActionButtons';
import Header from '@app/components/Onboarding/Header';
import DriverInvitationConfirmationModal from '@app/components/Onboarding/Modals/DriverInvitationConfirmationModal';
import { inviteDriverGroupFieldValidationSchema } from '@app/utils/validation/onboarding-validation';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, CircularProgress } from '@mui/material';
import { FieldArray, FormikContext, useFormik } from 'formik';
import {
  InviteDriversProvider,
  useInviteDriversContext,
} from '../InviteDrivers/InviteDriversContext';
import NewDriversList from '../InviteDrivers/NewDriversList';
import TelematicsDriversList from '../InviteDrivers/TelematicsDriversList';
import { useOnboardingGuide } from '../useOnboardingGuide';
import { type NewDriverField, useOnboardingInviteDrivers } from '../useOnboardingInviteDrivers';

const initialDriverData: NewDriverField = {
  checked: true,
  first_name: '',
  last_name: '',
  phone: '',
};

export default function OnboardingInviteDrivers() {
  return (
    <InviteDriversProvider>
      <OnboardingInviteDriversContent />
    </InviteDriversProvider>
  );
}

function OnboardingInviteDriversContent() {
  const { goToPrevStep } = useOnboardingGuide();

  const { errors, data, driversCountsData } = useInviteDriversContext();

  const {
    handleAddDrivers,
    disabled,
    isInviteConfirmationModalOpen,
    onInviteConfirmationModalClose,
  } = useOnboardingInviteDrivers();

  const formik = useFormik({
    initialValues: { drivers: [] as NewDriverField[] },
    isInitialValid: false,
    validationSchema: inviteDriverGroupFieldValidationSchema,
    onSubmit: (values) => {
      handleAddDrivers(values.drivers);
    },
  });

  return (
    <div className="flex flex-1 flex-col gap-6 md:gap-8">
      <Header
        title="Invite Drivers to use the Fuel Map"
        description="Drivers will be able to search fuel map and get info about discounts, but won’t access the admin feature."
      />
      <div className="flex grow flex-col items-start gap-3 md:w-full md:gap-6">
        <TelematicsDriversList />
        {!driversCountsData && !data && <CircularProgress className="self-center" />}
        <FormikContext.Provider value={formik}>
          <FieldArray name="drivers">
            {({ push }) => {
              return (
                <>
                  <NewDriversList />
                  {errors.length > 0 && (
                    <div>
                      {errors.map((error) => (
                        <p key={error} className="text-error text-sm font-medium capitalize">
                          {error}
                        </p>
                      ))}
                    </div>
                  )}
                  <Button
                    size="small"
                    color="tertiary"
                    className="mb-3 w-full md:w-fit"
                    startIcon={<FontAwesomeIcon icon={faPlus} />}
                    onClick={() => push(initialDriverData)}
                  >
                    Add driver
                  </Button>
                  <FooterActionButtons
                    isDisabled={disabled}
                    isLoading={disabled}
                    handleClickBack={goToPrevStep}
                    handleClickNext={formik.handleSubmit}
                  />
                </>
              );
            }}
          </FieldArray>
        </FormikContext.Provider>
      </div>
      <DriverInvitationConfirmationModal
        isOpen={isInviteConfirmationModalOpen}
        onClose={onInviteConfirmationModalClose}
      />
    </div>
  );
}
