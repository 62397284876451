import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faBank, faCreditCard, faWallet } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MenuItem, Select } from '@mui/material';
import classNames from 'classnames';
import { ReactElement } from 'react';

export type PaymentSelectionType =
  | 'debit_card'
  | 'bank_account'
  | 'customer_treasury_detail'
  | 'tpay_account';
interface PaymentSelectionDropdownProps {
  selectedPaymentType: PaymentSelectionType;
  setSelectedPaymentType: (item: PaymentSelectionType) => void;
  walletAvailable: boolean;
}

type PaymentSelectionItemType = {
  icon: IconProp;
  paymentType: string;
  paymentTime: string;
};

const DEBIT_CARD_PAYMENT_TYPE: PaymentSelectionItemType = {
  icon: faCreditCard,
  paymentType: 'Debit Card',
  paymentTime: 'Instant',
};

const WALLET_PAYMENT_TYPE: PaymentSelectionItemType = {
  icon: faWallet,
  paymentType: 'AtoB Wallet',
  paymentTime: 'Instant',
};

const BANK_ACCOUNT_TYPE: PaymentSelectionItemType = {
  icon: faBank,
  paymentType: 'Bank Transfer',
  paymentTime: '3 Days',
};

const PaymentSelectionDropdown = ({
  selectedPaymentType,
  setSelectedPaymentType,
  walletAvailable = false,
}: PaymentSelectionDropdownProps): ReactElement => {
  return (
    <Select
      renderValue={(value) => {
        return <PaymentSelectionItem type={value as PaymentSelectionType} />;
      }}
      value={selectedPaymentType}
      onChange={(e) => {
        setSelectedPaymentType(e.target.value as PaymentSelectionType);
      }}
      fullWidth
    >
      <MenuItem key="debit_card" value="debit_card">
        <PaymentSelectionItem
          type="debit_card"
          onClick={() => {
            setSelectedPaymentType('debit_card');
          }}
        />
      </MenuItem>
      <MenuItem key="bank_account" value="bank_account">
        <PaymentSelectionItem type="bank_account" />
      </MenuItem>
      {walletAvailable && (
        <MenuItem key="customer_treasury_detail" value="customer_treasury_detail">
          <PaymentSelectionItem type="customer_treasury_detail" />
        </MenuItem>
      )}
    </Select>
  );
};

interface PaymentTypeSelectorProps {
  paymentType: string;
  paymentTime?: string;
  paymentDescription?: string;
  selected?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  icon: IconProp;
}

const PaymentTypeSelector = ({
  paymentType,
  paymentTime,
  paymentDescription,
  selected = false,
  disabled = false,
  onClick,
  icon,
}: PaymentTypeSelectorProps): ReactElement => (
  <div
    data-testid="PaymentContent-Item"
    onClick={onClick}
    className={classNames(
      'z-base-dropdown flex w-full cursor-pointer items-center rounded-md p-4',
      !selected && 'hover:bg-green-secondary',
    )}
  >
    <div className="bg-green-secondary flex h-8 w-8 min-w-[44px] items-center justify-center rounded-[50%]">
      <FontAwesomeIcon
        icon={icon}
        className={classNames(disabled ? 'text-default-secondary' : 'text-green')}
      />
    </div>
    <div className="ml-3 flex w-full flex-wrap items-center">
      <div className="text-default-primary order-1 w-[60%] leading-5">{paymentType}</div>
      <div className="text-green order-2 m-auto flex-1 pr-2 text-right text-base font-medium">
        {paymentTime}
      </div>
      <div className="text-default-secondary order-3 w-full text-sm leading-3">
        {paymentDescription}
      </div>
    </div>
  </div>
);

const PaymentSelectionItem = ({
  type,
  onClick,
}: {
  type: PaymentSelectionType;
  onClick?: () => void;
}): ReactElement | null => {
  switch (type) {
    case 'bank_account':
      return (
        <PaymentTypeSelector
          paymentType={BANK_ACCOUNT_TYPE.paymentType}
          paymentTime={BANK_ACCOUNT_TYPE.paymentTime}
          onClick={onClick}
          icon={BANK_ACCOUNT_TYPE.icon}
          selected
        />
      );
    case 'debit_card':
      return (
        <PaymentTypeSelector
          paymentType={DEBIT_CARD_PAYMENT_TYPE.paymentType}
          paymentTime={DEBIT_CARD_PAYMENT_TYPE.paymentTime}
          onClick={onClick}
          icon={DEBIT_CARD_PAYMENT_TYPE.icon}
          selected
        />
      );
    case 'customer_treasury_detail':
      return (
        <PaymentTypeSelector
          paymentType={WALLET_PAYMENT_TYPE.paymentType}
          paymentTime={WALLET_PAYMENT_TYPE.paymentTime}
          onClick={onClick}
          icon={WALLET_PAYMENT_TYPE.icon}
          selected
        />
      );
    default:
      return null;
  }
};
export default PaymentSelectionDropdown;
