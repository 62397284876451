import { useTelematicsProvider } from '@app/hooks/query/useConnectTelematics';
import { useNavigate } from 'react-router-dom';
import { MarketingBanner } from './Designs/MarketingBanner';

export default function TelematicsNudgeBanner() {
  const navigate = useNavigate();
  const { data, isLoading } = useTelematicsProvider();
  if (isLoading) {
    return null;
  }

  const providers = data?.data.providers;
  const connectedProvider = providers?.filter((provider) => provider.connected);
  if (connectedProvider && connectedProvider.length > 0) {
    return null;
  }

  return (
    <MarketingBanner
      actionText="Connect now"
      bannerKey="integrations-home-nudge"
      title="Pro Tip"
      onClick={() => {
        navigate('/integrations');
      }}
    >
      Connect your telematics provider to automatically sync drivers and vehicles
    </MarketingBanner>
  );
}
