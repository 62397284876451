import useCustomer from '@app/hooks/useCustomer';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import useProduct from '@app/hooks/useProduct';
import { ReactElement, useCallback, useMemo, useRef, useState } from 'react';
import OnboardingCard from './OnboardingCard';
import { STEP_MAP } from './OnboardingStepMap';
import { OnboardingWizardContext } from './OnboardingWizardContext';
import OnboardingWrapper from './OnboardingWrapper';

const PREPAID_STEPPER_LABELS = [
  { label: 'Activate account', key: 'pay_setup_fee' },
  { label: 'Sync your telematics', key: 'sync_telematics' },
  { label: 'Maximize discounts with AtoB app', key: 'maximize_discounts' },
];

const CREDIT_STEPPER_LABELS = [
  { label: 'Connect a bank account', key: 'connect_bank_account' },
  { label: 'Sync your telematics', key: 'sync_telematics' },
  { label: 'Maximize discounts with AtoB app', key: 'maximize_discounts' },
];

const CREDIT_WITH_DEPOSIT_STEPPER_LABELS = [
  { label: 'Connect a bank account', key: 'connect_bank_account' },
  { label: 'Activate your credit line', key: 'security_deposit' },
  { label: 'Sync your telematics', key: 'sync_telematics' },
  { label: 'Maximize discounts with AtoB app', key: 'maximize_discounts' },
];

export default function OnboardingWizard(): ReactElement | null {
  const { interstitials } = useCustomer();
  const [isPrepaidCustomer] = useProduct('prepaid');
  const [shouldPaySecurityDeposit] = useFeatureFlags('security_deposit_net_new');

  const staticSteps = useRef((interstitials?.steps ?? []).map((step) => STEP_MAP[step.name]));

  const [stepNumber, setStepNumber] = useState(0);

  const goToPrevStep = useCallback(() => {
    setStepNumber((s) => {
      for (let i = s - 1; i >= 0; i--) {
        if (interstitials?.steps[i].status === false) {
          return i;
        }
      }
      return 0;
    });
  }, [interstitials?.steps]);

  const goToNextStep = useCallback(() => {
    setStepNumber((s) => {
      for (let i = s + 1; i < staticSteps.current.length; i++) {
        if (interstitials?.steps[i].status === false) {
          return i;
        }
      }
      return staticSteps.current.length - 1;
    });
  }, [interstitials?.steps]);

  const stepperLabels = useMemo(() => {
    return isPrepaidCustomer
      ? PREPAID_STEPPER_LABELS
      : shouldPaySecurityDeposit
      ? CREDIT_WITH_DEPOSIT_STEPPER_LABELS
      : CREDIT_STEPPER_LABELS;
  }, [isPrepaidCustomer, shouldPaySecurityDeposit]);

  const contextValue = useMemo(
    () => ({
      goToNextStep,
      goToPrevStep,
      stepperLabels,
    }),
    [goToNextStep, goToPrevStep, stepperLabels],
  );

  if (interstitials == null) {
    return null;
  }

  const step = staticSteps.current[stepNumber];

  const LeftContent = step.leftContent;
  const RightContent = step.rightContent;

  return (
    <OnboardingWizardContext.Provider value={contextValue}>
      <OnboardingWrapper
        leftContent={LeftContent ? <LeftContent /> : <OnboardingCard />}
        rightContent={<RightContent />}
      />
    </OnboardingWizardContext.Provider>
  );
}
