import { Button } from '@mui/material';
import { ReactElement } from 'react';

import { useNavigate } from 'react-router-dom';

const UberFreightConversionSorryPage = (): ReactElement => {
  const navigate = useNavigate();
  const onClickGoToDashboard = () => {
    navigate('/');
  };

  return (
    <div className="font-mTFF space-y-8 px-6">
      <h1 className="text-[40px] font-bold leading-[52px]">
        Unfortunately, you are not eligible for the Uber Freight Carrier Card
      </h1>
      <p>
        Thank you for your interest. Our partners at AtoB cannot approve your business for the Uber
        Freight Carrier Card at this time.
      </p>
      <Button
        fullWidth
        className="bg-black text-lg font-medium hover:bg-[rgb(51,51,51)]"
        onClick={onClickGoToDashboard}
      >
        Close
      </Button>
    </div>
  );
};

export default UberFreightConversionSorryPage;
