import { Coordinates, FuelGrade, FuelListing } from '@app/@types/fuel_listings.types';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import useProduct from '@app/hooks/useProduct';
import useThemeMode from '@app/hooks/useThemeMode';
import { useChallengeMatch } from '@app/pages/AccountOverview/useChallengeMatch';
import {
  DISCOUNT_BOOST_BRAND_IDS,
  DRIVER_REWARDED_BRAND_IDS,
} from '@app/pages/FuelMap/fuelMap.const';
import logger from '@app/utils/datadog-logger';
import {
  choosePreferredFuelPrice,
  getIsValidPrice,
  getReadableDistanceFromFuelListing,
} from '@app/utils/fuel-listings';
import { faLocationDotSlash, faUpload } from '@fortawesome/pro-regular-svg-icons';
import { faArrowTrendUp, faRocketLaunch, faTrophy } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import classNames from 'classnames';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { useMemo, useState } from 'react';
import FuelListingPriceWithDiscount from './FuelListingPriceWithDiscount';
import FuelListingShareStationModal from './FuelListingShareStationModal';
import LoadingComponent from './LoadingComponent';
import { BrandLogo } from './Logos/BrandLogo';
import { useHasDiscount } from './useHasDiscount';
import { usePriceListingType } from './usePriceListingType';

dayjs.extend(relativeTime);

export interface FuelListingsListProps {
  isFetching: boolean;
  originLocation: Coordinates | undefined;
  fuelListings: FuelListing[];
  selectedGrade: FuelGrade | 'TRUCK DIESEL';
  onSelect: (fuelListing: FuelListing) => void;
  showMore: (() => void) | null;
}

const FuelListingOverview = ({
  originLocation,
  fuelListing,
  onSelect,
  selectedGrade,
  onShareStation,
}: {
  originLocation: Coordinates | undefined;
  fuelListing: FuelListing;
  onSelect: (fuelListing: FuelListing) => void;
  selectedGrade: FuelGrade | 'TRUCK DIESEL';
  onShareStation: (l: FuelListing) => void;
}) => {
  const distance = useMemo(() => {
    if (originLocation == null) {
      return null;
    }
    return getReadableDistanceFromFuelListing(originLocation, fuelListing);
  }, [originLocation, fuelListing]);

  const priceToShow = choosePreferredFuelPrice(
    fuelListing.attributes.fuel_prices.data ?? [],
    selectedGrade,
  );

  const date = priceToShow?.attributes.fuel_sign_time ?? '';

  const isGuaranteedDiscount = priceToShow?.attributes.discount_type === 'RETAIL_MINUS';
  const isPriceValid = getIsValidPrice(priceToShow);
  const [isHover, setIsHover] = useState(false);
  const { lightClassName } = useThemeMode();

  const [challengeMatch, costPlusDriverCashback] = useProduct(
    'challenge_match',
    'cost_plus_driver_cashback',
  );
  const [onTwoDollarFee, onThreeDollarFee, onSixDollarFee] = useFeatureFlags(
    'oon_fee_two_dollar',
    'oon_fee_three_dollar',
    'oon_fee_six_dollar',
  );

  const priceListingType = usePriceListingType(priceToShow ?? null);
  const hasDiscount = useHasDiscount(priceListingType);
  const { currentDiscountBoost } = useChallengeMatch();

  const renderBadge = () => {
    if (
      challengeMatch &&
      DISCOUNT_BOOST_BRAND_IDS.includes(fuelListing.attributes.brand_id as number)
    ) {
      return (
        <div className="bg-green-primary absolute -bottom-0.5 -right-0.5 flex h-5 w-5 items-center justify-center rounded-full p-0.5">
          <FontAwesomeIcon icon={faRocketLaunch} className="text-contrast-primary h-2.5 w-2.5" />
        </div>
      );
    }

    if (
      costPlusDriverCashback &&
      !challengeMatch &&
      DRIVER_REWARDED_BRAND_IDS.includes(fuelListing.attributes.brand_id as number)
    ) {
      return (
        <div className="bg-green-primary absolute -bottom-0.5 -right-0.5 flex h-5 w-5 items-center justify-center rounded-full p-0.5">
          <FontAwesomeIcon icon={faTrophy} className="text-contrast-primary h-2.5 w-2.5" />
        </div>
      );
    }

    if (hasDiscount) {
      return (
        <div className="bg-green-primary absolute -bottom-0.5 -right-0.5 flex h-5 w-5 items-center justify-center rounded-full p-0.5">
          <FontAwesomeIcon icon={faArrowTrendUp} className="text-contrast-primary h-2.5 w-2.5" />
        </div>
      );
    }

    return null;
  };

  const renderDiscountBoost = () => {
    if (
      hasDiscount &&
      challengeMatch &&
      DISCOUNT_BOOST_BRAND_IDS.includes(fuelListing.attributes.brand_id as number)
    ) {
      return (
        <div className="flex items-center gap-1 truncate">
          <span className="bg-strong-secondary h-1 w-1 rounded-full" />
          <span className="text-green truncate">
            {currentDiscountBoost > 0
              ? `+${currentDiscountBoost}¢/gal as statement credit`
              : `Eligible for extra discounts`}
          </span>
        </div>
      );
    }
  };

  const renderDriverReward = () => {
    if (
      costPlusDriverCashback &&
      DRIVER_REWARDED_BRAND_IDS.includes(fuelListing.attributes.brand_id as number)
    ) {
      return (
        <div className="flex items-center gap-1 truncate">
          <span className="bg-strong-secondary h-1 w-1 rounded-full" />
          <span className="text-green truncate">Eligible for $25 driver reward</span>
        </div>
      );
    }
  };

  const renderFee = () => {
    const isFeeApplicable =
      (onTwoDollarFee || onThreeDollarFee || onSixDollarFee) && selectedGrade !== 'ELECTRIC';

    if (!isFeeApplicable) {
      return null;
    }

    const feeAmount = onTwoDollarFee ? '$2.00' : onThreeDollarFee ? '$3.00' : '$6.00';
    const noFeeText = 'No fee';
    const feeText = hasDiscount ? noFeeText : `${feeAmount} fee`;

    return (
      <div className="flex items-center gap-1 truncate">
        <span className="bg-strong-secondary h-1 w-1 rounded-full" />
        <span className={classNames('truncate', hasDiscount ? 'text-green' : '')}>{feeText}</span>
      </div>
    );
  };

  return (
    <div
      className={classNames(
        'border-soft cursor-pointer rounded border',
        hasDiscount
          ? 'bg-green-secondary hover:bg-green-secondary-hover'
          : 'bg-soft-primary hover:bg-soft-primary-hover',
      )}
      onClick={() => onSelect(fuelListing)}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <div className="flex items-center gap-2 p-3">
        <div className="flex w-full items-center gap-2">
          <div
            className={classNames(
              'relative h-10 w-10 shrink-0 items-center justify-center',
              lightClassName,
            )}
          >
            <BrandLogo
              logoURL={fuelListing.attributes.logo_url}
              brand={fuelListing.attributes.location.name}
              size={40}
              rounded
              border
              electric={selectedGrade === 'ELECTRIC'}
            />
            {renderBadge()}
          </div>
          <div className="flex flex-col items-start justify-center gap-0.5 truncate">
            <p className="text-default-primary w-full truncate text-sm font-semibold">
              {fuelListing.attributes.location.name}
            </p>
            <p className="text-default-secondary flex w-full flex-wrap items-center gap-1 text-xs">
              <span className="truncate">{distance}</span>
              {renderFee()}
              {renderDiscountBoost()}
              {isPriceValid ? (
                <div className="flex items-center gap-1 truncate">
                  <span className="bg-strong-secondary h-1 w-1 rounded-full" />
                  <span className="truncate">Updated {dayjs(date).fromNow()}</span>
                </div>
              ) : isGuaranteedDiscount ||
                priceToShow?.attributes.discount_per_gallon_cents === 0 ? (
                <div className="flex items-center gap-1 truncate">
                  <span className="bg-strong-secondary h-1 w-1 rounded-full" />
                  <span className="truncate">Price Unavailable</span>
                </div>
              ) : null}
              {renderDriverReward()}
            </p>
          </div>
          <div className="flex shrink-0 grow justify-end gap-2">
            {isHover && selectedGrade !== 'ELECTRIC' ? (
              <Button
                color="secondary"
                size="small"
                startIcon={<FontAwesomeIcon icon={faUpload} />}
                onClick={(e) => {
                  e.stopPropagation();
                  logger.info('fuel-listing-share', {
                    from: 'list',
                    id: fuelListing.attributes.site_id,
                  });
                  onShareStation(fuelListing);
                }}
              >
                Share
              </Button>
            ) : null}
            <div className="flex shrink-0 flex-col items-end justify-center gap-0.5">
              {priceToShow && <FuelListingPriceWithDiscount price={priceToShow} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const FuelListingsList = ({
  isFetching,
  originLocation,
  fuelListings,
  selectedGrade,
  onSelect,
  showMore,
}: FuelListingsListProps) => {
  const [shareStation, setShareStation] = useState<FuelListing | null>(null);
  if (isFetching) {
    return <LoadingComponent />;
  }

  return (
    <>
      {shareStation != null && (
        <FuelListingShareStationModal
          open={true}
          fuelGrade={selectedGrade}
          onClose={() => setShareStation(null)}
          fuelListing={shareStation}
          originLocation={originLocation}
        />
      )}
      <div className="flex h-auto flex-col gap-2 px-6 pb-2 sm:overflow-auto">
        {fuelListings.length ? (
          fuelListings.map((fuelListing: FuelListing) => (
            <FuelListingOverview
              key={fuelListing.id}
              originLocation={originLocation}
              fuelListing={fuelListing}
              onSelect={(listing) => {
                logger.info('fuel-listing-select', {
                  from: 'list',
                  id: listing.attributes.site_id,
                });
                onSelect(listing);
              }}
              selectedGrade={selectedGrade}
              onShareStation={setShareStation}
            />
          ))
        ) : (
          <div className="text-default-secondary flex w-full flex-col items-center gap-2 p-8 font-medium">
            <FontAwesomeIcon className="mb-4" icon={faLocationDotSlash} size="3x" />
            <div className="text-base">Nothing in this area</div>
            <div className="text-xs">Try searching another area or applying different filters</div>
          </div>
        )}
        {showMore && fuelListings.length > 0 && !isFetching && (
          <Button color="secondary" size="small" disabled={isFetching} onClick={showMore}>
            Show More
          </Button>
        )}
      </div>
    </>
  );
};

export default FuelListingsList;
