import { CreditLimitIncreaseRequestModal } from '@app/components/Modals/CreditLimitIncreaseRequest/CreditLimitIncreaseRequestModal';
import { Link } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const CreditLimitIncreasePrimaryAction: React.FC = () => {
  const [cliModalOpen, setCliModalOpen] = useState(false);

  return (
    <>
      <div
        onClick={() => setCliModalOpen(true)}
        className="text-default-secondary mt-1 cursor-pointer self-end text-sm underline"
      >
        Request limit increase
      </div>
      <CreditLimitIncreaseRequestModal open={cliModalOpen} onClose={() => setCliModalOpen(false)} />
    </>
  );
};
export const FindFuelPrimaryAction: React.FC = () => {
  const onNavigate = useNavigate();
  return (
    <>
      <Link
        onClick={() => onNavigate('/fuel-map')}
        className="text-default-secondary cursor-pointer text-sm"
      >
        Find fuel
      </Link>
    </>
  );
};
