import { CustomerData } from '@app/@types/customer.types';
import BankTransferWrapper from '@app/components/PaymentModal/BankTransferContent/BankTransferWrapper';
import DebitCardWrapper from '@app/components/PaymentModal/DebitCardContent/DebitCardWrapper';
import { PaymentSelectionType } from '@app/components/PaymentModal/SelectPaymentType/PaymentSelectionDropdown';
import WalletController from '@app/components/PaymentModal/WalletContent/WalletController';
import { ReactElement, useState } from 'react';
import amountDueConverter, { AmountDueProps } from './amountDueConverter';

interface PaymentModalWithEstimatesControllerProps {
  paymentSelectionType: PaymentSelectionType;
  closeModal: () => void;
  setShowPaymentSelection: (showPaymentSelection: boolean) => void;
  customer: CustomerData;
  upcomingPayment: AmountDueProps['upcomingPayment'];
}

const PaymentModalWithEstimatesController = ({
  paymentSelectionType,
  closeModal,
  setShowPaymentSelection,
  customer,
  upcomingPayment,
}: PaymentModalWithEstimatesControllerProps): ReactElement => {
  const { amountDueFormatted } = amountDueConverter({
    upcomingPayment,
    customer,
    // We use the arrears_due value when the customer is overdue.
    // This is generated by the backend.
    useArrearsDue: customer?.is_overdue,
  });
  const [paymentAmount, setPaymentAmount] = useState(amountDueFormatted ?? '$0.00');
  const [showAmountDue, setShowAmountDue] = useState(amountDueFormatted === paymentAmount);

  switch (paymentSelectionType) {
    case 'bank_account':
      return (
        <BankTransferWrapper
          closeModal={closeModal}
          setShowPaymentSelection={setShowPaymentSelection}
          paymentAmount={paymentAmount}
          setPaymentAmount={setPaymentAmount}
          showAmountDue={showAmountDue}
          setShowAmountDue={setShowAmountDue}
        />
      );
    case 'debit_card':
      return (
        <DebitCardWrapper
          closeModal={closeModal}
          setShowPaymentSelection={setShowPaymentSelection}
          paymentAmount={paymentAmount}
          setPaymentAmount={setPaymentAmount}
          showAmountDue={showAmountDue}
          setShowAmountDue={setShowAmountDue}
        />
      );
    case 'customer_treasury_detail':
      return (
        <WalletController
          closeModal={closeModal}
          setShowPaymentSelection={setShowPaymentSelection}
          paymentAmount={paymentAmount}
          setPaymentAmount={setPaymentAmount}
          showAmountDue={showAmountDue}
          setShowAmountDue={setShowAmountDue}
        />
      );
    default:
      // We should never hit this section, but just in case,
      // we should always allow someone to pay with a debit card.
      return (
        <DebitCardWrapper
          closeModal={closeModal}
          setShowPaymentSelection={setShowPaymentSelection}
          paymentAmount={paymentAmount}
          setPaymentAmount={setPaymentAmount}
          showAmountDue={showAmountDue}
          setShowAmountDue={setShowAmountDue}
        />
      );
  }
};

export default PaymentModalWithEstimatesController;
