import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorBanner } from './Designs/ErrorBanner';

const DeactivatedBanner = (): ReactElement => {
  const navigate = useNavigate();

  return (
    <ErrorBanner
      onClick={() => navigate('/billing/payment-history')}
      actionText={'View Payment Status'}
    >
      <div className="font-semibold">Account Deactivated</div>
      <div>
        Your account is deactivated because of a recently missed payment. It will be reviewed for
        reinstatement once all pending payments are settled.
      </div>
    </ErrorBanner>
  );
};

export default DeactivatedBanner;
