/* eslint-disable no-unused-vars */

import { useExtendMobileHeaderContextMenu } from '@app/contexts/MobileNavHeaderMenuContextComponent';
import PlaidContext from '@app/contexts/plaidContext';
import { getEnvironment } from '@app/utils/environment';
import { Button } from '@mui/material';
import { MouseEventHandler, useContext, useMemo } from 'react';
import { PlaidLinkOnEvent, PlaidLinkOnSuccess, usePlaidLink } from 'react-plaid-link';

export interface PlaidLinkConnectProps {
  setError: (e: Error) => void;
  buttonProps?: {
    disabled?: boolean;
    text?: string;
    className?: string;
    primary?: boolean;
    dark?: boolean;
    fullWidth?: boolean;
    size?: 'small' | 'medium';
  };
  text: string;
  onSuccess: PlaidLinkOnSuccess;
  onEvent: PlaidLinkOnEvent;
}

const PlaidLinkConnect = ({ buttonProps, text, onSuccess, onEvent }: PlaidLinkConnectProps) => {
  const { VITE_PLAID_ENV } = getEnvironment();
  const { linkToken } = useContext(PlaidContext);

  const { open, ready } = usePlaidLink({
    env: String(VITE_PLAID_ENV),
    onSuccess,
    onEvent,
    token: linkToken,
  });

  const menuItem = useMemo(
    () => ({
      value: text,
      onClick: open as () => void,
      disabled: () => !ready,
      order: 1,
    }),
    [open, ready, text],
  );

  useExtendMobileHeaderContextMenu(menuItem);

  return (
    <Button
      onClick={open as MouseEventHandler<HTMLButtonElement>}
      data-testid="plaid-link-connect"
      disabled={!ready}
      className={buttonProps?.className}
      color={
        !buttonProps?.disabled && !buttonProps?.primary && !buttonProps?.dark
          ? 'secondary'
          : 'primary'
      }
      size={buttonProps?.size}
      fullWidth={buttonProps?.fullWidth}
    >
      {text}
    </Button>
  );
};

export default PlaidLinkConnect;
