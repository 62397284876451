import useThemeMode from '@app/hooks/useThemeMode';
import { Button } from '@mui/material';
import { useTour } from '@reactour/tour';
import classNames from 'classnames';

function Arrow() {
  const { currentStep, steps, position: tourPosition } = useTour();
  const { darkClassName } = useThemeMode();

  const position = steps[currentStep].position ?? tourPosition;

  const getArrowPosition = () => {
    if (position === 'bottom') {
      return 'left-12 top-0 rotate-45';
    }

    if (position === 'top') {
      return 'left-12 top-full rotate-45';
    }
  };

  return (
    <div
      className={classNames(
        getArrowPosition(),
        darkClassName,
        'bg-soft-primary absolute h-3 w-3 -translate-x-1/2 -translate-y-1/2',
      )}
    />
  );
}

function Step() {
  const { currentStep, steps } = useTour();
  const numberOfSteps = steps.length;

  return (
    <span className="text-default-primary text-right text-sm">
      {currentStep + 1} of {numberOfSteps}
    </span>
  );
}

type TourStepProps = {
  title: string;
  description: string;
  onClick: () => void;
};

export default function TourStep({ title, description, onClick }: TourStepProps) {
  const { steps, currentStep } = useTour();
  const isLastStep = currentStep === steps.length - 1;

  return (
    <div className="flex flex-col gap-4">
      <Arrow />
      <div className="flex flex-col items-start gap-1 self-stretch">
        <div className="flex items-start gap-2.5 self-stretch">
          <span className="text-default-primary grow text-sm font-semibold">{title}</span>
          <Step />
        </div>
        <div className="flex flex-col items-start gap-1 self-stretch">
          <p className="text-default-secondary text-sm">{description}</p>
        </div>
      </div>
      <Button size="small" color="primary" fullWidth onClick={onClick}>
        {isLastStep ? 'Done' : 'Next'}
      </Button>
    </div>
  );
}
