import { ErrorNotification } from '@app/components/layout';
import Success from '@app/components/layout/SuccessPage';
import useCustomer from '@app/hooks/useCustomer';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import useProduct from '@app/hooks/useProduct';
import useUser from '@app/hooks/useUser';
import { guardAxiosError } from '@app/utils/error/guards';
import Modal, {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { formatPhoneNumber } from '@atob-developers/shared/src/utils/phoneUtils';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import axios from 'axios';
import { ReactElement, useState, useEffect } from 'react';
import OtpInput from './OtpInput';
import OtpOptions from './OtpOptions';

const OneTimePassword = ({
  isOpen,
  setIsOpen,
  onSuccess,
  onCancel,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  onSuccess?: () => void;
  onCancel?: () => void;
}): ReactElement | null => {
  const toggle = () => {
    onCancel?.();
    setIsOpen(!isOpen);
    setInputState(new Array(6).fill(''));
    setShowOtpOptions(false);
    setHasShownOptions(false);
    setShowResend(true);
    setLoading(false);
    setError(null);
    setSuccess(false);
  };

  const { user, isLoading } = useUser();
  const [walletLite] = useProduct('wallet_lite');
  const userEmail = user?.email;
  const userPhone = user?.phone;
  const { owner_email, owner_phone } = useCustomer();
  const [mfa_to_user] = useFeatureFlags('mfa_to_user');
  const [inputState, setInputState] = useState(new Array(6).fill(''));

  const email = mfa_to_user ? userEmail : owner_email;
  const phone = mfa_to_user ? userPhone : owner_phone;

  // Initialize with null to indicate "not yet determined"
  const [selectedOption, setSelectedOption] = useState<'Email' | 'SMS' | null>(null);
  const [showOtpOptions, setShowOtpOptions] = useState(false);
  const [hasShownOptions, setHasShownOptions] = useState(false);
  const [showResend, setShowResend] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (selectedOption === null && !isLoading) {
      setSelectedOption(phone != null && phone != '' ? 'SMS' : 'Email');
    }
  }, [phone, selectedOption, isLoading]);

  useEffect(() => {
    if (!isOpen || hasShownOptions || selectedOption === null) {
      return;
    }
    axios.post('security/mfa/challenge?method=' + selectedOption.toLowerCase());
  }, [isOpen, selectedOption, hasShownOptions]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowResend(true);
    }, 1000 * 60);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const reset = () => {
    setIsOpen(false);
    setInputState(new Array(6).fill(''));
    setShowOtpOptions(false);
    setSuccess(false);
  };

  const onVerify = async () => {
    if (selectedOption == null) return;

    const otp = inputState.join('');
    setLoading(true);
    try {
      await axios.post('/security/mfa/verify', { code: otp, method: selectedOption.toLowerCase() });
      setSuccess(true);
      onSuccess && onSuccess();
      setTimeout(() => {
        reset();
      }, 3000);
    } catch (e: unknown) {
      if (!guardAxiosError(e)) {
        return;
      }
      if (e?.response?.status === 400) {
        setError('Your code was either invalid or expired. Please resend and try again.');
      } else {
        setError('Something went wrong verifying your code. Please resend and try again.');
      }

      setShowResend(true);
    }

    setLoading(false);
  };

  const resendCode = async (option?: string) => {
    if (!option && selectedOption === null) return;

    setError(null);
    try {
      setLoading(true);
      const method = option ? option.toLowerCase() : selectedOption!.toLowerCase();
      await axios.post('security/mfa/challenge?method=' + method);
    } catch (e: unknown) {
      setError('Something went wrong. Please try again.');
    }

    setLoading(false);
  };

  if (selectedOption === null) {
    return null;
  }

  if (success) {
    return (
      <Modal
        open={isOpen}
        toggle={toggle}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        disableRestoreFocus={true}
      >
        <ModalHeader title="Account Verified" onClose={toggle} />
        <div className="text-center">
          <Success
            title="Your code was verified successfully"
            text="This window will close automatically."
          />
        </div>
      </Modal>
    );
  }

  if (showOtpOptions) {
    return (
      <Modal
        open={isOpen}
        toggle={toggle}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        disableRestoreFocus={true}
      >
        <ModalHeader title="2FA Options" onClose={toggle} />
        <ModalBodyContent>
          <OtpOptions
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            email={email}
            phone={phone}
            onSave={async (newOption: string | undefined) => {
              await resendCode(newOption);
              setShowOtpOptions(false);
            }}
            onCancel={() => {
              setShowOtpOptions(false);
            }}
          />
        </ModalBodyContent>
      </Modal>
    );
  }

  const ButtonGroup = () => {
    if (error) {
      return (
        <Button size="small" onClick={() => resendCode()}>
          Resend
        </Button>
      );
    }

    return (
      <div className="flex gap-2">
        {showResend && (
          <Button color="secondary" size="small" onClick={() => resendCode()}>
            Resend
          </Button>
        )}
        <LoadingButton size="small" loading={loading} onClick={onVerify}>
          Verify
        </LoadingButton>
      </div>
    );
  };

  const showTryAnotherWay =
    walletLite && phone != ''
      ? false
      : email != null && email != '' && phone != null && phone != '';

  return (
    <Modal
      data-testid="OTP_modal"
      open={isOpen}
      toggle={toggle}
      disableAutoFocus={true}
      disableEnforceFocus={true}
      disableRestoreFocus={true}
    >
      <ModalHeader data-testid="OTP_modal_header" title="Before you continue" onClose={toggle} />
      <ModalBodyContent>
        {error && <ErrorNotification error={error} />}
        <div className="mb-6">
          <div data-testid="OTP_message" className="text-default-secondary text-[16px]">
            Before you continue, please enter the 6-digit verification code sent via{' '}
            <b>
              {selectedOption === 'Email' ? 'email' : 'SMS'} to{' '}
              {selectedOption === 'Email' ? email : formatPhoneNumber(phone!)}
            </b>
            .
          </div>
        </div>
        <OtpInput inputState={inputState} setInputState={setInputState} />
      </ModalBodyContent>
      <ModalFooter>
        <div className="flex w-full justify-between">
          <div className="flex">
            {showTryAnotherWay && (
              <button
                className="text-default-primary text-sm font-semibold"
                onClick={() => {
                  setShowOtpOptions(true);
                  setHasShownOptions(true);
                }}
              >
                Try another way
              </button>
            )}
          </div>
          <div className="flex">
            <ButtonGroup />
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default OneTimePassword;
