import Modal, { ModalBodyContent, ModalHeader } from '@atob-developers/shared/src/components/Modal';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { ReactElement } from 'react';
import AccountInfo from '../AccountInfo';
import { Account, getFullAccountName, methodNameMapping } from '../utils';

export default function DeleteAccountModal({
  onAccountRemoval,
  onClose,
  open,
  account,
  recipientName,
  loading,
}: {
  onAccountRemoval?: () => void;
  onClose: () => void;
  open: boolean;
  account: Account;
  recipientName: string;
  loading: boolean;
}): ReactElement {
  return (
    <Modal maxWidth="sm" open={open} toggle={onClose}>
      <ModalHeader
        headerImage={
          <FontAwesomeIcon
            icon={faCircleInfo}
            className="bg-strong-secondary text-default-primary rounded-full p-4 text-xl"
          />
        }
        title={`Are you sure you want to remove this ${getFullAccountName(
          methodNameMapping[account.type],
          account.type,
        )}?`}
        secondaryTitle={`This action cannot be undone, and you will have to re-add it to use it in future transfers to ${recipientName}.`}
        onClose={onClose}
      />
      <ModalBodyContent>
        <div className="mb-3 flex flex-col gap-6">
          <AccountInfo account={account} className="py-3" />
          <div className="flex flex-col-reverse gap-3 md:flex-row">
            <Button color="secondary" fullWidth onClick={onClose}>
              Cancel
            </Button>
            <LoadingButton
              variant="destructive"
              fullWidth
              onClick={onAccountRemoval}
              loading={loading}
            >
              Remove this account
            </LoadingButton>
          </div>
        </div>
      </ModalBodyContent>
    </Modal>
  );
}
