import { EndpointResponse, Entity } from '@app/@types/api.types';
import { DriverData } from '@app/@types/driver.types';
import PhoneInput from '@app/components/Inputs/PhoneInput';
import { ErrorNotification } from '@app/components/layout';
import Success from '@app/components/layout/SuccessPage';
import Constants from '@app/utils/constants';
import Modal, {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { LoadingButton } from '@mui/lab';
import { FormControl, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { deserialize } from 'deserialize-json-api';
import { Formik, useFormikContext } from 'formik';
import { ReactElement, useContext, useState } from 'react';
import * as Yup from 'yup';
import { guardAxiosError } from '../../../utils/error/guards';
import PayrollContext from '../payroll-context';

type Driver = {
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  taxClassification?: string;
};

const validationSchema = Yup.object({
  email: Yup.string().nullable().email('Email address is not valid'),
});

export default function AddDriverModal({
  isActive,
  setIsActive,
}: {
  isActive: boolean;
  setIsActive: (isActive: boolean) => void;
}): ReactElement {
  const { addDriverToState } = useContext(PayrollContext);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState('');
  const [isEmployeeTenNinetyNine, setIsEmployeeTenNinetyNine] = useState(false);
  const { addToast } = useToasts();
  const closeModal = () => {
    setError('');
    setIsSuccess(false);
    setIsActive(false);
  };

  const onSubmit = async (values: Driver) => {
    let driverId = null;
    const { email, firstName, lastName, taxClassification } = values;
    let phone = values.phone;
    if (phone != null && phone.startsWith('+1 ')) {
      phone = phone.replace('+1 ', '');
    }

    const doesNotHaveContactFields = !email && !phone;
    if (doesNotHaveContactFields) {
      setError('Email or phone number is required');
      return;
    }
    const doesNotHaveAllValues = !firstName || !lastName || !taxClassification;
    if (doesNotHaveAllValues) {
      setError('All fields are required');
      return;
    }

    const employeeIsTenNinetyNine = taxClassification === Constants.PayrollEnums.TEN_NINETY_NINE;

    setIsEmployeeTenNinetyNine(employeeIsTenNinetyNine);

    let driverToAddToState = null;

    setIsLoading(true);

    try {
      const response = await axios.post<EndpointResponse<Entity<DriverData>>>(`/drivers`, {
        driver: {
          email,
          phone,
          first_name: firstName,
          last_name: lastName,
          payroll: {
            tax_classification: taxClassification,
          },
        },
      });
      const { data } = response;
      driverId = data.data.attributes.id.toString();
      driverToAddToState = {
        ...data.data.attributes,
        id: driverId,
      };

      if (!employeeIsTenNinetyNine) {
        setIsSuccess(true);
        setIsLoading(false);
      }
    } catch (e: unknown) {
      if (!guardAxiosError(e)) {
        return;
      }

      setIsLoading(false);
      const emailError =
        e?.response?.data?.errors?.email && `Email ${e?.response?.data?.errors?.email}.`;
      const phoneError =
        e?.response?.data?.errors?.phone && `Phone ${e?.response?.data?.errors?.phone}.`;
      const errorMessage =
        emailError || phoneError || 'There was a problem saving this driver. Please try again.';
      setError(errorMessage);
    }

    try {
      const response = await axios.post(`/drivers/${driverId}/payroll_invite`);
      driverToAddToState = deserialize(response.data).data;
      addDriverToState(driverToAddToState);
      setIsSuccess(true);
      setIsLoading(false);
    } catch (e: unknown) {
      Sentry.captureException(e);
      addDriverToState(driverToAddToState);
      setIsLoading(false);
      addToast({
        type: 'error',
        title: 'There was a problem sending the invitation. Please try again.',
      });
    }
  };

  if (isSuccess) {
    const successText = isEmployeeTenNinetyNine
      ? 'Please ask your driver to look for an email from hello@atob.us and activate by adding their debit card details.'
      : 'We have created this driver in your payroll list, but only support 1099 drivers for instant payroll at this time.';
    return (
      <Modal open={isActive} toggle={closeModal}>
        <ModalHeader title="Add Driver to Payroll" onClose={closeModal} />
        <Success title="Driver added successfully." text={successText} />
      </Modal>
    );
  }

  return (
    <div>
      <Modal open={isActive} toggle={closeModal}>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ handleSubmit }) => (
            <>
              <ModalHeader title="Add Driver to Payroll" onClose={closeModal} />
              <ModalBodyContent>
                {error && <ErrorNotification error={error} />}
                <NewDriverForm />
              </ModalBodyContent>
              <ModalFooter>
                <LoadingButton loading={isLoading} size="small" onClick={() => handleSubmit()}>
                  Add Driver
                </LoadingButton>
              </ModalFooter>
            </>
          )}
        </Formik>
      </Modal>
    </div>
  );
}

const NewDriverForm = (): ReactElement => {
  const { values, handleChange, handleBlur, touched, errors, handleSubmit, setFieldValue } =
    useFormikContext<Driver>();

  return (
    <form className="space-y-2" onSubmit={handleSubmit}>
      <TextField
        fullWidth
        size="small"
        id="firstName"
        label="First name"
        name="firstName"
        value={values.firstName}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.firstName && Boolean(errors.firstName)}
        helperText={touched.firstName && errors.firstName}
      />
      <TextField
        fullWidth
        size="small"
        id="lastName"
        label="Last name"
        name="lastName"
        value={values.lastName}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.lastName && Boolean(errors.lastName)}
        helperText={touched.lastName && errors.lastName}
      />
      <TextField
        fullWidth
        size="small"
        id="email"
        label="Email"
        name="email"
        type="email"
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        error={touched.email && Boolean(errors.email)}
        helperText={touched.email && errors.email}
      />
      <div>
        <PhoneInput
          fullWidth
          id="phone"
          name="phone"
          size="small"
          label="Phone number"
          value={values.phone}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.phone && Boolean(errors.phone)}
          helperText={touched.phone && errors.phone}
        />
      </div>
      <h3 className="text-default-primary block text-base font-bold">Employee Type</h3>
      <FormControl>
        <RadioGroup className="flex flex-row gap-3">
          <FormControlLabel
            id="employeeType1099"
            value="1099"
            label="1099"
            onChange={() => {
              setFieldValue('taxClassification', Constants.PayrollEnums.TEN_NINETY_NINE);
            }}
            checked={values.taxClassification === Constants.PayrollEnums.TEN_NINETY_NINE}
            control={<Radio />}
          />
          <FormControlLabel
            id="employeeTypeW2"
            value="W2"
            label="W2"
            onChange={() => {
              setFieldValue('taxClassification', Constants.PayrollEnums.W2);
            }}
            checked={values.taxClassification === Constants.PayrollEnums.W2}
            control={<Radio />}
          />
        </RadioGroup>
      </FormControl>
    </form>
  );
};
