import CopyCheckedIcon from '@app/assets/images/svg/copy-checked.svg';
import CopyIcon from '@app/assets/images/svg/copy.svg';
import useCustomer from '@app/hooks/useCustomer';
import onCopy from '@app/utils/on-copy';
import Divider from '@atob-developers/shared/src/components/Divider';
import Modal, { ModalBodyContent, ModalHeader } from '@atob-developers/shared/src/components/Modal';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

export default function WireInstructionsModal({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const customer = useCustomer();
  const financialAccount = customer.treasury.financial_account;
  const companyName = customer.company_name;
  const companyAddress = customer.company_address;

  return (
    <>
      <Modal maxWidth="xs" open={open} toggle={onClose}>
        <div className="flex justify-start">
          <ModalHeader
            title="Wire transfer instruction"
            secondaryTitle="Please provide below information to the bank that is sending the Wire transfer to complete adding funds to your AtoB Wallet account."
            onClose={onClose}
            headerImage={
              <FontAwesomeIcon
                icon={faCircleInfo}
                className="bg-strong-secondary text-default-primary rounded-full p-3 text-xl"
              />
            }
          />
        </div>
        <ModalBodyContent>
          <div className="bg-soft-secondary border-soft mb-4 flex flex-col gap-3 rounded-lg border p-4 text-sm">
            <InstructionItem
              title="Account number"
              value={financialAccount?.account_number || ''}
            />
            <InstructionItem
              title="Routing number"
              value={financialAccount?.routing_number || ''}
            />
            <Divider />
            <InstructionItem title="Beneficiary name" value={companyName} />
            <InstructionItem
              title="Beneficiary address"
              value={`${companyAddress.address1 || ''}${
                companyAddress.address2 ? ` ${companyAddress.address2}` : ''
              } ${companyAddress.city || ''}, ${companyAddress.state || ''} ${
                companyAddress.zip || ''
              }`}
            />
            <Divider />
            <InstructionItem title="Bank name" value={financialAccount?.bank_name || ''} />
            <InstructionItem
              title="Bank address"
              value={`${financialAccount?.bank_address.address1}${
                financialAccount?.bank_address.address2
                  ? `, ${financialAccount.bank_address.address2}`
                  : ''
              } ${financialAccount?.bank_address.city}, ${financialAccount?.bank_address
                .state} ${financialAccount?.bank_address.zip}`}
            />
            <InstructionItem
              title="Bank phone number"
              value={financialAccount?.bank_address.phone_number || ''}
            />
          </div>
        </ModalBodyContent>
      </Modal>
    </>
  );
}

const InstructionItem = ({ title, value }: { title: string; value: string }) => {
  const { addToast } = useToasts();
  const [copied, setCopied] = useState(false);

  const handleCopyClick = () => {
    onCopy(value, () => {
      setCopied(true);
      addToast({ type: 'success', title: 'Copied to clipboard!' });
      setTimeout(() => {
        setCopied(false);
      }, 5000);
    });
  };

  return (
    <div>
      <div className="text-default-secondary">{title}</div>
      <div className="flex flex-row justify-between">
        <div className="text-default-primary text-base font-semibold">{value}</div>
        <span onClick={handleCopyClick} className="min-w-[20px] cursor-pointer pt-0.5">
          {copied ? (
            <img src={CopyCheckedIcon} alt="copy-checked" />
          ) : (
            <img src={CopyIcon} alt="copy" />
          )}
        </span>
      </div>
    </div>
  );
};
