import { BankAccountPaymentMethodCombined } from '@app/@types/bankAccount.types';
import { DebitCardPaymentMethodCombined } from '@app/@types/debitCard.types';
import DebitCardModal from '@app/components/PaymentMethods/DebitCardModal';
import { AutoTopUpUpsell } from '@app/components/Prepaid/AutoTopUps/AutoTopUpUpsell';
import { SuggestedAmounts } from '@app/components/Prepaid/SuggestedAmounts';
import { ErrorNotification } from '@app/components/layout';
import Success from '@app/components/layout/SuccessPage';
import CustomerContext from '@app/contexts/customerContext';
import {
  CustomerOnboardingEventName,
  useCreateCustomerOnboardingEvent,
} from '@app/hooks/query/useCustomerOnboardingEvents';
import { convertAmountToCents } from '@app/hooks/use-amount-cents';
import useChannelPartner from '@app/hooks/useChannelPartner';
import useCustomer from '@app/hooks/useCustomer';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import useProduct from '@app/hooks/useProduct';
import logger from '@app/utils/datadog-logger';
import Modal, { ModalBodyContent, ModalHeader } from '@atob-developers/shared/src/components/Modal';
import {
  convertCentsToDollars,
  getCurrencyFromCents,
} from '@atob-developers/shared/src/utils/formatters/currencyFormat';
import {
  faCreditCard,
  faMoneyBillTransfer,
  faUniversity,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { Button, TextField } from '@mui/material';
import { capitalize } from 'lodash-es';
import { ReactElement, useContext, useEffect, useState } from 'react';
import { AddFundsScenario } from './AddFunds';
import { ConfirmFunds } from './ConfirmFunds';
import Confirmation from './Confirmation';
import { AmountInput } from './Inputs';
import { transferButtonClasses } from './TransferSelector';
import WalletAccountFunds from './WalletAccountFunds';
import WalletPaymentMethods from './WalletPaymentMethods';
import useAddFunds from './useAddFunds';
import { createReadableError } from './utils';
import type { AddressType, TreasuryFinancialAccount } from '@app/@types/customer.types';

export const addFundsScenarioToEventName: Record<AddFundsScenario, CustomerOnboardingEventName> = {
  add_funds: CustomerOnboardingEventName.CUSTOMER_ADD_FUNDS,
  account_setup_fee: CustomerOnboardingEventName.CUSTOMER_PAID_SETUP_FEE,
  security_deposit: CustomerOnboardingEventName.CUSTOMER_SECURITY_DEPOSIT_PAID,
};

export default function AddFundsModal({
  open,
  reset,
  onAddFunds,
  customerCompanyName,
  customerCompanyAddress,
  minimumDepositAmountCents,
  initialAmount,
  initialDescription,
  scenario,
  continueOnboarding,
}: {
  open: boolean;
  reset: () => void;
  onAddFunds: () => void;
  customerCompanyName: string;
  customerCompanyAddress: AddressType;
  minimumDepositAmountCents?: number;
  initialAmount?: number;
  initialDescription?: string;
  scenario: AddFundsScenario;
  continueOnboarding?: () => void;
}): ReactElement {
  const [callAddFundsOnClose, setCallAddFundsOnClose] = useState(false);
  const customer = useCustomer();

  const onSuccessfulPayment = () => {
    setCallAddFundsOnClose(true);
  };
  const onToggle = () => {
    if (callAddFundsOnClose) {
      onAddFunds();
    }
    reset();
  };

  const modalTitles: Record<AddFundsScenario, string> = {
    add_funds: 'Add money',
    account_setup_fee: 'Pay Your Fee',
    security_deposit: 'Security Deposit Payment',
  };

  return (
    <Modal open={open} toggle={onToggle}>
      <ModalHeader title={modalTitles[scenario]} onClose={onToggle} />
      <ModalBodyContent>
        <WalletPaymentMethods>
          {({ inboundBankAccounts, loading, fetchPaymentMethods, debitCards }) => (
            <AddFundsForm
              debitCards={debitCards}
              bankAccounts={inboundBankAccounts}
              financialAccount={customer.treasury.financial_account}
              reset={onToggle}
              continueOnboarding={continueOnboarding}
              onSuccessfulPayment={onSuccessfulPayment}
              loadingAccounts={loading}
              fetchPaymentMethods={fetchPaymentMethods}
              customerCompanyName={customerCompanyName}
              customerCompanyAddress={customerCompanyAddress}
              minimumDepositAmountCents={minimumDepositAmountCents}
              initialAmount={initialAmount}
              initialDescription={initialDescription}
              scenario={scenario}
            />
          )}
        </WalletPaymentMethods>
      </ModalBodyContent>
    </Modal>
  );
}

type FormErrors = {
  account?: string;
  description?: string;
};

const validate = (selectedAccountId: string | null, setErrors: (errors: FormErrors) => void) => {
  const errors: FormErrors = {
    account: selectedAccountId == null ? 'Please select an account' : undefined,
  };

  setErrors(errors);

  if (Object.entries(errors).filter(([_key, value]) => value != null).length > 0) {
    return !!errors;
  }
};

const AddFundsTransferOptions = ({
  transferType,
  setTransferType,
  financialAccount,
  sameDayAchEnabled,
}: {
  transferType: 'ach' | 'wire' | 'debit';
  setTransferType: (transferType: 'ach' | 'wire' | 'debit') => void;
  financialAccount?: TreasuryFinancialAccount | null;
  sameDayAchEnabled: boolean;
}): ReactElement => {
  const [trustedTreasury] = useProduct('treasury_trusted');
  return (
    <div className="flex w-full flex-col">
      <button
        type="button"
        className={transferButtonClasses(transferType === 'debit')}
        key="debit"
        onClick={() => setTransferType('debit')}
      >
        <FontAwesomeIcon icon={faCreditCard} />
        <div className="flex flex-col items-start pl-6">
          <div className="font-bold">Debit Card</div>
          <div className="text-sm font-light">Instant</div>
        </div>
      </button>
      <button
        type="button"
        className={transferButtonClasses(transferType === 'ach')}
        key="ach"
        onClick={() => setTransferType('ach')}
      >
        <FontAwesomeIcon icon={faUniversity} />
        <div className="flex flex-col items-start pl-6">
          <div className="font-bold">Bank Account</div>
          <div className="text-sm font-light">
            ACH Transfer, {sameDayAchEnabled ? 'by next day' : 'in 3-5 days'}
          </div>
        </div>
      </button>
      {trustedTreasury && financialAccount && (
        <button
          type="button"
          className={transferButtonClasses(transferType === 'wire')}
          key="wire"
          onClick={() => setTransferType('wire')}
        >
          <FontAwesomeIcon icon={faMoneyBillTransfer} />
          <div className="flex flex-col items-start pl-6">
            <div className="font-bold">Wire Transfer</div>
            <div className="text-sm font-light">
              {transferType === 'wire' ? 'Instructions below' : 'Click for instructions'}
            </div>
          </div>
        </button>
      )}
    </div>
  );
};

const WireInstructions = ({
  financialAccount,
  customerCompanyName,
  customerCompanyAddress,
}: {
  financialAccount: TreasuryFinancialAccount;
  customerCompanyName: string;
  customerCompanyAddress: AddressType;
}): ReactElement => {
  return (
    <div className="mt-4 flex w-full flex-col justify-between text-[14px]">
      <div className="text-default-primary font-bold">
        Sending a wire transfer to your AtoB Wallet
      </div>
      <div className="text-default-primary mb-5 flex">
        Please use these instructions for any wires within the United States. You will need to
        provide this information to the bank that is sending the wire to your AtoB Wallet account.
      </div>
      <div className="mb-3 flex">
        <div className="text-default-primary w-[200px]">Account number:</div>{' '}
        <div className="text-default-primary ml-1 font-bold">{financialAccount.account_number}</div>
      </div>
      <div className="mb-3 flex">
        <div className="text-default-primary w-[200px]">Routing number:</div>{' '}
        <div className="text-default-primary ml-1 font-bold">{financialAccount.routing_number}</div>
      </div>
      <div className="mb-3 flex">
        <div className="text-default-primary w-[200px]">Beneficiary name:</div>{' '}
        <div className="text-default-primary ml-1 font-bold">{customerCompanyName}</div>
      </div>
      <div className="mb-3 flex">
        <div className="text-default-primary w-[200px]">Beneficiary address:</div>{' '}
        <div className="text-default-primary ml-1 font-bold">
          {customerCompanyAddress.address1}
          <br />
          {customerCompanyAddress.address2 && (
            <>
              {customerCompanyAddress.address2}
              <br />
            </>
          )}
          {customerCompanyAddress.city}, {customerCompanyAddress.state} {customerCompanyAddress.zip}
          <br />
        </div>
      </div>
      <div className="mb-3 flex">
        <div className="text-default-primary w-[200px]">Bank name:</div>{' '}
        <div className="text-default-primary ml-1 font-bold">{financialAccount.bank_name}</div>
      </div>
      <div className="mb-3 flex">
        <div className="text-default-primary w-[200px]">Bank address:</div>
        <div className="text-default-primary ml-1 max-w-[200px] font-bold">
          <div>{financialAccount.bank_address.address1}</div>
          {financialAccount.bank_address.address2 && (
            <div>{financialAccount.bank_address.address2}</div>
          )}
          <div>
            {financialAccount.bank_address.city}, {financialAccount.bank_address.state}{' '}
            {financialAccount.bank_address.zip}
          </div>
        </div>
      </div>
      <div className="mb-3 flex">
        <div className="text-default-primary w-[200px]">Bank phone number:</div>{' '}
        <div className="text-default-primary ml-1 font-bold">
          {financialAccount.bank_address.phone_number}
        </div>
      </div>
    </div>
  );
};

export const AddFundsForm = ({
  reset,
  onSuccessfulPayment,
  bankAccounts,
  debitCards,
  financialAccount,
  loadingAccounts,
  fetchPaymentMethods,
  customerCompanyName,
  customerCompanyAddress,
  minimumDepositAmountCents,
  initialAmount,
  initialDescription,
  scenario,
  continueOnboarding,
}: {
  reset: () => void;
  onSuccessfulPayment: () => void;
  bankAccounts: BankAccountPaymentMethodCombined[];
  debitCards: DebitCardPaymentMethodCombined[];
  financialAccount?: TreasuryFinancialAccount | null;
  loadingAccounts: boolean;
  fetchPaymentMethods: (isInbound: boolean) => Promise<void>;
  customerCompanyName: string;
  customerCompanyAddress: AddressType;
  minimumDepositAmountCents?: number;
  initialAmount?: number;
  initialDescription?: string;
  scenario: AddFundsScenario;
  continueOnboarding?: () => void;
}) => {
  const [isConfirmingFunds, setIsConfirmingFunds] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState({} as FormErrors);
  const [overallError, setOverallError] = useState<string | null>(null);
  const [amountToAddCents, setAmountToAddCents] = useState(initialAmount ?? 0);
  const [editing, setEditing] = useState(true);
  const [validAmount, setValidAmount] = useState(true);
  const { supportEmailAddress } = useChannelPartner();
  const { trigger: createCustomerOnboardingEvent } = useCreateCustomerOnboardingEvent();
  const resetModal = () => {
    setClientSecret(null);
    reset();
  };

  const amountFixedFor: Record<AddFundsScenario, boolean> = {
    add_funds: false,
    account_setup_fee: true,
    security_deposit: true,
  };

  const minimumDepositAmountContextValue =
    useContext(CustomerContext)?.customer?.treasury.minimum_deposit_amount.cents || 2500;
  const minimumDepositAmount = minimumDepositAmountCents || minimumDepositAmountContextValue;

  const [SAME_DAY_ACH_ENABLED] = useFeatureFlags('same_day_ach_enabled');
  const [ACH_ENABLED] = useProduct('treasury_ach_debit');

  const initialBankAccountId = bankAccounts[0]?.id;
  const [selectedAccountId, setSelectedAccountId] = useState<string | null>(initialBankAccountId);
  const setSelectedAccount = (_: 'inbound' | 'outbound', accountId: string | null) => {
    setSelectedAccountId(accountId);
  };
  const initialDebitCardId = debitCards[0]?.id;
  const [selectedDebitCardId, setSelectedDebitCardId] = useState<string | null>(initialDebitCardId);
  useEffect(() => {
    if (selectedAccountId === undefined) {
      setSelectedAccountId(initialBankAccountId);
    }

    if (selectedDebitCardId === undefined) {
      setSelectedDebitCardId(initialDebitCardId);
    }
  }, [initialBankAccountId, selectedAccountId, initialDebitCardId, selectedDebitCardId]);

  const [transferType, setTransferType] = useState<'ach' | 'wire' | 'debit'>('debit');
  const isDebit = transferType === 'debit';

  const [formFields, setFormFields] = useState({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    amount: getCurrencyFromCents(initialAmount).replace('$', '') ?? '',
    description: initialDescription ?? '',
  });
  const [shouldShowAddDebitCard, setShouldShowAddDebitCard] = useState(false);

  const setAmount = (value: string) => {
    const isNotANumber = Number.isNaN(parseFloat(value));
    const isNotEmpty = value !== '';

    if (isNotANumber && isNotEmpty) {
      return;
    }

    setFormFields({ ...formFields, amount: value });
    setAmountToAddCents(convertAmountToCents(value));
  };

  const onSuccess = () => {
    setSuccess(true);
    setFormFields({
      amount: '',
      description: '',
    });

    void createCustomerOnboardingEvent({
      customer_onboarding_event: { name: addFundsScenarioToEventName[scenario] },
    });

    onSuccessfulPayment();
  };

  const onFailure = (e: unknown) => {
    logger.error('Error adding funds', { error: e });
    setEditing(true);
    // Currently, we are unexpectedly confirming the card payment twice, which
    // is causing the real payment intent error to be clobbered. Only set one
    // error at a time until this is fixed.
    if (!overallError) {
      const readableError = createReadableError(e);
      if (readableError) {
        setOverallError(readableError);
      }
    }
    setIsConfirmingFunds(false);
    setClientSecret(null);
  };

  const {
    submitFunds,
    clientSecret,
    setClientSecret,
    isLoading: isAddingFunds,
  } = useAddFunds({
    onSuccess,
    onFailure,
    amount: amountToAddCents,
    description: formFields.description,
    selectedPaymentMethodId: isDebit ? selectedDebitCardId : selectedAccountId,
    continueOnboarding,
  });

  const handleSubmit = async () => {
    const validationErrors = validate(isDebit ? selectedDebitCardId : selectedAccountId, setErrors);
    if (validationErrors || !validAmount || formFields.amount === '0.00') {
      if (transferType === 'debit' && debitCards.length === 0) {
        setShouldShowAddDebitCard(true);
      }
      setValidAmount(false);
      return;
    }
    if (editing) {
      setEditing(false);
      return;
    }
    setOverallError(null);

    submitFunds();
  };

  const isProcessingFunds = isAddingFunds || isConfirmingFunds;

  if (success) {
    const closeButton = (
      <div className="flex w-full justify-end">
        <div>
          <Button color="primary" onClick={resetModal}>
            Close
          </Button>
        </div>
      </div>
    );

    switch (scenario) {
      case 'add_funds':
        return (
          <>
            <Success
              title="Success!"
              text="The funds you added should arrive within 1-2 minutes."
            />
            <AutoTopUpUpsell />
            {closeButton}
          </>
        );
      case 'account_setup_fee':
        return (
          <>
            <Success title="Your setup fee has been paid!" text="" />
            <div className="text-default-primary flex flex-col items-center px-6 pb-8 text-sm">
              <ul className="list-outside list-disc">
                <li className="py-1">We will collect the fee amount from your Wallet shortly.</li>
                <li className="py-1">
                  Your cards have been ordered and will deliver in 7-10 days.
                </li>
                <li className="py-1">
                  You will need to add additional funds into your Wallet before your first
                  transaction.
                </li>
              </ul>
            </div>
            {closeButton}
          </>
        );
      case 'security_deposit':
        return (
          <>
            <Success
              title={
                transferType == 'debit'
                  ? 'Your security deposit has been paid!'
                  : 'Your security deposit payment is on the way!'
              }
              text={
                transferType == 'debit'
                  ? 'Your credit line should now be active.'
                  : 'Your credit line will be activated once the deposit payment settles.'
              }
            />
            {closeButton}
          </>
        );
    }
  }

  const selectedFinancialInstitution = bankAccounts.find(
    (account) => account.id === selectedAccountId,
  );

  const selectedDebitCard = isDebit && debitCards.find((card) => card.id === selectedDebitCardId);

  const ConfirmationScreen = (): ReactElement => {
    if (isDebit) {
      return (
        <Confirmation
          flow="deposit"
          amount={convertCentsToDollars({ value: amountToAddCents }).format()}
          financialInstitution={`${capitalize(
            (selectedDebitCard as DebitCardPaymentMethodCombined)?.brand,
          )} ••••${(selectedDebitCard as DebitCardPaymentMethodCombined)?.last_four}`}
        />
      );
    }

    return (
      <Confirmation
        flow="deposit"
        amount={convertCentsToDollars({ value: amountToAddCents }).format()}
        financialInstitution={`${selectedFinancialInstitution?.name} ••${selectedFinancialInstitution?.mask}`}
        message={
          transferType === 'ach'
            ? `Funds should arrive ${
                SAME_DAY_ACH_ENABLED ? 'by the next business day' : 'between 3-5 business days'
              }  due to ACH processing times.`
            : ''
        }
      />
    );
  };

  if (shouldShowAddDebitCard) {
    return (
      <DebitCardModal
        isActive={shouldShowAddDebitCard}
        setIsActive={setShouldShowAddDebitCard}
        onSuccess={async () => {
          await fetchPaymentMethods(true);
          setShouldShowAddDebitCard(false);
        }}
      />
    );
  }

  return (
    <>
      {overallError && <ErrorNotification error={overallError} />}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          void handleSubmit();
        }}
      >
        {editing ? (
          <>
            <AddFundsTransferOptions
              transferType={transferType}
              setTransferType={setTransferType}
              financialAccount={financialAccount}
              sameDayAchEnabled={SAME_DAY_ACH_ENABLED}
            />
            <div className="my-3" />
            <WalletAccountFunds
              transferType={transferType}
              bankAccounts={bankAccounts}
              loadingAccounts={isProcessingFunds || loadingAccounts}
              selectedAccountId={selectedAccountId}
              setSelectedAccount={setSelectedAccount}
              overallError={overallError}
              setOverallError={setOverallError}
              fetchPaymentMethods={fetchPaymentMethods}
              flow="inbound"
              debitCards={debitCards}
              selectedDebitCardId={selectedDebitCardId}
              setSelectedDebitCardId={setSelectedDebitCardId}
            />
            {transferType === 'wire' && financialAccount && (
              <WireInstructions
                financialAccount={financialAccount}
                customerCompanyName={customerCompanyName}
                customerCompanyAddress={customerCompanyAddress}
              />
            )}
            {transferType === 'ach' && !ACH_ENABLED && (
              <div className="text-default-primary mb-3 text-sm font-medium">
                Funding via bank account has not been enabled for your account. Please reach out to
                us at{' '}
                <a className="underline" href={`mailto:${supportEmailAddress}`}>
                  {supportEmailAddress}
                </a>{' '}
                for support. In the meantime, you may add money instantly using a debit card.
              </div>
            )}
            {((transferType === 'ach' && ACH_ENABLED) || isDebit) && (
              <>
                <div className="flex flex-col gap-2">
                  <AmountInput
                    validAmount={validAmount}
                    amount={formFields.amount}
                    setAmount={setAmount}
                    setValidAmount={setValidAmount}
                    minimumDeposit={convertCentsToDollars({ value: minimumDepositAmount }).format()}
                  />
                  {!amountFixedFor[scenario] && <SuggestedAmounts setAmount={setAmount} />}
                </div>
                <TextField
                  fullWidth
                  label="Description"
                  placeholder="Description"
                  onChange={(event) => {
                    const value = event.target.value;
                    if (value && value.length > 120) {
                      setErrors({ description: 'Must be less than 120 characters.' });
                      return;
                    }
                    setErrors({ description: undefined });
                    setFormFields({ ...formFields, description: value ?? '' });
                  }}
                  error={!!errors.description}
                  helperText={errors.description}
                  value={formFields.description}
                  disabled={amountFixedFor[scenario]}
                />
              </>
            )}
          </>
        ) : (
          <ConfirmationScreen />
        )}
        <div className="my-6 flex w-full gap-3 pt-2 sm:justify-end">
          {!editing && (
            <Button color="secondary" onClick={() => setEditing(true)} disabled={isProcessingFunds}>
              Cancel
            </Button>
          )}
          {((transferType === 'ach' && ACH_ENABLED) || isDebit) && (
            <LoadingButton type="submit" color="primary" loading={isProcessingFunds}>
              {
                {
                  add_funds: 'Add money',
                  account_setup_fee: 'Pay Account Setup Fee',
                  security_deposit: 'Pay Security Deposit',
                }[scenario]
              }
            </LoadingButton>
          )}
        </div>
      </form>
      {clientSecret && !success && (
        <ConfirmFunds
          setLoading={setIsConfirmingFunds}
          onSuccess={onSuccess}
          onFailure={onFailure}
          scenario={scenario}
        />
      )}
    </>
  );
};
