import { PaginatedEndpointResponse } from '@app/@types/api.types';
import Header from '@app/components/Navigation/Header';
import PageContentWrapper from '@app/components/wrappers/PageContentWrapper';
import { useExtendMobileHeaderContextMenu } from '@app/contexts/MobileNavHeaderMenuContextComponent';
import CustomerContext from '@app/contexts/customerContext';
import { DEFAULT_PAGE_SIZE } from '@app/hooks/paging/types';
import { useUrlState } from '@app/hooks/useUrlState';
import { moneyFormat } from '@app/pages/Wallet/WalletOverview/TransferFunds/utils';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import Card from '@atob-developers/shared/src/components/Card';
import Searchbar from '@atob-developers/shared/src/components/Searchbar';
import { faTruck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import debounce from 'lodash-es/debounce';
import { useContext, useMemo, useState } from 'react';
import useSWR from 'swr';
import Repayments from './Repayments';
import SingleRepaymentSidebar from './SingleRepaymentSidebar';

export type RepaymentStatus = 'active' | 'suspended' | 'deactivated';

export type Repayment = {
  id: number;
  customer_id: number;
  company_name: string;
  status: RepaymentStatus;
  created_at: string;
  amount: { cents: number };
  description: string;
  reference_id: string;
};

export default function BalanceRepayment() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const { customer } = useContext(CustomerContext);

  const [shownQueryValue, setShownQueryValue] = useState<string>('');
  const [selectedFilters, setSelectedFilters] = useUrlState({
    page: 1,
    query: '',
  });

  const {
    data,
    isLoading,
    error: repaymentsError,
    mutate,
  } = useSWR<PaginatedEndpointResponse<Repayment>>(
    {
      url: '/partners/repayments',
      params: {
        per: DEFAULT_PAGE_SIZE,
        all: false,
        ...selectedFilters,
      },
    },
    apiGetFetcher,
  );

  const debounceSearch = useMemo(() => {
    const performSearch = (queryValue: string) => {
      setSelectedFilters((p) => ({ ...p, query: queryValue, page: 1 }));
    };
    return debounce(performSearch, 400);
  }, [setSelectedFilters]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const queryValue = e.target.value;
    setShownQueryValue(queryValue);
    debounceSearch(queryValue);
  };

  const menuData = useMemo(
    () => [
      {
        id: 'singleRepayment',
        text: 'Create Repayment',
        icon: <FontAwesomeIcon icon={faTruck} />,
        onClick: () => setSidebarOpen(true),
        disabled: () => false,
        order: () => 1,
      },
      // TODO: Implement Bulk Upload later.
      // {
      //   id: 'bulkUpload',
      //   text: 'Bulk Upload',
      //   icon: <FontAwesomeIcon icon={faUsers} />,
      //   onClick: () => {},
      //   disabled: () => false,
      //   order: () => 2,
      // },
    ],
    [],
  );

  const menuItems = useMemo(
    () =>
      menuData.map((item) => ({
        value: (
          <>
            {item.icon}
            {item.text}
          </>
        ),
        onClick: item.onClick,
        disabled: item.disabled,
        order: item.order(),
      })),
    [menuData],
  );

  useExtendMobileHeaderContextMenu(...menuItems);

  return (
    <PageContentWrapper
      header={
        <Header
          title={
            <p className="text-default-primary text-lg font-semibold md:text-3xl">
              Balance Repayment
            </p>
          }
          rightContent={
            <div>
              <div className="flex justify-between gap-3">
                {menuData.map((item) => (
                  <Button
                    className="p-3 text-sm"
                    color={item.id === 'bulkUpload' ? 'secondary' : 'primary'}
                    key={item.id}
                    onClick={item.onClick}
                    disabled={item.disabled()}
                    startIcon={item.icon}
                  >
                    {item.text}
                  </Button>
                ))}
                <Searchbar
                  placeholder="Search repayments"
                  name="search"
                  onChange={handleSearch}
                  value={shownQueryValue}
                />
              </div>
              <SingleRepaymentSidebar
                open={sidebarOpen}
                reset={() => setSidebarOpen(false)}
                onSuccess={mutate}
              />
            </div>
          }
        />
      }
    >
      <>
        <div>
          <Card>
            <div className="text-default-secondary text-sm">Account Balance</div>
            <div className="text-default-primary text-2xl font-semibold">
              {moneyFormat(
                customer.account_balance.cents !== 0
                  ? (-1 * customer.account_balance.cents) / 100
                  : 0,
              )}
            </div>
          </Card>
        </div>
        <Repayments
          goToPage={(pageIndex: number) => setSelectedFilters((p) => ({ ...p, page: pageIndex }))}
          metadata={data?.meta}
          repaymentsPage={data?.data || []}
          repaymentsError={repaymentsError}
          loading={isLoading}
        />
      </>
    </PageContentWrapper>
  );
}
