import useThemeConfiguration from '@app/app/useThemeConfiguration';
import { useEventDismissed } from '@app/hooks/useEventDismissed';
import useThemeMode from '@app/hooks/useThemeMode';
import logger from '@app/utils/datadog-logger';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Link } from '@mui/material';
import Button from '@mui/material/Button';
import classNames from 'classnames';
import { PropsWithChildren, ReactNode } from 'react';
import { useInView } from 'react-intersection-observer';

export type TitleVariant =
  | 'Pro Tip'
  | 'Product Tour'
  | 'New Product'
  | 'New Feature'
  | 'New Discount'
  | 'Attention'
  | 'Offer'
  | 'Limited time offer'
  | 'Limited time welcome bonus';

export interface MarketingBannerProps {
  bannerKey: string;
  title?: string;
  icon?: IconProp;
  iconImg?: ReactNode;
  onClick?: () => void;
  actionText?: string;
  onLinkClick?: () => void;
  linkText?: string;
  nonDismissable?: boolean;
}

export const MarketingBanner = ({
  bannerKey,
  title,
  onClick,
  actionText,
  onLinkClick,
  linkText,
  nonDismissable = false,
  icon,
  iconImg,
  children,
}: PropsWithChildren<MarketingBannerProps>) => {
  const { dismissed, loading, setDismissed } = useEventDismissed(bannerKey);
  const { darkClassName } = useThemeMode();

  const { hideDismissableBanners } = useThemeConfiguration();
  const { ref } = useInView({
    triggerOnce: true,
    rootMargin: '50%',
    onChange: (inView) => {
      if (inView) {
        logger.info('banner-shown', { key: bannerKey });
      }
    },
  });

  if (loading || dismissed || hideDismissableBanners) {
    return null;
  }

  return (
    <div
      ref={ref}
      className={classNames(
        darkClassName,
        'bg-marketing-banner border-soft relative flex w-full flex-col items-start gap-3 rounded-lg border p-4 xl:shrink-0 xl:flex-row xl:items-center xl:justify-between xl:gap-0',
      )}
    >
      <div className="flex items-start gap-2 self-stretch xl:items-center xl:self-auto">
        {iconImg ? (
          <div className="h-8 w-8 xl:h-10 xl:w-10">{iconImg}</div>
        ) : icon ? (
          <div className="border-soft bg-soft-primary flex h-8 w-8 items-center justify-center rounded-full border xl:h-10 xl:w-10">
            <FontAwesomeIcon
              className="text-default-primary h-4 w-4 xl:h-6 xl:w-6"
              icon={faXmark}
            />
          </div>
        ) : (
          <span className="border-soft text-default-primary h-6 rounded border px-2 py-1 text-xs uppercase">
            New
          </span>
        )}
        <div className="flex flex-1 shrink-0 flex-col items-start gap-0.5 xl:gap-0">
          {title && (
            <span className="text-default-tertiary text-[10px] font-semibold uppercase leading-4 tracking-[0.65px] xl:text-xs xl:tracking-[0.78px]">
              {title}
            </span>
          )}
          <span className="text-default-primary text-xs font-medium leading-5 xl:text-sm">
            {children}
          </span>
        </div>
      </div>
      <div className="flex shrink-0 items-center gap-2 self-stretch xl:self-auto">
        {actionText && onClick && (
          <Button
            size="small"
            className="flex-1 xl:flex-none"
            onClick={() => {
              logger.info('banner-cta-clicked', { key: bannerKey });
              onClick();
            }}
          >
            {actionText}
          </Button>
        )}
        {linkText && onLinkClick && (
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              logger.info('banner-link-clicked', { key: bannerKey });
              onLinkClick();
            }}
            className="flex-1"
          >
            {linkText}
          </Link>
        )}
        {!nonDismissable && (
          <>
            <Button
              size="small"
              color="secondary"
              className="flex-1 xl:hidden"
              onClick={() => {
                logger.info('banner-dismissed', { key: bannerKey });
                setDismissed();
              }}
            >
              Dismiss
            </Button>
            <IconButton
              size="small"
              color="secondary"
              className="hidden xl:flex"
              onClick={() => {
                logger.info('banner-dismissed', { key: bannerKey });
                setDismissed();
              }}
            >
              <FontAwesomeIcon className="text-base" icon={faXmark} />
            </IconButton>
          </>
        )}
      </div>
    </div>
  );
};
