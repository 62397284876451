import { FlattenedPayrollData } from '@app/@types/payroll.types';
import { InputField, PhoneNumberField } from '@app/components/elements';
import { ErrorNotification } from '@app/components/layout';
import Success from '@app/components/layout/SuccessPage';
import Constants from '@app/utils/constants';
import Modal, {
  ModalFooter,
  ModalBodyContent,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import axios from 'axios';
import { Formik, FormikProps } from 'formik';
import { ReactElement, useContext, useState } from 'react';
import * as Yup from 'yup';
import PayrollContext from '../payroll-context';

const validationSchema = Yup.object({
  email: Yup.string()
    .nullable()
    .email('Email address is not valid')
    .test(
      'has-value',
      'Please add an email address or phone number',
      (value, context) => value || context.parent.phone,
    ),
  phone: Yup.string()
    .nullable()
    .test(
      'has-value',
      'Please add an email address or phone number',
      (value, context) => value || context.parent.email,
    ),
});

export default function AddDriverContactDetailsModal({
  isActive,
  reset,
  driver,
  updateInState,
}: {
  isActive: boolean;
  reset: () => void;
  driver?: FlattenedPayrollData;
  updateInState?: (id: string, driver: Partial<FlattenedPayrollData>) => void;
}): ReactElement {
  const [isSuccess, setIsSuccess] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const { currentDriver, updateDriverInState } = useContext(PayrollContext);
  const driverToAdd = driver || currentDriver;
  const updateDriver = updateInState || updateDriverInState;

  const {
    first_name: firstName,
    last_name: lastName,
    tax_classification: taxClassification,
    email: savedEmailAddress,
    phone: savedPhoneNumber,
  } = driverToAdd;

  const closeModal = () => {
    setIsSuccess(false);
    reset();
  };

  const onSubmit = async (values: { email: string; phone: string }) => {
    const { email, phone } = values;
    const driverValues = {
      email,
      phone,
      first_name: firstName,
      last_name: lastName,
      payroll: { tax_classification: taxClassification },
    };
    setLoading(true);

    try {
      await axios.put(`/drivers/${driverToAdd.id}`, { driver: driverValues });
      await axios.post(`/drivers/${driverToAdd.id}/payroll_invite`);
      updateDriver(driverToAdd?.id ?? '', {
        email,
        phone,
        status: Constants.PayrollEnums.INVITED,
      });
      setIsSuccess(true);
      setLoading(false);
    } catch (e: unknown) {
      setError("There was a problem adding this driver's details. Please try again");

      setLoading(false);
    }
  };

  if (isSuccess) {
    return (
      <Modal open={isActive} toggle={closeModal}>
        <ModalHeader title="" onClose={closeModal} />
        <Success
          title={`${firstName} added successfully.`}
          text={`Please ask ${firstName} to look for an email from hello@atob.us and activate by adding their debit card details.`}
        />
        <ModalFooter>
          <Button size="small" onClick={closeModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  return (
    <div>
      <Modal open={isActive} toggle={closeModal}>
        <Formik
          initialValues={{
            email: savedEmailAddress ?? '',
            phone: savedPhoneNumber ?? '',
          }}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {(
            props: FormikProps<{
              email: string;
              phone: string;
            }>,
          ) => (
            <>
              <ModalHeader title={`Invite ${firstName}`} onClose={closeModal} />
              <ModalBodyContent>
                {error && <ErrorNotification error={error} />}
                <div className="mb-6 text-[14px]">
                  <p className="text-default-primary mb-2">
                    {firstName} will receive a link to add their debit card information to get paid.
                    Please confirm {firstName}&apos;s phone or email so that invite link can be
                    sent.
                  </p>
                </div>
                <form onSubmit={props.handleSubmit}>
                  <PhoneNumberField
                    id="phone"
                    label={
                      savedPhoneNumber
                        ? `Confirm ${firstName}'s phone number`
                        : `Add ${firstName}'s phone number`
                    }
                  />
                  <div className="mt-2">
                    <InputField
                      id="email"
                      label={
                        savedEmailAddress
                          ? `Confirm ${firstName}'s email`
                          : `Add ${firstName}'s email`
                      }
                    />
                  </div>
                </form>
                <div className="text-default-secondary mt-4 text-xs">
                  Inform {firstName} that they will receive a signup link via text/email to add
                  debit card information.
                </div>
              </ModalBodyContent>
              <ModalFooter>
                <Button color="secondary" size="small" onClick={closeModal}>
                  Cancel
                </Button>
                <LoadingButton size="small" loading={loading} onClick={() => props.handleSubmit()}>
                  {`Invite ${firstName}`}
                </LoadingButton>
              </ModalFooter>
            </>
          )}
        </Formik>
      </Modal>
    </div>
  );
}
