import { CustomerData } from '@app/@types/customer.types';
import CustomerContext from '@app/contexts/customerContext';
import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import {
  THEME_CONFIGURATIONS,
  ThemeConfiguration,
  ThemeIdentifier,
} from './ThemeProvider/themeConfigurations';

export default function useThemeConfiguration(overrideCustomer?: CustomerData): ThemeConfiguration {
  const { customer: customerContext } = useContext(CustomerContext);
  const customer = overrideCustomer || customerContext;

  const windowPath = useLocation();

  if (
    (customer?.channel_partner?.name &&
      // eslint-disable-next-line @cspell/spellchecker
      ['uberfreight', 'uberfreight_intermodal'].includes(customer.channel_partner.name)) ||
    windowPath.pathname.startsWith('/uberfreightconversion')
  ) {
    return THEME_CONFIGURATIONS[ThemeIdentifier.UBER_FREIGHT];
  }

  return THEME_CONFIGURATIONS[ThemeIdentifier.DEFAULT];
}
