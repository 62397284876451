import Alert from '@atob-developers/shared/src/components/Alert';
import Modal, {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { LoadingButton } from '@mui/lab';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  resendMessage: () => void;
  isDisabled?: boolean;
  handleButtonClick: () => void;
  isLoading?: boolean;
};

export default function CheckYourPhoneModal({
  isOpen,
  onClose,
  resendMessage,
  isDisabled,
  handleButtonClick,
  isLoading,
}: Props) {
  return (
    <Modal open={isOpen}>
      <ModalHeader
        title="Check your phone for a text"
        onClose={onClose}
        className="justify-center"
        headerImage={<img src="images/onboarding/check-your-phone.webp" className="rounded-lg" />}
      >
        <p className="text-default-secondary pt-1.5 text-center text-base font-normal">
          Download and sign in to the AtoB app. Then simply follow the app prompts.
        </p>
      </ModalHeader>
      <ModalBodyContent>
        <Alert
          type="info"
          title="Didn’t receive a message? Try again."
          actionText="Resend message"
          action={resendMessage}
        />
      </ModalBodyContent>
      <ModalFooter>
        <LoadingButton
          loading={isDisabled || isLoading}
          onClick={handleButtonClick}
          className="w-full"
        >
          Done
        </LoadingButton>
      </ModalFooter>
    </Modal>
  );
}
