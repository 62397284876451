import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import classNames from 'classnames';

type FooterActionButtonsProps = {
  helperButtonText?: string;
  backButtonText?: string;
  nextButtonText?: string;
  handleClickBack?: () => Promise<void> | void;
  handleClickNext?: () => Promise<void> | void;
  isDisabled?: boolean;
  isLoading?: boolean;
  handleClickHelperText?: () => Promise<void> | void;
  hideBackButton?: boolean;
  className?: string;
};

export default function FooterActionButtons({
  helperButtonText,
  backButtonText,
  nextButtonText,
  handleClickBack,
  handleClickNext,
  isDisabled,
  isLoading,
  handleClickHelperText,
  hideBackButton = false,
  className,
}: FooterActionButtonsProps) {
  const nextButton = (
    <LoadingButton
      size="medium"
      loading={isLoading}
      className="w-full"
      onClick={handleClickNext}
      disabled={isDisabled}
    >
      {nextButtonText ?? 'Next'}
    </LoadingButton>
  );

  const backButton = (
    <Button
      color="secondary"
      size="medium"
      className={classNames('w-full', hideBackButton && 'hidden')}
      disabled={isLoading}
      onClick={handleClickBack}
    >
      {backButtonText ?? 'Back'}
    </Button>
  );

  const helperButton = helperButtonText ? (
    <button
      className="text-default-secondary font-medium underline"
      onClick={handleClickHelperText}
      disabled={isLoading}
    >
      {helperButtonText}
    </button>
  ) : null;

  return (
    <div className={classNames('flex w-full flex-1 flex-col justify-end pb-16 md:pb-0', className)}>
      {/* Desktop */}
      <div className={classNames('hidden w-full md:flex', helperButton && 'md:justify-between')}>
        {helperButton}
        <div className={classNames('flex items-center', !helperButton ? 'w-full gap-4' : 'gap-3')}>
          {backButton}
          {nextButton}
        </div>
      </div>

      {/* Mobile */}
      <div className="bg-soft-primary fixed bottom-0 left-0 flex w-full flex-col items-center gap-6 px-6 pb-8 pt-6 md:hidden">
        {helperButton}
        <div className="flex w-full items-center gap-3">
          {!hideBackButton && backButton}
          {nextButton}
        </div>
      </div>
    </div>
  );
}
