import CardInBubble from '@app/assets/images/svg/card_in_bubble.svg';
import { ATOB_SUPPORT_EMAIL } from '@app/constants/support';
import useCustomer from '@app/hooks/useCustomer';
import { useEventDismissed } from '@app/hooks/useEventDismissed';
import { getFetcher } from '@app/utils/data/fetchers';
import { getEnvironment } from '@app/utils/environment';
import Alert from '@atob-developers/shared/src/components/Alert';
import Modal, { ModalBodyContent, ModalHeader } from '@atob-developers/shared/src/components/Modal';
import { formatCurrency } from '@atob-developers/shared/src/utils/formatters';
import { Button } from '@mui/material';
import axios from 'axios';
import { PropsWithChildren } from 'react';
import useSWR from 'swr';
import { useBoolean } from 'usehooks-ts';
import { MarketingBanner } from './Designs/MarketingBanner';

type PreApprovedOffer = {
  amount: number;
  firm: boolean;
  uuid: string;
  decision: 'accepted' | 'requested_higher_amount' | null;
};

type CustomerApplicationWithStatus = {
  application_status: {
    status_details: {
      application_type: 'flex' | 'unlimited';
      preapproved_offer: PreApprovedOffer | null;
      status:
        | 'not_started'
        | 'incomplete'
        | 'under_review'
        | 'update_required'
        | 'approved'
        | 'rejected';
      application_details: {
        return_to_application_link: string;
      };
    };
    application_status_link: string;
  };
  preapproved_offer?: PreApprovedOffer;
};

const externalNavigate = (url: string) => {
  window.open(url, '_blank')?.focus();
};

export default function FuelCardUpsellBanner() {
  const customer = useCustomer();
  const { data: applicationStatusData, isLoading } = useSWR<CustomerApplicationWithStatus>(
    { url: '/customer/application_status' },
    getFetcher,
  );
  const statusDetails = applicationStatusData?.application_status?.status_details;
  const applicationState = statusDetails?.status;

  const { dismissed } = useEventDismissed('fuel_card_upsell_banner');

  const showBanner = customer.is_wallet_lite_only && !isLoading && !dismissed;

  if (!showBanner) {
    return null;
  }

  const preApprovedOffer = applicationStatusData?.preapproved_offer || null;
  const offerHasDecision = preApprovedOffer?.decision;

  if (
    !customer.has_open_customer_application ||
    !applicationStatusData ||
    (preApprovedOffer && !offerHasDecision)
  ) {
    return <NoOpenApplicationUpsellBanner offer={preApprovedOffer} email={customer.owner_email} />;
  } else if (applicationState === 'incomplete') {
    return (
      <HasIncompleteApplicationUpsellBanner
        applicationStatus={applicationStatusData.application_status}
        offer={applicationStatusData.preapproved_offer}
      />
    );
  } else if (applicationState === 'under_review') {
    return <UnderReviewUpsellBanner />;
  } else if (applicationState === 'update_required') {
    return (
      <UpdateRequiredUpsellBanner applicationStatus={applicationStatusData.application_status} />
    );
  } else {
    return null;
  }
}

const FuelCardUpsellMarketingBanner = (
  props: PropsWithChildren<{ onClick?: () => void; actionText?: string; title?: string }>,
) => {
  return (
    <MarketingBanner
      onClick={props.onClick}
      actionText={props.actionText}
      nonDismissable
      title={props.title}
      bannerKey="fuel_card_upsell"
      iconImg={
        <div className="flex items-center justify-center">
          <img src={CardInBubble} alt="Card Icon" className="h-auto max-w-[40px]" />
        </div>
      }
    >
      {props.children}
    </MarketingBanner>
  );
};

const UpdateRequiredUpsellBanner = ({
  applicationStatus,
}: {
  applicationStatus: CustomerApplicationWithStatus['application_status'];
}) => {
  return (
    <FuelCardUpsellMarketingBanner
      actionText="Submit now"
      onClick={() => {
        externalNavigate(applicationStatus.application_status_link);
      }}
    >
      We need more information from you to continue your application
    </FuelCardUpsellMarketingBanner>
  );
};

const UnderReviewUpsellBanner = () => {
  return (
    <FuelCardUpsellMarketingBanner>
      Your application is under review, you'll hear back from us soon!
    </FuelCardUpsellMarketingBanner>
  );
};

const HasIncompleteApplicationUpsellBanner = ({
  applicationStatus,
  offer,
}: {
  applicationStatus: CustomerApplicationWithStatus['application_status'];
  offer: CustomerApplicationWithStatus['preapproved_offer'];
}) => {
  const offerHasDecision = offer?.decision;

  let contentText = 'Complete your application for an Engaged Carrier Card';

  if (offerHasDecision) {
    const formattedOfferAmount = formatCurrency({
      value: offer.amount,
      options: { fromCents: false, precision: 0 },
    });

    contentText = `Complete your application so we can continue processing your weekly credit limit offer of ${formattedOfferAmount}`;
  }

  // modify this to return a different link if there is no decision on the offer smh

  return (
    <FuelCardUpsellMarketingBanner
      title="Complete your application"
      onClick={() => {
        externalNavigate(
          applicationStatus.status_details.application_details.return_to_application_link,
        );
      }}
      actionText="Continue"
    >
      {contentText}
    </FuelCardUpsellMarketingBanner>
  );
};

const NoOpenApplicationUpsellBanner = ({
  offer,
  email,
}: {
  email: string;
  offer: PreApprovedOffer | null;
}) => {
  const { value: upsellModalOpen, toggle: toggleUpsellModal } = useBoolean(false);
  const { value: createApplicationButtonLoading, toggle: toggleCreateApplicationButtonLoading } =
    useBoolean(false);

  const { VITE_CONNECT_URL } = getEnvironment();

  const onUpsellClick = async () => {
    toggleCreateApplicationButtonLoading();
    const { data } = await axios.post<CustomerApplicationWithStatus['application_status']>(
      '/customer/create_customer_application',
    );

    const applicationLink = data.status_details.application_details.return_to_application_link;
    externalNavigate(applicationLink);
  };

  let contentText = '';
  let onClick = () => {};

  if (offer) {
    const formattedOfferAmount = formatCurrency({
      value: offer.amount,
      options: { fromCents: false, precision: 0 },
    });
    contentText = `Congrats, you're pre-approved for an Engaged Carrier Card with a credit line of ${formattedOfferAmount}`;
    onClick = () => {
      externalNavigate(
        `${VITE_CONNECT_URL}/preapproved?offer_id=${offer.uuid}&email=${encodeURIComponent(email)}`,
      );
    };
  } else {
    contentText = 'Apply now for an Engaged Carrier Card with the largest network of discounts!';
    onClick = toggleUpsellModal;
  }

  return (
    <>
      <FuelCardUpsellMarketingBanner title="Free Upgrade" onClick={onClick} actionText="Apply">
        {contentText}
      </FuelCardUpsellMarketingBanner>

      <Modal open={upsellModalOpen} toggle={toggleUpsellModal}>
        <ModalHeader
          title="Apply for the Engaged Carrier Card"
          onClose={toggleUpsellModal}
          headerImage={<img src="/images/engaged-card.png" alt="popup-header" className="w-full" />}
        />
        <ModalBodyContent>
          <div className="bg-soft-primary flex flex-col items-center gap-4">
            <div className="text-default-secondary font-normal">
              Unlock up to $2.00/gal in fuel discounts, zero fees, free credit building, and
              nationwide acceptance.
            </div>
            <Alert
              className="w-full"
              type="info"
              title="Do you have any questions?"
              description="Our support team is available to you 24x7."
              actionText={<a href={`mailto:${ATOB_SUPPORT_EMAIL}`}>Contact support</a>}
            />
            <Button fullWidth onClick={onUpsellClick} disabled={createApplicationButtonLoading}>
              {createApplicationButtonLoading ? 'Loading...' : 'Start Application (5 mins)'}
            </Button>
          </div>
        </ModalBodyContent>
      </Modal>
    </>
  );
};
