import classNames from 'classnames';
import { ReactElement } from 'react';
import { Step, StepStatus, CurrentStep } from '../steps.types';

export const isCurrent = (step: Step): step is CurrentStep => step.status === StepStatus.CURRENT;

export const Current = ({ step }: { step: CurrentStep }): ReactElement => {
  const { id, name, href, required, renderIcon, disableIcon, description } = step;
  return (
    <a
      data-testid={`current-${id}`}
      href={href}
      className={classNames('flex px-6 py-4 text-sm font-medium', {
        'cursor-default': !href,
        'flex-col items-start': !!description,
        'items-center': !description,
      })}
      aria-current="step"
    >
      <div className="mb-4 flex items-center">
        {!disableIcon && (
          <span className="border-success flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2">
            <span className="text-green">{renderIcon ? renderIcon(id) : id}</span>
          </span>
        )}
        <div className={classNames('flex flex-col text-sm font-medium', !disableIcon && 'ml-4')}>
          {required && <span className="text-default-secondary">Required</span>}
          <span className="text-green">{name}</span>
        </div>
      </div>
      {description && <div className="text-default-secondary text-xs">{description}</div>}
    </a>
  );
};
