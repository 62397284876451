import useChannelPartner from '@app/hooks/useChannelPartner';
import { ReactElement } from 'react';
import { ErrorBanner } from './Designs/ErrorBanner';

const SuspendedBanner = (): ReactElement => {
  const { supportPhoneNumber } = useChannelPartner();

  return (
    <ErrorBanner>
      <div>Action Needed to Re-activate: Please contact customer care</div>
      <div>
        Your account is temporarily deactivated. Chat from your dashboard or call us 24/7 at{' '}
        {supportPhoneNumber}.
      </div>
    </ErrorBanner>
  );
};

export default SuspendedBanner;
