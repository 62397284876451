import useThemeMode from '@app/hooks/useThemeMode';
import { TourProvider, ProviderProps } from '@reactour/tour';
import classNames from 'classnames';
import { useState } from 'react';

export default function Tour({ steps, position, onClickMask, children, ...props }: ProviderProps) {
  const [currentStep, setCurrentStep] = useState(0);
  const { darkClassName } = useThemeMode();

  const disableScrolling = () => {
    document.body.style.overflow = 'hidden';
  };

  const enableScrolling = () => {
    document.body.style.overflow = '';
  };

  return (
    <TourProvider
      {...props}
      steps={steps}
      className={classNames(
        darkClassName,
        '!bg-soft-primary flex max-w-[350px] flex-col items-start gap-3 rounded !p-4',
      )}
      maskClassName="!text-transparent"
      position={position ?? 'bottom'}
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      afterOpen={disableScrolling}
      beforeClose={enableScrolling}
      onClickClose={enableScrolling}
      onClickMask={({ setIsOpen, ...props }) => {
        // By default clicking mask closes onboarding. We want to keep that behaviour but also do some other things
        // for example save event about skipping onboarding.
        onClickMask?.({ ...props, setIsOpen });
        setIsOpen(false);
        enableScrolling();
      }}
      components={{
        Arrow: () => null,
        Badge: () => null,
        Close: () => null,
        Navigation: () => null,
      }}
      styles={{
        clickArea: (base) => ({ ...base, clipPath: 'none' }),
        maskArea: () => ({ display: 'none' }),
        popover: (base) => ({
          ...base,
          ...(currentStep === 0 ? { transition: 'none' } : {}),
        }),
      }}
    >
      {children}
    </TourProvider>
  );
}
