import { faLocationDot } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const FuelListingMapLabelFixed = (_: { lat: number; lng: number }) => {
  return (
    <div className="flex -translate-y-full flex-col items-center justify-center">
      <FontAwesomeIcon className="text-green" size="3x" icon={faLocationDot} />
    </div>
  );
};

export default FuelListingMapLabelFixed;
