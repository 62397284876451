import { Plan } from '@app/@types/subscriptions.types';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { ReactElement } from 'react';

export default function PlanOption({
  plan,
  onSelect,
}: {
  plan: Plan;
  onSelect: (plan: Plan) => void;
}): ReactElement {
  return (
    <div
      className={classNames(
        'bg-soft-primary flex w-full cursor-pointer justify-between rounded-md border-2 px-8 py-4',
        plan.selected ? 'border-strong' : 'border-soft',
      )}
      onClick={() => onSelect(plan)}
    >
      <div className="flex flex-col justify-between">
        <h3 className="text-default-primary text-2xl font-bold capitalize">{plan.name}</h3>
        <div className="text-sm font-medium md:text-lg">
          {plan.name === 'premium' && (
            <div className="text-default-secondary pb-3 text-sm font-semibold">
              All Basic Card benefits plus:
            </div>
          )}
          {plan.valueProps.map((prop) => (
            <div
              key={prop}
              className="text-default-secondary flex flex-row items-center gap-2 pb-3 text-sm font-normal"
            >
              <FontAwesomeIcon icon={faCheck} />
              {prop}
            </div>
          ))}
        </div>
      </div>
      <div className="border-soft flex flex-col items-center justify-center border-l pl-4">
        <div className="text-default-primary text-2xl font-bold">{plan.amount}</div>
        {plan.amountCadence && (
          <span className="text-default-secondary w-[80px] text-center text-sm font-medium">
            {plan.amountCadence}
          </span>
        )}
        {plan.amountDetails && (
          <div className="text-default-secondary w-[80px] text-center text-sm font-medium">
            {plan.amountDetails}
          </div>
        )}
      </div>
    </div>
  );
}
