import {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { faCube } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { ReactElement } from 'react';
import { CreditEnablementNetNewStepsProps } from './CreditEnablementNetNewStepsProps';

export const CreditEnablementNetNewConfirmShipping = ({
  toggle,
  shippingAddress,
  onNext,
  onBack,
}: CreditEnablementNetNewStepsProps): ReactElement | null => {
  return (
    <>
      <ModalHeader title="Confirm Shipping Address" onClose={toggle} />
      <ModalBodyContent overflowVisible={true}>
        <div className="text-default-primary flex flex-col text-base">
          <div className="mt-2 flex items-center align-top">
            <FontAwesomeIcon
              icon={faCube}
              size="2x"
              className="text-default-primary mr-2 scale-75"
            />
            <div className="ml-2 mt-1">
              <div className="text-default-primary text-sm font-semibold">
                Your cards will be shipped to:
              </div>
              <div className="text-default-primary text-sm">{shippingAddress}</div>
            </div>
          </div>
        </div>

        <div className="text-default-primary my-4 text-sm">
          If this address is incorrect, please contact support to get this changed
        </div>
      </ModalBodyContent>
      <ModalFooter>
        <div className="flex w-full justify-between gap-2">
          <Button className="flex-1" size="medium" color="secondary" onClick={onBack}>
            Back
          </Button>
          <Button className="flex-1" size="medium" onClick={onNext}>
            Continue
          </Button>
        </div>
      </ModalFooter>
    </>
  );
};
