import { InstantPayoutMethod, TreasuryRecipient } from '@app/@types/instant_payout_method.types';
import BankAccountIcon from '@app/assets/images/svg/bank_account_icon.svg';
import PaypalIcon from '@app/assets/images/svg/paypal_icon.svg';
import VenmoIcon from '@app/assets/images/svg/venmo_icon.svg';
import ZelleIcon from '@app/assets/images/svg/zelle_icon.svg';
import classNames from 'classnames';
import { mapAccountType } from './utils';

export type PaymentMethodType = 'zelle' | 'paypal' | 'venmo' | 'recipient';

export const paymentMethodIconMapping = {
  zelle: ZelleIcon,
  paypal: PaypalIcon,
  venmo: VenmoIcon,
  recipient: BankAccountIcon,
};

export default function RecipientAccounts({
  instantPayoutMethods,
  treasuryRecipients,
}: {
  instantPayoutMethods: InstantPayoutMethod[];
  treasuryRecipients: TreasuryRecipient[];
}) {
  const instantTypes = instantPayoutMethods.map((method) => mapAccountType(method.type));
  const recipientTypes = treasuryRecipients.map((account) => account.type);
  const allTypes = [...new Set([...instantTypes, ...recipientTypes])];

  return (
    <>
      <div className="flex items-center">
        {allTypes.map((type, index) => (
          <div
            key={type}
            className={classNames('h-6 w-[34px]', {
              '-ml-2.5': index > 0,
            })}
            style={{ zIndex: allTypes.length - index }}
          >
            <img
              src={paymentMethodIconMapping[type as PaymentMethodType]}
              className="h-full w-full"
              alt={`${type} icon`}
            />
          </div>
        ))}
      </div>
    </>
  );
}
