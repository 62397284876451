import DefaultExportButton from '@app/components/Exporting/Default/DefaultExportButton';
import Header from '@app/components/Navigation/Header';
import StickyButtonWrapper from '@app/components/wrappers/StickyButtonWrapper';
import { useExtendMobileHeaderContextMenu } from '@app/contexts/MobileNavHeaderMenuContextComponent';
import { useTelematicsProvider } from '@app/hooks/query/useConnectTelematics';
import Searchbar from '@atob-developers/shared/src/components/Searchbar';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import axios from 'axios';
import { ChangeEvent, ReactElement, useMemo } from 'react';
import useSWRMutation from 'swr/mutation';
import { DriverQueryFilters } from './DriverQueryFilters';

interface DriversPageHeaderProps {
  onSearchChange: ((e: ChangeEvent<HTMLInputElement>) => void) | 'no-search';
  value: string;
  setShowDriverModal: () => void;
  selectedFilters: DriverQueryFilters;
}

const DriversPageHeader = ({
  onSearchChange,
  value,
  setShowDriverModal,
  selectedFilters,
}: DriversPageHeaderProps): ReactElement => {
  const { addToast } = useToasts();
  const { data } = useTelematicsProvider();

  const providers = data?.data.providers;
  const connectedProvider = providers?.filter((provider) => provider.connected);
  const hasActiveTelematics = connectedProvider && connectedProvider.length > 0;

  const { trigger: syncDrivers, isMutating: isSyncingDrivers } = useSWRMutation(
    `/drivers/sync_drivers`,
    async (url) => {
      const response = await axios.post(url);
      return response.data;
    },
    {
      onSuccess: (data) => {
        addToast({ type: 'success', title: data.message || 'Drivers sync started successfully!' });
      },
      onError: (error) => {
        if (axios.isAxiosError(error)) {
          addToast({
            type: 'error',
            title: error.response?.data?.error || 'Error syncing drivers',
          });
        } else {
          addToast({ type: 'error', title: 'Something went wrong' });
        }
      },
    },
  );

  const menuItem = useMemo(
    () => ({
      value: 'Add a driver',
      disabled: () => false,
      order: 1,
      onClick: setShowDriverModal,
    }),
    [setShowDriverModal],
  );

  useExtendMobileHeaderContextMenu(menuItem);

  return (
    <Header
      title="Drivers"
      mobileStickyButton={
        <StickyButtonWrapper>
          <Button
            size="small"
            onClick={setShowDriverModal}
            startIcon={<FontAwesomeIcon icon={faPlus as IconProp} size="1x" />}
            fullWidth
          >
            Add a driver
          </Button>
        </StickyButtonWrapper>
      }
      rightContent={
        <div className="flex flex-col content-center justify-end gap-3 md:flex-row">
          <DefaultExportButton
            selectedFilters={selectedFilters}
            exportType="drivers"
            buttonText="Export Drivers"
          />
          {hasActiveTelematics && (
            <LoadingButton
              size="small"
              color="primary"
              onClick={() => syncDrivers()}
              loading={isSyncingDrivers}
            >
              Sync Drivers
            </LoadingButton>
          )}
          <Button
            size="small"
            onClick={setShowDriverModal}
            startIcon={<FontAwesomeIcon icon={faPlus as IconProp} size="1x" />}
          >
            Add a driver
          </Button>
        </div>
      }
      searchBar={
        onSearchChange !== 'no-search' && (
          <Searchbar
            placeholder="Search drivers"
            name="search"
            onChange={onSearchChange}
            value={value}
          />
        )
      }
    />
  );
};

export default DriversPageHeader;
