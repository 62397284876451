import useCustomer from '@app/hooks/useCustomer';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import { NavbarGroup } from '@app/interfaces/navbar';
import { ReactElement } from 'react';
import { computeEnabledGroups, getNavBarItems } from './NavBarItems';
import NavBarWrapper from './NavBarWrapper';
import NavbarSection from './NavbarSection';

const NavBar = ({ navBarItems }: { navBarItems?: NavbarGroup[] }): ReactElement | null => {
  const { interstitials, products } = useCustomer();
  const [streamlinedDriverAppOnboarding, blocklistDriverAppOnboarding] = useFeatureFlags(
    'streamlined_driver_app_onboarding',
    'blocklist_driver_app_onboarding',
  );

  if (interstitials) {
    return null;
  }

  const enableStreamlinedDriverAppOnboarding =
    streamlinedDriverAppOnboarding && !blocklistDriverAppOnboarding;
  if (navBarItems === undefined) {
    navBarItems = getNavBarItems(products, enableStreamlinedDriverAppOnboarding);
  }

  const processedNavBarItems = computeEnabledGroups(products, navBarItems);

  return (
    <NavBarWrapper>
      {processedNavBarItems.map((section) => (
        <NavbarSection key={section.key} section={section} />
      ))}
    </NavBarWrapper>
  );
};

export default NavBar;
