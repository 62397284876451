import {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { Button } from '@mui/material';
import { ReactElement } from 'react';

export interface UnlimitedToFlexMigrationConfirmOfferProps {
  creditLimit: number;
  activationFee: number;
  onNext: () => void;
  onBack: () => void;
  onClose: () => void;
}

const UnlimitedToFlexMigrationConfirmOffer = ({
  creditLimit,
  activationFee,
  onNext,
  onBack,
  onClose,
}: UnlimitedToFlexMigrationConfirmOfferProps): ReactElement | null => {
  return (
    <>
      <ModalHeader title="Review Details" onClose={onClose} />
      <ModalBodyContent overflowVisible={true}>
        <div className="text-default-primary flex flex-col text-sm">
          <div className="my-1">You will be upgraded to AtoB Flex once you accept this offer.</div>
          <div className="my-1">
            A $3 per active card, per month fee will also be billed monthly.
          </div>
          <div className="my-1">
            A $6/transaction fee will be charged for every transaction outside of AtoB’s discount
            network.
          </div>

          <div className="my-4 text-base font-bold">Your Offer:</div>

          <div className="border-strong mb-4 flex w-full flex-col rounded-md border-2 text-left md:mb-0">
            <div className="w-full p-3">
              <div className="flex items-center md:flex-col md:items-start">
                <h3 className="mr-2 text-2xl font-semibold">${creditLimit}</h3>
                <div className="text-default-primary mt-2 text-sm font-bold">
                  Weekly credit line. Billed weekly.
                </div>
              </div>
            </div>
            <div className="ml-4 flex flex-col items-start p-2">
              <ul className="list-disc p-2 pt-0 text-sm">
                <li>No credit checks or personal guarantees</li>
                <li>Accepted at stations nationwide</li>
                <li>Get an average of 46¢ per gallon, and up to $1.85 off via the AtoB App</li>
              </ul>
            </div>
          </div>
        </div>
      </ModalBodyContent>
      <ModalFooter className="items-stretch">
        <Button className="flex-1" color="secondary" onClick={onBack}>
          Back
        </Button>
        <Button className="flex-1" color="primary" onClick={onNext}>
          Accept Offer {activationFee > 0 && `& Pay ${activationFee}`}
        </Button>
      </ModalFooter>
    </>
  );
};

export default UnlimitedToFlexMigrationConfirmOffer;
