import { Loading } from '@app/components/layout';
import Constants from '@app/utils/constants';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import * as Sentry from '@sentry/react';
import axios, { AxiosError } from 'axios';
import React, { ReactElement, useEffect } from 'react';

const getParams = () => {
  return new URLSearchParams(window.location.search);
};

const SettingsPageDriverRequestCallback: React.FC = (): ReactElement => {
  const params = getParams();
  const { addToast } = useToasts();

  useEffect(() => {
    const token = params.get('token');
    const update = params.get('update');

    axios
      .post(`/driver_portal/verification_requests/${update}`, { token })
      .then(() => {
        Sentry.captureMessage('DriverEmailVerificationRequest finalized successfully', {
          user: {
            params,
          },
        });
        if (update === 'approve') {
          addToast({
            type: 'success',
            title: 'Driver request approved',
            onDismiss: () =>
              (window.location.href = `/?status=${Constants.SettingsPageEnums.FAIL}`),
          });
        } else {
          addToast({
            type: 'error',
            title: 'Driver request rejected',
            onDismiss: () =>
              (window.location.href = `/?status=${Constants.SettingsPageEnums.FAIL}`),
          });
        }
      })
      .catch((e: AxiosError) => {
        Sentry.captureException(e, {
          user: {
            request_id: e?.response?.headers?.['x-request-id'],
            params,
          },
        });
        addToast({
          type: 'error',
          title: 'Request failed',
          onDismiss: () => (window.location.href = `/?status=${Constants.SettingsPageEnums.FAIL}`),
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Loading />;
};

export default SettingsPageDriverRequestCallback;
