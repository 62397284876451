import Modal, { ModalBodyContent, ModalHeader } from '@atob-developers/shared/src/components/Modal';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';

import { RepaymentSummary } from './RepaymentSummary';

export const ConfirmationModal = ({
  open,
  onClose,
  amount,
  description,
  onConfirm,
  loading,
  recipientName,
  referenceId,
}: {
  open: boolean;
  onClose: () => void;
  amount: string;
  description: string;
  onConfirm: () => void;
  loading: boolean;
  recipientName: string;
  referenceId: string;
}) => {
  return (
    <Modal open={open} toggle={onClose} maxWidth="xs">
      <ModalHeader title="Review Repayment" />
      <ModalBodyContent>
        <div className="mb-4 mt-2 flex flex-col gap-6">
          <RepaymentSummary
            recipientName={recipientName}
            referenceId={referenceId}
            description={description}
            amount={amount}
          />
          <div className="flex flex-col gap-3 md:flex-row">
            <Button color="secondary" fullWidth onClick={onClose}>
              Back to edit
            </Button>
            <LoadingButton color="primary" fullWidth onClick={onConfirm} loading={loading}>
              Confirm
            </LoadingButton>
          </div>
        </div>
      </ModalBodyContent>
    </Modal>
  );
};
