import { InstantPayoutRecipient } from '@app/@types/instant_payout_method.types';
import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import { paymentMethodIconMapping, PaymentMethodType } from '@app/pages/Wallet/RecipientAccounts';
import AddBankAccountModal from '@app/pages/Wallet/WalletOverview/TransferFunds/PayNow/AddBankAccountModal';
import { addRecipient } from '@app/pages/Wallet/WalletOverview/TransferFunds/WalletPaymentMethods';
import AddInstantPayoutMethodAccountModal from '@app/pages/Wallet/WalletOverview/TransferFunds/WithdrawFundsV2/Modals/AddInstantPayoutMethodAccountModal';
import { faPlus, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { ReactElement, useState } from 'react';
import { mutate } from 'swr';
import AccountInfo from './AccountInfo';
import DeleteBankAccountModal from './Modals/DeleteBankAccountModal';
import DeleteInstantPayoutAccountModal from './Modals/DeleteInstantPayoutAccountModal';
import { Account, getFullAccountName, mapRecipientAccounts, methodNameMapping } from './utils';

export default function RecipientAccountsList({
  recipient,
}: {
  recipient: InstantPayoutRecipient;
}): ReactElement {
  const accounts = mapRecipientAccounts(
    recipient?.instant_payout_methods || [],
    recipient?.treasury_recipients || [],
  );

  return (
    <div className="flex flex-col gap-4">
      <p className="text-default-primary font-semibold">Accounts</p>
      {Object.keys(methodNameMapping).map((method) => (
        <AccountsPanel
          key={method}
          recipient={recipient}
          paymentMethodType={method as PaymentMethodType}
          accounts={accounts.filter((account) => account.type === method)}
        />
      ))}
    </div>
  );
}

const RecipientAccount = ({
  account,
  recipient,
}: {
  account: Account;
  recipient: InstantPayoutRecipient;
}) => {
  const [openDeleteAccountModal, setOpenDeleteAccountModal] = useState(false);

  return (
    <>
      <AccountInfo account={account} className="py-1">
        <div className="flex gap-2">
          <Button
            color="tertiary"
            size="small"
            onClick={() => setOpenDeleteAccountModal(true)}
            startIcon={<FontAwesomeIcon icon={faTrash} />}
          />
        </div>
        <DeleteInstantPayoutAccountModal
          open={openDeleteAccountModal && ['paypal', 'zelle', 'venmo'].includes(account.type)}
          account={account}
          recipientName={recipient.name}
          recipientId={recipient.id}
          onClose={() => setOpenDeleteAccountModal(false)}
        />
        <DeleteBankAccountModal
          open={openDeleteAccountModal && account.type === 'recipient'}
          account={account}
          recipientName={recipient.name}
          recipientId={recipient.id}
          onClose={() => setOpenDeleteAccountModal(false)}
        />
      </AccountInfo>
    </>
  );
};

export const AccountsPanel = ({
  paymentMethodType,
  accounts,
  recipient,
}: {
  paymentMethodType: PaymentMethodType;
  accounts: Account[];
  recipient: InstantPayoutRecipient;
}) => {
  const [openAddAccountModal, setOpenAddAccountModal] = useState(false);
  const paymentMethodName = methodNameMapping[paymentMethodType];

  return (
    <>
      <RoundedCard className="flex flex-col justify-between gap-3 p-4">
        <div className="flex items-center gap-2">
          <img src={paymentMethodIconMapping[paymentMethodType]} className="h-6 w-[34px]" />
          <p className="text-default-primary font-medium">{paymentMethodName}</p>
        </div>
        <div className="flex flex-col gap-2">
          {accounts.map((account) => (
            <RecipientAccount key={account.id} account={account} recipient={recipient} />
          ))}
        </div>
        <Button
          color="secondary"
          size="small"
          onClick={() => setOpenAddAccountModal(true)}
          startIcon={<FontAwesomeIcon icon={faPlus} />}
        >
          Add {getFullAccountName(paymentMethodName, paymentMethodType)}
        </Button>
      </RoundedCard>

      <AddInstantPayoutMethodAccountModal
        reset={() => setOpenAddAccountModal(false)}
        open={openAddAccountModal && ['zelle', 'paypal', 'venmo'].includes(paymentMethodType)}
        type={paymentMethodType as 'zelle' | 'paypal' | 'venmo'}
        key={paymentMethodType}
        instantPayoutRecipientId={recipient.id}
      />
      <AddBankAccountModal
        reset={() => setOpenAddAccountModal(false)}
        onAddRecipient={async (bankAccount) => {
          await addRecipient({
            ...bankAccount,
            instant_payout_recipient_id: recipient.id,
          });
          await mutate({
            url: `/instant_payout/recipients/${recipient.id}?include=treasury_recipients,instant_payout_methods`,
          });
        }}
        isOwned={recipient.customer_owned}
        open={openAddAccountModal && paymentMethodType === 'recipient'}
      />
    </>
  );
};
