export const IFTA_FILING_FILTERS = {
  DASHBOARD: 'DASHBOARD',
} as const;

/**
 * Enum for IFTA filing stages in the UI flow
 */
export enum IftaFilingStage {
  // Stage 1: Initial stage
  INITIAL = '1',

  // Stage 2: Non-AtoB IFTA Reports
  NON_ATOB_REPORTS = '2',

  // Stage 3: AtoB IFTA Reports
  ATOB_REPORTS = '3',

  // Stage 4: Review and Submit
  REVIEW = '4',

  // Stage 5: Confirmation/Complete
  COMPLETE = '5',
}

/**
 * Enum for file upload modes used in the API
 */
export enum IftaFileUploadMode {
  // Mode 0: Non-AtoB files (used when stage is NON_ATOB_REPORTS)
  NON_ATOB = '0',

  // Mode 1: AtoB files (used when stage is ATOB_REPORTS)
  ATOB = '1',
}

/**
 * Maps UI filing stages to the appropriate file upload mode
 * @param stage The current IFTA filing stage
 * @returns The corresponding file upload mode
 */
export function getFileUploadModeFromStage(stage: string): string {
  switch (stage) {
    case IftaFilingStage.NON_ATOB_REPORTS:
      return IftaFileUploadMode.NON_ATOB;
    case IftaFilingStage.ATOB_REPORTS:
      return IftaFileUploadMode.ATOB;
    default:
      return IftaFileUploadMode.NON_ATOB; // Default fallback
  }
}
