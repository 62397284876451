import { EndpointResponse, PaginatedEndpointResponse } from '@app/@types/api.types';
import { NotificationTypes } from '@app/@types/notification_setting.types';
import { TagData } from '@app/@types/tag.types';
import { UserData } from '@app/@types/users.types';
import PhoneInput from '@app/components/Inputs/PhoneInput';
import { SidebarWrapper } from '@app/components/wrappers/SidebarWrapper';
import { notificationsList } from '@app/constants/notifications';
import {
  getUpdateUserNotificationSettingsParams,
  useUpdateUserNotificationSettings,
} from '@app/hooks/query/useNotificationSettings';
import useProduct from '@app/hooks/useProduct';
import { handleError } from '@app/utils/data/errorHandling';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import { WithId } from '@atob-developers/shared/src/@types/form.types';
import { SideBarBody, SideBarFooter } from '@atob-developers/shared/src/components/SideBar';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { getPhoneWithoutDialingCode } from '@atob-developers/shared/src/utils/formatters/phoneFormat';
import { LoadingButton } from '@mui/lab';
import { Button, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import axios, { AxiosError } from 'axios';
import { deserialize } from 'deserialize-json-api';
import { useFormik } from 'formik';
import { ReactElement, useEffect, useState } from 'react';

import useSWR from 'swr';
import * as Yup from 'yup';
import { NotificationsSection } from './NotificationsSection';
export interface UserSidebarProps {
  open: boolean;
  allowManageNotifications: boolean;
  setOpen: (open: boolean) => void;
  rolesEntry: Record<string, WithId<Record<string, string>>>;
  fetchData: () => void;
  workspaceRoles: boolean;
}

export const NewUserSidebar = ({
  open,
  allowManageNotifications,
  setOpen,
  rolesEntry,
  fetchData,
  workspaceRoles,
}: UserSidebarProps): ReactElement => {
  const { addToast } = useToasts();
  const { updateUserNotificationSettings } = useUpdateUserNotificationSettings();
  const [enabledNotifications, setEnabledNotifications] = useState<NotificationTypes[]>([]);
  const [selectedResources, setSelectedResources] = useState<string[]>(['all']);
  const roleItems: WithId<Record<string, string>>[] = Object.values(rolesEntry);
  const [walletLite] = useProduct('wallet_lite');
  const {
    data: tags,
    isLoading: isTagsLoading,
    error: tagError,
  } = useSWR<PaginatedEndpointResponse<TagData>>(
    !walletLite ? { url: '/tags?all=true' } : null,
    apiGetFetcher,
  );
  const allResources = [
    {
      id: 'all',
      name: 'All tags',
    },
    ...((tags?.data ?? []).map((tag) => ({ id: tag.id.toString(), name: tag.name })) || []),
  ];

  const onSave = (values: Record<string, string>) => {
    const successMsg = `We’ve emailed the user an invitation to join. ${
      values.phone
        ? 'To receive SMS notifications, the user must verify the phone number by clicking the SMS link sent to user’s phone.'
        : ''
    }`;
    const validatedValues = {
      ...values,
      phone: values.phone.replace(/[() -]/g, '').replace('+1', ''),
    };
    const showNotificationsOptions = values?.role !== 'bookkeeper' && allowManageNotifications;
    axios
      .post<EndpointResponse<UserData>>('/users', { user: validatedValues })
      .then((response) => {
        if (!showNotificationsOptions) {
          return;
        }
        const user = deserialize(response.data).data;
        if (!walletLite) {
          const params = getUpdateUserNotificationSettingsParams(
            user,
            selectedResources,
            enabledNotifications,
            allResources,
          );
          updateUserNotificationSettings(params);
        }
      })
      .then(() => {
        fetchData();
        addToast({
          type: 'success',
          title: successMsg,
        });
        setOpen(false);
      })
      .catch((res) => {
        handleError(res);
        if (res.response.status === 422 || res.response.status === 400) {
          if (res.response.data.errors.base) {
            addToast({
              type: 'error',
              title: res.response.data.errors.base,
            });
          } else {
            const errors = res.response.data.errors;
            const errorMessages = Array.isArray(errors)
              ? errors
              : Object.keys(errors).map((key) => key + ' ' + errors[key][0]);
            addToast({ type: 'error', title: errorMessages.join(', ') });
          }
        } else {
          addToast({
            type: 'error',
            title: 'There was an error inviting this user. Please try again later',
          });
        }
      });
  };

  const { values, handleChange, setFieldValue, isValid, isSubmitting, setSubmitting, submitForm } =
    useFormik({
      initialValues: { name: '', phone: '', email: '', role: '' },
      validationSchema: validationSchema,
      onSubmit: onSave,
    });

  const showNotificationOptions = values?.role !== 'bookkeeper' && allowManageNotifications;

  useEffect(() => {
    // List of valid notification IDs that can be enabled
    const notificationIDs = notificationsList
      .filter((n) => !n.globalOnly)
      .map((notification) => notification.id);

    if (!showNotificationOptions) {
      setEnabledNotifications([]);
    } else {
      setEnabledNotifications(notificationIDs);
    }
  }, [values, showNotificationOptions, setEnabledNotifications]);

  return (
    <SidebarWrapper title="Add a new user" open={open} setOpen={(val: boolean) => setOpen(val)}>
      <SideBarBody>
        <div data-testid="user-sidebar-body" className="flex flex-col space-y-4">
          <TextField
            fullWidth
            required
            id="name"
            size="small"
            slotProps={{ htmlInput: { 'data-testid': 'name-input' } }}
            label="Name"
            value={values.name}
            onChange={handleChange}
          />
          <PhoneInput
            id="phone"
            value={values.phone}
            slotProps={{ htmlInput: { 'data-testid': 'phone-input' } }}
            onChange={handleChange}
            label="Phone"
            size="small"
          />
          <TextField
            fullWidth
            id="email"
            size="small"
            label="Email"
            slotProps={{ htmlInput: { 'data-testid': 'email-input' } }}
            required
            value={values.email}
            onChange={handleChange}
          />
          {workspaceRoles && (
            <FormControl>
              <InputLabel size="small">Role</InputLabel>
              <Select
                value={values.role}
                size="small"
                fullWidth
                id="role"
                onChange={(e) => setFieldValue('role', e.target.value)}
                renderValue={(value) => {
                  const item = roleItems.find((item) => item.id === value);
                  if (!item) {
                    return <span className="text-default-secondary font-normal">Role</span>;
                  }
                  return (
                    <div className="text-default-primary flex w-full items-center justify-between">
                      {item.name}
                    </div>
                  );
                }}
                disabled={false}
                displayEmpty
              >
                {roleItems.map((item) => (
                  <MenuItem
                    key={item.id}
                    value={item.id}
                    classes={{ root: 'flex-col items-start' }}
                  >
                    <div className="text-default-primary flex cursor-pointer font-medium">
                      {item.name}
                    </div>
                    <div className="text-default-secondary flex cursor-pointer text-xs">
                      {item.description}
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          {showNotificationOptions && (
            <NotificationsSection
              isLoading={isTagsLoading || isSubmitting}
              error={tagError as AxiosError}
              allResources={allResources}
              userHasPhone={values.phone != ''}
              selectedResources={selectedResources}
              enabledNotifications={enabledNotifications}
              setSelectedResources={setSelectedResources}
              setEnabledNotifications={setEnabledNotifications}
            />
          )}
        </div>
      </SideBarBody>
      <SideBarFooter>
        <Button
          size="small"
          color="secondary"
          onClick={() => {
            setOpen(false);
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          data-testid="submit"
          loading={isSubmitting}
          size="small"
          onClick={() => {
            submitForm();
            setSubmitting(false);
          }}
          disabled={isSubmitting || !isValid}
        >
          Add
        </LoadingButton>
      </SideBarFooter>
    </SidebarWrapper>
  );
};

const validationSchema = Yup.object({
  email: Yup.string().email('Email address is not valid'),
  name: Yup.string().required(),
  phone: Yup.string()
    .required('Phone number is required')
    .test('len', 'Phone number is not valid', (val) => {
      const number = getPhoneWithoutDialingCode(val);
      return number?.length === 10;
    }),
  role: Yup.string().required(),
});
