import { EndpointResponse } from '@app/@types/api.types';
import useProduct from '@app/hooks/useProduct';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import { faPartyHorn } from '@fortawesome/pro-regular-svg-icons';
import { useState } from 'react';
import useSWR from 'swr';
import SecurityDepositOfferModal, {
  SecurityDeposit,
} from '../Modals/SecurityDepositModals/SecurityDepositOfferModal';
import { ULToCreditRequest } from '../Modals/ULToCreditRequest/ULToCreditRequest';
import { MarketingBanner } from './Designs/MarketingBanner';

const SecurityDepositOfferBanner = () => {
  const { data: securityDeposit, isLoading: loading } = useSWR<EndpointResponse<SecurityDeposit>>(
    { url: `/security_deposits/offer` },
    apiGetFetcher,
  );

  const [offerModalOpen, setOfferModalOpen] = useState(false);
  const [customModalOpen, setCustomModalOpen] = useState(false);

  // CISD flow is where the customer gets to pick their desired credit line
  const [cisdFlow] = useProduct('unlimited_to_credit_requests');
  // Offer flow is where the customer's credit line and deposit amount are pre-determined by Risk
  const offerFlow =
    securityDeposit?.data &&
    !['deposit_paid', 'fully_refunded', 'partially_refunded', 'closed'].includes(
      securityDeposit?.data?.status,
    );

  if (!offerFlow && !cisdFlow) return null;

  if (loading) {
    return null;
  }

  const handleButtonClick = () => {
    if (offerFlow) {
      setOfferModalOpen(true);
    } else if (cisdFlow) {
      setCustomModalOpen(true);
    }
  };

  return (
    <>
      {securityDeposit && (
        <SecurityDepositOfferModal
          securityDeposit={securityDeposit.data}
          open={offerModalOpen}
          onClose={() => {
            setOfferModalOpen(false);
          }}
        />
      )}

      <ULToCreditRequest
        open={customModalOpen}
        toggleModal={() => {
          setCustomModalOpen(false);
        }}
      />

      <MarketingBanner
        nonDismissable
        onClick={handleButtonClick}
        actionText="Continue with Offer"
        bannerKey="security-deposit-offer-banner"
        title="Offer"
        icon={faPartyHorn}
      >
        Congrats! You are qualified for a credit line by making a security deposit.
      </MarketingBanner>
    </>
  );
};

export default SecurityDepositOfferBanner;
