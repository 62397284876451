import useUser from '@app/hooks/useUser';
import React from 'react';
import { useLocation } from 'react-router-dom';
import MarketingBannerItem from './MarketingBannerItem';

const MarketingBannerComponent: React.FC = () => {
  const { user } = useUser();
  const location = useLocation();

  if (!user?.marketing_banners || user.marketing_banners.length === 0) return null;

  return (
    <>
      {user.marketing_banners
        .filter((banner) => banner.location === location.pathname)
        .map((banner) => (
          <MarketingBannerItem key={banner.key} banner={banner} />
        ))}
    </>
  );
};

export default MarketingBannerComponent;
