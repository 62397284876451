import { ReactElement, ReactNode } from 'react';

export const AutoTopUpEnablementWrapper = ({
  enabled,
  children,
}: {
  enabled: boolean;
  children: ReactNode;
}): ReactElement => {
  if (!enabled) {
    return (
      <div className="text-default-primary my-4 text-sm">
        Automatically deposit to your Wallet on a scheduled recurring frequency or when the balance
        is low. Use with a bank account or debit card.
      </div>
    );
  }

  return <>{children}</>;
};
