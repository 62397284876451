import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { AnchorHTMLAttributes } from 'react';

type LinkProps = {
  isExternal?: boolean;
  primary?: boolean;
} & AnchorHTMLAttributes<HTMLAnchorElement>;

export default ({ href, children, onClick, isExternal = false, primary = false }: LinkProps) => {
  const color = primary ? 'text-green' : 'text-default-primary';

  const classes = classNames(
    'inline-flex items-center px-2 py-1 text-sm leading-4 font-medium focus:outline-none cursor-pointer underline hover:opacity-75',
    color,
  );

  return (
    <a href={href} className={classes} onClick={onClick}>
      <span>{children} </span>
      {isExternal && (
        <FontAwesomeIcon
          icon={faArrowUpRightFromSquare}
          className="text-green ml-1 h-4 w-4"
          aria-hidden="true"
        />
      )}
    </a>
  );
};
