import { MobileNavHeaderMenuContext } from '@app/contexts/MobileNavHeaderMenuContextComponent';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import useIsAllowedToFund from '@app/utils/funding/useIsAllowedToFund';
import { Button } from '@mui/material';
import { ReactElement, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import AddFundsModal from './AddFundsModal';
import AddFundsSidebar from './AddFundsSidebar';
import type { AddressType } from '@app/@types/customer.types';

export type AddFundsScenario = 'add_funds' | 'account_setup_fee' | 'security_deposit';

interface AddFundsProps {
  onAddFunds: () => void;
  customerCompanyName: string;
  customerCompanyAddress: AddressType;
  accountNumber?: string | null;
  routingNumber?: string | null;
}

export default function AddFunds({
  onAddFunds,
  accountNumber,
  routingNumber,
  customerCompanyName,
  customerCompanyAddress,
  buttonProps = {},
}: AddFundsProps & {
  buttonProps?: {
    disabled?: boolean;
    text?: string | ReactElement;
    showInMobileHeaderMenu?: boolean;
    className?: string;
    primary?: boolean;
    dark?: boolean;
    fullWidth?: boolean;
    startIcon?: ReactNode;
    size?: 'small' | 'medium';
  };
}): ReactElement {
  const allowedToFund = useIsAllowedToFund();
  const [addFundsModalActive, setAddFundsModalActive] = useState(false);
  const { showInMobileHeaderMenu = true } = buttonProps;
  const menuItem = useMemo(
    () => ({
      value: buttonProps?.text || 'Add money',
      onClick: () => setAddFundsModalActive(true),
      order: 1,
      disabled: () => buttonProps?.disabled || !allowedToFund,
    }),
    [allowedToFund, buttonProps?.disabled, buttonProps?.text],
  );

  const { extendMenuOptions, removeMenuOptions } = useContext(MobileNavHeaderMenuContext);

  useEffect(() => {
    if (showInMobileHeaderMenu) {
      extendMenuOptions(menuItem);
      return () => {
        removeMenuOptions(menuItem);
      };
    }
  }, [
    buttonProps.showInMobileHeaderMenu,
    extendMenuOptions,
    menuItem,
    removeMenuOptions,
    showInMobileHeaderMenu,
  ]);

  return (
    <>
      <Button
        color={
          !buttonProps?.disabled && !buttonProps?.primary && !buttonProps?.dark
            ? 'secondary'
            : 'primary'
        }
        disabled={buttonProps?.disabled || !allowedToFund}
        fullWidth={buttonProps?.fullWidth}
        onClick={() => setAddFundsModalActive(true)}
        className={buttonProps?.className}
        startIcon={buttonProps?.startIcon}
        size={buttonProps?.size}
      >
        {buttonProps?.text || 'Add money'}
      </Button>
      <AddFundsModalDataWrapper
        onAddFunds={onAddFunds}
        accountNumber={accountNumber}
        routingNumber={routingNumber}
        customerCompanyName={customerCompanyName}
        customerCompanyAddress={customerCompanyAddress}
        modalActive={addFundsModalActive}
        setModalActive={setAddFundsModalActive}
        scenario={'add_funds'}
      />
    </>
  );
}

export const AddFundsModalDataWrapper = ({
  onAddFunds,
  customerCompanyName,
  customerCompanyAddress,
  modalActive,
  setModalActive,
  minimumDepositAmountCents,
  initialAmount,
  initialDescription,
  scenario,
  continueOnboarding,
}: AddFundsProps & {
  modalActive: boolean;
  setModalActive: (_: boolean) => void;
  minimumDepositAmountCents?: number;
  initialAmount?: number;
  initialDescription?: string;
  scenario: AddFundsScenario;
  continueOnboarding?: () => void;
}): ReactElement => {
  const [newAddFundsSidebar] = useFeatureFlags('new_add_funds_sidebar');

  return newAddFundsSidebar && scenario === 'add_funds' ? (
    <AddFundsSidebar
      open={modalActive}
      reset={() => setModalActive(false)}
      onAddFunds={onAddFunds}
      minimumDepositAmountCents={minimumDepositAmountCents}
    />
  ) : (
    <AddFundsModal
      open={modalActive}
      reset={() => setModalActive(false)}
      onAddFunds={onAddFunds}
      continueOnboarding={continueOnboarding}
      customerCompanyName={customerCompanyName}
      customerCompanyAddress={customerCompanyAddress}
      minimumDepositAmountCents={minimumDepositAmountCents}
      initialAmount={initialAmount}
      initialDescription={initialDescription}
      scenario={scenario}
    />
  );
};
