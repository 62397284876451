import { EndpointResponse } from '@app/@types/api.types';
import SidebarHeader from '@app/components/Sidebars/SidebarHeader';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import useSWR from 'swr';
import { FeeConfiguration, filterMethodsByTabValue, TransferKind } from '../utils';
import RecipientCard from './RecipientCard';
import { TransferPanel } from './TransferPanel';
import useTransferDestinations from './useTransferDestinations';
import { useWalletTransfer } from './useWalletTransfer';

export default function TransferDetailsForm() {
  const { resetAndClose, onCompletedTransfer, walletBalance, selectedRecipient } =
    useWalletTransfer();

  const transferKind = (
    selectedRecipient?.customer_owned ? 'own_transfer' : 'external_transfer'
  ) as TransferKind;
  const {
    normalizedExternalDestinations,
    normalizedOwnDestinations,
    loadingInstantPayoutRecipient,
    loadingOwnDestinations,
  } = useTransferDestinations();

  const { data: transferMethods, isLoading: loadingMethods } = useSWR<
    EndpointResponse<FeeConfiguration[]>
  >({ url: `/treasury/financial_account/all_fees` }, apiGetFetcher);

  const outboundTransferMethods = transferMethods?.data?.filter(
    (method) => !method.direction || method.direction === 'outbound',
  );

  return (
    <>
      <SidebarHeader title="Send money" onClose={resetAndClose} />
      <div className="flex flex-col gap-y-4 p-6 md:px-8 md:pb-8 md:pt-0">
        {selectedRecipient && <RecipientCard recipient={selectedRecipient} />}
        {transferKind === 'external_transfer' && (
          <TransferPanel
            availableMethods={filterMethodsByTabValue(outboundTransferMethods || [], transferKind)}
            availableDestinations={normalizedExternalDestinations}
            loadingMethods={loadingMethods}
            loadingDestinations={loadingInstantPayoutRecipient}
            reset={resetAndClose}
            onCompletedTransfer={onCompletedTransfer}
            walletBalance={walletBalance}
            transferKind="external_transfer"
          />
        )}
        {transferKind === 'own_transfer' && (
          <TransferPanel
            availableMethods={filterMethodsByTabValue(outboundTransferMethods || [], transferKind)}
            availableDestinations={normalizedOwnDestinations}
            loadingMethods={loadingMethods}
            loadingDestinations={loadingInstantPayoutRecipient || loadingOwnDestinations}
            reset={resetAndClose}
            onCompletedTransfer={onCompletedTransfer}
            walletBalance={walletBalance}
            transferKind="own_transfer"
          />
        )}
      </div>
    </>
  );
}
