type DetailsItemProps = {
  label: string;
  value: string | React.ReactNode;
  closed?: boolean;
};

export default function DetailsItem({ label, value, closed = true }: DetailsItemProps) {
  return (
    <div className="flex w-full items-center justify-between gap-1">
      <span className="text-default-secondary flex-1 text-xs leading-5 md:text-sm">{label}</span>
      <span className="text-default-primary flex-1 text-xs font-semibold leading-5 md:text-sm">
        {closed ? value : 'Pending'}
      </span>
    </div>
  );
}
