import { BankAccountPaymentMethodCombined } from '@app/@types/bankAccount.types';
import { faCheck, faPen } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';

type Props = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  openPlaidModal: Function;
  isPlaidReady: boolean;
  bankAccount: BankAccountPaymentMethodCombined;
};

export default function ConnectedBankCard({ openPlaidModal, isPlaidReady, bankAccount }: Props) {
  return (
    <div className="bg-soft-primary border-soft flex w-full flex-col items-center gap-6 rounded-2xl border p-6 md:p-8">
      <div className="bg-green-secondary flex items-center justify-center rounded-full p-3">
        <FontAwesomeIcon icon={faCheck} className="text-green h-6 w-6" />
      </div>
      <div className="flex flex-col items-center gap-1">
        <p className="flex gap-3 font-semibold md:text-lg">Account connected</p>
        <p>
          {bankAccount.institution_name} **** {bankAccount.mask}
        </p>
      </div>
      <LoadingButton
        onClick={() => openPlaidModal()}
        size="medium"
        color="secondary"
        loading={!isPlaidReady}
        startIcon={<FontAwesomeIcon icon={faPen} />}
        fullWidth
      >
        Edit payment source
      </LoadingButton>
    </div>
  );
}
