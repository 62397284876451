import { EndpointResponse } from '@app/@types/api.types';
import { CustomerData } from '@app/@types/customer.types';
import { CarrierSelector } from '@app/components/Partnerships/CarrierSelector/CarrierSelector';
import SidebarHeader from '@app/components/Sidebars/SidebarHeader';
import { CUSTOMER_QUERY_KEY } from '@app/hooks/query/useCustomerQuery';
import { DescriptionInput } from '@app/pages/Wallet/WalletOverview/TransferFunds/Inputs';
import {
  formatAmountString,
  moneyFormat,
  parseAmountString,
} from '@app/pages/Wallet/WalletOverview/TransferFunds/utils';
import { apiGetFetcher, apiPostFetcher } from '@app/utils/data/fetchers';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { faArrowUp, faDollarSign } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { Drawer, InputAdornment, TextField } from '@mui/material';
import axios from 'axios';
import { SetStateAction, useState } from 'react';
import useSWR, { mutate } from 'swr';
import useSWRMutation from 'swr/mutation';
import { Carrier } from '../types';
import { ConfirmationModal } from './Modals/ConfirmationModal';
import { SuccessModal } from './Modals/SuccessModal';

type RepaymentState = 'initial' | 'confirmation' | 'success';

export default function SingleRepaymentSidebar({
  open,
  reset,
  onSuccess,
}: {
  open: boolean;
  reset: () => void;
  onSuccess?: () => void;
}) {
  const [carrier, setCarrier] = useState<Carrier | null>(null);
  const [amount, setAmount] = useState('');
  const [referenceId, setReferenceId] = useState('');
  const [description, setDescription] = useState('');
  const [validAmount, setValidAmount] = useState(true);
  const [repaymentState, setRepaymentState] = useState<RepaymentState>('initial');

  const { addToast } = useToasts();

  const handleCancel = (): void => {
    reset();
    setCarrier(null);
    setDescription('');
    setAmount('');
    setReferenceId('');
    setRepaymentState('initial');
  };

  const { isMutating: isLoading, trigger } = useSWRMutation(
    {
      url: `partners/repayments`,
    },
    apiPostFetcher,
    {
      onError: (e) => {
        if (axios.isAxiosError(e)) {
          addToast({ type: 'error', title: e.message });
        }
      },
      onSuccess: () => {
        setRepaymentState('success');
        mutate(CUSTOMER_QUERY_KEY);
        onSuccess?.();
      },
    },
  );

  const { data: customerData } = useSWR<EndpointResponse<CustomerData>>(
    carrier?.id ? `/partners/customers/${carrier.id}?include_account_balance=true` : null,
    apiGetFetcher,
  );

  const onSubmit = () => {
    if (repaymentState === 'initial') {
      setRepaymentState('confirmation');
      return;
    }
    trigger({
      customer_id: carrier?.id,
      amount_cents: parseAmountString(amount) * 100,
      reference_id: referenceId,
      description,
    });
  };

  return (
    <Drawer open={open} onClose={handleCancel} anchor="right">
      <div className="flex h-screen w-screen flex-col overflow-y-auto md:max-w-xl">
        <SidebarHeader title="Single Repayment" onClose={handleCancel} />
        <>
          <div className="mb-[72px] flex-1">
            <div className="mb-12 flex flex-col gap-6 px-6 md:px-8">
              <CarrierSelector carrier={carrier} setCarrier={setCarrier} />
              <AmountInput
                amount={amount}
                setAmount={setAmount}
                setValidAmount={setValidAmount}
                balance={
                  (customerData?.data?.account_balance?.cents &&
                    moneyFormat(customerData.data.account_balance.cents / 100)) ||
                  null
                }
              />
              <ReferenceIdInput referenceId={referenceId} setReferenceId={setReferenceId} />
              <DescriptionInput description={description} setDescription={setDescription} />
            </div>
          </div>
          <div className="bg-soft-primary absolute bottom-0 left-0 right-0 w-full overflow-hidden">
            <div className="flex gap-3 px-6 pb-8 pt-4 md:px-8">
              <LoadingButton
                fullWidth
                onClick={onSubmit}
                startIcon={<FontAwesomeIcon icon={faArrowUp} />}
                disabled={!carrier || !referenceId || !amount || !validAmount}
                loading={isLoading}
              >
                Submit Balance Payment
              </LoadingButton>
            </div>
          </div>
        </>
        {repaymentState === 'confirmation' && carrier !== null && (
          <ConfirmationModal
            open
            onClose={() => setRepaymentState('initial')}
            onConfirm={onSubmit}
            loading={isLoading}
            amount={amount}
            description={description}
            recipientName={carrier?.company_name}
            referenceId={referenceId}
          />
        )}
        {repaymentState === 'success' && carrier !== null && (
          <SuccessModal
            open
            onClose={handleCancel}
            amount={amount}
            description={description}
            recipientName={carrier?.company_name}
            referenceId={referenceId}
          />
        )}
      </div>
    </Drawer>
  );
}

const ReferenceIdInput = ({
  referenceId,
  setReferenceId,
}: {
  referenceId: string;
  setReferenceId: (value: string) => void;
}) => {
  return (
    <TextField
      label="Reference ID"
      variant="outlined"
      fullWidth
      onChange={(e) => setReferenceId(e.target.value)}
      value={referenceId}
      placeholder="Enter Reference ID"
    />
  );
};

const AmountInput = ({
  amount,
  setAmount,
  balance,
  setValidAmount,
}: {
  amount: string;
  setAmount: (value: SetStateAction<string>) => void;
  balance: string | null;
  setValidAmount: (value: SetStateAction<boolean>) => void;
}) => {
  const balanceAmount = parseAmountString(balance || '0');
  const requestedAmount = parseAmountString(amount);

  const invalidAmount = balanceAmount < requestedAmount;

  return (
    <div>
      <h4 className="mb-2 font-medium">Amount</h4>
      <div className="flex flex-col gap-2">
        <TextField
          error={invalidAmount}
          onChange={(e) => {
            const formattedAmount = formatAmountString(e.target.value);
            setValidAmount(parseAmountString(formattedAmount) <= balanceAmount);
            setAmount(formattedAmount);
          }}
          value={amount}
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faDollarSign} />
                </InputAdornment>
              ),
            },
          }}
          placeholder="0.00"
          helperText={
            invalidAmount &&
            'This amount exceeds the maximum transferable amount. Please pick a lower amount.'
          }
        />
        {balance && (
          <div className="text-default-secondary text-sm">
            Current balance due:{' '}
            <span
              className="cursor-pointer underline"
              onClick={() => setAmount(balanceAmount.toString())}
            >
              {moneyFormat(balanceAmount)}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};
