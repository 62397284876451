import * as Yup from 'yup';

export const addressValidationSchema = Yup.object({
  address1: Yup.string().required('Address is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  zip: Yup.string()
    .required('ZIP Code is required')
    .matches(/^\d{5}$/, 'ZIP Code must be exactly 5 digits'),
});
