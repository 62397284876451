import useCustomerOnboardingEvents, {
  CustomerOnboardingEventName,
  useCreateCustomerOnboardingEvent,
} from '@app/hooks/query/useCustomerOnboardingEvents';
import useThemeMode from '@app/hooks/useThemeMode';
import { useHasCompletedOnboarding } from '@app/utils/onboarding/useHasCompletedOnboarding';
import { faChevronRight, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  CircularProgress,
  Step,
  StepButton,
  Stepper,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import classNames from 'classnames';
import { ReactElement } from 'react';
import tw from 'twin.macro';

type Step = {
  title: string;
  onClick: () => void;
  customerOnboardingEvent: CustomerOnboardingEventName;
};

const CONTAINER_TAILWIND =
  'bg-soft-primary bg-onboarding-bg-mobile md:bg-onboarding-bg-desktop ufgrayscale:rounded-none flex flex-col overflow-hidden rounded-lg bg-[length:auto_100%] bg-right bg-no-repeat px-6 py-8 items-start';

export default function OnboardingStepper({
  stepGroups,
  completedPartnerText,
}: {
  stepGroups: Step[][];
  completedPartnerText: string;
}): ReactElement | null {
  const onboardingEvents = useCustomerOnboardingEvents();
  const { darkClassName } = useThemeMode();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { trigger: createCustomerOnboardingEvent, isMutating } = useCreateCustomerOnboardingEvent();
  const hasCompletedOnboarding = useHasCompletedOnboarding();

  if (hasCompletedOnboarding) {
    return null;
  }

  // All onboarding events have already occurred so we don't need to show
  // the stepper
  if (stepGroups.flat().every((step) => onboardingEvents.has(step.customerOnboardingEvent))) {
    return (
      <div className={classNames(darkClassName, CONTAINER_TAILWIND)}>
        <div className="flex flex-col items-start gap-2 md:flex-row md:items-center">
          <div className="bg-success-primary flex h-9 w-9 items-center justify-center rounded-full">
            <FontAwesomeIcon className="text-contrast-primary" icon={faCheck} />
          </div>
          <div className="text-default-primary text-xl font-semibold">
            You're on your way to scoring big discounts!
          </div>
        </div>
        <div className="text-default-primary pb-4 pt-3 text-sm font-medium md:pl-12">
          Keep using your {completedPartnerText} to rack up the savings.
        </div>
        <Button
          startIcon={isMutating && <CircularProgress size={20} />}
          className="md:ml-12"
          onClick={() =>
            !isMutating &&
            createCustomerOnboardingEvent({
              customer_onboarding_event: {
                name: CustomerOnboardingEventName.CUSTOMER_ONBOARDING_COMPLETED,
              },
            })
          }
        >
          Got it
        </Button>
      </div>
    );
  }

  const existingCustomerOnboardingEvent = (
    customerOnboardingEventName: CustomerOnboardingEventName,
  ): boolean => {
    return onboardingEvents.has(customerOnboardingEventName);
  };

  let indexTracker = 0;
  return (
    <div className={classNames(darkClassName, CONTAINER_TAILWIND, 'gap-6')}>
      <div className="text-default-primary text-lg font-semibold">
        Get mega discounts in just a few steps!
      </div>

      <Stepper
        connector={null}
        nonLinear
        orientation={isMobile ? 'vertical' : 'horizontal'}
        activeStep={-1}
        className="w-full items-stretch md:w-auto md:min-w-[60%]"
      >
        {stepGroups.map((group, idx) => (
          <div
            key={idx}
            className={classNames(
              'flex flex-col justify-center overflow-hidden md:min-h-[7rem] md:flex-1',
              idx === 0 &&
                stepGroups.length > 1 &&
                'ufgrayscale:rounded-none border-soft rounded-t-lg border border-opacity-25 md:rounded-e-none md:rounded-s-lg',
              idx > 0 &&
                stepGroups.length > 1 &&
                'ufgrayscale:rounded-none border-soft border-x border-b border-opacity-25 md:border-l-0 md:border-t',
              idx === stepGroups.length - 1 &&
                stepGroups.length > 1 &&
                'rounded-b-lg md:rounded-e-lg md:rounded-s-none',
              stepGroups.length === 1 &&
                'ufgrayscale:rounded-none border-soft rounded-lg border border-opacity-25 md:rounded-lg md:rounded-e-lg md:rounded-s-lg',
            )}
          >
            {group.map(({ title: label, ...metadata }, innerIdx) => (
              <Step
                className={classNames(
                  'md:hover:bg-soft-primary-hover flex flex-1 items-stretch py-2 pr-3 md:hover:bg-opacity-5',
                  innerIdx < group.length - 1 && 'border-soft border-b border-opacity-25 ',
                )}
                index={indexTracker++}
                completed={existingCustomerOnboardingEvent(metadata.customerOnboardingEvent)}
                key={label}
                role="button"
                onClick={metadata.onClick}
              >
                <StepButton
                  className="m-0 justify-between px-4 py-0"
                  sx={{
                    '.MuiStepButton-root': {
                      padding: 0,
                    },
                    '.MuiStepIcon-root': {
                      ...(existingCustomerOnboardingEvent(metadata.customerOnboardingEvent)
                        ? {}
                        : { ...tw`border-soft` }),
                      // This causes an MUI error to be thrown but it's
                      // not a problem. There's a bug MUI is aware of
                      // It doesn't hurt aything though.
                      borderRadius: '100%',
                    },
                    '.Mui-completed svg': {
                      ...tw`!text-contrast-primary`,
                    },
                    '.MuiStepLabel-iconContainer.Mui-completed': {
                      ...tw`p-0.5 mr-2 bg-success-primary`,
                      borderRadius: '100%',
                    },
                  }}
                >
                  <div className="flex items-center justify-center">
                    <div className="text-default-primary flex pr-2">{label}</div>
                  </div>
                </StepButton>
                <div className="ml-2 flex flex-1 items-center justify-center">
                  <FontAwesomeIcon className="text-default-primary" icon={faChevronRight} />
                </div>
              </Step>
            ))}
          </div>
        ))}
      </Stepper>
    </div>
  );
}
