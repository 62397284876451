import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ErrorMessage = ({ error }: { error?: string }) => {
  if (!error) return null;

  return (
    <p className="text-error p-1 text-xs sm:text-sm">
      <FontAwesomeIcon icon={faExclamationCircle} className="text-error mr-1" />
      {error}
    </p>
  );
};
export default ErrorMessage;
