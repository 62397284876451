import useCustomer from '@app/hooks/useCustomer';
import useWindowWidth from '@app/hooks/useWindowWidth';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';

const BusinessCreditReportingLearnMoreModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}): ReactElement | null => {
  const { isSmallScreen } = useWindowWidth();
  const { t } = useTranslation();
  const customer = useCustomer();
  const closeIcon = (
    <button
      type="button"
      className="bg-soft-secondary absolute right-4 top-4 flex h-8 w-8 cursor-pointer items-center justify-center rounded-full focus:outline-none"
      onClick={onClose}
    >
      <FontAwesomeIcon icon={faXmark} size="1x" color="#676D7C" />
    </button>
  );

  return (
    <Dialog open={open} onClose={onClose} fullScreen={isSmallScreen}>
      <div className="bg-soft-secondary flex flex-row items-center bg-opacity-5">
        <img
          src="/images/credit-reporting-experian.svg"
          alt="popup-header"
          className="mx-auto h-full"
        />
        <div className="absolute right-0 top-0">{closeIcon}</div>
      </div>

      <DialogTitle>
        <span className="text-default-primary text-xl font-bold md:text-2xl">
          Business Credit Reporting
        </span>
      </DialogTitle>
      <DialogContent>
        <div className="mt-4">
          <h4 className="text-default-primary font-semibold">Glossary</h4>
          <p className="text-default-primary mt-2 text-sm">
            <span className="font-semibold">Terms</span> &ndash;{' '}
            {t('settings.business_credit_reporting.modal.terms.content')}
          </p>
          <p className="text-default-primary mt-2 text-sm">
            <span className="font-semibold">Days Beyond Terms Percentage</span> &ndash;{' '}
            {t('settings.business_credit_reporting.modal.days_beyond_terms_percentage.content')}
          </p>
          <p className="text-default-primary mt-2 text-sm">
            <span className="font-semibold">Account Balance</span> &ndash; This is the account
            balance at the day of reporting which is overdue.
          </p>
        </div>
        <div className="mt-8">
          <h4 className="font-semibold">FAQs</h4>
          <div className="mt-2 text-sm">
            <p className="font-semibold">
              <Trans i18nKey="settings.business_credit_reporting.modal.tradeline.title" t={t} />
            </p>
            <p className="mt-2">
              <Trans
                i18nKey="settings.business_credit_reporting.modal.tradeline.content"
                t={t}
                components={[
                  <a href={`mailto:${t('support_email')}`} className="underline" key="0"></a>,
                ]}
              />
            </p>
          </div>
          {customer.activated_at && new Date(customer.activated_at).getFullYear() < 2023 && (
            <div className="text-default-primary mt-4 text-sm">
              <p className="font-semibold">
                How can I see what AtoB reported for my account before 2023?
              </p>
              <p className="mt-2">
                Reach out to{' '}
                <a href="mailto:support@atob.com" className="underline">
                  support@atob.com
                </a>{' '}
                if you had a transacting account with AtoB before 2023 to ask for all history of
                reporting on your account.
              </p>
            </div>
          )}
          <div className="text-default-primary mt-4 text-sm">
            <p className="font-semibold">How do I improve my business credit score?</p>
            <p className="mt-2">
              Read our blog article on a few tips{' '}
              <a
                href={t('settings.business_credit_reporting.modal.improve_score.blog_post_url')}
                className="underline"
              >
                blog article
              </a>{' '}
              on a few tips
            </p>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default BusinessCreditReportingLearnMoreModal;
