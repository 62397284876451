import TourStep from '@app/components/Tour/TourStep';
import { StepType, useTour } from '@reactour/tour';
import { useCallback, useEffect, useMemo } from 'react';
import useCustomerOnboardingEvents, {
  CustomerOnboardingEventName,
  useCreateCustomerOnboardingEvent,
} from '../query/useCustomerOnboardingEvents';
import useProduct from '../useProduct';

export default function useWalletOnlyCustomerOnboarding(isWalletLoading: boolean) {
  const [walletLite, walletTransferFunds] = useProduct('wallet_lite', 'wallet_transfer_funds');
  const customerOnboardingEvents = useCustomerOnboardingEvents();
  const { trigger: createOnboardingEvent } = useCreateCustomerOnboardingEvent();
  const {
    steps: tourSteps,
    currentStep,
    setIsOpen,
    setSteps,
    setCurrentStep,
    onClickClose,
  } = useTour();

  const shouldShowWalletOnlyOnboarding =
    walletLite &&
    !isWalletLoading &&
    !customerOnboardingEvents.has(CustomerOnboardingEventName.CUSTOMER_SKIP_WALLET) &&
    !customerOnboardingEvents.has(CustomerOnboardingEventName.CUSTOMER_WALLET_COMPLETED);

  const isLastStep = currentStep === tourSteps.length - 1;

  const onClick = useCallback(() => {
    if (isLastStep) {
      createOnboardingEvent({
        customer_onboarding_event: { name: CustomerOnboardingEventName.CUSTOMER_WALLET_COMPLETED },
      });
      onClickClose?.({ currentStep, setCurrentStep, setIsOpen });
    }

    setCurrentStep((step) => step + 1);
  }, [createOnboardingEvent, currentStep, isLastStep, onClickClose, setCurrentStep, setIsOpen]);

  const steps: StepType[] = useMemo(
    () =>
      [
        {
          selector: "[data-tour='wallet_balance']",
          content: (
            <TourStep
              title="See your available funds"
              description="Funds are always displayed at the top of Wallet"
              onClick={onClick}
            />
          ),
        },
        {
          selector: "[data-tour='wallet_transactions']",
          content: (
            <TourStep
              title="Transactions live here"
              description="See a history of funds in and out of your Wallet"
              onClick={onClick}
            />
          ),
        },
        ...(walletTransferFunds
          ? [
              {
                selector: "[data-tour='wallet_send_and_withdraw']",
                content: (
                  <TourStep
                    title="Send money"
                    description="Click here to send funds to yourself or anyone with multiple instant pay options"
                    onClick={onClick}
                  />
                ),
              },
            ]
          : []),
      ] as StepType[],
    [walletTransferFunds, onClick],
  );

  useEffect(() => {
    if (!shouldShowWalletOnlyOnboarding) {
      return;
    }

    setSteps?.(steps);
    setIsOpen(true);
  }, [shouldShowWalletOnlyOnboarding, steps, setSteps, setIsOpen]);

  return null;
}
