import useProduct from '@app/hooks/useProduct';
import RadioGroup from '@atob-developers/shared/src/components/RadioButton';
import { Button } from '@mui/material';
import { ReactElement, useState } from 'react';

const OtpOptions = ({
  email,
  phone,
  selectedOption,
  setSelectedOption,
  onSave,
  onCancel,
}: {
  email?: string;
  phone?: string;
  selectedOption: 'Email' | 'SMS';
  setSelectedOption: (option: 'Email' | 'SMS') => void;
  onSave: (option?: string) => void;
  onCancel: () => void;
}): ReactElement => {
  const [newOption, setNewOption] = useState(selectedOption);
  const [walletLite] = useProduct('wallet_lite');
  const options = [];
  if (email && !walletLite) {
    options.push({
      label: `Email ${email}`,
      name: 'Email',
      value: 'Email',
      id: 'Email',
      checked: newOption === 'Email',
    });
  }
  if (phone) {
    options.push({
      label: `Send SMS to ${phone}`,
      name: 'SMS',
      value: 'SMS',
      id: 'SMS',
      checked: newOption === 'SMS',
    });
  }

  return (
    <>
      <div className="flex">
        <RadioGroup
          data={options}
          onSelect={(id: unknown) => {
            setNewOption(id as 'Email' | 'SMS');
          }}
        />
      </div>
      <div className="flex w-full justify-end gap-2">
        <Button onClick={onCancel} color="secondary" size="small">
          Cancel
        </Button>
        <Button
          onClick={() => {
            setSelectedOption(newOption);
            onSave(newOption);
          }}
          size="small"
        >
          Resend
        </Button>
      </div>
    </>
  );
};

export default OtpOptions;
