import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import SpinnerBoundary from '@app/components/Spinner/SpinnerBoundary';
import { Cell } from '@app/components/TableV2/Cell';
import { MobileListItem } from '@app/components/TableV2/MobileListItem';
import ResponsiveTable from '@app/components/TableV2/ResponsiveTable';
import { ColumnDefinition } from '@app/components/TableV2/Table';
import { Button } from '@mui/material';
import { ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const COLUMNS = [
  {
    field: 'state',
    headerName: 'State',
    minWidth: 150,
    renderCell: (params) => <Cell>{params.row.state}</Cell>,
  },
  {
    field: 'total_miles',
    headerName: 'Total Miles (B)',
    minWidth: 150,
    renderCell: (params) => (
      <Cell>
        <div className="flex">
          <div className="flex items-center justify-center text-center">
            {params.row.total_miles}
          </div>
        </div>
      </Cell>
    ),
  },
  {
    field: 'taxable_miles',
    headerName: 'Taxable Miles (C)',
    minWidth: 150,
    renderCell: (params) => <Cell>{params.row.total_miles}</Cell>,
  },
  {
    field: 'taxable_gallons',
    headerName: 'Taxable Gallons (D) (C/A)',
    minWidth: 250,
    renderCell: (params) => <Cell>{params.row.taxable_fuel}</Cell>,
  },
  {
    field: 'tax_paid_gallons',
    headerName: 'Tax Paid Gallons (E)',
    minWidth: 200,
    renderCell: (params) => <Cell>{params.row.fuel_purchased}</Cell>,
  },
  {
    field: 'net_taxable_gallons',
    headerName: 'Net Taxable Gallons (H) (D-E)',
    minWidth: 250,
    renderCell: (params) => <Cell>{params.row.net_fuel}</Cell>,
  },
  {
    field: 'tax_rate',
    headerName: 'Tax Rate (K)',
    minWidth: 150,
    renderCell: (params) => <Cell>{params.row.tax}</Cell>,
  },
  {
    field: 'tax_due_or_credit',
    headerName: 'Tax Due or Credit (L)(H * K)',
    minWidth: 250,
    renderCell: (params) => <Cell>{(params.row.tax * params.row.net_fuel).toFixed(4)}</Cell>,
  },
] as ColumnDefinition[];

// Define a type for the state object
interface StateData {
  id: number;
  state: string;
  total_miles: number;
  taxable_miles?: number;
  fuel_purchased: number;
  taxable_fuel: number;
  net_fuel: number;
  tax: number;
  interest_due?: number; // Optional property
  total_due?: number; // Optional property
}

// Define a type for the location state
interface LocationState {
  total_miles?: number; // Optional property
  total_fuel_purchased?: number; // Optional property
  total_taxable_fuel?: number; // Optional property
  net_taxable_fuel?: number; // Optional property
  tax?: number; // Optional property
  state_wise?: StateData[]; // Optional property
}

const MobileVehicleItemRenderer = ({
  row,
  onClick,
}: {
  row: StateData; // Use the defined type
  onClick?: () => void;
}) => (
  <MobileListItem onClick={onClick}>
    <div className="font-bold">
      <Cell>{row.id}</Cell>
    </div>
    <div>{row.state}</div>
  </MobileListItem>
);
const DownloadIftaReport = (): ReactElement => {
  const [isLoading, setIsLoading] = useState(false);
  const [stateRows, setStateRows] = useState<StateData[]>([]);
  const { state } = useLocation();
  const iftaReportRowData = state as LocationState;

  const handleDownload = () => {
    setIsLoading(true);
    const headers = [
      'State',
      'Total Miles (B)',
      'Taxable Miles (C)',
      'Taxable Gallons (D) (C/A)',
      'Tax Paid Gallons (E)',
      'Net Taxable Gallons (H) (D-E)',
      'Tax Rate (K)',
      'Tax Due or Credit (L)(H * K)',
    ];
    const data = stateRows.map((row) => [
      row.state,
      row.total_miles || 0, // Default to 0 if undefined
      row.taxable_miles || 0, // Default to 0 if undefined
      row.fuel_purchased || 0, // Default to 0 if undefined
      row.taxable_fuel || 0, // Default to 0 if undefined
      row.net_fuel || 0, // Default to 0 if undefined
      row.tax || 0, // Default to 0 if undefined
      (row.tax * (row.net_fuel || 0)).toFixed(4), // Default to 0 if undefined
    ]);

    let csvContent = 'data:text/csv;charset=utf-8,';
    csvContent += headers.join(',') + '\n';
    csvContent += data.map((row) => row.join(',')).join('\n');

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'table_data.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setIsLoading(false);
  };
  useEffect(() => {
    const TOTAL_COLUMN: StateData = {
      id: 10,
      total_miles: 0,
      fuel_purchased: 0,
      taxable_fuel: 0,
      net_fuel: 0,
      tax: 0,
      state: 'Total',
    };
    TOTAL_COLUMN.total_miles = iftaReportRowData?.total_miles || 0;
    TOTAL_COLUMN.fuel_purchased = iftaReportRowData?.total_fuel_purchased || 0;
    TOTAL_COLUMN.taxable_fuel = iftaReportRowData?.total_taxable_fuel || 0;
    TOTAL_COLUMN.net_fuel = iftaReportRowData?.net_taxable_fuel || 0;
    TOTAL_COLUMN.tax = iftaReportRowData?.tax || 0;

    setStateRows([...(iftaReportRowData?.state_wise || []), TOTAL_COLUMN]);
  }, [iftaReportRowData]);

  return (
    <>
      <RoundedCard className="relative py-4">
        {isLoading && <SpinnerBoundary />}
        <div className="flex flex-col justify-between px-4 md:flex-row md:items-center ">
          <div className="px-4 text-sm font-semibold md:px-6">
            <p>Your IFTA Report for JFM'24 is ready! </p>
          </div>
          <div className="h-fit w-full shrink-0 sm:w-fit">
            <Button
              className="w-full sm:w-auto"
              size="small"
              onClick={handleDownload}
              data-testid={'start-ifta-filing-button'}
            >
              Download Report
            </Button>
          </div>
        </div>
      </RoundedCard>
      <RoundedCard className="relative flex-1">
        <ResponsiveTable
          columns={COLUMNS}
          data={stateRows || []}
          loading={isLoading}
          mobileItemRenderer={MobileVehicleItemRenderer}
        />
      </RoundedCard>
    </>
  );
};

export default DownloadIftaReport;
