import { ReactElement } from 'react';
import RoundedCard from '../RoundedCard/RoundedCard';

export default function Skeleton(): ReactElement {
  return (
    <RoundedCard className="p-4 pb-12">
      <div
        data-testid="skeleton"
        className="justify-left m-3 flex h-full animate-pulse flex-row items-center"
      >
        <div className="w-full grow flex-col space-y-4">
          <div className="bg-strong-secondary h-6 flex-1 rounded-sm" />
          <div className="bg-strong-secondary h-6 rounded-sm" />
          <div className="bg-strong-secondary h-6 rounded-sm" />
          <div className="bg-strong-secondary h-6 w-6/12 rounded-sm" />
        </div>
      </div>
    </RoundedCard>
  );
}
