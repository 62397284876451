import FooterActionButtons from '@app/components/Onboarding/FooterActionButtons';
import Header from '@app/components/Onboarding/Header';
import { mapBankAndDebitCardData } from '@app/components/PaymentMethods/PaymentMethodUtils';
import {
  CustomerOnboardingEventName,
  useCreateCustomerOnboardingEvent,
} from '@app/hooks/query/useCustomerOnboardingEvents';
import usePlaidForOnboarding from '@app/utils/onboarding/usePlaidForOnboarding';
import Alert from '@atob-developers/shared/src/components/Alert';
import { faClockRotateLeft } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider } from '@mui/material';
import { useMemo } from 'react';
import ConnectWithPlaidCard from '../ConnectWithPlaidCard';
import ConnectedBankCard from '../ConnectedBankCard';
import IssuesDisclaimer from '../IssuesDisclaimer';
import { useOnboardingGuide } from '../useOnboardingGuide';

export default function OnboardingConnectBankAccount() {
  const { goToPrevStep, goToNextStep } = useOnboardingGuide();
  const { isPlaidReady, openPlaidModal, paymentMethods } = usePlaidForOnboarding();
  const { trigger: createCustomerOnboardingEvent } = useCreateCustomerOnboardingEvent();

  const { combinedPaymentMethods } = useMemo(
    () => mapBankAndDebitCardData(paymentMethods?.data || []),
    [paymentMethods],
  );

  const bankAccount = useMemo(
    () => combinedPaymentMethods.bankAccounts[0],
    [combinedPaymentMethods],
  );

  const handleClickNext = async () => {
    await createCustomerOnboardingEvent({
      customer_onboarding_event: {
        name: CustomerOnboardingEventName.CUSTOMER_ONBOARDING_ACTIVATION_ACKNOWLEDGED,
      },
    });
    goToNextStep();
  };

  return (
    <div className="flex flex-1 flex-col gap-6 md:gap-8">
      <Header title="Connect a bank account" />
      {bankAccount ? (
        <ConnectedBankCard
          openPlaidModal={openPlaidModal}
          isPlaidReady={isPlaidReady}
          bankAccount={bankAccount}
        />
      ) : (
        <ConnectWithPlaidCard
          openPlaidModal={openPlaidModal}
          isPlaidReady={isPlaidReady}
          bankAccount={bankAccount}
        />
      )}
      <Alert
        className="w-full"
        type="info"
        icon={<FontAwesomeIcon icon={faClockRotateLeft} className="h-5 w-5" />}
        title="Payments are made automatically"
        description="Your payment is made every Monday, but you can also make a manual payment at any time."
      />
      <Divider flexItem />
      <IssuesDisclaimer />
      <FooterActionButtons
        handleClickBack={goToPrevStep}
        handleClickNext={handleClickNext}
        isDisabled={!isPlaidReady || !bankAccount}
      />
    </div>
  );
}
