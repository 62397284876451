import { faDollarSign } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { Dispatch, SetStateAction } from 'react';

type Props = {
  setAccountSetupFeeModalActive: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
};

export default function PaySetUpFeeCard({ setAccountSetupFeeModalActive, isLoading }: Props) {
  return (
    <div className="border-soft bg-soft-primary flex w-full flex-col items-center gap-6 rounded-2xl border p-6">
      <div className="flex flex-col items-center gap-4">
        <div className="bg-strong-secondary flex items-center justify-center rounded-full p-3">
          <FontAwesomeIcon icon={faDollarSign} className="text-default-primary h-6 w-6" />
        </div>
        <div className="flex flex-col items-center gap-1">
          <p className="text-default-primary text-center font-semibold md:text-lg md:leading-6">
            Pay a set-up fee
          </p>
          <p className="text-default-secondary text-center text-sm leading-6 md:text-base">
            Pay $35 to your Wallet using your Debit Card or Bank Transfer.
          </p>
        </div>
      </div>
      <LoadingButton
        onClick={() => {
          setAccountSetupFeeModalActive(true);
        }}
        size="medium"
        loading={isLoading}
        fullWidth
      >
        Pay a set-up fee
      </LoadingButton>
    </div>
  );
}
