import { PaginatedEndpointResponse } from '@app/@types/api.types';
import Avatar from '@app/components/Avatar/Avatar';
import { FilterDropdownInfiniteList } from '@app/components/Filters/FilterDropdownInfiniteList';
import { Carrier } from '@app/pages/Partnerships/types';
import { Chip, MenuItem } from '@mui/material';
import { useCallback } from 'react';
import { SWRInfiniteKeyLoader } from 'swr/infinite';

export const CarrierChip = ({ name, number }: { name: string; number: string | number | null }) => {
  return (
    <Chip
      color="grey"
      size="small"
      label={
        <span>
          {name} {number || '-'}
        </span>
      }
    />
  );
};

export const CarrierSelector = ({
  carrier,
  setCarrier,
  renderListItem,
  renderValue,
}: {
  carrier: Carrier | null;
  setCarrier: (value: Carrier | null) => void;
  renderListItem?: (item: Carrier) => React.ReactNode;
  renderValue?: () => React.ReactNode;
}) => {
  const selectedIds = carrier ? [carrier.id.toString()] : [];

  const keyFunc: (
    searchString: string,
  ) => SWRInfiniteKeyLoader<PaginatedEndpointResponse<Carrier>> = useCallback(
    (searchString) => (index, previousPage) => {
      if (
        previousPage &&
        previousPage.meta?.pagination.current_page == previousPage.meta?.pagination.total_pages
      ) {
        return null;
      }
      return {
        url: '/partners/customers',
        params: {
          page: index + 1,
          per: 10,
          query: searchString,
          all: false,
        },
      };
    },
    [],
  );

  const defaultRenderListItem = (item: Carrier) => {
    return (
      <MenuItem key={item.id} value={item.id} onClick={() => setCarrier(item)}>
        <div className="align-center flex gap-2">
          <span className="text-default-secondary self-center text-sm font-medium">
            {item.company_name}
          </span>
          <CarrierChip name="ID" number={item.id} />
          <CarrierChip name="DOT" number={item.dot_number} />
        </div>
      </MenuItem>
    );
  };

  const defaultRenderValue = () => {
    if (carrier === null) return <div>Select a carrier</div>;
    return (
      <div className="flex gap-3">
        <Avatar
          initials={carrier?.company_name
            .split(/\s+/)
            .map((w) => w[0].toUpperCase())
            .join('')
            .slice(0, 2)}
          size="lg"
        />
        <div className="flex flex-col gap-2">
          <p className="text-default-secondary text-sm font-medium">{carrier?.company_name}</p>
          <div className="flex gap-2">
            <CarrierChip name="ID" number={carrier?.id} />
            <CarrierChip name="DOT" number={carrier?.dot_number} />
            <CarrierChip name="EIN" number={carrier?.ein} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <FilterDropdownInfiniteList
      name="carrier"
      fullWidth
      size="medium"
      label="Carrier"
      selectClassname="h-auto"
      onSelect={() => {}}
      multiselect={false}
      keyFunc={keyFunc}
      selectedIds={selectedIds}
      searchPlaceholder="Search by name, ID, DOT"
      renderListItem={renderListItem || defaultRenderListItem}
      onReset={() => setCarrier(null)}
      renderValue={renderValue || defaultRenderValue}
    />
  );
};
