import ConnectTelematicsCard from '@app/components/Onboarding/ConnectTelematicsCard';
import FooterActionButtons from '@app/components/Onboarding/FooterActionButtons';
import Header from '@app/components/Onboarding/Header';
import { useTelematicsProvider } from '@app/hooks/query/useConnectTelematics';
import {
  CustomerOnboardingEventName,
  useCreateCustomerOnboardingEvent,
} from '@app/hooks/query/useCustomerOnboardingEvents';
import { useState } from 'react';
import { useOnboardingGuide } from '../useOnboardingGuide';

export default function OnboardingSyncTelematics() {
  const [isSendingOnboardingEvents, setIsSendingOnboardingEvents] = useState(false);

  const { goToPrevStep, goToNextStep } = useOnboardingGuide();

  const { data: telematicsProviders, isLoading: isFetchingTelematicsProvider } =
    useTelematicsProvider();
  const connectedProvider = telematicsProviders?.data.providers.find(
    (provider) => provider.connected,
  );

  const { trigger: createCustomerOnboardingEvent } = useCreateCustomerOnboardingEvent();

  const handleSkip = async () => {
    setIsSendingOnboardingEvents(true);
    await Promise.all([
      createCustomerOnboardingEvent({
        customer_onboarding_event: {
          name: CustomerOnboardingEventName.CUSTOMER_SKIP_TELEMATICS,
        },
      }),
    ]);
    setIsSendingOnboardingEvents(false);

    goToNextStep();
  };

  return (
    <div className="flex flex-1 flex-col gap-6 md:gap-8">
      <Header title="Sync your telematics" />
      <ConnectTelematicsCard />
      <div className="flex flex-col gap-2">
        <p className="text-default-primary text-sm font-semibold leading-6 md:text-base">
          Don’t want to add Drivers or Vehicles now?
        </p>
        <div className="text-default-secondary text-xs font-medium leading-5 md:text-sm">
          <span>
            You can manage your fleet anytime through the <i>Drivers</i> and <i>Vehicles</i> pages
            in the dashboard.{' '}
          </span>
          <button onClick={handleSkip} className="cursor-pointer underline">
            Skip this step for now.
          </button>
        </div>
      </div>
      <FooterActionButtons
        handleClickBack={goToPrevStep}
        handleClickNext={goToNextStep}
        isDisabled={!connectedProvider}
        isLoading={isFetchingTelematicsProvider || isSendingOnboardingEvents}
      />
    </div>
  );
}
