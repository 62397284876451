import { AccountLimitsData } from '@app/@types/account_limits.types';
import SpinnerBoundary from '@app/components/Spinner/SpinnerBoundary';
import Divider from '@app/components/elements/Divider';
import axios from 'axios';
import { ReactElement, useEffect, useState } from 'react';
import BoostReminder from './BoostReminder';

const AccountLimitsSettings = ({ isPrepaid }: { isPrepaid: boolean }): ReactElement => {
  const [accountLimitsData, setAccountLimitsData] = useState<AccountLimitsData | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    axios
      .get('/account_limits')
      .then((response) => {
        setAccountLimitsData(response.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <div>
      {loading && <SpinnerBoundary />}
      {!loading && (
        <div className="bg-soft-primary border-soft flex-row rounded-lg border p-4">
          <h2 className="text-default-primary text-xl font-bold">Fuel Card Limits</h2>
          <div className="flex h-full w-full flex-col">
            <div className="flex flex-col pt-4 md:flex-row">
              <div className="flex h-full w-full flex-col items-start pr-8">
                <div className="w-full flex-row pt-4">
                  {!isPrepaid && (
                    <>
                      <div className="flex w-full justify-between">
                        <p className="text-default-primary mr-8">Weekly Credit Limit</p>
                        <p className="text-default-primary">
                          {accountLimitsData?.weekly_credit_limit}
                        </p>
                      </div>
                      <Divider />
                      <div className="flex w-full justify-between">
                        <p className="text-default-primary mr-8">Net Term</p>
                        <p className="text-default-primary">{accountLimitsData?.net_terms}</p>
                      </div>
                      <Divider />
                    </>
                  )}
                  <div className="flex w-full justify-between">
                    <p className="text-default-primary mr-8">Spend Categories</p>
                    <div className="flex-row">
                      {accountLimitsData &&
                        accountLimitsData.spend_categories.map((category) => (
                          <div
                            key={category}
                            className="text-default-primary flex justify-end text-right"
                          >
                            {category}
                          </div>
                        ))}
                    </div>
                  </div>
                  <Divider />
                  <div className="flex w-full justify-between">
                    <p className="text-default-primary mr-8">Account Balance</p>
                    <p className="text-default-primary">{accountLimitsData?.account_balance}</p>
                  </div>
                </div>
              </div>
              {!isPrepaid && <BoostReminder />}
            </div>
            <span className="text-default-primary text-sm">
              All cards are issued by Celtic Bank
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountLimitsSettings;
