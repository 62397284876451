import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import { Cell } from '@app/components/TableV2/Cell';
import { MobileListItem } from '@app/components/TableV2/MobileListItem';
import ResponsiveTable from '@app/components/TableV2/ResponsiveTable';
import { ColumnDefinition } from '@app/components/TableV2/Table';
import { useUploadTelematicsData } from '@app/hooks/query/useIftaFilingQuery';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { Button } from '@mui/material';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

interface MilesData {
  id: number;
  vin: string;
  jurisdiction: string;
  enter_time: string;
  distance: number;
  year: string | null;
  month: string | null;
  fuel_type: string | null;
}

type ReviewMilesDataInterface = {
  isLoading: boolean;
  data: MilesData[];
  file: File | null;
  setIsCSVUploaded: (uploaded: boolean) => void;
};

export const COLUMNS = [
  {
    field: 'vin',
    headerName: 'VIN',
    minWidth: 200,
    flex: 1,
    renderCell: (params) => <Cell>{params.row.vin}</Cell>,
  },
  {
    field: 'jurisdiction',
    headerName: 'Jurisdiction',
    minWidth: 150,
    flex: 1,
    renderCell: (params) => <Cell>{params.row.jurisdiction}</Cell>,
  },
  {
    field: 'enter_time',
    headerName: 'Enter Time',
    minWidth: 200,
    flex: 1,
    renderCell: (params) => <Cell>{params.row.enter_time}</Cell>,
  },
  {
    field: 'distance',
    headerName: 'Distance',
    minWidth: 150,
    flex: 1,
    renderCell: (params) => <Cell>{params.row.distance}</Cell>,
  },
  {
    field: 'year',
    headerName: 'Year',
    minWidth: 100,
    flex: 1,
    renderCell: (params) => <Cell>{params.row.year ? params.row.year : null}</Cell>,
  },
  {
    field: 'month',
    headerName: 'Month',
    minWidth: 100,
    flex: 1,
    renderCell: (params) => <Cell>{params.row.month ? params.row.month : null}</Cell>,
  },
  {
    field: 'fuel_type',
    headerName: 'Fuel Type',
    minWidth: 150,
    flex: 1,
    renderCell: (params) => <Cell>{params.row.fuel_type ? params.row.fuel_type : null}</Cell>,
  },
] as ColumnDefinition[];

const MobileVehicleItemRenderer = ({ row, onClick }: { row: MilesData; onClick?: () => void }) => (
  <MobileListItem onClick={onClick}>
    <div className="font-bold">
      <Cell>{row.vin}</Cell>
    </div>
    <div>{row.jurisdiction}</div>
    <div>{row.enter_time}</div>
    <div>{row.distance}</div>
    <div>{row.year}</div>
    <div>{row.month}</div>
  </MobileListItem>
);
const ReviewMilesData = ({
  isLoading,
  data,
  file,
  setIsCSVUploaded,
}: ReviewMilesDataInterface): ReactElement => {
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const { trigger: uploadTelematicsData } = useUploadTelematicsData();

  const onClickSave = async () => {
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    try {
      await uploadTelematicsData(formData);
      navigate('/ifta-filing?stage=3');
      setIsCSVUploaded(false);
    } catch (error) {
      addToast({
        type: 'error',
        title: `Error saving IFTA report`,
      });
    }
  };

  return (
    <>
      <RoundedCard className="relative flex-1 py-4">
        <div className="flex items-center justify-center bg-white">
          <h2 className="mb-4 text-lg font-semibold text-gray-800">
            Miles Data received for {data?.length} Vehicles
          </h2>
        </div>
        <ResponsiveTable
          columns={COLUMNS}
          data={data || []}
          loading={isLoading}
          mobileItemRenderer={MobileVehicleItemRenderer}
        />
        <div className="flex h-fit w-full items-center justify-center">
          <Button
            className="mt-4 w-full px-6 py-2 sm:w-auto"
            size="small"
            onClick={onClickSave}
            data-testid={'start-ifta-filing-button'}
          >
            Save Miles Data
          </Button>
        </div>
      </RoundedCard>
    </>
  );
};

export default ReviewMilesData;
