import SpinnerBoundary from '@app/components/Spinner/SpinnerBoundary';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import axios from 'axios';
import { ReactElement, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function PremiumOptinPage(): ReactElement {
  const navigate = useNavigate();
  const { addToast } = useToasts();

  useEffect(() => {
    axios
      .post('/customer/premium_optin', {})
      .then((res) => {
        if (res.data.message) {
          addToast({
            type: 'success',
            title: res.data.message,
          });
        }
      })
      .catch(() => {
        addToast({
          type: 'error',
          title: 'There was a problem. If the error persists, contact our support team.',
        });
      })
      .finally(() => navigate('/'));
  });

  return <SpinnerBoundary />;
}
