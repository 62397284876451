import { usePlaidConnectHandler } from '@app/hooks/usePlaidHandlers';
import { Button } from '@mui/material';
import PlaidLinkConnect from './PlaidLinkConnect';
import PlaidLinkUpdate from './PlaidLinkUpdate';

export type PlaidLinkProps = {
  setError: (error: Error) => void;
  buttonProps?: {
    disabled?: boolean;
    text?: string;
    className?: string;
    primary?: boolean;
    dark?: boolean;
    fullWidth?: boolean;
    size?: 'small' | 'medium';
  };
  text: string;
  pendingBankAccountId?: string;
  updateBankAccountId?: string;
  flow?: string;
  isLoading: boolean;
};

/**
 *
 * @returns <PlaidLink /> component for specified connecton
 * Options include: Update, Pending, and simple connect
 */
const PlaidLink = ({
  setError,
  buttonProps,
  text,
  updateBankAccountId,
  isLoading,
}: PlaidLinkProps) => {
  const { onSuccess, onEvent } = usePlaidConnectHandler();

  if (isLoading) {
    return (
      <Button
        data-testid="plaid-loading"
        className={buttonProps?.className}
        color={
          !buttonProps?.disabled && !buttonProps?.primary && !buttonProps?.dark
            ? 'secondary'
            : 'primary'
        }
        size={buttonProps?.size}
        fullWidth={buttonProps?.fullWidth}
        disabled
      >
        Loading
      </Button>
    );
  }

  if (updateBankAccountId !== undefined && updateBankAccountId !== null) {
    return (
      <PlaidLinkUpdate
        setError={setError}
        buttonProps={buttonProps}
        text={text}
        updateBankAccountId={updateBankAccountId}
      />
    );
  }
  return (
    <PlaidLinkConnect
      setError={setError}
      buttonProps={buttonProps}
      text={text}
      onSuccess={onSuccess}
      onEvent={onEvent}
    />
  );
};

export default PlaidLink;
