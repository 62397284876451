import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import { Cell } from '@app/components/TableV2/Cell';
import { MobileListItem } from '@app/components/TableV2/MobileListItem';
import ResponsiveTable from '@app/components/TableV2/ResponsiveTable';
import { ColumnDefinition } from '@app/components/TableV2/Table';
import { IftaFilingStage } from '@app/constants/iftaFiling';
import {
  useSyncIftaFuelSummary,
  useUpdateFuelTypeOtherTransaction,
} from '@app/hooks/query/useIftaFilingQuery';
import { formatTime } from '@app/utils/formatDate';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { formatDateLocale } from '@atob-developers/shared/src/utils/formatters';
import { Button } from '@mui/material';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface VehicleRow {
  vehicle_name: string;
  state: string;
  auth_timestamp: string;
  id: number;
  fuel_data: {
    type: string;
  };
  gallons: number;
}

interface MismatchedTransactionInterface {
  data: VehicleRow[];
}

const MobileVehicleItemRenderer = ({
  row,
  onClick,
}: {
  row: VehicleRow; // Use the defined type
  onClick?: () => void;
}) => (
  <MobileListItem onClick={onClick}>
    <div className="font-bold">
      <Cell>{row.auth_timestamp}</Cell>
    </div>
    <div>{row.state}</div>
    <div>{row.vehicle_name}</div> {/* This will now be correctly typed */}
  </MobileListItem>
);

// Define the interface for params
interface SaveParams {
  row: VehicleRow; // Use the VehicleRow type defined earlier
}

const SaveButtonComponent = ({ params }: { params: SaveParams }) => {
  const { trigger: updateFuelTypeOtherTransaction } = useUpdateFuelTypeOtherTransaction(
    params.row.id.toString(),
  );
  const { addToast } = useToasts();
  const onSave = async (params: SaveParams) => {
    try {
      updateFuelTypeOtherTransaction({
        gallons: params.row.gallons,
        fuel_type: params.row.fuel_data?.type,
      })
        .then(() => {
          addToast({
            type: 'success',
            title: `Transaction updated successfully`,
          });
        })
        .catch(() => {
          addToast({
            type: 'error',
            title: `Error in updating transaction`,
          });
        });
    } catch (error) {
      addToast({
        type: 'error',
        title: `Error in updating transaction`,
      });
    }
  };
  return (
    <div className="flex items-center justify-center gap-2">
      <div
        className="cursor-pointer transition-colors duration-200 hover:text-blue-500"
        onClick={() => {
          onSave(params);
        }}
      >
        Save
      </div>
    </div>
  );
};

const MismatchedTransaction = ({ data }: MismatchedTransactionInterface): ReactElement => {
  const [tableRows, setTableRows] = useState(data);
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const {
    trigger: syncIftaFuelSummary,
    error: syncIftaFuelSummaryError,
    isMutating,
  } = useSyncIftaFuelSummary();
  const COLUMNS = [
    {
      field: 'date',
      headerName: 'Date',
      minWidth: 200,
      flex: 1,
      renderCell: (params) => (
        <Cell>
          {formatDateLocale(new Date(params.row.auth_timestamp))} •{' '}
          {formatTime(new Date(params.row.auth_timestamp))}
        </Cell>
      ),
    },
    {
      field: 'vehicle_name',
      headerName: 'Vehicle',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <Cell>{params?.row?.vehicle?.name ? params?.row?.vehicle?.name : null}</Cell>
      ),
    },
    {
      field: 'merchant_name',
      headerName: 'Merchant Name & location',
      minWidth: 200,
      flex: 1,
      renderCell: (params) => (
        <Cell>{params?.row?.merchant?.name ? params?.row?.merchant?.name : null}</Cell>
      ),
    },
    {
      field: 'fuel_type',
      headerName: 'Fuel Type',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <Cell>
          <select
            value={params.row.fuel_data?.type}
            className="w-full border-none bg-transparent outline-none"
            onChange={(e) => {
              const updatedRows = tableRows.map((row: VehicleRow) =>
                row.id === params.row.id
                  ? { ...row, fuel_data: { ...row.fuel_data, type: e.target.value } }
                  : row,
              );
              setTableRows(updatedRows);
            }}
          >
            <option value="diesel">Diesel</option>
            <option value="gas">Gas</option>
            <option value="biodiesel">Biodiesel</option>
            <option value="ethanol">Ethanol</option>
            <option value="lpg">LPG</option>
            <option value="cng">CNG</option>
            <option value="other">Other</option>
          </select>
        </Cell>
      ),
    },
    {
      field: 'gallons',
      headerName: 'Gallons',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <Cell>
          <input
            type="number"
            value={params.row.gallons}
            className="border-none outline-none"
            inputMode="numeric"
            onWheel={(e) => (e.target as HTMLInputElement).blur()}
            onChange={(e) => {
              const updatedRows = tableRows.map((row: VehicleRow) =>
                row.id === params.row.id ? { ...row, gallons: Number(e.target.value) } : row,
              );
              setTableRows(updatedRows);
            }}
          />
        </Cell>
      ),
    },
    {
      field: 'total_cost',
      headerName: 'Total Cost',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <Cell>{params.row.gross_amount_cents ? params.row.gross_amount_cents : null}</Cell>
      ),
    },
    {
      field: 'transaction_type',
      headerName: 'Transaction Type',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => <Cell>{params.row.transaction_type}</Cell>,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => (
        <Cell>
          <SaveButtonComponent params={params} />
        </Cell>
      ),
    },
  ] as ColumnDefinition[];

  const onClickSave = useCallback(async () => {
    try {
      await syncIftaFuelSummary();
      navigate(`/ifta-filing?stage=${IftaFilingStage.NON_ATOB_REPORTS}`);
    } catch (error) {
      addToast({
        type: 'error',
        title: `Error in saving transaction`,
      });
    }
  }, [navigate, addToast, syncIftaFuelSummary]);

  useEffect(() => {
    if (tableRows?.length == 0) {
      onClickSave();
    }
  }, [tableRows?.length, onClickSave]);

  useEffect(() => {
    if (!syncIftaFuelSummaryError || isMutating) {
      return;
    }

    addToast({
      type: 'error',
      title: `Error in saving transaction`,
    });
  }, [syncIftaFuelSummaryError, isMutating, addToast]);

  return (
    <>
      <RoundedCard className="relative flex-1 py-4">
        <div className="flex items-center justify-center bg-white">
          <h2 className="mb-4 text-lg font-semibold text-gray-800">
            {tableRows.length} Transactions have missing details on vehicle mapped or gallons.
            Please review them & move ahead
          </h2>
        </div>
        {tableRows?.length > 0 && (
          <ResponsiveTable
            columns={COLUMNS}
            data={tableRows || []}
            loading={false}
            mobileItemRenderer={MobileVehicleItemRenderer}
          />
        )}
        <div className="flex h-fit w-full items-center justify-center">
          <Button
            className="mt-4 w-full px-6 py-2 sm:w-auto"
            size="small"
            onClick={onClickSave}
            data-testid={'start-ifta-filing-button'}
          >
            Next
          </Button>
        </div>
      </RoundedCard>
    </>
  );
};

export default MismatchedTransaction;
