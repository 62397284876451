import { EndpointResponse } from '@app/@types/api.types';
import { InstantPayoutRecipient } from '@app/@types/instant_payout_method.types';
import Avatar from '@app/components/Avatar/Avatar';
import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import DeleteRecipientModal from '@app/pages/Wallet/Recipients/DeleteRecipientModal';
import RecipientModal from '@app/pages/Wallet/Recipients/RecipientModal';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import { faPen, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, CircularProgress, Divider, Drawer } from '@mui/material';
import { ReactElement, useState } from 'react';
import useSWR from 'swr';
import SidebarHeader from '../SidebarHeader';
import RecipientAccountsList from './RecipientAccountsList';

type RecipientSidebarProps = {
  recipientId: string | null;
  open: boolean;
  setOpen: (open: boolean) => void;
  reset: () => void;
};

export const RecipientSidebar = ({
  recipientId,
  open,
  setOpen,
  reset,
}: RecipientSidebarProps): ReactElement => {
  const [editRecipientModalOpen, setEditRecipientModalOpen] = useState(false);
  const [deleteRecipientModalOpen, setDeleteRecipientModalOpen] = useState(false);

  const { data: recipient } = useSWR<EndpointResponse<InstantPayoutRecipient>>(
    recipientId != null
      ? {
          url: `/instant_payout/recipients/${recipientId}?include=treasury_recipients,instant_payout_methods`,
        }
      : null,
    apiGetFetcher,
  );

  const handleClose = () => {
    reset();
    setOpen(false);
  };

  return (
    <Drawer open={open} anchor="right" onClose={handleClose}>
      <div className="flex h-full w-screen flex-col overflow-auto md:max-w-xl">
        <SidebarHeader title="Recipient details" onClose={handleClose} />
        <Divider className="md:hidden" />
        {!recipient ? (
          <CircularProgress className="self-center" />
        ) : (
          <div className="flex flex-col gap-6 p-6 md:pt-0">
            <RoundedCard className="flex items-center justify-between gap-3 p-5">
              <div className="flex gap-2.5">
                <Avatar
                  initials={`${recipient.data.name
                    ?.split(' ')
                    .map((name: string) => name[0])
                    .slice(0, 2)
                    .join('')}`}
                  size="lg"
                />
                <div className="text-default-primary flex flex-col justify-center">
                  <p className="font-semibold">{recipient.data.name}</p>
                  <p className="text-sm">{recipient.data.nickname}</p>
                </div>
              </div>
              <div className="flex gap-2">
                <Button
                  color="tertiary"
                  size="small"
                  onClick={() => setDeleteRecipientModalOpen(true)}
                  startIcon={<FontAwesomeIcon icon={faTrash} />}
                >
                  <span className="hidden md:block">Delete</span>
                </Button>
                <Button
                  color="secondary"
                  size="small"
                  onClick={() => setEditRecipientModalOpen(true)}
                  startIcon={<FontAwesomeIcon icon={faPen} />}
                >
                  <span className="hidden md:block">Edit</span>
                </Button>
              </div>
            </RoundedCard>
            {recipient && <RecipientAccountsList recipient={recipient.data} />}
          </div>
        )}
      </div>
      {recipient && (
        <>
          <RecipientModal
            mode="edit"
            open={editRecipientModalOpen}
            currentRecipient={recipient.data}
            onSuccess={async () => {
              setEditRecipientModalOpen(false);
              handleClose();
            }}
            onClose={() => setEditRecipientModalOpen(false)}
          />
          <DeleteRecipientModal
            open={deleteRecipientModalOpen}
            recipient={recipient.data}
            onDeleteRecipient={() => {
              setDeleteRecipientModalOpen(false);
              handleClose();
            }}
            onClose={() => {
              setDeleteRecipientModalOpen(false);
            }}
          />
        </>
      )}
    </Drawer>
  );
};
