import SpinnerBoundary from '@app/components/Spinner/SpinnerBoundary';
import { PAYMENT_METHOD_QUERY_KEY } from '@app/hooks/query/usePaymentMethodsQuery';
import useWindowWidth from '@app/hooks/useWindowWidth';
import {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { ReactElement, useState } from 'react';
import { mutate } from 'swr';
import Plaid from '../../../components/Plaid';
import { CreditEnablementNetNewStepsProps } from './CreditEnablementNetNewStepsProps';

export const CreditEnablementNetNewConnectBankAccount = ({
  toggle,
  onNext,
  onBack,
}: CreditEnablementNetNewStepsProps): ReactElement | null => {
  const { addToast } = useToasts();
  const { isSmallScreen } = useWindowWidth();
  const [loading, setLoading] = useState<boolean>(false);

  return (
    <>
      {loading && (
        <div className="w-full">
          <SpinnerBoundary />
        </div>
      )}
      <ModalHeader title="Connect Primary Bank Account" onClose={toggle} />
      <ModalBodyContent overflowVisible={true}>
        <div className="text-default-primary mb-8 text-sm">
          The $75 one-time account setup fee will be automatically charged to this account.
        </div>
        <div className="text-default-primary my-4 text-sm">
          We use Plaid to connect your account.
          <div className="mt-2">
            <a
              href="https://www.plaid.com"
              target="_blank"
              rel="noreferrer"
              className="text-default-primary mt-2 underline decoration-1 underline-offset-2"
            >
              Learn more
            </a>
          </div>
        </div>
        <div className="mb-4">
          <div className="flex items-center">
            <FontAwesomeIcon icon={faCheck} size="2x" className="text-default-primary scale-75" />
            <div className="ml-2">
              <div className="text-default-primary text-sm">Easy, private, and secure</div>
            </div>
          </div>
          <div className="flex items-center">
            <FontAwesomeIcon icon={faCheck} size="2x" className="text-default-primary scale-75" />
            <div className="ml-2">
              <div className="text-default-primary text-sm">
                We will never sell your personal info
              </div>
            </div>
          </div>
        </div>
      </ModalBodyContent>
      <ModalFooter>
        <div className="flex w-full justify-between gap-2">
          <Button className="flex-1" color="secondary" size="medium" onClick={onBack}>
            Back
          </Button>
          <Plaid
            setError={() =>
              addToast({
                type: 'error',
                title:
                  'There was an error connecting to your bank account. Please contact support for assistance.',
              })
            }
            buttonProps={{
              className: 'flex-1',
              size: 'medium',
              primary: true,
            }}
            text={isSmallScreen ? 'Connect' : 'Connect Bank Account'}
            onSuccessCallback={() => {
              mutate(PAYMENT_METHOD_QUERY_KEY);
              onNext();
            }}
            onLinkingCallback={(phase: string) => {
              if (phase === 'START') {
                setLoading(true);
              } else if (phase === 'END') {
                setLoading(false);
              }
            }}
            flow="ul-to-flex-migration-modal"
          />
        </div>
      </ModalFooter>
    </>
  );
};
