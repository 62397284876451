import EntryButton from '@app/components/EntryButton/EntryButton';
import {
  CustomerOnboardingEventName,
  useCreateCustomerOnboardingEvent,
} from '@app/hooks/query/useCustomerOnboardingEvents';
import { convertAmountToCents } from '@app/hooks/use-amount-cents';
import useCustomer from '@app/hooks/useCustomer';

import { formatCurrency } from '@atob-developers/shared/src/utils/formatters';
import { convertCentsToDollars } from '@atob-developers/shared/src/utils/formatters/currencyFormat';
import { faGlobe } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { Chip, Divider } from '@mui/material';
import { useMemo, useState } from 'react';
import { ConfirmFunds } from './ConfirmFunds';
import { AmountInput, DescriptionInput, PaymentMethodSelector } from './Inputs';
import { SourceSelector } from './Inputs/SourceSelector';
import { ConfirmationModal, SuccessModal } from './Modals';
import { ErrorModal } from './Modals/ErrorModal';
import { SuccessIcon } from './Modals/SuccessModal';
import WireInstructionsModal from './WireInstructionsModal';
import useAddFunds from './useAddFunds';
import {
  computeTotalFeeAmount,
  FeeConfiguration,
  filterSourcesBySelectedMethod,
  nameMapping,
  NormalizedDestination,
  NormalizedSource,
  PaymentMethodType,
  totalTransferAmount,
  transferTimeMapping,
} from './utils';

type DepositState = 'initial' | 'confirmation' | 'success';
interface Props {
  availableMethods: PaymentMethodType[];
  availableSources: NormalizedSource[];
  loadingMethods: boolean;
  loadingSources: boolean;
  onCompletedTransfer: () => void;
  minimumDepositAmountCents?: number;
  feeConfigurations?: FeeConfiguration[];
}

export const AddFundsForm = ({
  loadingMethods,
  loadingSources,
  availableMethods,
  availableSources,
  onCompletedTransfer,
  minimumDepositAmountCents,
  feeConfigurations,
}: Props) => {
  const customer = useCustomer();

  const [isConfirmingFunds, setIsConfirmingFunds] = useState(false);
  const [depositState, setDepositState] = useState<DepositState>('initial');
  const [selectedMethod, setSelectedMethod] = useState<PaymentMethodType | null>(null);
  const [selectedSource, setSelectedSource] = useState<NormalizedDestination | null>(null);
  const [amount, setAmount] = useState('');
  const [description, setDescription] = useState('');
  const [validAmount, setValidAmount] = useState(true);
  const [wireModalOpen, setWireModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const filteredSources = useMemo(() => {
    return filterSourcesBySelectedMethod(availableSources, selectedMethod);
  }, [availableSources, selectedMethod]);

  const minimumDepositAmountContextValue = customer?.treasury.minimum_deposit_amount.cents ?? 2500;
  const minimumDepositAmount = minimumDepositAmountCents ?? minimumDepositAmountContextValue;

  const { trigger: createCustomerOnboardingEvent } = useCreateCustomerOnboardingEvent();

  const resetModal = () => {
    setClientSecret(null);
    setErrorModalOpen(false);
    setConfirmationModalOpen(false);
  };

  const onSuccess = () => {
    void createCustomerOnboardingEvent({
      customer_onboarding_event: { name: CustomerOnboardingEventName.CUSTOMER_ADD_FUNDS },
    });
    onCompletedTransfer();
    setDepositState('success');
  };

  const onFailure = (_e: unknown) => {
    setErrorModalOpen(true);

    setIsConfirmingFunds(false);
    setClientSecret(null);
  };

  const {
    submitFunds,
    clientSecret,
    setClientSecret,
    isLoading: isAddingFunds,
  } = useAddFunds({
    onSuccess,
    onFailure,
    amount: convertAmountToCents(amount),
    description: description,
    selectedPaymentMethodId: selectedSource && selectedSource?.id,
  });

  const handleSubmit = async () => {
    if (depositState === 'initial') {
      setDepositState('confirmation');
      setConfirmationModalOpen(true);
      return;
    }
    setErrorModalOpen(false);
    await submitFunds();
  };

  const isProcessingFunds = isAddingFunds || isConfirmingFunds;

  const creditAmount = (): string => {
    if (!amount || !selectedMethod) return '$0.00';

    const amountCents = convertAmountToCents(amount);

    const totalFeeAmount = computeTotalFeeAmount({
      transferAmountCents: amountCents,
      feeData: feeConfigurations?.find((fee) => fee.type === selectedMethod) || null,
    });

    const total = totalTransferAmount(amountCents, totalFeeAmount, 'inbound');

    return formatCurrency({
      value: total,
      options: { fromCents: true },
    });
  };

  return (
    <>
      <div className="mb-[72px] flex-1">
        <div className="mb-12 flex flex-col gap-6 px-6 md:px-8">
          <div className="flex flex-col gap-2">
            <PaymentMethodSelector
              availableMethods={availableMethods}
              availableDestinations={availableSources}
              selectedDestinationID={selectedSource?.id ?? null}
              selectedMethod={selectedMethod}
              setSelectedDestinationID={(id) =>
                setSelectedSource(availableSources.find((source) => source.id === id) ?? null)
              }
              setSelectedMethod={setSelectedMethod}
              loadingMethods={loadingMethods}
              transferKind="deposit"
              feeConfiguration={feeConfigurations}
            />
            <EntryButton
              onClick={() => setWireModalOpen(true)}
              title={nameMapping.us_domestic_wire}
              startIcon={
                <FontAwesomeIcon icon={faGlobe} className="text-default-secondary text-base" />
              }
            >
              <Chip label={transferTimeMapping.us_domestic_wire} color="grey" size="small" />
            </EntryButton>
          </div>
          <SourceSelector
            availableSources={filteredSources}
            selectedSource={selectedSource}
            selectedMethod={selectedMethod}
            setSelectedSource={setSelectedSource}
            loadingSources={loadingSources}
          />
          <AmountInput
            validAmount={validAmount}
            amount={amount}
            setAmount={setAmount}
            setValidAmount={setValidAmount}
            minimumDeposit={convertCentsToDollars({ value: minimumDepositAmount }).format()}
          />
          <DescriptionInput description={description} setDescription={setDescription} />
        </div>
      </div>
      <div className="bg-soft-primary absolute bottom-0 left-0 right-0 w-full overflow-hidden">
        <Divider />
        <div className="flex gap-3 px-6 pb-8 pt-4 md:px-8">
          <LoadingButton
            color="primary"
            fullWidth
            onClick={handleSubmit}
            disabled={!selectedMethod || !selectedSource || !amount || !validAmount}
            loading={isProcessingFunds}
          >
            Add money
          </LoadingButton>
        </div>
      </div>

      <WireInstructionsModal open={wireModalOpen} onClose={() => setWireModalOpen(false)} />

      {selectedSource && selectedMethod && (
        <>
          <ConfirmationModal
            open={confirmationModalOpen && !errorModalOpen}
            onClose={() => setDepositState('initial')}
            destination={selectedSource}
            method={selectedMethod}
            amount={amount}
            description={description}
            onConfirm={handleSubmit}
            loading={isProcessingFunds}
            buttonText="Add money"
            secondaryTitle={
              <span>
                An amount of <span className="text-default-primary">{creditAmount()}</span> will be
                credited to your AtoB account
              </span>
            }
            title="Review Transfer"
            fee={feeConfigurations?.find((fee) => fee.type === selectedMethod)}
          />
          <SuccessModal
            open={depositState === 'success'}
            onClose={resetModal}
            destination={selectedSource}
            method={selectedMethod}
            amount={amount}
            description={description}
            title="Transfer initiated"
            secondaryTitle="We are processing your transaction."
            icon={SuccessIcon.CHECK}
            fee={feeConfigurations?.find((fee) => fee.type === selectedMethod)}
          />
          <ErrorModal
            open={errorModalOpen}
            onClose={resetModal}
            onRetry={handleSubmit}
            destination={selectedSource}
            method={selectedMethod}
            amount={amount}
            description={description}
            title="Transfer failed"
            secondaryTitle="The transaction was interrupted and we were unable to complete it."
            fee={feeConfigurations?.find((fee) => fee.type === selectedMethod)}
          />
          {clientSecret && depositState !== 'success' && (
            <ConfirmFunds
              setLoading={setIsConfirmingFunds}
              onSuccess={onSuccess}
              onFailure={onFailure}
              scenario="add_funds"
            />
          )}
        </>
      )}
    </>
  );
};
