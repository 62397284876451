import Header from '@app/components/Navigation/Header';
import AutoTopUpSidebar from '@app/components/Prepaid/AutoTopUps/AutoTopUpSidebar';
import { useAutoTopUpData } from '@app/components/Prepaid/AutoTopUps/use-auto-topup-data';
import { useExtendMobileHeaderContextMenu } from '@app/contexts/MobileNavHeaderMenuContextComponent';
import useCustomer from '@app/hooks/useCustomer';
import useProduct from '@app/hooks/useProduct';
import useIsAllowedToFund from '@app/utils/funding/useIsAllowedToFund';
import { faRefresh, faUsers } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const WalletPageHeader = () => {
  const navigate = useNavigate();
  const { autoTopUp, isLoading, autoTopUpError, refreshAutoTopUp } = useAutoTopUpData();
  const [autoTopUpSidebarOpen, setAutoTopUpSidebarOpen] = useState(false);
  const allowedToFund = useIsAllowedToFund();
  const [walletLite, manageWalletRecipients] = useProduct(
    'wallet_lite',
    'manage_wallet_recipients',
  );
  const { company_name } = useCustomer();

  const closeAutoTopUpSidebar = () => {
    setAutoTopUpSidebarOpen(false);
    setTimeout(() => {
      refreshAutoTopUp();
    }, 1000);
  };

  const menuData = useMemo(
    () => [
      ...(!walletLite
        ? [
            {
              id: 'autoDeposit',
              text: 'Auto-Deposit',
              icon: <FontAwesomeIcon icon={faRefresh} className="text-base" />,
              onClick: () => setAutoTopUpSidebarOpen(true),
              disabled: () => !allowedToFund,
              order: () => 1,
            },
          ]
        : []),
      ...(manageWalletRecipients
        ? [
            {
              id: 'manageRecipients',
              text: 'Manage recipients',
              icon: <FontAwesomeIcon icon={faUsers} className="text-base" />,
              onClick: () => navigate('/wallet/recipients'),
              disabled: () => false,
              order: () => 2,
            },
          ]
        : []),
    ],
    [walletLite, manageWalletRecipients, allowedToFund, navigate],
  );

  const menuItems = useMemo(
    () =>
      menuData.map((item) => ({
        value: (
          <>
            {item.icon}
            {item.text}
          </>
        ),
        onClick: item.onClick,
        disabled: item.disabled,
        order: item.order(),
      })),
    [menuData],
  );

  useExtendMobileHeaderContextMenu(...menuItems);

  return (
    <Header
      title={walletLite ? company_name : 'Wallet'}
      rightContent={
        <div className="flex items-center justify-end gap-2">
          <div className="flex items-center justify-between">
            <div className="flex gap-2">
              {menuData.map((item) => (
                <Button
                  className="p-3 text-sm"
                  color="secondary"
                  key={item.id}
                  onClick={item.onClick}
                  disabled={item.disabled()}
                  startIcon={item.icon}
                >
                  {item.text}
                </Button>
              ))}
            </div>
            <AutoTopUpSidebar
              autoTopUp={autoTopUp}
              isLoading={isLoading}
              autoTopUpError={autoTopUpError}
              refreshAutoTopUp={refreshAutoTopUp}
              isOpen={autoTopUpSidebarOpen}
              toggle={closeAutoTopUpSidebar}
            />
          </div>
        </div>
      }
    />
  );
};

export default WalletPageHeader;
