import cx from 'classnames';
import { ReactElement, HTMLAttributes } from 'react';
type CardSectionTitleProps = {
  text?: string;
} & HTMLAttributes<HTMLDivElement>;

const CardSectionTitle = ({ children, text, className }: CardSectionTitleProps): ReactElement => {
  const baseClassName = 'text-sm font-semibold text-default-primary self-center w-fit';
  return <p className={cx(baseClassName, className)}>{text || children}</p>;
};

export default CardSectionTitle;
