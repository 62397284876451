import axios, { AxiosRequestConfig } from 'axios';
import { ReactElement, useState, ReactNode, useEffect, useRef } from 'react';
import OneTimePassword from '../components/OneTimePassword/OneTimePassword';
import { isRetryableError } from '../utils/error/isRetryableError';

const authEvents = {
  listeners: new Set<() => void>(),
  subscribe(listener: () => void) {
    this.listeners.add(listener);
    return () => {
      this.listeners.delete(listener);
    };
  },
  emit() {
    this.listeners.forEach((listener) => listener());
  },
};

export default function OTPValidator({ children }: { children: ReactNode }): ReactElement {
  const [isOpen, setIsOpen] = useState(false);
  const pendingRequestsRef = useRef<
    Array<{
      config: AxiosRequestConfig;
      resolve: (value: unknown) => void;
      reject: (reason?: unknown) => void;
    }>
  >([]);

  const processPendingRequests = () => {
    const requests = [...pendingRequestsRef.current];
    pendingRequestsRef.current = [];

    requests.forEach(({ config, resolve, reject }) => {
      axios(config)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  useEffect(() => {
    return authEvents.subscribe(() => {
      processPendingRequests();
    });
  }, []);

  useEffect(() => {
    const interceptorId = axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (isRetryableError(error)) {
          return new Promise((resolve, reject) => {
            pendingRequestsRef.current.push({
              config: error.config,
              resolve,
              reject,
            });
            setIsOpen(true);
          });
        }
        return Promise.reject(error);
      },
    );

    return () => {
      axios.interceptors.response.eject(interceptorId);
    };
  }, []);

  return (
    <>
      <OneTimePassword
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        onSuccess={() => authEvents.emit()}
        onCancel={() => {
          pendingRequestsRef.current.forEach(({ reject }) => {
            reject(new Error('Authentication was canceled'));
          });
          pendingRequestsRef.current = [];
        }}
      />
      {children}
    </>
  );
}
