import { DebitCardPaymentMethodCombined } from '@app/@types/debitCard.types';
import DescriptionItem from '@app/components/PaymentModal/DescriptionItem/DescriptionItem';
import PaymentDetailItem from '@app/components/PaymentModal/PaymentDetailSection/PaymentDetailItem/PaymentDetailItem';
import NoPaymentMethods from '@app/components/PaymentModal/components/NoPaymentMethods';
import PaymentDetailsHeaderText from '@app/components/PaymentModal/components/PaymentDetailsHeaderText';
import ReviewPaymentFooter from '@app/components/PaymentModal/components/ReviewPaymentFooter';
import { submitDebitCardPayment } from '@app/components/PaymentModal/helpers/submitDebitCardPayment';
import StripeElementsWrapper from '@app/components/StripeElements/StripeElementsWrapper';
import StripeContext from '@app/contexts/stripeContext';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import ApiEndpoints from '@app/utils/data/apiEndpoints';
import {
  convertNumbersWithCurrency,
  getCurrencyFromCents,
} from '@atob-developers/shared/src/utils/formatters/currencyFormat';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';
import { capitalize } from 'lodash-es';
import { ReactElement, useContext, useState } from 'react';

export interface DebitCardReviewPaymentDetailsProps {
  onBack: () => void;
  paymentAmount: string;
  selectedDebitCard: DebitCardPaymentMethodCombined | undefined;
  setPaymentScreen: (screen: 'edit' | 'review' | 'success' | 'error', message?: string) => void;
}

const DebitCardReviewPaymentDetails = StripeElementsWrapper(
  ({
    onBack,
    paymentAmount,
    selectedDebitCard,
    setPaymentScreen,
  }: DebitCardReviewPaymentDetailsProps): ReactElement | null => {
    const stripe: Stripe | null = useStripe();
    const elements = useElements();
    const [waiveInstantAccessFeeEnabled, twoPercentInstantFee] = useFeatureFlags(
      'waive_instant_access_fee',
      'instant_access_fee_2_percent',
    );
    const instantAccessFeePercentage = twoPercentInstantFee ? 2 : 1;

    const paymentAmountValueInCents = convertNumbersWithCurrency(paymentAmount) * 100;
    const instantAccessFeeInCents =
      paymentAmountValueInCents * (instantAccessFeePercentage / 100.0);
    const totalAmount = waiveInstantAccessFeeEnabled
      ? paymentAmountValueInCents // without fee
      : paymentAmountValueInCents + instantAccessFeeInCents;

    const { clientSecret } = useContext(StripeContext);

    const [submitting, setSubmitting] = useState(false);

    const payNow = () => {
      submitDebitCardPayment({
        stripe,
        elements,
        clientSecret,
        setSubmitting,
        setPaymentScreen,
      });
    };

    const backClickHandler = () => {
      onBack();
    };

    if (!selectedDebitCard) {
      return <NoPaymentMethods onBack={onBack} />;
    }

    const { payment_method_detail: debitCardPaymentMethodDetail } = selectedDebitCard;

    return (
      <>
        <div className="bg-soft-primary w-full space-y-10 px-6 py-6 sm:px-8">
          <div className="flex flex-col gap-4">
            <PaymentDetailsHeaderText>Review Payment Details</PaymentDetailsHeaderText>
            <div className="flex flex-col gap-4">
              <div className="border-soft border-b" />
              <PaymentDetailItem text="Debit Card">
                {capitalize(debitCardPaymentMethodDetail.brand)} ••••{' '}
                {debitCardPaymentMethodDetail.last_four}
              </PaymentDetailItem>
              <div className="border-soft border-b" />
              <PaymentDetailItem text="Amount">{paymentAmount}</PaymentDetailItem>
              {!waiveInstantAccessFeeEnabled && (
                <>
                  <div className="border-soft border-b" />
                  <PaymentDetailItem text="Instant Access Fee">
                    {getCurrencyFromCents(instantAccessFeeInCents)}
                  </PaymentDetailItem>
                  <DescriptionItem
                    text={`A ${instantAccessFeePercentage}% fee is applied to instant debit payments`}
                  />
                </>
              )}
              <div className="border-soft border-b" />
              <PaymentDetailItem text="Est. Processing Date">Instant</PaymentDetailItem>
            </div>
          </div>
        </div>
        <ReviewPaymentFooter
          loading={submitting}
          onBack={backClickHandler}
          payNowHandler={payNow}
          paymentAmount={getCurrencyFromCents(totalAmount)}
        />
      </>
    );
  },
  ApiEndpoints.PAYMENTS_ENDPOINTS.PAYMENT_INTEGRATION_ENDPOINT,
);

export default DebitCardReviewPaymentDetails;
