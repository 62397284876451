import { ReactElement, ReactNode } from 'react';

export default function OnboardingWrapper({
  leftContent,
  rightContent,
}: {
  leftContent: ReactNode;
  rightContent: ReactNode;
}): ReactElement {
  return (
    <div className="bg-soft-secondary flex min-h-screen justify-center">
      <div className="divide-soft flex w-full md:divide-x">
        <div className="sticky top-0 hidden h-screen w-1/2 md:block">{leftContent}</div>
        <div className="flex w-1/2 flex-1 items-stretch p-6 pb-8 md:p-8">{rightContent}</div>
      </div>
    </div>
  );
}
