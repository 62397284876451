import useChannelPartner from '@app/hooks/useChannelPartner';
import { ModalBodyContent, ModalFooter } from '@atob-developers/shared/src/components/Modal';
import { faCircleExclamation, faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { ReactElement } from 'react';

export const SecurityDepositPaymentFailed = ({
  onClose,
}: {
  onClose: () => void;
}): ReactElement => {
  const { supportPhoneNumber, supportEmailAddress } = useChannelPartner();

  return (
    <>
      <ModalBodyContent overflowVisible={true}>
        <div className="flex flex-col items-center">
          <div className="mb-3 mt-8">
            <FontAwesomeIcon className="fa-2x text-error" icon={faCircleExclamation} />
          </div>
          <h1 className="text-default-primary mb-2 text-center text-2xl font-bold">
            Payment Failed
          </h1>
        </div>
        <div className="bg-strong-secondary mt-6 rounded-lg p-4">
          <div className="flex justify-between">
            <FontAwesomeIcon className="fa-lg text-default-primary p-4" icon={faCircleInfo} />
            <p className="text-default-primary w-full px-4 text-base font-medium">
              Please reach out to support to get this resolved. You can call us at{' '}
              {supportPhoneNumber} or email {supportEmailAddress}.
            </p>
          </div>
        </div>
      </ModalBodyContent>
      <ModalFooter>
        <Button color="secondary" size="medium" onClick={onClose} fullWidth>
          Done
        </Button>
      </ModalFooter>
    </>
  );
};
