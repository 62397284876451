import type { Address } from '@app/components/elements/AddressInput';

export type TransferMethod = 'ach' | 'us_domestic_wire';

export type TransferType = 'debit' | 'bank';

export type WithdrawMethod = 'ach' | 'us_domestic_wire' | 'debit';

export type WithdrawFeeData = {
  type: WithdrawMethod;
  fee_amount: number;
  fee_percentage: number;
  error?: string;
};

export type AddRecipientAccount = (recipient: RecipientToCreate) => Promise<void>;

export type RecipientData = {
  id: string;
  type: string;
  billing_details: {
    name: string;
    address?: Address;
  };
  display_name: string;
  payment_details: {
    bank_account: {
      account_holder_type: 'individual' | 'company';
      institution_name: string;
      mask: string;
      supported_networks: string[];
    };
  };
  customer_owned: boolean;
};

export type RecipientToCreate = {
  billing_details: {
    name: string;
    address?: Address;
  };
  us_bank_account: {
    account_number: string;
    routing_number: string;
    account_holder_type: 'individual' | 'company';
  };
  display_name?: string;
  id?: string;
  customer_owned?: boolean;
  instant_payout_recipient_id?: string;
};

export type RecipientToEdit = {
  billing_details: {
    name: string;
    address?: Address;
  };
  display_name?: string;
  id: string;
};

export const recipientDataToNewRecipient = (recipient: RecipientData): RecipientToCreate => {
  if (!Object.keys(recipient).length) {
    return {
      billing_details: { name: '' },
      us_bank_account: {
        account_holder_type: 'individual',
        account_number: '',
        routing_number: '',
      },
      display_name: '  ',
      id: '',
    };
  }

  const {
    billing_details: { name, address },
    payment_details: { bank_account },
    display_name,
  } = recipient;

  return {
    billing_details: { name, address },
    us_bank_account: {
      account_holder_type: bank_account.account_holder_type,
      account_number: '',
      routing_number: '',
    },
    display_name,
    id: recipient.id,
  };
};
