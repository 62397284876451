import { handleMakePaymentMethodPrimary } from '@app/components/PaymentMethods/PaymentMethodUtils';
import { Spacer } from '@app/components/elements';
import { ErrorNotification } from '@app/components/layout';
import WalletDetail from '@app/pages/Wallet/WalletOverview/WalletDetail';
import onCopy from '@app/utils/on-copy';
import SideBar, { SideBarBody } from '@atob-developers/shared/src/components/SideBar';
import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Chip } from '@mui/material';
import { ReactElement, useState } from 'react';

const WalletSidebar = ({
  title,
  open,
  toggle,
  data,
  handleRefreshPaymentMethods,
}: {
  title: string;
  open: boolean;
  toggle: () => void;
  data: { accountNumber: string; routingNumber: string; id: string; is_primary: boolean } | null;
  handleRefreshPaymentMethods: () => void;
}): ReactElement => {
  const [showRoutingNumber, setShowRoutingNumber] = useState(false);
  const [copyRoutingNumber, setCopyRoutingNumber] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const makePrimaryPaymentMethod = async () => {
    try {
      await handleMakePaymentMethodPrimary(data!.id);
      handleRefreshPaymentMethods();
    } catch (e) {
      setError('There was a problem making this payment method primary.');
    } finally {
      setLoading(false);
    }
  };

  const onCopyNumber = (number: string, setCopyNumber: (copied: boolean) => void) =>
    onCopy(number, () => {
      setCopyNumber(true);
      setTimeout(() => {
        setCopyNumber(false);
      }, 3000);
    });

  return (
    <SideBar title={title} open={open} toggle={toggle}>
      <SideBarBody>
        {data?.is_primary && (
          <div className="mb-6">
            <Chip label="Primary" color="green" />
          </div>
        )}
        {error && <ErrorNotification error={error} />}
        {data?.routingNumber && (
          <WalletDetail
            title="Routing"
            detailNumber={data.routingNumber}
            showNumber={showRoutingNumber}
            setShowNumber={setShowRoutingNumber}
            copyNumber={copyRoutingNumber}
            setCopyNumber={setCopyRoutingNumber}
            onCopyNumber={onCopyNumber}
          />
        )}
        {data?.accountNumber && (
          <WalletDetail
            restricted
            title="Account"
            showNumber={false}
            detailNumber={data.accountNumber}
          />
        )}
        <Spacer />
        {loading ? (
          <div className="mt-3 text-sm">
            <FontAwesomeIcon icon={faSpinnerThird} className="h-6 animate-spin" />
          </div>
        ) : (
          !data?.is_primary && (
            <Button onClick={makePrimaryPaymentMethod} color="secondary" size="medium">
              Make Primary Payment Method
            </Button>
          )
        )}
      </SideBarBody>
    </SideBar>
  );
};

export default WalletSidebar;
