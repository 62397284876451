import { ErrorMessage, Field } from 'formik';

interface InputFieldProps {
  label?: string;
  id: string;
  required?: boolean;
  disabled?: boolean;
  className?: string;
  autocomplete?: string;
  type?: string;
  dataTestId?: string;
  placeholder?: string;
  as?: string;
}

const InputField = ({
  label = '',
  id,
  type,
  as = 'input',
  required = false,
  disabled = false,
  className = '',
  autocomplete = 'off',
  dataTestId = '',
  placeholder = '',
}: InputFieldProps) => (
  <div>
    <label className="text-default-primary block text-base font-bold" htmlFor={id}>
      {label}
      {required && <span className="text-red ml-1">*</span>}
    </label>
    <div className="relative clear-both text-base">
      <Field
        id={id}
        type={type}
        as={as}
        name={id}
        disabled={disabled}
        className={`${className} border-soft bg-soft-primary text-default-primary w-full max-w-full rounded border p-2`}
        autoComplete={autocomplete}
        data-testid={dataTestId}
        placeholder={placeholder}
      />
      <ErrorMessage name={id} component="p" className="text-error" />
    </div>
  </div>
);

export default InputField;
