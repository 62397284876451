import { BlankPlaceholder } from '@atob-developers/shared/src/components/Table';
import { faCircleUser, faTruck, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement } from 'react';

const AssignedToItem = ({
  text,
  icon,
  show,
}: {
  text: string;
  icon: IconDefinition;
  show?: boolean;
}) => {
  if (!show) return null;
  return (
    <div className="flex items-center">
      <FontAwesomeIcon className="text-default-secondary" icon={icon} />
      <p className="text-default-primary pl-2 pr-3 text-sm font-medium">{text}</p>
    </div>
  );
};

export const CardAssignedTo = ({
  driver,
  vehicle,
}: {
  driver: string;
  vehicle: string;
}): ReactElement => {
  if (!driver && !vehicle) {
    return <BlankPlaceholder />;
  }

  return (
    <>
      <AssignedToItem text={driver} icon={faCircleUser} show={!!driver} />
      <AssignedToItem text={vehicle} icon={faTruck} show={!!vehicle} />
    </>
  );
};
