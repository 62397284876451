import { SingleDriverUserDetail } from '@app/@types/driver.types';
import DefaultPrompt from '@app/components/Prompt/DefaultPrompt';
import DataItem, { DataItemType } from '@atob-developers/shared/src/components/DataItem';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { ReactElement, useState } from 'react';

type PreviewDriverSidebarItemType = {
  label: string;
  type: DataItemType;
  path: string;
  required: boolean;
};

interface PreviewDriverSidebarAccountStatusProps<T> {
  item: PreviewDriverSidebarItemType;
  driver: Record<string, T>;
}

const AccountStatusItemContent = <T,>(content: T, driver: Record<string, T>): ReactElement => {
  let bodyMessage = '';
  let actionMessage = '';
  let actionRoute = '';
  let successMessage = '';
  let errorMessage = '';
  let onClickAction;
  const [showAccountStatusUpdate, setShowAccountStatusUpdate] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [accountStatusUpdateMessage, setAccountStatusUpdateMessage] = useState('');

  // Handle account status updates and actions
  switch (content as unknown as string) {
    case 'account_created':
      bodyMessage = 'Using the AtoB App';
      break;
    case 'invited':
      bodyMessage = 'Ready to use the AtoB App';
      actionMessage = 'Send Download Link';
      // eslint-disable-next-line @typescript-eslint/no-base-to-string, @typescript-eslint/restrict-template-expressions
      actionRoute = `/drivers/${driver.id}/driver_app_invite`;
      // eslint-disable-next-line @typescript-eslint/no-base-to-string, @typescript-eslint/restrict-template-expressions
      successMessage = `Download link successfully sent to ${driver.phone}!`;
      errorMessage =
        'There was an error texting this driver. Please try again, or if the issue persists, contact support.';
      break;
    case 'not_invited':
      bodyMessage = 'Not Invited';
      actionMessage = 'Send Invite';
      // eslint-disable-next-line @typescript-eslint/no-base-to-string, @typescript-eslint/restrict-template-expressions
      actionRoute = `/drivers/${driver.id}/driver_app_invite`;
      // eslint-disable-next-line @typescript-eslint/no-base-to-string, @typescript-eslint/restrict-template-expressions
      successMessage = `Invite text successfully sent to ${driver.phone}!`;
      errorMessage =
        'There was an error inviting this driver. Please try again, or if the issue persists, contact support.';
      break;
  }

  if (!driver.phone) {
    onClickAction = () => {
      setShowAccountStatusUpdate(true);
      setAccountStatusUpdateMessage(
        `This driver must have a valid phone number in order to send an invite.`,
      );
      setHasError(true);
    };
  } else {
    onClickAction = () =>
      axios
        .post<SingleDriverUserDetail>(actionRoute, {
          driver,
          force_invite: true,
        })
        .then(() => {
          setAccountStatusUpdateMessage(successMessage);
        })
        .catch((err) => {
          setAccountStatusUpdateMessage(errorMessage);
          Sentry.captureException(err, {
            user: {
              request_id: err?.response?.headers?.['x-request-id'],
              owner_email: driver.email,
            },
          });
          setHasError(true);
        })
        .finally(() => setShowAccountStatusUpdate(true));
  }

  return (
    <div>
      <div className="flex flex-row space-x-2">
        <div>{bodyMessage}</div>
        <div className="text-green cursor-pointer underline" onClick={onClickAction}>
          {actionMessage}
        </div>
      </div>
      {showAccountStatusUpdate && (
        <div className="mt-2">
          <DefaultPrompt
            error={hasError}
            clickHandler={() => {
              setHasError(false);
              setShowAccountStatusUpdate(false);
            }}
            message={accountStatusUpdateMessage}
          />
        </div>
      )}
    </div>
  );
};

const PreviewDriverSidebarAccountStatus = <T,>({
  item,
  driver,
}: PreviewDriverSidebarAccountStatusProps<T>): ReactElement => {
  return (
    <>
      <DataItem
        item={{
          ...item,
          type: DataItemType.TEXT,
          content:
            item?.path && driver[item?.path]
              ? AccountStatusItemContent(driver[item?.path], driver)
              : '-',
        }}
        tooltipText={
          'Drivers can use the AtoB App to claim fuel discounts at select gas stations and truck stops.'
        }
      />
    </>
  );
};

export default PreviewDriverSidebarAccountStatus;
