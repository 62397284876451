import LineItem from '@app/components/layout/LineItem';
import { formatCurrency } from '@atob-developers/shared/src/utils/formatters';
import { FeeConfiguration, feeString, totalTransferAmount } from './utils';

export const TransferSummary = ({
  recipientName,
  amount,
  fee,
  feeAmount = 0,
  description,
  transferType,
  arrivalTime,
}: {
  recipientName: string | null;
  amount: number;
  fee?: FeeConfiguration;
  feeAmount?: number;
  description: string;
  transferType: string;
  arrivalTime?: string;
}) => {
  return (
    <div className="bg-soft-secondary border-soft flex flex-col gap-3 rounded-lg border p-4 text-sm">
      {recipientName && <LineItem label="Recipient" value={recipientName} />}
      <LineItem label="Transfer type" value={transferType} />
      <LineItem
        label="Amount"
        value={formatCurrency({ value: amount, options: { fromCents: true } })}
      />
      {fee && (
        <LineItem
          label={`Fee (${feeString(fee)})`}
          value={formatCurrency({ value: feeAmount, options: { fromCents: true } })}
        />
      )}
      {fee && (
        <LineItem
          label="Total Amount"
          value={formatCurrency({
            value: totalTransferAmount(amount, feeAmount, fee.direction),
            options: { fromCents: true },
          })}
        />
      )}
      {arrivalTime && <LineItem label="Est. Arrival Time" value={arrivalTime} />}
      {description && (
        <div>
          <span className="text-default-secondary">Description</span>
          <p className="text-default-primary pt-2 font-semibold">{description}</p>
        </div>
      )}
    </div>
  );
};
