import { faCircleMinus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement } from 'react';
import DefaultPrompt from './DefaultPrompt';

interface BankAccountCanNotBePrimaryPromptProps {
  clickHandler: () => void;
}

const message = 'Bank account cannot be your primary account';

const BankAccountCanNotBePrimaryPrompt = ({
  clickHandler,
}: BankAccountCanNotBePrimaryPromptProps): ReactElement => {
  return (
    <DefaultPrompt
      clickHandler={clickHandler}
      message={message}
      error={true}
      icon={<FontAwesomeIcon icon={faCircleMinus} className="text-error" />}
    />
  );
};

export default BankAccountCanNotBePrimaryPrompt;
