import Modal, {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { formatDate } from '@atob-developers/shared/src/utils/formatters';
import { faCheck, faClock } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import classNames from 'classnames';
import { ReactElement } from 'react';
import { TransferSummary } from '../TransferSummary';
import {
  computeEstimatedReceptionTime,
  computeTotalFeeAmount,
  transferTypeMapping,
  FeeConfiguration,
  NormalizedDestination,
  PaymentMethodType,
} from '../utils';

export enum SuccessIcon {
  CHECK = 'check',
  CLOCK = 'clock',
}

export const SuccessModal = ({
  open,
  onClose,
  destination,
  amount,
  fee,
  description,
  method,
  title = 'Transfer complete',
  secondaryTitle,
  icon,
}: {
  open: boolean;
  onClose: () => void;
  destination: NormalizedDestination;
  amount: string;
  fee?: FeeConfiguration;
  description: string;
  method: PaymentMethodType;
  title?: string;
  secondaryTitle?: string | ReactElement;
  icon: SuccessIcon;
}) => {
  const transferAmountCents = Math.round(parseFloat(amount) * 100);
  const feeAmount = fee && computeTotalFeeAmount({ transferAmountCents, feeData: fee });

  const iconMapping = {
    [SuccessIcon.CHECK]: {
      icon: faCheck,
      className: 'bg-green-secondary text-success',
    },
    [SuccessIcon.CLOCK]: {
      icon: faClock,
      className: 'bg-strong-secondary text-default-primary',
    },
  };

  return (
    <Modal open={open} toggle={onClose} maxWidth="xs">
      <ModalHeader
        title={title}
        secondaryTitle={secondaryTitle}
        className="justify-center text-center"
        headerImage={
          <FontAwesomeIcon
            icon={iconMapping[icon].icon}
            className={classNames(
              'flex h-5 w-5 justify-self-center rounded-full p-4',
              iconMapping[icon].className,
            )}
          />
        }
      />
      <ModalBodyContent>
        <TransferSummary
          transferType={transferTypeMapping[method]}
          arrivalTime={formatDate({
            dateValue: computeEstimatedReceptionTime(method),
            pattern: 'MMMM D, YYYY',
          })}
          recipientName={destination.recipientName}
          fee={fee}
          feeAmount={feeAmount}
          description={description}
          amount={transferAmountCents}
        />
      </ModalBodyContent>
      <ModalFooter>
        <Button color="primary" fullWidth onClick={onClose} className="p-3">
          Done
        </Button>
      </ModalFooter>
    </Modal>
  );
};
