import { IconDefinition, faAngleLeft, faAngleRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonHTMLAttributes, useEffect, useState } from 'react';

interface PaginationProps {
  onPageChange: (page: number) => void;
  currentPage: number;
  pages: number;
}

const getPages = (currentPage: number, pages: number) => {
  let start = 0;
  switch (true) {
    case pages <= 3:
      return Array.from({ length: pages }, (_, i) => i + 1);
    case currentPage === 1 || currentPage === 2:
      return Array.from({ length: 3 }, (_, i) => i + 1);
    case currentPage === pages - 1 || currentPage === pages:
      start = pages - 2;
      return [...Array(pages - start + 1).keys()].map((x) => x + start);
    default: {
      start = currentPage - 1;
      const end = currentPage + 1;
      return [...Array(end - start + 1).keys()].map((x) => x + start);
    }
  }
};

type PaginationButtonProps = {
  icon: IconDefinition;
  text: string;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const PaginationButton = ({ onClick, icon, text, disabled }: PaginationButtonProps) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className="border-soft text-default-secondary bg-soft-primary hover:bg-soft-primary-hover relative inline-flex h-7 w-7 cursor-pointer items-center justify-center rounded-md border px-2 py-2 text-sm font-medium sm:h-9 sm:w-9"
  >
    <span className="sr-only">{text}</span>
    <FontAwesomeIcon icon={icon} className="text-default-secondary h-3 w-3" aria-hidden="true" />
  </button>
);

const PaginationDots = ({ show }: { show: boolean }) => (
  <p className="inline-flex w-4 items-center justify-center py-2 text-sm sm:w-7">{show && '...'}</p>
);

const Pagination = ({ pages, currentPage, onPageChange }: PaginationProps) => {
  const [pagesToDisplay, setPages] = useState<number[]>([]);

  // This state is added to avoid glitch when changing between states
  const [selectedPage, setSelectedPage] = useState<number>(currentPage);

  useEffect(() => {
    setPages(getPages(currentPage, pages));
    setSelectedPage(currentPage);
  }, [currentPage, pages]);

  return (
    <nav
      className="relative z-0 flex flex-wrap items-center justify-center gap-y-2 space-x-1.5"
      aria-label="Pagination"
    >
      <div className="flex flex-wrap items-center space-x-1.5">
        <PaginationButton
          onClick={() => currentPage > 1 && onPageChange(currentPage - 1)}
          icon={faAngleLeft}
          text="Previous"
          disabled={currentPage === 1}
        />
        <PaginationDots show={pagesToDisplay[0] !== 1} />
        {pagesToDisplay.map((page) => (
          <div
            key={page}
            className={`bg-soft-primary relative flex h-7 w-7 cursor-pointer items-center justify-center rounded-md py-2 text-sm sm:h-9 sm:w-9 ${
              selectedPage === page
                ? 'text-green font-bold '
                : 'text-default-secondary hover:bg-soft-primary-hover font-normal '
            }`}
            onClick={() => onPageChange(page)}
          >
            {page}
          </div>
        ))}
        <PaginationDots show={pagesToDisplay[pagesToDisplay.length - 1] !== pages} />
        <PaginationButton
          onClick={() => currentPage < pages && onPageChange(currentPage + 1)}
          icon={faAngleRight}
          text="Next"
          disabled={currentPage === pages}
        />
      </div>
    </nav>
  );
};

export default Pagination;
