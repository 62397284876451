import { PaginatedEndpointResponse } from '@app/@types/api.types';
import Avatar from '@app/components/Avatar/Avatar';
import DialogDropzone from '@app/components/DialogDropzone/DialogDropzone';
import MobileNavHeader from '@app/components/Navigation/MobileNavHeader';
import {
  CarrierChip,
  CarrierSelector,
} from '@app/components/Partnerships/CarrierSelector/CarrierSelector';
import { ErrorNotification } from '@app/components/layout';
import Skeleton from '@app/components/layout/Skeleton';
import { PageContentWrapper } from '@app/components/wrappers/PageContentWrapper';
import { DEFAULT_PAGE_SIZE } from '@app/hooks/paging/types';
import useProduct from '@app/hooks/useProduct';
import { useSingleUrlState } from '@app/hooks/useUrlState';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import { guardAxiosError } from '@app/utils/error/guards';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { faFileArrowUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, TextField, MenuItem, Chip } from '@mui/material';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { deserialize } from 'deserialize-json-api';
import React, { ReactElement, useEffect, useState } from 'react';
import useSWR from 'swr';
import { Carrier } from '../types';
import FundingRequests from './FundingRequests';

const Title = ({ title }: { title: string }): ReactElement => {
  return <h2 className="text-contrast-tertiary text-sm font-bold uppercase">{title}</h2>;
};

const WalletBalance = ({ balance }: { balance: string | null }): ReactElement => {
  return (
    <div className="flex flex-col justify-between">
      <Title title="Wallet Balance" />
      <div className="text-green text-[40px] font-normal">
        {balance === null ? 'Pending' : balance}
      </div>
    </div>
  );
};

export type FundingStatus =
  | 'created'
  | 'processing'
  | 'failed'
  | 'succeeded'
  | 'reverted'
  | 'abandoned';

export type FundingRequest = {
  id: number;
  customer_id: number;
  channel_partner_id: number;
  reference_id: string;
  status: FundingStatus;
  description: string;
  customer_name: string;
  created_at: string;
  updated_at: string;
  processed_at: string; // when processing started
  fulfilled_at: string; // when processing was successful
};

type FinancialAccountDetails = {
  balance: string | null;
};

const initialFinancialAccountDetails: FinancialAccountDetails = {
  balance: null,
};

const CarrierListDetails = (carrier: Carrier, setCarrier: (value: Carrier | null) => void) => {
  const isSuspended = carrier.suspended_at != null;
  const isNotApproved = carrier.status !== 'approved';
  const isInactive = isNotApproved || isSuspended;
  return (
    <MenuItem key={carrier.id} value={carrier.id} onClick={() => setCarrier(carrier)}>
      <div className="align-center flex gap-2">
        <span className="text-default-secondary self-center text-sm font-medium">
          {carrier.company_name}
        </span>
        {carrier.id && <CarrierChip name="ID" number={carrier.id} />}
        {carrier.ein && <CarrierChip name="EIN" number={carrier.ein} />}
        {carrier.dot_number && <CarrierChip name="DOT" number={carrier.dot_number} />}
        {carrier.mc_number && <CarrierChip name="MC" number={carrier.mc_number} />}
        {carrier.external_id && <CarrierChip name="EXTERNAL ID" number={carrier.external_id} />}
        <Chip
          color={isInactive ? 'red' : 'green'}
          size="small"
          label={
            <span>{isInactive ? (isSuspended ? 'suspended' : carrier.status) : 'active'}</span>
          }
        />
      </div>
    </MenuItem>
  );
};

const SelectedCarrierDetails = ({ carrier }: { carrier: Carrier }): ReactElement => {
  const isSuspended = carrier.suspended_at != null;
  const isNotApproved = carrier.status !== 'approved';
  const isInactive = isNotApproved || isSuspended;
  return (
    <div className="flex gap-3">
      <Avatar
        initials={carrier?.company_name
          .split(/\s+/)
          .map((w) => w[0].toUpperCase())
          .join('')
          .slice(0, 2)}
        size="lg"
      />
      <div className="flex flex-col gap-2">
        <p className="text-default-secondary text-sm font-medium">{carrier?.company_name}</p>
        <div className="flex gap-2">
          {carrier.id && <CarrierChip name="ID" number={carrier.id} />}
          {carrier.ein && <CarrierChip name="EIN" number={carrier.ein} />}
          {carrier.dot_number && <CarrierChip name="DOT" number={carrier.dot_number} />}
          {carrier.mc_number && <CarrierChip name="MC" number={carrier.mc_number} />}
          {carrier.external_id && <CarrierChip name="EXTERNAL ID" number={carrier.external_id} />}
          <Chip
            color={isInactive ? 'red' : 'green'}
            size="small"
            label={
              <span>{isInactive ? (isSuspended ? 'suspended' : carrier.status) : 'active'}</span>
            }
          />
        </div>
      </div>
    </div>
  );
};

type FundingRequestFormProps = {
  carrierId: number;
  onCancel: () => void;
  refetchFundingRequests: () => void;
};

function FundingRequestForm({
  carrierId,
  onCancel,
  refetchFundingRequests,
}: FundingRequestFormProps): ReactElement {
  const [dollarAmount, setDollarAmount] = useState('');
  const [referenceId, setReferenceId] = useState('');
  const [description, setDescription] = useState('');
  const [requestError, setRequestError] = useState('');

  const isValidDollarAmount = (amount: string): boolean => {
    const dollarAmountRegex = /^\d+(\.\d{2})?$/;
    return dollarAmountRegex.test(amount);
  };

  const handleSubmit = (): void => {
    setRequestError('');

    if (!dollarAmount || !referenceId) {
      setRequestError('Dollar Amount and Reference ID are required.');
      return;
    }

    if (!isValidDollarAmount(dollarAmount)) {
      setRequestError('Please enter a valid dollar amount (e.g., 100 or 100.00).');
      return;
    }

    const data = {
      customer_id: carrierId,
      dollar_amount: dollarAmount,
      reference_id: referenceId,
      description: description, // This field is optional
    };

    axios
      .post('/partners/funding_requests', data)
      .then(() => {
        refetchFundingRequests(); // Trigger a refetch of the funding requests after success
        onCancel(); // Reset the form and go back to the initial state after form submission
      })
      .catch((error) => {
        // Extract error message or use a generic fallback
        const errorMessage =
          error.response?.data?.error || 'An unexpected error occurred. Please try again.';
        setRequestError(`An error occurred while submitting your request: ${errorMessage}`);
      })
      .finally(() => {
        refetchFundingRequests(); // Always refetch the funding requests even in case of error
      });
  };

  return (
    <div>
      {requestError && <div className="text-error mb-2">{requestError}</div>}
      <TextField
        label="Dollar Amount"
        fullWidth
        required
        value={dollarAmount}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDollarAmount(e.target.value)}
        margin="normal"
      />
      <TextField
        label="Reference ID"
        fullWidth
        required
        value={referenceId}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setReferenceId(e.target.value)}
        margin="normal"
      />
      <TextField
        label="Description"
        fullWidth
        value={description}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDescription(e.target.value)}
        margin="normal"
      />
      <div className="mt-4 flex space-x-4">
        <Button color="primary" onClick={handleSubmit}>
          Submit Funding Request
        </Button>
        <Button color="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
}

export default function Funding(): ReactElement {
  const [carrier, setCarrier] = useState<Carrier | null>(null);
  const [loading, setLoading] = useState(false);
  const [showFundingForm, setShowFundingForm] = useState(false);
  const [openFileUpload, setOpenFileUpload] = useState(false);
  const [error, setError] = useState<boolean>(false);

  const [financialData, setFinancialData] = useState<FinancialAccountDetails>(
    initialFinancialAccountDetails,
  );

  const [page, setPage] = useSingleUrlState<number>('page', 1);
  const {
    data,
    isLoading,
    error: fundingRequestsError,
    mutate,
  } = useSWR<PaginatedEndpointResponse<FundingRequest>>(
    {
      url: '/partners/funding_requests',
      params: {
        per: DEFAULT_PAGE_SIZE,
        page,
        all: false,
        customer_id: carrier ? carrier.id : null,
      },
    },
    apiGetFetcher,
  );

  const handleRevertFundingRequest = async (id: number) => {
    try {
      await axios.post(`/partners/funding_requests/${id}/revert`);
      await mutate();
      return null;
    } catch (e: unknown) {
      if (!guardAxiosError(e)) {
        Sentry.captureException(e);
        return 'An unexpected error occurred. Please try again.';
      }
      Sentry.captureException(e, {
        user: {
          request_id: e.response?.headers?.['x-request-id'],
        },
      });
      return e.response?.data?.error || 'Failed to revert funding request. Please try again.';
    }
  };

  const [canManuallyFund] = useProduct('funding_with_manual_requests');

  const handleCancel = (): void => {
    setShowFundingForm(false);
  };

  const fetchFinancialData = async (): Promise<void> => {
    setLoading(true);
    try {
      const financialAccountResponse = await axios.get('/treasury/financial_account');

      if (financialAccountResponse.data.data === null) {
        setFinancialData(initialFinancialAccountDetails);
      } else {
        const { data: financialAccountData } = deserialize(financialAccountResponse.data);
        setFinancialData({
          balance: financialAccountData.balance,
        });
      }
    } catch (e) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchFinancialData();
  }, []);

  const balance = financialData.balance;

  const uploadDocuments = async (files: File[]) => {
    if (!files || files.length === 0) {
      addToast({ type: 'error', title: 'Please select files to upload.' });
      return;
    }

    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('factoring_requests[]', files[i]);
    }

    try {
      const response = await axios.post('/partners/process_bulk_funding_requests', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        const { bad_requests } = response.data;

        if (bad_requests && bad_requests.length > 0) {
          addToast({
            type: 'warning',
            title: 'The following funding requests were unable to be processed:',
            description: `The following statements failed: ${bad_requests.join(', ')}`,
          });
        } else {
          addToast({ type: 'success', title: 'Documents processed successfully!' });
        }
      }
    } catch (error) {
      addToast({ type: 'error', title: 'Failed to process documents.' });
    }
  };

  const handleClose = () => {
    setOpenFileUpload(false);
  };

  const { addToast } = useToasts();

  return (
    <>
      <MobileNavHeader title="Wallet Funding Requests" />
      <div className="m-4 md:mr-0" />
      <PageContentWrapper>
        {canManuallyFund && (
          <div className="flex w-full items-center justify-between">
            <h1 className="text-default-primary text-lg font-medium">Funding Requests</h1>
            <Button
              className="p-3 text-sm"
              color="secondary"
              onClick={() => setOpenFileUpload(true)}
              startIcon={<FontAwesomeIcon icon={faFileArrowUp} />}
            >
              Upload File
            </Button>
            {openFileUpload && (
              <DialogDropzone
                open={openFileUpload}
                handleSubmit={(files) => {
                  uploadDocuments(files);
                  handleClose();
                }}
                options={{
                  maxSize: 10000000,
                  maxFiles: 10,
                }}
                handleClose={handleClose}
              />
            )}
            {error && (
              <ErrorNotification
                error={
                  "We're having issues loading your Wallet details. Please try again; if the issue persists, please contact support."
                }
              />
            )}
          </div>
        )}
        <div>
          <div className="bg-soft-primary rounded shadow-md">
            {loading ? (
              <Skeleton />
            ) : (
              <div className="flex justify-between">
                <div className="flex w-full flex-col">
                  <div className="flex justify-between gap-x-6 p-5 pb-3">
                    <div className="mr-3 flex w-full">
                      <div className="mr-4">
                        <WalletBalance balance={balance} />
                      </div>
                    </div>
                  </div>
                  <div className="border-soft border-b"></div>
                  <div className="flex items-center justify-between p-5"></div>
                  <div className="flex justify-between pb-10 pl-5 pr-5">
                    <div className="mr-3 flex w-full">
                      <div className="mr-4 w-full">
                        <div className="flex flex-col justify-between">
                          <CarrierSelector
                            carrier={carrier}
                            setCarrier={(carrier) => {
                              setCarrier(carrier);
                              setShowFundingForm(false);
                            }}
                            renderListItem={(item) =>
                              CarrierListDetails(item, (carrier) => {
                                setCarrier(carrier);
                                setShowFundingForm(false);
                              })
                            }
                            renderValue={() => {
                              if (carrier === null) return <div>Select a carrier</div>;
                              return <SelectedCarrierDetails carrier={carrier} />;
                            }}
                          />
                          {carrier && (
                            <>
                              {!showFundingForm ? (
                                <div className="mt-4 flex space-x-4">
                                  {canManuallyFund && (
                                    <Button
                                      color="primary"
                                      onClick={() => setShowFundingForm(true)}
                                      disabled={
                                        carrier.status !== 'approved' ||
                                        carrier.suspended_at != null
                                      }
                                    >
                                      Create Funding Request
                                    </Button>
                                  )}
                                </div>
                              ) : (
                                <div className="mt-4">
                                  <FundingRequestForm
                                    carrierId={carrier.id}
                                    onCancel={handleCancel}
                                    refetchFundingRequests={mutate}
                                  />
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        {isLoading ? (
          <Skeleton />
        ) : (
          <>
            <h1 className="text-default-primary text-lg font-medium">
              Funding Requests for {carrier ? carrier.company_name : 'All Carriers'}
            </h1>

            {data?.data?.length ? (
              <FundingRequests
                fundingRequestsPage={data.data}
                fundingRequestsError={fundingRequestsError}
                metadata={data.meta}
                goToPage={setPage}
                onRevertRequest={handleRevertFundingRequest}
              />
            ) : (
              <p className="text-default-primary">No funding requests yet</p>
            )}
          </>
        )}
      </PageContentWrapper>
    </>
  );
}
