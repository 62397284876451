import { FieldState } from '@app/components/VGSElements/types';
import { cssWithWidth } from '@app/components/VGSElements/utils';
import AddressInput, { Address } from '@app/components/elements/AddressInput';
import { getEnvironment } from '@app/utils/environment';
import isNumeric from '@app/utils/is-numeric';
import RadioGroup from '@atob-developers/shared/src/components/RadioButton';
import { TextField } from '@mui/material';
import * as Sentry from '@sentry/react';
import { loadVGSCollect } from '@vgs/collect-js';
import { ReactElement, Dispatch, useCallback, useState, useRef, useEffect } from 'react';
import './RecipientForm.css';
import BankAccountDetailsVGSForm from './BankAccountDetailsVGSForm';

type BankAccountFormProps = {
  type: 'add' | 'edit';
  formId: string;
  onSubmit: (
    e: React.FormEvent<HTMLFormElement>,
    tokenizedRoutingNumber?: string,
    tokenizedAccountNumber?: string,
  ) => void;
  errors: Record<string, string>;
  name: string;
  setName: (name: string) => void;
  nickname: string;
  setNickname: (nickname: string) => void;
  isBusinessAccount: boolean;
  setIsBusinessAccount: (isBusinessAccount: boolean) => void;
  routingNumber: string;
  setRoutingNumber: (routingNumber: string) => void;
  accountNumber: string;
  setAccountNumber: (accountNumber: string) => void;
  confirmedAccountNumber: string;
  setConfirmedAccountNumber: (confirmedAccountNumber: string) => void;
  addressValues: Address;
  setAddressValues: (addressValues: Address) => void;
  addressErrors: Record<string, string>;
  setAddressErrors: (addressErrors: Record<string, string | undefined>) => void;
  touched: Record<string, boolean>;
  setTouched: (values: Record<string, boolean | undefined>) => void;
  children: React.ReactNode;
  vgs: boolean;
  setValidAccountNumbers: (validAccountNumbers: boolean) => void;
  setLoading: (v: boolean) => void;
};

export default function BankAccountForm({
  type,
  formId,
  onSubmit,
  errors,
  name,
  setName,
  nickname,
  setNickname,
  isBusinessAccount,
  setIsBusinessAccount,
  routingNumber,
  setRoutingNumber,
  accountNumber,
  setAccountNumber,
  confirmedAccountNumber,
  setConfirmedAccountNumber,
  addressValues,
  setAddressValues,
  addressErrors,
  setAddressErrors,
  touched,
  setTouched,
  children,
  vgs,
  setValidAccountNumbers,
  setLoading,
}: BankAccountFormProps): ReactElement {
  const { VITE_VGS_BANK_INFO_VAULT_ID, VITE_VGS_ENVIRONMENT, VITE_VGS_TOKENIZATION_ROUTE } =
    getEnvironment();
  const [form, setForm] = useState(null);
  const [displayConfirmation, setDisplayConfirmation] = useState(false);
  const [vgsError, setVgsError] = useState('');

  const initForm = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (vgsCollect: any) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const form = vgsCollect.init((_: any) => {});
      form.setRouteId(VITE_VGS_TOKENIZATION_ROUTE);
      const accountNumberField = form.field('#vgs-account-number', {
        type: 'text',
        name: 'accountNumber',
        placeholder: 'Account Number',
        validations: ['required'],
        css: cssWithWidth('100%'),
      });
      const confirmAccountNumberField = form.field('#vgs-confirm-account-number', {
        type: 'text',
        name: 'confirmAccountNumber',
        placeholder: 'Confirm Account Number',
        validations: [
          'required',
          {
            type: 'compareValue',
            params: {
              field: 'accountNumber',
              function: 'match',
            },
          },
        ],
        css: cssWithWidth('100%'),
      });

      [accountNumberField, confirmAccountNumberField].forEach((field) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        field.on('update', (_: any) => {
          setValidAccountNumbers(
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
            (Object.values(form?.state) as FieldState[]).reduce(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (acc: any, curr: FieldState) => acc && curr?.isValid,
              true,
            ),
          );
        });
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      accountNumberField.on('update', (fieldState: any) => {
        setDisplayConfirmation(fieldState.isValid);
      });
      setForm(form);
    },
    [VITE_VGS_TOKENIZATION_ROUTE, setValidAccountNumbers],
  );

  const hasLoadedForm = useRef(false);
  useEffect(() => {
    const loadForm = async () => {
      try {
        const vgsCollect = await loadVGSCollect({
          vaultId: VITE_VGS_BANK_INFO_VAULT_ID as string,
          environment: VITE_VGS_ENVIRONMENT as string,
          version: '2.18.1',
        });
        initForm(vgsCollect);
      } catch (e: unknown) {
        setVgsError(
          'We were unable to load the banking information form. Please try again. If the error persists, please contact our support team.',
        );
        Sentry.captureMessage('Error setting up VGS Collect');
      }
    };

    if (!hasLoadedForm.current && vgs) {
      hasLoadedForm.current = true;
      loadForm();
    }
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = async (e: any) => {
    setLoading(true);
    e.preventDefault();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    form.tokenize(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      function (_: any, response: any) {
        setAccountNumber(response['accountNumber']);
        onSubmit(e, response['accountNumber']);
      },
      () => {},
    );
  };

  return (
    <form id={formId} onSubmit={vgs ? handleSubmit : onSubmit}>
      <div className="flex flex-col gap-2">
        <TextField
          fullWidth
          required
          disabled={type === 'edit'}
          label="Account Holder Name"
          placeholder="Account Holder Name"
          error={!!errors.name}
          helperText={errors.name}
          value={name}
          onChange={(event) => event.target.value !== null && setName(event.target.value)}
        />
        <TextField
          fullWidth
          disabled={type === 'edit'}
          label="Nickname (optional)"
          placeholder="Nickname"
          value={nickname}
          onChange={(event) => event.target.value !== null && setNickname(event.target.value)}
        />
        <div className="max-w-[200px]">
          <div className="text-default-primary mb-1 font-medium">Account Type</div>
          <RadioGroup
            data={[
              {
                label: 'Business',
                name: 'Business',
                value: 'business',
                id: 'business',
                checked: isBusinessAccount,
              },
              {
                label: 'Personal',
                name: 'Personal',
                value: 'personal',
                id: 'personal',
                checked: !isBusinessAccount,
              },
            ]}
            onSelect={(id) => {
              if (type === 'edit') {
                return;
              }
              setIsBusinessAccount(id === 'business');
            }}
            className="flex justify-between"
          />
        </div>
        {type === 'add' && !vgs && (
          <>
            <TextField
              fullWidth
              required
              label="Routing Number"
              placeholder="Routing Number"
              value={routingNumber}
              error={!!errors.routing}
              helperText={errors.routing}
              onChange={(event) => {
                const value = event.target.value;
                if (value && !isNumeric(value)) {
                  return;
                }
                value !== null && setRoutingNumber(value);
              }}
            />
            <TextField
              fullWidth
              required
              label="Account Number"
              placeholder="Account Number"
              value={accountNumber}
              error={!!errors.account}
              helperText={errors.account}
              onChange={(event) => {
                const value = event.target.value;
                if (value && !isNumeric(value)) {
                  return;
                }
                value !== null && setAccountNumber(value);
              }}
            />
          </>
        )}
        {!vgs && (accountNumber !== '' || confirmedAccountNumber !== '') && (
          <TextField
            fullWidth
            required
            label="Confirm Account Number"
            placeholder="Confirm Account Number"
            value={confirmedAccountNumber}
            error={!!errors.confirmedAccount}
            helperText={errors.confirmedAccount}
            onChange={(event) => {
              const value = event.target.value;
              if (value && !isNumeric(value)) {
                return;
              }
              value !== null && setConfirmedAccountNumber(value);
            }}
          />
        )}
        {type === 'add' && vgs && (
          <>
            <TextField
              fullWidth
              required
              label="Routing Number"
              placeholder="Routing Number"
              value={routingNumber}
              error={!!errors.routing}
              helperText={errors.routing}
              onChange={(event) => {
                const value = event.target.value;
                if (value && !isNumeric(value)) {
                  return;
                }
                value !== null && setRoutingNumber(value);
              }}
            />
            <BankAccountDetailsVGSForm
              error={vgsError}
              validationError={errors.confirmedAccount}
              displayConfirmation={displayConfirmation}
            />
          </>
        )}
        <AddressInput
          label="Recipient Address (for Wire Transfers only)"
          addressValues={addressValues as Record<string, string>}
          setAddressValues={setAddressValues as Dispatch<unknown>}
          errors={addressErrors}
          setErrors={setAddressErrors}
          touched={touched}
          setTouched={setTouched}
        />
      </div>
      <div className="mb-4 mt-8 flex w-full justify-end">{children}</div>
    </form>
  );
}
