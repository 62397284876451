import CardSectionTitle from '@app/components/Cards/CardSectionTitle';
import CardRow from '@app/components/PriceDetailRow/CardRow';
import logger from '@app/utils/datadog-logger';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Switch, Tooltip } from '@mui/material';
import axios from 'axios';
import { ReactElement, useState } from 'react';
import CardUnlockSecurityModal from './CardUnlockSecurityModal';

interface AssignSectionCardAuthProps {
  cardSecurityEnabled: boolean;
  cardId: string | number;
  readonly: boolean;
}

const AssignSectionCardAuth = ({
  cardSecurityEnabled,
  cardId,
  readonly,
}: AssignSectionCardAuthProps): ReactElement => {
  const [loading, setLoading] = useState(false);
  const [cardSecurityToggleState, setCardSecurityToggleState] = useState(cardSecurityEnabled);
  const [showModal, setShowModal] = useState(false);

  const { addToast } = useToasts();

  const toggleSMSAuthHandler = () => {
    setLoading(true);
    logger.info('Toggling SMS Auth', { cardId });
    axios
      .put(`/cards/${cardId}`, {
        card: { card_security_enabled: !cardSecurityToggleState },
      })
      .then(() => {
        const newState = cardSecurityToggleState ? 'deactivated' : 'activated';
        logger.info('Toggled SMS Auth', { cardId, newState });
        addToast({
          type: 'success',
          title: `Card unlock security has been ${newState}`,
        });
        setCardSecurityToggleState(!cardSecurityToggleState);
      })
      .catch(() => {
        const newState = cardSecurityToggleState ? 'deactivated' : 'activated';
        logger.error('Error toggling SMS Auth', { cardId, newState });
        addToast({
          type: 'error',
          title: `Card unlock security could not be ${newState}`,
        });
        setCardSecurityToggleState(cardSecurityToggleState);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const enable = () => {
    if (!cardSecurityToggleState) {
      logger.info('Opening SMS Auth Modal', { cardId });
      setShowModal(true);
    } else {
      toggleSMSAuthHandler();
    }
  };

  const confirm = () => {
    toggleSMSAuthHandler();
    logger.info('Closing SMS Auth Modal after confirmation', { cardId });
    setShowModal(false);
  };

  const reset = () => {
    logger.info('Closing SMS Auth Modal without confirmation', { cardId });
    setShowModal(false);
  };

  return (
    <div>
      <CardRow>
        <div className="flex-col">
          <div className="flex items-center gap-2">
            <CardSectionTitle text="Card Unlock Security" />
            <Tooltip
              title={
                readonly || loading
                  ? 'When a Card has a Tag, this property can only be edited on the Tag itself.'
                  : null
              }
            >
              <FontAwesomeIcon
                icon={faCircleInfo}
                className="text-default-primary cursor-pointer"
                onClick={() => setShowModal((prev) => !prev)}
              />
            </Tooltip>
          </div>
          <div className="text-default-secondary flex items-center text-sm font-normal lg:hidden xl:flex">
            Ensures card can only be used by a verified driver.
          </div>
        </div>
        <Switch
          disabled={readonly || loading}
          checked={cardSecurityToggleState}
          onChange={enable}
        />
      </CardRow>
      <CardUnlockSecurityModal
        cardSecurityEnabled={cardSecurityToggleState}
        open={showModal}
        loading={loading}
        confirm={confirm}
        reset={reset}
      />
    </div>
  );
};

export default AssignSectionCardAuth;
