import { ChargeEventQueryParams } from '@app/contexts/ChargeEventsContextComponent';
import { useExtendMobileHeaderContextMenu } from '@app/contexts/MobileNavHeaderMenuContextComponent';
import { Button } from '@mui/material';
import { ReactElement, useMemo, useState } from 'react';
import ExportTransactions from './ExportTransactions';

type TransactionFiltersProps = {
  selectedFilters: ChargeEventQueryParams;
};

const ExportTransactionsContainer = ({
  selectedFilters,
}: TransactionFiltersProps): ReactElement => {
  const [openModal, toggleModal] = useState(false);

  const menuItem = useMemo(
    () => ({
      value: 'Export Transactions',
      onClick: () => toggleModal(true),
      disabled: () => false,
      order: 1,
    }),
    [],
  );
  useExtendMobileHeaderContextMenu(menuItem);

  return (
    <div className="flex gap-2 text-right">
      <Button size="small" onClick={() => toggleModal(true)} className="hidden md:block">
        Export Transactions
      </Button>
      <ExportTransactions
        open={openModal}
        onClose={() => toggleModal(false)}
        key={openModal.toString()}
        selectedFilters={selectedFilters}
      />
    </div>
  );
};

export default ExportTransactionsContainer;
