import StripeElementsWrapper from '@app/components/StripeElements/StripeElementsWrapper';
import StripeContext from '@app/contexts/stripeContext';
import ApiEndpoints from '@app/utils/data/apiEndpoints';
import { useStripe } from '@stripe/react-stripe-js';
import { ReactElement, useContext, useEffect, useRef } from 'react';
import { AddFundsScenario } from './AddFunds';

export const ConfirmFunds = StripeElementsWrapper(
  ({
    onSuccess,
    onFailure,
    setLoading,
    scenario,
  }: {
    onSuccess: () => void;
    onFailure: (e: unknown) => void;
    setLoading: (loading: boolean) => void;
    scenario: AddFundsScenario;
  }): ReactElement => {
    const { clientSecret } = useContext(StripeContext);
    const stripe = useStripe();
    const isConfirmingRef = useRef(false);

    useEffect(() => {
      setLoading(true);

      if (!stripe) {
        setLoading(false);
        return;
      }

      const confirmFunds = async () => {
        try {
          const url = new URL(window.location.href);
          url.searchParams.set('payment_type', scenario);

          const result = await stripe.confirmCardPayment(
            clientSecret,
            {
              return_url: url.toString(),
            },
            { handleActions: false },
          );
          const redirectUrl = result?.paymentIntent?.next_action?.redirect_to_url?.url;
          if (redirectUrl) {
            window.location.href = redirectUrl;
            return;
          }

          if (result.error) {
            onFailure(result.error);
            return;
          }

          if (result.paymentIntent.status === 'succeeded') {
            onSuccess();
          }
        } catch (e: unknown) {
          onFailure(e);
        }

        setLoading(false);
      };

      if (!clientSecret || !stripe) {
        return;
      }

      if (!isConfirmingRef.current) {
        isConfirmingRef.current = true;
        void confirmFunds();
      }
    }, [clientSecret, stripe, onSuccess, onFailure, setLoading, scenario]);

    return <></>;
  },
  ApiEndpoints.PAYMENTS_ENDPOINTS.TREASURY_INTEGRATION_ENDPOINT,
);
