import { Treasury } from '@app/@types/customer.types';
import { ErrorNotification } from '@app/components/layout';
import { Button } from '@mui/material';
import axios, { AxiosError } from 'axios';
import { ReactElement, useEffect, useState } from 'react';
import WalletBenefits from './WalletBenefits';

export const getStripeLink = async (): Promise<string> => {
  const { data: stripeVerificationLinkData } = await axios.get(
    '/treasury/onboarding/id_verification_link',
  );
  const { id_verification_link } = stripeVerificationLinkData.data;

  return id_verification_link;
};

const RedirectingToStripeMessage = () => (
  <div className="mt-6 flex flex-col items-center text-center leading-4">
    <h2 className="text-default-primary text-[24px] font-bold">
      Thanks for getting started with AtoB Wallet!
    </h2>
    <div className="text-default-secondary max-w-[500px]">
      <p className="my-6">
        We are now redirecting to our partner, Stripe, to complete the setup process.
      </p>
      <p className="mb-6">This should take about two minutes.</p>
    </div>
  </div>
);

const GoToStripeMessage = ({
  loadingLink,
  redirectUrl,
}: {
  loadingLink: boolean;
  redirectUrl: string;
}) => (
  <div className="mt-6 flex flex-col items-center text-center leading-4">
    <h2 className="text-default-primary text-[24px] font-bold">
      Thanks for getting started with AtoB Wallet!
    </h2>
    <div className="text-default-secondary max-w-[500px]">
      <p className="my-6">Please complete your enrollment below.</p>
      <p className="mb-6">This should take about two minutes.</p>
      {loadingLink ? (
        <div className="text-sm italic">Loading...</div>
      ) : (
        <a href={redirectUrl}>
          <Button color="primary">Complete enrollment</Button>
        </a>
      )}
    </div>
  </div>
);

const SetupComplete = () => (
  <div className="mt-12 flex flex-col items-center justify-items-center text-center leading-4">
    <h2 className="text-default-primary text-[24px] font-bold">
      Thanks for signing up for AtoB Wallet!
    </h2>
    <div className="text-default-secondary max-w-[500px]">
      <p className="my-6">Our partner, Stripe, is verifying your identity.</p>
      {/* TODO: Add back in when email/SMS comms are ready */}
      {/* <p className="my-6">
        We will notify you via SMS and email once your identity is verified and your account is all
        set up!
      </p> */}
      <p className="mb-6">This can take up to five minutes.</p>
    </div>
  </div>
);

export default function Onboarding({
  customerTreasuryDetails,
}: {
  customerTreasuryDetails: Treasury;
}): ReactElement | null {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
  const [loadingLink, setLoadingLink] = useState(false);

  const notEnrolled = customerTreasuryDetails?.status === 'not_enrolled';
  const startedEnrollment = customerTreasuryDetails?.status === 'started';
  const pendingEnrollment = customerTreasuryDetails?.status === 'pending_verification';
  const redirectingToStripeAutomatically =
    !loadingLink && redirectUrl === null && startedEnrollment;

  useEffect(() => {
    (async () => {
      if (startedEnrollment) {
        setLoading(true);
        setLoadingLink(true);
        const stripeLink = await getStripeLink();
        setRedirectUrl(stripeLink);
        setLoadingLink(false);
      }
    })();
  }, [startedEnrollment]);

  const enroll = async () => {
    setLoading(true);
    setError(null);

    try {
      await axios.post('/treasury/onboarding/enroll');
      const stripeLink = await getStripeLink();
      window.location.href = stripeLink;
    } catch (e) {
      setError((e as AxiosError).message);
    }

    setLoading(false);
  };

  if (notEnrolled) {
    return (
      <div>
        {error && <ErrorNotification error={error} />}
        <WalletBenefits onClick={enroll} loading={loading} />
      </div>
    );
  }

  if (redirectingToStripeAutomatically) {
    return (
      <div>
        <RedirectingToStripeMessage />
      </div>
    );
  }

  if (startedEnrollment && redirectUrl) {
    return (
      <div>
        <GoToStripeMessage loadingLink={loadingLink} redirectUrl={redirectUrl} />
      </div>
    );
  }

  if (pendingEnrollment) {
    return <SetupComplete />;
  }

  return null;
}
