import { faRefresh } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import classNames from 'classnames';
import React, { ReactElement, Fragment, ReactNode } from 'react';

export default function FilterGroup({
  children,
  className,
  clearFilters,
}: {
  children: ReactNode;
  className?: string;
  clearFilters?: () => void;
}): ReactElement {
  const childrenToRender = nonEmptyChildren(children);

  return (
    <div className="block w-full">
      <div
        className={classNames(
          'mx-2 flex w-full flex-col items-start space-y-2.5 pt-1 md:flex-row md:space-y-0',
          className,
        )}
        aria-label="Filters"
      >
        <div className="no-scrollbar flex w-full items-center space-x-2 overflow-x-auto md:w-fit">
          {childrenToRender.map((child, index) => {
            return <Fragment key={index}>{child}</Fragment>;
          })}
        </div>
        {clearFilters && (
          <div className="pl-2">
            <IconButton
              size="small"
              color="secondary"
              className="w-full md:!ml-auto md:w-auto"
              onClick={clearFilters}
            >
              <FontAwesomeIcon icon={faRefresh} className="text-default-primary h-5 w-5" />
            </IconButton>
          </div>
        )}
      </div>
    </div>
  );
}

const nonEmptyChildren = (children: React.ReactNode) => {
  return React.Children.toArray(children).filter((child) => child !== null);
};
