import { InstantPayoutRecipient } from '@app/@types/instant_payout_method.types';
import { createContext, useContext } from 'react';

export enum WalletTransferStep {
  Recipient = 1,
  TransferDetails = 2,
}

export const WalletTransferContext = createContext<{
  step: WalletTransferStep;
  goNextStep: (s?: WalletTransferStep) => void;
  goPrevStep: (s?: WalletTransferStep) => void;
  resetAndClose: () => void;
  onCompletedTransfer: () => void;
  walletBalance: string | null | undefined;
  recipients: InstantPayoutRecipient[];
  isFetchingRecipients: boolean;
  refetchRecipients: () => void;
  selectedRecipient: InstantPayoutRecipient | null;
  selectedRecipientID: InstantPayoutRecipient['id'] | null;
  setSelectedRecipientID: (recipient: InstantPayoutRecipient['id'] | null) => void;
  resetSelectedRecipient: () => void;
}>({
  step: WalletTransferStep.Recipient,
  goNextStep: () => {},
  goPrevStep: () => {},
  resetAndClose: () => {},
  onCompletedTransfer: () => {},
  walletBalance: '$0.00',
  recipients: [],
  isFetchingRecipients: false,
  refetchRecipients: () => {},
  selectedRecipient: null,
  selectedRecipientID: null,
  setSelectedRecipientID: () => {},
  resetSelectedRecipient: () => {},
});

export function useWalletTransfer() {
  const transferContext = useContext(WalletTransferContext);
  if (!transferContext) {
    throw new Error('useWalletTransfer must be used within WalletTransferContext');
  }
  return transferContext;
}
