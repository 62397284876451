import Modal, {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { formatDate } from '@atob-developers/shared/src/utils/formatters';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { ReactElement } from 'react';
import { TransferSummary } from '../TransferSummary';
import {
  computeEstimatedReceptionTime,
  computeTotalFeeAmount,
  FeeConfiguration,
  NormalizedDestination,
  PaymentMethodType,
  transferTypeMapping,
} from '../utils';

export const ConfirmationModal = ({
  open,
  onClose,
  destination,
  amount,
  fee,
  description,
  onConfirm,
  loading,
  method,
  buttonText,
  title,
  secondaryTitle,
}: {
  open: boolean;
  onClose: () => void;
  destination: NormalizedDestination;
  amount: string;
  fee?: FeeConfiguration;
  description: string;
  onConfirm: () => void;
  loading: boolean;
  method: PaymentMethodType;
  buttonText: string;
  title: string;
  secondaryTitle?: string | ReactElement;
}) => {
  const transferAmountCents = Math.round(parseFloat(amount) * 100);
  const feeAmount = fee && computeTotalFeeAmount({ transferAmountCents, feeData: fee });

  return (
    <Modal open={open} toggle={onClose} maxWidth="xs">
      <ModalHeader title={title} secondaryTitle={secondaryTitle} />
      <ModalBodyContent>
        <TransferSummary
          transferType={transferTypeMapping[method]}
          arrivalTime={formatDate({
            dateValue: computeEstimatedReceptionTime(method),
            pattern: 'MMMM D, YYYY',
          })}
          recipientName={destination.recipientName}
          fee={fee}
          feeAmount={feeAmount}
          description={description}
          amount={transferAmountCents}
        />
      </ModalBodyContent>
      <ModalFooter>
        <Button color="secondary" fullWidth onClick={onClose}>
          Back to edit
        </Button>
        <LoadingButton color="primary" fullWidth onClick={onConfirm} loading={loading}>
          {buttonText}
        </LoadingButton>
      </ModalFooter>
    </Modal>
  );
};
