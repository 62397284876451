import { Field, ErrorMessage, FieldProps } from 'formik';
import InputMask from 'react-input-mask';

interface PhoneNumberFieldProps {
  label: string;
  id: string;
  required?: boolean;
}

const PhoneNumberField = ({ label, id, required = false }: PhoneNumberFieldProps) => (
  <div className="field">
    <label className="text-default-primary block text-base font-bold" htmlFor={id}>
      {label}
      {required && <span className="text-red ml-1">*</span>}
    </label>
    <div className="border-soft bg-soft-primary text-default-primary w-full max-w-full rounded border p-2">
      <Field id={id} name={id}>
        {({ field }: FieldProps) => (
          <InputMask
            className="input"
            id={id}
            mask="(999) 999-9999"
            name={id}
            onBlur={field.onBlur}
            onChange={field.onChange}
            value={field.value}
          />
        )}
      </Field>
    </div>
    <ErrorMessage name={id} component="p" className="text-error" />
  </div>
);

export default PhoneNumberField;
