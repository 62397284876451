import { ELDProvider } from '@app/@types/eld-connect.types';
import InputField from '@app/components/elements/Fields/InputField';
import PasswordInputField from '@app/components/elements/Fields/PasswordInputField';
import { useConnectTelematics } from '@app/hooks/query/useConnectTelematics';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import * as Sentry from '@sentry/react';
import { Formik, FormikHelpers, FormikProps, FormikValues } from 'formik';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

const TelematicsConnectionForm = ({ provider }: { provider: ELDProvider }): ReactElement => {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const { connectTelematics, isConnecting } = useConnectTelematics(provider);

  const navigateOnSuccess = () => {
    addToast({
      type: 'success',
      title: 'Integration has been connected. Please wait a few minutes for your vehicles to sync.',
    });
    window.location.href = '/integrations';
  };

  const showErrorToast = (errorMsg: string) => {
    Sentry.captureException(`TelematicsConnectionForm Error: Error connecting to ${provider.id}`, {
      extra: { errorMsg },
    });
    addToast({ type: 'error', title: errorMsg });
  };

  const onSubmit = async (
    values: FormikValues,
    formikHelpers: FormikHelpers<Record<string, unknown>>,
  ) => {
    const params = provider.params?.map((param) => ({
      id: param.id,
      paramKey: param.paramKey,
      paramName: param.paramName,
      paramValue: values[param.paramKey],
    }));
    const requestData = {
      eld_credentials: params,
      eld_provider_id: provider.id,
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    const response = await connectTelematics(requestData);

    if (response instanceof Error) {
      showErrorToast(response.message);
    } else if (!isConnecting && /connected/i.test(response?.status)) {
      navigateOnSuccess();
    }
    formikHelpers.setSubmitting(false);
  };

  return (
    <Formik initialValues={{}} onSubmit={onSubmit}>
      {(props: FormikProps<Record<string, unknown>>) => (
        <form onSubmit={props.handleSubmit} className="w-1/2">
          <section className="modal-card-body">
            {provider.params?.map((param) =>
              param.paramType === 'password' ? (
                <PasswordInputField
                  id={param.paramKey}
                  key={param.paramKey}
                  label={param.paramName}
                />
              ) : (
                <InputField id={param.paramKey} key={param.paramKey} label={param.paramName} />
              ),
            )}
          </section>
          <footer className="mt-4 flex gap-x-5">
            <LoadingButton onClick={props.submitForm} size="medium" loading={props.isSubmitting}>
              Connect
            </LoadingButton>
            <Button
              size="medium"
              color="secondary"
              disabled={isConnecting}
              onClick={() => {
                navigate('/integrations');
              }}
            >
              Cancel
            </Button>
          </footer>
        </form>
      )}
    </Formik>
  );
};

export default TelematicsConnectionForm;
