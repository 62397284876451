import { FlattenedPayrollData } from '@app/@types/payroll.types';
import { formatCurrency } from '@atob-developers/shared/src/utils/formatters';
import { Button, TextField } from '@mui/material';
import { ReactElement } from 'react';

const PaymentDescriptionBody = ({
  driver,
  amountToPay,
  description,
  updateDescription,
}: {
  driver: FlattenedPayrollData;
  amountToPay: number;
  description: string;
  updateDescription: (_: string) => void;
}): ReactElement => {
  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    if (value.length <= 280) {
      updateDescription(value);
    }
  };
  return (
    <div>
      <div className="mb-4 flex flex-col items-center">
        <span className="text-default-primary mt-2 text-lg font-semibold">
          Pay {driver.first_name} {driver.last_name}
        </span>
        <span className="text-default-primary mt-2 text-xl font-semibold">
          {formatCurrency({ value: amountToPay, options: { fromCents: true } })}
        </span>
      </div>
      <div>
        <TextField
          label={
            <p className="text-default-primary">
              Add a payment description <span className="text-default-tertiary">(optional)</span>
            </p>
          }
          multiline
          name="comment"
          id="comment"
          className="border-soft text-default-primary mb-1 block w-full flex-1 resize-none rounded-md border p-1 ring-0 sm:text-sm"
          value={description}
          onChange={handleDescriptionChange}
          helperText={
            <p className={`${description.length < 280 ? 'text-default-secondary' : 'text-red'}`}>
              {description.length}
            </p>
          }
        />
      </div>
    </div>
  );
};
const PaymentDescriptionFooter = ({
  resetPage,
  isContinueButtonDisabled,
  confirmDescription,
}: {
  resetPage: () => void;
  isContinueButtonDisabled: boolean;
  confirmDescription: () => void;
}): ReactElement => {
  return (
    <footer className="flex w-full justify-end pb-8">
      <div className="flex max-w-[350px] gap-2">
        <Button color="secondary" size="medium" onClick={resetPage}>
          Cancel
        </Button>
        <Button size="medium" disabled={isContinueButtonDisabled} onClick={confirmDescription}>
          Continue
        </Button>
      </div>
    </footer>
  );
};

export { PaymentDescriptionBody, PaymentDescriptionFooter };
