import { ReactElement, ForwardedRef, PropsWithChildren, forwardRef } from 'react';
interface ChangeTextProps {
  onClick: () => void;
}

const ChangeText = forwardRef(
  (
    { onClick, children }: PropsWithChildren<ChangeTextProps>,
    ref: ForwardedRef<HTMLElement>,
  ): ReactElement => {
    return (
      <span ref={ref} onClick={onClick} className="text-green cursor-pointer text-sm underline">
        {children}
      </span>
    );
  },
);
ChangeText.displayName = 'ChangeText';

export default ChangeText;
