import { statesArray } from '@app/constants/states';
import { Address } from '@app/interfaces/address';
import { getEnvironment } from '@app/utils/environment';
import useScript from '@atob-developers/shared/src/hooks/useScript';
import { InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import classNames from 'classnames';
import { ReactElement, useCallback, useEffect, useRef } from 'react';
import './BillingAddressForm.css';

const BillingAddressForm = ({
  address,
  setAddress,
  errors,
  validateField,
}: {
  address: Address;
  setAddress: React.Dispatch<React.SetStateAction<Address>>;
  errors: Partial<Record<keyof Address, string>>;
  validateField: (field: keyof Address, value: string) => Promise<void>;
}): ReactElement => {
  const { VITE_GOOGLE_API_KEY } = getEnvironment();
  const mapsLoaded =
    useScript(
      `https://maps.googleapis.com/maps/api/js?libraries=geometry,places&key=${VITE_GOOGLE_API_KEY}&v=weekly&callback=Function.prototype`,
    ) === 'ready';
  const autoCompleteRef = useRef<google.maps.places.Autocomplete>();
  const address1Ref = useRef<HTMLInputElement>(null);
  const address2Ref = useRef<HTMLInputElement>(null);

  const onChangeAddress = useCallback(
    (value: string, nameOfField: string) => {
      validateField(nameOfField as keyof Address, value);
      setAddress((prev: Address) => ({
        ...prev,
        [nameOfField]: value,
      }));
    },
    [setAddress, validateField],
  );

  const fillInAddress = useCallback(() => {
    if (!autoCompleteRef.current) {
      return;
    }
    const place = autoCompleteRef.current.getPlace();

    if (!place || !place.address_components) {
      return;
    }

    setAddress({
      address1: '',
      address2: '',
      city: '',
      state: '',
      zip: '',
    });
    let address1 = '';

    for (const component of place.address_components) {
      const componentType = component.types[0];
      switch (componentType) {
        case 'street_number': {
          address1 = `${component.long_name} ${address1}`;
          break;
        }

        case 'route': {
          address1 += component.long_name;
          break;
        }

        case 'postal_code': {
          onChangeAddress(component.long_name, 'zip');
          break;
        }

        case 'locality': {
          onChangeAddress(component.long_name, 'city');
          break;
        }

        case 'administrative_area_level_1': {
          onChangeAddress(component.short_name, 'state');
          break;
        }
      }
    }

    onChangeAddress(address1, 'address1');

    address2Ref.current?.focus();
    if (address1Ref.current) {
      address1Ref.current.value = address1;
    }

    autoCompleteRef.current.set('place', undefined);
  }, [setAddress, onChangeAddress]);

  useEffect(() => {
    if (!mapsLoaded || !address1Ref.current || autoCompleteRef.current) {
      return;
    }
    autoCompleteRef.current = new google.maps.places.Autocomplete(address1Ref.current, {
      componentRestrictions: { country: 'us' },
      fields: ['address_components'],
      types: ['address'],
    });
    autoCompleteRef.current.addListener('place_changed', fillInAddress);
  }, [fillInAddress, mapsLoaded]);

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    validateField(name as keyof Address, value);
  };

  const onChangeField = (e: React.ChangeEvent<HTMLInputElement> | SelectChangeEvent) => {
    const { value, name } = e.target;
    onChangeAddress(value, name);
  };
  const onChangeAddressField = (e: React.ChangeEvent<HTMLInputElement>) => onChangeField(e);
  const onChangeState = (e: SelectChangeEvent) => onChangeField(e);

  const isValid = Object.values(errors).length === 0;

  const slotProps = { htmlInput: { className: 'font-medium' } };

  return (
    <div className="flex w-full flex-col gap-2">
      <InputLabel
        className={classNames('font-medium', isValid ? 'text-default-primary' : 'text-error')}
      >
        Address
      </InputLabel>
      <TextField
        fullWidth
        name="address1"
        placeholder="Address line"
        inputRef={address1Ref}
        autoComplete="address-line1"
        slotProps={slotProps}
        onChange={onChangeAddressField}
        onBlur={handleBlur}
        error={!!errors.address1}
        helperText={errors.address1}
      />
      <TextField
        fullWidth
        name="address2"
        placeholder="Suite"
        inputRef={address2Ref}
        autoComplete="address-line2"
        slotProps={slotProps}
        onChange={onChangeAddressField}
        onBlur={handleBlur}
        error={!!errors.address2}
        helperText={errors.address2}
      />
      <div className="flex justify-between gap-2">
        <TextField
          fullWidth
          name="city"
          placeholder="City"
          autoComplete="address-level2"
          slotProps={slotProps}
          value={address.city || ''}
          onChange={onChangeAddressField}
          onBlur={handleBlur}
          error={!!errors.city}
          helperText={errors.city}
        />
        <Select
          className="w-full font-medium"
          name="state"
          autoComplete="address-level1"
          value={address.state || ''}
          displayEmpty
          renderValue={(value) => value || <span className="text-default-secondary">State</span>}
          onChange={onChangeState}
          onBlur={handleBlur}
          error={!!errors.state}
        >
          {statesArray.map((state) => (
            <MenuItem value={state} key={state}>
              {state}
            </MenuItem>
          ))}
        </Select>
      </div>
      <TextField
        name="zip"
        placeholder="ZIP Code"
        autoComplete="postal-code"
        slotProps={{
          htmlInput: {
            maxLength: 5,
            className: slotProps.htmlInput.className,
          },
        }}
        value={address.zip || ''}
        onChange={onChangeAddressField}
        onBlur={handleBlur}
        error={!!errors.zip}
        helperText={errors.zip}
      />
    </div>
  );
};

export default BillingAddressForm;
