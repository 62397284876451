enum PAYMENTS_ENDPOINTS {
  PRIMARY_PAYMENT_METHOD_ENDPOINT = '/payments/payment_methods/primary',
  PAYMENT_INTEGRATION_ENDPOINT = `/integrations/payment`,
  TREASURY_INTEGRATION_ENDPOINT = `/integrations/treasury`,
  STRIPE_INTENTS_ENDPOINT = `/payments/intents`,
}

enum FUEL_CARD_PAYMENTS_ENDPOINTS {
  ESTIMATES_ENDPOINT = `/fuel_card_payments/estimates`,
  ISSUING_INTEGRATION_ENDPOINT = `/integrations/issuing`,
}

export default class ApiEndpoints {
  static readonly PAYMENTS_ENDPOINTS = PAYMENTS_ENDPOINTS;

  static readonly FUEL_CARD_PAYMENTS_ENDPOINTS = FUEL_CARD_PAYMENTS_ENDPOINTS;
}
