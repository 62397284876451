import { PaymentMethodCombined } from '@app/@types/payments.types';
import {
  FlattenedPayrollData,
  PayrollCalculation,
  PayrollCalculationBackendEnums,
  PayrollDriverState,
} from '@app/@types/payroll.types';
import { ErrorNotification } from '@app/components/layout';
import Skeleton from '@app/components/layout/Skeleton';
import StripeContext from '@app/contexts/stripeContext';
import usePayrollBillingCalculation from '@app/hooks/payroll/usePayrollBillingCalculation';
import useWindowWidth from '@app/hooks/useWindowWidth';
import Constants from '@app/utils/constants';
import { mapToBackendItemizationEnum, sumPayrollPayment } from '@app/utils/payroll-itemization';
import Modal, { ModalBodyContent, ModalHeader } from '@atob-developers/shared/src/components/Modal';
import { capitalize } from 'lodash-es';
import { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { isBankAccount, isDebitCard } from '../../../components/PaymentMethods/PaymentMethodUtils';
import { PayWithAccountUI } from '../../../components/StripeElements/PayWithBankAccount';
import { PayWithSavedCardUI } from '../../../components/StripeElements/PayWithDebitCard';
import {
  PaymentIntentOptions,
  usePaymentIntent,
} from '../../../components/StripeElements/PayWithPaymentMethod';
import {
  MobilePayrollPaymentBody,
  MobilePayrollPaymentContainer,
  MobilePayrollPaymentFooter,
  MobilePayrollPaymentTitle,
} from '../PaymentFlow/MobilePaymentContainer';
import {
  CalculationErrors,
  PaymentCalculation,
  PaymentCalculationBody,
  PaymentCalculationFooter,
  PaymentCalculatorTotal,
} from '../PaymentFlow/PaymentCalculation';
import {
  PaymentConfirmationCalculations,
  PaymentConfirmationDetails,
  PaymentConfirmationFooter,
} from '../PaymentFlow/PaymentConfirmation';
import {
  PaymentDescriptionBody,
  PaymentDescriptionFooter,
} from '../PaymentFlow/PaymentDescription';
import { DebitOption, PaymentSpeedFooter } from '../PaymentFlow/PaymentSpeed';
import PaymentSuccessful from '../PaymentSuccessful';

const { IntentTypes } = Constants;

type PaymentStep = 'Description' | 'Speed' | 'Confirmation' | 'Complete';
const getStepToTake = (
  descriptionConfirmed: boolean,
  paymentSpeedConfirmed: boolean,
  paymentSuccess: boolean,
): PaymentStep => {
  if (paymentSuccess) {
    return 'Complete';
  }
  if (!descriptionConfirmed && !paymentSpeedConfirmed) {
    return 'Description';
  } else if (descriptionConfirmed && !paymentSpeedConfirmed) {
    return 'Speed';
  } else {
    return 'Confirmation';
  }
};
export interface PayDriverModalProps {
  payNowOrCalculate: 'Pay now' | 'Calculate' | null;
  amountToPay: number;
  driver: FlattenedPayrollData;
  back: () => void;
  reset: () => void;
  updateDriverInState: (
    id: string,
    driverDetails: Partial<FlattenedPayrollData>,
    callback?: (updatedState: PayrollDriverState) => void,
  ) => void;
  paymentSourceDebit: PaymentMethodCombined | null;
  paymentDetails: PaymentCalculation;
  setPaymentDetails: React.Dispatch<React.SetStateAction<PaymentCalculation>>;
  calculationErrors: { [key in CalculationErrors]: string | null };
  setCalculationErrors: React.Dispatch<
    React.SetStateAction<{
      [key in CalculationErrors]: string | null;
    }>
  >;
  calculationDescriptionConfirmed: boolean;
  setPayrollError: (error: string) => void;
}

export default function PayDriverModal(props: PayDriverModalProps): ReactElement {
  const {
    payNowOrCalculate,
    amountToPay,
    driver,
    back,
    reset,
    paymentSourceDebit,
    updateDriverInState,
    paymentDetails,
    setPaymentDetails,
    calculationErrors,
    setCalculationErrors,
    calculationDescriptionConfirmed,
    setPayrollError,
  } = props;
  const { setClientSecret, resetPaymentIntent } = useContext(StripeContext);

  const [descriptionConfirmed, setDescriptionConfirmed] = useState(false);
  const [paymentSpeedConfirmed, setPaymentSpeedConfirmed] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [selectedPaymentMethod, setPaymentMethodSelected] = useState<PaymentMethodCombined | null>(
    null,
  );
  const { isSmallScreen } = useWindowWidth();

  const [fees, setAmountToPayInCents] = usePayrollBillingCalculation();
  const [isDebitSelected, setDebitSelected] = useState(true);

  useEffect(() => {
    if (!payNowOrCalculate) {
      return;
    }
    setAmountToPayInCents(sumPayrollPayment(paymentDetails).toString());
  }, [paymentDetails, payNowOrCalculate, setAmountToPayInCents]);

  const handleConfirmDescription = useCallback(() => {
    setDescriptionConfirmed(true);
  }, []);

  useEffect(() => {
    // Payment itemization screen has completed - so simulate the action being taken in the modal
    if (calculationDescriptionConfirmed) {
      handleConfirmDescription();
    }
  }, [calculationDescriptionConfirmed, handleConfirmDescription]);

  const resetPaymentFlow = useCallback(() => {
    setPaymentSpeedConfirmed(false);
    setDescriptionConfirmed(false);
    setPaymentSuccess(false);
    setPaymentMethodSelected(null);
    setClientSecret(null);
    resetPaymentIntent();
    setPaymentDetails({
      calculationMethod: 'Flat Amount Payroll',
      amount_cents: amountToPay,
      description: '',
      itemizations: [],
    });
    reset();
  }, [
    reset,
    amountToPay,
    resetPaymentIntent,
    setClientSecret,
    setPaymentSuccess,
    setPaymentDetails,
  ]);

  const handlePaymentSpeedConfirmed = (selectedPaymentMethod: PaymentMethodCombined | null) => {
    setPaymentSpeedConfirmed(true);
    setPaymentMethodSelected(selectedPaymentMethod);
  };

  const handleUpdateCalculation = useCallback(
    (calculationDetails: Omit<PaymentCalculation, 'itemizations'>) => {
      setPaymentDetails((details) => {
        return { ...details, ...calculationDetails };
      });
    },
    [setPaymentDetails],
  );

  const handleUpdateError = useCallback(
    (key: CalculationErrors, value: string | null) => {
      setCalculationErrors((errors) => {
        return { ...errors, [key]: value };
      });
    },
    [setCalculationErrors],
  );

  const [feesInfo, payrollBillingFields] =
    fees === 'billing-not-enabled' || fees === null
      ? [{ amount_in_cents: 0 }, { billing_policy: 1 }]
      : [
          { amount_in_cents: fees.fees.reduce((p, c) => p + c.amount_in_cents, 0) },
          { billing_policy: fees.billing_policy },
        ];

  const mapToBackendEnum = (method: PayrollCalculation): PayrollCalculationBackendEnums => {
    switch (method) {
      case 'Flat Amount Payroll':
        return 'calculation_flat_amount_payroll';
      case 'Flat Amount Other':
        return 'calculation_flat_amount_other';
      case 'Pay per Mile':
        return 'calculation_per_mile';
      case 'Hourly':
        return 'calculation_hourly';
      case 'Percentage of Load':
        return 'calculation_load';
    }
  };

  const createPayrollMetadata = () => {
    let metadata: PaymentIntentOptions;
    metadata = {
      driver_id: driver.id,
      ...payrollBillingFields,
      items: paymentDetails.itemizations
        .filter((i) => i.amount_cents > 0)
        .map((item) => {
          const { amount_cents, description, subcategory, category } = item;
          return {
            amount_cents: amount_cents,
            description: description,
            category: mapToBackendItemizationEnum(subcategory, category),
          };
        }),
    };
    if (paymentDetails.calculationMethod && paymentDetails.amount_cents > 0) {
      metadata = {
        ...metadata,
        calculation_method: mapToBackendEnum(paymentDetails.calculationMethod),
        ...(paymentDetails?.rateCents && { calculation_rate: paymentDetails.rateCents }),
        ...(paymentDetails?.units && { calculation_units: paymentDetails.units }),
        ...(paymentDetails?.amount_cents && { calculation_amount: paymentDetails.amount_cents }),
        ...(paymentDetails?.loadRate && {
          calculation_percentage_of_load: paymentDetails.loadRate,
        }),
        ...(paymentDetails?.loadValueCents && { calculation_load: paymentDetails.loadValueCents }),
      };
    }
    return metadata;
  };

  const shouldTriggerPaymentIntent =
    payNowOrCalculate !== null &&
    driver.id.length > 0 &&
    selectedPaymentMethod !== null &&
    fees !== null;

  const { error: savedPaymentMethodIntentError } = usePaymentIntent(
    shouldTriggerPaymentIntent,
    sumPayrollPayment(paymentDetails),
    isDebitCard(selectedPaymentMethod) ? feesInfo : { amount_in_cents: 0 },
    IntentTypes.PAYROLL_PAYMENT,
    // shouldTriggerPaymentIntent checks that this hook is only run when selectedPaymentMethod is not null
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    selectedPaymentMethod!,
    {
      payroll: createPayrollMetadata(),
      description: paymentDetails?.description || '',
    },
  );

  useEffect(() => {
    if (savedPaymentMethodIntentError) {
      setPayrollError(savedPaymentMethodIntentError);
      resetPaymentFlow();
    }
  }, [savedPaymentMethodIntentError, resetPaymentFlow, setPayrollError]);

  const formatPaymentMethodDetails = (
    paymentMethod: PaymentMethodCombined | null,
  ): {
    cardDescription: string | null;
    lastFour: string | null;
  } => {
    if (!paymentMethod) {
      return {
        cardDescription: null,
        lastFour: null,
      };
    } else if (isDebitCard(paymentMethod)) {
      return {
        cardDescription: `${capitalize(paymentMethod.brand)} debit card`,
        lastFour: paymentMethod.last_four,
      };
    } else if (isBankAccount(paymentMethod)) {
      return {
        cardDescription: `${capitalize(paymentMethod.institution_name)} bank account`,
        lastFour: paymentMethod.mask,
      };
    }
    return { cardDescription: '', lastFour: '' };
  };
  const { cardDescription: debitDescription, lastFour: lastFourDebit } =
    formatPaymentMethodDetails(paymentSourceDebit);

  const paymentStep = getStepToTake(descriptionConfirmed, paymentSpeedConfirmed, paymentSuccess);

  const renderPaymentConfirmationModal = ({
    onSubmitPayment,
    loading,
    error,
  }: {
    onSubmitPayment: () => void;
    loading: boolean;
    error: string;
  }) => {
    return (
      <div className="m-4">
        {error && <ErrorNotification error={error} />}
        <div className="flex flex-col">
          <PaymentConfirmationCalculations
            driver={driver}
            feesInfo={isDebitCard(selectedPaymentMethod) ? feesInfo : { amount_in_cents: 0 }}
            paymentDetails={paymentDetails}
          />
          <div className="border-soft my-4 border border-solid" />
          <PaymentConfirmationDetails
            paymentDetails={paymentDetails}
            speed={isBankAccount(selectedPaymentMethod) ? ' 3 - 4 business days' : '30 minutes'}
            {...formatPaymentMethodDetails(selectedPaymentMethod)}
          />
          <PaymentConfirmationFooter
            onSubmitPayment={onSubmitPayment}
            loading={loading}
            resetPage={resetPaymentFlow}
          />
        </div>
      </div>
    );
  };
  const renderPaymentSpeed = () => {
    return (
      <>
        <div className="mb-4">
          <DebitOption
            isDebitSelected={isDebitSelected}
            setDebitSelected={setDebitSelected}
            paymentSourceDebit={paymentSourceDebit}
            debitDescription={debitDescription}
            lastFourDebit={lastFourDebit}
            feesInfo={feesInfo}
          />
        </div>
        <PaymentSpeedFooter
          isContinueButtonDisabled={!isDebitSelected}
          resetPage={resetPaymentFlow}
          handleClick={() => {
            handlePaymentSpeedConfirmed(paymentSourceDebit);
          }}
        />
      </>
    );
  };

  const isModalOpen = !!payNowOrCalculate;

  if (payNowOrCalculate === 'Calculate' && paymentStep === 'Description') {
    const noPaymentSource = !paymentSourceDebit;
    const anyCalculationErrors =
      Object.values(calculationErrors).filter((message) => message !== null).length > 0;
    const isContinueButtonDisabled =
      noPaymentSource || !paymentDetails?.amount_cents || anyCalculationErrors;
    const humanReadableError = Object.values(calculationErrors)
      .filter((message): message is string => message !== null)
      .reduce((errorMessage, curError) => `${curError}. ${errorMessage}`, '');

    if (isSmallScreen) {
      return (
        <MobilePayrollPaymentContainer>
          <MobilePayrollPaymentTitle
            driverFullName={`${driver.first_name} ${driver.last_name}`}
            back={() => {
              resetPaymentFlow();
              back();
            }}
          />
          <MobilePayrollPaymentBody>
            {humanReadableError !== '' && (
              <ErrorNotification error={humanReadableError} className="w-full" />
            )}
            <PaymentCalculationBody
              default_calculation_method={driver.last_paid_calculation_method}
              updateCalculation={handleUpdateCalculation}
              updateError={handleUpdateError}
            />
          </MobilePayrollPaymentBody>
          <MobilePayrollPaymentFooter>
            <div className="border-soft w-full border border-solid shadow-lg"></div>
            <div className="flex w-full justify-between px-6 py-3">
              <PaymentCalculatorTotal paymentDetails={paymentDetails} />{' '}
            </div>
            <div className="border-soft w-full border border-solid"></div>
            <PaymentCalculationFooter
              isContinueButtonDisabled={isContinueButtonDisabled}
              confirmDescription={handleConfirmDescription}
              canDeleteItems={false}
              deleteItems={() => {}}
            />
          </MobilePayrollPaymentFooter>
        </MobilePayrollPaymentContainer>
      );
    }
  }
  switch (paymentStep) {
    case 'Description':
      return (
        <ModalContent open={isModalOpen} toggle={resetPaymentFlow} title="Payment Amount">
          <div>
            <PaymentDescriptionBody
              driver={driver}
              amountToPay={paymentDetails.amount_cents}
              description={paymentDetails?.description || ''}
              updateDescription={(description) => {
                setPaymentDetails({
                  ...paymentDetails,
                  description,
                });
              }}
            />
            <PaymentDescriptionFooter
              isContinueButtonDisabled={!paymentSourceDebit}
              resetPage={resetPaymentFlow}
              confirmDescription={handleConfirmDescription}
            />
          </div>
        </ModalContent>
      );
    case 'Speed':
      return (
        <ModalContent open={isModalOpen} toggle={resetPaymentFlow} title="Payroll Transfer Speed">
          <div>{renderPaymentSpeed()}</div>
        </ModalContent>
      );
    case 'Confirmation':
      return (
        <ModalContent title="Confirm Payment" toggle={resetPaymentFlow} open={isModalOpen}>
          {!fees ? (
            <>
              <Skeleton />
            </>
          ) : (
            <div>
              {isDebitCard(selectedPaymentMethod) ? (
                <PayWithSavedCardUI
                  onSuccess={() => {
                    paymentDetails?.calculationMethod &&
                      updateDriverInState(driver.id, {
                        last_paid_calculation_method: paymentDetails?.calculationMethod,
                      });
                    setPaymentSuccess(true);
                    setPayrollError('');
                  }}
                >
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                  {/* @ts-expect-error */}
                  {renderPaymentConfirmationModal}
                </PayWithSavedCardUI>
              ) : (
                <PayWithAccountUI
                  onSuccess={() => {
                    paymentDetails?.calculationMethod &&
                      updateDriverInState(driver.id, {
                        last_paid_calculation_method: paymentDetails?.calculationMethod,
                      });
                    setPaymentSuccess(true);
                    setPayrollError('');
                  }}
                >
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                  {/* @ts-expect-error */}
                  {renderPaymentConfirmationModal}
                </PayWithAccountUI>
              )}
            </div>
          )}
        </ModalContent>
      );
    case 'Complete': {
      const timeToPayout = isDebitCard(paymentSourceDebit)
        ? 'less than 30 minutes'
        : '3 - 4 business days';
      return (
        <ModalContent open={isModalOpen} toggle={resetPaymentFlow}>
          <div>
            <PaymentSuccessful
              title="Payout request initiated."
              text={`Payment will reach ${driver.first_name} in ${timeToPayout}. If it fails, you will be informed via email.`}
            />
          </div>
        </ModalContent>
      );
    }
  }
}
export const ModalContent = ({
  children,
  title = '',
  open,
  toggle,
}: {
  children?: ReactElement;
  title?: string;
  open: boolean;
  toggle: () => void;
}): ReactElement => (
  <Modal open={open} toggle={toggle}>
    <ModalHeader title={title} onClose={toggle} />
    <ModalBodyContent overflowVisible={true}>{children}</ModalBodyContent>
  </Modal>
);
