import { TelematicsSetting } from '@app/@types/card.types';
import CardSectionTitle from '@app/components/Cards/CardSectionTitle';
import CardRow from '@app/components/PriceDetailRow/CardRow';
import constants from '@app/utils/constants';
import logger from '@app/utils/datadog-logger';
import { Tooltip } from '@atob-developers/shared/src/components/Tooltip';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { CircularProgress, Select, MenuItem } from '@mui/material';
import axios from 'axios';
import { ReactElement, useState } from 'react';

interface AssignSectionTelematicsAuthProps {
  telematicsSetting: TelematicsSetting;
  cardId: string | number;
  readonly: boolean;
}

const OPTIONS = constants.TelematicsOptions;

const AssignSectionTelematicsAuth = ({
  telematicsSetting,
  cardId,
  readonly,
}: AssignSectionTelematicsAuthProps): ReactElement => {
  const [telematicsSettingDropdownValue, setTelematicsSettingDropdownValue] =
    useState(telematicsSetting);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();

  const updateTelematicsSetting = (newSetting: { id: string; name: string }) => {
    setLoading(true);
    logger.info('Toggling card telematics', { cardId, newSetting });
    axios
      .put(`/cards/${cardId}`, {
        card: { telematics_setting: newSetting.id },
      })
      .then(() => {
        logger.info('Toggled card telematics', { cardId, newSetting });
        addToast({
          type: 'success',
          title: `Telematics security is set to ${newSetting.name}`,
        });
        setTelematicsSettingDropdownValue(newSetting.id as TelematicsSetting);
      })
      .catch((err) => {
        logger.error('Failed toggling card telematics', { cardId, newSetting });
        addToast({
          type: 'error',
          title: err.message,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <div className="relative flex items-center justify-between space-x-2">
        <CardRow>
          <CardSectionTitle className="flex items-center space-x-2" text="Telematics Security" />
          <Tooltip
            align="bottom-end"
            title="Telematics security can alert you if transactions occur away from your fleet vehicles.
            You can review these transactions in the Suspicious Activity page under the Telematics section."
          />
        </CardRow>
      </div>
      <div className="text-default-secondary flex items-center text-sm font-normal lg:hidden xl:flex">
        Get alerted when cards are being used away from the vehicle location.
      </div>
      <div className="flex h-[70%] cursor-not-allowed items-center justify-start">
        <Select
          disabled={readonly}
          value={telematicsSettingDropdownValue}
          fullWidth
          onChange={(e) => {
            const item = OPTIONS.find((option) => option.id === e.target.value);
            item && updateTelematicsSetting(item);
          }}
          displayEmpty
          renderValue={(value) => {
            const item = OPTIONS.find((option) => option.id === value);
            if (!item) {
              return <span className="text-default-secondary font-normal">Select an option</span>;
            }
            return (
              <div className="text-default-primary flex-grow text-left">
                {loading ? (
                  <div className="flex items-center">
                    <CircularProgress size="20px" />
                  </div>
                ) : (
                  item.name
                )}
              </div>
            );
          }}
        >
          {OPTIONS.map((item) => (
            <MenuItem key={item.id} value={item.id} classes={{ root: 'flex-col items-start' }}>
              <strong className="text-default-primary">{item.name}</strong>
              <p className="text-default-primary">{item.description}</p>
            </MenuItem>
          ))}
        </Select>
      </div>
    </div>
  );
};

export default AssignSectionTelematicsAuth;
