import DefaultSideBar from '@atob-developers/shared/src/components/SideBar';
import { ReactElement, PropsWithChildren, ReactNode } from 'react';
type SidebarWrapperProps = PropsWithChildren<{
  title: ReactNode;
  open: boolean;
  setOpen: (val: boolean) => void;
}>;

export const SidebarWrapper = ({
  title,
  open,
  setOpen,
  children,
}: SidebarWrapperProps): ReactElement => (
  <div className={open ? `z-side-drawer bg-backdrop fixed inset-0 flex` : ''}>
    <DefaultSideBar
      open={open}
      toggle={() => {
        setOpen(false);
      }}
      title={title || ''}
    >
      {children}
    </DefaultSideBar>
  </div>
);
