import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import { Cell } from '@app/components/TableV2/Cell';
import { MobileListItem } from '@app/components/TableV2/MobileListItem';
import ResponsiveTable from '@app/components/TableV2/ResponsiveTable';
import { ColumnDefinition } from '@app/components/TableV2/Table';
import { getFileUploadModeFromStage, IftaFilingStage } from '@app/constants/iftaFiling';
import { useDeleteUploadedFile, useGetUploadedFiles } from '@app/hooks/query/useIftaFilingQuery';
import { Button } from '@mui/material';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

export type Files = {
  id: number;
  created_at: string;
  file_name: string;
  no_of_records: string;
};

type RowData = {
  s_no: number;
  id: number;
  upload_date: string;
  file_name: string;
  source: string;
};

const MobileVehicleItemRenderer = ({ row, onClick }: { row: RowData; onClick?: () => void }) => (
  <MobileListItem onClick={onClick}>
    <div className="font-bold">
      <Cell>{row.s_no}</Cell>
    </div>
    <div>{row.file_name}</div>
    <div>{row.upload_date}</div>
  </MobileListItem>
);

const DeleteUploadedFile = ({ id, fetchReport }: { id: string; fetchReport: () => void }) => {
  const { trigger } = useDeleteUploadedFile(id);
  const deleteFile = async () => {
    const response = await trigger();
    if (response?.data) {
      fetchReport();
    }
  };

  return (
    <div className="flex items-center justify-center gap-2">
      <div
        className="cursor-pointer transition-colors duration-200 hover:bg-gray-100"
        onClick={deleteFile}
      >
        delete
      </div>
    </div>
  );
};

const ReportUpload = (): ReactElement => {
  const navigate = useNavigate();
  const [rows, setRows] = useState<RowData[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams] = useSearchParams();
  const stage = searchParams.get('stage') || IftaFilingStage.INITIAL;

  const { mutate: getUploadedFiles, data: files } = useGetUploadedFiles(
    getFileUploadModeFromStage(stage),
  );

  const onClickNext = useCallback(() => {
    const nextStage = parseInt(stage) + 1;
    navigate(`/ifta-filing?stage=${nextStage}`);
  }, [stage, navigate]);

  useEffect(() => {
    if (files && Array.isArray(files.data) && files.data.length === 0) {
      navigate(`/ifta-filing?stage=${stage}&isUpload=true`);
    }
  }, [files, navigate, stage]);

  const onClickAddMore = () => {
    navigate(`/ifta-filing?stage=${stage}&isUpload=true`);
  };

  const COLUMNS = [
    {
      field: 's_no',
      headerName: 'S No.',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => <Cell>{params.row.s_no}</Cell>,
    },
    {
      field: 'id',
      headerName: 'Id',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => <Cell>{params.row.id}</Cell>,
    },
    {
      field: 'upload_date',
      headerName: 'Upload Date',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => <Cell>{params.row.upload_date}</Cell>,
    },
    {
      field: 'file_name',
      headerName: 'File Name',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => <Cell>{params.row.file_name}</Cell>,
    },
    {
      field: 'no_of_records',
      headerName: 'No of records',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => <Cell>{params.row.no_of_records}</Cell>,
    },
    {
      field: 'source',
      headerName: 'Source',
      minWidth: 150,
      flex: 1,
      renderCell: (params) => <Cell>{params.row.source}</Cell>,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      minWidth: 250,
      flex: 1,
      renderCell: (params) => (
        <Cell>
          <DeleteUploadedFile id={params.row.id} fetchReport={getUploadedFiles} />
        </Cell>
      ),
    },
  ] as ColumnDefinition[];

  useEffect(() => {
    if (files?.data && Array.isArray(files.data) && files.data.length > 0) {
      setRows(
        files.data.map((file: Files, index: number) => ({
          s_no: index + 1,
          id: file.id,
          upload_date: new Date(file.created_at).toLocaleDateString(),
          file_name: file.file_name,
          no_of_records: file.no_of_records,
          source: 'Manual Upload',
        })),
      );
      setIsLoading(false);
    }
  }, [files]);

  return (
    <>
      <RoundedCard className="relative flex-1 py-4">
        <div className="flex items-center justify-center bg-white">
          <h2 className="mb-4 text-lg font-semibold text-gray-800">
            {stage == IftaFilingStage.NON_ATOB_REPORTS
              ? 'Non AtoB fuel IFTA Report Uploads'
              : 'AtoB telematics IFTA Report Uploads'}
          </h2>
        </div>
        <ResponsiveTable
          columns={COLUMNS}
          data={rows || []}
          loading={isLoading}
          mobileItemRenderer={MobileVehicleItemRenderer}
        />
        <div className="flex h-fit w-full items-center justify-center">
          <Button
            className="mt-4 w-full px-6 py-2 sm:w-auto"
            size="small"
            onClick={onClickAddMore}
            data-testid={'start-ifta-filing-button'}
          >
            Add More
          </Button>
          <Button
            className="ml-4 mt-4 w-full px-6 py-2 sm:w-auto"
            size="small"
            onClick={onClickNext}
            data-testid={'start-ifta-filing-button'}
          >
            Next
          </Button>
        </div>
      </RoundedCard>
    </>
  );
};

export default ReportUpload;
