import AtobAppIcon from '@app/assets/images/svg/atob_app_icon.svg';
import { MarketingBanner } from '@app/components/Banner/Designs/MarketingBanner';
import FuelListingsMap from '@app/components/FuelListings/FuelListingsMap';
import SearchMoreButtonFixed from '@app/components/FuelListings/SearchMoreButtonFixed';
import MobileNavHeader from '@app/components/Navigation/MobileNavHeader';
import useIsDeviceAppEligible from '@app/components/popups/components/useIsDeviceAppEligible';
import useCustomer from '@app/hooks/useCustomer';
import useIsNativeApp from '@app/hooks/useIsNativeApp';
import { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';
import FleetTypePopup from './FleetTypePopup';
import { FuelMapContextProvider, useFuelMapContext } from './FuelMapContext';

export const FuelFinder = () => {
  const customer = useCustomer();

  const { showMoreButton, routing } = useFuelMapContext();

  return (
    <div className="h-mobile-screen w-full md:sticky md:top-0 md:h-screen">
      <FleetTypePopup customer={customer} />
      <div className="bg-soft-primary flex h-full flex-grow flex-row">
        <div className="border-soft flex w-full sm:w-1/2 sm:border-r md:w-[55%] xl:w-2/5">
          <Outlet />
        </div>
        <div className="hidden w-0 sm:relative sm:block sm:w-1/2 md:w-[45%] xl:w-3/5">
          <FuelListingsMap />
          {showMoreButton && !routing && (
            <div className="absolute left-1/2 top-8 -translate-x-1/2">
              <SearchMoreButtonFixed />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default function FuelMapPage(): ReactElement | null {
  const [deviceType] = useIsDeviceAppEligible();
  const { isNativeApp } = useIsNativeApp();
  if (isNativeApp) {
    return null;
  }

  return (
    <FuelMapContextProvider>
      <MobileNavHeader title="Fuel Map" />
      {(deviceType === 'android' || deviceType === 'ios') && (
        <div className="px-3 md:px-0">
          <MarketingBanner
            iconImg={<img alt="AtoB App Icon" src={AtobAppIcon} />}
            title="Pro Tip"
            nonDismissable={true}
            onClick={() => (window.location.href = 'https://atob-app.app.link/')}
            bannerKey="mweb_app_fuelfinder"
            actionText="Open the AtoB App"
          >
            Fuel Map is better in the app! View by location, get exclusive discounts, and manage
            your entire account, only in the AtoB app.
          </MarketingBanner>
        </div>
      )}
      <FuelFinder />
    </FuelMapContextProvider>
  );
}
