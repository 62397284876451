import { CesDetails } from '@app/components/Cards/ReactivateCesOrUnlimitedOffer/CesDetails';
import { CesOrUnlimitedSelect } from '@app/components/Cards/ReactivateCesOrUnlimitedOffer/CesOrUnlimitedSelect';
import { ReactivatedSuccessfullyCesOrUnlimitedOffer } from '@app/components/Cards/ReactivateCesOrUnlimitedOffer/ReactivatedSuccessfullyCesOrUnlimitedOffer';
import { UnlimitedDetails } from '@app/components/Cards/ReactivateCesOrUnlimitedOffer/UnlimitedDetails';
import { Loading } from '@app/components/layout';
import { guardAxiosError } from '@app/utils/error/guards';
import Modal from '@atob-developers/shared/src/components/Modal';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import axios from 'axios';
import { ReactElement, useState } from 'react';

export type ReactivateCesOrUnlimitedParams = {
  reactivation_option: ReactivationOption;
  offer_accepted: boolean;
};

export type ReactivationOption = 'ces' | 'unlimited';

const ReactivateCesOrUnlimitedOfferModal = ({
  open,
  onClose,
  onSuccessCallback,
}: {
  open: boolean;
  onClose: () => void;
  onSuccessCallback: () => void;
}): ReactElement => {
  const [params, setParams] = useState<ReactivateCesOrUnlimitedParams>({
    reactivation_option: 'ces',
    offer_accepted: false,
  });

  type Screen =
    | 'select'
    | 'accept_details_ces'
    | 'accept_details_unlimited'
    | 'success_reactivated';

  const initialScreen: Screen = 'select';
  const [screen, setScreen] = useState<Screen>(initialScreen);

  const [loading, setLoading] = useState<boolean>(false);
  const { addToast } = useToasts();

  /*
    Screen 1: select between CES and UL reactivation
      store selection in memory
    Screen 2: more info about selected option, "I read/accept" checkmark below with "reactivate" button
      call appropriate customer/accept_ces_fee or elect_unlimited API
  */
  const screenSequenceCes: Screen[] = ['select', 'accept_details_ces', 'success_reactivated'];

  const screenSequenceUnlimited: Screen[] = [
    'select',
    'accept_details_unlimited',
    'success_reactivated',
  ];

  const getNextScreen: (_: Screen) => () => void = (currentScreen: Screen) => {
    const sequence =
      params.reactivation_option === 'ces' ? screenSequenceCes : screenSequenceUnlimited;
    const currentIndex = sequence.indexOf(currentScreen);
    return () => setScreen(sequence[currentIndex + 1]);
  };

  const getPreviousScreen: (_: Screen) => () => void = (currentScreen: Screen) => {
    const sequence =
      params.reactivation_option === 'ces' ? screenSequenceCes : screenSequenceUnlimited;
    const currentIndex = sequence.indexOf(currentScreen);
    return () => setScreen(sequence[currentIndex - 1]);
  };

  const confirmCes = async () => {
    try {
      setLoading(true);
      await axios.post('/customer/accept_ces_fee');
    } catch (e) {
      if (guardAxiosError(e)) {
        addToast({
          type: 'error',
          title: 'Something went wrong! Please try again or contact support if the issue persists',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const confirmUnlimited = async () => {
    try {
      setLoading(true);
      await axios.post('/customer/elect_unlimited');
    } catch (e) {
      if (guardAxiosError(e)) {
        addToast({
          type: 'error',
          title: 'Something went wrong! Please try again or contact support if the issue persists',
        });
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSuccessfulReactivation = () => {
    onSuccessCallback();
    onClose();
  };

  const render = () => {
    if (loading) return <Loading />;
    switch (screen) {
      case 'select':
        return (
          <CesOrUnlimitedSelect
            params={params}
            setParams={setParams}
            onNext={getNextScreen('select')}
            closeModal={onClose}
          />
        );
      case 'accept_details_ces':
        return (
          <CesDetails
            params={params}
            setParams={setParams}
            onNext={() => {
              confirmCes();
              getNextScreen('accept_details_ces')();
            }}
            onBack={getPreviousScreen('accept_details_ces')}
            closeModal={onClose}
          />
        );
      case 'accept_details_unlimited':
        return (
          <UnlimitedDetails
            params={params}
            setParams={setParams}
            onNext={() => {
              confirmUnlimited();
              getNextScreen('accept_details_unlimited')();
            }}
            onBack={getPreviousScreen('accept_details_unlimited')}
            closeModal={onClose}
          />
        );
      case 'success_reactivated':
        return (
          <ReactivatedSuccessfullyCesOrUnlimitedOffer
            params={params}
            closeModal={handleSuccessfulReactivation}
          />
        );
    }
  };

  return (
    <Modal open={open} toggle={onClose}>
      {render()}
    </Modal>
  );
};

export default ReactivateCesOrUnlimitedOfferModal;
