import { InstantPayoutRecipient } from '@app/@types/instant_payout_method.types';
import Avatar from '@app/components/Avatar/Avatar';
import RoundedCard from '@app/components/RoundedCard/RoundedCard';

type Props = {
  recipient?: InstantPayoutRecipient;
};

export default function RecipientCard({ recipient }: Props) {
  return (
    <RoundedCard className="flex items-center justify-between gap-3 p-5">
      <div className="flex gap-3">
        <Avatar
          size="lg"
          initials={`
            ${recipient?.name?.split(' ')?.[0]?.[0] ?? 'A'}${
              recipient?.name?.split(' ')?.[1]?.[0] ?? ''
            }`}
        />
        <div className="flex flex-col justify-center">
          <p className="text-default-primary font-semibold">{recipient?.name}</p>
          {recipient?.nickname && (
            <p className="text-default-primary text-sm">{recipient.nickname}</p>
          )}
        </div>
      </div>
    </RoundedCard>
  );
}
