import {
  useConnectTelematicsOAuth,
  useTelematicsProvider,
} from '@app/hooks/query/useConnectTelematics';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip, Button } from '@mui/material';

export default function ConnectTelematicsCard() {
  const { mutate } = useTelematicsProvider();
  const { trigger: startTelematicsFlow, isMutating: isGettingOAuthURL } = useConnectTelematicsOAuth(
    undefined,
    mutate,
  );

  return (
    <div className="border-soft bg-soft-primary shadow-marker flex flex-col gap-4 rounded-lg border p-6">
      <div className="flex flex-wrap items-center justify-between gap-2">
        <p className="text-lg font-semibold">Connect telematics</p>
        <div className="flex gap-2">
          <Chip color="green" label="Recommended" />
          <Chip color="green" label="2 min" />
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <p className="text-default-secondary">
          Your drivers and vehicles will remain synced with Telematics.
        </p>
        <p className="text-default-secondary">
          You won't need to make changes manually, whether it's adding, updating, or deactivating
          drivers or vehicles.
        </p>
      </div>
      <Button size="medium" disabled={isGettingOAuthURL} onClick={startTelematicsFlow}>
        Connect Telematics provider
      </Button>
      <div className="flex flex-col gap-2">
        <p className="text-default-primary font-semibold">New benefits you'll get:</p>
        <div className="text-default-primary flex items-center gap-2">
          <FontAwesomeIcon icon={faCheck} className="text-default-primary" />
          <p>Higher credit limit</p>
        </div>
        <div className="text-default-primary flex items-center gap-2">
          <FontAwesomeIcon icon={faCheck} className="text-default-primary" />
          <p>Automatic fleet syncing</p>
        </div>
        <div className="text-default-primary flex items-center gap-2">
          <FontAwesomeIcon icon={faCheck} className="text-default-primary" />
          <p>Smart discount offers</p>
        </div>
        <div className="text-default-primary flex items-center gap-2">
          <FontAwesomeIcon icon={faCheck} className="text-default-primary" />
          <p>Enhanced fraud protection</p>
        </div>
      </div>
    </div>
  );
}
