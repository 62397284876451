import { SettingsCurrentValue } from '@app/components/Cards/Settings/SettingsCurrentValue';
import { SettingsPageEntityType } from '@app/constants/settings';
import { SETTINGS_ROW_OPTIONS } from '@app/constants/settingsRow';
import { statesArray } from '@app/constants/states';
import { IAddressProps } from '@app/pages/Settings/SettingsPageRow';
import { InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import React, { ReactElement } from 'react';

interface StateOptions {
  id: string;
  value: string;
}

interface AddressFormProps {
  type: SettingsPageEntityType.COMPANY_ADDRESS | SettingsPageEntityType.CARD_SHIPPING_ADDRESS;
  values: Record<string, string | null>;
  errors: Record<string, string | undefined>;
  setValues: (values: Record<string, string | null>) => void;
  setErrors: (errors: Record<string, string | undefined>) => void;
  currentAddress: IAddressProps;
}

export const AddressForm = ({
  type,
  values,
  errors,
  setErrors,
  setValues,
  currentAddress,
}: AddressFormProps): ReactElement => {
  const options: StateOptions[] = statesArray.map((state, index) => ({
    id: index.toString(),
    value: state,
  }));

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    setErrors({ ...errors, [name]: undefined });
    setValues({
      ...values,
      [name]: event.target.value,
    });
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    const name = event.target.name;
    setErrors({ ...errors, [name]: undefined });

    const selectedState = options.find((option) => option.id === event.target.value)?.value || '';
    setValues({
      ...values,
      [name]: selectedState,
    });
  };

  return (
    <div className="flex flex-col gap-2">
      <div className="bg-soft-secondary flex flex-col gap-2 rounded-lg p-5">
        <p className="text-default-secondary flex items-center text-xs font-normal uppercase">
          {`current ${SETTINGS_ROW_OPTIONS[type].title}`}
        </p>
        <SettingsCurrentValue type={type} value={currentAddress} />
      </div>
      <div className="flex flex-col gap-2">
        <InputLabel className="text-default-primary block text-sm font-semibold">
          {type === SettingsPageEntityType.COMPANY_ADDRESS
            ? 'New Company Address'
            : 'New Card Shipping Address'}
        </InputLabel>
        <div className="flex flex-col gap-3">
          <TextField
            name="address1"
            placeholder="Address Line One"
            error={!!errors.address1}
            helperText={errors.address1}
            value={values.address1}
            onChange={handleTextFieldChange}
          />
          <TextField
            name="address2"
            placeholder="Address Line Two (optional)"
            value={values.address2}
            onChange={handleTextFieldChange}
          />
          <div className="flex justify-between gap-3">
            <TextField
              fullWidth
              name="city"
              placeholder="City"
              error={!!errors.city}
              helperText={errors.city}
              value={values.city}
              onChange={handleTextFieldChange}
            />
            <Select
              fullWidth
              value={options.find((option) => option.value === values.state)?.id || ''}
              onChange={handleSelectChange}
              name="state"
              renderValue={(value) => {
                const selectedOption = options.find((option) => option.id === value);
                return selectedOption?.value || 'State';
              }}
              error={!!errors.state}
              displayEmpty
            >
              {options.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {option.value}
                </MenuItem>
              ))}
            </Select>
            <TextField
              fullWidth
              name="zip"
              placeholder="Zip"
              error={!!errors.zip}
              helperText={errors.zip}
              value={values.zip}
              onChange={handleTextFieldChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
