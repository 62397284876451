import { Box, Tab as MUITab, Tabs } from '@mui/material';
import classNames from 'classnames';

type TabProps<T extends string> = {
  tabs: Tab<T>[];
  selectedTab: T;
  handleTabChange: (tab: T) => void;
  className?: string;
};

export type Tab<T extends string> = {
  name: string;
  tab: T;
};

function UnderlinedTabs<T extends string>({
  tabs,
  selectedTab,
  handleTabChange,
  className = '',
}: TabProps<T>) {
  if (tabs.length === 0) {
    return null;
  }

  return (
    <Box className="border-soft border-b">
      <Tabs
        value={selectedTab}
        onChange={(_event, newValue: T) => handleTabChange(newValue)}
        variant="scrollable"
        scrollButtons="auto"
        classes={{ root: classNames('h-8 md:h-auto min-h-[32px] md:min-h-auto', className) }}
      >
        {tabs.map((tab) => (
          <MUITab
            label={tab.name}
            value={tab.tab}
            key={tab.tab}
            classes={{
              root: 'h-8 md:h-auto min-h-[32px] md:min-h-auto mt-0 text-sm p-0 md:pt-4 pb-2 normal-case items-start w-auto min-w-0 me-8 text-default-primary',
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
}

export default UnderlinedTabs;
