import { InputLabel, ListItemText, MenuItem, Select, TextField } from '@mui/material';
import { ReactElement } from 'react';
import DefaultPrompt from '../Prompt/DefaultPrompt';
import { VehicleReducer } from './useVehicleFormReducer';

type VehicleFormProps = {
  reducer: VehicleReducer;
};

const FUEL_TYPES = [
  { id: 'diesel', value: 'diesel', label: 'Diesel' },
  { id: 'unleaded', value: 'unleaded', label: 'Regular' },
  { id: 'plus', value: 'plus', label: 'Midgrade and below' },
  { id: 'super', value: 'super', label: 'Premium and below' },
];

export default function VehicleForm({ reducer }: VehicleFormProps): ReactElement {
  const { vehicle, setVehicleProperty, setErrors } = reducer;

  if (!reducer.editing) {
    return (
      <div className="flex flex-col gap-8">
        <div className="space-y-2 text-sm font-medium">
          <p className="text-default-secondary">Name</p>
          <p className="text-default-primary">{vehicle.name || '-'}</p>
        </div>
        <div className="space-y-2 text-sm font-medium">
          <p className="text-default-secondary">VIN</p>
          <p className="text-default-primary">{vehicle.vin || '-'}</p>
        </div>
        <div className="space-y-2 text-sm font-medium">
          <p className="text-default-secondary">Fuel Type</p>
          <p className="text-default-primary capitalize">{vehicle.fuel_type || '-'}</p>
        </div>
        <div className="space-y-2 text-sm font-medium">
          <p className="text-default-secondary">Tank Capacity (Gallons)</p>
          <p className="text-default-primary">{vehicle.tank_capacity_gallons || '-'}</p>
        </div>
        <VehicleIntegrationField integrationConnections={vehicle.integration_connections} />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-8">
      {reducer.editing && vehicle.telematics_vehicle && (
        <div className="text-default-secondary text-sm italic">
          This vehicle is synced from your telematics provider. Please update names and VINs with
          your telematics provider, and the changes will be synced over shortly.
        </div>
      )}
      {vehicle.errors?.map((error, idx) => (
        <DefaultPrompt
          key={idx}
          message={error}
          clickHandler={() => {
            const errors = vehicle.errors;
            errors.splice(idx, 1);
            setErrors(errors);
          }}
          error
        />
      ))}
      <TextField
        fullWidth
        required
        label="Name"
        placeholder="Name"
        size="small"
        value={vehicle.name ?? ''}
        onChange={(e) => setVehicleProperty('name', e.target.value)}
        slotProps={{
          htmlInput: {
            'data-testid': `input:name`,
          },
        }}
      />
      <TextField
        fullWidth
        label="VIN"
        placeholder="VIN"
        size="small"
        value={vehicle.vin ?? ''}
        onChange={(e) => setVehicleProperty('vin', e.target.value)}
        slotProps={{
          htmlInput: {
            'data-testid': `input:vin`,
          },
        }}
      />
      <div className="space-y-2">
        <InputLabel id="input:fuel_type" className="text-default-primary text-sm font-medium">
          Fuel Type
        </InputLabel>
        <Select
          fullWidth
          displayEmpty
          labelId="input:fuel_type"
          label="Fuel Type"
          size="small"
          value={vehicle?.fuel_type ?? ''}
          onChange={(e) => {
            if (e.target.value) {
              setVehicleProperty('fuel_type', e.target.value);
            }
          }}
          renderValue={(value) => {
            const item = FUEL_TYPES.find((item) => item.id === value);
            if (!item) {
              return <span className="text-default-secondary">Select Fuel Type</span>;
            }

            return item.label;
          }}
        >
          {FUEL_TYPES.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              <ListItemText>{item.label}</ListItemText>
            </MenuItem>
          ))}
        </Select>
      </div>
      <TextField
        fullWidth
        label="Tank Capacity (Gallons)"
        placeholder="Tank Capacity"
        size="small"
        value={vehicle.tank_capacity_gallons ?? ''}
        onChange={(e) => setVehicleProperty('tank_capacity_gallons', e.target.value)}
        slotProps={{
          htmlInput: {
            'data-testid': `input:tank_capacity_gallons`,
          },
        }}
      />
      <VehicleIntegrationField integrationConnections={vehicle.integration_connections} />
    </div>
  );
}

function VehicleIntegrationField({
  integrationConnections,
}: {
  integrationConnections?: string[];
}) {
  if (!integrationConnections || integrationConnections.length === 0) {
    return null;
  }

  return (
    <div className="space-y-2 text-sm font-medium">
      <InputLabel className="text-default-primary">Synced</InputLabel>
      <div>
        {integrationConnections.map((connectionName: string) => (
          <p key={connectionName}>{connectionName}</p>
        ))}
      </div>
    </div>
  );
}
