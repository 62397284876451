import { DeviceData } from '@app/@types/device.types';
import { ErrorNotification } from '@app/components/layout';
import Modal, {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { LoadingButton } from '@mui/lab';
import axios, { AxiosError } from 'axios';
import { ReactElement, useState } from 'react';

interface ArchiveDeviceModalProps {
  isActive: boolean;
  reset: () => void;
  device: DeviceData;
}

export default function ArchiveDeviceModal({
  isActive,
  reset,
  device,
}: ArchiveDeviceModalProps): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { addToast } = useToasts();

  const deleteDevice = async () => {
    try {
      setIsLoading(true);
      await axios.delete(`/devices/${device.id}`);
      reset();
      addToast({
        type: 'success',
        title: `${device.name} successfully archived`,
      });
    } catch (e: unknown) {
      setError((e as AxiosError).message);
    }

    setIsLoading(false);
  };

  return (
    <Modal open={isActive}>
      <ModalHeader title={`Remove ${device.name}`} onClose={reset} />
      <ModalBodyContent>
        {error && <ErrorNotification error={error} />}
        <p className="mb-4">This will remove {device.name} from your list of devices.</p>
      </ModalBodyContent>
      <ModalFooter>
        <LoadingButton size="small" loading={isLoading} onClick={deleteDevice}>
          Remove {device.name ?? ''}
        </LoadingButton>
      </ModalFooter>
    </Modal>
  );
}
