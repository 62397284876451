import Header from '@app/components/Navigation/Header';
import PageContentWrapper from '@app/components/wrappers/PageContentWrapper';
import { IFTA_FILING_FILTERS } from '@app/constants/iftaFiling';
import UnderlinedTabs from '@atob-developers/shared/src/components/UnderlinedTabs';
import { ReactElement } from 'react';
import { useSearchParams } from 'react-router-dom';
import DownloadIftaReport from './DownloadIftaReport';
import FetchingTransaction from './FetchingTransaction';
import GeneratingReport from './GeneratingReport';
import IftaReportFromAtoBCard from './IftaReportFromAtoBCard';
import IftaReportFromOtherFuelCard from './IftaReportFromOtherFuelCard';
import StartIftaFiling from './StartIftaFiling';

const IFTA_FILING_TABS = Object.freeze([
  {
    name: IFTA_FILING_FILTERS.DASHBOARD,
    tab: IFTA_FILING_FILTERS.DASHBOARD,
  },
]);
const filteredTabs = IFTA_FILING_TABS.map((t) => ({
  name: t.name,
  tab: t.tab,
}));
export default function IftaFilingPage(): ReactElement {
  const [searchParams] = useSearchParams();
  const stage = searchParams.get('stage');

  return (
    <PageContentWrapper
      className="pb-12"
      header={<Header title="IFTA Filing" />}
      pageTabs={
        <UnderlinedTabs
          selectedTab={IFTA_FILING_FILTERS.DASHBOARD}
          tabs={filteredTabs}
          handleTabChange={() => {}}
        />
      }
    >
      {stage == '0' ? (
        <StartIftaFiling />
      ) : stage == '1' ? (
        <FetchingTransaction />
      ) : stage == '2' ? (
        <IftaReportFromOtherFuelCard />
      ) : stage == '3' ? (
        <IftaReportFromAtoBCard />
      ) : stage == '4' ? (
        <GeneratingReport />
      ) : stage == '5' ? (
        <DownloadIftaReport />
      ) : null}
    </PageContentWrapper>
  );
}
