import { ELDProvider } from '@app/@types/eld-connect.types';
import Header from '@app/components/Navigation/Header';
import { Loading } from '@app/components/layout';
import PageContentWrapper from '@app/components/wrappers/PageContentWrapper';
import { useTelematicsProvider } from '@app/hooks/query/useConnectTelematics';
import React, { ReactElement } from 'react';
import { useParams } from 'react-router-dom';

import TelematicsConnectionForm from './TelematicsConnectionForm';

const TelematicsConnectionFormPage: React.FC = (): ReactElement => {
  const { providerId } = useParams();
  const { data } = useTelematicsProvider();
  const providers = data?.data.providers;
  const provider = providers?.find((p: ELDProvider) => p.id === providerId);

  if (!provider) {
    return <Loading />;
  }

  return (
    <PageContentWrapper header={<Header title={`Connect to ${provider.displayName}`} />}>
      <p>
        Connect AtoB to {provider.displayName} by entering the requested credentials below. You can
        disconnect at any time.
      </p>
      <div className="container flex w-full flex-col items-center gap-6 md:flex-row md:items-stretch md:gap-16">
        <TelematicsConnectionForm provider={provider} />
      </div>
    </PageContentWrapper>
  );
};

export default TelematicsConnectionFormPage;
