import { useReceiptImageQuery } from '@app/hooks/query/useReceiptImageQuery';
import Modal, {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { Button } from '@mui/material';
import axios from 'axios';
import { ReactElement, useCallback, useMemo } from 'react';

interface ReceiptImageModalProps {
  open: boolean;
  toggle: () => void;
  receiptImageId: string | null;
}

export function ReceiptImageModal({
  open,
  toggle,
  receiptImageId,
}: ReceiptImageModalProps): ReactElement {
  const { data: imageBlob } = useReceiptImageQuery(receiptImageId);
  const blobUrl = useMemo(
    () => (imageBlob ? URL.createObjectURL(imageBlob) : undefined),
    [imageBlob],
  );

  // imageBlob.type comes in the form "image/png", we want to grab just "png"
  // to create the filename for downloading
  const blobType = useMemo(() => (imageBlob ? imageBlob.type.split('/')[1] : null), [imageBlob]);

  const deleteReceiptImage = useCallback(() => {
    if (window.confirm('Are you sure you want to delete this receipt?')) {
      axios.delete(`/receipt_images/${receiptImageId}`);
      toggle();
    }
  }, [receiptImageId, toggle]);

  return (
    <Modal open={open} toggle={toggle}>
      <ModalHeader title="" onClose={toggle} />
      <ModalBodyContent>
        <img src={blobUrl} alt="The receipt uploaded by the driver" />
      </ModalBodyContent>
      <ModalFooter>
        <Button color="secondary" size="small" onClick={deleteReceiptImage}>
          Delete
        </Button>
        <Button size="small" href={blobUrl || ''} download={`receipt.${blobType}`}>
          Download
        </Button>
      </ModalFooter>
    </Modal>
  );
}
