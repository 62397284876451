import SpinnerBoundary from '@app/components/Spinner/SpinnerBoundary';
import useCustomer from '@app/hooks/useCustomer';
import { AddFundsModalDataWrapper } from '@app/pages/Wallet/WalletOverview/TransferFunds/AddFunds';
import {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { Button } from '@mui/material';
import { ReactElement, useState } from 'react';
import { SecurityDeposit } from './SecurityDepositOfferModal';

export const SecurityDepositOfferDetails = ({
  securityDeposit,
  onClose,
  continueOnboarding,
}: {
  securityDeposit: SecurityDeposit | null;
  onClose: () => void;
  continueOnboarding?: () => void;
}): ReactElement => {
  const [addFundsModalOpen, setAddFundsModalOpen] = useState(false);

  const customer = useCustomer();

  if (!securityDeposit) {
    return (
      <ModalBodyContent overflowVisible={true}>
        <div className="h-80">
          <SpinnerBoundary />
        </div>
      </ModalBodyContent>
    );
  }

  return (
    <>
      <ModalHeader title="Security Deposit Details" onClose={onClose} />
      <ModalBodyContent overflowVisible={true}>
        Here are the details for your Security Deposit.
        <div className="bg-strong-secondary mb-6 mt-6 rounded-lg p-4">
          <div className="mb-4 flex justify-between">
            <div className="text-default-secondary">Credit Limit</div>
            <div className="text-default-primary font-bold">
              ${securityDeposit.credit_limit_cents / 100}
            </div>
          </div>
          <div className="flex justify-between">
            <div className="text-default-secondary">Security Deposit</div>
            <div className="text-default-primary font-bold">
              ${securityDeposit.amount_cents / 100}
            </div>
          </div>
        </div>
        <AddFundsModalDataWrapper
          customerCompanyName={customer.company_name}
          customerCompanyAddress={customer.company_address}
          onAddFunds={() => (window.location.href = '/')}
          continueOnboarding={continueOnboarding}
          modalActive={addFundsModalOpen}
          setModalActive={setAddFundsModalOpen}
          minimumDepositAmountCents={securityDeposit.amount_cents}
          initialAmount={securityDeposit.amount_cents}
          initialDescription={'Security Deposit Payment'}
          scenario={'security_deposit'}
        />
      </ModalBodyContent>
      <ModalFooter>
        <Button size="medium" onClick={() => setAddFundsModalOpen(true)} fullWidth>
          Continue
        </Button>
      </ModalFooter>
    </>
  );
};
