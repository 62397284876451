import CardNumber from '@app/components/CardDetails/CardNumber';
import SpendingLimitsCard from '@app/components/CardDetails/SpendingLimitsCard/SpendingLimitsCard';
import {
  CardWorkflowState,
  ToggleCardActivation,
} from '@app/components/CardDetails/ToggleCardActivation/ToggleCardActivation';
import { CardSensitiveData } from '@app/components/Cards/CardSensitiveData/CardSensitiveData';
import SpendAnalysis from '@app/components/SpendAnalysis/SpendAnalysis';
import useCustomer from '@app/hooks/useCustomer';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import Card from '@atob-developers/shared/src/components/Card';
import UnderlinedTabs from '@atob-developers/shared/src/components/UnderlinedTabs';
import { ReactElement, useState } from 'react';
import AssignSectionCard from '../../components/CardDetails/AssignSection/AssignSectionCard';
import CardSpecificTransactions from './CardSpecificTransactions';
import CardUnlockRequestsList from './CardUnlockRequestsList';
import SecurityDetailCard from './SecurityDetailCard';
import type { CardDataWithRelationships } from '@app/@types/card.types';

type CardDetailsPageContentProps = {
  data: CardDataWithRelationships;
  allowSetSpendLimit: boolean;
  smsUnlockEnabled: boolean;
  onCardChange: () => void;
  telematicsEnabled: boolean;
  resourceTagsEnabled: boolean;
};

const CardDetailsPageContent = ({
  data,
  allowSetSpendLimit,
  smsUnlockEnabled,
  onCardChange,
  telematicsEnabled,
}: CardDetailsPageContentProps): ReactElement => {
  const [tabVal, setTabVal] = useState<'transactions' | 'unlocks'>('transactions');
  const customer = useCustomer();
  const [allowViewingSensitiveData] = useFeatureFlags('virtual_card_ordering_allowlist');
  const TWO_WEEKS_AGO = new Date(Date.now() - 14 * 24 * 60 * 60 * 1000);
  const customerCanViewSensitiveData =
    allowViewingSensitiveData ||
    data.card_type === 'virtual' ||
    new Date(customer.created_at) <= TWO_WEEKS_AGO;

  return (
    <>
      <div className="flex">
        <div className="grow">
          <CardNumber
            cardNumber={data.card_number_last_four}
            status={data.workflow_state}
            secondLine={data.second_line}
            cardType={data.card_type}
          />
          {customerCanViewSensitiveData && (
            <CardSensitiveData cardId={data.id} externalCardId={data.stripe_card_id} />
          )}
        </div>
        <div className="hidden items-start justify-end lg:flex">
          <ToggleCardActivation
            cardWorkflowState={data.workflow_state as CardWorkflowState}
            cardId={data.id}
            onCardChange={onCardChange}
          />
        </div>
      </div>
      <div className="flex w-full flex-col gap-4 lg:flex-row">
        <div className="grow sm:order-1 sm:w-full lg:order-2 lg:basis-1/3">
          <AssignSectionCard
            cardId={data.id}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            driverId={data.driver_id}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            vehicleId={data.vehicle_id}
            onCardChange={onCardChange}
          />
        </div>
        <div className="grow sm:order-2 sm:w-full lg:order-1 lg:basis-1/3">
          <SpendingLimitsCard
            data={data}
            allowSetSpendLimit={allowSetSpendLimit}
            refreshCard={onCardChange}
          />
        </div>
        {(telematicsEnabled || smsUnlockEnabled) && (
          <div className="sm:order-3 sm:w-full lg:order-3 lg:basis-1/3">
            <SecurityDetailCard
              cardId={data.id}
              resourceTag={data.resource_tag}
              cardSecurityEnabled={data.card_security_enabled}
              telematicsEnabled={telematicsEnabled}
              telematicsSetting={data.telematics_setting}
              smsUnlockEnabled={smsUnlockEnabled}
            />
          </div>
        )}
        <div className="pb-4 sm:order-4 sm:w-full lg:hidden">
          <ToggleCardActivation
            cardWorkflowState={data.workflow_state as CardWorkflowState}
            cardId={data.id}
            onCardChange={onCardChange}
          />
        </div>
      </div>
      <div className="block sm:hidden" />
      <div className="hidden h-full sm:block">
        <div className="w-full lg:order-5">
          <div className="pb-8">
            <UnderlinedTabs
              tabs={[
                { name: 'Transactions', tab: 'transactions' },
                { name: 'Unlock Requests', tab: 'unlocks' },
              ]}
              selectedTab={tabVal}
              handleTabChange={(tab) => setTabVal(tab)}
            />
            <div className="mt-4">
              {tabVal === 'transactions' && <CardSpecificTransactions cardID={data.id} />}
              {tabVal === 'unlocks' && <CardUnlockRequestsList cardID={data.id} />}
            </div>
          </div>
          <div className="pb-4">
            <Card>
              <SpendAnalysis cardId={data.id} allowViewTransactions={true} />
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};
export default CardDetailsPageContent;
