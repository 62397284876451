import { CustomerData } from '@app/@types/customer.types';
import PaymentModalWithEstimatesController from '@app/components/PaymentModal/PaymentModalWithEstimatesController/PaymentModalWithEstimatesController';
import PaymentSelectionDropdown, {
  PaymentSelectionType,
} from '@app/components/PaymentModal/SelectPaymentType/PaymentSelectionDropdown';
import { ReactElement, useState } from 'react';
import { AmountDueProps } from '../PaymentModalWithEstimatesController/amountDueConverter';

interface PaymentModalWithEstimatesContainerProps {
  closeModal: () => void;
  defaultPaymentSelectionType: PaymentSelectionType;
  customer: CustomerData;
  upcomingPayment: AmountDueProps['upcomingPayment'];
  walletAvailable: boolean;
}

const PaymentModalWithEstimatesContainer = ({
  closeModal,
  defaultPaymentSelectionType,
  customer,
  upcomingPayment,
  walletAvailable,
}: PaymentModalWithEstimatesContainerProps): ReactElement => {
  const [selectedPaymentType, setSelectedPaymentType] = useState<PaymentSelectionType>(
    defaultPaymentSelectionType,
  );
  const [showPaymentSelection, setShowPaymentSelection] = useState(true);

  return (
    <>
      {showPaymentSelection && (
        <div className="bg-soft-primary w-full px-6 pt-6 sm:px-8">
          <PaymentSelectionDropdown
            selectedPaymentType={selectedPaymentType}
            setSelectedPaymentType={setSelectedPaymentType}
            walletAvailable={walletAvailable}
          />
        </div>
      )}
      <PaymentModalWithEstimatesController
        paymentSelectionType={selectedPaymentType}
        closeModal={closeModal}
        setShowPaymentSelection={setShowPaymentSelection}
        customer={customer}
        upcomingPayment={upcomingPayment}
      />
    </>
  );
};

export default PaymentModalWithEstimatesContainer;
