import { TruckType } from '@app/@types/telematics.types';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { VehicleLabel } from './MapMarkers';

interface DropdownProps {
  trucks: TruckType[];
  selectTruck: (truck: TruckType, setError: (e: unknown) => void) => void;
  selectedTruck: TruckType | null;
  classes?: string;
  setError: (e: unknown) => void;
}

const Dropdown = ({ trucks, selectTruck, selectedTruck, setError }: DropdownProps) => {
  return (
    <Select
      fullWidth
      displayEmpty
      renderValue={(_) => {
        if (!selectedTruck) {
          return <span className="text-default-secondary font-normal">Select a vehicle...</span>;
        }
        return <VehicleLabel name={selectedTruck.name} vin={selectedTruck.vin} />;
      }}
      placeholder="Select a truck"
      value={selectedTruck?.id}
      onChange={(event: SelectChangeEvent<string>) => {
        const {
          target: { value },
        } = event;
        const truck = trucks.find((truck) => truck.id === value);
        if (truck) {
          selectTruck(truck, setError);
        }
      }}
    >
      {trucks?.map((truck) => (
        <MenuItem key={truck.id} value={truck.id}>
          <VehicleLabel name={truck.name} vin={truck.vin} className="flex-auto" />
        </MenuItem>
      ))}
    </Select>
  );
};

export default Dropdown;
