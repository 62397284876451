import { DebitCardPaymentMethodCombined } from '@app/@types/debitCard.types';
import { handleMakePaymentMethodPrimary } from '@app/components/PaymentMethods/PaymentMethodUtils';
import RemovePaymentMethodModal from '@app/components/PaymentMethods/RemovePaymentMethodModal';
import PaymentMethodsPrompt from '@app/components/Prompt/PaymentMethodsBankAccountPrompt';
import { SidebarWrapper } from '@app/components/wrappers/SidebarWrapper';
import useModal from '@app/hooks/useModal';
import { SideBarBody, SideBarFooter } from '@atob-developers/shared/src/components/SideBar';
import { Button, Chip } from '@mui/material';
import { AxiosResponse } from 'axios';
import { capitalize } from 'lodash-es';
import { ReactElement, useState } from 'react';

export enum DebitCardUpdateStates {
  ERROR = 'error',
  SUCCESSFUL = 'successful',
  NONE = 'none',
}
export interface DebitCardSidebarProps {
  title: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  card: DebitCardPaymentMethodCombined;
  handleRefreshPaymentMethods: () => void;
}

export const DebitCardSidebar = ({
  title,
  open,
  setOpen,
  card,
  handleRefreshPaymentMethods,
}: DebitCardSidebarProps): ReactElement => {
  const [isPrimary, setIsPrimary] = useState(card?.is_primary ?? false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [showRemoveCardModal, setShowRemoveCardModal] = useModal();
  const [debitCardUpdateState, setDebitCardUpdateState] = useState<DebitCardUpdateStates>(
    DebitCardUpdateStates.NONE,
  );

  const sideBarClickHandler = () => {
    setIsPrimary(!isPrimary);
    setDebitCardUpdateState(DebitCardUpdateStates.NONE);
    setOpen(!open);
  };

  const handleChecked = async () => {
    const response = await handleMakePaymentMethodPrimary(card.id);
    if ((response as AxiosResponse).status === 200) {
      setDebitCardUpdateState(DebitCardUpdateStates.SUCCESSFUL);
      setIsPrimary(!isPrimary);
      handleRefreshPaymentMethods();
    } else {
      if ((response as AxiosResponse).data?.errors?.base?.length) {
        setErrorMessage((response as AxiosResponse).data.errors.base[0]);
      } else {
        setErrorMessage(
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          'Something went wrong making this payment method primary. Please refresh the page and try again',
        );
      }
      setDebitCardUpdateState(DebitCardUpdateStates.ERROR);
    }
  };
  return (
    <SidebarWrapper title={title} open={open} setOpen={sideBarClickHandler}>
      <SideBarBody>
        <div className="mb-7">{isPrimary && <Chip label="Primary" color="green" />}</div>
        <div className="mb-7 flex flex-col">
          <p className="text-default-primary text-sm">Expiration</p>
          <p className="text-default-primary pt-2 text-sm font-bold">{card.expires}</p>
        </div>
        <div className="mb-7 flex flex-col">
          <p className="text-default-primary text-sm">Card Ending in</p>
          <p className="text-default-primary pt-2 text-sm font-bold">{card.last_four}</p>
        </div>
        <div className="mb-7 flex flex-col">
          <p className="text-default-primary text-sm">Type</p>
          <p className="text-default-primary pt-2 text-sm font-bold">
            {capitalize(card.payment_method_detail.brand)}
          </p>
        </div>
        {card.cardholder_name && (
          <div className="mb-7 flex flex-col">
            <p className="text-default-primary text-sm">Cardholder Name</p>
            <p className="text-default-primary pt-2 text-sm font-bold">{card.cardholder_name}</p>
          </div>
        )}
        {card.billing_address && (
          <div className="mb-7 flex flex-col">
            <p className="text-default-primary text-sm">Billing Address</p>
            <p className="text-default-primary whitespace-pre-line pt-2 text-sm font-bold">
              {card.billing_address.address1}
              {card.billing_address.address2 && ` — ${card.billing_address.address2}`}
              {'\n'}
              {card.billing_address.city}, {card.billing_address.state}
              {'\n'}
              {card.billing_address.zip} United States
            </p>
          </div>
        )}
        {!isPrimary && (
          <Button onClick={handleChecked} color="secondary" size="medium">
            Make Primary Payment Method
          </Button>
        )}
        <div className="mt-3">
          <DebitCardUpdatePrompt
            status={debitCardUpdateState}
            setBankAccountUpdateState={setDebitCardUpdateState}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            errorMessage={errorMessage}
          />
        </div>
      </SideBarBody>
      <RemovePaymentMethodModal
        isActive={showRemoveCardModal}
        setIsActive={setShowRemoveCardModal}
        message={`Do you want to delete your debit card ending in ${card.last_four}?`}
        buttonText="Remove Card"
        isPrimary={isPrimary}
      />
      <SideBarFooter>
        <Button size="medium" variant="destructive" onClick={() => setShowRemoveCardModal()}>
          Remove Card
        </Button>
      </SideBarFooter>
    </SidebarWrapper>
  );
};

interface DebitCardUpdatePromptProps {
  status: DebitCardUpdateStates;
  setBankAccountUpdateState: (status: DebitCardUpdateStates) => void;
  errorMessage: string;
}

const DebitCardUpdatePrompt = ({
  status,
  setBankAccountUpdateState,
  errorMessage,
}: DebitCardUpdatePromptProps) => {
  if (status === DebitCardUpdateStates.NONE) {
    return null;
  } else if (status === DebitCardUpdateStates.ERROR) {
    if (
      errorMessage &&
      errorMessage.toLowerCase().includes('link prepaid card as primary payment method')
    ) {
      errorMessage =
        "We don't accept prepaid cards for payments. Please link a business debit card.";
    }
    return (
      <PaymentMethodsPrompt
        clickHandler={() => setBankAccountUpdateState(DebitCardUpdateStates.NONE)}
        error={true}
        customErrorMessage={errorMessage}
      />
    );
  }
  return (
    <PaymentMethodsPrompt
      clickHandler={() => setBankAccountUpdateState(DebitCardUpdateStates.NONE)}
      error={false}
    />
  );
};
