import { EndpointResponse } from '@app/@types/api.types';
import { BankAccountPaymentMethodCombined } from '@app/@types/bankAccount.types';
import { DebitCardPaymentMethodCombined } from '@app/@types/debitCard.types';
import { PaymentMethodCombined } from '@app/@types/payments.types';
import { PAYMENT_METHOD_QUERY_KEY } from '@app/hooks/query/usePaymentMethodsQuery';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import { useMemo, useState } from 'react';
import useSWR from 'swr';
import { AccountType, NormalizedSource, PaymentMethodType } from './utils';

const useAddFundsSources = () => {
  const [normalizingSources, setNormalizingSources] = useState(false);

  const { data: addFundsSources, isLoading: loadingAddFundsSources } = useSWR<
    EndpointResponse<PaymentMethodCombined[]>
  >(PAYMENT_METHOD_QUERY_KEY, apiGetFetcher);

  const normalizedSources: NormalizedSource[] = useMemo(() => {
    if (!addFundsSources?.data) return [];

    setNormalizingSources(true);
    const processedSourcesData = addFundsSources.data
      .map((source) => {
        const type = source.payment_method_detail.type as AccountType;

        if (type === 'debit_card') {
          source = source as DebitCardPaymentMethodCombined;

          return {
            id: source.id,
            recipientType: 'own',
            name: source.payment_method_detail.description,
            alternateName: source.payment_method_detail.expires,
            type: 'debit_card',
            lastFour: source.payment_method_detail.last_four,
            brand: source.payment_method_detail.brand,
            supportedNetworks: ['debit'],
            recipientName: null,
          };
        }
        if (type === 'bank_account') {
          source = source as BankAccountPaymentMethodCombined;

          return {
            id: source.id,
            recipientType: 'own',
            alternateName: null,
            name: source.payment_method_detail.name,
            type: 'bank_account',
            lastFour: source.payment_method_detail.mask,
            supportedNetworks: source.payment_method_detail
              .supported_networks as PaymentMethodType[],
            brand: null,
            recipientName: null,
            needsAddress: false,
            state: source.payment_method_detail.state,
          };
        }
        return null;
      })
      .filter((item) => item !== null) as NormalizedSource[];
    setNormalizingSources(false);

    return processedSourcesData;
  }, [addFundsSources?.data]);

  return {
    addFundsSources: normalizedSources,
    loadingAddFundsSources: loadingAddFundsSources || normalizingSources,
  };
};

export default useAddFundsSources;
