import { SettingsPageEntityType } from '@app/constants/settings';
import { SETTINGS_ROW_OPTIONS } from '@app/constants/settingsRow';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement } from 'react';

export const SettingsRowHeadline = ({ type }: { type: SettingsPageEntityType }): ReactElement => {
  return (
    <div className="flex basis-10/12 flex-col gap-2 xl:basis-5/12">
      <div className="group flex items-center gap-2 text-sm font-medium xl:text-base xl:font-semibold">
        <FontAwesomeIcon
          icon={SETTINGS_ROW_OPTIONS[type].icon}
          className="text-default-primary text-lg"
        />
        <p className="text-default-primary basis-9/12 text-lg font-semibold">
          {SETTINGS_ROW_OPTIONS[type].title}
        </p>
      </div>
      <p className="text-default-secondary text-sm font-normal">
        {SETTINGS_ROW_OPTIONS[type].subtitle}
      </p>
    </div>
  );
};
