import { BankAccountPaymentMethodCombined } from '@app/@types/bankAccount.types';
import { DebitCardPaymentMethodCombined } from '@app/@types/debitCard.types';
import { PaymentMethodCombined } from '@app/@types/payments.types';
import DebitCardModal from '@app/components/PaymentMethods/DebitCardModal';
import Ellipsis from '@app/components/elements/Ellipsis';
import { Button, MenuItem, Select } from '@mui/material';
import { capitalize } from 'lodash-es';
import { ReactElement, useState } from 'react';
import { isBankAccount, isDebitCard } from '../../components/PaymentMethods/PaymentMethodUtils';
import { PaymentSourceProps } from './Payroll';

const MiniSpacer = () => <div className="m-4" />;

const renderCard = (
  item: DebitCardPaymentMethodCombined,
  clickHandler: (item: PaymentMethodCombined) => void,
) => {
  const { id, brand, last_four, expires } = item;
  return (
    <div
      key={id}
      onClick={() => clickHandler(item)}
      className="flex w-full items-center justify-between"
    >
      <div>{`${capitalize(brand)} ending in ${last_four}`}</div>
      <div className="text-default-secondary ml-4 text-right md:ml-0">Expires {expires}</div>
    </div>
  );
};

const renderBankAccount = (
  item: BankAccountPaymentMethodCombined,
  clickHandler: (item: PaymentMethodCombined) => void,
) => {
  const { id, institution_name, mask } = item;
  return (
    <div
      key={id}
      onClick={() => clickHandler(item)}
      className="flex w-full items-center justify-between"
    >
      <div>{`${capitalize(institution_name)}`}</div>
      <div className="text-default-secondary ml-4 text-right md:ml-0">Acct ending in {mask}</div>
    </div>
  );
};

const createRenderPaymentSource =
  (clickHandler: (_: PaymentMethodCombined) => void) => (item: PaymentMethodCombined) => {
    if (isDebitCard(item)) {
      return renderCard(item, clickHandler);
    } else if (isBankAccount(item)) {
      return renderBankAccount(item, clickHandler);
    }
  };

export default function PayrollPaymentSource({
  paymentSourceDebit,
  setPaymentSourceDebit,
  refreshPaymentMethods,
  cards,
  loading,
}: PaymentSourceProps & {
  refreshPaymentMethods: () => void;
  bankAccounts: BankAccountPaymentMethodCombined[];
  cards: DebitCardPaymentMethodCombined[];
  loading: boolean;
}): ReactElement {
  const [showDebitCardModal, setShowDebitCardModal] = useState(false);

  const renderPaymentSourceDebit = createRenderPaymentSource((paymentSourceDebit) =>
    setPaymentSourceDebit(paymentSourceDebit as DebitCardPaymentMethodCombined),
  );

  const renderAddPaymentDebitButton = () => {
    const maxCardsReached = cards.length >= 5;
    return (
      <div className="mx-2 my-4 flex justify-end">
        <div className="max-w-[240px]">
          <Button
            size="small"
            disabled={maxCardsReached}
            onClick={() => {
              if (!maxCardsReached) {
                setShowDebitCardModal(true);
              }
            }}
          >
            <span title={maxCardsReached ? 'You have reached the card limit.' : ''}>
              Add New Debit Card
            </span>
          </Button>
        </div>
      </div>
    );
  };

  const doesNotHavePaymentCards = !loading && cards.length === 0;
  const hasPaymentCards = !doesNotHavePaymentCards;
  return (
    <div className="w-full flex-col">
      <div className="flex items-center">
        {loading && (
          <div>
            Loading
            <Ellipsis />
          </div>
        )}
      </div>
      <div className="flex flex-col justify-between md:flex-row md:justify-start">
        <div>
          <h1 className="text-default-primary mr-4 text-xl font-bold">
            Instant Funding Payment Source
          </h1>
          <MiniSpacer />
          {hasPaymentCards ? (
            <Select value={paymentSourceDebit?.id} fullWidth data-testid="dropdown-button">
              {cards.map((card) => (
                <MenuItem key={card.id} value={card.id} classes={{ root: 'flex-col items-start' }}>
                  {renderPaymentSourceDebit(card)}
                </MenuItem>
              ))}
              <MenuItem
                key="add_funds"
                id="add_funds"
                classes={{ root: 'flex-col items-end cursor-default' }}
              >
                {renderAddPaymentDebitButton()}
              </MenuItem>
            </Select>
          ) : (
            <Button onClick={() => setShowDebitCardModal(true)}>Add New Debit Card</Button>
          )}
        </div>
      </div>
      <DebitCardModal
        isActive={showDebitCardModal}
        setIsActive={setShowDebitCardModal}
        onSuccess={refreshPaymentMethods}
      />
    </div>
  );
}
