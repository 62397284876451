import DropdownCard from '@app/components/DropdownCard/DropdownCard';
import useOnClickOutside from '@app/hooks/useOnClickOutside';
import useWindowWidth from '@app/hooks/useWindowWidth';
import Link from '@atob-developers/shared/src/components/Link';
import { BUTTON_TYPES } from '@atob-developers/shared/src/components/constants';
import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import { ReactElement, useRef, useState } from 'react';

interface ActionsListProps {
  actions: ActionData[];
  overflowLimit: number;
}

export type ActionData = {
  onClick: () => void;
  text: string;
} & {
  [key in BUTTON_TYPES]?: boolean;
};

const ActionsList = ({ actions, overflowLimit }: ActionsListProps): ReactElement => {
  const [showOverflowMenu, setShowOverflowMenu] = useState(false);
  const windowWidth = useWindowWidth();
  /**
   * 100px = the width of the left content in the action bar
   * 120px = the max width of each of the action links
   */
  const buttonSpace = Math.floor((windowWidth.width - 100) / 120);
  const actionsLimit = Math.min(buttonSpace, overflowLimit);
  const primaryActions = actions.slice(0, actionsLimit);
  const overflownActions = actions.slice(actionsLimit);

  const ref = useRef<HTMLDivElement>(null);

  useOnClickOutside(ref, () => {
    setShowOverflowMenu(false);
  });

  return (
    <div className="relative flex h-full">
      <div className="flex gap-5">
        {primaryActions.map((action) => {
          return (
            <Link {...action} key={action.text} className="w-auto" primary>
              {action.text}
            </Link>
          );
        })}
      </div>
      {overflownActions.length > 0 && (
        <div ref={ref}>
          <IconButton
            color="secondary"
            size="small"
            onClick={() => {
              setShowOverflowMenu(!showOverflowMenu);
            }}
          >
            <FontAwesomeIcon icon={faEllipsisVertical} className="text-lg" />
          </IconButton>
          <DropdownCard
            className="bottom-10 right-2 mt-5 w-auto"
            show={showOverflowMenu}
            close={() => setShowOverflowMenu(false)}
            anchor={ref.current}
          >
            <div className="flex flex-col">
              {overflownActions.map((action) => (
                <Link
                  key={action.text}
                  onClick={() => {
                    setShowOverflowMenu(false);
                    action.onClick();
                  }}
                  primary
                >
                  {action.text}
                </Link>
              ))}
            </div>
          </DropdownCard>
        </div>
      )}
    </div>
  );
};
export default ActionsList;
