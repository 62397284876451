import { convertCentsToDollars } from '@atob-developers/shared/src/utils/formatters/currencyFormat';
import { faBank, faCreditCard, faMoneyBillTransfer } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { useEffect, ReactElement } from 'react';
import type { WithdrawFeeData, WithdrawMethod } from './transfer.types';

export const transferButtonClasses = (active: boolean): string =>
  classNames(
    active ? 'outline-none ring-offset-2 border-strong border-2' : 'border-soft border',
    'flex items-center m-1',
    'sm:mr-2 inline-flex items-center px-4 py-2',
    'shadow-sm text-base rounded-md',
    'font-medium text-default-primary bg-soft-primary hover:bg-soft-primary-hover disabled:opacity-50',
  );

const feeString = (fee: WithdrawFeeData): string => {
  const percentage = fee.fee_percentage > 0 ? `${fee.fee_percentage}%` : null;
  const amount =
    // eslint-disable-next-line @typescript-eslint/no-base-to-string, @typescript-eslint/restrict-template-expressions
    fee.fee_amount > 0 ? `$${convertCentsToDollars({ value: fee.fee_amount })}` : null;

  return [amount, percentage].filter(Boolean).join(' + ');
};

const TransferSelector = ({
  transferMethods,
  transferType,
  selectTransferType,
  children,
}: {
  transferMethods: WithdrawFeeData[];
  transferType: WithdrawMethod;
  selectTransferType: (transferType: WithdrawMethod) => void;
  children?: (transferMethod: WithdrawMethod, error: string) => ReactElement | null;
}): ReactElement => {
  useEffect(() => {
    selectTransferType(transferType);
  }, [transferType, selectTransferType]);
  return (
    <div className="align-start mb-2 flex flex-col">
      <div className="flex justify-evenly sm:justify-center">
        {transferMethods.map((transferMethod) => {
          if (transferMethod.type === 'ach') {
            return (
              <button
                type="button"
                className={classNames(
                  transferButtonClasses(transferType === 'ach'),
                  'flex-auto flex-col',
                )}
                onClick={() => selectTransferType('ach')}
                key={transferMethod.type}
              >
                <FontAwesomeIcon icon={faBank} />
                <div
                  className={classNames({
                    'font-bold': transferType === 'ach',
                  })}
                >
                  ACH Transfer
                </div>
                <div className="text-sm font-light">1-3 days</div>
                <div className="text-sm font-light">{feeString(transferMethod)}</div>
              </button>
            );
          }
          if (transferMethod.type === 'us_domestic_wire') {
            if (transferMethod.error === 'NO_ADDRESS_FOUND' && children) {
              return children(transferMethod.type, transferMethod.error);
            }

            return (
              <button
                type="button"
                className={classNames(
                  transferButtonClasses(transferType === 'us_domestic_wire'),
                  'flex-auto flex-col',
                )}
                onClick={() => selectTransferType('us_domestic_wire')}
                key={transferMethod.type}
              >
                <FontAwesomeIcon icon={faMoneyBillTransfer} />
                <div
                  className={classNames({
                    'font-bold': transferType === 'us_domestic_wire',
                  })}
                >
                  Wire Transfer
                </div>
                <div className="text-sm font-light">Same day</div>
                <div className="text-sm font-light">{feeString(transferMethod)}</div>
              </button>
            );
          }
          if (transferMethod.type === 'debit') {
            return (
              <button
                type="button"
                className={classNames(
                  transferButtonClasses(transferType === 'debit'),
                  'flex-auto flex-col',
                )}
                onClick={() => selectTransferType('debit')}
                key={transferMethod.type}
              >
                <FontAwesomeIcon icon={faCreditCard} />
                <div>Debit Card</div>
                <div className="text-sm font-light">Instant</div>
                <div className="text-sm font-light">{feeString(transferMethod)}</div>
              </button>
            );
          }
          return null;
        })}
      </div>
    </div>
  );
};

export default TransferSelector;
