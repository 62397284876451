import { faPlusCircle, faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from './Button';
import DropdownItem from './DropdownItem';
import Input from './Input';
import { Tooltip } from './Tooltip';

// GENERIC REPRESENTATION OF FORM / LIST OF DIFFERENT VALUES.
// renderContent should be extended in the future

export enum DataItemType {
  TEXT = 'text',
  TEXT_FIELD = 'textarea',
  TEXT_INPUT = 'input',
  GROUP = 'group',
  DROPDOWN = 'dropdown',
  DRIVER = 'driver',
  VEHICLE = 'vehicle',
}

export interface DataItemProps<T> {
  type: DataItemType | null;
  label: string;
  button?: string;
  required?: boolean;
  path: string;
  items?: DataItemProps<T>[];
  content?: T | Record<string, T> | string;
  onButtonClick?: () => void;
}

const renderContent = <T,>(item: DataItemProps<T>) => {
  switch (item.type) {
    case DataItemType.TEXT:
      return <p>{item?.content as string}</p>;
    case DataItemType.TEXT_INPUT:
      return (
        // eslint-disable-next-line @typescript-eslint/no-base-to-string, @typescript-eslint/restrict-template-expressions
        <Input label={item.label} inputId={item.path} labelHidden placeholder={`${item.content}`} />
      );
    case DataItemType.GROUP:
      // FOR GROUPS WE HAVE RECURSIVE CALL OF RENDERING FUNCTION IF DATA IS NESTED IN FORWARDED OBJECT
      return (
        <div className="flex flex-col space-y-4 text-sm">
          {(item?.items || []).map((groupItem, itemIdx) => (
            <DataItem
              item={{
                ...groupItem,
                content:
                  groupItem?.path && item.content
                    ? (item.content as Record<string, T>)[groupItem?.path] || ''
                    : '',
                onButtonClick: groupItem.button
                  ? () => groupItem.onButtonClick && groupItem.onButtonClick()
                  : undefined,
              }}
              key={`grouped-item-${itemIdx}`}
              subItem
            />
          ))}
        </div>
      );
    case DataItemType.DRIVER:
    case DataItemType.VEHICLE:
      return <DropdownItem name={item?.content as unknown as string} />;
    default:
      return null;
  }
};

const DataItem = <T,>({
  item,
  subItem,
  tooltipText,
}: {
  item: DataItemProps<T>;
  subItem?: boolean;
  tooltipText?: string;
}) => {
  return (
    <div className="text-default-primary flex flex-col space-y-2">
      {/* LABEL */}
      {item.type !== DataItemType.TEXT_INPUT && (
        <div className="flex flex-row gap-2">
          <p
            className={`${subItem ? 'font-semibold' : 'font-normal'} ${
              item?.type == DataItemType.GROUP ? 'mb-4' : 'mb-0'
            }`}
          >
            {item.label}
          </p>
          {tooltipText && (
            <Tooltip icon={faInfoCircle} iconClassName="text-default-primary" title={tooltipText} />
          )}
        </div>
      )}

      {/* CONTENT */}
      <div className={`${subItem ? 'font-normal' : 'font-semibold'}`}>{renderContent(item)}</div>

      {/* Additional BUTTON */}
      {item.button && (
        <div className="w-fit">
          <Button onClick={item.onButtonClick}>
            {/* THIS ICON CAN BE FORWARDED LATER SO BUTTON CAN BE GENERIC ALSO */}
            <FontAwesomeIcon icon={faPlusCircle} className="pr-2.5" />
            {item.button}
          </Button>
        </div>
      )}
    </div>
  );
};

export default DataItem;
