import { DriverData } from '@app/@types/driver.types';
import { FlattenedPayrollData } from '@app/@types/payroll.types';
import { ErrorNotification } from '@app/components/layout';
import Modal, {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { LoadingButton } from '@mui/lab';
import axios from 'axios';
import { ReactElement, useState } from 'react';

interface ArchiveDriverModalProps {
  isActive: boolean;
  reset: () => void;
  driver: DriverData | FlattenedPayrollData;
  onArchiveSuccess: (updatedDriver: DriverData) => void;
}

export default function ArchiveDriverModal({
  isActive,
  reset,
  driver,
  onArchiveSuccess,
}: ArchiveDriverModalProps): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const { addToast } = useToasts();

  const deleteDriver = async () => {
    try {
      setIsLoading(true);
      const driverResponse = await axios.delete(`/drivers/${driver.id}`);
      onArchiveSuccess(driverResponse.data.data.attributes);
      addToast({ type: 'success', title: `${driver.name} successfully removed` });
      reset();
    } catch (e: unknown) {
      setError(e as string);
    }

    setIsLoading(false);
  };

  return (
    <Modal open={isActive} toggle={reset}>
      <ModalHeader title={`Remove ${driver.first_name}`} onClose={reset} />
      <ModalBodyContent>
        {error && <ErrorNotification error={error} />}
        <p className="text-default-primary mb-4">
          This will remove {driver.name} from your list of drivers.
        </p>
        <p className="text-default-secondary text-sm">
          If you would like restore this driver in the future, <b>add them again as a new driver</b>
          .
        </p>
      </ModalBodyContent>
      <ModalFooter>
        <LoadingButton size="small" loading={isLoading} onClick={deleteDriver}>
          Remove {driver.name ?? ''}
        </LoadingButton>
      </ModalFooter>
    </Modal>
  );
}
