import {
  CustomerPathToCreditPromotionDetails,
  PathToCreditPromotionDetails,
} from '@app/@types/promotion.types';
import CustomerContext from '@app/contexts/customerContext';
import { useAcceptedAccountPromotionsQuery } from '@app/hooks/query/useAcceptedAccountPromotionsQuery';
import { useFlexOffersQuery } from '@app/hooks/query/useFlexOfferQuery';
import currencyFormat from '@atob-developers/shared/src/utils/formatters/currencyFormat';
import { Button } from '@mui/material';
import { ReactElement, useContext, useState } from 'react';
import LearnMoreModal from './LearnMoreModal';
import ProgressNotification from './ProgressNotification';

export type SpendPromotion = {
  workflow_state: 'active' | 'inactive' | 'complete' | 'in_review';
  target_weeks_met: number;
  total_weeks_remaining: number;
  goal_target_weeks: number;
  goal_total_weeks: number;
  goal_spend_amount: string;
};

export default function PathToCredit(): ReactElement {
  const [learnMoreModalOpen, setLearnMoreModalOpen] = useState(false);

  const { customer } = useContext(CustomerContext);
  const acceptedAccountPromotions = useAcceptedAccountPromotionsQuery('path_to_credit');
  const flexOffer = useFlexOffersQuery(customer);

  if (
    acceptedAccountPromotions.isLoading ||
    !acceptedAccountPromotions?.data?.data?.length ||
    flexOffer.isLoading
  ) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return null;
  }

  const [customerPromotionData] = acceptedAccountPromotions.data.data;
  const customerPromotionDataDetails =
    customerPromotionData.details as CustomerPathToCreditPromotionDetails;
  const promotionDataDetails = customerPromotionData.account_promotion
    .details as PathToCreditPromotionDetails;

  const workflow_state = customerPromotionData.workflow_state;
  const target_weeks_met = customerPromotionDataDetails.target_weeks_met;
  const total_weeks_remaining = customerPromotionDataDetails.total_weeks_remaining;
  const goal_total_weeks = promotionDataDetails.total_weeks;
  const goal_target_weeks = promotionDataDetails.target_weeks;
  const goal_spend_amount = currencyFormat({
    value: promotionDataDetails.weekly_spend_target_cents / 100.0,
    onlyWholeNumber: true,
  });

  const customerFlexOffer = flexOffer?.data?.data || null;

  const spendPromotion: SpendPromotion = {
    workflow_state,
    target_weeks_met,
    total_weeks_remaining,
    goal_total_weeks,
    goal_target_weeks,
    goal_spend_amount,
  };

  if (workflow_state === 'inactive') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return null;
  }

  if (workflow_state === 'complete') {
    return (
      <div className="w-full">
        <div className="bg-path-to-credit-jumping bg-soft-primary mt-4 flex flex-col items-center overflow-hidden rounded-xl bg-[length:300px_250px] bg-[top_35px_left_-70px] bg-no-repeat p-6 pb-8 md:bg-[length:500px_400px] md:bg-[top_-75px_left_-100px] lg:bg-[top_-100px_left_-100px]">
          <h3 className="my-6 max-w-[300px] text-center font-semibold lg:max-w-[100%]">
            You did it! You&apos;re now eligible for a credit line by switching to AtoB Flex.
          </h3>
          {/* TODO: Add controller endpoint to switch to AtoB Flex. */}
          <Button size="medium">Contact Support</Button>
        </div>
      </div>
    );
  }

  if (workflow_state === 'active') {
    const heading = customerFlexOffer
      ? `Earn toward your $${customerFlexOffer.credit_limit} credit line`
      : 'Earn toward a credit line';
    const guarantee = customerFlexOffer
      ? 'get your credit line with'
      : 'build your business credit and get evaluated for';
    return (
      <div className="bg-path-to-credit-superhero bg-soft-primary mt-4 flex flex-col overflow-hidden rounded-xl bg-[length:300px_250px] bg-[center_right_-150px] bg-no-repeat p-6 lg:bg-[length:600px_500px] lg:bg-[bottom_-100px_right_-200px]">
        <h3 className="font-semibold">{heading}</h3>
        <div className="max-w-[300px] text-sm lg:max-w-[50%]">
          Spend at least {goal_spend_amount} per week for {goal_target_weeks - target_weeks_met} of
          the next {total_weeks_remaining} weeks to {guarantee} AtoB Flex.{' '}
          <button
            onClick={() => {
              setLearnMoreModalOpen(true);
            }}
            className="underline"
          >
            Learn More
          </button>
        </div>
        <LearnMoreModal
          spendPromotion={spendPromotion}
          flexOffer={customerFlexOffer}
          open={learnMoreModalOpen}
          onClose={() => setLearnMoreModalOpen(false)}
        />
        <div className="mt-6">
          <ProgressCoins completed={target_weeks_met} total={goal_target_weeks} />
        </div>
        <ProgressNotification spendPromotion={spendPromotion} />
      </div>
    );
  }

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  return null;
}

const CompletedCoin = () => {
  return (
    <div className="bg-orange-primary text-contrast-primary mb-2 ml-2 flex h-7 w-7 items-center justify-center rounded-full">
      $
    </div>
  );
};
const InProgressCoin = ({ slot }: { slot: number }) => {
  return (
    <div className="border-soft text-default-tertiary mb-2 ml-2 flex h-7 w-7 items-center justify-center rounded-full border border-dashed">
      {slot}
    </div>
  );
};

const ProgressCoins = ({
  completed,
  total,
}: {
  completed: number;
  total: number;
}): ReactElement => {
  return (
    <div className="flex max-w-xs flex-wrap md:max-w-sm xl:max-w-xl">
      {new Array(total).fill(0).map((_, i) => {
        if (i < completed) {
          return <CompletedCoin key={i} />;
        }
        return <InProgressCoin key={i} slot={i + 1} />;
      })}
    </div>
  );
};
