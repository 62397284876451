import { ChargeEventData } from '@app/@types/charge_events.types';
import { ReceiptImageModal } from '@app/components/Modals/ChargeEventModals/ReceiptImageModal';
import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import RoundedCardCore from '@app/components/RoundedCard/RoundedCardCore';
import RoundedCardTitle from '@app/components/RoundedCard/RoundedCardTitle';
import Alert from '@atob-developers/shared/src/components/Alert';
import { faReceipt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from '@mui/material';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import DetailsItem from './DetailsItem';
import { formatHelper } from './utils';

export default function ChargeEventTransactionDetails({
  chargeEvent,
}: {
  chargeEvent: ChargeEventData;
}) {
  const forceCapture = chargeEvent.auth_approved && chargeEvent.auth_status == null;
  const closed = chargeEvent.auth_status === 'closed' || forceCapture;

  const [isReceiptImageModalOpen, setIsReceiptImageModalOpen] = useState(false);

  const formattedGrossAmount = formatHelper(chargeEvent.gross_amount_cents);
  const formattedNetAmount = formatHelper(chargeEvent.net_amount_cents);
  const formattedDiscountAmount = formatHelper(chargeEvent.discount_amount_cents);
  const formattedGallons =
    chargeEvent.quantity == null || chargeEvent.quantity === '0.0' ? 'N/A' : chargeEvent.quantity;
  const formattedPricePerGallon = formatHelper(chargeEvent.unit_price?.cents);
  const formattedDiscountPerGallon = formatHelper(chargeEvent.discount_unit_price?.cents);
  const formattedNetPricePerGallon = formatHelper(chargeEvent.net_unit_price?.cents);
  const formattedFuelType = formatHelper(chargeEvent.product_type);
  const formattedPurchaseType = formatHelper(chargeEvent.purchase_type);
  const receiptImageLink =
    chargeEvent.receipt_image_id != null ? (
      <div className="flex items-center gap-2">
        <FontAwesomeIcon icon={faReceipt} />
        <Link
          className="cursor-pointer text-sm"
          onClick={() => setIsReceiptImageModalOpen((p) => !p)}
        >
          Receipt
        </Link>
      </div>
    ) : null;

  return (
    <>
      <RoundedCard>
        <RoundedCardTitle title="Transaction Details" chip={receiptImageLink} />
        <div className="flex flex-col lg:flex-row">
          <RoundedCardCore className="border-soft border-b lg:border-b-0 lg:border-r">
            <DetailsItem
              label="Card used"
              value={
                chargeEvent.card_id ? (
                  <RouterLink
                    to={`/cards/${chargeEvent.card_id}`}
                    className="text-default-primary underline"
                  >
                    {`···· ${chargeEvent.card_last4}`}
                  </RouterLink>
                ) : (
                  `···· ${chargeEvent.card_last4}`
                )
              }
            />
            <DetailsItem label="Gross amount" value={formattedGrossAmount} closed={closed} />
            <DetailsItem label="Discount amount" value={formattedDiscountAmount} closed={closed} />
            <DetailsItem label="Net amount" value={formattedNetAmount} closed={closed} />
            <DetailsItem label="Gallons" value={formattedGallons} closed={closed} />
            <DetailsItem label="Price per gallon" value={formattedPricePerGallon} closed={closed} />
            <DetailsItem
              label="Discount per gallon"
              value={formattedDiscountPerGallon}
              closed={closed}
            />
            <DetailsItem
              label="Net price per gallon"
              value={formattedNetPricePerGallon}
              closed={closed}
            />
          </RoundedCardCore>
          <RoundedCardCore>
            <div className="flex w-full flex-col gap-4">
              <DetailsItem label="Fuel Type" value={formattedFuelType} closed={closed} />
              <DetailsItem label="Purchase type" value={formattedPurchaseType} closed={closed} />
              <Alert
                type="info"
                title="Line item breakdown not available"
                description="This transaction does not have a line item breakdown"
              />
            </div>
          </RoundedCardCore>
        </div>
      </RoundedCard>
      <ReceiptImageModal
        open={isReceiptImageModalOpen}
        toggle={() => setIsReceiptImageModalOpen((p) => !p)}
        receiptImageId={chargeEvent.receipt_image_id}
      />
    </>
  );
}
