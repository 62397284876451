import { EndpointResponse } from '@app/@types/api.types';
import { InstantPayoutMethod } from '@app/@types/instant_payout_method.types';
import { apiDeleteFetcher, FetcherKey } from '@app/utils/data/fetchers';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { AxiosError } from 'axios';
import { mutate } from 'swr';
import useSWRMutation from 'swr/mutation';
import { Account } from '../utils';
import DeleteAccountModal from './DeleteAccountModal';

export default function DeleteInstantPayoutAccountModal({
  open,
  onClose,
  account,
  recipientName,
  recipientId,
}: {
  open: boolean;
  onClose: () => void;
  account: Account;
  recipientName: string;
  recipientId: string;
}) {
  const { addToast } = useToasts();
  const { trigger, isMutating: isLoading } = useSWRMutation<
    EndpointResponse<InstantPayoutMethod>,
    AxiosError,
    FetcherKey
  >(`/treasury/instant_payout_methods/${account?.id}`, apiDeleteFetcher, {
    onSuccess: () => {
      mutate({
        url: `/instant_payout/recipients/${recipientId}?include=treasury_recipients,instant_payout_methods`,
      });
      onClose();
    },
    onError: (error) => {
      addToast({
        type: 'error',
        title: error.message || 'There was a problem deleting your account',
      });
    },
  });

  return (
    <DeleteAccountModal
      open={open}
      onClose={onClose}
      onAccountRemoval={trigger}
      account={account}
      recipientName={recipientName}
      loading={isLoading}
    />
  );
}
