import { FiltersNotice, ScreenProps } from '@app/components/Exporting/Screens';
import { DateRange } from '@mui/lab';
import {
  Button,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { Dayjs } from 'dayjs';
import { ReactElement } from 'react';

import ExportIFTATransactionsText from './ExportIFTATransactionsText';
import { ExportTransactionsDatePicker } from './ExportTransactionsDatePicker';

export const ExportConfiguration = ({
  value,
  onChange,
  onClose,
  onContinue,
  type,
  date,
  onDateChange,
  hideFiltersNotice,
}: {
  value: string;
  onChange: (value: string) => void;
  type: string;
  date: DateRange<Dayjs>;
  onDateChange: (value: DateRange<Dayjs>) => void;
  hideFiltersNotice: boolean;
} & ScreenProps): ReactElement => (
  <div>
    <DialogContent className="flex flex-col gap-8">
      <FormControl>
        <FormLabel id="type" className="mb-4">
          Export Type
        </FormLabel>
        <RadioGroup
          aria-label="type"
          name="type"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          className="flex flex-col gap-4"
        >
          <FormControlLabel
            value="transactions_generic"
            control={<Radio />}
            label={
              <>
                All Transaction Data
                <div className="text-default-secondary text-sm">
                  Exports all information available.
                </div>
              </>
            }
          />
          <FormControlLabel
            value="transactions_ifta"
            control={<Radio />}
            label={
              <>
                IFTA Report
                <div className="text-default-secondary text-sm">
                  Reports diesel transactions with the totals by jurisdiction.
                </div>
              </>
            }
          />
        </RadioGroup>
      </FormControl>
      {type === 'transactions_ifta' && <ExportIFTATransactionsText />}
      {!hideFiltersNotice && (
        <div>
          <FiltersNotice />
        </div>
      )}
      <ExportTransactionsDatePicker value={date} onChange={onDateChange} />
    </DialogContent>
    <DialogActions>
      <Button color="secondary" size="medium" onClick={onClose}>
        Close
      </Button>
      <Button size="medium" onClick={onContinue}>
        Download
      </Button>
    </DialogActions>
  </div>
);
