import LineItem from '@app/components/layout/LineItem';
import { formatCurrency } from '@atob-developers/shared/src/utils/formatters';

export const RepaymentSummary = ({
  recipientName,
  amount,
  referenceId,
  description,
}: {
  amount: string;
  description: string;
  referenceId: string;
  recipientName: string;
}) => {
  return (
    <div className="bg-soft-secondary border-soft flex flex-col gap-3 rounded-lg border p-4 text-sm">
      {recipientName && <LineItem label="Recipient" value={recipientName} />}
      <LineItem
        label="Amount"
        value={formatCurrency({ value: amount, options: { fromCents: false } })}
      />
      {referenceId && <LineItem label="Reference ID" value={referenceId} />}
      {description ? (
        <div>
          <span className="text-default-secondary">Description</span>
          <p className="text-default-primary pt-2 font-semibold">{description}</p>
        </div>
      ) : (
        <div className="flex justify-between">
          <span className="text-default-secondary">Description</span>
          <p className="text-default-secondary">Blank</p>
        </div>
      )}
    </div>
  );
};
