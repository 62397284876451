import { useContext } from 'react';
import { OnboardingWizardContext } from './OnboardingWizardContext';

export function useOnboardingGuide(): {
  goToPrevStep: () => void;
  goToNextStep: () => void;
  stepperLabels: Array<{ label: string; key: string }>;
} {
  return useContext(OnboardingWizardContext);
}
