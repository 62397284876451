import { FlexOfferResponse } from '@app/@types/promotion.types';
import {
  faArrowUpToDottedLine,
  faBank,
  faDollarSign,
  faListCheck,
  faXmark,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { ReactElement } from 'react';
import type { SpendPromotion } from './PathToCredit';

const LearnMoreModal = ({
  open,
  onClose,
  spendPromotion,
  flexOffer,
}: {
  open: boolean;
  onClose: () => void;
  spendPromotion: SpendPromotion;
  flexOffer: FlexOfferResponse | null;
}): ReactElement | null => {
  const closeIcon = (
    <Button
      color="secondary"
      size="small"
      className="absolute right-4 top-4"
      startIcon={<FontAwesomeIcon icon={faXmark} size="1x" color="#676D7C" />}
    />
  );

  const { goal_spend_amount, total_weeks_remaining, goal_target_weeks } = spendPromotion;

  const heading = flexOffer ? 'Earn towards AtoB Flex (Credit)' : 'Get evaluated for AtoB Flex';
  const actionOnCompletion = flexOffer
    ? 'provide information about your business and connect your bank account'
    : 'provide information about your business, which will be used for credit line evaluation';

  return (
    <Dialog open={open} onClose={onClose}>
      <>
        <div className="flex flex-row items-center">
          <img src="/images/path-to-credit-modal-hero.png" alt="popup-header" className="w-full" />
          <div className="absolute right-0 top-0">{closeIcon}</div>
        </div>
      </>
      <DialogTitle>
        <h3 className="text-xl font-bold md:text-2xl">{heading}</h3>
      </DialogTitle>
      <DialogContent>
        <h4 className="text-default-primary font-semibold">Rules</h4>
        <div className="text-default-primary text-sm font-normal">
          In the next {total_weeks_remaining} weeks, spend at least {goal_spend_amount} for{' '}
          {goal_target_weeks} weeks. Upon completion, you'll be asked to {actionOnCompletion}.
        </div>
        {flexOffer ? (
          <div className="mt-2 text-sm font-normal">
            If your bank account is in good standing, you can accept the terms and switch to AtoB
            Flex (credit) at the credit offer outlined in your email.
          </div>
        ) : (
          <div className="mt-2 text-sm font-normal">
            Once approved, you&apos;ll be able to review details of the terms and decide to switch
            to AtoB Flex (credit), or stay with AtoB Unlimited (prepaid).
          </div>
        )}

        <h4 className="text-default-primary my-4 mt-6 text-base font-semibold">
          Upgrading to AtoB Flex
        </h4>
        <div className="flex items-center">
          <FontAwesomeIcon
            icon={faArrowUpToDottedLine}
            size="1x"
            className="text-default-primary scale-150 p-3 pr-6"
          />
          <div className="border-soft flex flex-col border-b pb-4 text-sm">
            <div className="text-default-primary font-bold">Credit Line & Billing Cycle</div>
            <div className="text-default-primary">
              If approved, you will receive a credit line, and be billed on a recurring cycle to be
              determined by our review of your business information.
            </div>
          </div>
        </div>
        {flexOffer ? (
          <>
            <div className="my-4 flex items-center">
              <FontAwesomeIcon
                icon={faBank}
                size="1x"
                className="text-default-primary scale-150 p-3 pr-6 pt-1"
              />
              <div className="border-soft flex flex-col border-b pb-4 text-sm">
                <div className="text-default-primary font-bold">Link Bank Account</div>
                <div className="text-default-primary">
                  Link your bank account with a balance above the required amount of $
                  {flexOffer.bank_balance_required_cents / 100} to ensure your account is in good
                  standing.
                </div>
              </div>
            </div>
            <div className="mb-4 flex items-center">
              <FontAwesomeIcon
                icon={faDollarSign}
                size="1x"
                className="text-default-primary ml-1 scale-150 p-3 pr-6"
              />
              <div className="flex flex-col text-sm">
                <div className="text-default-primary font-bold">Pay Upgrade Fee</div>
                <div className="text-default-primary">
                  Pay a one-time upgrade fee of ${flexOffer.fee_cents / 100}, only at time of
                  completing your spend target for 4 weeks. If you do not meet your spend target,
                  you won&apos;t be charged anything.
                </div>
              </div>
            </div>
          </>
        ) : (
          <div className="mb-8 mt-4 flex items-center">
            <FontAwesomeIcon
              icon={faListCheck}
              size="1x"
              className="text-default-primary scale-150 p-3 pr-5"
            />
            <div className="flex flex-col text-sm">
              <div className="text-default-primary font-bold">Merchant Category Restrictions</div>
              <div className="text-default-primary mb-8 mt-4 flex">
                Unlike AtoB Unlimited, you will only be able to use your card toward fuel and
                vehicle expenses.{' '}
                <a
                  className="underline"
                  target="_blank"
                  rel="noreferrer"
                  href="https://atobco.zendesk.com/hc/en-us/articles/5377939755419-Can-I-use-my-AtoB-card-to-spend-on-more-than-fuel"
                >
                  View Categories
                </a>
              </div>
            </div>
          </div>
        )}
        {!flexOffer && (
          <div className="text-default-secondary mt-8 text-xs">
            *AtoB guarantees 100% credit evaluation; most customers will be able to receive some
            form of credit offering.
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default LearnMoreModal;
