import { EndpointResponse } from '@app/@types/api.types';
import { InstantPayoutRecipient } from '@app/@types/instant_payout_method.types';
import FilterGroup from '@app/components/Filters/FilterGroup';
import Header from '@app/components/Navigation/Header';
import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import { RecipientSidebar } from '@app/components/Sidebars/RecipientSidebar/RecipientSidebar';
import { PageContentWrapper } from '@app/components/wrappers/PageContentWrapper';
import StickyButtonWrapper from '@app/components/wrappers/StickyButtonWrapper';
import { useUrlState } from '@app/hooks/useUrlState';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import Searchbar from '@atob-developers/shared/src/components/Searchbar';
import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { isEqual, pick } from 'lodash-es';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSWR from 'swr';
import RecipientModal from './Recipients/RecipientModal';
import RecipientsList from './RecipientsList';

export default function RecipientsOverview(): ReactElement {
  const [isAddRecipientModalActive, setAddRecipientModalActive] = useState(false);
  const [sidebarRecipientId, setSidebarRecipientId] = useState<string | null>(null);

  const [selectedFilters, setSelectedFilters] = useUrlState<{ like: string | null }>({
    like: null,
  });

  const clearFilters = useCallback(() => {
    setSelectedFilters((prevFilters) => ({ ...prevFilters, like: null }));
  }, [setSelectedFilters]);

  const showClearFilters = useMemo(() => {
    const queryStateFilters = pick(selectedFilters, 'like');
    return !isEqual(queryStateFilters, { like: null });
  }, [selectedFilters]);

  const navigate = useNavigate();

  const {
    data: recipients,
    isLoading,
    mutate,
    error,
  } = useSWR<EndpointResponse<InstantPayoutRecipient[]>>(
    {
      url: '/instant_payout/recipients?include=treasury_recipients,instant_payout_methods',
      params: {
        like: selectedFilters.like,
      },
    },
    apiGetFetcher,
  );

  const AddRecipientButton = (
    <Button
      size="small"
      startIcon={<FontAwesomeIcon icon={faPlus} />}
      className="w-full md:w-auto"
      onClick={() => setAddRecipientModalActive(true)}
    >
      Add recipient
    </Button>
  );

  const SearchInput = (
    <FilterGroup
      className="!m-0 w-full !p-0"
      clearFilters={showClearFilters ? clearFilters : undefined}
    >
      <Searchbar
        placeholder="Search recipient"
        name="search"
        onChange={(e) => setSelectedFilters((p) => ({ ...p, like: e.target.value, page: 1 }))}
        value={selectedFilters.like ?? ''}
        className="w-full"
      />
    </FilterGroup>
  );

  return (
    <PageContentWrapper
      header={
        <Header
          title="Manage recipients"
          onBack={() => navigate('/wallet/overview')}
          mobileStickyButton={<StickyButtonWrapper>{AddRecipientButton}</StickyButtonWrapper>}
          rightContent={
            <div className="flex gap-3">
              {SearchInput}
              {AddRecipientButton}
            </div>
          }
        />
      }
    >
      <RoundedCard className="border-b-0">
        <div className="border-soft border-b p-4 md:hidden">{SearchInput}</div>
        <RecipientsList
          recipients={recipients?.data ?? []}
          isLoading={isLoading}
          recipientsError={error}
          onRowClick={(row: InstantPayoutRecipient) => setSidebarRecipientId(row.id)}
        />
      </RoundedCard>
      <RecipientModal
        mode="add"
        onSuccess={async () => {
          await mutate();
          setAddRecipientModalActive(false);
        }}
        onClose={() => setAddRecipientModalActive(false)}
        open={isAddRecipientModalActive}
      />

      <RecipientSidebar
        recipientId={sidebarRecipientId}
        open={!!sidebarRecipientId}
        reset={mutate}
        setOpen={(open: boolean) => !open && setSidebarRecipientId(null)}
      />
    </PageContentWrapper>
  );
}
