import { MobileNavHeaderMenuContext } from '@app/contexts/MobileNavHeaderMenuContextComponent';
import { useEventDismissed } from '@app/hooks/useEventDismissed';
import useMatcher from '@app/hooks/useMatcher';
import { NavbarOption } from '@app/interfaces/navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip } from '@mui/material';
import classNames from 'classnames';
import { useContext } from 'react';
import { NavLink } from 'react-router-dom';

type NavbarOptionProps = {
  option: NavbarOption;
};

export function NavbarOptionLink({ option }: NavbarOptionProps) {
  const { link, showActiveOn, icon, message, event } = option;

  const { setMobileNavOpen } = useContext(MobileNavHeaderMenuContext);
  const { dismissed, loading, setDismissed } = useEventDismissed(event || '');
  const isActive = useMatcher(link, ...(showActiveOn ?? []));

  const showChip = !!event && !loading && !dismissed;

  const dismissChip = () => {
    if (event) {
      setDismissed();
    }

    setMobileNavOpen(false);
  };

  return (
    <NavLink
      onClick={dismissChip}
      to={link || '#'}
      className={classNames(
        'hover:bg-soft-primary-hover flex h-12 cursor-pointer items-center justify-between rounded p-3 md:h-10 md:py-2.5 md:pr-2.5',
        isActive && 'bg-strong-secondary',
      )}
      data-testid="navbar-section-item"
    >
      <div className="flex flex-grow items-center gap-2.5">
        <FontAwesomeIcon icon={icon} className="text-default-primary h-6 w-6 md:h-5 md:w-5" />
        <p className="text-default-secondary text-sm font-medium">{message}</p>
        {showChip && <Chip size="small" color="black" label="New" />}
      </div>
    </NavLink>
  );
}
