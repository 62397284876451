import { ChargeEventData } from '@app/@types/charge_events.types';
import useChartJSStyling from '@app/hooks/useChartJSStyling';
import { faCheck, faInfoCircle } from '@fortawesome/pro-regular-svg-icons';
import { faCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip, Tooltip } from '@mui/material';
import {
  BarElement,
  BarProps,
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  LinearScale,
} from 'chart.js';
import classNames from 'classnames';
import { isEqual } from 'lodash-es';
import { ReactElement, useRef, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import NoDataAvailable from './NoDataAvailable';

const TOOLTIP_TEXT =
  'Fuel level readings from the telematics device may fluctuate. ' +
  'Therefore estimate for gallons added to tank are based on the most conservative ' +
  'measurement of fuel in tank before vs. after the transaction.';

ChartJS.register(CategoryScale, LinearScale, BarElement);

const CHART_X_PADDING = 8;
const ORANGE_BAR_OFFSET = 2;
const MOCK_DATA_NO_AVAILABLE = 2;
const ANIMATION_DURATION = 500;

type StyleType = {
  top: number;
  left: number;
  width: number;
  height: number;
};

const ChargeEventFuelLevelsChart = ({
  chargeEvent,
}: {
  chargeEvent: ChargeEventData;
}): ReactElement => {
  const chartRef = useRef<ChartJS<'bar', number[], unknown>>(null);
  const [styles, setStyles] = useState<StyleType | undefined>(undefined);

  useChartJSStyling();

  const { fuel_levels_for_transaction } = chargeEvent;

  const fuelDataAvailable = !!fuel_levels_for_transaction?.gallons_before_authorization_min;

  const suspicious = fuel_levels_for_transaction?.is_suspicious;
  const gallonsMissing = parseFloat(
    fuel_levels_for_transaction?.purchase_difference_by_min_max || '0',
  );
  const gallonsPurchased = parseFloat(fuel_levels_for_transaction?.gallons_purchased || '0');
  const gallonsAddedToTank = parseFloat(
    fuel_levels_for_transaction?.expected_gallons_purchased_by_min_max || '0',
  );

  const resultDisplay =
    gallonsMissing <= 0
      ? 'No excess gallons purchased'
      : `${gallonsMissing.toFixed(1)} excess gallons purchased`;

  const options: ChartOptions<'bar'> = {
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    layout: {
      padding: { left: CHART_X_PADDING, right: CHART_X_PADDING },
    },
    animation: {
      duration: ANIMATION_DURATION,
      onComplete: () => {
        if (!chartRef.current) {
          return;
        }

        const chart = chartRef.current;

        const purchasedData = chart.getDatasetMeta(0).data.at(0)! as BarElement & BarProps;
        const gallonsData = chart.getDatasetMeta(1).data.at(0)! as BarElement & BarProps;

        const stylesObject = {
          left: gallonsData.x - ORANGE_BAR_OFFSET,
          top: purchasedData.y - purchasedData.height / 2 - ORANGE_BAR_OFFSET,
          width: purchasedData.width - gallonsData.width + ORANGE_BAR_OFFSET * 2,
          height: purchasedData.height + ORANGE_BAR_OFFSET * 2,
          opacity: 1,
        };

        if (!isEqual(stylesObject, styles)) {
          setStyles(stylesObject);
        }
      },
    },
    scales: {
      y: {
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
      x: {
        suggestedMax: Math.max(gallonsPurchased, gallonsAddedToTank) + 3,
        grid: {
          color: window
            .getComputedStyle(document.body)
            .getPropertyValue('--backgroundColor-strong-secondary'),
        },
        border: {
          display: false,
        },
        ticks: {
          display: true,
        },
      },
    },
    plugins: {
      datalabels: {
        display: true,
        formatter: (value, context) => {
          // Internal gallons label - don't show this.
          if (context.dataset.label?.startsWith('[INTERNAL]')) {
            return '';
          }

          // Uppar bar label, galons purchase. We always show the number here.
          if (context.dataset.label === 'Gallons purchased') {
            return `${gallonsPurchased.toFixed(1)} gal`;
          }

          // We show the number here only if the value is available
          return fuelDataAvailable ? `${value.toFixed(1)} gal` : 'No data available';
        },
        align: 'end',
        anchor: 'end',
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      tooltip: {
        enabled: true,
        caretSize: 0,
        displayColors: false,
        filter: (tooltipItem) =>
          tooltipItem.dataset.label === 'Gallons purchased' && gallonsMissing > 0,
        callbacks: {
          label: () => resultDisplay,
          title: () => '',
        },
      },
    },
  };

  const labels = ['Gallons'];
  const datasets =
    gallonsMissing > 0
      ? [
          {
            label: 'Gallons purchased',
            data: [gallonsPurchased],
            backgroundColor: window
              .getComputedStyle(document.body)
              .getPropertyValue('--textColor-default-primary'),
            borderRadius: 6,
            stack: 'Stack 0',
            borderSkipped: false,
          },
          {
            label: 'Gallons added to tank (Estimated)',
            data: [gallonsAddedToTank],
            backgroundColor: window
              .getComputedStyle(document.body)
              .getPropertyValue('--textColor-default-tertiary'),
            borderRadius: 6,
            borderSkipped: false,
            stack: 'Stack 1',
          },
        ]
      : [
          {
            label: 'Gallons purchased',
            data: [fuelDataAvailable ? gallonsPurchased : MOCK_DATA_NO_AVAILABLE],
            backgroundColor: window
              .getComputedStyle(document.body)
              .getPropertyValue('--textColor-default-primary'),
            stack: 'Stack 0',
          },
          {
            label: fuelDataAvailable ? 'Gallons added to tank (Estimated)' : 'No data available',
            data: [fuelDataAvailable ? gallonsPurchased : MOCK_DATA_NO_AVAILABLE],
            backgroundColor: window
              .getComputedStyle(document.body)
              .getPropertyValue('--textColor-default-tertiary'),
            stack: 'Stack 1',
          },
        ];

  return (
    <div className="flex w-full flex-col gap-3">
      <div className="flex w-full flex-col gap-2">
        <div className="flex w-full items-center justify-between gap-1">
          <div className="flex items-center gap-1">
            <span className="text-default-secondary text-xs leading-5 md:text-sm">
              Fuel level check
            </span>
            <Tooltip title={TOOLTIP_TEXT}>
              <FontAwesomeIcon icon={faInfoCircle} className="text-default-secondary h-4 w-4" />
            </Tooltip>
          </div>
          {fuelDataAvailable && (
            <Chip
              size="small"
              color={suspicious ? 'orange' : 'green'}
              label={suspicious ? 'Suspicious' : 'Passed'}
              icon={
                <FontAwesomeIcon icon={suspicious ? faInfoCircle : faCheck} className="h-3 w-3" />
              }
            />
          )}
        </div>
        {fuelDataAvailable && (
          <span className="text-default-primary text-xs font-medium leading-5 md:text-sm">
            {resultDisplay}
          </span>
        )}
      </div>
      <div className="relative">
        <div
          className={classNames(
            'border-soft bg-soft-secondary flex flex-col rounded-md border',
            !fuelDataAvailable && 'blur-[6px]',
          )}
        >
          <div className="flex flex-col gap-2 p-4 lg:flex-row">
            <div className="flex items-center gap-2.5">
              <FontAwesomeIcon icon={faCircle} className="text-default-primary h-1.5 w-1.5" />
              <span className="text-default-primary text-[10px] font-medium leading-4 md:text-xs">
                Gallons purchased
              </span>
            </div>
            <div className="flex items-center gap-2.5">
              <FontAwesomeIcon icon={faCircle} className="text-default-secondary h-1.5 w-1.5" />
              <span className="text-default-primary text-[10px] font-medium leading-4 md:text-xs">
                Gallons added to the tank{' '}
                <span className="text-default-secondary font-normal">(Estimated)</span>
              </span>
            </div>
          </div>
          <div className="relative">
            <Bar
              className="max-w-full"
              ref={chartRef}
              data={{
                labels,
                datasets,
              }}
              options={options}
            />
            {gallonsPurchased !== gallonsAddedToTank && (
              <div
                className="border-warning pointer-events-none absolute rounded-r-md border-2 border-dashed opacity-0 transition-opacity"
                style={{ ...styles, transitionDuration: `${ANIMATION_DURATION}ms` }}
              />
            )}
          </div>
        </div>
        {!fuelDataAvailable && <NoDataAvailable />}
      </div>
    </div>
  );
};

export default ChargeEventFuelLevelsChart;
