import { CustomerSettingsAPIWrapped } from '@app/@types/customer_settings.types';
import { Switch } from '@mui/material';
import axios from 'axios';
import { ReactElement, useState } from 'react';

interface TelematicsNotificationsFormProps {
  value: boolean;
}

const TelematicsNotificationsForm = ({ value }: TelematicsNotificationsFormProps): ReactElement => {
  const [isEnabled, setIsEnabled] = useState(value);

  const submitChangeRequest = () => {
    axios
      .post<CustomerSettingsAPIWrapped>('/customer/change_request', {
        change_request: {
          change_request_type: 'telematics_notifications_enabled',
          new_value: !isEnabled,
        },
      })
      .catch(() => setIsEnabled(isEnabled))
      .finally(() => setIsEnabled(!isEnabled));
  };

  return (
    <div className="flex h-[70%] cursor-not-allowed items-center justify-start gap-2">
      <p>Enable SMS notifications for suspicious activities</p>
      <Switch checked={isEnabled} onChange={submitChangeRequest} />
    </div>
  );
};

export default TelematicsNotificationsForm;
