import Header from '@app/components/Navigation/Header';
import { Loading } from '@app/components/layout';
import ConfirmActionDialog from '@app/components/layout/ConfirmActionDialog';
import PageContentWrapper from '@app/components/wrappers/PageContentWrapper';
import {
  getUpdateRestrictionParams,
  parseRestrictionResponse,
  useRestrictions,
  useUpdateRestrictions,
} from '@app/hooks/query/useRestrictions';
import useProduct from '@app/hooks/useProduct';
import constants from '@app/utils/constants';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import * as Sentry from '@sentry/react';
import { Form, Formik } from 'formik';
import { ReactElement, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { SaveButton } from './SaveButton';
import SpendControlForm from './SpendControlForm';
import { FormValues } from './policy.types';

interface EditDefaultSettingsPageProps {
  allowSetSpendLimit: boolean;
  allowRestrictions: boolean;
  allowTelematics: boolean;
  spendTier: number;
}
const TelematicsOptions = constants.TelematicsOptions;

const EditDefaultSettingsPage = ({
  spendTier,
  allowSetSpendLimit,
  allowRestrictions,
  allowTelematics,
}: EditDefaultSettingsPageProps): ReactElement => {
  const [openUnsavedDialog, setOpenUnsavedDialog] = useState(false);
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const { data: restrictionData, isLoading: isLoadingRestrictions } = useRestrictions();
  const [resourceTagsEnabled] = useProduct('resource_tags');

  const restrictions =
    restrictionData && restrictionData.data && parseRestrictionResponse(restrictionData.data);

  const navigateBack = resourceTagsEnabled ? () => navigate('/tags') : null;
  const { updateRestrictions, error: restrictionsError } = useUpdateRestrictions();

  if (restrictionsError) {
    Sentry.captureException(restrictionsError);
    addToast({
      type: 'error',
      title: 'There was an error saving the configuration. Please refresh and try again later',
    });
  }

  const validationSchema = Yup.object().shape({
    policy: Yup.object({
      name: Yup.string(),
      description: Yup.string(),
      per_transaction_limit: Yup.number(),
      daily_spend_limit: Yup.number(),
      weekly_spend_limit: Yup.number(),
      telematics_setting: Yup.mixed().oneOf(TelematicsOptions.map((o) => o.id)),
      card_security_enabled: Yup.boolean(),
    }),
    restrictions: Yup.object({
      afdOnly: Yup.boolean(),
      selectedCategoryIds: Yup.array(),
      merchantWhitelist: Yup.array(),
      merchantBlacklist: Yup.array(),
    }),
  });

  const onSubmit = async (tagValues: FormValues) => {
    const restrictionParams = getUpdateRestrictionParams(
      tagValues.restrictions,
      'Customer',
      spendTier,
    );
    await updateRestrictions(restrictionParams);
  };

  const initialValues = {
    policy: {
      name: '',
      description: '',
      per_transaction_limit: '',
      daily_spend_limit: '',
      weekly_spend_limit: '',
      telematics_setting: TelematicsOptions[0].id,
      card_security_enabled: false,
    },
    restrictions: {
      afdOnly: restrictions?.afdOnly || false,
      selectedCategoryIds: restrictions?.selectedCategoryIds || [],
      merchantWhitelist: restrictions?.merchantWhitelist || [],
      merchantBlacklist: restrictions?.merchantBlacklist || [],
    },
  };

  if (isLoadingRestrictions) {
    return <Loading />;
  }

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ values, touched, setFieldValue, errors, dirty, isValid, submitForm, isSubmitting }) => (
        <Form>
          <ConfirmActionDialog
            title={'Unsaved changes'}
            open={openUnsavedDialog}
            handleConfirm={navigateBack ? navigateBack : () => {}}
            handleCancel={() => setOpenUnsavedDialog(false)}
          >
            Leaving this page will discard any unsaved changes. Do you want to continue?
          </ConfirmActionDialog>
          <PageContentWrapper
            header={
              <Header
                title="Default Spend Policies"
                onBack={
                  navigateBack &&
                  (() => {
                    return Object.values(touched).length === 0
                      ? navigateBack()
                      : setOpenUnsavedDialog(true);
                  })
                }
              />
            }
          >
            <SpendControlForm
              allowTelematics={allowTelematics}
              allowSetSpendLimit={allowSetSpendLimit}
              allowRestrictions={allowRestrictions}
              isDefaultSettings={true}
              spendTier={spendTier}
              values={values}
              setFieldValue={setFieldValue}
              errors={errors}
              touched={touched}
              saveButton={
                <SaveButton
                  disabled={!(isValid && dirty)}
                  isSubmitting={isSubmitting}
                  onSubmit={submitForm}
                />
              }
            />
          </PageContentWrapper>
        </Form>
      )}
    </Formik>
  );
};

export default EditDefaultSettingsPage;
