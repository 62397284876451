import classNames from 'classnames';
import { ForwardedRef, PropsWithChildren, ReactElement, forwardRef } from 'react';

export function CardHeader({
  title,
  description,
  className,
}: {
  title: string | ReactElement;
  description?: string;
  className?: string;
}): ReactElement {
  return (
    <div
      className={classNames(
        'flex flex-col md:flex-row md:items-center md:justify-between',
        className,
      )}
    >
      <h2 className="text-default-primary h-fit flex-1 text-base font-semibold">{title}</h2>
      {description && (
        <p className="text-default-secondary pt-2 text-sm font-normal md:pt-0">{description}</p>
      )}
    </div>
  );
}

export function CardSection({ children }: PropsWithChildren<unknown>): ReactElement {
  return (
    <div className="align-center border-soft flex flex-col justify-between border-b px-6 py-4 last:border-b-0 md:justify-start">
      {children}
    </div>
  );
}

const Card = forwardRef(function CardElement(
  {
    children,
    radius,
    noPadding = false,
  }: PropsWithChildren<{ radius?: 's' | 'm' | 'l'; noPadding?: boolean }>,
  ref?: ForwardedRef<HTMLDivElement>,
) {
  const RADIUS_SIZE = {
    s: 'rounded',
    m: 'rounded-lg',
    l: 'rounded-xl',
  };
  return (
    <div
      ref={ref}
      className={classNames(
        'rounded-card border-soft bg-soft-primary relative inline-block h-full w-full min-w-fit border',
        RADIUS_SIZE[radius || 'm'],
        !noPadding && 'p-4',
      )}
    >
      {children}
    </div>
  );
});

export default Card;
