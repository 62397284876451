import { BankAccountWithEstimateData } from '@app/@types/payments.types';
import PaymentDetailItem from '@app/components/PaymentModal/PaymentDetailSection/PaymentDetailItem/PaymentDetailItem';
import ErrorMessage from '@app/components/PaymentModal/PaymentErrors/ErrorMessage';
import BankBrokeConnectionError from '@app/components/PaymentModal/components/BankBrokeConnectionError';
import ChangeAccountText from '@app/components/PaymentModal/components/ChangeText';
import FloatingDropdown from '@app/components/PaymentModal/components/FloatingDropdown';
import FloatingDropdownItem from '@app/components/PaymentModal/components/FloatingDropdownItem';
import { ReactElement, ForwardedRef, forwardRef, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface BankAccountDetailItemProps {
  selectedBankAccount?: BankAccountWithEstimateData;
  setSelectedBankAccount: (selectedBankAccount: BankAccountWithEstimateData) => void;
  bankAccountsWithEstimates: BankAccountWithEstimateData[];
  bankAccountError: string | null;
}

const BankAccountDetailItem = ({
  selectedBankAccount,
  bankAccountsWithEstimates,
  setSelectedBankAccount,
  bankAccountError,
}: BankAccountDetailItemProps): ReactElement => {
  const [showSelectBankAccountModal, setShowSelectBankAccountModal] = useState(false);
  const navigate = useNavigate();

  const navigateToPaymentMethods = () => {
    navigate('/billing/payment-methods');
  };
  const anchorRef = useRef(null);
  if (!selectedBankAccount) {
    return (
      <NoSelectedBankAccount
        bankAccountError={bankAccountError}
        navigateToPaymentMethods={navigateToPaymentMethods}
      />
    );
  }

  const getInstitutionName = (institutionName?: string): string => {
    if (!institutionName) {
      return 'Bank Account';
    }
    return institutionName;
  };

  const { attributes: defaultBankAccountAttributes } = selectedBankAccount.bank_account.data;

  return (
    <>
      <FloatingDropdown
        open={showSelectBankAccountModal}
        toggle={() => setShowSelectBankAccountModal(false)}
        title="Bank Accounts"
        showRight
        anchorEl={anchorRef.current}
      >
        {bankAccountsWithEstimates.map((bankAccountWithEstimates: BankAccountWithEstimateData) => {
          const { attributes: bankAccountWithEstimatesAttributes } =
            bankAccountWithEstimates.bank_account.data;
          const { attributes: selectedBankAccountAttributes } =
            selectedBankAccount.bank_account.data;
          return (
            <FloatingDropdownItem
              selected={
                bankAccountWithEstimatesAttributes.bank_account_id ===
                selectedBankAccountAttributes.bank_account_id
              }
              text={`${getInstitutionName(
                bankAccountWithEstimatesAttributes.institution_name,
              )} •••• ${bankAccountWithEstimatesAttributes.mask}`}
              key={bankAccountWithEstimatesAttributes.bank_account_id}
              paymentItemKey={bankAccountWithEstimatesAttributes.bank_account_id}
              onClick={() => {
                setSelectedBankAccount(bankAccountWithEstimates);
                setShowSelectBankAccountModal(false);
              }}
            />
          );
        })}
        <FloatingDropdownItem
          paymentItemKey="add-a-new-bank"
          selected={false}
          text="Add New Bank"
          onClick={() => navigateToPaymentMethods()}
        />
      </FloatingDropdown>
      <PaymentDetailItem text="Bank Account">
        {defaultBankAccountAttributes.institution_name} ••••
        {defaultBankAccountAttributes.mask}
        <EditBankAccountText
          ref={anchorRef}
          reconnectBank={defaultBankAccountAttributes.plaid_item_status === 'inactive'}
          onClick={() => setShowSelectBankAccountModal(true)}
          navigateToPaymentMethods={navigateToPaymentMethods}
        />
      </PaymentDetailItem>
    </>
  );
};

const NoSelectedBankAccount = ({
  navigateToPaymentMethods,
  bankAccountError,
}: {
  bankAccountError: string | null;
  navigateToPaymentMethods: () => void;
}) => {
  return (
    <>
      <PaymentDetailItem text="Bank Account">
        <span
          className="text-green cursor-pointer font-medium"
          onClick={() => navigateToPaymentMethods()}
        >
          Add Bank Account
        </span>
      </PaymentDetailItem>
      {bankAccountError && (
        <div className="flex justify-end">
          <ErrorMessage text={bankAccountError} />
        </div>
      )}
    </>
  );
};

const EditBankAccountText = forwardRef(
  (
    {
      onClick,
      reconnectBank = false,
      navigateToPaymentMethods,
    }: {
      onClick: () => void;
      reconnectBank: boolean;
      navigateToPaymentMethods: () => void;
    },
    ref: ForwardedRef<HTMLElement>,
  ): ReactElement => {
    return (
      <>
        <ChangeAccountText ref={ref} onClick={onClick}>
          Change
        </ChangeAccountText>
        {reconnectBank && (
          <BankBrokeConnectionError navigateToPaymentMethods={navigateToPaymentMethods} />
        )}
      </>
    );
  },
);
EditBankAccountText.displayName = 'EditBankAccountText';

export default BankAccountDetailItem;
