import { VehicleData } from '@app/@types/vehicle.types';
import { ErrorNotification } from '@app/components/layout';
import Modal, {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { LoadingButton } from '@mui/lab';
import axios, { AxiosError } from 'axios';
import { ReactElement, useState } from 'react';

interface ArchiveVehicleModalProps {
  isActive: boolean;
  reset: () => void;
  vehicle: VehicleData;
}

export default function ArchiveVehicleModal({
  isActive,
  reset,
  vehicle,
}: ArchiveVehicleModalProps): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { addToast } = useToasts();

  const deleteVehicle = async () => {
    try {
      setIsLoading(true);
      await axios.delete(`/vehicles/${vehicle.id}`);
      reset();
      addToast({ type: 'success', title: `${vehicle.name} successfully archived` });
    } catch (e: unknown) {
      setError((e as AxiosError).message);
    }

    setIsLoading(false);
  };

  return (
    <Modal open={isActive} toggle={reset}>
      <ModalHeader title={`Archive ${vehicle.name}`} onClose={reset} />
      <ModalBodyContent>
        {error && <ErrorNotification error={error} />}
        <div className="flex w-full flex-col items-baseline justify-between">
          <p className="text-default-primary mb-4">
            This will archive {vehicle.name} from your list of vehicles.
          </p>
          <p className="text-default-secondary text-sm">
            You can restore it at any time in the future.
          </p>
        </div>
      </ModalBodyContent>
      <ModalFooter>
        <LoadingButton size="small" loading={isLoading} onClick={deleteVehicle}>
          Archive {vehicle.name ?? ''}
        </LoadingButton>
      </ModalFooter>
    </Modal>
  );
}
