import { PaginationMeta } from '@app/@types/api.types';
import { CardData } from '@app/@types/card.types';
import { WithDriver } from '@app/@types/driver.types';
import { TagListData, WithResourceTag } from '@app/@types/tag.types';
import { WithVehicle } from '@app/@types/vehicle.types';
import BulkActionsBar from '@app/components/BulkActionsBar/BulkActionsBar';
import { CardAssignedTo } from '@app/components/Cards/CardsListItems';
import { AssignTagSidebar } from '@app/components/Sidebars/AssignTagSidebar/AssignTagSidebar';
import { Cell, CellDetail } from '@app/components/TableV2/Cell';
import { MobileListItem } from '@app/components/TableV2/MobileListItem';
import ResponsiveTable from '@app/components/TableV2/ResponsiveTable';
import { TagColumn } from '@app/components/TableV2/StandardColumns';
import { ColumnDefinition } from '@app/components/TableV2/Table';
import useProduct from '@app/hooks/useProduct';
import { chainBulkRequests } from '@app/utils/data/bulkRequests';
import { WithId } from '@atob-developers/shared/src/@types/form.types';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { Chip } from '@mui/material';
import { GridRowId, GridSortModel } from '@mui/x-data-grid-pro';
import axios from 'axios';
import { ReactElement, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

type CardListItem = CardData & WithResourceTag & WithDriver & WithVehicle;

type CardsListProps = {
  data: CardListItem[];
  paginationMeta: PaginationMeta | undefined;
  showTags: boolean;
  handleSelectCards: (selectedCards: readonly GridRowId[]) => void;
  selectedCards: string[];
  fetchData: () => void;
  onPageIndexChange: (pageIndex: number) => void;
  dataLoading: boolean;
  onSortModelChange: (model: GridSortModel) => void;
};

const CardStatusChip = ({ workflowState }: { workflowState: CardData['workflow_state'] }) => {
  if (workflowState === 'provisioning') {
    return <Chip label="Provisioning" size="small" color="white" className="uppercase" />;
  } else if (workflowState === 'active') {
    return <Chip label="Active" size="small" color="green" className="uppercase" />;
  }
  return <Chip label="Inactive" size="small" color="red" className="uppercase" />;
};

const CardTypeChip = ({ cardType }: { cardType: CardData['card_type'] }) => {
  if (cardType === 'virtual') {
    return <Chip label="Virtual Card" size="small" color="grey" className="uppercase" />;
  }
  return <Chip label="Physical Card" size="small" color="grey" className="uppercase" />;
};

const ListItem = ({ row, onClick }: { row: CardListItem; onClick?: () => void }) => (
  <MobileListItem onClick={onClick}>
    <div className="space-y-0.5">
      {row.workflow_state === 'inactive' && (
        <div className="pb-2">
          <Chip label="Inactive" color="red" size="small" />
        </div>
      )}
      <div className="pb-2">
        <CardTypeChip cardType={row.card_type} />
      </div>
      <p className="text-default-primary font-semibold">
        {row.card_number_last_four} {row.second_line}
      </p>
      <p className="text-default-primary">{row.driver?.name}</p>
      <p className="text-default-primary">{row.vehicle?.name}</p>
      <p className="text-default-primary">{row.resource_tag && row.resource_tag.tag_name}</p>
    </div>
  </MobileListItem>
);

const CardsList = ({
  data,
  paginationMeta,
  showTags,
  handleSelectCards,
  selectedCards,
  fetchData,
  dataLoading,
  onPageIndexChange,
  onSortModelChange,
}: CardsListProps): ReactElement => {
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [loading, setIsLoading] = useState(false);
  const [showAssignTag, setShowAssignTag] = useState(false);
  const showVirtualCards = data?.some((card) => card.card_type === 'virtual');
  const [resourceTagsEnabled] = useProduct('resource_tags');

  const columns = useMemo(
    () =>
      [
        {
          field: 'card_number_last_four',
          headerName: 'Card Number',
          minWidth: 150,
          flex: 1,
          renderCell: ({ row }) => {
            return (
              <Cell>
                {row.card_number_last_four}
                <CellDetail>{row.second_line}</CellDetail>
              </Cell>
            );
          },
        },
        {
          field: 'workflow_state',
          headerName: 'Status',
          minWidth: 150,
          flex: 1,
          renderCell: ({ row }) => <CardStatusChip workflowState={row.workflow_state} />,
        },
        {
          field: 'card_type',
          headerName: 'Card Type',
          canBeShown: showVirtualCards,
          minWidth: 150,
          flex: 1,
          renderCell: ({ row }) => <CardTypeChip cardType={row.card_type} />,
        },
        {
          field: 'driver.name',
          headerName: 'Assigned To',
          minWidth: 300,
          flex: 3,
          renderCell: ({ row }) => {
            return <CardAssignedTo driver={row.driver?.name} vehicle={row.vehicle?.name} />;
          },
        },
        {
          field: 'resource_tag',
          headerName: 'Tag',
          canBeShown: resourceTagsEnabled,
          flex: 2,
          valueGetter: (_, row: CardListItem) => row.resource_tag?.tag_name,
          ...TagColumn,
        },
      ] as ColumnDefinition[],
    [resourceTagsEnabled, showVirtualCards],
  );

  const handleRowClick = (row: CardListItem) => {
    // eslint-disable-next-line react/prop-types
    navigate(`/cards/${row.id}`);
  };

  const bulkDeactivateAction = {
    text: 'Deactivate',
    onClick: () => {
      setIsLoading(true);
      const requests = selectedCards.map((id) => {
        return () => axios.put(`/cards/${id}/make_inactive`);
      });
      chainBulkRequests(requests)
        .then(() => {
          addToast({
            type: 'success',
            title: 'Your cards were successfully deactivated.',
          });
        })
        .catch(() => {
          addToast({
            type: 'error',
            title: 'There was a problem deactivating your cards.',
          });
        })
        .finally(() => {
          fetchData();
          setIsLoading(false);
        });
    },
  };

  const bulkActivateAction = {
    text: 'Activate',
    onClick: () => {
      setIsLoading(true);
      const requests = selectedCards.map((id) => {
        return () => axios.put(`/cards/${id}/activate`);
      });
      chainBulkRequests(requests)
        .then(() => {
          addToast({
            type: 'success',
            title: 'Your cards were successfully activated.',
          });
        })
        .catch(() => {
          addToast({
            type: 'error',
            title: 'There was a problem activating your cards.',
          });
        })
        .finally(() => {
          fetchData();
          setIsLoading(false);
        });
    },
  };

  const bulkRemoveTagsAction = {
    text: 'Remove Tag',
    onClick: () => onDeleteTagClick(),
  };

  const bulkAssignTagsAction = {
    text: 'Assign Tag',
    onClick: () => setShowAssignTag(true),
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const selectedCardTags: WithId<TagListData & { tag_id: number }>[] = selectedCards
    .map((val) => {
      const card = data.find((card) => card.id.toString() === val.toString());

      if (!card) {
        return null;
      }

      return {
        name: card.resource_tag?.tag_name,
        id: card.resource_tag?.id,
        tag_id: card.resource_tag?.tag_id,
        key: card.resource_tag?.id?.toString(),
        customer_id: card.customer_id,
      };
    })
    .filter((val) => !!val);

  const onDeleteTagClick = () => {
    axios
      .delete(`/resource_tags/bulk`, {
        data: {
          resource_tags: {
            resource_tag_ids: selectedCardTags.map((rt) => rt.id).filter((rt) => !!rt),
            resource_type: 'Card',
          },
        },
      })
      .then(() => {
        fetchData();
        setShowAssignTag(false);
      });
  };

  const assignTag = (tagID: number) => {
    return axios
      .post('/resource_tags/bulk', {
        resource_tags: {
          tag_id: tagID,
          resource_ids: selectedCards,
          resource_type: 'Card',
        },
      })
      .then(() => {
        fetchData();
      })
      .catch(() => {
        fetchData();
        addToast({
          type: 'error',
          title: 'Error assigning tags',
        });
      });
  };

  const onSaveAssignTags = (selectedTagID: number) => {
    assignTag(selectedTagID).then(() => {
      setShowAssignTag(false);
    });
  };

  const bulkActions = [bulkActivateAction, bulkDeactivateAction];
  // This is actually wrong. It should be assign task permission that triggers this.
  // TODO: Add assign tag as a product and fix this.
  if (showTags) {
    bulkActions.push(bulkAssignTagsAction, bulkRemoveTagsAction);
  }

  return (
    <>
      <ResponsiveTable
        columns={columns}
        data={data}
        onRowClick={handleRowClick}
        selectable={true}
        loading={loading || dataLoading}
        onRowSelectionModelChange={handleSelectCards}
        rowSelectionModel={selectedCards}
        onSortModelChange={onSortModelChange}
        paginationMeta={paginationMeta}
        onPageChange={(page) => onPageIndexChange(page)}
        mobileItemRenderer={ListItem}
      />
      <BulkActionsBar
        selectedCount={selectedCards.length}
        actions={bulkActions}
        onDeselectAll={() => handleSelectCards([])}
        overflowLimit={4}
      />
      {showAssignTag && (
        <AssignTagSidebar
          open={showAssignTag}
          setOpen={setShowAssignTag}
          onSaveTagClick={onSaveAssignTags}
          selectedID={null}
        />
      )}
    </>
  );
};

export default CardsList;
