import { faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const WarningMessage = ({ warning }: { warning?: string }) => {
  if (!warning) return null;

  return (
    <p className="text-warning p-1 text-xs sm:text-sm lg:text-base">
      <FontAwesomeIcon icon={faExclamationCircle} className="text-warning mr-1" />
      {warning}
    </p>
  );
};
export default WarningMessage;
