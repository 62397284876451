import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import { Cell } from '@app/components/TableV2/Cell';
import { MobileListItem } from '@app/components/TableV2/MobileListItem';
import ResponsiveTable from '@app/components/TableV2/ResponsiveTable';
import { ColumnDefinition } from '@app/components/TableV2/Table';
import { IftaFilingStage } from '@app/constants/iftaFiling';
import { useUploadIftaReport } from '@app/hooks/query/useIftaFilingQuery';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { Button } from '@mui/material';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

// Define the interface for the mapping data
interface MappingData {
  s_no: number;
  id: number;
  month: string;
  gallons: number;
  state: string;
  fuel_type: string | null;
  vehicle_name: string | null;
  year: number | null;
}

// Update the interface for the component props
type ReviewMappingInterface = {
  data: MappingData[];
  file: File | null;
  setIsCSVUploaded: (uploaded: boolean) => void;
};

export const COLUMNS = [
  {
    field: 's_no',
    headerName: 'S No.',
    minWidth: 150,
    flex: 1,
    renderCell: (params) => <Cell>{params.row.s_no}</Cell>,
  },
  {
    field: 'month',
    headerName: 'Month',
    minWidth: 150,
    flex: 1,
    renderCell: (params) => <Cell>{params.row.month}</Cell>,
  },
  {
    field: 'gallons',
    headerName: 'Gallons',
    minWidth: 150,
    flex: 1,
    renderCell: (params) => <Cell>{params.row.gallons}</Cell>,
  },
  {
    field: 'state',
    headerName: 'State',
    minWidth: 150,
    flex: 1,
    renderCell: (params) => <Cell>{params.row.state}</Cell>,
  },
  {
    field: 'fuel_type',
    headerName: 'Fuel type',
    minWidth: 150,
    flex: 1,
    renderCell: (params) => <Cell>{params.row.fuel_type ? params.row.fuel_type : null}</Cell>,
  },
  {
    field: 'vehicle_name',
    headerName: 'Vehicle',
    minWidth: 200,
    flex: 1,
    renderCell: (params) => <Cell>{params.row.vehicle_name ? params.row.vehicle_name : null}</Cell>,
  },
  {
    field: 'year',
    headerName: 'Year',
    minWidth: 150,
    flex: 1,
    renderCell: (params) => <Cell>{params.row.year ? params.row.year : null}</Cell>,
  },
] as ColumnDefinition[];

const MobileVehicleItemRenderer = ({
  row,
  onClick,
}: {
  row: MappingData;
  onClick?: () => void;
}) => (
  <MobileListItem onClick={onClick}>
    <div className="font-bold">
      <Cell>{row.s_no}</Cell>
    </div>
    <div>{row.state}</div>
    <div>{row.vehicle_name}</div>
  </MobileListItem>
);
const ReviewMapping = ({ data, file, setIsCSVUploaded }: ReviewMappingInterface): ReactElement => {
  const { addToast } = useToasts();
  const { trigger: uploadReport } = useUploadIftaReport();
  const navigate = useNavigate();
  const onClickSave = async () => {
    if (!file) return;

    const formData = new FormData();
    formData.append('ifta_fuel_csv', file);

    try {
      await uploadReport(formData);
      navigate(`/ifta-filing?stage=${IftaFilingStage.NON_ATOB_REPORTS}`);
      setIsCSVUploaded(false);
    } catch (error) {
      addToast({
        type: 'error',
        title: `Error saving IFTA report`,
      });
    }
  };

  return (
    <>
      <RoundedCard className="relative flex-1 py-4">
        <div className="flex items-center justify-center bg-white">
          <h2 className="mb-4 text-lg font-semibold text-gray-800">
            {COLUMNS.length} Columns & {data.length} entries found. Review the mapping with right
            indexes
          </h2>
        </div>
        <ResponsiveTable
          columns={COLUMNS}
          data={data || []}
          loading={false}
          mobileItemRenderer={MobileVehicleItemRenderer}
        />
        <div className="flex h-fit w-full items-center justify-center">
          <Button
            className="mt-4 w-full px-6 py-2 sm:w-auto"
            size="small"
            onClick={onClickSave}
            data-testid={'start-ifta-filing-button'}
          >
            Save Fuel Reports
          </Button>
        </div>
      </RoundedCard>
    </>
  );
};

export default ReviewMapping;
