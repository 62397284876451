import { PaginationMeta } from '@app/@types/api.types';
import { DateAndTimeColumn, DefaultColumn } from '@app/components/TableV2/StandardColumns';
import Table, { ColumnDefinition } from '@app/components/TableV2/Table';
import { moneyFormat } from '@app/pages/Wallet/WalletOverview/TransferFunds/utils';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { ReactElement } from 'react';
import { Repayment } from './BalanceRepayment';

dayjs.extend(utc);
dayjs.extend(timezone);

interface RepaymentTableProps {
  repaymentPage: Repayment[];
  metadata: PaginationMeta;
  onPageIndexChange: (pageIndex: number) => void;
  loading: boolean;
}

export default function RepaymentTable({
  repaymentPage,
  metadata,
  onPageIndexChange,
  loading,
}: RepaymentTableProps): ReactElement {
  const columns = [
    {
      field: 'customer_id',
      headerName: 'Customer ID',
      minWidth: 130,
      flex: 1,
    },
    {
      field: 'company_name',
      headerName: 'Carrier name',
      minWidth: 130,
      flex: 1,
    },
    {
      field: 'created_at',
      valueGetter: (_, row: Repayment) =>
        dayjs(row.created_at).local().format('YYYY-MM-DD HH:mm:ss'),
      ...DateAndTimeColumn('Payment date', 130, false),
      flex: 1,
    },
    {
      field: 'amount',
      headerName: 'Amount paid',
      minWidth: 120,
      flex: 1,
      renderCell: ({ row }) => moneyFormat(row.amount.cents / 100.0),
    },
    {
      field: 'reference_id',
      headerName: 'Reference ID',
      minWidth: 130,
      flex: 1,
    },
    {
      field: 'description',
      headerName: 'Description',
      ...DefaultColumn,
      flex: 2,
    },
  ] as ColumnDefinition[];

  return (
    <Table
      columns={columns}
      data={repaymentPage}
      paginationMeta={metadata}
      onPageChange={onPageIndexChange}
      loading={loading}
    />
  );
}
