import { ChargeEventData, SuspiciousChargeEvent } from '@app/@types/charge_events.types';
import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import SpinnerBoundary from '@app/components/Spinner/SpinnerBoundary';
import {
  CardColumn,
  DateAndTimeColumn,
  DriverColumn,
  MoneyColumn,
  TagColumn,
} from '@app/components/TableV2/StandardColumns';
import Table, { ColumnDefinition } from '@app/components/TableV2/Table';
import { TransactionStatusBadge } from '@app/components/TransactionsCard/TransactionStatusBadge';
import { ChargeEventQueryParams } from '@app/contexts/ChargeEventsContextComponent';
import { SortablePropDrill } from '@app/hooks/paging/useSortReducer.types';
import useChargeEvents from '@app/hooks/useChargeEvents';
import {
  formatCurrency,
  formatDateLocale,
  formatTime,
} from '@atob-developers/shared/src/utils/formatters';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { SuspiciousActivitiesFilters } from './SuspiciousActivitiesFilters';
import { Error } from './SuspiciousActivitiesPage';

const columns: ColumnDefinition[] = [
  {
    field: 'authorized_at',
    flex: 1,
    ...DateAndTimeColumn(),
  },
  {
    field: 'auth_approved',
    headerName: 'Status',
    flex: 1,
    minWidth: 120,
    renderCell: (params) => {
      const { auth_approved, auth_status } = params.row;
      return <TransactionStatusBadge auth_approved={auth_approved} auth_status={auth_status} />;
    },
  },
  {
    field: 'suspicious_reasons',
    headerName: 'Reason',
    flex: 3,
    minWidth: 250,
    valueGetter: (value: string[]) => value.join(', '),
  },
  {
    headerName: 'Gross',
    headerAlign: 'right',
    field: 'gross_amount_cents',
    flex: 1,
    ...MoneyColumn,
  },
  {
    field: 'card_last_four',
    headerName: 'Card',
    flex: 1,
    ...CardColumn,
    headerAlign: 'right',
    align: 'right',
  },
  {
    field: 'driver_name',
    headerName: 'Driver',
    flex: 1,
    ...DriverColumn,
  },
  {
    field: 'vehicle_name',
    headerName: 'Vehicle',
  },
  {
    field: 'merchant_name',
    flex: 2,
    minWidth: 200,
    headerName: 'Merchant',
  },
  {
    field: 'tag',
    headerName: 'Tag',
    flex: 1,
    ...TagColumn,
  },
];

export type SuspiciousActivitiesPageContentProps = {
  resourceTagsEnabled: boolean;
  setCurrentPage: (n: number) => void;
  filters: ChargeEventQueryParams;
  onSetFilters: (v: Partial<ChargeEventQueryParams>) => void;
  clearFilters?: () => void;
} & SortablePropDrill;

const SuspiciousActivitiesPageContent = ({
  setCurrentPage,
  resourceTagsEnabled,
  sort,
  toggleSort,
  filters,
  onSetFilters,
  clearFilters,
}: SuspiciousActivitiesPageContentProps): ReactElement => {
  const { error, isLoading, data } = useChargeEvents('/charge_events/suspicious', filters);
  const navigate = useNavigate();
  const goToTransactionDetails = (chargeEvent: ChargeEventData) => {
    navigate(`${chargeEvent.id}`);
  };

  return (
    <>
      {error ? (
        <Error />
      ) : (
        <>
          <RoundedCard className="hidden md:block">
            {resourceTagsEnabled && (
              <SuspiciousActivitiesFilters
                selectedFilters={filters}
                setSelectedFilters={onSetFilters}
                clearFilters={clearFilters}
              />
            )}
            <Table
              columns={columns}
              data={data?.data}
              loading={isLoading}
              pinLeftColumns={['authorized_at']}
              onRowClick={(row) => goToTransactionDetails(row)}
              paginationMeta={data?.meta}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </RoundedCard>
          <div className="block md:hidden">
            <SuspiciousActivityMobile
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              data={data?.data}
              {...{ isLoading, resourceTagsEnabled, sort, toggleSort }}
            />
          </div>
        </>
      )}
    </>
  );
};

const SuspiciousActivityMobileRow = ({
  record,
}: {
  record: SuspiciousChargeEvent;
}): ReactElement => {
  const {
    authorized_at: timestamp,
    merchant_name: merchant,
    gross_amount_cents: amount,
    suspicious_reasons: reasons,
    id,
  } = record;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const date = new Date(timestamp);
  const navigate = useNavigate();

  return (
    <div
      className="bg-soft-primary border-soft flex flex-row justify-between rounded-lg border p-4"
      onClick={() => navigate(`${id}`)}
    >
      <div className="text-default-primary">
        <div className="flex flex-row justify-between pb-2">
          <TransactionStatusBadge
            auth_approved={record.auth_approved}
            auth_status={record.auth_status}
          />
        </div>
        <div className="mt-2 text-sm">{reasons.join(', ')}</div>
        <div className="text-sm font-semibold">{merchant}</div>
        <div className="text-sm">
          {formatDateLocale(date)} • {formatTime(date)}
        </div>
      </div>
      <div className="text-default-primary text-sm font-semibold">
        {formatCurrency({ value: amount })}
      </div>
    </div>
  );
};

const SuspiciousActivityMobile = ({
  isLoading,
  data,
  resourceTagsEnabled,
}: {
  data: SuspiciousChargeEvent[];
  isLoading: boolean;
  resourceTagsEnabled: boolean;
}) => {
  return (
    <div className="relative">
      {isLoading && (
        <SpinnerBoundary backgroundColor={null} className="pt-8" verticallyCenter={false} />
      )}
      <div className="flex flex-col space-y-2">
        {data &&
          data.map((record, idx) => (
            <SuspiciousActivityMobileRow key={idx} {...{ record, resourceTagsEnabled }} />
          ))}
      </div>
    </div>
  );
};

export default SuspiciousActivitiesPageContent;
