import useThemeConfiguration from '@app/app/useThemeConfiguration';
import {
  ActivationRetentionPromoCopyData,
  useActivationRetentionPromoCopyQuery,
} from '@app/hooks/query/useActivationRetentionPromoCopyQuery';
import useThemeMode from '@app/hooks/useThemeMode';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import { ReactElement } from 'react';
import useLocalStorage from '../popups/components/useLocalStorage';

export default function PrepaidActivationPromotionBanner(): ReactElement | null {
  const { data, error, isLoading } = useActivationRetentionPromoCopyQuery();
  const [dismissed, setDismissed] = useLocalStorage('prepaid-activation-promotion-banner', false);
  const { hideDismissableBanners } = useThemeConfiguration();
  const { darkClassName } = useThemeMode();

  if (dismissed || isLoading || error || hideDismissableBanners) {
    return null;
  }

  const { tagline, body } = data?.data as ActivationRetentionPromoCopyData;

  return (
    <>
      <div className={darkClassName}>
        <div className="bg-soft-primary relative flex w-full items-center justify-between overflow-hidden bg-no-repeat">
          <div className="flex flex-row items-center justify-between gap-x-2 px-[32px] py-[22px] text-base md:w-full">
            <div className="space-y-2">
              <div className="text-default-secondary">
                <b className="text-default-primary font-bold">Special Offer: </b>
                {tagline}
              </div>
              <div className="text-default-secondary">{body}</div>
            </div>
            <IconButton color="primary" onClick={() => setDismissed(true)}>
              <FontAwesomeIcon icon={faXmark} className="text-default-primary" />
            </IconButton>
          </div>
        </div>
      </div>
    </>
  );
}
