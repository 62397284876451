import { EndpointResponse, Entity } from '@app/@types/api.types';
import { UserData } from '@app/@types/users.types';
import PhoneInput from '@app/components/Inputs/PhoneInput';
import { phoneValidationSchema } from '@app/utils/validation/yup-validation';
import Modal, {
  ModalFooter,
  ModalBodyContent,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { getPhoneWithoutDialingCode } from '@atob-developers/shared/src/utils/formatters/phoneFormat';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { Formik } from 'formik';
import { ReactElement } from 'react';

interface AddUserPhoneModalProps {
  open: boolean;
  toggle: () => void;
  user: UserData;
}

const AddUserPhoneModal = ({ open, toggle, user }: AddUserPhoneModalProps): ReactElement => {
  const { addToast } = useToasts();

  const submitAddUserPhoneRequest = async (values: Record<string, string | null>) => {
    const params = {
      user: {
        ...user,
        ...{ phone: getPhoneWithoutDialingCode(values.phone) },
      },
    };
    return axios
      .put<EndpointResponse<Entity<UserData>>>(`/users/${user.id}`, params)
      .then(() => window.location.reload())
      .catch((error) => {
        Sentry.captureException(error, {
          user: {
            request_id: error?.response?.headers?.['x-request-id'],
            user_id: user.id,
          },
        });
        addToast({
          type: 'error',
          title: "There was an error saving this user's details. Please try again later",
        });
      });
  };

  return (
    <Modal open={open} toggle={toggle}>
      <Formik
        initialValues={{ phone: null }}
        validationSchema={phoneValidationSchema}
        onSubmit={submitAddUserPhoneRequest}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <ModalHeader title="Add Your Phone Number" onClose={toggle} />
            <ModalBodyContent overflowVisible={true}>
              <div>
                After enabling, you'll receive an SMS verification link on your phone. Please click
                on it to verify or you will not be able to receive SMS notifications.
              </div>
              <PhoneInput
                fullWidth
                label="Phone Number"
                name="phone"
                value={values.phone}
                onChange={handleChange}
                onBlur={handleBlur}
                className="mt-3"
                error={touched.phone && !!errors.phone}
                helperText={touched.phone && errors.phone}
              />
            </ModalBodyContent>
            <ModalFooter>
              <Button color="secondary" size="small" onClick={toggle}>
                Cancel
              </Button>
              <LoadingButton size="small" type="submit" loading={isSubmitting}>
                Save
              </LoadingButton>
            </ModalFooter>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddUserPhoneModal;
