import { EndpointResponse } from '@app/@types/api.types';
import { Loading, ErrorNotification } from '@app/components/layout';
import useCustomer from '@app/hooks/useCustomer';
import { getFetcher } from '@app/utils/data/fetchers';
import {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import axios from 'axios';
import { ReactElement, useEffect, useState } from 'react';
import useSWR from 'swr';
import {
  CardOrderConstraints,
  CardOrderFeeDetails,
  DeliveryMethodResponse,
} from './CardOrderModal';
import type { CardRequestParams } from '@app/@types/card_requests.types';

export const CardOrderShipping = ({
  params,
  setParams,
  orderConstraints,
  onNext,
  onBack,
  closeModal,
}: {
  params: CardRequestParams;
  setParams: (_: CardRequestParams) => void;
  orderConstraints: CardOrderConstraints;
  onNext: () => void;
  onBack: () => void;
  closeModal: () => void;
}): ReactElement => {
  const [loading, setLoading] = useState<boolean>(false);
  const customer = useCustomer();
  const [feeDetails, setFeeDetails] = useState<CardOrderFeeDetails>();

  const { data: deliveryMethodData, error: deliveryMethodError } = useSWR<
    EndpointResponse<DeliveryMethodResponse[]>
  >({ url: '/delivery_methods' }, getFetcher);

  useEffect(() => {
    const getFeeDetails = async () => {
      if (orderConstraints.fee_waived) {
        return;
      }

      const response = await axios.post('/card_requests/fee_details', {
        number_of_cards: params.number_of_cards,
        delivery_method: customer.bluemark_enabled ? 'dhl_max' : 'standard',
      });

      setFeeDetails(response.data);
    };

    setLoading(true);

    Promise.all([getFeeDetails()]).then(() => setLoading(false));
  }, [orderConstraints.fee_waived, params.number_of_cards, customer.bluemark_enabled]);

  if (deliveryMethodError) {
    return (
      <ErrorNotification error="We're having issues loading delivery methods. Please try again or if the issue persists, contact support." />
    );
  }

  return (
    <>
      {loading && <Loading />}
      <ModalHeader title="Shipping Speed" onClose={closeModal} />
      <ModalBodyContent overflowVisible={true}>
        <div className="mb-16 mt-8">
          <FormControl>
            <FormLabel>
              <p className="text-default-primary font-semibold">Choose a Method</p>
            </FormLabel>
            <RadioGroup
              aria-label="delivery_method"
              name="delivery_method"
              value={params.delivery_method_id}
              onChange={(deliveryMethod) =>
                setParams({
                  ...params,
                  delivery_method_id: deliveryMethod.target.value,
                })
              }
              className="flex flex-col gap-3"
            >
              {deliveryMethodData?.data?.map((deliveryMethod) => {
                return (
                  <FormControlLabel
                    key={deliveryMethod.id}
                    value={deliveryMethod.id}
                    control={<Radio />}
                    label={
                      `${deliveryMethod.attributes.user_facing_name} (${deliveryMethod.attributes.description})` +
                      (!orderConstraints.fee_waived && feeDetails
                        ? ' - $' +
                          feeDetails['shipping_fee_options'][deliveryMethod.attributes.method]
                            .cents /
                            100
                        : '')
                    }
                  />
                );
              })}
            </RadioGroup>
          </FormControl>
        </div>
      </ModalBodyContent>
      <ModalFooter>
        <Button className="flex-1" size="small" color="secondary" onClick={onBack}>
          Back
        </Button>
        <Button className="flex-1" size="small" onClick={onNext}>
          Continue
        </Button>
      </ModalFooter>
    </>
  );
};
