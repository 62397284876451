import * as yup from 'yup';

export const driverValidationSchema = yup.object().shape({
  first_name: yup.string().required('First name is required'),
  email: yup.string().email('Invalid email format'),
  phone: yup
    .string()
    .transform((value: string) => value.replace(/[^\d]/g, '').replace(/^1/, ''))
    .test('valid-number', 'Invalid phone number format', (value) => {
      return !value || value.length === 10;
    }),
});
