import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, FormControl, IconButton, ListSubheader, Select } from '@mui/material';
import { SingleInputDateRangeField, StaticDateRangePicker } from '@mui/x-date-pickers-pro';
import dayjs, { Dayjs } from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import utc from 'dayjs/plugin/utc';
import { ReactElement, useState } from 'react';

dayjs.extend(isoWeek);
dayjs.extend(utc);

export const FilterDropdownDate = ({
  fromDate,
  toDate,
  onDateRangeChange,
}: {
  fromDate: string | null;
  toDate: string | null;
  onDateRangeChange: (fromDate: string | null, toDate: string | null) => void;
}): ReactElement => {
  const savedFromDate = fromDate ? dayjs.unix(parseInt(fromDate)) : null;
  const savedToDate = toDate ? dayjs.unix(parseInt(toDate)) : null;

  const isBlank = !savedFromDate && !savedToDate;
  const [touched, setTouched] = useState(false);

  const onTouched = (latestFromDate: Dayjs | null, latestToDate: Dayjs | null) => {
    onDateRangeChange(
      latestFromDate ? dayjs(latestFromDate).unix().toString() : null,
      latestToDate ? dayjs(latestToDate).endOf('day').unix().toString() : null,
    );
    setTouched(true);
  };

  return (
    <FormControl className="min-w-min flex-1">
      <Select
        size="small"
        renderValue={() => {
          if (savedFromDate && savedToDate) {
            return (
              <>
                {savedFromDate.format('MM/D/YY')} - {savedToDate.format('MM/D/YY')}
              </>
            );
          } else if (savedFromDate) {
            return savedFromDate.format('MM/D/YY') + ' - ';
          }
          return 'Date';
        }}
        displayEmpty
      >
        <ListSubheader className="bg-soft-primary">
          <StaticDateRangePicker
            slots={{
              toolbar: (props) => (
                <SingleInputDateRangeField
                  {...props}
                  size="small"
                  value={[savedFromDate, savedToDate]}
                  onChange={([newFromDate, newToDate]) => {
                    const isValidFrom =
                      newFromDate?.isValid() && newFromDate.year().toString().length === 4;
                    // Checking year length since newFromDate.isValid() returns true in case year is written as '0202' because it is automatically formatted to '202'
                    const isValidTo =
                      newToDate?.isValid() && newToDate.year().toString().length === 4;

                    if (isValidFrom && isValidTo) {
                      return onTouched(newFromDate, newToDate);
                    }
                  }}
                />
              ),
              previousIconButton: (props) => (
                <IconButton {...props} size="small" color="tertiary">
                  <FontAwesomeIcon icon={faChevronLeft} className="h-4 w-4" />
                </IconButton>
              ),
              nextIconButton: (props) => (
                <IconButton {...props} size="small" color="tertiary">
                  <FontAwesomeIcon icon={faChevronRight} className="h-4 w-4" />
                </IconButton>
              ),
            }}
            slotProps={{
              shortcuts: {},
              actionBar: { actions: [] },
            }}
            value={[savedFromDate, savedToDate]}
            onChange={([newFromData, newToDate]) => {
              onTouched(newFromData, newToDate);
            }}
          />

          {(!isBlank || touched) && (
            <div className="flex justify-between rounded p-3">
              <div>
                {!isBlank && (
                  <Button
                    color="secondary"
                    onClick={() => {
                      onTouched(null, null);
                    }}
                    className="mx-none hover:bg-strong-secondary min-w-[0px] border-transparent bg-transparent px-2 underline"
                  >
                    Clear all
                  </Button>
                )}
              </div>
            </div>
          )}
        </ListSubheader>
      </Select>
    </FormControl>
  );
};
