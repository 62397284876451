import { ReactElement, useState } from 'react';
import ReactivateCesOrUnlimitedOfferModal from '../Modals/CardModals/ReactivateCesOrUnlimitedOfferModal';
import { ErrorBanner } from './Designs/ErrorBanner';

const ReactivateCesOrUnlimitedBanner = ({
  onSuccessCallback,
}: {
  onSuccessCallback: () => void;
}): ReactElement => {
  const [openModal, toggleModal] = useState(false);

  return (
    <ErrorBanner
      onClick={() => toggleModal(true)}
      actionText="Reactivate now"
      data-testid="reactivate-ces-unlimited-banner"
    >
      <span className="text-contrast-primary font-semibold">Action Required for Reactivation:</span>{' '}
      View this offer to reactivate your card account.
      {openModal && (
        <ReactivateCesOrUnlimitedOfferModal
          open={openModal}
          onClose={() => toggleModal(false)}
          onSuccessCallback={onSuccessCallback}
        />
      )}
    </ErrorBanner>
  );
};

export default ReactivateCesOrUnlimitedBanner;
