import cx from 'classnames';
import { ReactElement, HTMLAttributes, ReactNode } from 'react';

export const PageContentWrapper = ({
  children,
  className,
  baseClassName,
  header,
  pageTabs,
}: HTMLAttributes<HTMLDivElement> & {
  children: ReactNode;
  header?: ReactNode;
  pageTabs?: ReactNode;
  baseClassName?: string;
}): ReactElement => {
  const baseClass =
    'flex flex-col gap-6 md:gap-8 pb-10 pt-0 md:p-10 md:pt-8 grow bg-soft-secondary min-h-screen';

  // Since this is only wrapper for page content (not header), and max-height of the container is 100vh,
  // set flex-grow:1 to this component so it always take remaining space of the screen.
  // If some parts are not visible, they are already handled with scroll on previous wrapper.
  return (
    <div className={cx(baseClass, baseClassName)}>
      {header}
      {pageTabs && <div className="px-4 md:px-0">{pageTabs}</div>}
      <div
        className={cx(
          'flex flex-col gap-6 p-4 md:gap-8 md:p-0',
          { 'pt-0 md:pt-0 lg:pt-0': header != null },
          className,
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default PageContentWrapper;
