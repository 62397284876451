import { getFetcher } from '@app/utils/data/fetchers';
import { MobileFriendlyTooltip } from '@atob-developers/shared/src/components/MobileFriendlyTooltip';
import { currencyFormat } from '@atob-developers/shared/src/utils/formatters';
import { convertCentsToDollars } from '@atob-developers/shared/src/utils/formatters/currencyFormat';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip, Skeleton } from '@mui/material';
import { ReactElement } from 'react';
import useSWRImmutable from 'swr/immutable';

type SecurityDepositSummary = {
  paid_amount_cents: number;
};

export const SecurityDepositDetails = (): ReactElement | null => {
  const { data: depositSummary, isLoading: loading } = useSWRImmutable<SecurityDepositSummary>(
    { url: `/security_deposits/summary` },
    getFetcher,
  );

  if (!loading && depositSummary?.paid_amount_cents === 0) {
    return null;
  }

  return (
    <div className="flex items-center">
      {loading ? (
        <Skeleton className="h-8 w-40" />
      ) : (
        <>
          <h2 className="text-default-secondary font-regular mr-3 text-sm">Security deposit:</h2>
          <Chip
            color="grey"
            label={
              <>
                <MobileFriendlyTooltip
                  className="pr-2"
                  title="You can contact support to refund your security deposit."
                >
                  <FontAwesomeIcon icon={faCircleInfo} />
                </MobileFriendlyTooltip>
                {depositSummary?.paid_amount_cents
                  ? currencyFormat({
                      value: convertCentsToDollars({ value: depositSummary.paid_amount_cents }),
                      onlyWholeNumber: true,
                    })
                  : '-'}
              </>
            }
          />
        </>
      )}
    </div>
  );
};
