import { ReactElement, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ReportUpload from './ReportUpload';
import ReviewMilesData from './ReviewMilesData';
import UploadIftaReportFromTelematics from './UploadIftaReportFromTelematics';

// Define the interface for the parsed data
interface ParsedData {
  id: number;
  vin: string;
  jurisdiction: string;
  enter_time: string;
  distance: number;
  year: string;
  month: string;
  fuel_type: string;
}

export default function IftaReportFromAtoBCard(): ReactElement {
  const [parsedData, setParsedData] = useState<ParsedData[]>([]);
  const [file, setFile] = useState<File | null>(null);
  const [isCSVUploaded, setIsCSVUploaded] = useState(false);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const isUpload = searchParams.get('isUpload');

  const handleFileUpload = (file: File | null, results: Array<Array<string>>) => {
    if (!file) return;
    setFile(file);
    if (results.length < 2) return;

    const filtered_data = results.slice(1);

    const data = filtered_data
      ?.map((values, index) => {
        return {
          id: index + 1,
          vin: values[0],
          jurisdiction: values[1],
          enter_time: values[2],
          distance: Number(String(values[3])?.replace(/,/g, '')) || 0,
          year: values[4],
          month: values[5],
          fuel_type: values[6],
        };
      })
      .filter(Boolean) as ParsedData[];
    setParsedData(data);
    setIsCSVUploaded(true);
    navigate('/ifta-filing?stage=3');
  };
  return (
    <>
      {isUpload ? (
        <UploadIftaReportFromTelematics handleFileUpload={handleFileUpload} />
      ) : isCSVUploaded ? (
        <ReviewMilesData
          isLoading={false}
          data={parsedData}
          file={file}
          setIsCSVUploaded={setIsCSVUploaded}
        />
      ) : (
        <ReportUpload />
      )}
    </>
  );
}
