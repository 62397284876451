import { ChallengeMatchLevel } from '@app/@types/challenge_match_card.types';
import LockAnimation from '@app/assets/animations/atob-lock.json';
import AnimatedCounter from '@app/components/AnimatedComponents/AnimatedCounter';
import ShimmerEffect from '@app/components/AnimatedComponents/ShimmerEffect';
import SpinnerBoundary from '@app/components/Spinner/SpinnerBoundary';
import {
  rectGenerator,
  extractNumberFromCurrency,
  formatCurrencyToNearestDollarFloor,
  formatCurrencyToNearestDollarCeil,
} from '@app/utils/challenge-match-card';
import logger from '@app/utils/datadog-logger';
import { MobileFriendlyTooltip } from '@atob-developers/shared/src/components/MobileFriendlyTooltip';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { faLock } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Chip,
  ClickAwayListener,
  Fade,
  Paper,
  Popper,
  PopperProps,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import classNames from 'classnames';
import { cubicBezier, motion, useInView } from 'framer-motion';
import lottie from 'lottie-web';
import { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import { useChallengeMatch } from './useChallengeMatch';

export default function ChallengeMatchTracker({
  variant,
}: {
  variant?: 'small' | 'large';
}): ReactElement {
  const { challengeMatch, isLoading, nextLevel, isTAOnly } = useChallengeMatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const trackerVariant = variant ? variant : isMobile ? 'small' : 'large';

  const max =
    (nextLevel
      ? nextLevel.threshold_amount.cents
      : challengeMatch?.levels[challengeMatch?.levels.length - 1]?.threshold_amount.cents) ?? 0;

  return (
    <>
      {isLoading || challengeMatch == null ? (
        <SpinnerBoundary />
      ) : (
        <div className="flex w-full flex-col justify-center gap-2 p-4 md:gap-4">
          <SpendTracker
            spent={nextLevel ? challengeMatch?.this_week.settled_amt.cents ?? 0 : max}
            pending={nextLevel ? challengeMatch?.this_week.pending_amt.cents ?? 0 : 0}
            max={max}
            variant={trackerVariant}
          >
            <div className="flex h-full flex-1 self-stretch">
              <div className="inline-flex flex-1 flex-col items-center justify-center gap-3">
                <div className="flex flex-col items-center gap-0.5">
                  <span
                    className={classNames(
                      'text-default-primary text-center font-semibold',
                      trackerVariant === 'small'
                        ? 'text-2xl tracking-[-0.6px]'
                        : 'text-3xl tracking-[-1.2px]',
                    )}
                  >
                    <span>$</span>
                    <AnimatedCounter
                      from={0}
                      to={extractNumberFromCurrency(
                        formatCurrencyToNearestDollarFloor(
                          challengeMatch?.this_week.settled_amt.cents ?? 0,
                        ),
                      )}
                    />
                  </span>
                  {isTAOnly ? (
                    <span
                      className={classNames(
                        'text-default-secondary text-center font-medium',
                        trackerVariant === 'small' ? 'text-[10px]' : 'text-base',
                      )}
                    >
                      Spend this week at TA Petro
                    </span>
                  ) : challengeMatch.partners.length > 1 ? (
                    <span
                      className={classNames(
                        'text-default-secondary text-center font-medium',
                        trackerVariant === 'small' ? 'text-[10px]' : 'text-base',
                      )}
                    >
                      Spend this week at TA Petro, RaceTrac, Speedway
                    </span>
                  ) : (
                    <span
                      className={classNames(
                        'text-default-secondary text-center font-medium',
                        trackerVariant === 'small' ? 'text-xs' : 'text-base',
                      )}
                    >
                      Spend this week
                    </span>
                  )}
                </div>
                {nextLevel ? (
                  <div className="relative overflow-hidden rounded">
                    <Chip
                      label={`${formatCurrencyToNearestDollarCeil(
                        max - (challengeMatch?.this_week.settled_amt.cents ?? 0),
                      )} to next boost`}
                      size="small"
                      color="grey"
                      className={classNames(trackerVariant === 'small' ? 'text-xs' : 'text-base')}
                    />
                    <ShimmerEffect />
                  </div>
                ) : (
                  <Chip label="Completed" size="small" color="green" />
                )}
              </div>
            </div>
          </SpendTracker>
          <div className="flex items-start justify-center gap-2 self-center">
            {challengeMatch?.levels.map((level) => (
              <LevelChip
                key={level.level}
                settledSpend={challengeMatch.this_week.settled_amt.cents ?? 0}
                level={level}
                isFirstNotUnlocked={nextLevel?.level === level.level}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
}

function LevelChip({
  settledSpend,
  isFirstNotUnlocked,
  level,
}: {
  settledSpend: number;
  isFirstNotUnlocked: boolean;
  level: ChallengeMatchLevel;
}): ReactElement {
  const amountNeeded = level.threshold_amount.cents;
  const hasReached = settledSpend >= amountNeeded;

  return (
    <MobileFriendlyTooltip
      title={`Activated at ${formatCurrencyToNearestDollarCeil(amountNeeded)}`}
    >
      <motion.div
        className={classNames(
          'bg-strong-secondary flex items-center gap-1.5 rounded-full py-1 pl-1 pr-[7px]',
          isFirstNotUnlocked && 'relative overflow-hidden',
        )}
        animate={
          hasReached && {
            scale: [1, 1.1, 1.05, 1],
            backgroundColor: ['#F0F1F4', '#FFFFFF', '#FFFFFF', '#FFFFFF'],
            border: [
              '1px solid #F0F1F4',
              '1px solid #E2E4E9',
              '1px solid #E2E4E9',
              '1px solid #E2E4E9',
            ],
            transition: {
              delay: 1 + level.level * 0.25,
            },
          }
        }
      >
        {hasReached ? (
          <FlipCircle lockId={level.level} />
        ) : (
          <div className="bg-strong-secondary flex h-6 w-6 items-center justify-center rounded-full">
            <FontAwesomeIcon icon={faLock} className="text-default-secondary h-3 w-3" />
          </div>
        )}
        <span className="text-default-secondary text-sm font-medium">{level.cents_back}¢</span>
        {isFirstNotUnlocked && <ShimmerEffect />}
      </motion.div>
    </MobileFriendlyTooltip>
  );
}

const FlipCircle = ({ lockId }: { lockId: number }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  useEffect(() => {
    setTimeout(
      () => {
        setIsFlipped(true);
      },
      1000 + lockId * 250,
    );
  }, [lockId]);

  useEffect(() => {
    const containerClassName = `lock-${lockId}`;
    const container = document.getElementsByClassName(containerClassName);

    if (container) {
      const animation = lottie.loadAnimation({
        container: container[container.length - 1],
        renderer: 'svg',
        loop: false,
        autoplay: false,
        animationData: LockAnimation,
      });

      const timeout = setTimeout(
        () => {
          animation.play();
        },
        250 + lockId * 250,
      );

      return () => {
        clearTimeout(timeout);
        animation.destroy();
      };
    }
  }, [lockId]);

  const variantsFront = {
    visible: { rotateY: 0, opacity: 1, transition: { duration: 0.5 } },
    invisible: { rotateY: 180, opacity: 0, transition: { duration: 0.5 } },
  };

  const variantsBack = {
    visible: { rotateY: 0, opacity: 1, transition: { duration: 0.5 } },
    invisible: { rotateY: 180, opacity: 0, transition: { duration: 0.5 } },
  };

  return (
    <div className="relative">
      <motion.div
        animate={isFlipped ? 'visible' : 'invisible'}
        variants={variantsBack}
        className="bg-green-primary absolute flex h-6 w-6 items-center justify-center rounded-full"
      >
        <FontAwesomeIcon icon={faCheck} className="text-contrast-primary h-3 w-3" />
      </motion.div>
      <motion.div
        animate={isFlipped ? 'invisible' : 'visible'}
        variants={variantsFront}
        className="bg-strong-secondary flex h-6 w-6 items-center justify-center rounded-full"
      >
        <div className={`lock-${lockId} fill-default-secondary stroke-default-secondary`} />
      </motion.div>
    </div>
  );
};

const SpendTracker = ({
  spent,
  pending,
  max,
  variant = 'small',
  children,
}: {
  spent: number;
  pending: number;
  max: number;
  variant?: 'small' | 'large';
  children: ReactNode;
}) => {
  const [popoverContent, setPopoverContent] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<PopperProps['anchorEl']>(null);
  const ref = useRef<HTMLDivElement>(null);
  const inView = useInView(ref, { once: true });

  const spentPercentage = (spent / max) * 100;
  const pendingPercentage = Math.min((pending / max) * 100, 100 - spentPercentage);

  const radius = variant === 'small' ? 96 : 171;
  const strokeWidth = variant === 'small' ? 16 : 20;
  const center = variant === 'small' ? 104 : 181;

  // Using 270 degrees of the circle
  const maxAngle = 270;
  const startAngle = 225;

  const spentAngle = (spentPercentage / 100) * maxAngle;
  const pendingAngle = (pendingPercentage / 100) * maxAngle;

  const describeArc = (startAngle: number, endAngle: number) => {
    const start = polarToCartesian(center, center, radius, endAngle);
    const end = polarToCartesian(center, center, radius, startAngle);
    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

    return ['M', start.x, start.y, 'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(' ');
  };

  const polarToCartesian = (
    centerX: number,
    centerY: number,
    radius: number,
    angleInDegrees: number,
  ) => {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    };
  };

  const id = popoverContent ? 'virtual-element-popper' : undefined;

  return (
    <div ref={ref} className="flex w-full flex-col items-center">
      <ClickAwayListener onClickAway={() => setOpen(false)}>
        <svg
          className={classNames('w-full', variant === 'small' ? 'max-w-[208px]' : 'max-w-[362px]')}
          viewBox={variant === 'small' ? '0 0 208 185' : '0 0 362 323'}
        >
          <defs>
            <linearGradient id="beam" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" style={{ stopColor: 'rgba(255, 255, 255, 0)', stopOpacity: 1 }} />
              <stop
                offset="50%"
                style={{ stopColor: 'rgba(255, 255, 255, 0.75)', stopOpacity: 1 }}
              />
              <stop offset="100%" style={{ stopColor: 'rgba(255, 255, 255, 0)', stopOpacity: 1 }} />
            </linearGradient>
            <mask id={`pendingArcMask-${variant}`}>
              <rect
                x="0"
                y="0"
                width={variant === 'small' ? '208' : '362'}
                height={variant === 'small' ? '185' : '323'}
                fill="black"
              />
              <path
                d={describeArc(startAngle + spentAngle, startAngle + spentAngle + pendingAngle)}
                stroke="white"
                fill="none"
                strokeWidth={strokeWidth}
                strokeLinecap="butt"
              />
            </mask>
            <mask id={`remainingArcMask-${variant}`}>
              <rect
                x="0"
                y="0"
                width={variant === 'small' ? '208' : '362'}
                height={variant === 'small' ? '185' : '323'}
                fill="black"
              />
              <path
                d={describeArc(startAngle, startAngle + maxAngle)}
                stroke="white"
                strokeWidth={strokeWidth}
                strokeLinecap="butt"
              />
              <path
                d={describeArc(startAngle, startAngle + spentAngle)}
                stroke="black"
                strokeWidth={strokeWidth}
                strokeLinecap="butt"
              />
              <path
                d={describeArc(startAngle + spentAngle, startAngle + spentAngle + pendingAngle)}
                stroke="black"
                strokeWidth={strokeWidth}
                strokeLinecap="butt"
              />
            </mask>
          </defs>
          {/* Background Arc */}
          <path
            d={describeArc(startAngle, startAngle + maxAngle)}
            className="stroke-green-secondary"
            fill="none"
            strokeWidth={strokeWidth}
            strokeLinecap="butt"
          />
          {/* Pending Arc */}
          <motion.path
            initial={{ pathLength: 0, pathOffset: 1 }}
            animate={inView ? { pathLength: 1, pathOffset: 0 } : {}}
            transition={{ delay: 2, duration: 0.5, ease: cubicBezier(0.3, 0.2, 0, 1) }}
            d={describeArc(startAngle + spentAngle, startAngle + spentAngle + pendingAngle)}
            className="stroke-green-secondary"
            fill="none"
            strokeWidth={strokeWidth}
            strokeLinecap="butt"
            onTouchStart={(e) => {
              setAnchorEl(rectGenerator(e.touches[0].clientX, e.touches[0].clientY));
              setPopoverContent(`Pending: ${formatCurrencyToNearestDollarFloor(pending)}`);
              setOpen(true);
              logger.info('challenge-match-card-pending-arc-tooltip', {
                from: 'tooltip',
              });
            }}
            onMouseEnter={(e) => {
              setAnchorEl(rectGenerator(e.clientX, e.clientY));
              setPopoverContent(`Pending: ${formatCurrencyToNearestDollarFloor(pending)}`);
              setOpen(true);
              logger.info('challenge-match-card-pending-arc-tooltip', {
                from: 'tooltip',
              });
            }}
            onMouseMove={(e) => {
              setAnchorEl(rectGenerator(e.clientX, e.clientY));
              setPopoverContent(`Pending: ${formatCurrencyToNearestDollarFloor(pending)}`);
              setOpen(true);
            }}
            onMouseLeave={() => {
              setAnchorEl(null);
              setOpen(false);
            }}
          />
          {/* Spent Arc */}
          <motion.path
            initial={{ pathLength: 0, pathOffset: 1 }}
            animate={inView ? { pathLength: 1, pathOffset: 0 } : {}}
            transition={{ duration: 2, ease: cubicBezier(0.3, 0.2, 0, 1) }}
            d={describeArc(startAngle, startAngle + spentAngle)}
            className="stroke-green-primary"
            fill="none"
            strokeWidth={strokeWidth}
            strokeLinecap="butt"
            onTouchStart={(e) => {
              setAnchorEl(rectGenerator(e.touches[0].clientX, e.touches[0].clientY));
              setPopoverContent(`Spent: ${formatCurrencyToNearestDollarFloor(spent)}`);
              setOpen(true);
              logger.info('challenge-match-card-spent-arc-tooltip', {
                from: 'tooltip',
              });
            }}
            onMouseEnter={(e) => {
              setAnchorEl(rectGenerator(e.clientX, e.clientY));
              setPopoverContent(`Spent: ${formatCurrencyToNearestDollarFloor(spent)}`);
              setOpen(true);
              logger.info('challenge-match-card-spent-arc-tooltip', {
                from: 'tooltip',
              });
            }}
            onMouseMove={(e) => {
              setAnchorEl(rectGenerator(e.clientX, e.clientY));
              setPopoverContent(`Spent: ${formatCurrencyToNearestDollarFloor(spent)}`);
              setOpen(true);
            }}
            onMouseLeave={() => {
              setOpen(false);
              setAnchorEl(null);
            }}
          />
          <g mask={`url(#pendingArcMask-${variant})`}>
            <motion.rect
              width={variant === 'small' ? 32 : 40}
              height={variant === 'small' ? 32 : 40}
              className={classNames(
                variant === 'small'
                  ? '-translate-x-4 -translate-y-4'
                  : '-translate-x-5 -translate-y-5',
              )}
              fill="url(#beam)"
              style={{
                offsetPath: `path("${describeArc(
                  -10 + startAngle + spentAngle,
                  startAngle + spentAngle + pendingAngle + 10,
                )}")`,
                offsetDistance: 'var(--challenge-card-beam-offset)',
              }}
              transition={{
                delay: 2,
                repeatType: 'loop',
                repeat: 2,
                repeatDelay: 1.25,
                duration: 0.75,
                ease: 'easeInOut',
              }}
              initial={{ '--challenge-card-beam-offset': '100%' }}
              animate={{ '--challenge-card-beam-offset': '0%' }}
            />
          </g>
          <g mask={`url(#remainingArcMask-${variant})`}>
            <motion.rect
              width={variant === 'small' ? 32 : 40}
              height={variant === 'small' ? 32 : 40}
              className={classNames(
                variant === 'small'
                  ? '-translate-x-4 -translate-y-4'
                  : '-translate-x-5 -translate-y-5',
              )}
              fill="url(#beam)"
              style={{
                offsetPath: `path("${describeArc(
                  -10 + startAngle + spentAngle + pendingAngle,
                  startAngle + maxAngle + 10,
                )}")`,
                offsetDistance: 'var(--challenge-card-beam-offset)',
              }}
              transition={{
                delay: 2,
                repeatType: 'loop',
                repeat: 2,
                repeatDelay: 1.25,
                duration: 0.75,
                ease: 'easeInOut',
              }}
              initial={{ '--challenge-card-beam-offset': '100%' }}
              animate={{ '--challenge-card-beam-offset': '0%' }}
            />
          </g>
          <foreignObject
            x={variant === 'small' ? '32' : '70'}
            y={variant === 'small' ? '42' : '100'}
            width={variant === 'small' ? '143' : '218'}
            height={variant === 'small' ? '102' : '150'}
          >
            {children}
          </foreignObject>
          {/* Text at the beginning of the arc */}
          <text
            x={variant === 'small' ? 42 : 64}
            y={variant === 'small' ? 175 : 314}
            dominantBaseline="middle"
            textAnchor="start"
            className={classNames(
              'fill-strong-primary font-sans font-semibold',
              variant === 'small' ? 'text-xs' : 'text-base',
            )}
          >
            $0
          </text>
          {/* Text at the end of the arc */}
          <text
            x={variant === 'small' ? 164 : 294}
            y={variant === 'small' ? 175 : 314}
            dominantBaseline="middle"
            textAnchor="end"
            className={classNames(
              'fill-strong-primary font-sans font-semibold',
              variant === 'small' ? 'text-xs' : 'text-base',
            )}
          >
            {formatCurrencyToNearestDollarCeil(max)}
          </text>
        </svg>
      </ClickAwayListener>
      <Popper id={id} open={open} anchorEl={anchorEl} transition placement="bottom-start">
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <Paper
              classes={{
                root: 'bg-strong-primary shadow-tooltip rounded text-sm font-medium text-contrast-primary p-2.5',
              }}
            >
              {popoverContent}
            </Paper>
          </Fade>
        )}
      </Popper>
    </div>
  );
};
