import { ComponentType } from 'react';
import OnboardingConnectBankAccount from './Steps/OnboardingConnectBankAccount';
import OnboardingInviteDrivers from './Steps/OnboardingInviteDrivers';
import OnboardingMaximizeDiscounts from './Steps/OnboardingMaximizeDiscounts';
import OnboardingPaySetUpFee from './Steps/OnboardingPaySetUpFee';
import OnboardingSecurityDeposit from './Steps/OnboardingSecurityDeposit';
import OnboardingSyncTelematics from './Steps/OnboardingSyncTelematics';
import OnboardingWalletLite from './Steps/OnboardingWalletLite';
import OnboardingWelcomeToAtoB from './Steps/OnboardingWelcomeToAtoB';
import ConnectBankAccountVisual from './Steps/Visuals/ConnectBankAccountVisual';
import InviteDriversVisual from './Steps/Visuals/InviteDriversVisual';
import MaximizeDiscountsVisual from './Steps/Visuals/MaximizeDiscountsVisual';
import PaySecurityDepositVisual from './Steps/Visuals/PaySecurityDepositVisual';
import PaySetUpFeeVisual from './Steps/Visuals/PaySetUpFeeVisual';
import SyncTelematicsVisual from './Steps/Visuals/SyncTelematicsVisual';
import WalletLiteVisual from './Steps/Visuals/WalletLiteVisual';
import WelcomeToAtobVisual from './Steps/Visuals/WelcomeToAtobVisual';

export type OnboardingStep = {
  leftContent: ComponentType;
  rightContent: ComponentType;
  blurb: string | null;
};

export const STEP_MAP: { [key: string]: OnboardingStep } = {
  welcome: {
    leftContent: WelcomeToAtobVisual,
    rightContent: OnboardingWelcomeToAtoB,
    blurb: null,
  },
  connect_bank_account: {
    leftContent: ConnectBankAccountVisual,
    rightContent: OnboardingConnectBankAccount,
    blurb: 'Connect a bank account',
  },
  security_deposit: {
    leftContent: PaySecurityDepositVisual,
    rightContent: OnboardingSecurityDeposit,
    blurb: 'Activate your credit line',
  },
  pay_setup_fee: {
    leftContent: PaySetUpFeeVisual,
    rightContent: OnboardingPaySetUpFee,
    blurb: 'Activate your account',
  },
  sync_telematics: {
    leftContent: SyncTelematicsVisual,
    rightContent: OnboardingSyncTelematics,
    blurb: 'Sync your telematics',
  },
  maximize_discounts: {
    leftContent: MaximizeDiscountsVisual,
    rightContent: OnboardingMaximizeDiscounts,
    blurb: 'Maximize discounts with AtoB app',
  },
  invite_drivers: {
    leftContent: InviteDriversVisual,
    rightContent: OnboardingInviteDrivers,
    blurb: null,
  },
  wallet_lite_download_app: {
    leftContent: WalletLiteVisual,
    rightContent: OnboardingWalletLite,
    blurb: null,
  },
} as const;
