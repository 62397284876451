import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import { IftaFilingStage } from '@app/constants/iftaFiling';
import { useSyncIftaSummary } from '@app/hooks/query/useIftaFilingQuery';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { Button } from '@mui/material';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { Loading } from '../layout';

const StartIftaFiling = (): ReactElement => {
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const { trigger: syncIftaSummary, isMutating } = useSyncIftaSummary();
  const onClickStartNow = async () => {
    try {
      await syncIftaSummary();
      navigate(`/ifta-filing?stage=${IftaFilingStage.INITIAL}`);
    } catch (error) {
      addToast({
        type: 'error',
        title: `Failed to sync telematics IFTA summary. Please try again`,
      });
    }
  };

  return (
    <RoundedCard className="relative flex-1 py-4">
      <div className="flex flex-col justify-between px-4 md:flex-row md:items-center ">
        <div className="px-4 text-sm font-semibold md:px-6">
          <p>Make your IFTA filing easier with AtoB </p>
        </div>
        <div className="h-fit w-full shrink-0 sm:w-fit">
          {isMutating ? (
            <Loading />
          ) : (
            <Button
              className="w-full sm:w-auto"
              size="small"
              onClick={onClickStartNow}
              data-testid={'start-ifta-filing-button'}
            >
              Start Now
            </Button>
          )}
        </div>
      </div>
    </RoundedCard>
  );
};

export default StartIftaFiling;
