import { PlaidResponse } from '@app/@types/plaid.types';
import getBodyForPlaidRequest from '@app/components/Plaid/PlaidUtils/PlaidUtils';
import { noArgPostFetcher } from '@app/utils/data/fetchers';
import { getEnvironment } from '@app/utils/environment';
import { usePlaidLink, PlaidLinkOnEvent, PlaidLinkOnSuccess } from 'react-plaid-link';
import useSWRImmutable from 'swr/immutable';

export default function usePlaid({
  updateBankAccountId,
  onSuccessCallback,
  onSuccess,
  onEvent,
}: {
  updateBankAccountId?: string;
  onEvent: PlaidLinkOnEvent;
  onSuccess: PlaidLinkOnSuccess;
  onSuccessCallback: () => void;
}) {
  const { VITE_PLAID_ENV } = getEnvironment();

  const body = getBodyForPlaidRequest({ updateBankAccountId });
  const key = { plaid: 'link', ...(body ?? {}) };
  const { data } = useSWRImmutable<PlaidResponse>(
    { url: '/plaid/create_link_token', params: body, key },
    noArgPostFetcher,
  );

  const { open: openPlaidModal, ready: isPlaidReady } = usePlaidLink({
    env: String(VITE_PLAID_ENV),
    onSuccess: async (token, meta) => {
      await onSuccess(token, meta);
      onSuccessCallback?.();
    },
    onEvent: onEvent,
    token: data?.link_token ?? null,
  });

  return { openPlaidModal, isPlaidReady };
}
