import { AUTO_TOP_UP_SIDEBAR_ENABLED } from '@app/components/PrepaidAccountOverview/PrepaidAccountOverviewCards';
import { faClockTwo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement } from 'react';
import { useAutoTopUpData } from './use-auto-topup-data';

export const AutoTopUpUpsell = (): ReactElement => {
  const { hasAutoTopUp, isLoading } = useAutoTopUpData();

  if (hasAutoTopUp || isLoading) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return null;
  }

  return (
    <>
      <div className="hidden pb-4 sm:flex">
        <div className="bg-strong-secondary flex flex-col items-start justify-between rounded-lg p-4 font-medium md:flex-row">
          <FontAwesomeIcon
            icon={faClockTwo}
            className="text-default-primary flex h-[30px] w-[30px] max-w-[30px] items-center p-4"
          />
          <div
            onClick={() => (window.location.href = `/?${AUTO_TOP_UP_SIDEBAR_ENABLED}=true`)}
            className="text-default-primary h-full w-full grow cursor-pointer pl-2 text-sm font-normal"
          >
            <b>Enable Auto-Deposit</b> to ensure drivers are never stuck at the pump due to
            insufficient funds in your Wallet.
          </div>
        </div>
      </div>
    </>
  );
};
