import useWindowWidth from '@app/hooks/useWindowWidth';
import { faCreditCard, faDollarCircle, IconDefinition } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProductSubscription } from './PlansOverview';

const getTitle = (type: string): string => {
  if (type === 'atob_plus_premium') {
    return 'Fuel Card';
  }
  if (type === 'payroll') {
    return 'Payroll';
  }

  return '';
};

const getPlan = (plan: ProductSubscription['plan']): string => {
  if (plan === 'basic') {
    return 'Basic Plan';
  }
  if (plan === 'monthly') {
    return 'Monthly Plan';
  }
  if (plan === 'premium') {
    return 'Premium Plan';
  }

  return '';
};

const Detail = ({ type, value }: { type: string; value: string | number }): ReactElement => (
  <div className="flex text-[16px] font-medium">
    <div className="text-default-primary mr-[2px]">{type}:</div>
    <div className="text-default-primary">{value}</div>
  </div>
);

const getIcon = (product: ProductSubscription): IconDefinition => {
  if (product.type === 'atob_plus_premium') {
    return faCreditCard;
  }

  if (product.type === 'payroll') {
    return faDollarCircle;
  }

  return faCreditCard;
};

export default function ProductCard({
  product,
  editable,
  children,
}: {
  product: ProductSubscription;
  editable: boolean;
  children: (
    Detail: ({ type, value }: { type: string; value: string | number }) => ReactElement,
  ) => ReactElement;
}): ReactElement {
  const { isMobile } = useWindowWidth();
  const navigate = useNavigate();

  if (isMobile) {
    return (
      <div className="bg-soft-primary border-soft flex rounded-lg border">
        <div className="flex w-full flex-col">
          <div className="border-soft flex w-full flex-col justify-between border-b">
            <div className="flex w-full flex-col p-4 pt-4">
              <div className="mb-4 flex flex-col">
                <div className="flex items-center">
                  <div className="bg-green-secondary mr-4 flex h-8 w-8 items-center justify-center rounded-full">
                    <div>
                      <FontAwesomeIcon icon={getIcon(product)} className="text-green scale-90" />
                    </div>
                  </div>
                  <h2 className="text-default-primary mb-1 text-xl font-bold">
                    {getTitle(product.type)}
                  </h2>
                </div>
                <b className="text-default-primary mt-3">{getPlan(product.plan)}</b>
                <div className="text-default-primary font-medium">{product.cost}</div>
              </div>
              {editable && (
                <Button onClick={() => navigate(`/settings/plans/${product.type}`)}>
                  Change Plan
                </Button>
              )}
            </div>
          </div>
          <div className="w-full px-6 py-4">{children(Detail)}</div>
        </div>
      </div>
    );
  }
  return (
    <div className="bg-soft-primary border-soft flex rounded-lg border px-8 py-6">
      <div className="flex w-full">
        <div className="bg-green-secondary mr-4 mt-2 flex h-16 w-[4.3rem] items-center justify-center rounded-full">
          <div>
            <FontAwesomeIcon icon={getIcon(product)} className="text-green scale-125" />
          </div>
        </div>
        <div className="flex w-full flex-col">
          <div className="border-soft flex w-full justify-between border-b">
            <div className="mb-4 flex flex-col">
              <h2 className="text-default-primary mb-1 text-xl font-bold">
                {getTitle(product.type)}
              </h2>
              <b className="text-default-primary">{getPlan(product.plan)}</b>
              <div className="text-default-primary">{product.cost}</div>
            </div>
            {editable && (
              <Button onClick={() => navigate(`/settings/plans/${product.type}`)}>
                Change Plan
              </Button>
            )}
          </div>
          <div className="mt-4 w-full">{children(Detail)}</div>
        </div>
      </div>
    </div>
  );
}
