import { SecurityDepositNetNewOfferDetails } from '@app/components/Banner/SecurityDepositNetNewBanner';
import Header from '@app/components/Onboarding/Header';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import { getFetcher } from '@app/utils/data/fetchers';
import { CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import useSWR from 'swr';
import PaySecurityDeposit from '../PaySecurityDeposit';
import SecurityDepositPending from '../SecurityDepositPending';
import { useOnboardingGuide } from '../useOnboardingGuide';

function SecurityDepositOptions({
  offerDetails,
  refetchOfferDetails,
}: {
  offerDetails: SecurityDepositNetNewOfferDetails;
  refetchOfferDetails: () => void;
}) {
  return (
    <div className="flex flex-1 flex-col gap-8">
      {offerDetails.existing_deposit?.status === 'deposit_pending' ||
      offerDetails.existing_deposit?.status === 'deposit_paid_pending_hold' ? (
        <SecurityDepositPending securityDeposit={offerDetails.existing_deposit} />
      ) : (
        <PaySecurityDeposit offerDetails={offerDetails} refetchOfferDetails={refetchOfferDetails} />
      )}
    </div>
  );
}

export default function OnboardingSecurityDeposit() {
  const { goToNextStep } = useOnboardingGuide();
  const [SECURITY_DEPOSIT_NET_NEW_OFFER_OPEN, SECURITY_DEPOSIT_NET_NEW_ENROLLED] = useFeatureFlags(
    'security_deposit_net_new_offer_open',
    'security_deposit_net_new_enrolled',
  );

  const isSecurityDepositCustomer =
    SECURITY_DEPOSIT_NET_NEW_OFFER_OPEN || SECURITY_DEPOSIT_NET_NEW_ENROLLED;

  const {
    data: offerDetails,
    isLoading: isFetchingOfferDetails,
    mutate: refetchOfferDetails,
  } = useSWR<SecurityDepositNetNewOfferDetails>(
    isSecurityDepositCustomer ? { url: `/security_deposits/net_new_offer_details` } : null,
    getFetcher,
  );

  useEffect(() => {
    if (
      [
        'deposit_paid',
        'fully_refunded',
        'partially_refunded',
        'closed',
        'deposit_pending',
        'deposit_paid_pending_hold',
      ].includes(offerDetails?.existing_deposit?.status || '')
    ) {
      goToNextStep();
    }
  }, [offerDetails?.existing_deposit?.status, goToNextStep]);

  if (isFetchingOfferDetails || !offerDetails) {
    return (
      <div className="flex h-full items-center justify-center">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div className="flex h-full flex-col gap-6 md:gap-8">
      <Header
        title="Activate your credit line"
        description="Make a refundable deposit adequate for the requested credit line. After 1 month of $2500 or more in spend and on-time payments, you’re eligible to request a full refund of your deposit."
      />
      <div className="flex h-full items-center justify-center">
        {isFetchingOfferDetails ? (
          <CircularProgress />
        ) : (
          <SecurityDepositOptions
            offerDetails={offerDetails}
            refetchOfferDetails={refetchOfferDetails}
          />
        )}
      </div>
    </div>
  );
}
