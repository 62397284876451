import EntryButton from '@app/components/EntryButton/EntryButton';
import SidebarHeader from '@app/components/Sidebars/SidebarHeader';
import useProduct from '@app/hooks/useProduct';
import RecipientAccounts from '@app/pages/Wallet/RecipientAccounts';
import { faMagnifyingGlass, faPlus, faUsers } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Chip, CircularProgress, Divider, InputAdornment, TextField } from '@mui/material';
import classNames from 'classnames';
import { groupBy } from 'lodash-es';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import RecipientModal from '../../../Recipients/RecipientModal';
import { useWalletTransfer } from './useWalletTransfer';

export default function RecipientSelector() {
  const {
    recipients,
    isFetchingRecipients,
    refetchRecipients,
    goNextStep,
    resetAndClose,
    setSelectedRecipientID,
  } = useWalletTransfer();
  const [showAddRecipientModal, setShowAddRecipientModal] = useState(false);
  const navigate = useNavigate();
  const [searchFilter, setSearchFilter] = useState('');
  const [manageWalletRecipients] = useProduct('manage_wallet_recipients');

  const groupedRecipients = useMemo(() => {
    const grouped = groupBy(recipients, (recipient) =>
      recipient.customer_owned ? 'internal' : 'external',
    );
    for (const item of ['internal', 'external']) {
      const searchBy = searchFilter.toLocaleLowerCase();
      grouped[item] = (grouped[item] ?? [])
        .filter(
          (recipient) =>
            recipient.name.toLocaleLowerCase().includes(searchBy) ||
            (recipient.nickname ?? '').toLocaleLowerCase().includes(searchBy),
        )
        .sort((a, b) => a.name.localeCompare(b.name));
    }
    return grouped;
  }, [recipients, searchFilter]);

  return (
    <div className="bg-soft-primary">
      <SidebarHeader title="Send money" onClose={resetAndClose} />
      <div className="mx-6 mb-8 h-full md:mx-8">
        <h4 className="text-default-primary mb-2 font-medium">Select a recipient to send money</h4>
        <div className="flex h-full flex-col pb-4">
          {isFetchingRecipients ? (
            <div className="flex flex-1 items-center justify-center">
              <CircularProgress />
            </div>
          ) : (
            <>
              <TextField
                onChange={(e) => setSearchFilter(e.target.value)}
                value={searchFilter}
                className="mb-6"
                placeholder="Search recipients"
                slotProps={{
                  input: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <FontAwesomeIcon
                          icon={faMagnifyingGlass}
                          className="text-default-primary"
                        ></FontAwesomeIcon>
                      </InputAdornment>
                    ),
                  },
                }}
              />

              <div className="flex flex-col gap-2">
                {groupedRecipients['internal'].map((recipient) => (
                  <EntryButton
                    className="last:mb-4"
                    key={recipient.id}
                    onClick={() => {
                      setSelectedRecipientID(recipient.id);
                      goNextStep();
                    }}
                    title={recipient.name}
                  >
                    <div className="flex flex-1 flex-row justify-between">
                      {recipient.customer_owned && <Chip label="My organization" color="grey" />}
                      <RecipientAccounts
                        instantPayoutMethods={recipient.instant_payout_methods ?? []}
                        treasuryRecipients={recipient.treasury_recipients ?? []}
                      />
                    </div>
                  </EntryButton>
                ))}
              </div>
              {groupedRecipients['external'].length > 0 &&
                groupedRecipients['internal'].length > 0 && <Divider className="" />}
              <div className="flex flex-col gap-2">
                {(groupedRecipients['external'] ?? []).map((recipient) => (
                  <EntryButton
                    className={classNames(
                      'last:mb-4',
                      groupedRecipients['internal'].length > 0 && 'first:mt-4',
                    )}
                    key={recipient.id}
                    onClick={() => {
                      setSelectedRecipientID(recipient.id);
                      goNextStep();
                    }}
                    title={recipient.name}
                  >
                    <div className="flex flex-1 flex-row justify-end">
                      <RecipientAccounts
                        instantPayoutMethods={recipient.instant_payout_methods ?? []}
                        treasuryRecipients={recipient.treasury_recipients ?? []}
                      />
                    </div>
                  </EntryButton>
                ))}
              </div>
            </>
          )}
          {!isFetchingRecipients && manageWalletRecipients && (
            <>
              <Button
                onClick={() => setShowAddRecipientModal(true)}
                startIcon={<FontAwesomeIcon icon={faPlus} />}
                color="secondary"
                size="small"
                fullWidth
              >
                Add new recipient
              </Button>
              <Button
                onClick={() => navigate('recipients')}
                startIcon={<FontAwesomeIcon icon={faUsers} />}
                color="tertiary"
                size="small"
                fullWidth
                className="mt-2"
              >
                Manage recipients
              </Button>
            </>
          )}
        </div>
      </div>
      <RecipientModal
        mode="add"
        onSuccess={async () => {
          await refetchRecipients();
          setShowAddRecipientModal(false);
        }}
        onClose={() => setShowAddRecipientModal(false)}
        open={showAddRecipientModal}
      />
    </div>
  );
}
