import { ReactElement } from 'react';

interface DividerProps {
  label?: string;
}

export default function Divider({ label }: DividerProps): ReactElement {
  return (
    <div className="relative my-4 flex">
      <div className="absolute inset-0 flex items-center" aria-hidden="true">
        <div className="border-soft w-full border-t" />
      </div>
      <div className="relative flex justify-center">
        {label && (
          <span className="bg-soft-primary text-default-secondary px-2 text-sm">{label} </span>
        )}
      </div>
    </div>
  );
}
