import { Snackbar, SnackbarCloseReason } from '@mui/material';
import { ReactElement, ReactNode, createContext, useCallback, useEffect, useState } from 'react';
import Alert from './Alert';

export type ToastOptions = {
  type: 'success' | 'error' | 'info' | 'warning';
  title: string | ReactNode;
  description?: string | ReactNode;
  actionText?: string | ReactNode;
  action?: () => void;
  secondaryActionText?: string | ReactNode;
  secondaryAction?: () => void;
  onDismiss?: () => void;
};

type MessageType = { content: ReactElement; onDismiss: (() => void) | undefined };

export type AddToast = (options: ToastOptions) => void;

export const ToastContext = createContext<AddToast>((() => {}) as unknown as AddToast);

export default function ToastProvider({ children }: { children: ReactNode }): ReactElement {
  const [open, setOpen] = useState(false);
  const [incomingMessages, setIncomingMessages] = useState<MessageType[]>([]);
  const [currentMessage, setCurrentMessage] = useState<MessageType | null>(null);

  useEffect(() => {
    if (incomingMessages.length && !currentMessage) {
      setCurrentMessage(incomingMessages[0]);
      setIncomingMessages((prev) => prev.slice(1));
      setOpen(true);
    } else if (incomingMessages.length && currentMessage && open) {
      setOpen(false);
    }
  }, [incomingMessages, currentMessage, open]);

  const handleClose = useCallback(
    (_: React.SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
      if (reason === 'clickaway') {
        return;
      }

      setOpen(false);
      currentMessage?.onDismiss && currentMessage?.onDismiss();
    },
    [currentMessage],
  );

  const addToast = useCallback<AddToast>(
    (options: ToastOptions) => {
      const newMsg: MessageType = {
        content: <Alert {...options} onClose={handleClose} variant="filled" />,
        onDismiss: options?.onDismiss,
      };
      setIncomingMessages((prev) => [...prev, newMsg]);
    },
    [handleClose],
  );

  return (
    <ToastContext.Provider value={addToast}>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={open}
        onClose={handleClose}
        autoHideDuration={5000}
        TransitionProps={{ onExited: () => setCurrentMessage(null) }}
      >
        <div>{currentMessage?.content}</div>
      </Snackbar>
    </ToastContext.Provider>
  );
}
