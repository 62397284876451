export const mapAccountType = (type: string) => {
  switch (type) {
    case 'InstantPayoutMethod::Zelle':
      return 'zelle';
    case 'InstantPayoutMethod::Paypal':
      return 'paypal';
    case 'InstantPayoutMethod::Venmo':
      return 'venmo';
  }
};
