import { EndpointResponse } from '@app/@types/api.types';
import { InstantPayoutRecipient } from '@app/@types/instant_payout_method.types';
import { apiDeleteFetcher, FetcherKey } from '@app/utils/data/fetchers';
import Modal, { ModalBodyContent, ModalHeader } from '@atob-developers/shared/src/components/Modal';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { AxiosError } from 'axios';
import { ReactElement } from 'react';
import useSWRMutation from 'swr/mutation';

const DeleteRecipientModal = ({
  onClose,
  open,
  recipient,
  onDeleteRecipient,
}: {
  onClose: () => void;
  open: boolean;
  recipient: InstantPayoutRecipient;
  onDeleteRecipient?: () => void;
}): ReactElement => {
  const { addToast } = useToasts();
  const { trigger, isMutating: isLoading } = useSWRMutation<
    EndpointResponse<InstantPayoutRecipient>,
    AxiosError,
    FetcherKey
  >(`/instant_payout/recipients/${recipient.id}`, apiDeleteFetcher, {
    onSuccess: () => {
      onDeleteRecipient?.();
      onClose();
      addToast({ type: 'success', title: 'Recipient deleted' });
    },
    onError: (error) => {
      addToast({
        type: 'error',
        title: error.message || 'There was a problem deleting this recipient',
      });
    },
  });

  return (
    <Modal maxWidth="sm" open={open} toggle={onClose}>
      <ModalHeader
        headerImage={
          <FontAwesomeIcon
            icon={faCircleInfo}
            className="bg-strong-secondary text-default-primary rounded-full p-4 text-xl"
          />
        }
        title={`Are you sure you want to remove ${recipient.name}?`}
        secondaryTitle="This action cannot be undone, and you will lose all of the attached payment methods."
        onClose={onClose}
      />
      <ModalBodyContent>
        <div className="mb-3 flex flex-col-reverse gap-3 md:flex-row">
          <Button color="secondary" fullWidth onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            variant="destructive"
            fullWidth
            onClick={() => trigger()}
            loading={isLoading}
          >
            Remove this recipient
          </LoadingButton>
        </div>
      </ModalBodyContent>
    </Modal>
  );
};

export default DeleteRecipientModal;
