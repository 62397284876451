import { EndpointResponse, Entity } from '@app/@types/api.types';
import { DriverData, GenerateUnlockIdResult, ValidationResult } from '@app/@types/driver.types';
import PhoneInput from '@app/components/Inputs/PhoneInput';
import useChannelPartner from '@app/hooks/useChannelPartner';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import useProduct from '@app/hooks/useProduct';
import { FetcherKey, getFetcher, postFetcher } from '@app/utils/data/fetchers';
import logger from '@app/utils/datadog-logger';
import { driverValidationSchema } from '@app/utils/validation/create-driver-validation';
import Modal, {
  ModalFooter,
  ModalBodyContent,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { faWarning } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { Button, TextField } from '@mui/material';
import axios, { AxiosError } from 'axios';
import { deserialize } from 'deserialize-json-api';
import { useFormik } from 'formik';
import { debounce, throttle } from 'lodash-es';
import { ChangeEvent, ReactElement, useState, FocusEvent } from 'react';
import useSWRMutation from 'swr/mutation';

type CreateDriverModalProps = {
  open: boolean;
  toggle: () => void;
  enableStreamlinedDriverAppOnboarding: boolean;
  onDriversUpdate: () => void;
};

const DEBOUNCE_TIME_IN_MS = 400;

const initialValues = {
  first_name: '',
  last_name: '',
  email: '',
  phone: '',
  unlock_id: '',
  notes: '',
  external_username: '',
};

const CreateDriverModal = ({
  open,
  toggle,
  enableStreamlinedDriverAppOnboarding,
  onDriversUpdate,
}: CreateDriverModalProps): ReactElement => {
  const [unlockIdWarning, setUnlockIdWarning] = useState<string | null>(null);
  const [disableAutoInvite] = useFeatureFlags('disable_new_phone_welcome_sms');
  const [allowSelfServePins] = useProduct('drivers_unlock_id');
  const { addToast } = useToasts();
  const { partnerName } = useChannelPartner();
  const formik = useFormik({
    initialValues,
    validationSchema: driverValidationSchema,
    onSubmit: async (values) => {
      let phone = values.phone;
      if (phone != null && phone.startsWith('1')) {
        phone = phone.replace('1', '');
      }
      await createDriver({ driver: { ...values, phone } });
    },
  });

  const { trigger: createDriver, isMutating: isCreating } = useSWRMutation<
    EndpointResponse<Entity<DriverData>>,
    AxiosError,
    FetcherKey,
    unknown
  >({ url: 'drivers' }, postFetcher, {
    onSuccess: (res) => {
      const { data } = deserialize(res.data);
      // Kick off driver app invite for provided phone number
      if (
        enableStreamlinedDriverAppOnboarding &&
        formik.values.phone?.length > 1 &&
        !disableAutoInvite
      ) {
        axios.post<EndpointResponse<Entity<DriverData>>>(`/drivers/${data.id}/driver_app_invite`, {
          data,
          force_invite: false,
        });
      }

      onDriversUpdate();
      addToast({ type: 'success', title: 'Driver successfully added!' });
      modalToggle();
    },
    onError: (e) => {
      const error = e.response?.data?.error || 'Sorry, an unknown error occurred';
      addToast({ type: 'error', title: error });
    },
  });

  const { trigger: validateNewUnlockId } = useSWRMutation<
    ValidationResult,
    AxiosError,
    string,
    string
  >(
    '/drivers/validate_new_unlock_id',
    (url, { arg: unlockId }) => getFetcher({ url, params: { unlock_id: unlockId } }),
    {
      onSuccess: (res) => {
        setUnlockIdWarning(res?.warning ?? null);
      },
      onError: (e) => logger.error('Error trying to validate a new unlock ID', { error: e }),
    },
  );

  const { trigger: generateNewUnlockId } = useSWRMutation<
    GenerateUnlockIdResult,
    AxiosError,
    FetcherKey,
    unknown
  >({ url: '/drivers/generate_new_unlock_id' }, getFetcher, {
    onSuccess: (res) => {
      formik.setFieldValue('unlock_id', res.unlock_id);
      setUnlockIdWarning(null);
    },
    onError: (e) => {
      logger.error('Error trying to generate a new unlock ID', { error: e });
      addToast({ type: 'error', title: "Couldn't generate unlock ID" });
    },
  });

  const verifyUnlockId = debounce(async (val: string) => {
    validateNewUnlockId(val);
  }, DEBOUNCE_TIME_IN_MS);

  const generateUnlockId = throttle(async () => {
    generateNewUnlockId();
  }, DEBOUNCE_TIME_IN_MS);

  const handleUnlockIdChange = (
    e: ChangeEvent<HTMLInputElement> | FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
  ) => {
    formik.handleChange(e);
    verifyUnlockId(e.target.value);
  };

  const modalToggle = () => {
    toggle();
    formik.resetForm();
  };

  return (
    <Modal open={open} toggle={modalToggle}>
      <ModalHeader title="Add New Driver" onClose={modalToggle} />
      <ModalBodyContent>
        <form className="flex flex-col gap-6">
          <TextField
            required
            name="first_name"
            size="small"
            label="First Name"
            value={formik.values.first_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.first_name && Boolean(formik.errors.first_name)}
            helperText={formik.touched.first_name && formik.errors.first_name}
          />
          <TextField
            name="last_name"
            size="small"
            label="Last Name"
            value={formik.values.last_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <TextField
            name="email"
            size="small"
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <div>
            <PhoneInput
              fullWidth
              name="phone"
              size="small"
              label="Phone Number"
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
            />
          </div>
          {allowSelfServePins && (
            <div className="flex flex-col items-start gap-2">
              <TextField
                fullWidth
                name="unlock_id"
                size="small"
                label="Unlock ID"
                value={formik.values.unlock_id}
                onChange={handleUnlockIdChange}
                onBlur={handleUnlockIdChange}
              />
              {unlockIdWarning && (
                <p className="text-warning space-x-1 text-sm">
                  <FontAwesomeIcon icon={faWarning} />
                  <span>{unlockIdWarning}</span>
                </p>
              )}
              <button
                type="button"
                className="text-green text-sm hover:underline"
                onClick={generateUnlockId}
              >
                Generate Unique ID
              </button>
            </div>
          )}
          <TextField
            name="notes"
            size="small"
            label="Notes"
            value={formik.values.notes}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <TextField
            name="external_username"
            size="small"
            label="External Username"
            value={formik.values.external_username}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
        </form>
        {enableStreamlinedDriverAppOnboarding && formik.values.phone && !disableAutoInvite && (
          <div className="text-default-primary my-2 block text-sm font-medium">
            {`Your driver will automatically be eligible to use the ${partnerName} app`}
          </div>
        )}
      </ModalBodyContent>
      <ModalFooter>
        <Button color="secondary" size="small" onClick={modalToggle}>
          Cancel
        </Button>
        <LoadingButton size="small" loading={isCreating} onClick={() => formik.handleSubmit()}>
          Add New Driver
        </LoadingButton>
      </ModalFooter>
    </Modal>
  );
};

export default CreateDriverModal;
