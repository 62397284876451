import {
  PayrollCalculationBackendEnums,
  PayrollItemizationBackendEnums,
  PayrollItemizationData,
  PayrollTransactionData,
} from '@app/@types/payroll.types';
import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import Table, { ColumnDefinition } from '@app/components/TableV2/Table';
import { Divider } from '@app/components/elements';
import { mapFromItemizationBackendEnum } from '@app/utils/payroll-itemization';
import { CustomTooltip } from '@atob-developers/shared/src/components/Tooltip';
import { formatCurrency } from '@atob-developers/shared/src/utils/formatters';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro';
import dayjs from 'dayjs';
import { capitalize } from 'lodash-es';
import { ReactElement, useMemo } from 'react';
import { ItemizationCategories } from '../Payroll/PaymentFlow/PaymentItemization';
import { mapFromCalculationBackendEnum } from '../Payroll/payroll-context';

interface DesktopTransactionsTableProps {
  transactions: PayrollTransactionData[];
  loading: boolean;
}

export default function DesktopTransactionsTable({
  transactions,
  loading,
}: DesktopTransactionsTableProps): ReactElement {
  const shouldShowFeesColumn = transactions.length !== 0 && transactions[0].fees !== undefined;

  const columns = useMemo(() => {
    return [
      {
        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        minWidth: 74,
      },
      {
        field: 'name',
        headerName: 'Driver Name',
        flex: 2,
      },
      {
        field: 'payment_date',
        headerName: 'Payment Date',
        flex: 1,
        valueFormatter: (value: number) => dayjs.unix(value).format('MM-DD-YYYY'),
      },
      {
        field: 'payment_card',
        headerName: 'Payment Method',
        flex: 1,
      },
      {
        field: 'amount',
        headerName: 'Amount',
        flex: 1,
      },
      {
        field: 'fees',
        headerName: 'Fees',
        flex: 1,
        canBeShown: shouldShowFeesColumn,
      },
      {
        field: 'payment_status',
        headerName: 'Status',
        flex: 1,
        renderCell: ({ row }) => {
          const status = row.payment_status;
          const error_tooltip = status === 'error' && row.error_message;
          if (!error_tooltip) {
            return <span className="text-default-primary">{capitalize(status)}</span>;
          }

          return (
            <>
              {error_tooltip && (
                <span className="text-warning ml-2">
                  <CustomTooltip icon={faExclamationTriangle}>
                    {error_tooltip}
                    <div className="flex-row items-center">
                      <div className="inline-flex">{capitalize(status)}</div>
                    </div>
                  </CustomTooltip>
                </span>
              )}
            </>
          );
        },
      },
    ] as ColumnDefinition[];
  }, [shouldShowFeesColumn]);

  return (
    <RoundedCard>
      <Table
        columns={columns}
        data={transactions}
        loading={loading}
        getDetailPaneContent={({ row }) => <TransactionsTableExpandedRow data={row} />}
      />
    </RoundedCard>
  );
}

/**
 * This expanded section component is used for both teh desktop and mobile table.
 */
export const TransactionsTableExpandedRow = ({
  data,
}: {
  data: PayrollTransactionData;
}): ReactElement => {
  if (!data.itemizations || data.itemizations.length === 0) {
    // Description is already shown on main card for mobile so hide expanded view on mobile
    return (
      <div className="mt-6 hidden flex-col pl-6 pt-6 lg:flex">
        <div className="flex flex-col text-sm">
          <div className="mb-3 flex">
            <span className="text-default-primary mr-1 font-bold">Description:</span>
            {data.description && <span className="text-default-primary">"{data.description}"</span>}
          </div>
        </div>
      </div>
    );
  }

  //payroll payment will have at most 1 calculation but multiple items
  const calculation = data.itemizations.filter((i) =>
    i.attributes.item_type.startsWith('calculation_'),
  );
  const items = data.itemizations.filter((i) => i.attributes.item_type.startsWith('itemization_'));

  const getUnitDescriptor = (item_type: PayrollCalculationBackendEnums) => {
    if (item_type === 'calculation_hourly') {
      return 'Hour';
    } else if (item_type === 'calculation_per_mile') {
      return 'Mile';
    }
  };

  const rateSection = (calculation: PayrollItemizationData): ReactElement | null => {
    const item_type = calculation.item_type;
    const includesRate = [
      'calculation_per_mile',
      'calculation_hourly',
      'calculation_load',
    ].includes(item_type);

    if (!includesRate) {
      return null;
    }

    if (item_type === 'calculation_hourly' || item_type === 'calculation_per_mile') {
      return (
        <div className="text-default-primary flex-1 text-sm">
          <span>
            {formatCurrency({
              value: calculation.rate_cents,
              options: { fromCents: true },
            })}{' '}
            per {getUnitDescriptor(item_type)}
          </span>{' '}
          x <span>{calculation.units}</span> {getUnitDescriptor(item_type)}s
        </div>
      );
    } else {
      return (
        <div className="text-default-primary flex-1 text-sm">
          <span>{calculation.percentage}% of load</span> x{' '}
          <span>
            {formatCurrency({
              value: calculation.load_cents,
              options: { fromCents: true },
            })}
          </span>
        </div>
      );
    }
  };
  return (
    <div className="mt-6 flex flex-col py-6 pr-3 lg:pl-6 ">
      <div className="flex flex-col text-sm">
        {calculation.length > 0 && (
          <div className="flex flex-col">
            <div className="text-default-primary mb-3 flex justify-between">
              <span className="font-bold">Gross Payment</span>
              <span className="font-bold">Gross Pay Total</span>
            </div>
            <div className="text-default-primary flex lg:pl-4">
              <div className="flex basis-3/4 flex-col gap-y-2 lg:flex-row lg:gap-x-2">
                <div className="flex-1">
                  {mapFromCalculationBackendEnum(
                    calculation[0].attributes.item_type as PayrollCalculationBackendEnums,
                  )}
                </div>

                {/* TODO [PAYRO-1112] Fix bug that itemization description is not set on itemization record,
                Then update this code to read it from the itemization object*/}
                <div className="text-default-primary hidden flex-1 lg:flex">
                  {data.description && <span>"{data.description}"</span>}
                </div>

                {rateSection(calculation[0].attributes)}
              </div>
              <div className="flex basis-1/4 justify-end">
                <span className="text-default-primary text-base font-bold">
                  {formatCurrency({
                    value: calculation[0].attributes.amount_cents,
                    options: { fromCents: true },
                  })}
                </span>
              </div>
            </div>
          </div>
        )}
        {items.length > 0 && (
          <>
            {calculation.length > 0 && <Divider />}
            <div className="flex flex-col gap-y-4">
              {ItemizationCategories.map((category) => {
                const itemsInCategory = items.filter((item) => {
                  const { item_type } = item.attributes;
                  if (category === 'Bonus or Advance') {
                    return item_type.includes('bonus') || item_type.includes('advance');
                  } else {
                    /**
                     * Example:
                     *
                     * category: "Deduction"
                     * item_type: itemization_deduction_towing
                     */
                    return item_type.includes(category.toLowerCase());
                  }
                });
                if (itemsInCategory.length === 0) {
                  return null;
                }

                return (
                  <div key={category}>
                    <div className="mb-2 text-base font-semibold">{category}</div>
                    <div className="flex flex-col gap-y-2">
                      {itemsInCategory.map((item) => {
                        const amount_cents =
                          category === 'Deduction'
                            ? item.attributes.amount_cents * -1
                            : item.attributes.amount_cents;
                        return (
                          <div key={item.id} className="flex justify-between pl-2 lg:pl-4">
                            <div className="text-default-primary flex basis-3/4 flex-col gap-y-2 lg:flex-row">
                              <div className="basis-1/3">
                                {mapFromItemizationBackendEnum(
                                  item.attributes.item_type as PayrollItemizationBackendEnums,
                                )}
                              </div>
                              <div className="basis-2/3">
                                {item.attributes.description && (
                                  <span>"{item.attributes.description}"</span>
                                )}
                              </div>
                            </div>
                            <div className="flex basis-1/4 justify-end">
                              <span className="text-default-primary text-base font-bold">
                                {formatCurrency({
                                  value: amount_cents,
                                  options: { fromCents: true },
                                })}
                              </span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
            <Divider />
            <div className="flex justify-end">
              <span className="text-default-primary mr-10 text-base font-semibold">
                Total Payment{' '}
              </span>
              <span className="text-default-primary text-base font-semibold">{data.amount}</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
