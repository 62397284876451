import useCustomer from '@app/hooks/useCustomer';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import dayjs from 'dayjs';
import { ReactElement } from 'react';
import { MarketingBanner } from './Designs/MarketingBanner';

export default function FuelDiscountPromotionalBanner(): ReactElement | null {
  const [showBanner] = useFeatureFlags('cp10_promo_offer');
  const customer = useCustomer();

  const createdDate = dayjs(customer.created_at);
  const endDate = createdDate.add(60, 'days');

  if (!showBanner || dayjs().isAfter(endDate)) {
    return null;
  }

  return (
    <MarketingBanner bannerKey="fuel_discount_promotional" title="New Discount">
      Enjoy{' '}
      <strong>
        <em>extra discounts</em> up to 10¢/gal
      </strong>{' '}
      through {endDate.format('M/D')} — only at AtoB Discount Network stations. Prices reflect your
      upgraded discounts.
    </MarketingBanner>
  );
}
