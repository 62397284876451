import { InstantPayoutMethod, TreasuryRecipient } from '@app/@types/instant_payout_method.types';
import { PaymentMethodType } from '@app/pages/Wallet/RecipientAccounts';
import { mapAccountType } from '@app/pages/Wallet/utils';

export type Account = {
  id: string;
  name: string | null;
  mask: string | null;
  bankName: string | null;
  type: PaymentMethodType;
};

export const methodNameMapping = {
  zelle: 'Zelle',
  paypal: 'PayPal',
  venmo: 'Venmo',
  recipient: 'Bank account',
};

export const getFullAccountName = (
  paymentMethodName: string,
  paymentMethodType: PaymentMethodType,
) =>
  paymentMethodType === 'recipient'
    ? paymentMethodName.toLowerCase()
    : `${paymentMethodName} account`;

export const mapRecipientAccounts = (
  instantPayouts: InstantPayoutMethod[],
  treasuryRecipients: TreasuryRecipient[],
): Account[] => {
  return [
    ...instantPayouts.map((account) => ({
      id: account.id,
      name: account.username,
      mask: null,
      bankName: null,
      type: mapAccountType(account.type) as PaymentMethodType,
    })),
    ...treasuryRecipients.map((account) => ({
      id: account.id,
      name: account.billing_details.name,
      mask: account.payment_details.bank_account.mask,
      bankName: account.payment_details.bank_account.institution_name,
      type: account.type,
    })),
  ];
};
