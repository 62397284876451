import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { ReactElement } from 'react';

import { Step, StepStatus, CompleteStep } from '../steps.types';

export const isComplete = (step: Step): step is CompleteStep => step.status === StepStatus.COMPLETE;

export const Complete = ({ step }: { step: CompleteStep }): ReactElement => {
  const { name, href, id, disableIcon } = step;
  return (
    <a
      data-testid={`complete-${id}`}
      href={href}
      className={classNames('group flex w-full items-center', {
        'cursor-default': !href,
      })}
    >
      <span className="flex items-center px-6 py-4 text-sm font-medium">
        {!disableIcon && (
          <span className="group-hover:bg-green-primary-hover border-success flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full border-2">
            <FontAwesomeIcon className="fa-1x text-success" icon={faCheck} />
          </span>
        )}
        <div className={classNames('flex flex-col text-sm font-medium', !disableIcon && 'ml-4')}>
          <span className="text-green text-xs">Done</span>
          <span className="text-default-primary">{name}</span>
        </div>
      </span>
    </a>
  );
};
