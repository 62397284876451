import {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';

export const ULToCreditRequestReviewPending = ({ onClose }: { onClose: () => void }) => {
  return (
    <>
      <ModalHeader title="" onClose={onClose} />
      <ModalBodyContent overflowVisible={true}>
        <div className="flex flex-col items-center">
          <div className="text-green mb-3 mt-16 md:mt-2">
            <FontAwesomeIcon className="fa-3x" icon={faCircleCheck} />
          </div>
          <h1 className="text-default-primary mb-1 text-center text-2xl font-bold">
            Request Pending
          </h1>
          <p className="text-default-primary my-8 mb-2 w-3/4 text-center text-base">
            Thank you for requesting a conversion to a credit account. Your request is under review;
            you&apos;ll receive an email once we have made a decision.
          </p>
        </div>
      </ModalBodyContent>
      <ModalFooter>
        <Button size="medium" onClick={onClose} fullWidth>
          Done
        </Button>
      </ModalFooter>
    </>
  );
};
