import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAdornment, TextField } from '@mui/material';
import { InputHTMLAttributes } from 'react';

type SearchbarProps = {
  name: string;
} & InputHTMLAttributes<HTMLInputElement>;

export default function Searchbar({
  placeholder,
  onChange,
  name,
  value,
  className,
  'aria-label': ariaLabel,
}: SearchbarProps) {
  return (
    <TextField
      size="small"
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder || 'Search'}
      slotProps={{
        input: {
          'startAdornment': (
            <InputAdornment position="start">
              <FontAwesomeIcon icon={faMagnifyingGlass} className="text-default-primary" />
            </InputAdornment>
          ),
          'aria-label': ariaLabel,
        },
        htmlInput: {
          'data-testid': 'search-bar',
        },
      }}
      className={className}
    />
  );
}
