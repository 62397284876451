import useChannelPartner from '@app/hooks/useChannelPartner';
import useWindowWidth from '@app/hooks/useWindowWidth';
import possessive from '@app/utils/possessive';
import { faCalendar, faFile, faClock, faCoins } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import classNames from 'classnames';
import { ReactElement, ReactNode } from 'react';

const Benefit = ({
  title,
  reason,
  children,
}: {
  title: string;
  reason: string;
  children: ReactNode;
}) => {
  return (
    <div className="xs:mb-0 mb-8 max-w-[400px] last:mb-0 sm:mb-4">
      {children}
      <h2 className="text-default-primary my-3 text-xl sm:my-4">{title}</h2>
      <p className="text-default-secondary text-base">{reason}</p>
    </div>
  );
};

export default function PayrollBenefits({ onClick }: { onClick: () => void }): ReactElement {
  const { isMobile } = useWindowWidth();
  const { partnerName } = useChannelPartner();
  const iconSize = isMobile ? '2x' : '3x';

  return (
    <div>
      <div className="bg-soft-primary max-w-72 rounded-md p-8 shadow-md">
        <div className="flex flex-col items-center">
          <h2
            className={classNames(
              'text-green text-center text-[32px] font-[700]',
              !isMobile && 'mb-8',
            )}
          >
            Benefits of {possessive(partnerName ?? '')} Instant Payroll
          </h2>
          {isMobile && (
            <div className="my-8 flex flex-col items-center">
              <Button size="medium" onClick={onClick}>
                Get Started
              </Button>
              <h3 className="text-green mt-4 text-center font-[700]">
                Sign up today to get your first month FREE!
              </h3>
            </div>
          )}
          <div className="xs:grid xs:grid-rows-2 xs:grid-cols-2 xs:gap-x-8 my-4 sm:gap-y-4 md:gap-y-8">
            <Benefit
              title="Instant transfer in bank account"
              reason="Pay your drivers in as fast as 30 minutes."
            >
              <FontAwesomeIcon icon={faClock} size={iconSize} className="text-green" />
            </Benefit>
            <Benefit
              title="Pay your drivers 24/7"
              reason="We enable you to run payroll 24/7 with no limitations."
            >
              <FontAwesomeIcon icon={faCalendar} size={iconSize} className="text-green" />
            </Benefit>
            <Benefit
              title="Pay as you go pricing"
              reason="Stop paying monthly and per-employee fees for payroll. For every $10K in payroll processed, pay just $50."
            >
              <FontAwesomeIcon icon={faCoins} size={iconSize} className="text-green" />
            </Benefit>
            <Benefit
              title="Simplify 1099 taxes"
              reason="Get built in tracking and history to calculate 1099 taxes easily."
            >
              <FontAwesomeIcon icon={faFile} size={iconSize} className="text-green" />
            </Benefit>
          </div>
          {!isMobile && (
            <div className="flex flex-col items-center">
              <h3 className="text-green mb-6 mt-16 font-[700]">
                Sign up today to get your first month FREE!
              </h3>
              <Button size="medium" onClick={onClick}>
                Get Started
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
