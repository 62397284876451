import { MerchantData } from '@app/@types/spend_restriction.types';
import { ReactElement } from 'react';
export const MerchantRestrictionList = ({
  merchants,
  removeMerchantRestriction,
  emptyMessage,
}: {
  merchants: MerchantData[];
  removeMerchantRestriction: (merchantID: string) => void;
  emptyMessage: string;
}): ReactElement => {
  return (
    <div>
      {merchants?.length === 0 && (
        <p className="text-default-secondary mb-5 text-[14px]">{emptyMessage}</p>
      )}
      <div className="text-default-primary mb-4 text-sm">
        {merchants?.map((m) => (
          <div className="mt-2 flex flex-row" key={m.id}>
            <div className="basis-4/5">
              <p className="text-default-primary">{m.name}</p>
              <p className="text-default-secondary text-[13px]">{m.display_address}</p>
            </div>
            <button
              className="text-default-primary flex basis-1/5 items-center no-underline hover:underline"
              onClick={() => removeMerchantRestriction(m.id)}
            >
              Remove
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};
