import { Chip } from '@mui/material';
import { ReactElement } from 'react';
interface CardNumberProps {
  cardNumber: string;
  status: string;
  secondLine: string;
  cardType: 'physical' | 'virtual' | null;
}

const CardNumber = ({
  cardNumber,
  status,
  secondLine,
  cardType,
}: CardNumberProps): ReactElement => {
  return (
    <>
      <div className="flex items-center">
        <p className="text-default-primary pr-3 text-xl font-bold">{`Card •••• ${cardNumber}`}</p>
        {status === 'active' && <Chip label="Active" color="green" />}
        {status === 'inactive' && <Chip label="Inactive" color="red" />}
        {status === 'provisioning' && <Chip label="Provisioning" color="white" />}
        <div className="ml-2" />
        <Chip label={cardType === 'virtual' ? 'Virtual Card' : 'Physical Card'} color="grey" />
        <br />
      </div>
      <div className="text-default-primary pt-2 text-sm">{secondLine}</div>
    </>
  );
};

export default CardNumber;
