import { apiPostHeadersOnly, FetcherKey } from '@app/utils/data/fetchers';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { AxiosError, AxiosResponseHeaders } from 'axios';
import useSWRMutation, { SWRMutationResponse } from 'swr/mutation';

export type CreateAndInviteDriverParam = {
  driver: { phone: string };
};

export function useSendAppInviteLinkText(): SWRMutationResponse<
  AxiosResponseHeaders,
  AxiosError,
  FetcherKey,
  CreateAndInviteDriverParam
> {
  const { addToast } = useToasts();

  return useSWRMutation<AxiosResponseHeaders, AxiosError, FetcherKey, CreateAndInviteDriverParam>(
    {
      url: '/drivers/send_admin_app_link',
    },
    apiPostHeadersOnly,
    {
      onError: (e) => {
        addToast({
          type: 'error',
          title: e.response?.data.error,
        });
      },
    },
  );
}
