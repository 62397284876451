import { paymentMethodIconMapping } from '@app/pages/Wallet/RecipientAccounts';
import { Chip } from '@mui/material';
import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import { Account } from './utils';

export default function AccountInfo({
  className,
  children,
  account,
}: PropsWithChildren<{
  className: string;
  account: Account;
}>) {
  return (
    <div
      className={classNames(
        'border-soft bg-soft-primary flex w-full flex-grow items-center gap-2 rounded border px-4',
        className,
      )}
    >
      <div className="flex w-full flex-wrap items-center gap-2">
        <div className="flex items-center gap-2 font-medium">
          <img src={paymentMethodIconMapping[account.type]} alt={`${account.type} icon`} />
          {account.type === 'recipient' ? `•••• ${account.mask}` : account.name}
        </div>
        {account.type === 'recipient' && (
          <Chip color="grey" size="small" label={account.bankName} />
        )}
      </div>
      {children}
    </div>
  );
}
