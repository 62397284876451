import { ErrorNotification } from '@app/components/layout';
import CardSpendingLimitsContext from '@app/contexts/cardSpendingLimitsContext';
import Modal, { ModalBodyContent, ModalFooter } from '@atob-developers/shared/src/components/Modal';
import { LoadingButton } from '@mui/lab';
import { TextField } from '@mui/material';
import axios from 'axios';
import { ReactElement, useContext, useState } from 'react';

import type { CardData } from '@app/@types/card.types';

type EditSpendLimitsModalProps = {
  open: boolean;
  toggle: () => void;
  card?: CardData;
  onSuccess?: () => void;
};

export interface SpendLimits {
  daily_spend_limit: string | null;
  per_transaction_limit: string | null;
  weekly_spend_limit: string | null;
}

const initialLimits = {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  perTransactionLimit: null as string,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  dailyTransactionLimit: null as string,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  weeklyTransactionLimit: null as string,
};

const EditSpendLimitsModal = ({
  open,
  toggle,
  card,
  onSuccess,
}: EditSpendLimitsModalProps): ReactElement => {
  const {
    perTransactionLimit,
    dailyTransactionLimit,
    weeklyTransactionLimit,
    setPerTransactionLimit,
    setDailyTransactionLimit,
    setWeeklyTransactionLimit,
  } = useContext(CardSpendingLimitsContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  initialLimits.perTransactionLimit = initialLimits.perTransactionLimit ?? perTransactionLimit;
  initialLimits.dailyTransactionLimit =
    initialLimits.dailyTransactionLimit ?? dailyTransactionLimit;
  initialLimits.weeklyTransactionLimit =
    initialLimits.weeklyTransactionLimit ?? weeklyTransactionLimit;

  const revertToInitialLimits = () => {
    initialLimits.perTransactionLimit && setPerTransactionLimit(initialLimits.perTransactionLimit);
    initialLimits.dailyTransactionLimit &&
      setDailyTransactionLimit(initialLimits.dailyTransactionLimit);
    initialLimits.weeklyTransactionLimit &&
      setWeeklyTransactionLimit(initialLimits.weeklyTransactionLimit);
  };

  const updateSpendLimits = () => {
    setIsLoading(true);
    const spendLimits: SpendLimits = {
      per_transaction_limit: perTransactionLimit ? perTransactionLimit : null,
      daily_spend_limit: dailyTransactionLimit ? dailyTransactionLimit : null,
      weekly_spend_limit: weeklyTransactionLimit ? weeklyTransactionLimit : null,
    };
    const formRequest = () => {
      setError(null);
      if (card) {
        return (values: SpendLimits) => axios.put(`/cards/${card.id}`, { card: values });
      } else {
        return () => Promise.reject(new Error('card data required to set spend limits'));
      }
    };
    const sendRequest = formRequest();
    sendRequest(spendLimits)
      .then(() => {
        onSuccess && onSuccess();
        toggle();
      })
      .catch(() => {
        setError('There was an error updating your spending limits. Please try again.');
        revertToInitialLimits();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Modal open={open} toggle={toggle}>
      <ModalBodyContent>
        <div className="flex flex-col gap-2">
          {error && <ErrorNotification error={error} />}
          <div className="basis-6/12">
            <TextField
              fullWidth
              id="per-transaction-limit"
              size="small"
              label="Per Transaction Limit"
              placeholder="Per Transaction Limit"
              value={perTransactionLimit}
              onChange={(e) => setPerTransactionLimit(e.target.value)}
            />
          </div>
          <div className="basis-6/12">
            <TextField
              fullWidth
              id="daily-spend-limit"
              size="small"
              label="Daily Spend Limit"
              placeholder="Daily Spend Limit"
              value={dailyTransactionLimit}
              onChange={(e) => setDailyTransactionLimit(e.target.value)}
            />
          </div>
          <div className="basis-6/12">
            <TextField
              fullWidth
              id="weekly-spend-limit"
              size="small"
              label="Weekly Spend Limit"
              placeholder="Weekly Spend Limit"
              value={weeklyTransactionLimit}
              onChange={(e) => setWeeklyTransactionLimit(e.target.value)}
            />
          </div>
          <div className="block w-full basis-6/12 rounded-md sm:text-sm">
            <p className="text-default-secondary">
              * Max Spend Limits are capped by the account limits.
            </p>
          </div>
        </div>
      </ModalBodyContent>
      <ModalFooter>
        <LoadingButton onClick={() => updateSpendLimits()} size="small" loading={isLoading}>
          Save
        </LoadingButton>
      </ModalFooter>
    </Modal>
  );
};

export default EditSpendLimitsModal;
