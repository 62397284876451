import { createContext } from 'react';

type OnboardingWizardContextType = {
  goToPrevStep: () => void;
  goToNextStep: () => void;
  stepperLabels: Array<{ label: string; key: string }>;
};

export const OnboardingWizardContext = createContext<OnboardingWizardContextType>({
  goToPrevStep: () => {},
  goToNextStep: () => {},
  stepperLabels: [],
});
