import { ReactNode } from 'react';

const WarningNotification = ({ children }: { children: ReactNode }) => {
  return (
    <section>
      <div className="container">
        <div className="border-warning bg-orange-secondary mb-4 border-l-4 p-4">
          <div className="flex">
            <div className="ml-3">
              <div className="text-warning text-sm">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WarningNotification;
