import { statesArray } from '@app/constants/states';
import { MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import { ReactElement, Dispatch, SetStateAction } from 'react';

interface StateOptions {
  id: string;
  value: string;
}

export default function AddressInput({
  label,
  addressValues,
  setAddressValues,
  errors,
  setErrors,
  touched,
  setTouched,
}: {
  label: string;
  addressValues: Record<string, string | null | undefined>;
  setAddressValues: Dispatch<SetStateAction<unknown>>;
  errors: Record<string, string>;
  setErrors: (errors: Record<string, string | undefined>) => void;
  touched: Record<string, boolean>;
  setTouched: (errors: Record<string, boolean | undefined>) => void;
}): ReactElement {
  const options: StateOptions[] = statesArray.map((state, index) => ({
    id: index.toString(),
    value: state,
  }));

  const handleTextFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    setErrors({ ...errors, [name]: undefined });
    setAddressValues({
      ...addressValues,
      [name]: event.target.value,
    });
  };

  const handleSelectChange = (event: SelectChangeEvent) => {
    const name = event.target.name;
    setErrors({ ...errors, [name]: undefined });

    const selectedState = options.find((option) => option.id === event.target.value)?.value || '';
    setAddressValues({
      ...addressValues,
      [name]: selectedState,
    });
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setTouched({ ...touched, [event.target.name]: true });
  };

  return (
    <div className="flex flex-col gap-3">
      <TextField
        label={label}
        name="address1"
        placeholder="Address Line #1"
        error={touched.address1 && !!errors.address1}
        helperText={touched.address1 && errors.address1}
        value={addressValues.address1}
        onChange={handleTextFieldChange}
        onBlur={handleBlur}
      />
      <TextField
        name="address2"
        placeholder="Address Line #2 (optional)"
        value={addressValues.address2}
        error={touched.address2 && !!errors.address2}
        helperText={touched.address2 && errors.address2}
        onChange={handleTextFieldChange}
        onBlur={handleBlur}
      />
      <div className="flex flex-wrap gap-3 md:flex-nowrap">
        <div className="flex w-full gap-3 md:flex-1 md:basis-2/3">
          <TextField
            className="flex-1 basis-1/2"
            fullWidth
            name="city"
            placeholder="City"
            error={touched.city && !!errors.city}
            helperText={touched.city && errors.city}
            value={addressValues.city}
            onChange={handleTextFieldChange}
            onBlur={handleBlur}
          />
          <Select
            className="flex-1 basis-1/2"
            fullWidth
            value={options.find((option) => option.value === addressValues.state)?.id || ''}
            onChange={handleSelectChange}
            onBlur={handleBlur}
            name="state"
            renderValue={(value) => {
              const selectedOption = options.find((option) => option.id === value);
              return (
                selectedOption?.value || (
                  <span className="text-default-secondary text-base">State</span>
                )
              );
            }}
            error={touched.state && !!errors.state}
            displayEmpty
          >
            {options.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.value}
              </MenuItem>
            ))}
          </Select>
        </div>
        <TextField
          className="flex-1 basis-full md:basis-1/3"
          fullWidth
          name="zip"
          placeholder="Zip"
          error={touched.zip && !!errors.zip}
          helperText={touched.zip && errors.zip}
          value={addressValues.zip}
          onChange={handleTextFieldChange}
          onBlur={handleBlur}
        />
      </div>
    </div>
  );
}

export type Address = {
  address1: string;
  address2?: string;
  city: string;
  state: string;
  zip: string;
};
