import { sumItemizationCategory } from '@app/utils/payroll-itemization';
import { formatCurrency } from '@atob-developers/shared/src/utils/formatters';
import {
  convertCentsToDollars,
  convertDollarsToCents,
} from '@atob-developers/shared/src/utils/formatters/currencyFormat';
import { MenuItem, Select, TextField, Checkbox } from '@mui/material';
import { ReactElement } from 'react';
import { v4 as uuid } from 'uuid';

export const ItemizationCategories = ['Bonus or Advance', 'Reimbursement', 'Deduction'] as const;
export type PayrollItemizationCategory = (typeof ItemizationCategories)[number];
export type PayrollItemizationSubcategory =
  | 'Bonus'
  | 'Advance'
  | 'Fuel'
  | 'Hotel & Meals'
  | 'Vendor'
  | 'Transportation'
  | 'Other'
  | 'Maintenance'
  | 'Insurance'
  | 'Dispatch Fee'
  | 'Towing';
export type PayrollItemization = {
  id: string;
  amount_cents: number;
  description: string;
  category: PayrollItemizationCategory;
  subcategory: PayrollItemizationSubcategory;
  to_delete: boolean;
};
export const DefaultItems: PayrollItemization[] = [
  {
    id: uuid(),
    amount_cents: 0,
    description: '',
    to_delete: false,
    category: 'Bonus or Advance',
    subcategory: 'Bonus',
  },
  {
    id: uuid(),
    amount_cents: 0,
    description: '',
    to_delete: false,
    category: 'Reimbursement',
    subcategory: 'Fuel',
  },
  {
    id: uuid(),
    amount_cents: 0,
    description: '',
    to_delete: false,
    category: 'Deduction',
    subcategory: 'Other',
  },
];
const Itemizations: {
  id: number;
  category: PayrollItemizationCategory;
  subcategory: PayrollItemizationSubcategory;
}[] = [
  {
    id: 1,
    category: 'Bonus or Advance',
    subcategory: 'Bonus',
  },
  {
    id: 2,
    category: 'Bonus or Advance',
    subcategory: 'Advance',
  },
  {
    id: 3,
    category: 'Reimbursement',
    subcategory: 'Fuel',
  },
  {
    id: 4,
    category: 'Reimbursement',
    subcategory: 'Hotel & Meals',
  },
  {
    id: 5,
    category: 'Reimbursement',
    subcategory: 'Vendor',
  },
  {
    id: 6,
    category: 'Reimbursement',
    subcategory: 'Transportation',
  },
  {
    id: 7,
    category: 'Reimbursement',
    subcategory: 'Other',
  },
  {
    id: 8,
    category: 'Deduction',
    subcategory: 'Maintenance',
  },
  {
    id: 9,
    category: 'Deduction',
    subcategory: 'Insurance',
  },
  {
    id: 10,
    category: 'Deduction',
    subcategory: 'Dispatch Fee',
  },
  {
    id: 11,
    category: 'Deduction',
    subcategory: 'Towing',
  },
  {
    id: 12,
    category: 'Deduction',
    subcategory: 'Other',
  },
];

interface ItemizationRowProps {
  item: PayrollItemization;
  updateItemization: (updatedItem: PayrollItemization) => void;
  deleteEnabled: boolean;
}

export const PaymentItemizationRow = ({
  item,
  updateItemization,
  deleteEnabled,
}: ItemizationRowProps): ReactElement => {
  const { id, amount_cents, description, category, subcategory, to_delete } = item;

  const handleSelection = (subcategory: PayrollItemizationSubcategory | null) => {
    if (subcategory) {
      updateItemization({ ...item, subcategory });
    }
  };

  const onChangeAmount = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    e.stopPropagation();
    const { value } = e.target;
    const isNotANumber = Number.isNaN(parseFloat(value));
    const isEmpty = value === '';
    if (isNotANumber || isEmpty) {
      updateItemization({ ...item, amount_cents: 0 });
      return;
    }
    if (value.startsWith('-')) {
      return;
    }
    updateItemization({
      ...item,
      amount_cents: convertDollarsToCents({ value: parseFloat(value) }).value as number,
    });
  };

  return (
    <>
      {deleteEnabled && (
        <Checkbox
          name={`delete-item-${id}`}
          checked={to_delete}
          onChange={() => updateItemization({ ...item, to_delete: !to_delete })}
        />
      )}
      <Select
        value={subcategory}
        onChange={(e) => handleSelection(e.target.value as PayrollItemizationSubcategory)}
        fullWidth
      >
        {Itemizations.filter((i) => i.category === category).map((i) => (
          <MenuItem key={i.id} value={i.subcategory}>
            {i.subcategory}
          </MenuItem>
        ))}
      </Select>
      <div className="w-full">
        <div className="flex w-full flex-col font-[400]">
          <TextField
            fullWidth
            id="item-description"
            placeholder="Payment description (optional)"
            value={description}
            onChange={(e) => updateItemization({ ...item, description: e.target.value })}
          />
        </div>
      </div>
      <div className="flex items-center">
        <div className="flex">
          <div className="w-[150px]">
            <TextField
              fullWidth
              id={id}
              placeholder="0.00"
              value={convertCentsToDollars({ value: amount_cents }).value as number}
              onChange={(e) => onChangeAmount(e)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

interface ItemizationBodyProps {
  itemizations: PayrollItemization[];
  addItem: (categories: {
    category: PayrollItemizationCategory;
    subcategory: PayrollItemizationSubcategory;
  }) => void;
  updateItemization: (updatedItem: PayrollItemization) => void;
  deleteEnabled: boolean;
}
export const PaymentItemizationBody = ({
  addItem,
  itemizations,
  updateItemization,
  deleteEnabled,
}: ItemizationBodyProps): ReactElement => {
  const handleItemAddClick = (name: PayrollItemizationCategory | null) => {
    if (!name) {
      return;
    }
    if (name.startsWith('Bonus')) {
      addItem({
        category: 'Bonus or Advance',
        subcategory: 'Bonus',
      });
    } else if (name.startsWith('Deduction')) {
      addItem({
        category: 'Deduction',
        subcategory: 'Maintenance',
      });
    } else if (name.startsWith('Reimbursement')) {
      addItem({
        category: 'Reimbursement',
        subcategory: 'Hotel & Meals',
      });
    }
  };

  return (
    <>
      {ItemizationCategories.map((category) => {
        const itemsInCategory = itemizations.filter((item) => item.category === category);
        if (itemsInCategory.length === 0) {
          return null;
        }
        return (
          <>
            <div className="text-base font-semibold">{category}</div>
            <div className="flex flex-col">
              {itemsInCategory.map((item) => (
                <div key={item.id} className="flex items-center gap-x-4">
                  <PaymentItemizationRow
                    item={item}
                    updateItemization={updateItemization}
                    deleteEnabled={deleteEnabled}
                  />
                </div>
              ))}
            </div>
            <div className="flex self-end">
              <div className="mr-2 text-base font-semibold">Subtotal</div>
              <div className="text-base font-semibold">
                {formatCurrency({
                  value: sumItemizationCategory(itemsInCategory),
                })}
              </div>
            </div>
          </>
        );
      })}
      <Select
        fullWidth
        value={null}
        onChange={(e) => handleItemAddClick(e.target.value as PayrollItemizationCategory)}
        displayEmpty
        renderValue={(_) => {
          return 'Add Item';
        }}
      >
        {ItemizationCategories.map((category) => (
          <MenuItem key={category} value={category}>
            {category}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};
