import RoundedCard from '@app/components/RoundedCard/RoundedCard';
import { useGetFuelTypeOtherTransactions } from '@app/hooks/query/useIftaFilingQuery';
import { motion } from 'framer-motion';
import { ReactElement, useEffect, useState } from 'react';
import MismatchedTransaction from './MismatchedTransaction';

const FetchingTransaction = (): ReactElement => {
  const [progress, setProgress] = useState(10);
  const [showData, setShowData] = useState(false);

  const { data, isLoading } = useGetFuelTypeOtherTransactions();

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev == 100) {
          setShowData(true);
          clearInterval(interval);
        }
        return prev < 100 ? prev + 15 : 100;
      });
    }, 500);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      {!showData || isLoading ? (
        <RoundedCard className="relative flex-1 py-4">
          <div className="flex h-[50vh] flex-col items-center justify-center bg-white">
            <p className="text-700 mb-1 text-center text-lg">Fetching all your AtoB Fuel</p>
            <p className="text-700 mb-12 text-center text-lg">Transactions for Last Quarter</p>
            <div className="h-2 w-64 overflow-hidden rounded-full bg-gray-300">
              <motion.div
                className="h-full bg-teal-500"
                initial={{ width: '10%' }}
                animate={{ width: `${progress}%` }}
                transition={{ repeat: Infinity, duration: 1, ease: 'linear' }}
              />
            </div>
          </div>
        </RoundedCard>
      ) : (
        <MismatchedTransaction data={data?.data || []} />
      )}
    </>
  );
};

export default FetchingTransaction;
