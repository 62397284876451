import useFeatureFlags from '@app/hooks/useFeatureFlags';
import { faHandsHoldingDollar } from '@fortawesome/pro-regular-svg-icons';
import { MarketingBanner } from './Designs/MarketingBanner';

function WelcomeBonusBanner() {
  const [showBanner] = useFeatureFlags('new_cx_discount_promo');

  if (!showBanner) {
    return null;
  }

  return (
    <MarketingBanner
      bannerKey="onboarding-banner"
      title="Limited time welcome bonus"
      icon={faHandsHoldingDollar}
      nonDismissable
    >
      <p>Enjoy up to 10¢/gal in extra fuel savings on top of our standard discounts.</p>
    </MarketingBanner>
  );
}

export default WelcomeBonusBanner;
