import { DeviceData } from '@app/@types/device.types';
import { ErrorNotification } from '@app/components/layout';
import Modal, {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { LoadingButton } from '@mui/lab';
import axios, { AxiosError } from 'axios';
import { ReactElement, useState } from 'react';

export default function RestoreDeviceModal({
  isActive,
  reset,
  device,
}: {
  isActive: boolean;
  reset: () => void;
  device: DeviceData;
}): ReactElement {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>('');
  const { addToast } = useToasts();

  const restoreDevice = async () => {
    try {
      setIsLoading(true);
      await axios.put(`/devices/${device.id}`, { device: { archived_at: null } });
      reset();
      addToast({
        type: 'success',
        title: `${device.name} successfully restored`,
      });
    } catch (e: unknown) {
      setError((e as AxiosError).message);
    }

    setIsLoading(false);
  };

  return (
    <Modal open={isActive} toggle={reset}>
      <ModalHeader title={`Restore ${device.name}`} onClose={reset} />
      <ModalBodyContent>
        {error && <ErrorNotification error={error} />}
        <div className="mt-4 flex w-full flex-col items-baseline justify-between">
          <p className="mb-4">
            This will restore {device.name} from your archived list of devices.
          </p>
          <p className="text-default-secondary text-sm">
            You can archive it at any time in the future.
          </p>
        </div>
      </ModalBodyContent>
      <ModalFooter>
        <LoadingButton size="small" loading={isLoading} onClick={restoreDevice}>
          Restore {device.name ?? ''}
        </LoadingButton>
      </ModalFooter>
    </Modal>
  );
}
