import PhoneInput from '@app/components/Inputs/PhoneInput';
import FooterActionButtons from '@app/components/Onboarding/FooterActionButtons';
import Header from '@app/components/Onboarding/Header';
import CheckYourPhoneModal from '@app/components/Onboarding/Modals/CheckYourPhoneModal';
import {
  CustomerOnboardingEventName,
  useCreateCustomerOnboardingEvent,
} from '@app/hooks/query/useCustomerOnboardingEvents';
import { useSendAppInviteLinkText } from '@app/hooks/query/useSendAppInviteLinkText';
import useCustomer from '@app/hooks/useCustomer';
import useUser from '@app/hooks/useUser';
import { howToMaximizeDiscountsValidationSchema } from '@app/utils/validation/onboarding-validation';
import { getPhoneWithoutDialingCode } from '@atob-developers/shared/src/utils/formatters/phoneFormat';
import { faSparkles } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip } from '@mui/material';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useOnboardingGuide } from '../useOnboardingGuide';

export default function OnboardingMaximizeDiscounts() {
  const [isOpen, setIsOpen] = useState(false);
  const { owner_phone, company_phone } = useCustomer();
  const user = useUser();

  const { goToPrevStep, goToNextStep } = useOnboardingGuide();
  const { trigger: createOnboardingEvent } = useCreateCustomerOnboardingEvent();

  const { trigger: sendAppInviteLinkText, isMutating: isSendingAppInviteLink } =
    useSendAppInviteLinkText();

  const formik = useFormik({
    initialValues: {
      phone: user?.user?.phone || owner_phone || company_phone || '',
    },
    isInitialValid: false,
    validationSchema: howToMaximizeDiscountsValidationSchema,
    onSubmit: async (values) => {
      try {
        await sendAppInviteLinkText({
          driver: { phone: getPhoneWithoutDialingCode(values.phone) ?? '' },
        });
      } finally {
        setIsOpen(true);
      }
    },
  });

  return (
    <div className="flex flex-1 flex-col gap-6 md:gap-8">
      <Header title="Maximize discounts with AtoB app" />
      <div className="flex flex-col items-start gap-4 md:gap-6">
        <div className="flex flex-col items-start gap-1 md:gap-2">
          <Chip color="green" label="Recommended" icon={<FontAwesomeIcon icon={faSparkles} />} />
          <h3 className="text-default-primary font-semibold">Download the mobile app</h3>
          <p className="text-default-secondary text-sm leading-6">
            Download the mobile app and don’t miss any discount info! Type your phone number and we
            will send you the link to download the app.
          </p>
        </div>
        <form>
          <PhoneInput
            id="phone"
            name="phone"
            label="Your phone number"
            value={formik.values.phone}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={!!formik.errors.phone}
            helperText={formik.errors.phone}
          />
        </form>
      </div>
      <FooterActionButtons
        isDisabled={isSendingAppInviteLink && !isOpen}
        isLoading={isSendingAppInviteLink && !isOpen}
        handleClickBack={goToPrevStep}
        handleClickNext={formik.handleSubmit}
      />
      {isOpen && (
        <CheckYourPhoneModal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          resendMessage={formik.handleSubmit}
          isDisabled={isSendingAppInviteLink}
          handleButtonClick={async () => {
            await createOnboardingEvent({
              customer_onboarding_event: {
                name: CustomerOnboardingEventName.CUSTOMER_OWNER_APP_DOWNLOAD,
              },
            });
            goToNextStep();
          }}
        />
      )}
    </div>
  );
}
