import { MarketingBannerData } from '@app/@types/users.types';
import { useEventDismissed } from '@app/hooks/useEventDismissed';
import { useState } from 'react';
import Markdown from 'react-markdown';
import AmountApplicationModal from '../Modals/MarketingBannerModals/AmountApplicationModal';
import AtoBSavingsPopup from '../Modals/MarketingBannerModals/AtoBSavingsModal';
import { CreditEnablementNetNewModal } from '../Modals/MarketingBannerModals/CreditEnablementNetNewModal';
import EngagedPromotionModal from '../Modals/MarketingBannerModals/EngagedPromotionModal';
import { MarketingBanner } from './Designs/MarketingBanner';

interface MarketingBannerItemProps {
  banner: MarketingBannerData;
}

const MODAL_COMPONENTS: Record<
  string,
  ({ isOpen, closeModal }: { isOpen: boolean; closeModal: () => void }) => JSX.Element | null
> = {
  engaged_promotion_modal: EngagedPromotionModal,
  credit_enablement_modal: CreditEnablementNetNewModal,
  atob_savings_popup: AtoBSavingsPopup,
  amount_application_modal: AmountApplicationModal,
};

const MarketingBannerItem = ({ banner }: MarketingBannerItemProps) => {
  const { dismissed } = useEventDismissed(banner.key);
  const [activeModal, setActiveModal] = useState<string | null>(null);

  if (dismissed) return null;

  const handleActionClick = () => {
    if (!banner.action) return;

    switch (banner.action.type) {
      case 'navigate_internal':
        if (banner.action.path) {
          window.location.href = banner.action.path;
        }
        break;
      case 'navigate_external':
        if (banner.action.url) {
          const url = banner.action.url.startsWith('http')
            ? banner.action.url
            : `https://${banner.action.url}`;
          window.open(url, '_blank', 'noopener,noreferrer');
        }
        break;
      case 'open_modal':
        if (banner.action.modal_id) {
          setActiveModal(banner.action.modal_id);
        }
        break;
      default:
        break;
    }
  };

  const closeModal = () => setActiveModal(null);

  const renderBannerContent = (text: string) => (
    <Markdown
      components={{
        a: ({ href, children, ...props }) => {
          if (href?.startsWith('/')) {
            return (
              <a
                {...props}
                href={href}
                onClick={(event) => {
                  event.preventDefault();
                  window.location.href = href;
                }}
              >
                {children}
              </a>
            );
          }
          return (
            <a {...props} href={href} target="_blank" rel="noopener noreferrer">
              {children}
            </a>
          );
        },
      }}
    >
      {text}
    </Markdown>
  );

  const ModalComponent = activeModal ? MODAL_COMPONENTS[activeModal] : null;

  return (
    <>
      <MarketingBanner
        bannerKey={banner.key}
        title={banner.banner_title}
        nonDismissable={!banner.dismissible}
        {...(banner.action?.type !== 'no_action' && {
          actionText: banner.action?.label,
          onClick: handleActionClick,
        })}
      >
        {renderBannerContent(banner.banner_text)}
      </MarketingBanner>

      {ModalComponent && <ModalComponent isOpen={!!activeModal} closeModal={closeModal} />}
    </>
  );
};

export default MarketingBannerItem;
