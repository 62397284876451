import useThemeMode from '@app/hooks/useThemeMode';
import { faXmark } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { PartnerLogo } from '../layout/PartnerLogo';

type NavbarLogoProps = {
  onClose?: () => void;
};

export default function NavBarLogo({ onClose }: NavbarLogoProps) {
  const { isDarkThemeOn } = useThemeMode();

  return (
    <div
      className={classNames(
        'relative flex items-center',
        onClose ? 'p-6' : 'w-navbar-logo-desktop px-2',
      )}
    >
      {onClose && (
        <IconButton size="small" color="tertiary" onClick={onClose}>
          <FontAwesomeIcon icon={faXmark} className="text-default-primary h-5 w-5" />
        </IconButton>
      )}
      <NavLink
        to="/"
        className={classNames(
          onClose && 'absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2',
        )}
      >
        <PartnerLogo darkStyle={isDarkThemeOn} aria-hidden="true" />
      </NavLink>
    </div>
  );
}
