import classNames from 'classnames';

import React, { ReactElement } from 'react';

import RoundedCard from '../RoundedCard/RoundedCard';

type Classes = {
  root?: string;
  gridContainer?: string;
};

interface TilesContainerProps {
  children: React.ReactNode;
  classes?: Classes;
  header?: React.ReactNode;
  footer?: React.ReactNode;
}

const TilesContainer = ({
  children,
  classes,
  header,
  footer,
}: TilesContainerProps): ReactElement => {
  return (
    <RoundedCard className={classNames('w-full overflow-hidden', classes?.root)}>
      {header}
      <div className={classNames(`grid w-full gap-px`, classes?.gridContainer)}>{children}</div>
      {footer}
    </RoundedCard>
  );
};

export default TilesContainer;
