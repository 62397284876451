import { PayrollTransactionData } from '@app/@types/payroll.types';
import { ErrorNotification } from '@app/components/layout';
import generateCSV from '@app/utils/generate-csv';
import { Button } from '@mui/material';
import dayjs from 'dayjs';
import { ReactElement, useState } from 'react';

import DesktopTransactionsTable from './DesktopTransactionsTable';
import MobileTransactionsTable from './MobileTransactionsTable';

interface TransactionTableProps {
  transactions: PayrollTransactionData[];
  loading?: boolean;
}

export default function PayrollTransactionTable({
  transactions,
  loading,
}: TransactionTableProps): ReactElement {
  const [error, setError] = useState<string | null>(null);

  const exportCSV = () => {
    const csvData = transactions.map((transaction) => {
      // We sanitize the outputs of CSV string data for user provided fields, by adding single quotes around the field.
      // CSV fields surroundeded by single-quote characters force spreadsheet programs to view data as strings instead of formulas
      return {
        'Driver Name': `'${transaction.name}'`,
        'Tax Classification': transaction.tax_classification,
        'Payment Date': dayjs.unix(transaction.payment_date).format('MM-DD-YYYY'),
        'Payment Card': transaction.payment_card,
        'Amount paid': transaction.amount,
        'Fees': transaction.fees,
        'Payment Status': transaction.payment_status,
        'Description': `'${transaction.description}'`,
      };
    });

    generateCSV({
      csvData,
      title: 'Payroll Transactions',
      onError: () => setError('No transactions found for these days.'),
    });
  };

  return (
    <>
      <div className="mb-6 flex w-full items-center justify-between">
        <h2 className="text-default-primary text-2xl font-bold">Transactions</h2>
        <div>
          <Button size="small" onClick={exportCSV}>
            Export
          </Button>
        </div>
      </div>
      {error && <ErrorNotification error={error} />}
      <>
        <div className="hidden flex-col lg:flex">
          <DesktopTransactionsTable
            transactions={transactions}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            loading={loading}
          />
        </div>
        <div className="flex lg:hidden">
          <MobileTransactionsTable transactions={transactions} />
        </div>
      </>
    </>
  );
}
