import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCircleXmark, faUserCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { HTMLAttributes } from 'react';

type DropdownItemProps = {
  name: string;
  selected?: boolean;
  onRemove?: () => void;
  icon?: IconDefinition;
} & HTMLAttributes<HTMLDivElement>;

const DropdownItem = ({ name, onClick, selected, onRemove, icon }: DropdownItemProps) => (
  <div
    className={classNames(
      'hover:bg-green-secondary-hover flex w-full gap-3 rounded-3xl px-4 py-3',
      {
        'bg-strong-secondary': !!onRemove,
        'text-green': selected,
        'text-default-secondary': !selected,
      },
    )}
    onClick={!onRemove ? onClick : undefined}
  >
    <FontAwesomeIcon icon={icon ?? faUserCircle} className="h-6 w-6 self-center" />
    <p
      className={classNames('w-full self-center text-base', {
        'cursor-pointer': !!onClick,
      })}
      onClick={onClick}
    >
      {name}
    </p>
    {!!onRemove && (
      <FontAwesomeIcon
        icon={faCircleXmark}
        className="text-default-secondary h-6 w-6 cursor-pointer"
        onClick={onRemove}
      />
    )}
  </div>
);

export default DropdownItem;
