import { EndpointResponse } from '@app/@types/api.types';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import { useMemo } from 'react';
import useSWR from 'swr';
import { Direction, FeeConfiguration, PaymentMethodType } from './utils';

const inboundMethodMapping: { [key in PaymentMethodType]?: string } = {
  inbound_debit: 'debit',
};

const useFeeConfigurations = (direction: Direction) => {
  const { data: transferMethods, isLoading: loadingMethods } = useSWR<
    EndpointResponse<FeeConfiguration[]>
  >({ url: `/treasury/financial_account/all_fees` }, apiGetFetcher);

  const normalizedFees: FeeConfiguration[] = useMemo(() => {
    if (!transferMethods?.data) return [];

    const processedFeesData = transferMethods.data
      .filter((method) => !method.direction || method.direction === direction)
      .map((method) => {
        return {
          ...method,
          type: (inboundMethodMapping[method.type] || method.type) as PaymentMethodType,
        };
      });

    return processedFeesData;
  }, [transferMethods?.data, direction]);
  return {
    feeConfigurations: normalizedFees,
    loading: loadingMethods,
  };
};

export default useFeeConfigurations;
