import { ChargeEventData } from '@app/@types/charge_events.types';
import { TransactionsFilters } from '@app/constants/transactions';
import { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import TransactionsList from '../../pages/Transactions/TransactionsList';
import RoundedCard from '../RoundedCard/RoundedCard';

interface TransactionsCardProps {
  transactions: ChargeEventData[];
  isLoading: boolean;
}

export default function TransactionsCard({
  transactions,
  isLoading,
}: TransactionsCardProps): ReactElement {
  const cardId = transactions[0]?.card_id;
  const viewAllTransactionsUrl = cardId ? `/transactions?by_card_ids[]=${cardId}` : '/transactions';

  return (
    <RoundedCard>
      <p className="border-soft text-default-primary border-b px-4 pb-8 pt-4 text-lg font-semibold">
        Recent Transactions
      </p>
      <TransactionsList
        currentTab={TransactionsFilters.ALL}
        rows={transactions}
        isLoading={isLoading}
        pageNavPrefix="/transactions/"
      />
      {transactions.length > 0 && (
        <div className="py-4 text-center">
          <Link to={viewAllTransactionsUrl}>
            <span className="text-default-secondary cursor-pointer text-sm underline">
              View All Transactions
            </span>
          </Link>
        </div>
      )}
    </RoundedCard>
  );
}
