import DeviceForm from '@app/components/Devices/DeviceForm';
import { DeviceState, useDeviceFormReducer } from '@app/components/Devices/useDeviceFormReducer';
import DefaultPrompt from '@app/components/Prompt/DefaultPrompt';
import { FetcherKey, postFetcher } from '@app/utils/data/fetchers';
import Modal, {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { countries } from '@atob-developers/shared/src/utils/countries';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import { AxiosError } from 'axios';
import { ReactElement, useState } from 'react';
import useSWRMutation from 'swr/mutation';
interface AddDeviceModalProps {
  open: boolean;
  toggle: () => void;
  refreshData: () => void;
}

type DeviceInfoUpdateStates = 'none' | 'error' | 'successful';

const addDeviceSuccessMessage = 'Device successfully added!';
const addDeviceErrorMessage = 'There was an error adding the device. Please try again.';

const AddDeviceInfoPrompt = ({
  addDeviceState,
  setAddDeviceState,
  message,
}: {
  addDeviceState: DeviceInfoUpdateStates;
  setAddDeviceState: (state: DeviceInfoUpdateStates) => void;
  message?: string;
}) => {
  if (addDeviceState === 'none') {
    return null;
  }

  return (
    <DefaultPrompt
      clickHandler={() => setAddDeviceState('none')}
      error={addDeviceState === 'error'}
      message={
        addDeviceState === 'error' ? message || addDeviceErrorMessage : addDeviceSuccessMessage
      }
    />
  );
};

type CreateDeviceMutationHookParams = {
  device: DeviceState;
};

const AddDeviceModal = ({ open, toggle, refreshData }: AddDeviceModalProps): ReactElement => {
  const reducer = useDeviceFormReducer({
    editing: true,
  });
  const { device, setDevice } = reducer;

  const [error, setError] = useState<string | null>();
  const [addDeviceState, setAddDeviceState] = useState<DeviceInfoUpdateStates>('none');

  const { trigger, isMutating } = useSWRMutation<
    unknown,
    AxiosError,
    FetcherKey,
    CreateDeviceMutationHookParams
  >(
    {
      url: '/devices',
    },
    postFetcher,
    {
      onSuccess: () => {
        refreshData();
        toggle();
        setDevice();
      },
      onError: (e) => {
        const errorMessage = e.response?.data?.error;
        setError(errorMessage || 'Sorry, an unknown error occurred. Please try again later.');
        setAddDeviceState('error');
      },
    },
  );

  const addDeviceHandler = () => {
    const dialingCode = Object.values(countries).find((country) => {
      return device.phone_number?.includes(country.dialingCode);
    })?.dialingCode;

    trigger({
      device: {
        ...device,
        phone_number: device.phone_number?.replace(`${dialingCode} `, '').replace(/\D/g, ''),
      },
    });
  };

  const modalToggle = () => {
    setAddDeviceState('none');
    setDevice(); // Clear state of modal
    toggle();
  };

  return (
    <Modal open={open} toggle={modalToggle}>
      <ModalHeader title="Add New Device" onClose={modalToggle} />
      <ModalBodyContent>
        <AddDeviceInfoPrompt
          setAddDeviceState={setAddDeviceState}
          addDeviceState={addDeviceState}
          message={error || undefined}
        />
        <div className="mb-20 py-4">
          <DeviceForm {...{ reducer }} />
        </div>
      </ModalBodyContent>
      <ModalFooter>
        <div className="flex justify-end gap-3">
          <Button color="secondary" size="small" onClick={modalToggle}>
            Cancel
          </Button>
          <LoadingButton loading={isMutating} size="small" onClick={() => addDeviceHandler()}>
            Add New Device
          </LoadingButton>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default AddDeviceModal;
