import { ChallengeMatch } from '@app/@types/challenge_match_card.types';
import RaceTracIcon from '@app/assets/images/svg/racetrac-logo.svg';
import SpeedwayIcon from '@app/assets/images/svg/speedway-logo.svg';
import TAPetroIcon from '@app/assets/images/svg/ta-petro-logo.svg';
import SpinnerBoundary from '@app/components/Spinner/SpinnerBoundary';
import { Button, Skeleton } from '@mui/material';
import { ReactElement, useState } from 'react';
import ChallengeMatchTracker from './ChallengeMatchTracker';
import WeeklyDiscountBoostModal from './WeeklyDiscountBoostModal';
import { useChallengeMatch } from './useChallengeMatch';

export default function ChallengeMatchCard(): ReactElement {
  const [showModal, setShowModal] = useState(false);
  const { isLoading, challengeMatch, currentDiscountBoost, isTAOnly } = useChallengeMatch();

  return (
    <div className="border-soft bg-soft-primary relative flex-shrink-0 basis-[344px] rounded-lg border">
      <div className="border-soft flex flex-col gap-2 border-b px-5 py-4">
        <ChallengeMatchTitle match={challengeMatch} isLoading={isLoading} isTAOnly={isTAOnly} />
        <div className="border-soft bg-soft-secondary flex items-center justify-between rounded border px-3 py-2">
          <span className="text-default-secondary text-xs">Current discount boost</span>
          <span className="text-default-primary text-sm font-semibold">
            {currentDiscountBoost > 0 ? 'Extra ' : ''}
            {currentDiscountBoost}¢ per gal
          </span>
        </div>
      </div>
      {isLoading || challengeMatch == null ? (
        <SpinnerBoundary className="rounded-lg" />
      ) : (
        <div className="px-5 py-4">
          <ChallengeMatchTracker variant="small" />
          <div className="flex flex-row gap-4 pt-2">
            {isTAOnly ? (
              <div className="flex items-center justify-center gap-1">
                <img src={TAPetroIcon} className="h-10 w-10 flex-shrink-0" />
              </div>
            ) : (
              <div className="flex flex-1 items-center justify-center gap-1">
                <img src={TAPetroIcon} className="h-10 w-10 flex-shrink-0" />
                <img src={RaceTracIcon} className="h-10 w-10 flex-shrink-0" />
                <img src={SpeedwayIcon} className="h-10 w-10 flex-shrink-0" />
              </div>
            )}
            <Button
              className="flex-1"
              size="small"
              color="secondary"
              onClick={() => setShowModal(true)}
            >
              View Details
            </Button>
          </div>
        </div>
      )}
      <WeeklyDiscountBoostModal open={showModal} onClose={() => setShowModal(false)} />
    </div>
  );
}

function ChallengeMatchTitle({
  match,
  isLoading,
  isTAOnly,
}: {
  match: ChallengeMatch | undefined;
  isLoading: boolean;
  isTAOnly: boolean;
}): ReactElement {
  if (isLoading || match == null) {
    return <Skeleton />;
  }
  let title = 'Extra discount boost';
  if (isTAOnly) {
    title = 'Extra boost at TA Stations';
  }
  if (match.partners.length > 1) {
    title = 'Extra boost at TA, RaceTrac, Speedway';
  }
  return (
    <div className="flex items-center gap-1.5">
      <h4 className="text-default-primary text-base font-semibold">{title}</h4>
    </div>
  );
}
