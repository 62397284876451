import { SortType } from '@app/@types/fuel_listings.types';
import useChannelPartner from '@app/hooks/useChannelPartner';
import Constants from '@app/utils/constants';
import logger from '@app/utils/datadog-logger';
import { faCheck, faArrowUpArrowDown } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';

export default function SortSelect({
  disabled,
  selectedSort,
  onSelectSort,
}: {
  disabled: boolean;
  selectedSort: SortType;
  onSelectSort: (sortMetric: SortType) => void;
}) {
  const handleChange = (event: SelectChangeEvent<SortType>) => {
    const sortMetric = event.target.value as SortType;
    logger.info('fuel-listing-filter', {
      key: 'fuel_sort',
      sort: sortMetric,
    });
    onSelectSort(sortMetric);
  };

  const { name } = useChannelPartner();

  return (
    <FormControl className="min-w-min flex-1" disabled={disabled}>
      <InputLabel className="sr-only">Sort</InputLabel>
      <Select
        size="small"
        value={selectedSort}
        onChange={handleChange}
        defaultValue="discountedPrice"
        IconComponent={() => (
          <FontAwesomeIcon
            icon={faArrowUpArrowDown}
            className="text-default-primary pointer-events-none absolute right-2 h-4 w-4"
          />
        )}
        renderValue={(value) =>
          value === 'discountedPrice' ? 'Price' : value === 'distance' ? 'Distance' : 'Recommended'
        }
      >
        {name != Constants.ChannelPartners.EG_AMERICA_CHANNEL_PARTNER_NAME && (
          <MenuItem value="recommended">
            <ListItemText>Recommended</ListItemText>
            {selectedSort === 'recommended' && (
              <ListItemIcon>
                <FontAwesomeIcon icon={faCheck} />
              </ListItemIcon>
            )}
          </MenuItem>
        )}
        <MenuItem value="discountedPrice">
          <ListItemText>Price</ListItemText>
          {selectedSort === 'discountedPrice' && (
            <ListItemIcon>
              <FontAwesomeIcon icon={faCheck} />
            </ListItemIcon>
          )}
        </MenuItem>
        <MenuItem value="distance">
          <ListItemText>Distance</ListItemText>
          {selectedSort === 'distance' && (
            <ListItemIcon>
              <FontAwesomeIcon icon={faCheck} />
            </ListItemIcon>
          )}
        </MenuItem>
      </Select>
    </FormControl>
  );
}
