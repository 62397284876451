import { faCircleCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { ReactElement } from 'react';
import { CreditEnablementNetNewStepsProps } from './CreditEnablementNetNewStepsProps';

export const CreditEnablementNetNewConfirmation = ({
  onNext,
}: CreditEnablementNetNewStepsProps): ReactElement | null => {
  return (
    <div className="flex flex-col items-center gap-8 p-10">
      <div className="text-success mb-3 mt-8">
        <FontAwesomeIcon className="fa-3x" icon={faCircleCheck} />
      </div>
      <h1 className="text-default-primary text-2xl font-bold">Welcome to your new credit line!</h1>
      <p className="text-default-primary w-full text-center text-base">
        We’ll email you when your card ships. Cards typically arrive within 7-10 business days.
      </p>
      <Button fullWidth size="medium" onClick={onNext}>
        Done
      </Button>
    </div>
  );
};
