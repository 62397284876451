import { ModalBodyContent, ModalFooter } from '@atob-developers/shared/src/components/Modal';
import { faCircleInfo, faClock } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { ReactElement } from 'react';
import { SecurityDeposit } from './SecurityDepositOfferModal';

export const SecurityDepositPaymentPending = ({
  securityDeposit,
  onClose,
}: {
  securityDeposit: SecurityDeposit;
  onClose: () => void;
}): ReactElement => {
  return (
    <>
      <ModalBodyContent overflowVisible={true}>
        <div className="flex flex-col items-center">
          <div className="mb-3 mt-8">
            <FontAwesomeIcon className="fa-2x text-default-primary" icon={faClock} />
          </div>
          <h1 className="mb-2 text-center text-2xl font-bold">Thank you!</h1>
          <h2 className="mb-8 text-center text-lg">Your security deposit payment is pending.</h2>
        </div>

        <div className="bg-strong-secondary mb-6 mt-6 rounded-lg p-4">
          <div className="mb-4 flex justify-between">
            <div className="text-default-secondary">Credit Limit</div>
            <div className="text-default-primary font-bold">
              ${securityDeposit.credit_limit_cents / 100}
            </div>
          </div>
          <div className="flex justify-between">
            <div className="text-default-secondary">Security Deposit</div>
            <div className="text-default-primary font-bold">
              ${securityDeposit.amount_cents / 100}
            </div>
          </div>
        </div>

        <div className="bg-strong-secondary mt-6 rounded-lg p-4">
          <div className="flex justify-between">
            <FontAwesomeIcon className="fa-lg text-default-primary p-4" icon={faCircleInfo} />
            <p className="text-default-primary w-full px-4 text-base font-medium">
              {securityDeposit.payment_method_type === 'ach'
                ? 'Your credit line will be activated once your payment is successful. This generally takes 2-3 business days. We will email you when the process succeeds.'
                : 'Your credit line will be activated once your payment is successful. This should only takes a few minutes and we will email you when the process succeeds.'}
            </p>
          </div>
        </div>
      </ModalBodyContent>
      <ModalFooter>
        <Button color="secondary" size="medium" onClick={onClose} fullWidth>
          Done
        </Button>
      </ModalFooter>
    </>
  );
};
