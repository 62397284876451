import { ReactElement } from 'react';

const ExportIFTATransactionsText = (): ReactElement => {
  return (
    <div className="text-default-primary w-full text-sm italic">
      Note: Not all merchants report full fuel data for each transaction, resulting in incomplete
      IFTA exports.{' '}
      <a
        href="https://atobco.zendesk.com/hc/en-us/articles/11227268681243"
        className="underline"
        target="_blank"
        rel="noreferrer"
      >
        Learn more
      </a>
    </div>
  );
};

export default ExportIFTATransactionsText;
