import { EndpointResponse, Entity } from '@app/@types/api.types';
import { DriverData, GenerateUnlockIdResult, ValidationResult } from '@app/@types/driver.types';
import PhoneInput from '@app/components/Inputs/PhoneInput';
import useChannelPartner from '@app/hooks/useChannelPartner';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import useProduct from '@app/hooks/useProduct';
import { FetcherKey, getFetcher, putFetcher } from '@app/utils/data/fetchers';
import logger from '@app/utils/datadog-logger';
import { driverValidationSchema } from '@app/utils/validation/create-driver-validation';
import { SideBarBody, SideBarFooter } from '@atob-developers/shared/src/components/SideBar';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { faLink, faWarning } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { Button, TextField } from '@mui/material';
import { AxiosError } from 'axios';
import { useFormik } from 'formik';
import { capitalize, debounce, throttle } from 'lodash-es';
import { ChangeEvent, FocusEvent, ReactElement, useState } from 'react';
import useSWRMutation from 'swr/mutation';

interface EditDriverSidebarProps {
  setOpen: (val: boolean) => void;
  driver: DriverData;
  onDriversUpdate: (updatedDriver: DriverData) => void;
  enableStreamlinedDriverAppOnboarding: boolean;
}

const DEBOUNCE_TIME_IN_MS = 400;

const EditDriverSidebar = ({
  driver,
  setOpen,
  onDriversUpdate,
  enableStreamlinedDriverAppOnboarding,
}: EditDriverSidebarProps): ReactElement => {
  const [unlockIdWarning, setUnlockIdWarning] = useState<string | null>(null);
  const [disableAutoInvite] = useFeatureFlags('disable_new_phone_welcome_sms');
  const [allowSelfServePins] = useProduct('drivers_unlock_id');
  const { partnerName } = useChannelPartner();
  const { addToast } = useToasts();

  const formik = useFormik({
    initialValues: {
      first_name: driver.first_name,
      last_name: driver.last_name ?? '',
      email: driver.email ?? '',
      phone: driver.phone ?? '',
      unlock_id: driver.unlock_id ?? '',
      notes: driver.notes ?? '',
      external_username: driver.external_username ?? '',
    },
    validationSchema: driverValidationSchema,
    onSubmit: async (values) => {
      const formattedValues = {
        ...values,
        first_name: capitalize(values.first_name),
        last_name: values.last_name ? capitalize(values.last_name) : null,
      };
      await updateDriver({ driver: formattedValues });
    },
  });

  const { trigger: updateDriver, isMutating: isUpdating } = useSWRMutation<
    EndpointResponse<Entity<DriverData>>,
    AxiosError,
    FetcherKey,
    unknown
  >({ url: `/drivers/${driver.id}` }, putFetcher, {
    onSuccess: (res) => {
      const data = {
        ...res.data.attributes,
        first_name: res.data.attributes.first_name,
        last_name: res.data.attributes.last_name,
        email: res.data.attributes.email || null,
      };
      onDriversUpdate(data);
      setOpen(false);
      addToast({ type: 'success', title: 'Driver information successfully updated!' });
    },
    onError: (e) => {
      const error = e.response?.data?.error || 'Sorry, an unknown error occurred';
      addToast({ type: 'error', title: error });
    },
  });

  const { trigger: validateNewUnlockId } = useSWRMutation<
    ValidationResult,
    AxiosError,
    string,
    string
  >(
    '/drivers/validate_new_unlock_id',
    (url, { arg: unlockId }) =>
      getFetcher({ url, params: { unlock_id: unlockId, driver_id: driver.id.toString() } }),
    {
      onSuccess: (res) => {
        setUnlockIdWarning(res?.warning ?? null);
      },
      onError: (e) => logger.error('Error trying to validate a new unlock ID', { error: e }),
    },
  );

  const { trigger: generateNewUnlockId } = useSWRMutation<
    GenerateUnlockIdResult,
    AxiosError,
    FetcherKey,
    unknown
  >({ url: '/drivers/generate_new_unlock_id' }, getFetcher, {
    onSuccess: (res) => {
      formik.setFieldValue('unlock_id', res.unlock_id);
      setUnlockIdWarning(null);
    },
    onError: (e) => {
      logger.error('Error trying to generate a new unlock ID', { error: e });
      addToast({ type: 'error', title: "Couldn't generate unlock ID" });
    },
  });

  const verifyUnlockId = debounce(async (val: string) => {
    validateNewUnlockId(val);
  }, DEBOUNCE_TIME_IN_MS);

  const generateUnlockId = throttle(async () => {
    generateNewUnlockId();
  }, DEBOUNCE_TIME_IN_MS);

  const handleUnlockIdChange = (
    e: ChangeEvent<HTMLInputElement> | FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
  ) => {
    formik.handleChange(e);
    verifyUnlockId(e.target.value);
  };

  return (
    <>
      <SideBarBody>
        <form className="flex flex-col gap-6">
          {!!driver.synchronized && (
            <div className="bg-strong-secondary flex flex-row items-center gap-2 rounded-md p-3 italic">
              <FontAwesomeIcon icon={faLink} className="text-default-primary px-1" />
              Synchronized via {capitalize(driver.source || '')}
            </div>
          )}
          <TextField
            required
            name="first_name"
            size="small"
            label="First Name"
            value={formik.values.first_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.first_name && Boolean(formik.errors.first_name)}
            helperText={formik.touched.first_name && formik.errors.first_name}
          />
          <TextField
            name="last_name"
            size="small"
            label="Last Name"
            value={formik.values.last_name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <TextField
            name="email"
            size="small"
            label="Email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          <div>
            <PhoneInput
              fullWidth
              name="phone"
              size="small"
              label="Phone Number"
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
            />
          </div>
          {allowSelfServePins && (
            <div className="flex flex-col items-start gap-2">
              <TextField
                fullWidth
                name="unlock_id"
                size="small"
                label="Unlock ID"
                value={formik.values.unlock_id}
                onChange={handleUnlockIdChange}
                onBlur={handleUnlockIdChange}
              />
              {unlockIdWarning && (
                <p className="text-warning space-x-1 text-sm">
                  <FontAwesomeIcon icon={faWarning} />
                  <span>{unlockIdWarning}</span>
                </p>
              )}
              <button
                type="button"
                className="text-green text-sm hover:underline"
                onClick={generateUnlockId}
              >
                Generate Unique ID
              </button>
            </div>
          )}
          <TextField
            name="notes"
            size="small"
            label="Notes"
            value={formik.values.notes}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {!!driver.external_username && (
            <TextField
              name="external_username"
              size="small"
              label="External Username"
              value={formik.values.external_username}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          )}
        </form>
        {enableStreamlinedDriverAppOnboarding &&
          formik.values.phone?.replaceAll(/[^\d]/g, '').replace(/^1/, '') !==
            formik.initialValues.phone &&
          !disableAutoInvite && (
            <div className="text-default-secondary my-2 block text-sm font-medium">
              {`Your driver will automatically be eligible to use the ${partnerName} app`}
            </div>
          )}
      </SideBarBody>
      <SideBarFooter>
        <Button size="small" variant="destructive" onClick={() => setOpen(false)}>
          Discard Changes
        </Button>
        <LoadingButton size="small" loading={isUpdating} onClick={() => formik.handleSubmit()}>
          <span>Save Changes</span>
        </LoadingButton>
      </SideBarFooter>
    </>
  );
};

export default EditDriverSidebar;
