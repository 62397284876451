import { faEllipsisVertical } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ReactElement, PropsWithChildren } from 'react';

export default function MerchantRestrictionDialog({
  open,
  handleClose,
}: PropsWithChildren<{
  open: boolean;
  handleClose: () => void;
}>): ReactElement {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Adding Merchant Restriction</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span>To block or whitelist a merchant:</span>
            <ol>
              <li className="text-default-primary">
                1. Transact at the specific merchant once so that it shows up on the Transactions
                page
              </li>
              <li className="text-default-primary">
                2. Go to the Transactions page, and click on the the transaction with the merchant
                you want to block or whitelist
              </li>
              <li className="text-default-primary">
                3. On the Transaction Detail page, click on the
                <span className="bg-strong-secondary mx-2 inline-flex h-[20px] w-[20px] items-center justify-center">
                  <FontAwesomeIcon icon={faEllipsisVertical} />
                </span>
                button, and select Whitelist Merchant, or Blacklist Merchant.
              </li>
            </ol>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" size="medium" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
