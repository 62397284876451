import { BankAccountPaymentMethodCombined } from '@app/@types/bankAccount.types';
import { faBank } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';

type Props = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  openPlaidModal: Function;
  isPlaidReady: boolean;
  bankAccount: BankAccountPaymentMethodCombined;
};

export default function ConnectWithPlaidCard({ openPlaidModal, isPlaidReady, bankAccount }: Props) {
  return (
    <div className="border-soft bg-soft-primary flex w-full flex-col items-center gap-6 rounded-2xl border p-6 md:p-8">
      <div className="flex flex-col items-center gap-4">
        <div className="bg-strong-secondary flex items-center justify-center rounded-full p-3">
          <FontAwesomeIcon icon={faBank} className="text-default-primary h-6 w-6" />
        </div>
        <div className="flex flex-col items-center gap-1">
          <p className="text-default-primary text-center text-lg font-semibold">
            Connect bank account with Plaid
          </p>
          <p className="text-default-secondary text-center text-base">
            Plaid connection is easy, private, and secure. Your data is protected.
          </p>
        </div>
      </div>
      <LoadingButton
        onClick={() => openPlaidModal()}
        size="medium"
        disabled={!isPlaidReady || !!bankAccount}
        loading={!isPlaidReady}
        fullWidth
      >
        Connect bank account
      </LoadingButton>
    </div>
  );
}
