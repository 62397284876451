import { MobileFriendlyTooltip } from '@atob-developers/shared/src/components/MobileFriendlyTooltip';

import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { ReactElement, ReactNode } from 'react';

interface TileProps {
  title: ReactNode;
  value: ReactNode;
  rightValue?: ReactNode;
  tooltip?: string;
  footer?: ReactNode;
}

export function Tile({ title, value, rightValue, tooltip, footer }: TileProps): ReactElement {
  return (
    <TileContainer>
      <div className="flex flex-col justify-between text-sm">
        <div className="flex items-center">
          <div className="text-default-secondary mr-2">{title}</div>
          {tooltip && (
            <MobileFriendlyTooltip title={tooltip}>
              <FontAwesomeIcon icon={faCircleInfo} className="text-default-secondary text-lg" />
            </MobileFriendlyTooltip>
          )}
        </div>
        <div className="text-default-primary mt-2 flex w-full items-center justify-between">
          <div className="text-2xl font-semibold tracking-tight">{value}</div>{' '}
          <div>{rightValue}</div>
        </div>
        {footer}
      </div>
    </TileContainer>
  );
}

export function CollapsibleTile({ title, value, tooltip, footer }: TileProps): ReactElement {
  return (
    <TileContainer className="py-1">
      <div className="flex flex-col justify-between text-sm">
        <div className="flex items-center">
          <div className="text-default-secondary mr-2">{title}</div>
          {tooltip && (
            <MobileFriendlyTooltip title={tooltip}>
              <FontAwesomeIcon icon={faCircleInfo} className="text-default-secondary text-lg" />
            </MobileFriendlyTooltip>
          )}
        </div>
        <div className="text-default-primary mt-2 flex w-full items-center justify-between">
          <div className="text-2xl font-semibold tracking-tight">{value}</div>
        </div>
        {footer}
      </div>
    </TileContainer>
  );
}

export const TileContainer = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}): ReactElement => (
  <div className={classNames('bg-soft-primary flex h-full w-full flex-col p-4 md:p-6', className)}>
    {children}
  </div>
);
