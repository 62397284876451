import currencyFormat from '@atob-developers/shared/src/utils/formatters/currencyFormat';
import classNames from 'classnames';
import { ReactElement } from 'react';
import CardRow from './CardRow';

type CurrencyTypeConfiguration = {
  [key in string]: string;
};

const CURRENCY_TYPE_COLORS: CurrencyTypeConfiguration = {
  base: 'text-default-primary',
  plus: 'text-green',
  minus: 'text-red',
  pending: 'text-default-secondary',
};

const getCurrencyPrefix = (balance: string | number): string => {
  if (typeof balance === 'string')
    switch (balance.charAt(0)) {
      case '+':
        return 'plus';
      case '-':
        return 'minus';
      case '$':
        return 'currency';
      default:
        return 'base';
    }
  return 'number';
};

const PriceDetailRow = ({
  title,
  balance,
}: {
  title: string;
  balance: string | number;
}): ReactElement => (
  <CardRow>
    <p className="text-default-primary text-sm">{title}</p>
    <p
      className={classNames(
        'text-default-primary text-sm',
        CURRENCY_TYPE_COLORS[getCurrencyPrefix(balance)],
      )}
    >
      {typeof balance === 'string'
        ? balance
        : currencyFormat({ value: balance, onlyWholeNumber: true })}
    </p>
  </CardRow>
);

export default PriceDetailRow;
