import { EndpointResponse } from '@app/@types/api.types';
import { FinancialAccountData } from '@app/@types/payments.types';
import SpinnerBoundary from '@app/components/Spinner/SpinnerBoundary';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import logger from '@app/utils/datadog-logger';
import { faCheckCircle, faXmarkCircle } from '@fortawesome/pro-regular-svg-icons';
import { ReactElement, useState } from 'react';
import useSWR from 'swr';
import PaymentStatusContent from '../PaymentErrors/PaymentStatusContent';
import WalletEditPaymentDetails from './WalletEditPaymentDetails';
import WalletReviewPaymentDetails from './WalletReviewPaymentDetails';
import AddWallet from './components/AddWallet';
interface WalletControllerProps {
  setShowPaymentSelection: (showPaymentSelection: boolean) => void;
  closeModal: () => void;
  paymentAmount: string;
  setPaymentAmount: (paymentAmount: string) => void;
  showAmountDue: boolean;
  setShowAmountDue: (showAmountDue: boolean) => void;
}

const WalletController = ({
  closeModal,
  setShowPaymentSelection,
  paymentAmount,
  setPaymentAmount,
  showAmountDue,
  setShowAmountDue,
}: WalletControllerProps): ReactElement | null => {
  const [paymentScreen, setPaymentScreen] = useState<'edit' | 'review' | 'success' | 'error'>(
    'edit',
  );

  const {
    error: walletError,
    data: walletData,
    isLoading: isLoadingWallet,
  } = useSWR<EndpointResponse<FinancialAccountData>>(
    { url: '/treasury/financial_account' },
    apiGetFetcher,
  );

  if (isLoadingWallet) {
    return (
      <div className="w-full">
        <SpinnerBoundary />
      </div>
    );
  }

  if (walletError || !walletData) {
    logger.warn('Error loading Wallet payment modal', {
      walletError,
    });
    return <AddWallet />;
  }

  const onNext = () => {
    setShowPaymentSelection(false);
    setPaymentScreen('review');
  };

  const onBack = () => {
    setShowPaymentSelection(true);
    setPaymentScreen('edit');
  };

  switch (paymentScreen) {
    case 'edit':
      logger.info('Loading Wallet edit payment modal');
      return (
        <WalletEditPaymentDetails
          onNext={onNext}
          closeModal={closeModal}
          paymentAmount={paymentAmount}
          setPaymentAmount={setPaymentAmount}
          walletData={walletData.data}
          showAmountDue={showAmountDue}
          setShowAmountDue={setShowAmountDue}
        />
      );
    case 'review':
      logger.info('Loading Wallet review payment modal');
      return (
        <WalletReviewPaymentDetails
          setPaymentScreen={setPaymentScreen}
          onBack={onBack}
          paymentAmount={paymentAmount}
        />
      );
    case 'success':
      return (
        <PaymentStatusContent
          title="Success"
          icon={faCheckCircle}
          message="We've received your payment request. Please allow 1-2 minutes for this payment to reflect on your account and show on your Payment History page."
          iconClassName="text-success"
          showButton
        />
      );
    case 'error':
      return (
        <PaymentStatusContent
          title="Error processing your Wallet Payment"
          icon={faXmarkCircle}
          message="There was an error processing your payment. Please contact support."
          iconClassName="text-error"
        />
      );
    default:
      return null;
  }
};

export default WalletController;
