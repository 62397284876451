import { InstantPayoutMethod } from '@app/@types/instant_payout_method.types';
import PaypalLogo from '@app/assets/images/svg/paypal-logo.svg';
import VenmoLogo from '@app/assets/images/svg/venmo-logo.svg';
import ZelleLogo from '@app/assets/images/svg/zelle-logo.svg';
import { guardAxiosError } from '@app/utils/error/guards';
import {
  convertCentsToDollars,
  formatCurrency,
} from '@atob-developers/shared/src/utils/formatters/currencyFormat';
import {
  faBuildingColumns,
  faCreditCardBlank,
  faGlobe,
  IconDefinition,
} from '@fortawesome/pro-regular-svg-icons';
import * as Sentry from '@sentry/react';
import { StripeError } from '@stripe/stripe-js';
import axios from 'axios';
import { mutate } from 'swr';
import { RecipientToEdit } from './transfer.types';

export type PaymentMethodType =
  | 'ach'
  | 'debit'
  | 'us_domestic_wire'
  | 'zelle'
  | 'paypal'
  | 'venmo'
  | 'inbound_debit';
export type TransferKind = 'external_transfer' | 'own_transfer' | 'deposit';
export type WithdrawalState = 'initial' | 'confirmation' | 'success';

export const nameMapping: Record<PaymentMethodType, string> = {
  ach: 'ACH transfer',
  debit: 'Debit card',
  us_domestic_wire: 'Wire transfer',
  zelle: 'Zelle',
  paypal: 'PayPal',
  venmo: 'Venmo',
  inbound_debit: 'Debit card',
};

export const accountNameMapping: Record<PaymentMethodType, string> = {
  ach: 'account',
  debit: 'debit card',
  us_domestic_wire: 'account',
  zelle: 'Zelle account',
  paypal: 'PayPal account',
  venmo: 'Venmo Account',
  inbound_debit: 'debit card',
};

export const faIconMapping: Record<'ach' | 'debit' | 'us_domestic_wire', IconDefinition> = {
  ach: faBuildingColumns,
  debit: faCreditCardBlank,
  us_domestic_wire: faGlobe,
};

export const iconMapping: Record<'zelle' | 'venmo' | 'paypal', string> = {
  zelle: ZelleLogo,
  venmo: VenmoLogo,
  paypal: PaypalLogo,
};

export const transferTimeMapping: Record<PaymentMethodType, string> = {
  ach: '1-3 business days',
  debit: 'Instant',
  us_domestic_wire: '1 business day',
  zelle: 'Instant',
  paypal: 'Instant',
  venmo: 'Instant',
  inbound_debit: 'Instant',
};

export const estimatedReceptionTimeMapping: Record<PaymentMethodType, string> = {
  ach: 'within 3 business days',
  debit: 'shortly',
  us_domestic_wire: 'within 1 business day',
  zelle: 'shortly',
  paypal: 'shortly',
  venmo: 'shortly',
  inbound_debit: 'shortly',
};

export const estimatedArrivalTimeMapping: Record<PaymentMethodType, number> = {
  ach: 3,
  debit: 0,
  us_domestic_wire: 1,
  zelle: 0,
  paypal: 0,
  venmo: 0,
  inbound_debit: 0,
};

export const transferTypeMapping = {
  ach: 'ACH transfer',
  us_domestic_wire: 'Wire transfer',
  debit: 'Debit card',
  zelle: 'Zelle transfer',
  paypal: 'PayPal',
  venmo: 'Venmo',
  inbound_debit: 'Debit card',
};

export type Direction = 'inbound' | 'outbound';

export type FeeConfiguration = {
  type: PaymentMethodType;
  cents: number;
  percentage: number;
  direction: Direction;
  max: number | null;
};

export type AccountType =
  | 'bank_account'
  | 'debit_card'
  | 'zelle_account'
  | 'paypal_account'
  | 'venmo_account';

export type RecipientType = 'external' | 'own';

export type NormalizedDestination = {
  id: string;
  name: string; // main identifier
  alternateName: string | null; // secondary identifier (nickname, expiration date, etc)
  supportedNetworks: PaymentMethodType[];
  instant: boolean;
  lastTransferredAt: string | null;
  accountType: PaymentMethodType | null;
  recipientType: RecipientType;
  type: AccountType;
  lastFour: string;
  brand: string | null; // used for debit cards only. e.g. Visa, Mastercard
  recipientName: string | null; // used for external recipients only
  needsAddress: boolean;
  state: string | null; // used for bank accounts only
};

export type NormalizedSource = NormalizedDestination;

export const feeString = (fee: FeeConfiguration): string => {
  const percentage = fee.percentage > 0 ? `${fee.percentage}%` : null;
  // eslint-disable-next-line @typescript-eslint/no-base-to-string, @typescript-eslint/restrict-template-expressions
  const amount = fee.cents > 0 ? `$${convertCentsToDollars({ value: fee.cents })}` : null;

  const max = fee.max
    ? `, up to ${formatCurrency({ value: fee.max, options: { fromCents: true } })}`
    : null;

  let feeString = [amount, percentage].filter(Boolean).join(' + ');

  if (!feeString) {
    return 'Free';
  }

  if (max) {
    feeString += max;
  }

  return feeString;
};

export const formatAmountString = (amount: string) => {
  const numberRegex = /^\d*\.?\d*$/;
  if (numberRegex.test(amount)) {
    const parts = amount.split('.');
    if (parts[1] && parts[1].length > 2) {
      // Limit to two decimal places
      return parseFloat(amount).toFixed(2);
    } else {
      return amount;
    }
  } else {
    return '';
  }
};

export const parseAmountString = (amount: string) => {
  const parsedAmount = parseFloat(amount.replace(/[$,]/g, '').trim());
  if (isNaN(parsedAmount)) {
    return 0;
  }
  return parsedAmount;
};

export const moneyFormat = (amount: number | bigint) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    useGrouping: true,
    maximumFractionDigits: 2,
  });

  return formatter.format(amount);
};

export const filterMethodsByTabValue = (methods: FeeConfiguration[], transferKind: TransferKind) =>
  methods.filter((method) => transferKind !== 'external_transfer' || method.type !== 'debit');

export const filterSourcesBySelectedMethod = (
  destinations: NormalizedSource[],
  method: PaymentMethodType | null,
) => {
  if (method === 'debit') {
    return destinations.filter((destination) => destination.type === 'debit_card');
  } else if (method === 'ach') {
    return destinations.filter((destination) => destination.type === 'bank_account');
  }

  return [];
};

export const filterDestinationsBySelectedMethod = (
  destinations: NormalizedDestination[],
  method: PaymentMethodType | null,
) => {
  if (!method) {
    return [];
  }

  if (method === 'debit') {
    return destinations.filter((destination) => destination.type === 'debit_card');
  } else if (method === 'zelle') {
    return destinations.filter((destination) => destination.type === 'zelle_account');
  } else if (method === 'paypal') {
    return destinations.filter((destination) => destination.type === 'paypal_account');
  } else if (method === 'venmo') {
    return destinations.filter((destination) => destination.type === 'venmo_account');
  } else {
    return destinations.filter((destination) => destination.type === 'bank_account');
  }
};

export const computeTotalFeeAmount = ({
  transferAmountCents,
  feeData,
}: {
  transferAmountCents: number;
  feeData: FeeConfiguration | null;
}): number => {
  if (!feeData) {
    return 0;
  }

  const feeAmount = Math.round(transferAmountCents * feeData.percentage) / 100 + feeData.cents;

  return feeData.max ? Math.min(feeData.max, feeAmount) : feeAmount;
};

export const computeMaxAmountWithFee = ({
  amountRequested,
  feeConfiguration,
}: {
  amountRequested: number;
  feeConfiguration?: FeeConfiguration;
}): number => {
  if (!feeConfiguration) {
    return amountRequested / 100;
  }

  const { percentage, cents: fixedFee, max } = feeConfiguration;
  const originalAmount = Math.floor((amountRequested - fixedFee) / (1 + percentage / 100)) / 100;
  const maxAmount = max ? Math.floor(amountRequested - max) / 100 : 0;

  return Math.max(0, originalAmount, maxAmount);
};

export const computeEstimatedReceptionTime = (paymentMethod: PaymentMethodType): Date => {
  const today = new Date();
  const daysToAdd = estimatedArrivalTimeMapping[paymentMethod];

  if (daysToAdd === 0) return today;

  const result = new Date(today);

  for (let addedDays = 0; addedDays < daysToAdd; ) {
    result.setDate(result.getDate() + 1);
    if (result.getDay() !== 0 && result.getDay() !== 6) addedDays++;
  }

  return result;
};

export const withdrawFunds = async (
  amount: string,
  description: string,
  idempotentKey: string,
  method: FeeConfiguration,
  accountId: string,
  selectedTransferType: TransferKind,
) => {
  const amountCents = Math.round(parseFloat(amount) * 100);
  await axios.post('/treasury/financial_account/transfer', {
    idempotency_key: idempotentKey,
    amount: amountCents,
    description,
    method: method.type,
    fee: computeTotalFeeAmount({ transferAmountCents: amountCents, feeData: method }),
    [selectedTransferType === 'own_transfer' && method.type == 'debit'
      ? 'payment_method_id'
      : 'recipient_id']: accountId,
  });
};

export const editRecipient = async (recipient: RecipientToEdit) => {
  await axios.patch(`/treasury/recipients/${recipient.id}`, { ...recipient });
  await mutate('/treasury/recipients');
};

export const addInstantPayoutMethodAccount = async (
  instant_payout_method: Omit<InstantPayoutMethod, 'id' | 'last_transferred_at'>,
) => {
  await axios.post('/treasury/instant_payout_methods', { instant_payout_method });
  await mutate({ url: '/treasury/instant_payout_methods' });
};

const createReadableStripeError = (error: unknown) => {
  const stripeError = error as StripeError;
  if (stripeError.type !== 'card_error') {
    return stripeError.message ?? 'Something went wrong, please contact support.';
  }
  switch (stripeError.code) {
    case 'card_declined':
      return 'Payment declined by the issuer.';
    case 'expired_card':
      return 'This card is expired. Please add another one and try again.';
    default:
      return 'This card cannot be used. Please add another one and try again.';
  }
};

export const createReadableError = (error: unknown) => {
  Sentry.captureException(error);
  const defaultError = 'Something went wrong. Please try again.';
  const isStripeError = (error: unknown): error is StripeError => {
    return (error as StripeError).type !== undefined;
  };

  if (!isStripeError(error)) {
    if (!guardAxiosError(error)) {
      return defaultError;
    } else {
      return `There was an error: ${error?.response?.data?.errors?.[0] || defaultError}`;
    }
  }
  return createReadableStripeError(error);
};

export const totalTransferAmount = (
  amount: number,
  feeAmount: number,
  direction: Direction,
): number => {
  return direction === 'outbound' ? amount + feeAmount : amount - feeAmount;
};
