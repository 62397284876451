import { SecurityDepositNetNewOfferDetails } from '@app/components/Banner/SecurityDepositNetNewBanner';
import { SecurityDepositOfferDetails } from '@app/components/Modals/SecurityDepositModals/SecurityDepositOfferDetails';
import FooterActionButtons from '@app/components/Onboarding/FooterActionButtons';
import RadioSelector from '@app/components/RadioSelector/RadioSelector';
import useSecurityDeposit from '@app/hooks/useSecurityDeposit';
import Modal from '@atob-developers/shared/src/components/Modal';
import { formatCurrency } from '@atob-developers/shared/src/utils/formatters';
import { RadioGroup, Chip, Divider } from '@mui/material';
import IssuesDisclaimer from './IssuesDisclaimer';
import { useOnboardingGuide } from './useOnboardingGuide';

export default function PaySecurityDeposit({
  offerDetails,
  refetchOfferDetails,
}: {
  offerDetails: SecurityDepositNetNewOfferDetails;
  refetchOfferDetails: () => void;
}) {
  const { goToPrevStep, goToNextStep } = useOnboardingGuide();
  const {
    securityDepositModalOpen,
    setSecurityDepositModalOpen,
    fixedOffer,
    desiredCreditLine,
    setDesiredCreditLine,
    isLoading,
    handleContinue,
  } = useSecurityDeposit({
    offerDetails,
    refetchOfferDetails,
  });

  const creditLimitOptions = [
    {
      value: 500,
      label: '$500',
      depositAmount: (500 * (offerDetails?.deposit_percentage || 30)) / 100,
    },
    ...Array.from({ length: (offerDetails.max_credit_limit || 4000) / 1000 }, (_, index) => ({
      value: (index + 1) * 1000,
      label: `$${(index + 1) * 1000}`,
      depositAmount: fixedOffer
        ? offerDetails.deposit_amount || 300
        : ((index + 1) * 1000 * (offerDetails?.deposit_percentage || 30)) / 100,
    })),
  ];

  return (
    <>
      <div className="flex flex-col gap-2">
        {!fixedOffer && (
          <>
            <p className="text-default-primary text-lg font-semibold">
              Select your desired credit limit
            </p>
            <RadioGroup className="gap-2">
              {creditLimitOptions.map((limitOption) => {
                return (
                  <RadioSelector
                    key={limitOption.value}
                    value={limitOption.value}
                    label={
                      <div className="flex gap-2">
                        <p className="text-base font-medium">{limitOption.label}</p>
                        <Chip
                          color="grey"
                          size="small"
                          label={`$${limitOption.depositAmount ?? 0} deposit`}
                        />
                      </div>
                    }
                    onChange={() => setDesiredCreditLine(limitOption.value)}
                    labelPlacement="start"
                    checked={limitOption.value === desiredCreditLine}
                  />
                );
              })}
            </RadioGroup>
          </>
        )}
        {fixedOffer && (
          <>
            <p className="text-default-primary text-lg font-semibold">
              Please accept your security deposit below
            </p>
            <RadioGroup className="gap-2">
              <RadioSelector
                value={1}
                label={
                  <div className="flex gap-2">
                    <p className="text-base font-medium">
                      {formatCurrency({
                        value: offerDetails.credit_limit || 0,
                        options: { fromCents: false },
                      })}
                    </p>
                    <Chip
                      color="grey"
                      size="small"
                      label={`$${offerDetails.deposit_amount ?? 0} deposit`}
                    />
                  </div>
                }
                onChange={() => setDesiredCreditLine(offerDetails.credit_limit || 0)}
                labelPlacement="start"
                checked={offerDetails.credit_limit === desiredCreditLine}
              />
            </RadioGroup>
          </>
        )}
      </div>
      <Divider flexItem />
      <IssuesDisclaimer />
      <FooterActionButtons
        handleClickBack={goToPrevStep}
        handleClickNext={handleContinue}
        isLoading={isLoading}
        isDisabled={!desiredCreditLine}
      />
      <Modal
        open={securityDepositModalOpen}
        toggle={() => {
          setSecurityDepositModalOpen(false);
        }}
      >
        <SecurityDepositOfferDetails
          securityDeposit={offerDetails.existing_deposit || null}
          onClose={() => {
            setSecurityDepositModalOpen(false);
          }}
          continueOnboarding={goToNextStep}
        />
      </Modal>
    </>
  );
}
