import { TextField } from '@mui/material';
import { SetStateAction } from 'react';

export const DescriptionInput = ({
  description,
  setDescription,
}: {
  description: string;
  setDescription: (value: SetStateAction<string>) => void;
}) => (
  <TextField
    label={
      <p>
        Description <span className="text-default-secondary">(optional)</span>
      </p>
    }
    multiline
    rows={4}
    variant="outlined"
    fullWidth
    onChange={(e) => setDescription(e.target.value)}
    value={description}
    placeholder="Describe the reason of this transaction..."
  />
);
