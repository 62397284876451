import currencyFormat, {
  convertDollarsToCents,
  convertCentsToDollars,
} from '@atob-developers/shared/src/utils/formatters/currencyFormat';
import { faDollar } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TextField } from '@mui/material';
import { ReactElement, useEffect, useState } from 'react';

export const PAYOUT_LIMIT_CENTS = 999900;
export const PAYOUT_LIMIT_DOLLARS = convertCentsToDollars({
  value: PAYOUT_LIMIT_CENTS,
});
export const FORMATTED_LIMIT = currencyFormat({
  value: PAYOUT_LIMIT_DOLLARS,
});

const centsToDollarsInString = (value: number): string => {
  const valueInDollars = convertCentsToDollars({
    value,
  }).value;
  return value ? valueInDollars.toString() : '';
};
export default function PayAmountInput({
  amountToPayDriver,
  setAmountToPayDriver,
}: {
  amountToPayDriver: number;
  setAmountToPayDriver: (amount: number) => void;
}): ReactElement {
  const [warning, setWarning] = useState<string>('');

  const [amount, setAmount] = useState(centsToDollarsInString(amountToPayDriver));

  useEffect(() => {
    //After payment completes, this will reset the input value
    setAmount(centsToDollarsInString(amountToPayDriver));
  }, [amountToPayDriver, setAmount]);

  const onChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const { value } = e.target;
    const isNotANumber = Number.isNaN(parseFloat(value));
    const isNotEmpty = value !== '';

    //Prevents invalid characters like text
    if (isNotANumber && isNotEmpty) {
      return;
    }

    const amountToPayDriverInCents = convertDollarsToCents({ value: parseFloat(value) })
      .value as number;

    //Prevents input past payout limit
    if (amountToPayDriverInCents >= PAYOUT_LIMIT_CENTS) {
      setWarning && setWarning(`Must be less than ${FORMATTED_LIMIT}`);
      return;
    }
    setWarning && setWarning('');

    setAmount(value);
    setAmountToPayDriver(amountToPayDriverInCents);
  };

  return (
    <TextField
      size="small"
      type="number"
      value={amount}
      onChange={onChangeAmount}
      name="amount-to-pay-driver"
      id="amount-to-pay-driver"
      placeholder="0.00"
      helperText={warning}
      slotProps={{
        input: {
          startAdornment: <FontAwesomeIcon icon={faDollar} className="text-default-primary pr-2" />,
        },
      }}
    />
  );
}
