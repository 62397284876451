import { EndpointResponse } from '@app/@types/api.types';
import { InstantPayoutRecipient } from '@app/@types/instant_payout_method.types';
import { apiGetFetcher } from '@app/utils/data/fetchers';
import { Drawer } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import useSWR from 'swr';
import RecipientSelector from './RecipientSelector';
import TransferDetailsForm from './TransferDetailsForm';
import { WalletTransferContext, WalletTransferStep } from './useWalletTransfer';

export const WithdrawFundsSidebarNew = ({
  open,
  reset,
  onCompletedTransfer,
  walletBalance,
}: {
  open: boolean;
  reset: () => void;
  onCompletedTransfer: () => void;
  walletBalance?: string | null;
}) => {
  const [step, setStep] = useState<WalletTransferStep>(WalletTransferStep.Recipient);
  const [selectedRecipientID, setSelectedRecipientID] = useState<
    InstantPayoutRecipient['id'] | null
  >(null);

  const {
    data: instantPayoutRecipients,
    isLoading: isFetchingRecipients,
    mutate: refetchRecipients,
  } = useSWR<EndpointResponse<InstantPayoutRecipient[]>>(
    { url: '/instant_payout/recipients?include=treasury_recipients,instant_payout_methods' },
    apiGetFetcher,
  );

  const resetAndClose = useCallback(() => {
    setStep(WalletTransferStep.Recipient);

    reset();
  }, [reset]);

  const contextVal = useMemo(
    () => ({
      step,
      goNextStep: (s?: WalletTransferStep) =>
        setStep((p) => {
          if (s != null) {
            return s;
          } else {
            return p + 1;
          }
        }),
      goPrevStep: (s?: WalletTransferStep) =>
        setStep((p) => {
          if (s != null) {
            return s;
          } else {
            return p - 1;
          }
        }),

      resetAndClose,
      onCompletedTransfer,
      walletBalance,
      recipients: instantPayoutRecipients?.data ?? [],
      isFetchingRecipients,
      refetchRecipients,
      selectedRecipient:
        (instantPayoutRecipients?.data ?? []).find(
          (recipient) => recipient.id == selectedRecipientID,
        ) ?? null,
      selectedRecipientID,
      setSelectedRecipientID,
      resetSelectedRecipient: () => setSelectedRecipientID(null),
    }),
    [
      step,
      resetAndClose,
      onCompletedTransfer,
      walletBalance,
      instantPayoutRecipients?.data,
      isFetchingRecipients,
      refetchRecipients,
      selectedRecipientID,
    ],
  );

  return (
    <WalletTransferContext.Provider value={contextVal}>
      <Drawer open={open} onClose={resetAndClose} anchor="right">
        <div className="bg-soft-primary flex h-screen w-screen flex-col overflow-y-auto md:max-w-xl">
          {step === WalletTransferStep.Recipient && <RecipientSelector />}
          {step === WalletTransferStep.TransferDetails && <TransferDetailsForm />}
        </div>
      </Drawer>
    </WalletTransferContext.Provider>
  );
};
