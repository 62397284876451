import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement, useEffect, useRef } from 'react';

export default function TelematicsConnectedSuccessPage(): ReactElement {
  const sendSucceed = useRef(false);
  useEffect(() => {
    if (!sendSucceed.current) {
      window.opener.postMessage('success');
      sendSucceed.current = true;
    }
  });
  return (
    <div className="flex flex-col items-center p-6">
      <div className="text-default-primary mb-3">
        <FontAwesomeIcon className="fa-4x " icon={faCheckCircle} />
      </div>
      <h2 className="text-default-primary mb-4 text-2xl font-bold">Connection complete</h2>
      <p className="text-default-primary mb-4 text-lg">
        It can take up to a couple of minutes for your data to sync. You can close this window and
        check back later.
      </p>
      <p className="text-default-primary">Please return back to the previous page</p>
    </div>
  );
}
