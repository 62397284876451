import { TruckType } from '@app/@types/telematics.types';
import { truckIsIdle } from '@app/utils/Telematics/TelematicsMapsUtils';
import { faLocationArrow, faCircle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Coords } from 'google-map-react';

interface RenderPrimaryMarkerProps {
  name: string;
  vin: string;
  onClick?: () => void;
  isMatchedVehicle?: boolean;
}

interface VehicleLabelProps {
  name: string;
  vin: string;
  className?: string;
}

export const VehicleLabel = ({ name, vin, className }: VehicleLabelProps) => {
  return (
    <div className={className}>
      <div className="text-center text-sm font-bold">{name}</div>
      <div className="text-center text-xs">{vin}</div>
    </div>
  );
};

const MapLabel = ({ name, vin, onClick, isMatchedVehicle }: RenderPrimaryMarkerProps) => {
  return (
    <div
      className={classNames(
        'map-marker-text border-2 shadow-md',
        isMatchedVehicle && 'border-success',
      )}
      onClick={onClick}
    >
      <VehicleLabel name={name} vin={vin} />
    </div>
  );
};

interface DriverMarkerProps extends Coords {
  selectTruck: (truck: TruckType, setError: ((e: unknown) => void) | null) => void;
  truck: TruckType;
}

const DriverMarker = ({ selectTruck, truck }: DriverMarkerProps) => {
  const isIdle = truckIsIdle(truck);

  return (
    <>
      <FontAwesomeIcon
        style={{
          transform: `rotate(${(truck.location?.bearing || 45) - 45}deg)`,
          left: '-7px',
          top: '-9px',
        }}
        icon={isIdle ? faCircle : faLocationArrow}
        className={`${isIdle ? 'location-circle' : 'location-arrow'}`}
      />
      <MapLabel name={truck.name} vin={truck.vin} onClick={() => selectTruck(truck, null)} />
    </>
  );
};

export type VehicleLocationType = {
  vin: string;
  latitude: string;
  longitude: string;
  bearing: number;
  name: string;
  isMatchedVehicle?: boolean;
};

export { DriverMarker, MapLabel as RenderPrimaryMarker };
