import '@atob-developers/shared/src/modules/mui.d.ts';
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles';
import { ThemeConfiguration } from './themeConfigurations';

export default function ThemeProvider({
  children,
  themeConfiguration,
}: {
  children: React.ReactNode;
  themeConfiguration: ThemeConfiguration;
}) {
  return <MUIThemeProvider theme={themeConfiguration.muiTheme}>{children}</MUIThemeProvider>;
}
