import { PaginationMeta } from '@app/@types/api.types';
import { DEFAULT_PAGE_SIZE } from '@app/hooks/paging/types';
import { Pagination as MuiPagination } from '@mui/material';
import tw from 'twin.macro';

type PaginationProps = {
  paginationMeta?: PaginationMeta;
  pageSize?: number;
  onPageChange?: (page: number) => void;
};

export default function Pagination({ paginationMeta, pageSize, onPageChange }: PaginationProps) {
  const rowCount = paginationMeta?.pagination.total_count ?? 0;
  const currentPage = paginationMeta?.pagination.current_page ?? 1;
  const totalPages = rowCount && Math.ceil(rowCount / (pageSize ?? DEFAULT_PAGE_SIZE));

  const getPageItemsRange = () => {
    if (paginationMeta?.pagination.total_count === 0) {
      return '0 to 0';
    }

    const start = (currentPage - 1) * (pageSize ?? DEFAULT_PAGE_SIZE) + 1;
    const end = Math.min(start + (pageSize ?? DEFAULT_PAGE_SIZE) - 1, rowCount);
    return `${start} to ${end}`;
  };

  if (!paginationMeta) {
    return null;
  }

  return (
    <div className="flex justify-center pb-3 pl-5 pr-5 pt-3 md:justify-between">
      <div className="text-default-secondary hidden pt-1 text-sm md:block">
        Showing{' '}
        <span className="text-default-primary font-medium">{`${getPageItemsRange()} of ${rowCount} results`}</span>
      </div>
      <MuiPagination
        className="w-full md:w-auto"
        page={currentPage || 1}
        onChange={(_, page) => onPageChange?.(page)}
        count={totalPages}
        shape="rounded"
        sx={{
          '.MuiButtonBase-root': {
            ...tw`font-medium text-sm text-default-secondary hover:text-default-primary bg-soft-primary hover:bg-soft-primary-hover active:bg-soft-primary-clicked`,
          },
          '& .Mui-selected': {
            ...tw`text-default-primary bg-strong-secondary hover:bg-strong-secondary-hover active:bg-strong-secondary-clicked`,
          },
          '& .MuiPagination-ul': {
            ...tw`flex justify-between`,
          },
        }}
      />
    </div>
  );
}
