import {
  CustomerOnboardingEventName,
  useCreateCustomerOnboardingEvent,
} from '@app/hooks/query/useCustomerOnboardingEvents';
import Modal, { ModalFooter, ModalHeader } from '@atob-developers/shared/src/components/Modal';
import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export default function DriverInvitationConfirmationModal({ isOpen, onClose }: Props) {
  const { trigger: createOnboardingEvent, isMutating: isCreatingOnboardingEvents } =
    useCreateCustomerOnboardingEvent();

  const handleClick = () => {
    createOnboardingEvent({
      customer_onboarding_event: {
        name: CustomerOnboardingEventName.CUSTOMER_ONBOARDING_COMPLETED,
      },
    });
  };

  return (
    <Modal open={isOpen}>
      <ModalHeader title="" onClose={onClose}>
        <div className="flex justify-center">
          <div className="bg-green-secondary flex h-12 w-12 items-center justify-center rounded-full md:h-20 md:w-20">
            <FontAwesomeIcon icon={faCheck} className="text-green text-lg md:text-2xl" size="xl" />
          </div>
        </div>
        <p className="text-default-primary pt-6 text-center text-xl font-semibold tracking-tight">
          Invitations have been sent
        </p>
        <p className="text-default-secondary pb-6 pt-1.5 text-center text-base font-normal">
          Ask drivers to check their phones and download the app by the link.
        </p>
      </ModalHeader>
      <ModalFooter>
        <LoadingButton
          onClick={handleClick}
          loading={isCreatingOnboardingEvents}
          className="w-full"
        >
          Done
        </LoadingButton>
      </ModalFooter>
    </Modal>
  );
}
