import SavingsLogo from '@app/assets/images/svg/atob_savings.svg';
import { CreditLimitIncreaseRequestModal } from '@app/components/Modals/CreditLimitIncreaseRequest/CreditLimitIncreaseRequestModal';
import { ULToCreditRequest } from '@app/components/Modals/ULToCreditRequest/ULToCreditRequest';
import { CollapsibleTile, Tile } from '@app/components/OverviewTiles/Tile';
import { ConfigureAutoTopUp } from '@app/components/PrepaidAccountOverview/HeadingCard';
import useProduct from '@app/hooks/useProduct';
import { MobileFriendlyTooltip } from '@atob-developers/shared/src/components/MobileFriendlyTooltip';
import { convertNumbersWithCurrency } from '@atob-developers/shared/src/utils/formatters/currencyFormat';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LinearProgress, Link as MUILink } from '@mui/material';
import { ReactElement, useState } from 'react';

interface AvailableToSpendTileProps {
  isCredit: boolean; // Credit Tile: True, Prepaid tile: False
  availableAmount: string;
  currentWeekSpend: string;
  periodStartDay: string;
  pendingSpendAmount: string;
  spendLimit?: string; // Only available for Credit tile
  setAutoTopUpSidebarOpen?: (isOpen: boolean) => void; // only applicable for prepaid tile
}

export function AvailableToSpendTile({
  isCredit,
  availableAmount,
  currentWeekSpend,
  periodStartDay,
  pendingSpendAmount,
  spendLimit,
  setAutoTopUpSidebarOpen,
}: AvailableToSpendTileProps): ReactElement {
  let progressValueVar = 0;
  spendLimit = isCredit ? spendLimit : '';
  if (isCredit && spendLimit != undefined) {
    // &&spendLimit != undefined because it is optional and used only for credit tile.
    const formattedSpendLimit = convertNumbersWithCurrency(spendLimit);
    progressValueVar = Math.min(
      (convertNumbersWithCurrency(availableAmount) / formattedSpendLimit) * 100,
      100,
    );
  }
  const progressValue = progressValueVar;

  const [cliOrULModalOpen, setCliOrULModalOpen] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [canRequestULtoFlex] = useProduct('unlimited_to_credit_requests');
  const [cliRequests] = useProduct('cli_requests');
  const canRequestCliOrULToFlex = isCredit ? cliRequests : canRequestULtoFlex;

  return (
    <Tile
      title="Available to Spend"
      value={availableAmount}
      rightValue={
        isCredit ? <p className="text-default-secondary">Limit of {spendLimit}</p> : undefined
      }
      footer={
        <>
          {isCredit && (
            <LinearProgress
              className="bg-green-secondary"
              classes={{ bar: 'bg-green-primary' }}
              variant="determinate"
              value={progressValue}
            />
          )}
          {showDetails && (
            <div className="flex w-full flex-col pt-4">
              <div className="flex items-center justify-between">
                <div className="text-default-secondary flex items-center text-sm">
                  Period Spend
                  <MobileFriendlyTooltip
                    className="pl-1"
                    title={`Charges made this period (starting ${periodStartDay} at 12:00AM, Pacific time), excluding any transactions that have yet to settle.`}
                  >
                    <FontAwesomeIcon icon={faCircleInfo} className=" text-default-secondary" />
                  </MobileFriendlyTooltip>
                </div>
                <p className="font-semibold">{currentWeekSpend}</p>
              </div>
              <div className="flex items-center justify-between pt-2">
                <div className="text-default-secondary flex items-center text-sm">
                  Pending Transactions
                  <MobileFriendlyTooltip
                    className="pl-1"
                    title="Charges that have yet to settle. You will not be billed for these charges until they settle."
                  >
                    <FontAwesomeIcon icon={faCircleInfo} className=" text-default-secondary" />
                  </MobileFriendlyTooltip>
                </div>
                <p className="text-default-primary font-semibold">
                  {pendingSpendAmount.replace('-', '')}
                </p>
              </div>
            </div>
          )}
          <div
            className={
              isCredit
                ? 'flex w-full items-center justify-between pt-4'
                : 'mt-1 flex flex-row items-center justify-between'
            }
          >
            <MUILink
              onClick={() => setShowDetails((prev) => !prev)}
              className="text-default-secondary cursor-pointer"
            >
              {showDetails ? 'Hide' : 'Show'} Details
            </MUILink>
            {canRequestCliOrULToFlex && (
              <>
                <div
                  onClick={() => setCliOrULModalOpen(true)}
                  className="text-default-secondary cursor-pointer self-end text-sm underline"
                >
                  {isCredit ? 'Request limit increase' : 'Request a credit line'}
                </div>
                {/* Applicable only for credit customers to increase their credit limit */}
                {isCredit && (
                  <CreditLimitIncreaseRequestModal
                    open={cliOrULModalOpen}
                    onClose={() => setCliOrULModalOpen(false)}
                  />
                )}
                {/* Applicable only for prepaid customers to convert UL to credit */}
                {!isCredit && (
                  <ULToCreditRequest
                    open={cliOrULModalOpen}
                    toggleModal={() => setCliOrULModalOpen(false)}
                  />
                )}
              </>
            )}
          </div>
          {!isCredit && setAutoTopUpSidebarOpen != undefined && (
            <div className="max-w-1/2 pt-1">
              <ConfigureAutoTopUp setAutoTopUpSidebarOpen={setAutoTopUpSidebarOpen} />
            </div>
          )}
        </>
      }
    />
  );
}

interface PendingTransactionsTileProps {
  pendingAmount: string;
}

export function PendingTransactionsTile({
  pendingAmount,
}: PendingTransactionsTileProps): ReactElement {
  return (
    <CollapsibleTile
      title="Pending Transactions"
      value={pendingAmount.replace('-', '')}
      tooltip="Charges that have yet to settle. You will not be billed for these charges until they settle."
    />
  );
}

interface PeriodSavingsTileProps {
  potentialDiscountAmount: string;
  discountAmount: string;
  onDetailsClick: () => void;
}

export function PeriodSavingsTile({
  potentialDiscountAmount,
  discountAmount,
  onDetailsClick,
}: PeriodSavingsTileProps): ReactElement {
  return (
    <Tile
      title={
        <div className="text-green flex items-center">
          <img src={SavingsLogo} className="pr-1" />
          AtoB Savings
        </div>
      }
      value={
        <>
          {discountAmount.replace('-', '')}
          {potentialDiscountAmount !== '$0.00' && (
            <div className="text-default-primary text-sm font-normal">
              You could have saved <span className="font-semibold">{potentialDiscountAmount}</span>{' '}
              more
            </div>
          )}
        </>
      }
      footer={
        <div
          onClick={onDetailsClick}
          className="text-default-secondary hidden cursor-pointer text-sm underline md:flex md:pt-6"
        >
          View details
        </div>
      }
    />
  );
}
