import { ErrorNotification } from '@app/components/layout';
import { guardAxiosError } from '@app/utils/error/guards';
import isNumeric from '@app/utils/is-numeric';
import Modal, { ModalBodyContent, ModalHeader } from '@atob-developers/shared/src/components/Modal';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import React, { ReactElement, useState } from 'react';
import { RecipientToCreate, RecipientToEdit } from '../transfer.types';
import BankAccountForm from './BankAccountForm';
import type { Address } from '@app/components/elements/AddressInput';

const validateAddress = (
  address: Address,
  setAddressErrors: (errors: Record<string, string>) => void,
) => {
  setAddressErrors({});
  const { address1, city, state, zip } = address;
  const hasNotFilledOutAddress = !address1 && !city && !zip;

  if (hasNotFilledOutAddress) {
    return true;
  }

  if (!address1) {
    setAddressErrors({ address1: 'Address is required' });
    return false;
  }
  if (!city) {
    setAddressErrors({ city: 'City is required' });
    return false;
  }
  if (!state) {
    setAddressErrors({ state: 'State is required' });
    return false;
  }
  if (!zip) {
    setAddressErrors({ zip: 'Zip is required' });
    return false;
  }
  if (!isNumeric(zip)) {
    setAddressErrors({ zip: 'Zip must be numeric' });
    return false;
  }

  return true;
};

type EditRecipientErrors = {
  name: string | null;
  routing: string | null;
  account: string | null;
  confirmedAccount: string | null;
};

const initialErrors: EditRecipientErrors = {
  name: null,
  routing: null,
  account: null,
  confirmedAccount: null,
};

const EditRecipientModal = ({
  recipient,
  reset,
  onEditRecipient,
  open,
}: {
  recipient: RecipientToCreate;
  reset: () => void;
  onEditRecipient: (recipient: RecipientToEdit) => Promise<void>;
  open: boolean;
}): ReactElement => {
  const [name, setName] = useState(() => {
    return recipient?.billing_details?.name || '';
  });
  const [nickname, setNickname] = useState(recipient.display_name || '');
  const [isBusinessAccount, setIsBusinessAccount] = useState(
    recipient.us_bank_account?.account_holder_type === 'company',
  );
  const [accountNumber, setAccountNumber] = useState('');
  const [routingNumber, setRoutingNumber] = useState('');
  const [confirmedAccountNumber, setConfirmedAccountNumber] = useState('');
  const [errors, setErrors] = useState<EditRecipientErrors>(initialErrors);
  const [overallError, setOverallError] = useState<string | null>(null);
  const [showErrorRefresh, setShowErrorRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addressValues, setAddressValues] = useState(recipient.billing_details?.address || {});
  const [addressErrors, setAddressErrors] = useState({});
  const [touched, setTouched] = useState({});

  const toggle = () => {
    setName('');
    setAccountNumber('');
    setRoutingNumber('');
    setConfirmedAccountNumber('');
    setAddressValues({} as React.SetStateAction<Address>);
    setAddressErrors({});
    setTouched({});
    setErrors(initialErrors);
    setOverallError(null);
    setShowErrorRefresh(false);
    reset();
  };

  const validate = () => {
    const validationErrors = { ...initialErrors };
    const isValidAddress = validateAddress(addressValues as Address, setAddressErrors);
    if (!isValidAddress) {
      return;
    }

    setErrors(validationErrors);
    return !Object.values(validationErrors).some((err) => err !== null);
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (validate()) {
      setLoading(true);
      try {
        recipient.id &&
          (await onEditRecipient({
            billing_details: {
              name,
              address: addressValues as Address,
            },
            display_name: nickname,
            id: recipient.id,
          }));

        toggle();
      } catch (e: unknown) {
        if (!guardAxiosError(e)) {
          setOverallError('Something went wrong. Please try again.');
          return;
        }
        const errorMessage = e.response?.data?.errors[0] || e.message;
        setOverallError(errorMessage);
      }

      setLoading(false);
    }
  };

  if (showErrorRefresh) {
    return (
      <Modal open={open} toggle={toggle}>
        <ModalHeader title="Edit Recipient" onClose={toggle} />
        <ModalBodyContent>
          {overallError && <ErrorNotification error={overallError} />}
          <div className="flex h-[300px] w-full flex-col items-center">
            <div className="flex w-[200px] justify-center">
              <Button color="secondary" onClick={() => toggle()}>
                Refresh
              </Button>
            </div>
          </div>
        </ModalBodyContent>
      </Modal>
    );
  }

  return (
    <Modal open={open} toggle={toggle}>
      <ModalHeader title="Edit bank account" onClose={toggle} />
      <ModalBodyContent>
        {overallError && <ErrorNotification error={overallError} />}
        <BankAccountForm
          setLoading={setLoading}
          type="edit"
          formId="edit_bank_account_form"
          onSubmit={onSubmit}
          errors={errors as Record<string, string>}
          name={name}
          setName={setName}
          nickname={nickname}
          setNickname={setNickname}
          isBusinessAccount={isBusinessAccount}
          setIsBusinessAccount={setIsBusinessAccount}
          routingNumber={routingNumber}
          setRoutingNumber={setRoutingNumber}
          accountNumber={accountNumber}
          setAccountNumber={setAccountNumber}
          confirmedAccountNumber={confirmedAccountNumber}
          setConfirmedAccountNumber={setConfirmedAccountNumber}
          addressValues={addressValues as Address}
          setAddressValues={setAddressValues}
          addressErrors={addressErrors}
          setAddressErrors={setAddressErrors}
          touched={touched}
          setTouched={setTouched}
          vgs={false}
          setValidAccountNumbers={() => {}}
        >
          <LoadingButton
            size="medium"
            loading={loading}
            type="submit"
            form="edit_bank_account_form"
          >
            Save
          </LoadingButton>
        </BankAccountForm>
      </ModalBodyContent>
    </Modal>
  );
};

export default EditRecipientModal;
