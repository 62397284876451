import ActionsList, { ActionData } from '@app/components/ActionsList/ActionsList';
import Link from '@atob-developers/shared/src/components/Link';
import { ReactElement } from 'react';

interface BulkActionBarProps {
  selectedCount: number;
  onDeselectAll: () => void;
  actions: ActionData[];
  overflowLimit: number;
}

const BulkActionBar = ({
  selectedCount,
  actions,
  onDeselectAll,
  overflowLimit,
}: BulkActionBarProps): ReactElement | null => {
  if (selectedCount === 0) {
    return null;
  }

  return (
    <div className="z-bulk-action-bar border-soft bg-soft-primary fixed inset-x-0 bottom-0 flex w-full items-center rounded border drop-shadow-2xl md:bottom-10 md:left-[15%] md:mx-auto md:max-w-xl lg:max-w-3xl">
      <div className="flex flex-1 items-center justify-center px-2 py-2 align-middle sm:px-4 sm:py-4">
        <div className="min-w-[100px] flex-1">
          {selectedCount > 1 && (
            <span className="text-default-primary flex px-2 text-sm font-bold md:inline">
              {selectedCount} selected
            </span>
          )}
          <Link onClick={onDeselectAll}>{selectedCount === 1 ? 'Deselect' : 'Deselect all'}</Link>
        </div>
        <ActionsList actions={actions} overflowLimit={overflowLimit} />
      </div>
    </div>
  );
};
export default BulkActionBar;
