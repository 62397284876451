import classNames from 'classnames';
import { InputHTMLAttributes } from 'react';
import { formatInput } from '../utils/formatUtils';
import ErrorMessage from './ErrorMessage';
import WarningMessage from './WarningMessage';
import {
  INPUT_BG_COLORS,
  INPUT_BORDER_COLOR,
  INPUT_FOCUS_RING_COLORS,
  INPUT_TEXT_COLORS,
  INPUT_TYPES,
  InputType,
} from './constants';

export type InputProps = {
  label: string;
  inputId: string;
  inputType?: InputType;
  labelHidden?: boolean;
  base?: boolean;
  error?: string;
  warning?: string;
  readOnly?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

export default function Input({
  label,
  inputId,
  inputType = 'text',
  placeholder,
  labelHidden = false,
  required = false,
  value,
  onChange,
  error,
  warning,
  disabled = false,
  base = false,
  readOnly = false,
  className = '',
  ...props
}: InputProps) {
  const types: { [key in INPUT_TYPES]: boolean } = {
    base,
    disabled,
    error: !!error,
    warning: !!warning,
  };

  const inputStyleName: INPUT_TYPES =
    Object.entries(types)
      .map(([key, value]) => ({ key: key as INPUT_TYPES, value }))
      .find(({ value }) => value)?.key || 'base';

  return (
    <div className="my-2">
      <label
        htmlFor={inputId}
        className={classNames('text-default-primary block text-sm font-semibold', {
          'sr-only': labelHidden,
        })}
      >
        {label} {required && <span className="text-red">*</span>}
      </label>
      {readOnly ? (
        <span className="text-default-primary text-sm">{value}</span>
      ) : (
        <div className="mt-1">
          <BaseInput
            value={formatInput((value as string) || '', inputType)}
            required={required}
            type={inputType}
            name={inputId}
            id={inputId}
            disabled={disabled}
            grayBorder={!base && !disabled && !error && !warning}
            inputStyleName={inputStyleName}
            placeholder={placeholder || label}
            onChange={onChange}
            className={className}
            {...props}
          />
          <ErrorMessage error={error} />
          <WarningMessage warning={warning} />
        </div>
      )}
    </div>
  );
}

type BaseInputProps = {
  inputStyleName?: INPUT_TYPES;
  grayBorder?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

export function BaseInput({
  className,
  inputStyleName = 'base',
  grayBorder,
  ...props
}: BaseInputProps) {
  return (
    <input
      className={classNames(
        'disabled:bg-soft-secondary border-1 block w-full rounded-md shadow-sm sm:text-sm',
        INPUT_FOCUS_RING_COLORS[inputStyleName],
        INPUT_BG_COLORS[inputStyleName],
        INPUT_TEXT_COLORS[inputStyleName],
        INPUT_BORDER_COLOR[inputStyleName],
        { 'border-soft': !grayBorder },
        className,
      )}
      {...props}
    />
  );
}
