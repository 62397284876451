import useCustomerOnboardingEvents, {
  CustomerOnboardingEventName,
  useCreateCustomerOnboardingEvent,
} from '@app/hooks/query/useCustomerOnboardingEvents';
import Alert from '@atob-developers/shared/src/components/Alert';
import Modal, {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { Button } from '@mui/material';
import { useState } from 'react';

export default function OnboardingSummaryModal() {
  const customerOnboardingEvents = useCustomerOnboardingEvents();
  const [isOpen, setIsOpen] = useState(
    customerOnboardingEvents.has(CustomerOnboardingEventName.CUSTOMER_ONBOARDING_SHOW_SUMMARY) &&
      !customerOnboardingEvents.has(CustomerOnboardingEventName.CUSTOMER_SUMMARY_COMPLETED),
  );

  const { trigger: createOnboardingEvent } = useCreateCustomerOnboardingEvent();

  const finishOnboardingFlow = () => {
    createOnboardingEvent({
      customer_onboarding_event: { name: CustomerOnboardingEventName.CUSTOMER_SUMMARY_COMPLETED },
    });
    createOnboardingEvent({
      customer_onboarding_event: {
        name: CustomerOnboardingEventName.CUSTOMER_ONBOARDING_COMPLETED,
      },
    });

    setIsOpen(false);
  };

  return (
    <Modal open={isOpen}>
      <ModalHeader
        title="You're all done"
        onClose={finishOnboardingFlow}
        className="justify-center"
        headerImage={
          <img src="images/onboarding/onboarding-confirmation.webp" className="rounded-lg" />
        }
      >
        <p className="text-default-secondary pt-1.5 text-center text-base font-normal">
          When your AtoB card arrives in the mail, they’ll be auto-activated and ready for immediate
          use.
        </p>
      </ModalHeader>
      <ModalBodyContent>
        <Alert
          type="info"
          title="Do you have any question?"
          description="Our support team is available to you 24x7."
          actionText={<a href="tel:+18339472862">Contact support</a>}
        />
      </ModalBodyContent>
      <ModalFooter>
        <div className="flex flex-1 flex-col">
          <Button onClick={finishOnboardingFlow} className="w-full">
            Go to dashboard
          </Button>
          <p className="text-default-secondary pt-1.5 text-center text-base font-normal">
            You can also explore{' '}
            <a
              href="https://atobco.zendesk.com/hc/en-us/sections/4407033456795-FAQ"
              target="_blank"
              className="text-default-primary font-medium underline"
              rel="noreferrer"
            >
              our FAQ
            </a>{' '}
            and help articles anytime you have questions.
          </p>
        </div>
      </ModalFooter>
    </Modal>
  );
}
