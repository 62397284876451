import { PurchaseUploadingState } from '@app/hooks/query/useConnectTelematics';
import { useToasts } from '@atob-developers/shared/src/hooks/useToasts';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Switch } from '@mui/material';
import axios from 'axios';
import { ReactElement, useState } from 'react';

export function PurchaseUploadingDetailPane({
  purchaseUploadingStatus,
}: {
  purchaseUploadingStatus: PurchaseUploadingState;
}): ReactElement {
  const [uploadingEnabled, setUploadingEnabled] = useState(
    purchaseUploadingStatus === 'connection_enabled',
  );
  const [showPurchaseUploadingConfig, setShowPurchaseUploadingConfig] = useState(false);
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();

  const firstTimeSetup = purchaseUploadingStatus === 'available';

  const handleToggleUploadingEnabled = async () => {
    if (firstTimeSetup) {
      setShowPurchaseUploadingConfig(true);
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post(
        uploadingEnabled
          ? '/telematics/connections/disable_purchase_uploading'
          : '/telematics/connections/enable_purchase_uploading',
      );
      addToast({ type: 'success', title: response.data.status });
      setUploadingEnabled(!uploadingEnabled);
    } catch (error: unknown) {
      if (axios.isAxiosError(error)) {
        addToast({ type: 'error', title: error.response?.data.status });
      } else {
        addToast({ type: 'error', title: 'Something went wrong' });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="flex flex-grow flex-row items-center justify-between p-4">
        <div className="ml-4">
          <div className="text-default-primary font-semibold">
            Send Transaction Data to your Telematics Provider
          </div>
          <div className="text-default-secondary">
            Transactions take about 24 hours to appear in your Samsara dashboard
          </div>
        </div>
        <Switch
          checked={uploadingEnabled}
          onChange={handleToggleUploadingEnabled}
          disabled={loading}
        />
      </div>
      {showPurchaseUploadingConfig && (
        <PurchaseUploadingConfiguration onClose={() => setShowPurchaseUploadingConfig(false)} />
      )}
    </>
  );
}

function PurchaseUploadingConfiguration({ onClose }: { onClose: () => void }) {
  const [loading, setLoading] = useState(false);
  const { addToast } = useToasts();

  const handleConnect = async () => {
    setLoading(true);
    try {
      const response = await axios.post('/telematics/connections/enable_purchase_uploading');
      window.location.href = response.data.auth;
    } catch (e: unknown) {
      if (axios.isAxiosError(e)) {
        addToast({ type: 'error', title: e.response?.data?.message || 'An error occurred' });
      } else {
        addToast({ type: 'error', title: (e as Error).message });
      }
    }
  };

  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>Send Transactions Data</DialogTitle>
      <DialogContent>
        By enabling, all future transactions will be automatically uploaded to your Telematics
        provider. This applies only to transactions associated with vehicles synced via Telematics
        (vehicles with a blue link icon on the vehicles page).
      </DialogContent>
      <DialogActions>
        <Button size="small" color="secondary" onClick={onClose}>
          Cancel
        </Button>
        <LoadingButton onClick={handleConnect} size="small" loading={loading}>
          Connect
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
