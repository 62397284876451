import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement } from 'react';

interface FloatingDropdownItemProps {
  selected: boolean;
  text: string;
  onClick: () => void;
  paymentItemKey: string;
}

const CheckMark = ({ show }: { show: boolean }) => {
  if (show) {
    return <FontAwesomeIcon icon={faCheck} className="text-green" />;
  }
  return <div className="w-4" />;
};

const FloatingDropdownItem = ({
  selected,
  text,
  onClick,
  paymentItemKey,
}: FloatingDropdownItemProps): ReactElement => {
  return (
    <div
      className="flex items-center justify-center py-4 text-center"
      key={`MobileFloatingDropdown-Item-${paymentItemKey}`}
      onClick={onClick}
    >
      <CheckMark show={selected} />
      <p className="text-default-primary mr-4 max-w-[70%] flex-1 cursor-pointer font-medium">
        {text}
      </p>
    </div>
  );
};

export default FloatingDropdownItem;
