import { FetcherKey } from '@app/utils/data/fetchers';
import Modal, {
  ModalBodyContent,
  ModalFooter,
  ModalHeader,
} from '@atob-developers/shared/src/components/Modal';
import { faFileUpload } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingButton } from '@mui/lab';
import { Button, Checkbox, FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers-pro';
import axios, { AxiosError } from 'axios';
import dayjs, { Dayjs } from 'dayjs';
import {
  ChangeEvent,
  createContext,
  ReactElement,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import useSWRMutation from 'swr/mutation';

enum Step {
  Reason,
  StolenCard,
  PoliceReport,
  EvidenceAndComment,
  Submit,
  DriverMisuse,
}

export const DisputeFormContext = createContext<{
  step: Step;
  reason: string | null;
  setReason: (s: string) => void;
  cardStolen: 'card_stolen' | 'card_not_stolen' | null;
  setCardStolen: (s: 'card_stolen' | 'card_not_stolen') => void;
  cardStolenTime: Dayjs | null;
  policeReportFiled: 'report_filed' | 'report_not_filed' | null;
  setPoliceReportFiled: (s: 'report_filed' | 'report_not_filed') => void;
  policeReportNumber: string;
  setPoliceReportNumber: (s: string) => void;
  setCardStolenTime: (d: Dayjs | null) => void;
  files: FileList | null;
  setFiles: (f: FileList | null) => void;
  comment: string;
  setComment: (s: string) => void;
  consentToDisclaimer: boolean;
  setConsentToDisclaimer: (s: boolean) => void;
  goNextStep: (s?: Step) => void;
  goPrevStep: (s?: Step) => void;
  resetAndClose: () => void;
  submitForm: () => void;
  isLoading: boolean;
}>({
  step: Step.Reason,
  goNextStep: () => {},
  reason: null,
  cardStolen: null,
  setCardStolen: (_) => {},
  cardStolenTime: dayjs(),
  setCardStolenTime: (_) => {},
  policeReportFiled: null,
  setPoliceReportFiled: (_) => {},
  policeReportNumber: '',
  setPoliceReportNumber: () => {},
  files: null,
  setFiles: () => {},
  comment: '',
  setComment: () => {},
  consentToDisclaimer: false,
  setConsentToDisclaimer: () => {},
  goPrevStep: () => {},
  setReason: (_) => {},
  resetAndClose: () => {},
  submitForm: () => {},
  isLoading: false,
});

export function useDisputeForm() {
  const transferContext = useContext(DisputeFormContext);
  if (!transferContext) {
    throw new Error('useDisputeForm must be used within DisputeFormContext');
  }
  return transferContext;
}

function StepReason(): ReactElement {
  const { goNextStep, setReason, reason } = useDisputeForm();
  return (
    <>
      <ModalBodyContent>
        <h1 className="pb-4">What is your reason for disputing the transaction?</h1>
        <RadioGroup className="gap-3" value={reason} onChange={(e) => setReason(e.target.value)}>
          <FormControlLabel
            value="unknown_transaction"
            control={<Radio />}
            label="I did not incur this transaction"
          />
          <FormControlLabel
            value="duplicate_transaction"
            control={<Radio />}
            label="This is a duplicate transaction"
          />
          <FormControlLabel
            value="incorrect_amount"
            control={<Radio />}
            label="The amount of the transaction is incorrect"
          />
          <FormControlLabel
            value="missing_refund"
            control={<Radio />}
            label="The merchant did not process the credit/refund as agreed"
          />
          <FormControlLabel
            value="return_missing_refund"
            control={<Radio />}
            label="I returned the goods/services, but the merchant refused to issue a refund"
          />
          <FormControlLabel value="driver_misuse" control={<Radio />} label="Driver Misuse" />
        </RadioGroup>
      </ModalBodyContent>
      <ModalFooter>
        <Button
          disabled={reason == null}
          onClick={() => goNextStep(reason == 'driver_misuse' ? Step.DriverMisuse : undefined)}
        >
          Next
        </Button>
      </ModalFooter>
    </>
  );
}

function StepStolenCard(): ReactElement {
  const { cardStolen, setCardStolen, cardStolenTime, setCardStolenTime, goNextStep, goPrevStep } =
    useDisputeForm();
  return (
    <>
      <ModalBodyContent>
        <h1 className="pb-4">Was your card stolen?</h1>
        <RadioGroup
          className="pb-4"
          value={cardStolen}
          onChange={(e) => {
            setCardStolen(e.target.value as 'card_stolen' | 'card_not_stolen');
            if (e.target.value == 'card_not_stolen') {
              setCardStolenTime(null);
            }
          }}
        >
          <FormControlLabel value="card_stolen" control={<Radio />} label="Yes" />
          <FormControlLabel value="card_not_stolen" control={<Radio />} label="No" />
        </RadioGroup>
        {cardStolen == 'card_stolen' && (
          <>
            <p className="pb-2 text-base">When was your card stolen?</p>
            <DatePicker value={cardStolenTime} onChange={(v) => setCardStolenTime(v)} />
          </>
        )}
      </ModalBodyContent>
      <ModalFooter>
        <Button onClick={() => goPrevStep()}>Previous</Button>
        <Button
          disabled={cardStolen == null || (cardStolen == 'card_stolen' && cardStolenTime == null)}
          onClick={() => goNextStep()}
        >
          Next
        </Button>
      </ModalFooter>
    </>
  );
}

function StepPoliceReport(): ReactElement {
  const {
    policeReportFiled,
    setPoliceReportFiled,
    policeReportNumber,
    setPoliceReportNumber,
    goNextStep,
    goPrevStep,
  } = useDisputeForm();
  return (
    <>
      <ModalBodyContent>
        <h1 className="pb-4">Have you filed a police report?</h1>
        <RadioGroup
          className="pb-4"
          value={policeReportFiled}
          onChange={(e) => {
            setPoliceReportFiled(e.target.value as 'report_filed' | 'report_not_filed');
            if (e.target.value == 'report_not_filed') {
              setPoliceReportNumber('');
            }
          }}
        >
          <FormControlLabel value="report_filed" control={<Radio />} label="Yes" />
          <FormControlLabel value="report_not_filed" control={<Radio />} label="No" />
        </RadioGroup>
        {policeReportFiled == 'report_filed' && (
          <>
            <p className="pb-2 text-base">Please provide the report number</p>
            <TextField
              value={policeReportNumber}
              onChange={(e) => setPoliceReportNumber(e.target.value)}
            />
          </>
        )}
      </ModalBodyContent>
      <ModalFooter>
        <Button onClick={() => goPrevStep()}>Previous</Button>
        <Button
          disabled={
            policeReportFiled == null ||
            (policeReportFiled == 'report_filed' && policeReportNumber == '')
          }
          onClick={() => goNextStep()}
        >
          Next
        </Button>
      </ModalFooter>
    </>
  );
}

function VisuallyHiddenInput(props: React.InputHTMLAttributes<HTMLInputElement>) {
  return (
    <input
      {...props}
      className="absolute inset-0 h-px w-px overflow-hidden whitespace-nowrap opacity-0"
    />
  );
}

function StepEvidenceAndComment(): ReactElement {
  const {
    files,
    setFiles,
    reason,
    cardStolen,
    comment,
    setComment,
    submitForm,
    goPrevStep,
    consentToDisclaimer,
    setConsentToDisclaimer,
    isLoading,
  } = useDisputeForm();

  return (
    <>
      <ModalBodyContent>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <p className="text-sm">
              Please attach evidence that you (or the driver) was not in the location of the
              disputed transaction at the time it was incurred. Acceptable forms of evidence
              include:
            </p>
            <p className="text-sm">
              Copy of ELD (Electronic Logging Device) showing location of driver/vehicle and the
              company name.
            </p>
            <p className="text-sm">
              Receipts (grocery, restaurant, etc.) that place the card in a different location
              compared to the transaction in question
            </p>
            <p className="text-sm">
              Supplemental: Official police reports documenting the incident reported
            </p>
          </div>
          <div className="flex flex-row items-center gap-2">
            <Button
              size="small"
              component="label"
              role={undefined}
              tabIndex={-1}
              startIcon={<FontAwesomeIcon icon={faFileUpload} />}
            >
              Upload files
              <VisuallyHiddenInput
                type="file"
                accept="application/pdf, image/*"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setFiles((event.target as HTMLInputElement).files);
                }}
                multiple
              />
            </Button>
            {(files?.length ?? 0) > 0 ? <span>{files?.length} files selected</span> : null}
          </div>
          <div>
            <TextField
              fullWidth
              label="Additional Information"
              helperText="Please provide additional details regarding the circumstances of this dispute. The more information you provide us, the better we can understand your claim and advocate on your behalf. (250 character max)"
              multiline
              value={comment}
              onChange={(e) => {
                if (e.target.value.length < 250) {
                  setComment(e.target.value);
                }
              }}
            />
          </div>
          {(reason == 'unknown_transaction' || cardStolen == 'card_stolen') && (
            <div>
              <p className="text-md">Disclaimer for Unknown Transaction Disputes</p>
              <p className="text-sm">
                <em>Important: Please Read Before Submitting Your Dispute</em>
              </p>
              <p className="text-sm">
                By proceeding with this dispute for an unknown transaction, or if your card was
                stolen, you acknowledge that{' '}
                <strong>your current card will be canceled immediately</strong> upon submission to
                help protect your account from potential unauthorized activity. If you require a
                replacement card, you must reply to your dispute submission confirmation email and
                confirm the shipping address for your new card. Failure to do so may result in
                delays in receiving your replacement. If you do not wish to proceed under these
                terms, please exit this form and contact Customer Support for further assistance.
              </p>

              <FormControlLabel
                label="I have read and understood the disclaimer"
                control={
                  <Checkbox
                    id="consent-to-disclaimer"
                    onChange={(e) => {
                      setConsentToDisclaimer(e.target.checked);
                    }}
                    checked={consentToDisclaimer}
                  />
                }
              />
            </div>
          )}
        </div>
      </ModalBodyContent>
      <ModalFooter>
        <Button onClick={() => goPrevStep()}>Previous</Button>
        <LoadingButton
          disabled={
            isLoading ||
            comment.length == 0 ||
            files == null ||
            files.length == 0 ||
            ((reason == 'unknown_transaction' || cardStolen == 'card_stolen') &&
              !consentToDisclaimer)
          }
          loading={isLoading}
          onClick={() => {
            submitForm();
          }}
        >
          Submit
        </LoadingButton>
      </ModalFooter>
    </>
  );
}

function StepDriverMisuseDisclaimer(): ReactElement {
  const { resetAndClose } = useDisputeForm();
  return (
    <>
      <ModalBodyContent>
        AtoB cannot dispute transactions performed by your drivers. If a driver used a card outside
        its intended scope you will need to address it directly with your driver.
      </ModalBodyContent>
      <ModalFooter>
        <Button onClick={resetAndClose}>Close</Button>
      </ModalFooter>
    </>
  );
}

export function CreateDisputeModal({
  open,
  toggle,
  chargeEventID,
  refreshChargeEvent,
}: {
  chargeEventID: string;
  open: boolean;
  toggle: () => void;
  refreshChargeEvent: () => void;
}): ReactElement {
  const [reason, setReason] = useState<string | null>(null);
  const [step, setStep] = useState<Step>(Step.Reason);
  const [cardStolen, setCardStolen] = useState<'card_stolen' | 'card_not_stolen' | null>(null);
  const [cardStolenTime, setCardStolenTime] = useState<Dayjs | null>(null);
  const [policeReportFiled, setPoliceReportFiled] = useState<
    'report_filed' | 'report_not_filed' | null
  >(null);
  const [policeReportNumber, setPoliceReportNumber] = useState<string>('');
  const [files, setFiles] = useState<FileList | null>(null);
  const [comment, setComment] = useState<string>('');
  const [consentToDisclaimer, setConsentToDisclaimer] = useState<boolean>(false);

  const resetAndClose = useCallback(() => {
    toggle();
    setReason(null);
    setStep(Step.Reason);
  }, [toggle]);

  const { trigger, isMutating } = useSWRMutation<unknown, AxiosError, FetcherKey>(
    `/charge_events/${chargeEventID}/dispute`,
    async (url: string) => {
      const formData = new FormData();
      for (let i = 0; i < (files?.length ?? 0); i++) {
        const file = files?.[i];
        if (file) {
          formData.append('attachments[]', file);
        }
      }
      if (reason) {
        formData.append('dispute_reason', reason);
      }
      if (cardStolenTime) {
        formData.append('card_stolen_at', cardStolenTime.toString());
      }
      if (policeReportNumber.length > 0) {
        formData.append('police_report_reference', policeReportNumber);
      }
      formData.append('comments', comment);
      await axios.post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    {
      onSuccess: () => {
        refreshChargeEvent();
        resetAndClose();
      },
    },
  );

  const contextVal = useMemo(
    () => ({
      step,
      reason,
      setReason,
      cardStolen,
      setCardStolen,
      cardStolenTime,
      setCardStolenTime,
      policeReportFiled,
      setPoliceReportFiled,
      policeReportNumber,
      setPoliceReportNumber,
      files,
      setFiles,
      comment,
      setComment,
      consentToDisclaimer,
      setConsentToDisclaimer,
      trigger,
      isLoading: isMutating,
      goNextStep: (s?: Step) =>
        setStep((p) => {
          if (s != null) {
            return s;
          } else {
            return p + 1;
          }
        }),
      goPrevStep: (s?: Step) =>
        setStep((p) => {
          if (s != null) {
            return s;
          } else {
            return p - 1;
          }
        }),
      resetAndClose,
      submitForm: trigger,
    }),
    [
      cardStolen,
      cardStolenTime,
      comment,
      consentToDisclaimer,
      files,
      isMutating,
      policeReportFiled,
      policeReportNumber,
      reason,
      resetAndClose,
      step,
      trigger,
    ],
  );
  return (
    <DisputeFormContext.Provider value={contextVal}>
      <Modal open={open} toggle={resetAndClose}>
        <ModalHeader title="Dispute Transaction" />
        {step == Step.Reason && <StepReason />}
        {step == Step.StolenCard && <StepStolenCard />}
        {step == Step.DriverMisuse && <StepDriverMisuseDisclaimer />}
        {step == Step.PoliceReport && <StepPoliceReport />}
        {step == Step.EvidenceAndComment && <StepEvidenceAndComment />}
      </Modal>
    </DisputeFormContext.Provider>
  );
}
