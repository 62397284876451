import { CustomerData } from '@app/@types/customer.types';
import AppViewOverrideContainer from '@app/app/AppContainer/AppViewOverrideContainer';
import SignCombinedAgreement from '@app/components/CombinedAgreement/SignCombinedAgreement';
import { ErrorNotification } from '@app/components/layout';
import useAuth from '@app/hooks/useAuth';
import useChannelPartner from '@app/hooks/useChannelPartner';
import { Button } from '@mui/material';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { isEmpty } from 'lodash-es';
import { ReactElement } from 'react';

export default function getAppViewOverrideByProduct({
  customer,
  error,
}: {
  customer: CustomerData | null;
  error: Error | undefined;
}): ReactElement | null {
  if (axios.isAxiosError(error)) {
    if (error.response?.status === 401 || error.response?.status === 404) {
      Sentry.captureException(error);
      return <ApiAuthFailure />;
    }
  }

  if (error) {
    return <ErrorNotification error={error} generic />;
  }

  if (
    customer &&
    !isEmpty(customer) &&
    (!customer.tos_signed_at || !customer.ach_agreement_signed_at)
  ) {
    return <AppViewOverrideContainer>{<SignCombinedAgreement />}</AppViewOverrideContainer>;
  }
  if (isEmpty(customer)) {
    return <ErrorNotification error={'Empty Customer!'} generic />;
  }

  return null;
}

function ApiAuthFailure(): ReactElement {
  const { logout } = useAuth();
  const { supportPhoneNumber, supportEmailAddress } = useChannelPartner();
  return (
    <div className="m-8 flex w-full flex-col justify-start">
      <ErrorNotification error={'Api Auth Failure'}>
        <p className="text-default-primary">
          We could not find an account associated with this email address. To register with this
          email, please{' '}
          <a className="underline" href={`tel:${supportPhoneNumber}`}>
            call
          </a>{' '}
          or{' '}
          <a className="underline" href={`mailto:${supportEmailAddress}`}>
            email
          </a>{' '}
          support for assistance.
        </p>
      </ErrorNotification>
      <section className="flex w-full justify-center">
        <div className="flex">
          <div>
            <Button color="primary" onClick={() => logout()}>
              Try Another Email
            </Button>
          </div>
        </div>
      </section>
    </div>
  );
}
