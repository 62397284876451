import { EndpointResponse } from '@app/@types/api.types';
import { Coordinates, FuelListing, FuelPrice } from '@app/@types/fuel_listings.types';
import useFeatureFlags from '@app/hooks/useFeatureFlags';
import useProduct from '@app/hooks/useProduct';
import useThemeMode from '@app/hooks/useThemeMode';
import { useChallengeMatch } from '@app/pages/AccountOverview/useChallengeMatch';
import { useFuelMapContext } from '@app/pages/FuelMap/FuelMapContext';
import {
  DISCOUNT_BOOST_BRAND_IDS,
  DRIVER_REWARDED_BRAND_IDS,
} from '@app/pages/FuelMap/fuelMap.const';
import { getFetcher } from '@app/utils/data/fetchers';
import logger from '@app/utils/datadog-logger';
import {
  choosePreferredFuelPrice,
  getIsValidPrice,
  getReadableDistanceFromFuelListing,
} from '@app/utils/fuel-listings';
import Alert from '@atob-developers/shared/src/components/Alert';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faChevronLeft,
  faDollar,
  faUpload,
  faLocationDot,
  faRocketLaunch,
  faRoute,
  faTrophy,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import classNames from 'classnames';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { startCase } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import useSWRImmutable from 'swr/immutable';

import SpinnerBoundary from '../Spinner/SpinnerBoundary';
import FuelListingPriceWithDiscount from './FuelListingPriceWithDiscount';
import FuelListingShareStationModal from './FuelListingShareStationModal';
import { BrandLogo } from './Logos/BrandLogo';
import { useHasDiscount } from './useHasDiscount';
import { usePriceListingType } from './usePriceListingType';
dayjs.extend(relativeTime);

const FuelListingDetailPrice = ({ price }: { price: FuelPrice }) => {
  const date = price.attributes.fuel_sign_time ?? '';

  const isGuaranteedDiscount = price.attributes.discount_type === 'RETAIL_MINUS';
  const isPriceValid = getIsValidPrice(price);

  return (
    <div className="flex flex-col gap-1 py-3">
      <div className="flex flex-row justify-between">
        <div className="min-w-0">
          <div className="text-default-primary truncate text-ellipsis text-sm font-medium capitalize">
            {price.attributes.high_flow && price.attributes.fuel_grade === 'DIESEL'
              ? 'Truck Diesel'
              : price.attributes.fuel_grade.toLowerCase()}
          </div>
          <p className="text-default-secondary flex w-full items-center gap-1 text-xs">
            {isPriceValid ? (
              <span className="truncate text-ellipsis">Updated {dayjs(date).fromNow()}</span>
            ) : isGuaranteedDiscount || price.attributes.discount_per_gallon_cents === 0 ? (
              <span className="truncate text-ellipsis">Price Unavailable</span>
            ) : null}
          </p>
        </div>
        <div className="flex flex-col items-end justify-center">
          <FuelListingPriceWithDiscount price={price} />
        </div>
      </div>
    </div>
  );
};

const FuelListingDetailPrices = ({ prices }: { prices: FuelPrice[] }) => {
  if (!prices.length) {
    return null;
  }

  // Pull truck diesel prices to the top.
  const displayPrices = prices.sort((a, _) => {
    return a.attributes.high_flow ? -1 : 1;
  });

  return (
    <div className="flex flex-col gap-2">
      <h3 className="text-default-secondary text-xs font-semibold uppercase">Fuel</h3>
      <div className="flex flex-col">
        {displayPrices.map((price: FuelPrice) => (
          <FuelListingDetailPrice price={price} key={price.id} />
        ))}
      </div>
    </div>
  );
};

const FuelListingDetailOverview = ({
  fuelListing,
  toggleModal,
}: {
  fuelListing: FuelListing;
  toggleModal: () => void;
}) => {
  const { selectedGrade } = useFuelMapContext();
  const [shareStations] = useFeatureFlags('share_stations');
  const { lightClassName } = useThemeMode();

  return (
    <div className="flex flex-col gap-3 pb-4">
      <div className="flex flex-row items-center gap-3">
        <div className={lightClassName}>
          <BrandLogo
            logoURL={fuelListing.attributes.logo_url}
            brand={fuelListing.attributes.location.name}
            size={40}
            rounded
            border
            electric={selectedGrade === 'ELECTRIC'}
          />
        </div>
        <span className="text-default-primary text-2xl font-semibold tracking-[-0.6px]">
          {fuelListing.attributes.location.name}
        </span>
      </div>
      {shareStations && selectedGrade !== 'ELECTRIC' && (
        <Button
          size="small"
          onClick={() => {
            logger.info('fuel-listing-share', {
              from: 'details',
              id: fuelListing.attributes.site_id,
            });
            toggleModal();
          }}
          startIcon={<FontAwesomeIcon icon={faUpload} className="size-4" />}
        >
          Share to drivers
        </Button>
      )}
    </div>
  );
};

const FuelListingDetailLocation = ({
  currentLocation,
  fuelListing,
}: {
  currentLocation: Coordinates | undefined;
  fuelListing: FuelListing;
}) => {
  const [challengeMatch, costPlusDriverCashback] = useProduct(
    'challenge_match',
    'cost_plus_driver_cashback',
  );
  const [onTwoDollarFee, onThreeDollarFee, onSixDollarFee] = useFeatureFlags(
    'oon_fee_two_dollar',
    'oon_fee_three_dollar',
    'oon_fee_six_dollar',
  );
  const { selectedGrade } = useFuelMapContext();
  const priceToShow = choosePreferredFuelPrice(
    fuelListing.attributes.fuel_prices.data ?? [],
    selectedGrade,
  );
  const priceListingType = usePriceListingType(priceToShow ?? null);
  const hasDiscount = useHasDiscount(priceListingType);
  const { currentDiscountBoost } = useChallengeMatch();

  const distance = useMemo(() => {
    if (currentLocation == null) {
      return null;
    }
    return getReadableDistanceFromFuelListing(currentLocation, fuelListing);
  }, [currentLocation, fuelListing]);

  return (
    <div className="flex flex-col gap-2 py-4">
      <h3 className="text-default-secondary text-xs font-semibold uppercase">Details</h3>
      <div>
        {(onTwoDollarFee || onThreeDollarFee || onSixDollarFee) && selectedGrade !== 'ELECTRIC' && (
          <p className="text-default-primary flex items-center gap-2 py-3 text-sm font-medium">
            <FontAwesomeIcon fixedWidth size="lg" icon={faDollar} />
            <span className={classNames(hasDiscount ? 'text-green' : 'text-default-primary')}>
              {hasDiscount
                ? 'No transaction fee'
                : onTwoDollarFee
                ? '$2.00 fee'
                : onThreeDollarFee
                ? '$3.00 fee'
                : '$6.00 fee'}
            </span>
          </p>
        )}
        {challengeMatch &&
          DISCOUNT_BOOST_BRAND_IDS.includes(fuelListing.attributes.brand_id as number) && (
            <p className="text-green flex items-center gap-2 py-3 text-sm font-medium">
              <FontAwesomeIcon fixedWidth size="lg" icon={faRocketLaunch} />
              {currentDiscountBoost > 0
                ? `+${currentDiscountBoost}¢/gal as statement credit`
                : `Eligible for extra discounts`}
            </p>
          )}
        {costPlusDriverCashback &&
          !challengeMatch &&
          DRIVER_REWARDED_BRAND_IDS.includes(fuelListing.attributes.brand_id as number) && (
            <p className="text-green flex items-center gap-2 py-3 text-sm font-medium">
              <FontAwesomeIcon fixedWidth size="lg" icon={faTrophy} />
              Eligible for $25 driver reward
            </p>
          )}
        {distance && (
          <p className="text-default-primary flex items-center gap-2 py-3 text-sm font-medium">
            <FontAwesomeIcon fixedWidth size="lg" icon={faRoute} />
            {distance}
          </p>
        )}
        <p className="text-default-primary flex items-center gap-2 py-3 text-sm font-medium underline">
          <FontAwesomeIcon fixedWidth size="lg" icon={faLocationDot} />
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://www.google.com/maps?q=${fuelListing.attributes.readable_address}`}
          >
            {`${startCase(fuelListing.attributes.readable_address)}`}
          </a>
        </p>
      </div>
    </div>
  );
};

export default function FuelListingsDetail() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { id } = useParams();
  const [shareStations] = useFeatureFlags('share_stations');
  const [isModalOpen, setModalOpen] = useState(false);

  const toggleModal = () => setModalOpen(!isModalOpen);

  const {
    selectedGrade,
    addressCoords,
    setSelectedGrade,
    destinationCoords,
    setDestinationCoords,
    setAddressCoords,
    setFilterHighFlowDieselLanes,
    setDiscountOnly,
    setSelectedFuelListingId,
    setShowMoreButton,
    routing,
    gMap,
    selectedFuelListingId,
    fuelRadiusData,
    fuelBoundsData,
  } = useFuelMapContext();

  const { data: fetchedFuelListing } = useSWRImmutable<EndpointResponse<FuelListing>>(
    {
      url: id ? `/fuel_listings/${id}` : null,
    },
    getFetcher,
  );

  const fuelListing =
    fetchedFuelListing?.data ||
    fuelRadiusData?.data.find((listing) => listing.id === id) ||
    fuelBoundsData?.data.find((listing) => listing.id === id);

  const goBack = () => {
    setSelectedFuelListingId(null);
    setShowMoreButton(false);
    if (!routing && addressCoords != null) {
      gMap?.panTo(new google.maps.LatLng(addressCoords.latitude, addressCoords.longitude));
    }

    navigate('/fuel-map');
  };

  // Setup coords when user enter the details by url.
  // We need this to show the stations on the map
  useEffect(() => {
    if (!destinationCoords && fuelListing && !selectedFuelListingId) {
      setAddressCoords(fuelListing.attributes.location.coordinates);
      setSelectedGrade('REGULAR');
      setFilterHighFlowDieselLanes(false);
      setDiscountOnly(false);
    }
  }, [
    destinationCoords,
    fuelListing,
    setAddressCoords,
    setDestinationCoords,
    setSelectedGrade,
    setFilterHighFlowDieselLanes,
    setDiscountOnly,
    selectedFuelListingId,
  ]);

  // Make sure the map is loaded when we display details
  const isLoading = fuelListing == null;

  return (
    <div className="flex w-full flex-col gap-4">
      <div className="border-soft flex flex-row items-center gap-2 border-b px-6 py-4 text-base font-semibold">
        <FontAwesomeIcon
          icon={faChevronLeft as IconProp}
          className="text-default-primary cursor-pointer"
          role="button"
          onClick={goBack}
          size="sm"
        />
        <p className="text-default-primary">Station Details</p>
      </div>

      {isLoading || !fuelListing ? (
        <div className="relative mt-24 w-full rounded-lg text-center">
          <SpinnerBoundary />
        </div>
      ) : (
        <div className="flex grow flex-col justify-between overflow-auto px-6 pb-6 pt-4">
          <div className="flex flex-col">
            <FuelListingDetailOverview fuelListing={fuelListing} toggleModal={toggleModal} />
            <FuelListingDetailLocation currentLocation={addressCoords} fuelListing={fuelListing} />
            <FuelListingDetailPrices prices={fuelListing.attributes.fuel_prices.data ?? []} />
          </div>
          <div className="flex flex-col gap-6">
            <Alert
              type="info"
              title={`Purchase with your ${t(
                'program_name',
                'AtoB',
              )} card to receive discounted prices.`}
            />
          </div>
          {shareStations && (
            <FuelListingShareStationModal
              open={isModalOpen}
              onClose={toggleModal}
              fuelListing={fuelListing}
              fuelGrade={selectedGrade}
              originLocation={addressCoords}
            />
          )}
        </div>
      )}
    </div>
  );
}
